import Messages from "../Messages";
import Utils from "../Utils";
import Settings from "../Settings";
import uuidv4 from "uuid/v4";
import { i18n } from '../i18n';
import { appcore } from "../appcore_ripe_proto";

export enum SortInputBy {
    NAME,
    UPLOADED_AT,
    CONFIGS_UPDATED_AT
}

let initial_state = {
    inputs: [], 
    output_configs: [], 
    selectable_outputs: [],
    icc_profiles: [],
    printers: [],
    screening_configs: [],
    size_positions:[],
    color_management_configs: [], 
    ink_management_configs: [],
    can_generate_output: false, 
    can_clear_output: false,
    can_send_to_print_or_job_lib: false,
    outputs: [],
    selected_thumbnail_tab_id: 0,
    thumbnail_url: "",
    live_thumbnail_guid: "",
    live_thumbnail_url: "",
    input_list_filter: "",
    filtered_inputs: [],
    output_settings_override: null,
    image_size_position: null,
    color_management_config: null,
    ink_management_config: null,
    input_list_sort: "",
    page_number: 1,
    data_loaded: false,
    filter_by_labels: [],
    sort_input_by: SortInputBy.NAME,
    output_settings_overrides: [],
    maps_oem_label: {},
    pages_to_print_disabled: false
};


function getThumbnailUrl(item, rotation, page_number, session_id) {

    return Settings.backendUrl + "/" + item.guid + "/thumbnail_" + rotation + ".png?page_number=" + page_number + "&session_id=" +  encodeURIComponent(session_id);
}

function getLiveThumbnailUrl(thumbnail_guid, rotation, session_id) {

    if(thumbnail_guid === "") {
        return "";
    }

    var thumbnail_url = Settings.backendUrl + "/tmp_live_views/" + session_id + "/" + thumbnail_guid + "_" + rotation + ".png?session_id=" +  encodeURIComponent(session_id);
    return thumbnail_url;
}

function getInputsFromPDFToolsData(pdf_tools_data) {
    var inputs = [];
    for(var i = 0; i < pdf_tools_data.source_pdfs.source_pdfs.length; i++) {
        var source_pdf = pdf_tools_data.source_pdfs.source_pdfs[i];
        inputs.push({
          uploading: false,
          upload_percentage: 0,
          selected: false,
          show_undo_deletion_button: false,
          undo_deletion_pressed: false,
          ... source_pdf
        });
    }
    return inputs;
}


function createOutputSettingsOverride(config, input) {
    if(config != null && input != null) {
        var override: any = {};
        override.dpi_x = config.dpi_x;
        override.dpi_y = config.dpi_y;
        override.icc_profile_guid = config.icc_profile_guid;
        override.output_code = config.output_code;
        override.printer_output = config.printer_output;
        override.use_raw_thumbnail = config.use_raw_thumbnail;
        override.screening_config_guid = config.screening_config_guid;
        override.collate = config.collate;
        override.copies = config.copies;
        override.bottom_margin_page = config.bottom_margin_page;
        override.bottom_margin_copy = config.bottom_margin_copy;
        override.intent = config.intent;
        override.preserve = config.preserve;
        override.selected_pages_to_print = config.selected_pages_to_print;
        override.pages = config.pages;
        override.print_mode_pcl = config.print_mode_pcl;
        return override;
    }

    return null;
}

function createColorManagementConfig(input, config, color_management_configs) {
    if(input != null && config != null) {
        let cm_config = color_management_configs.find((item) => { return item.pdf_guid == input.guid && item.config_guid == config.guid });
        if(cm_config == undefined) {
            cm_config = Messages.ColorManagementConfigType.toObject(Messages.ColorManagementConfigType.create(
                {
                brightness: Messages.ColorManagementValuesType.create(), 
                contrast: Messages.ColorManagementValuesType.create(), 
                config_guid: config.guid, pdf_guid: input.guid }), { defaults: true });
                color_management_configs.push(cm_config);
        }
        return cm_config;
    }
    return null;
}

 function createInkManagementConfig(input, config, ink_management_configs) {
    if(input != null && config != null) {
        let im_config = ink_management_configs.find((item) => { return item.pdf_guid == input.guid && item.config_guid == config.guid });
        if(im_config == undefined) {
            im_config = Messages.InkManagementConfigType.toObject(Messages.InkManagementConfigType.create({ config_guid: config.guid, pdf_guid: input.guid }), { defaults: true });
            ink_management_configs.push(im_config);
        }
        return im_config;
    }
    return null;
}

function createImageSizePosition(input, config, size_positions) {
    if(input != null && config != null) {
        let size_pos = size_positions.find((item) => { return item.pdf_guid == input.guid && item.config_guid == config.guid });
        if(size_pos == undefined) {
            size_pos = Messages.ImageSizePositionType.toObject(Messages.ImageSizePositionType.create({
                offset_unit: 10,
                resize_unit: 10,
                x_offset: 0,
                y_offset: 0,
                rotation: 0,
                width: input.width_mm,
                height: input.height_mm,
                config_guid: config.guid,
                pdf_guid: input.guid
            }), { defaults: true });
            size_positions.push(size_pos);
        }
        return size_pos;
    }
    return null;
}

function sortBy(prop: "filename" | "uploaded_at" | "configs_updated_at", sort: "asc" | "desc", a, b) {
    if(sort == "asc") {
        if(a[prop] == b[prop]) {
            return 0;
        }
        if(a[prop] > b[prop]) {
            return 1;
        }
        if(a[prop] < b[prop]) {
            return -1;
        }
    } else {
        if(b[prop] == a[prop]) {
            return 0;
        }
        if(b[prop] > a[prop]) {
            return 1;
        }
        if(b[prop] < a[prop]) {
            return -1;
        }
    }

}

function filterAndSort(inputs, filter, sort, labels, sort_by: SortInputBy) {
    let arr = inputs.filter((input) => {
        return input.filename.toLowerCase().includes(filter.toLowerCase());
    });

    if(labels.length > 0) {

        arr = arr.filter((input) => {
            for(let i = 0; i < labels.length; i++) {
                if(input.id_labels.indexOf(labels[i]) != -1) {
                    return true;
                }
            }
            return false;
        });
    }

    let sort_func;
    if(sort_by == SortInputBy.NAME) {
        sort_func = sortBy.bind(undefined, "filename", sort);
    } else if(sort_by == SortInputBy.UPLOADED_AT) {
        sort_func = sortBy.bind(undefined, "uploaded_at", sort);
    } else {
        sort_func = sortBy.bind(undefined, "configs_updated_at", sort);
    }

    if(sort == "asc" ||sort == "desc") {
        arr = arr.sort(sort_func);
    }

    return arr;
}

function getInput(guid, inputs) {
    let item = inputs.find((item) => { return item.guid == guid; });
    if(item == undefined) {
        return null;
    }
    return item;
}

function pagesToPrintDisabled(output_settings_override, outputs): boolean {

    if(output_settings_override != undefined) {

        let selected_output = outputs.find((value) => { return value.code == output_settings_override.output_code && value.printer_output == output_settings_override.printer_output; });
        if(selected_output != undefined) {
            return selected_output.show_print_mode_pcl && output_settings_override.print_mode_pcl == appcore.ripe.PrintModePCL.single_page_static;
        }
    }
    return false;
}

function selectInputPdf(guid, state, sharedState, appState) {

    let inputs = state.inputs.slice();

    let input  = getInput(guid, inputs);

    let config = null;

    if(sharedState.selected_printhead_config_id != -1) {
        config = state.output_configs[sharedState.selected_printhead_config_id];
    }

    if(input != null) {
        for(let i = 0; i < inputs.length; i++) {
            inputs[i].selected = input.guid === inputs[i].guid;
        }
    }
    
    let outputs = [];
    let can_generate_output = false;
    let can_clear_output = false;

    if(config != null && input != null) {
        if(input.split_pdf_results != undefined && input.split_pdf_results[config.guid] != undefined) {
            outputs = input.split_pdf_results[config.guid].split_pdfs;
        }
        if(outputs == undefined) {
            outputs = [];
        }
        for(let i = 0; i < outputs.length; i++) {
            outputs[i].selected = false;
        }

        can_generate_output = outputs.length == 0;
        can_clear_output = !can_generate_output;
    }

    let size_pos = createImageSizePosition(input, config, state.size_positions);
    
    let output_settings_override;
    if(config != undefined && input != undefined) {
        output_settings_override = state.output_settings_overrides.find((item) => { return config.guid == item.config_guid && input.guid == item.pdf_guid; });

        if(output_settings_override == undefined) {
            output_settings_override = createOutputSettingsOverride(config, input);
        }
    } 

    return Object.assign({}, state, { 
        inputs: inputs,
        outputs: outputs,
        filtered_inputs: filterAndSort(inputs, state.input_list_filter, state.input_list_sort, state.filter_by_labels, state.sort_input_by), 
        output_settings_override: output_settings_override,
        image_size_position: size_pos,
        color_management_config: createColorManagementConfig(input, config, state.color_management_configs),
        ink_management_config: createInkManagementConfig(input, config, state.ink_management_configs),
        page_number: 1,
        can_generate_output: can_generate_output,
        can_clear_output: can_clear_output,
        thumbnail_url: size_pos != null ? getThumbnailUrl(input, size_pos.rotation, 1, appState.session_id) : "",
        live_thumbnail_url: "",
        pages_to_print_disabled: pagesToPrintDisabled(output_settings_override, state.selectable_outputs)
    });
}

function selectConfig(id, state, sharedState, appState) {

    let input  = getInput(sharedState.selected_pdf_guid, state.inputs);

    let config = null;

    if(id != -1) {
        config = state.output_configs[id];
    }
    
    let outputs = [];

    let can_generate_output = false;
    let can_clear_output = false;

    if(config != null && input != null) {
        if(input.split_pdf_results != undefined && input.split_pdf_results[config.guid] != undefined) {
            outputs = input.split_pdf_results[config.guid].split_pdfs;
        }
        if(outputs == undefined) {
            outputs = [];
        }
        for(let i = 0; i < outputs.length; i++) {
            outputs[i].selected = false;
        }

        can_generate_output = outputs.length == 0;
        can_clear_output = !can_generate_output;
    }

    let size_pos = createImageSizePosition(input, config, state.size_positions);

    let output_settings_override;
    if(input != undefined && config != undefined) {
        output_settings_override = state.output_settings_overrides.find((item) => { return config.guid == item.config_guid && input.guid == item.pdf_guid; });

        if (output_settings_override == undefined) {
            output_settings_override = createOutputSettingsOverride(config, input)
        }
    }

    let map_oem_label = undefined;
    if(config != null) {
        map_oem_label = state.maps_oem_label[config.map_oem_label_guid];
    }

    return Object.assign({}, state, { 
        output_settings_override: output_settings_override,
        image_size_position: size_pos,
        color_management_config: createColorManagementConfig(input, config, state.color_management_configs),
        ink_management_config: createInkManagementConfig(input, config, state.ink_management_configs),
        page_number: 1,
        can_generate_output: can_generate_output,
        can_clear_output: can_clear_output,
        outputs: outputs,
        thumbnail_url: size_pos != null ? getThumbnailUrl(input, size_pos.rotation, 1, appState.session_id) : "",
        live_thumbnail_url: "",
        map_oem_label: map_oem_label,
        pages_to_print_disabled: pagesToPrintDisabled(output_settings_override, state.selectable_outputs)
    });
}

let generate_live_view_timeout_id = null;
let generate_live_view_guid = "";

function generateLiveView(sharedState, state, appState) {

    if(sharedState.selected_pdf_guid != "" && 
        state.output_settings_override != null && 
        sharedState.selected_printhead_config_id != -1 &&
        state.ink_management_config != null &&
        state.color_management_config != null) 
    {

        if(generate_live_view_timeout_id != null) {
            clearTimeout(generate_live_view_timeout_id);
        }

        generate_live_view_timeout_id = setTimeout(() => {

            if(generate_live_view_guid != "") {

                var qrMessage = Messages.QrMessageType.encode({
                    session_id: appState.session_id, 
                    identifier: Utils.QrMessageId.CANCEL_GENERATE_LIVE_VIEW, 
                    data: Messages.CancelGenerateLiveViewType.encode({ guid: generate_live_view_guid }).finish() 
                }).finish();
            
                Utils.sendMessage(Utils.MessageId.QR_MESSAGE, qrMessage);
            }

            generate_live_view_guid = uuidv4();

            var data = Messages.GenerateLiveViewType.encode(
            { 
                guid: generate_live_view_guid, 
                pdf_guid: sharedState.selected_pdf_guid, 
                profile_guid: state.output_settings_override.icc_profile_guid, 
                config_guid: state.output_configs[sharedState.selected_printhead_config_id].guid, 
                color_management_config: state.color_management_config, 
                ink_management_config: state.ink_management_config,
                page_number: state.page_number
            }).finish();
            var qrMessage = Messages.QrMessageType.encode({ session_id: appState.session_id, identifier: Utils.QrMessageId.GENERATE_LIVE_VIEW, data: data }).finish();
            Utils.sendMessage(Utils.MessageId.QR_MESSAGE, qrMessage);

        }, 200);

    }

}

function sendLabelOperation(op, id_label, guid_pdf, session_id) {
    let data = Messages.LabelOperationType.encode(
        {
            label: { id: id_label },
            op: op,
            pdf_guid: guid_pdf
        }).finish();

    let qrMessage = Messages.QrMessageType.encode(
        {
            session_id: session_id,
            identifier: Utils.QrMessageId.LABEL_OPERATION,
            data: data
        }).finish();
    Utils.sendMessage(Utils.MessageId.QR_MESSAGE, qrMessage);
}

export const pdfToolsReducer = (appState, sharedState, state = initial_state, action) => {
    switch (action.type) {
    case 'GET_PDF_TOOLS_DATA':
    {
        let qrMessage = Messages.QrMessageType.encode({ session_id: appState.session_id, identifier: Utils.QrMessageId.GET_PDF_TOOLS_DATA }).finish();
        Utils.sendMessage(Utils.MessageId.QR_MESSAGE, qrMessage);

        return state;
    }
    case 'PDF_TOOLS_DATA':
    {
        let pdf_tools_data = Messages.PDFToolsData.toObject(Messages.PDFToolsData.decode(action.data), { defaults: true});

        let inputs = getInputsFromPDFToolsData(pdf_tools_data);

        let map_oem_label = undefined;
        if(sharedState.selected_printhead_config_id != -1) {
            let config = pdf_tools_data.configs.config[sharedState.selected_printhead_config_id];
            map_oem_label = pdf_tools_data.configs.maps_oem_label[config.map_oem_label_guid];
        }

        let new_state = Object.assign({}, state, { 
            inputs: inputs, 
            output_configs: pdf_tools_data.configs.config,
            selectable_outputs: pdf_tools_data.configs.selectable_outputs,
            icc_profiles: pdf_tools_data.configs.icc_profiles.icc_profiles,
            printers: pdf_tools_data.configs.printers,
            screening_configs: pdf_tools_data.configs.screening_configs.config,
            size_positions: pdf_tools_data.size_positions.size_positions, 
            color_management_configs: pdf_tools_data.color_management_configs.configs, 
            ink_management_configs: pdf_tools_data.ink_management_configs.configs,
            data_loaded: true,
            labels: pdf_tools_data.labels.labels,
            output_settings_overrides: pdf_tools_data.output_settings_overrides,
            maps_oem_label: pdf_tools_data.configs.maps_oem_label,
            map_oem_label: map_oem_label
        });
        new_state = selectInputPdf(sharedState.selected_pdf_guid, new_state, sharedState, appState);
        
        generateLiveView(sharedState, new_state, appState);

        return new_state;
    }
    case 'PDF_TOOLS_FILTER_INPUT_CHANGE':
    {
        return Object.assign({}, state, { 
            input_list_filter: action.value, 
            filtered_inputs: filterAndSort(state.inputs, action.value, state.input_list_sort, state.filter_by_labels, state.sort_input_by) });
    }
    case 'PDF_TOOLS_INPUT_LIST_SORT':
    {
        let input_list_sort = state.input_list_sort == action.value ? "" : action.value;
        return Object.assign({}, state, {
            input_list_sort: input_list_sort, 
             filtered_inputs: filterAndSort(state.inputs, state.input_list_filter, input_list_sort, state.filter_by_labels, state.sort_input_by) 
        });
    }
    case 'PDF_TOOLS_DOWNLOAD_INPUT':
    {
        let input = state.inputs.find((input) => { return input.guid == action.guid; });
        const url = Settings.backendUrl + "/source/" + input.guid + "/" + input.guid + ".pdf?session_id=" + encodeURIComponent(appState.session_id);
        Utils.download(url);
        return state;
    }
    case 'PDF_TOOLS_START_DELETE_INPUT':
    {
        let inputs = state.inputs.map((item, index) => {
            if(item.guid == action.guid) {
                return {
                    ... item,
                    show_undo_deletion_button: true
                }
            }
            return item;
        });

        return Object.assign({}, state, {
            inputs: inputs
        });
    }
    case 'PDF_TOOLS_DELETE_INPUT':
    {
        let inputs = state.inputs.map((item, index) => {
            if(item.guid == action.guid) {

                if(item.undo_deletion_pressed) {
                    return {
                        ... item,
                        show_undo_deletion_button: false,
                        undo_deletion_pressed: false
                    }
                } else {
                    let delete_input_data = Messages.DeleteInputPDFType.encode({ guid: action.guid }).finish();
                    let qr_message_data = Messages.QrMessageType.encode({ identifier: Utils.QrMessageId.DELETE_INPUT_PDF, data: delete_input_data, session_id: appState.session_id }).finish();
                    Utils.sendMessage(Utils.MessageId.QR_MESSAGE, qr_message_data);
                }
            }
            return item;
        });

        return Object.assign({}, state, {
            inputs: inputs
        });
    }
    case 'PDF_TOOLS_UNDO_DELETE_INPUT':
    {
        let inputs = state.inputs.map((item, index) => {
            if(item.guid == action.guid) {
                return {
                    ... item,
                    undo_deletion_pressed: true
                }
            }
            return item;
        });

        return Object.assign({}, state, {
            inputs: inputs
        });
    }
    case 'PDF_TOOLS_UPLOAD_FILE_START':
    {
        let new_inputs = state.inputs.slice();
        new_inputs.push({
            uploading: true,
            upload_percentage: 0,
            selected: false,
            show_undo_deletion_button: false,
            undo_deletion_pressed: false,
            guid: action.guid,
            filename: action.filename,
            id_labels: []
        });
        return Object.assign({}, state, {
            inputs: new_inputs, 
             filtered_inputs: filterAndSort(new_inputs, state.input_list_filter, state.input_list_sort, state.filter_by_labels, state.sort_input_by) 
        });   
    }
    case 'PDF_TOOLS_UPLOAD_FILE_PROGRESS':
    {
        let inputs = state.inputs.map((item, index) => {
            if(item.guid == action.guid) {
                return {
                    ... item,
                    upload_percentage: action.progress
                }
            }
            return item;
        });

        return Object.assign({}, state, {
            inputs: inputs,
            filtered_inputs: filterAndSort(inputs, state.input_list_filter, state.input_list_sort, state.filter_by_labels, state.sort_input_by)
        });
    }
    case 'PDF_TOOLS_UPLOAD_FILE_FINISH':
    {
        let text = null;

        if (action.status === 409) {
            text = `${i18n.t("warning")}: ${i18n.t("file_is_already_in_the_list")}`;
        } else if(action.status === 501) {
            if(action.responseText === "ERROR_VARYING_PAGE_SIZE_NOT_SUPPORTED") {
                text = `${i18n.t("warning")}: ${i18n.t("pdf_with_varying_page_sizes_is_not_supported")}`;
            } else if(action.responseText === "ERROR_PDF_PASSWORD_NOT_SUPPORTED") {
                text = `${i18n.t("warning")}: ${i18n.t("password_protected_pdf_is_not_supported")}`;
            } else if(action.responseText === "ERROR_USER_UNIT_NOT_SUPPORTED") {
                text = `${i18n.t("warning")}: ${i18n.t("pdf_with_custom_user_unit_is_not_supported")} ${i18n.t("contanct_by_email")}.`;
            } else if(action.responseText === "ERROR_UNKNOWN") {
                text = `${i18n.t("warning")}: ${i18n.t("pdf_upload_failed")} ${i18n.t("contanct_by_email")}.`;
            }
            
        } else if(action.status !== 200) {
            text = `${i18n.t("error")}: ${i18n.t("pdf_file_upload_returned_with_status_code", {0: action.status})}`;
        }

        let inputs = state.inputs.map((item, index) => {
            if(item.guid == action.guid) {
                let filename = item.filename;
                if(text != null) {
                    filename += " (" + text + ")";
                }
                return {
                    ... item,
                    filename: filename,
                    upload_percentage: action.progress,
                    guid: action.response.guid != undefined ? action.response.guid : item.guid,
                    width_mm: action.response.width_mm,
                    height_mm: action.response.height_mm,
                    uploading: false,
                    upload_error: action.status == 200 ? false : true,
                    pdf_a1b_compliant: action.response.pdf_a1b_compliant,
                    num_of_pages: action.response.num_of_pages,
                    uploaded_at: action.response.uploaded_at,
                    configs_updated_at: action.response.configs_updated_at
                }
            }
            return item;
        });

        return Object.assign({}, state, {
            inputs: inputs,
            filtered_inputs: filterAndSort(inputs, state.input_list_filter, state.input_list_sort, state.filter_by_labels, state.sort_input_by)
        });
    }
    case 'PDF_TOOLS_UPLOAD_FILE_ERROR_DISMISS':
    {
        let new_inputs = state.inputs.filter((item) => {
            return item.guid != action.guid;
        });

        return Object.assign({}, state, {
            inputs: new_inputs, 
             filtered_inputs: filterAndSort(new_inputs, state.input_list_filter, state.input_list_sort, state.filter_by_labels, state.sort_input_by) 
        });  
    }
    case 'PDF_TOOLS_DELETE_INPUT_PDF_RESULT':
    {
        let delete_result = Messages.DeleteInputPDFResultType.decode(action.data);
        let new_state = { ... state, inputs: state.inputs.slice() };
        let input  = getInput(delete_result.guid, new_state.inputs);
        if(input != undefined && delete_result.error_code == 0) {
            let index = new_state.inputs.findIndex((item) => { return item.guid == delete_result.guid; });
            new_state.inputs.splice(index, 1);
            new_state.filtered_inputs = filterAndSort(new_state.inputs, new_state.input_list_filter, new_state.input_list_sort, state.filter_by_labels, state.sort_input_by);
        }
        if(sharedState.selected_pdf_guid == "") {
            new_state.image_size_position = null;
            new_state.color_management_config = null;
            new_state.ink_management_config = null;
            new_state.outputs = [];
            new_state.can_generate_output = false;
            new_state.can_clear_output = false;
            new_state.output_settings_override = null;
        }
        return new_state;
    }
    case 'SELECT_INPUT_PDF':
    {
        
        let new_state = selectInputPdf(action.guid, state, sharedState, appState);
        generateLiveView(sharedState, new_state, appState);
        return new_state;
    }
    case 'PDF_TOOLS_OUTPUT_SETTINGS_OVERRIDE_CHANGE':
    {
        let output_changed = state.output_settings_override.output_code != action.value.output_code || state.output_settings_override.printer_output != action.value.printer_output;
        let profile_changed = state.output_settings_override.icc_profile_guid != action.value.icc_profile_guid;
        let generate_live_view = output_changed || profile_changed;

        let input  = getInput(sharedState.selected_pdf_guid, state.inputs);
        let config = state.output_configs[sharedState.selected_printhead_config_id];

        let output_settings_override = state.output_settings_overrides.find((item) => { return config.guid == item.config_guid && input.guid == item.pdf_guid; });

        let new_overrides;
        let new_override = { ... action.value, config_guid: config.guid, pdf_guid: input.guid };

        if(output_settings_override == undefined) {
            new_overrides = state.output_settings_overrides.slice();
            new_overrides.push(new_override);
        } else {
            new_overrides = state.output_settings_overrides.map((item) => {
                if(config.guid == item.config_guid && input.guid && item.pdf_guid) {
                    return new_override;
                }
                return item;
            })
        }

        let qr_message = Messages.QrMessageType.encode({ 
            session_id: appState.session_id, 
            identifier: Utils.QrMessageId.SAVE_OUTPUT_SETTINGS_OVERRIDE, 
            data: Messages.OutputSettingsOverride.encode(new_override).finish() }).finish();  

        Utils.sendMessage(Utils.MessageId.QR_MESSAGE, qr_message);

        let new_state = Object.assign({}, state, {
            output_settings_overrides: new_overrides,
            output_settings_override: new_override,
            live_thumbnail_url: generate_live_view ? "" : state.live_thumbnail_url,
            pages_to_print_disabled: pagesToPrintDisabled(new_override, state.selectable_outputs)
        }); 
        if(generate_live_view) {
            generateLiveView(sharedState, new_state, appState);
        }
        return new_state;
    }
    case 'PDF_TOOLS_INK_MANAGEMENT_CHANGE':
    {
        var im_config = { ... action.value };
        var im_configs = state.ink_management_configs.map((item) => {
            if(item.config_guid == action.value.config_guid && item.pdf_guid == action.value.pdf_guid) {
                return im_config;
            }
            return item;
        });

        let new_state = Object.assign({}, state, {
            ink_management_configs: im_configs,
            ink_management_config: im_config,
            live_thumbnail_url: ""
        }); 


        generateLiveView(sharedState, new_state, appState);

        return new_state;
    }
    case 'PDF_TOOLS_IMAGE_SIZE_POSITION_CHANGE':
    {
        let size_pos_config = { ... action.value };
        
        let size_pos_configs = state.size_positions.map((item) => {
            if(item.config_guid == action.value.config_guid && item.pdf_guid == action.value.pdf_guid) {
                return size_pos_config;
            }
            return item;
        });

        let input  = getInput(sharedState.selected_pdf_guid, state.inputs);

        let thumbnail_url = "";
        let live_thumbnail_url = "";

        if(input != null) {
            thumbnail_url = getThumbnailUrl(input, size_pos_config.rotation, state.page_number, appState.session_id);
            live_thumbnail_url = getLiveThumbnailUrl(state.live_thumbnail_guid, size_pos_config.rotation, appState.session_id);
        }

        return Object.assign({}, state, {
            size_positions: size_pos_configs,
            image_size_position: size_pos_config,
            thumbnail_url: thumbnail_url,
            live_thumbnail_url: live_thumbnail_url
        }); 
    }
    case 'SELECT_PRINTHEAD_CONFIG':
    {
        let new_state = selectConfig(action.id, state, sharedState, appState);
        generateLiveView(sharedState, new_state, appState);
        return new_state;
    }
    case 'PDF_TOOLS_GENERATE_LIVE_VIEW_RESULT': 
    {
        let result = Messages.GenerateLiveViewResultType.decode(action.data);
        let live_thumbnail_url = "";
        if(generate_live_view_guid == result.guid && state.image_size_position != null) {
            live_thumbnail_url = getLiveThumbnailUrl(result.guid, state.image_size_position.rotation, appState.session_id);
        }

        return Object.assign({}, state, {
            live_thumbnail_url: live_thumbnail_url,
            live_thumbnail_guid: result.guid
        }); 


    }
    case 'PDF_TOOLS_GENERATE': 
    {
        var generate = Messages.GenerateType.create({
            guid: sharedState.selected_pdf_guid, 
            config_guid: state.output_configs[sharedState.selected_printhead_config_id].guid, 
            settings_override: { ... state.output_settings_override },
            generate_type: action.gen_type,
            filename: action.filename
        });

        if(generate.settings_override.selected_pages_to_print == "current_page") {
            generate.settings_override.pages = state.page_number.toString();
        }

        let generateData = Messages.GenerateType.encode(generate).finish();

        var qrMessageData = Messages.QrMessageType.encode({ session_id: appState.session_id, identifier: Utils.QrMessageId.GENERATE, data: generateData }).finish();
        Utils.sendMessage(Utils.MessageId.QR_MESSAGE, qrMessageData);
        return state;
    }
    case 'PDF_TOOLS_SOURCE_PDF_CONFIGS_UPDATED_AT':
    {
        var source_pdf_configs_updated_at = Messages.SourcePDFConfigsUpdatedAtType.decode(action.data);

        var new_inputs = state.inputs.map((item) => {
            if(item.guid == source_pdf_configs_updated_at.guid) {

                return {
                    ... item,
                    configs_updated_at: source_pdf_configs_updated_at.configs_updated_at
                };
            }
            return item;
        });

        return { 
            ... state, 
            inputs: new_inputs,
            filtered_inputs: filterAndSort(new_inputs, state.input_list_filter, state.input_list_sort, state.filter_by_labels, state.sort_input_by) 
        };
        
    }
    case 'PDF_TOOLS_SOURCE_PDF':
    {

        var source_pdf = Messages.SourcePDFType.decode(action.data);

        var new_inputs = state.inputs.map((item) => {
            if(item.guid == source_pdf.guid) {

                let split_pdf_results = Object.assign({}, item.split_pdf_results, source_pdf.split_pdf_results);
                return {
                    ... item,
                    split_pdf_results
                };
            }
            return item;
        });
    
        let config = null;

        if(sharedState.selected_printhead_config_id != -1) {
            config = state.output_configs[sharedState.selected_printhead_config_id];
        }
        
        let input = getInput(sharedState.selected_pdf_guid, new_inputs);

        let outputs = [];
    
        let can_generate_output = state.can_generate_output;
        let can_clear_output = state.can_clear_output;
    
        if(config != null && input != null && input.guid == source_pdf.guid) {
            if(input.split_pdf_results[config.guid] != undefined) {
                outputs = input.split_pdf_results[config.guid].split_pdfs;
            }
            if(outputs == undefined) {
                outputs = [];
            }
            for(let i = 0; i < outputs.length; i++) {
                outputs[i].selected = false;
            }
    
            can_generate_output = outputs.length == 0;
            can_clear_output = !can_generate_output;
        }

        return Object.assign({}, state, { 
            inputs: new_inputs,
            outputs: outputs,
            can_generate_output: can_generate_output,
            can_clear_output: can_clear_output
        });
    }
    case 'PDF_TOOLS_OUTPUT_SELECT':
    {
        let outputs = state.outputs.map((item) => {
            return {
                ... item,
                selected: item.filename == action.filename
            }
        });
        return Object.assign({}, state, { outputs: outputs });
    }
    case 'PDF_TOOLS_OUTPUT_DOWNLOAD':
    {
        let config_guid = state.output_configs[sharedState.selected_printhead_config_id].guid;
        const url = Settings.backendUrl + "/output/" + sharedState.selected_pdf_guid + "/" + config_guid + "/" + encodeURIComponent(action.filename) + "?session_id=" + encodeURIComponent(appState.session_id);
        Utils.download(url);
        return state;
    }
    case 'PDF_TOOLS_OUTPUT_DOWNLOAD_ZIP':
    {
        let input = getInput(sharedState.selected_pdf_guid, state.inputs);
        let config_guid = state.output_configs[sharedState.selected_printhead_config_id].guid;
  
        const url = Settings.backendUrl + "/output/" + input.guid + "/" + config_guid + "/zip/" + input.guid + ".zip?session_id=" + encodeURIComponent(appState.session_id);
        Utils.download(url);
        return state;
    }
    case 'PDF_TOOLS_DELETE_OUTPUT':
    {
        let pdf_guid = sharedState.selected_pdf_guid;
        var config_cuid = state.output_configs[sharedState.selected_printhead_config_id].guid;
        var deleteData = Messages.DeleteSplitFilesType.encode({ guid: pdf_guid, config_guid: config_cuid }).finish();
        var qrMessageData = Messages.QrMessageType.encode({ session_id: appState.session_id, identifier: Utils.QrMessageId.DELETE_SPLIT_FILES, data: deleteData }).finish();
        Utils.sendMessage(Utils.MessageId.QR_MESSAGE, qrMessageData);
        return state;
    }
    case 'PDF_TOOLS_PAGE_NUMBER_CHANGE': 
    {
        let input = getInput(sharedState.selected_pdf_guid, state.inputs);


        let new_state = Object.assign({}, state, {
            page_number: action.value,
            thumbnail_url: getThumbnailUrl(input, state.image_size_position.rotation, action.value, appState.session_id),
            live_thumbnail_url: ""
        });  

        generateLiveView(sharedState, new_state, appState);

        return new_state;
    }
    case 'PDF_TOOLS_UNMOUNT': 
    {
        return Object.assign({}, state, {
            data_loaded: false
        });
    }
    case 'PDF_TOOLS_LABEL_ADD':
    {

        sendLabelOperation(Messages.LabelOperationType.Operation.ADD_LABEL_TO_PDF, action.id_label, action.guid_pdf, appState.session_id);

        let inputs = state.inputs.map((input) => {
            if(input.guid == action.guid_pdf) {
                let id_labels = input.id_labels.slice();
                id_labels.push(action.id_label);
                return {
                    ... input,
                    id_labels: id_labels
                };
            }
            return input;
        });

        return Object.assign({}, state, {
            inputs: inputs
        });
    }
    case 'PDF_TOOLS_LABEL_DELETE':
    {
        sendLabelOperation(Messages.LabelOperationType.Operation.DELETE_LABEL_FROM_PDF, action.id_label, action.guid_pdf, appState.session_id);

        let inputs = state.inputs.map((input) => {
            if(input.guid == action.guid_pdf) {
                let id_labels = input.id_labels.slice();
                id_labels.splice(id_labels.indexOf(action.id_label), 1);
                return {
                    ... input,
                    id_labels: id_labels
                };
            }
            return input;
        });

        return Object.assign({}, state, {
            inputs: inputs
        });
    }
    case 'PDF_TOOLS_FILTER_BY_LABEL': 
    {
        return Object.assign({}, state, {
            filter_by_labels: action.values,
            filtered_inputs: filterAndSort(state.inputs, state.input_list_filter, state.input_list_sort, action.values, state.sort_input_by)
        });
    }
    case 'PDF_TOOLS_SORT_INPUT_BY_CHANGE':
    {
        let new_sort_input_by: SortInputBy;
        if(state.sort_input_by == SortInputBy.NAME) {
            new_sort_input_by = SortInputBy.UPLOADED_AT;
        } else if(state.sort_input_by == SortInputBy.UPLOADED_AT) {
            new_sort_input_by = SortInputBy.CONFIGS_UPDATED_AT;
        } else {
            new_sort_input_by = SortInputBy.NAME;
        }

        return { 
            ... state, 
            sort_input_by: new_sort_input_by,
            filtered_inputs: filterAndSort(state.inputs, state.input_list_filter, state.input_list_sort, state.filter_by_labels, new_sort_input_by)
         };
    }
    default:
        return state;
    }
};