import React, { Dispatch } from 'react';
import { StyleSheet, Text, Image, View, TextInput, Picker, Switch } from 'react-native';
import { connect, ConnectedProps } from 'react-redux'
import { Button, DataTable } from "react-native-paper";
import { colors } from "./Styles";
import { RipeButton } from "./RipeButton";
import RipeButtonDelete from "./RipeButtonDelete";

import utils from './Utils';
import * as printheadConfigsActions from './actions/PrintheadConfigurationsActions';
import { selectPrintheadConfig, toggleUnit } from './actions/SharedActions';
import DeviceLinks from "./DeviceLinks";
import { withTranslation, WithTranslation } from "react-i18next";
import ComponentPagesToPrint from "./ComponentPagesToPrint/ComponentPagesToPrint";
import { StateRoot } from './StateRoot';
import { ActionGetPrintheadConfigurations, ActionPrintModePCLChange } from './actions/PrintheadConfigurationsActions';
import { appcore } from './appcore_ripe_proto';

const mapStateToProps = (state: StateRoot) => ({
    ... state.printhead_configurations,
    selected_printhead_config_id: state.shared.selected_printhead_config_id,
    inch: state.shared.inch
});

const mapDispatchToProps = (dispatch: Dispatch<{type: string}>) => {
    return {
      onLoadData: () => {
        dispatch(new ActionGetPrintheadConfigurations());
      },
      onNewConfig: (current_size) => {
        dispatch(printheadConfigsActions.newConfig(current_size));
      },
      onSelectedConfigChange: (id) => {
        dispatch(selectPrintheadConfig(id));
      },
      onSaveData: () => {
        dispatch(printheadConfigsActions.savePrintheadConfigurations());
      },
      onDeleteConfig: (id, current_size) => {
        dispatch(printheadConfigsActions.deleteConfig(id, current_size));
      },
      onCopyConfig: (id, current_size) => {
        dispatch(printheadConfigsActions.copyConfig(id, current_size));
      },
      onToggleUnit: () => {
        dispatch(toggleUnit());
      },
      onToggleHead: (id, head_id) => {
        dispatch(printheadConfigsActions.toggleHead(id, head_id));
      },
      onTotalPrintWidthChange: (id, value, inch) => {
        dispatch(printheadConfigsActions.totalPrintWidthChange(id, value, inch));
      },
      onNameChange: (id, value) => {
        dispatch(printheadConfigsActions.nameChange(id, value));
      },
      onLeftChange: (id, head_id, value, inch) => {
        dispatch(printheadConfigsActions.leftChange(id, head_id, value, inch));
      },
      onRightChange: (id, head_id, value, inch) => {
        dispatch(printheadConfigsActions.rightChange(id, head_id, value, inch));
      },
      onOutputChange: (id, value) => {
        dispatch(printheadConfigsActions.outputChange(id, value));
      },
      onScreeningChange: (id, value) => {
        dispatch(printheadConfigsActions.screeningChange(id, value));
      },
      onIntentChange: (id, value) => {
        dispatch(printheadConfigsActions.intentChange(id, value));
      },
      onPreserveChange: (id, value) => {
        dispatch(printheadConfigsActions.preserveChange(id, value));
      },
      onProfileChange: (id, value) => {
        dispatch(printheadConfigsActions.profileChange(id, value));
      },
      onDpiChange: (id, value) => {
        dispatch(printheadConfigsActions.dpiChange(id, value));
      },
      onStitchingChange: (id, value) => {
        dispatch(printheadConfigsActions.stitchingChange(id, value));
      },
      onToggleRawThumbnail: (id) => {
        dispatch(printheadConfigsActions.toggleRawThumbnail(id));
      },
      onOverlapDotPitchChange: (id, value) => {
        dispatch(printheadConfigsActions.overlapDotPitchChange(id, value));
      },
      onUnmount: () => {
        dispatch(printheadConfigsActions.unmount());
      },
      onCopiesChange: (id, value) => {
        dispatch(printheadConfigsActions.copiesChange(id, value));
      },
      onToggleCollate: (id) => {
        dispatch(printheadConfigsActions.toggleCollate(id));
      },
      onBottomMarginPageChange: (id, value, inch) => {
        dispatch(printheadConfigsActions.bottomMarginPageChange(id, value, inch));
      },
      onBottomMarginCopyChange: (id, value, inch) => {
        dispatch(printheadConfigsActions.bottomMarginCopyChange(id, value, inch));
      },
      onToggleAlwaysGenerateHeadWidthRaster: (id) => {
        dispatch(printheadConfigsActions.toggleAlwaysGenerateHeadWidthRaster(id));
      },
      onDeviceLinkUpload: (id: number, files: FileList) => {
        dispatch(printheadConfigsActions.deviceLinkUpload(id, files));
      },
      onDeviceLinkDelete: (id: number, config_id: number) => {
        dispatch(printheadConfigsActions.deviceLinkDelete(id, config_id));
      },
      onDeviceLinkDownload: (id: number, config_id: number) => {
        dispatch(printheadConfigsActions.deviceLinkDownload(id, config_id));
      },
      onDeviceLinkMoveUp: (id: number, config_id: number) => {
        dispatch(printheadConfigsActions.deviceLinkMoveUp(id, config_id));
      },
      onDeviceLinkMoveDown: (id: number, config_id: number) => {
        dispatch(printheadConfigsActions.deviceLinkMoveDown(id, config_id));
      },
      onPagesToPrintChange: (id: number, pages: string, selected_pages_to_print: string) => {
        dispatch(printheadConfigsActions.pagesToPrintChange(id, pages, selected_pages_to_print));
      },
      onPrintModePCLChange: (id: number, value: appcore.ripe.PrintModePCL) => {
          dispatch(new ActionPrintModePCLChange(id, value));
      }
    }
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>

type PropsPrintheadConfigurationPage = PropsFromRedux & WithTranslation & { style: any };

class PrintheadConfigurationPage extends React.Component<PropsPrintheadConfigurationPage, {}> {

    componentWillMount() {
        this.props.onLoadData();
    }

    componentWillUnmount() {
        this.props.onUnmount();
    }

    renderConfigsPicker() {
        var pickerItems = [];
        for(var i = 0; i < this.props.configs.length; i++) {
            pickerItems.push(<Picker.Item label={this.props.configs[i].name} value={i}></Picker.Item>);
            }
        return (
            <Picker selectedValue={this.props.selected_printhead_config_id} onValueChange={ (value) => { this.props.onSelectedConfigChange(value)} } style={{flex: 1}}>
                { pickerItems }
            </Picker>
        );
    }

    selectedConfigNumberOfHeads() {
        return this.props.configs[this.props.selected_printhead_config_id].head_params.filter((item) => { return item.enabled; }).length;
    }

    renderOutputPicker() { 

        const { t } = this.props;

        var config = this.props.configs[this.props.selected_printhead_config_id];

        var selectedOutput = this.props.selectable_outputs.findIndex((value) => { 
            return value.code == config.output_code && value.printer_output == config.printer_output; 
        });

        var items = [];
        
        for(var i = 0; i < this.props.selectable_outputs.length; i++)  {

            if(this.props.selectable_outputs[i].printer_output) {
                items.push(<Picker.Item label={ `${t("printer")}: ${this.props.selectable_outputs[i].name}`} value={i}></Picker.Item>);
            } else {
                items.push(<Picker.Item label={ `${t("file")}: ${this.props.selectable_outputs[i].name}`} value={i}></Picker.Item>);
            }
        }

        var config = this.props.configs[this.props.selected_printhead_config_id];
        return (
            <View style={{flexDirection: "row", marginTop: 10}}>
                <Text style={{width: 140}}>{t("output_device")}</Text>
                <Picker style={{flex: 1, marginLeft: 10}} 
                    onValueChange={(value) => { this.props.onOutputChange(this.props.selected_printhead_config_id, value)}} 
                    selectedValue={selectedOutput}>
                    {items}
                </Picker>
            </View>
        );
    }

    renderICCProfilePicker() {
        
        const { t } = this.props;

        var items = [];
        
        items.push(<Picker.Item label={t("none")} value="none"></Picker.Item>);
        items.push(<Picker.Item label={t("default")} value=""></Picker.Item>);

        for(var i = 0; i < this.props.icc_profiles.length; i++)  {
            items.push(<Picker.Item label={this.props.icc_profiles[i].name} value={this.props.icc_profiles[i].guid}></Picker.Item>);
        }

        var config = this.props.configs[this.props.selected_printhead_config_id];
        return (
            <View style={{flexDirection: "row", marginTop: 10}}>
                <Text style={{width: 140}}>{t("profile")}</Text>
                <Picker style={{flex: 1, marginLeft: 10}} 
                    onValueChange={(value) => { this.props.onProfileChange(this.props.selected_printhead_config_id, value)}} 
                    selectedValue={config.icc_profile_guid}>
                    {items}
                </Picker>
            </View>
        );
    }

    renderHeadConfigParams() {
        
        const { t } = this.props;

        if(this.props.selected_printhead_config_id == -1) {
            return null;
        }

        var config = this.props.configs[this.props.selected_printhead_config_id];
        var printheadParams = [];
        var totalWidthComponent = 
        <View style={{flexDirection: "row", marginTop: 10}}>
                <Text  style={{width: 140}}>{t("total_print_width")}</Text>
                <TextInput keyboardType="numeric" onChangeText={ (value) => { this.props.onTotalPrintWidthChange(this.props.selected_printhead_config_id, value, this.props.inch)} } 
                    style={[styles.inputStyle, { width: 88, marginLeft: 10 }]} 
                    value={ utils.convertIfInch(config.total_print_width_mm, this.props.inch)}>
                </TextInput>
        </View>
        var configName = 
        <View style={{flexDirection: "row", marginTop: 10}}>
                <Text  style={{width: 140}}>{t("config_name")}</Text>
                <TextInput onChangeText={(value) => { this.props.onNameChange(this.props.selected_printhead_config_id, value)}} 
                    style={[styles.inputStyle, { textAlign: "left", flex: 1, marginLeft: 10}]} 
                    value={config.name}></TextInput>
        </View>


        for(let i = 0; i <config.head_params.length; i++) {
            var nextEnabled = i + 1 < config.head_params.length && config.head_params[i+1].enabled;
            var prevEnabled = i - 1 >= 0 && config.head_params[i-1].enabled;
            var currentEnabled = config.head_params[i].enabled;

            var switchDisabled = false;

            if(currentEnabled && nextEnabled) {
                switchDisabled = true;
            }

            if(!currentEnabled && !nextEnabled && !prevEnabled && i != 0) {
                switchDisabled = true;
            }

            printheadParams.push(
                
                <DataTable.Row>
                    <DataTable.Cell>
                        {/* @ts-ignore*/}
                        <Switch activeThumbColor={colors.switchThumb} 
                        onTintColor={colors.switchTint} 
                        style={{marginLeft: 5}} 
                        value={config.head_params[i].enabled} 
                        disabled={switchDisabled} 
                        onValueChange={ () => { this.props.onToggleHead(this.props.selected_printhead_config_id, i) } }>

                        </Switch>
                    </DataTable.Cell>
                    <DataTable.Cell>
                        <Text>{(i+1)}</Text>
                    </DataTable.Cell>
                    <DataTable.Cell>
                        
                        <TextInput keyboardType="numeric" 
                            editable={currentEnabled} 
                            style={[styles.inputStyle, {width: 50}]} 
                            value={ utils.convertIfInch(config.head_params[i].left_mm, this.props.inch) } 
                            onChangeText={(value) => { this.props.onLeftChange(this.props.selected_printhead_config_id, i, value, this.props.inch)}}></TextInput>
                        
                    </DataTable.Cell>
                    <DataTable.Cell>
                        <TextInput keyboardType="numeric"
                            editable={currentEnabled} 
                            style={[styles.inputStyle, {width: 50}]} 
                            value={utils.convertIfInch(config.head_params[i].right_mm, this.props.inch)} 
                            onChangeText={(value) => { this.props.onRightChange(this.props.selected_printhead_config_id, i, value, this.props.inch)}}></TextInput>
                    </DataTable.Cell>
                    <DataTable.Cell>
                        <TextInput editable={false} style={[styles.inputStyle, {width: 50}]} value={utils.convertIfInch(config.head_params[i].print_width_mm, this.props.inch)}></TextInput>
                    </DataTable.Cell>
                </DataTable.Row>
            );
        }


        
        return (
            <View>
                <DataTable  style={{width: 780}}> 
                    <DataTable.Header>
                        <DataTable.Title>
                            <View style={{flexDirection: "row"}}>
                                <Text style={ !this.props.inch ? {fontWeight: "bold"}:{}}>{t("mm")}</Text>
                                {/* @ts-ignore*/ }
                                <Switch activeThumbColor={colors.switchThumb} style={{marginLeft: 5, marginRight: 5}} value={this.props.inch} 
                                onValueChange={ () => { this.props.onToggleUnit()} } 
                                thumbColor={colors.switchThumb} tintColor={colors.switchTint} onTintColor={colors.switchTint}/>

                                <Text style={ this.props.inch ? {fontWeight: "bold"}:{}}>{t("inch")}</Text>
                            </View>
                        </DataTable.Title> 
                        <DataTable.Title><Text style={styles.tableTextStyle}>{t("head")}</Text></DataTable.Title>
                        <DataTable.Title><Text style={styles.tableTextStyle}>{t("left")}</Text></DataTable.Title>
                        <DataTable.Title><Text style={styles.tableTextStyle}>{t("right")}</Text></DataTable.Title>
                        <DataTable.Title><Text style={styles.tableTextStyle}>{t("print_width")}</Text></DataTable.Title>
                    </DataTable.Header>
                    { printheadParams }
                </DataTable>
                { configName }
                { totalWidthComponent }
                { this.renderOutputPicker() }
                { this.renderOutputSpecificSettings() }
            </View>
        );
    }

    getOEMLabel(label) {
        if(this.props.map_oem_label != undefined && this.props.map_oem_label.mapping[label] != undefined) {
            return this.props.map_oem_label.mapping[label];
        }
        return label;
    }

    renderOutputSpecificSettings() {

        const { t } = this.props;

        if(this.props.selected_printhead_config_id === -1) {
            return null;
        }
        
        var config = this.props.configs[this.props.selected_printhead_config_id];
        
        var selectedOutput = this.props.selectable_outputs.find((value) => { return value.code == config.output_code && value.printer_output == config.printer_output; });

        if(selectedOutput === undefined) {
            return null;
        }

        var dpiConfig = null;

        if(selectedOutput.show_dpi) {

            var dpiItems = [];

            for(var i = 0; i < selectedOutput.dpi_options.length; i++) {
                dpiItems.push(<Picker.Item 
                    value={selectedOutput.dpi_options[i]} 
                    label={this.getOEMLabel(selectedOutput.dpi_options[i])}></Picker.Item>);
            }

            dpiConfig = 
            <View style={{flexDirection: "row", marginTop: 10}}>
                <Text  style={{width: 140}}>{t("dpi")}</Text>
                <Picker style={{flex: 1, marginLeft: 10}} 
                    selectedValue={config.dpi_x + "x" + config.dpi_y} 
                    onValueChange={(value) => { this.props.onDpiChange(this.props.selected_printhead_config_id, value)}}>
                    {dpiItems}
                </Picker>
            </View>;
        }

        var useRawThumbnail = null;
        if(selectedOutput.show_use_raw_thumbnail) { 
            useRawThumbnail = 
            <View style={{flexDirection: "row", marginTop: 10}}>
                <Text style={{width: 140}}>{t("use_raw_thumbnail")}</Text>
                {/* @ts-ignore*/ }
                <Switch activeThumbColor={colors.switchThumb} style={{marginLeft: 10, alignSelf: "center"}} value={config.use_raw_thumbnail} 
                    onValueChange={() => { this.props.onToggleRawThumbnail(this.props.selected_printhead_config_id)}}
                     onTintColor={colors.switchTint}/>
            </View>;
        }

        var screeningConfig = null;
        if(selectedOutput.show_screening) {

            var items = [];
        
            items.push(<Picker.Item label={t("default")} value="default"></Picker.Item>);

            for(var i = 0; i < this.props.screening_configs.length; i++) {
                items.push(<Picker.Item label={this.props.screening_configs[i].name} value={this.props.screening_configs[i].guid}></Picker.Item>);
            }

            screeningConfig = 
            <View style={{flexDirection: "row", marginTop: 10}}>
                <Text  style={{width: 140}}>{t("screening")}</Text>
                <Picker style={{flex: 1, marginLeft: 10}} 
                    onValueChange={(value) => { this.props.onScreeningChange(this.props.selected_printhead_config_id, value)}} 
                    selectedValue={config.screening_config_guid}>
                    {items}
                </Picker>
            </View>;
        }

        var iccProfilePicker = null;
        if(selectedOutput.show_profile) {
            iccProfilePicker = this.renderICCProfilePicker();
        }
        
        let copiesConfig = null;
        let copiesConfigBottomMargin = null;
        if(selectedOutput.show_copies_config) {
            copiesConfig = 
            <View style={{flexDirection: "column"}}>
                <View style={{flexDirection: "row", marginTop: 10}}>
                    <Text  style={{width: 140}}>{t("copies")}</Text>
                    <TextInput 
                        onChangeText={(value) => { this.props.onCopiesChange(this.props.selected_printhead_config_id, value)}}
                        value={config.copies.toString()} 
                        style={[styles.inputStyle, { width: 88, marginLeft: 10 }]}>
                    </TextInput>
                </View>
                <View style={{flexDirection: "row", marginTop: 10}}>
                    <Text  style={{width: 140}}>{t("collate")}</Text>
                     {/* @ts-ignore*/ }
                    <Switch activeThumbColor={colors.switchThumb} 
                        onTintColor={colors.switchTint} 
                        style={{marginLeft: 5}} 
                        value={selectedOutput.only_collate_is_supported ? true : config.collate} 
                        disabled={selectedOutput.only_collate_is_supported ? true : config.copies == 1} 
                        onValueChange={
                            () => { this.props.onToggleCollate(this.props.selected_printhead_config_id) }
                        }>
                    </Switch>
                </View>
            </View>;
            if(selectedOutput.show_bottom_margin_copy) {
                copiesConfigBottomMargin = 
                <View style={{flexDirection: "row", marginTop: 10}}>
                    <Text  style={{width: 140}}>{`${t("bottom_margin")} (${t("copy")})`}</Text>
                    <TextInput 
                        keyboardType="numeric"
                        onChangeText={(value) => { this.props.onBottomMarginCopyChange(this.props.selected_printhead_config_id, value, this.props.inch)}}
                        value={utils.convertIfInch(config.bottom_margin_copy, this.props.inch) } 
                        style={[styles.inputStyle, { width: 88, marginLeft: 10 }]}>
                    </TextInput>
                </View>
            }
        }
        
        let pages_to_print_config = 
        <ComponentPagesToPrint style={{marginTop: 10}} 
        disabled={this.props.pages_to_print_disabled}
        selected_pages_to_print={config.selected_pages_to_print}
        pages={config.pages}
        onChange={(pages, selected_pages_to_print) => { 
            this.props.onPagesToPrintChange(this.props.selected_printhead_config_id, pages, selected_pages_to_print) 
        }}></ComponentPagesToPrint>;

        let bottomMarginPage;
        if(selectedOutput.show_bottom_margin_page) {
            bottomMarginPage =
            <View style={{flexDirection: "row", marginTop: 10}}>
                <Text  style={{width: 140}}>{`${t("bottom_margin")} (${t("page")})`}</Text>
                <TextInput 
                    keyboardType="numeric"
                    onChangeText={(value) => { this.props.onBottomMarginPageChange(this.props.selected_printhead_config_id, value, this.props.inch)}}
                    value={utils.convertIfInch(config.bottom_margin_page, this.props.inch)} 
                    style={[styles.inputStyle, { width: 88, marginLeft: 10 }]}>
                </TextInput>
            </View>;
        }

        let generateHeadWidthRasterConfig;
        if(selectedOutput.show_always_generate_head_width_raster) {
            generateHeadWidthRasterConfig = <View style={{flexDirection: "row", marginTop: 10}}>
                <Text style={{width: 140}}>{t("always_generate_head_width_raster")}</Text>
                 {/* @ts-ignore*/ }
                <Switch activeThumbColor={colors.switchThumb} 
                    onTintColor={colors.switchTint} 
                    style={{marginLeft: 5}} 
                    value={selectedOutput.always_generate_head_width_raster_enabled ? config.always_generate_head_width_raster : true} 
                    disabled={!selectedOutput.always_generate_head_width_raster_enabled} 
                    onValueChange={
                        () => { this.props.onToggleAlwaysGenerateHeadWidthRaster(this.props.selected_printhead_config_id) }
                    }>
                </Switch>
            </View>
        }

        let intentConfigs; 
        if(selectedOutput.show_intent) {

            intentConfigs =
            <View style={{flexDirection: "row", marginTop: 10}}>
                <Text  style={{width: 140}}>{t("intent")}</Text>
                <Picker style={{flex: 1, marginLeft: 10}} 
                    onValueChange={(value) => { this.props.onIntentChange(this.props.selected_printhead_config_id, parseInt(value))}} 
                    selectedValue={config.intent}>
                    <Picker.Item label={t("source")} value="0"></Picker.Item>
                    <Picker.Item label={t("perceptual")} value="1"></Picker.Item>
                    <Picker.Item label={t("colorimetric_relative")} value="2"></Picker.Item>
                    <Picker.Item label={t("saturation")} value="3"></Picker.Item>
                    <Picker.Item label={t("colorimetric_absolute")} value="4"></Picker.Item>
                </Picker>
            </View>;
        }

        let preserveConfigs;
        if(selectedOutput.show_preserve) {

            preserveConfigs =
            <View style={{flexDirection: "row", marginTop: 10}}>
                <Text  style={{width: 140}}>{t("preserve")}</Text>
                {/* @ts-ignore*/}
                <Picker disabled={config.intent == 0 ||config.intent == 4} style={{flex: 1, marginLeft: 10}} 
                    onValueChange={(value) => { this.props.onPreserveChange(this.props.selected_printhead_config_id, parseInt(value))}} 
                    selectedValue={config.preserve}>
                    <Picker.Item label={t("none")} value="0"></Picker.Item>
                    <Picker.Item label={t("black")} value="1"></Picker.Item>
                    <Picker.Item label={t("black_plane")} value="2"></Picker.Item>
                </Picker>
            </View>;
        }

        let deviceLinkConfigs;
        if(selectedOutput.show_device_links) {

            let device_links_uploading = this.props.device_links_uploading.filter((item) => {
                return config.guid == item.config_guid;
            });
    
            let device_links = [];
            if(config.device_link_ids != undefined) {
                for(let i = 0; i < config.device_link_ids.length; i++) {
                    let dl = this.props.device_links.find((item) => { return item.id == config.device_link_ids[i]; });
                    if(dl != undefined) {
                        device_links.push(dl);
                    }
                }
            }

            deviceLinkConfigs = 
            <div style={{marginTop: 10 }}>
                <DeviceLinks 
                    device_links_uploading={device_links_uploading}
                    device_links={device_links}
                    onUpload={ (files) => { this.props.onDeviceLinkUpload(this.props.selected_printhead_config_id, files) }}
                    onDelete={ (id) => { this.props.onDeviceLinkDelete(id, this.props.selected_printhead_config_id) } }
                    onDownload={ (id) => { this.props.onDeviceLinkDownload(id, this.props.selected_printhead_config_id)} }
                    onMoveUp={ (id) => { this.props.onDeviceLinkMoveUp(id, this.props.selected_printhead_config_id)} }
                    onMoveDown={ (id) => { this.props.onDeviceLinkMoveDown(id, this.props.selected_printhead_config_id)} }
                >
                </DeviceLinks>
            </div>;
        };

        let print_mode_pcl;
        if(selectedOutput.show_print_mode_pcl) {
            print_mode_pcl =
            <View style={{flexDirection: "row", marginTop: 10}}>
                <Text  style={{width: 140}}>{t("print_mode")}</Text>
                {/* @ts-ignore*/}
                <Picker style={{flex: 1, marginLeft: 10}} 
                    onValueChange={(value) => { this.props.onPrintModePCLChange(this.props.selected_printhead_config_id, parseInt(value))}} 
                    selectedValue={config.print_mode_pcl}>
                    <Picker.Item label={t("continuous")} value={appcore.ripe.PrintModePCL.continuous}></Picker.Item>
                    <Picker.Item label={t("single_page")} value={appcore.ripe.PrintModePCL.single_page}></Picker.Item>
                    <Picker.Item label={t("single_page_static")} value={appcore.ripe.PrintModePCL.single_page_static}></Picker.Item>
                </Picker>
            </View>;
        }

        return (
            <View>
                { dpiConfig }
                { iccProfilePicker }
                { useRawThumbnail }
                { screeningConfig }
                { bottomMarginPage }
                { copiesConfigBottomMargin }
                { copiesConfig }
                { pages_to_print_config }
                { generateHeadWidthRasterConfig }
                { intentConfigs }
                { preserveConfigs }
                { print_mode_pcl }
                { deviceLinkConfigs }
            </View>
        );
        
    }

    drawHeadConfig() {

        const { t } = this.props;

        if(this.props.selected_printhead_config_id === -1) {
            return null;
        }

        var config = this.props.configs[this.props.selected_printhead_config_id];
        var printheadBoxes = [];
        var printWidth = 0;

        for(var i = 0; i < config.head_params.length; i++) {

            if(config.head_params[i].enabled) {


                if(config.head_params[i].left_mm < config.total_print_width_mm && config.head_params[i].right_mm <= config.total_print_width_mm) {

                    printWidth += config.head_params[i].print_width_mm;

                    var startPercent = (config.head_params[i].left_mm / config.total_print_width_mm * 100);
                    
                    var boxLeft = 
                    <View style={{ width: 10, height: 20, bottom: -11, position: "absolute", left: startPercent + "%"}}>
                        <Image style={{width: 10, height: 20}} source={{uri: "left.png"}}></Image>
                    </View>

                    printheadBoxes.push(boxLeft);

                    if(i + 1 < config.head_params.length && config.head_params[i+1].enabled) {

                        var linePercent = (printWidth / config.total_print_width_mm * 100);
                        var line = 
                        <View style={{  position: "absolute", marginLeft: -1, left: linePercent + "%", borderLeftColor: "black", borderLeftWidth: 2, borderStyle: "dashed", height: 50, bottom: -26}}></View>
                        printheadBoxes.push(line);
                    }

                    var endPercent = (config.head_params[i].right_mm / config.total_print_width_mm * 100);

                    var textPercent = (config.head_params[i].left_mm + config.head_params[i].right_mm) / config.total_print_width_mm / 2 * 100;

                    var text = 
                    <Text style={{ position: "absolute", left: textPercent + "%", marginLeft: -22, bottom: 0 }}>{`${t("head")} ${( i + 1)}`}</Text>

                    printheadBoxes.push(text);

                    var boxRight = 
                    <View style={{ width: 10, height: 20, bottom: -11, position: "absolute", left: endPercent + "%", marginLeft: -10}}>
                        <Image style={{width: 10, height: 20}} source={{uri: "right.png"}}></Image>
                    </View>

                    printheadBoxes.push(boxRight);
                }
            }
        }

        var mainLine =
        <View style={{borderBottomColor: "black", borderBottomWidth: 2, margin: 60, width: "78%"}}>
            {printheadBoxes}
        </View>
        return mainLine;
    }

    renderButtons() {

        const { t } = this.props;

        var buttons = [];

        if(this.props.selected_printhead_config_id !== -1) {
            buttons.push(<RipeButtonDelete style={[{ marginLeft: 10 }]} timeout={3000}
            onPress={() => { this.props.onDeleteConfig(this.props.selected_printhead_config_id, this.props.configs.length) }}>

            </RipeButtonDelete>);
        }
        
        buttons.push(<RipeButton style={[{marginLeft: 10 }]} icon="content-save" onPress={ () => { this.props.onSaveData() } }>{t("save")}</RipeButton>);

        buttons.push(<RipeButton style={[{marginLeft: 10 }]} icon="plus" onPress={ () => { this.props.onNewConfig(this.props.configs.length) }}>{t("new")}</RipeButton>);

        if(this.props.selected_printhead_config_id !== -1) {
            buttons.push(<RipeButton style={[{marginLeft: 10 }]} icon="content-copy" 
            onPress={ () => { this.props.onCopyConfig(this.props.selected_printhead_config_id, this.props.configs.length) }}>{t("copy")}</RipeButton>);
        }
        
        return buttons;
    }

    renderStitchingPicker() {

        const { t } = this.props;

        if(this.props.selected_printhead_config_id !== -1) {

            var selectedConfig =  this.props.configs[this.props.selected_printhead_config_id];

            if(this.selectedConfigNumberOfHeads() == 1) {
                return null;
            }

            var overlapDotPitch = 
            <View style={{flexDirection: "row"}}>
                    <Text>{t("overlap_dot_pitch")}</Text>
                    <TextInput 
                    value={selectedConfig.overlap_dot_pitch.toString()} 
                    style={[styles.inputStyle, { width: 88, marginLeft: 10 }]} 
                    keyboardType="numeric" 
                    onChangeText={(value) => { this.props.onOverlapDotPitchChange(this.props.selected_printhead_config_id, value)}}>

                    </TextInput>
            </View>

            var buttons = [];

            for(let i = 0; i < this.props.stitching_types.length; i++) {

                var checked = false;
                if(i == selectedConfig.stitching_id) {
                    checked = true;
                }

                var disabled = false;
                if(i == 3 || i == 2) {
                    disabled = true;
                }

                buttons.push(
                    <Button onPress={() => { this.props.onStitchingChange(this.props.selected_printhead_config_id, i)}} contentStyle={{alignItems: "center", justifyContent:"flex-start"}}
                        icon={{uri: this.props.stitching_types[i].icon}} color={ checked ? "#cee9ff" : "#FFFFFF"}
                        mode={ "contained"} style={{marginBottom: 5}}  disabled={disabled}>{t(this.props.stitching_types[i].text_key)}</Button>
                );
            }
            return (
                <View style={{marginLeft: 100}}>
                    <Text style={{marginBottom: 5}}>{t("stitching")}</Text>
                    { buttons }
                    { overlapDotPitch }
                </View>
            );
        }
        return null;
    }

    render() {
        if(!this.props.data_loaded) {
            return null;
        }

        return (
            <View style={[this.props.style, { alignItems:"center" }]}>
                <View style={{flexDirection: "row", justifyContent: "center", alignItems: "center", width: "50%"}}>
                    { this.renderConfigsPicker() }
                    { this.renderButtons() }
                </View>
                { this.drawHeadConfig() }
                <View style={{flexDirection: "row"}}>
                    { this.renderHeadConfigParams() }
                    { this.renderStitchingPicker() }
                </View>
            </View>
        );
    }

}

const styles = StyleSheet.create({
    inputStyle: {
        borderBottomWidth: 2,
        borderBottomColor: "#75757d",
        textAlign: "right"
    },
    button: {
        backgroundColor: "#FFFFFF", padding: 10
    },
    buttonText: {
        alignSelf: "center"
    },
    tableTextStyle: {
        fontSize: 16
    }
  });
  

export default withTranslation()(connector(PrintheadConfigurationPage));