import PDFSplittingPage from '../PDFSplittingPage';
import { connect } from 'react-redux';
import * as pdfToolsActions from '../actions/PDFToolsActions.js';
import { selectInputPdf, selectPrintheadConfig, toggleUnit} from '../actions/SharedActions.js';

const mapStateToProps = (state, ownProps) => {
    return {
        selected_printhead_config_id: state.shared.selected_printhead_config_id,
        selected_pdf_guid: state.shared.selected_pdf_guid,
        ... state.pdf_tools,
        session_id: state.app.session_id,
        inch: state.shared.inch
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      onInputSelect: (guid) => {
        dispatch(selectInputPdf(guid));
      },
      onOutputConfigSelect: (id) => {
        dispatch(selectPrintheadConfig(id));
      },
      onLoadData: () => {
        dispatch(pdfToolsActions.getPDFToolsData());
      },
      onFilterInputChange: (value) => {
        dispatch(pdfToolsActions.filterInputChange(value));
      },
      onInputListSort: (value) => {
        dispatch(pdfToolsActions.inputListSort(value));
      },
      onDownloadInput: (guid) => {
        dispatch(pdfToolsActions.downloadInput(guid));
      },
      onStartDeleteInput: (guid) => {
        dispatch(pdfToolsActions.startDeleteInput(guid));
      },
      onDeleteInput: (guid) => {
        dispatch(pdfToolsActions.deleteInput(guid));
      },
      onUndoDeleteInput: (guid) => {
        dispatch(pdfToolsActions.undoDeleteInput(guid));
      },
      onUploadFileStart: (guid, filename) => {
        dispatch(pdfToolsActions.uploadFileStart(guid, filename));
      },
      onUploadFileProgress: (guid, progress) => {
        dispatch(pdfToolsActions.uploadFileProgress(guid, progress));
      },
      onUploadFileFinish: (guid, status, response, responseText) => {
        dispatch(pdfToolsActions.uploadFileFinish(guid, status, response, responseText));
      },
      onUploadFileErrorDismiss: (guid) => {
        dispatch(pdfToolsActions.uploadFileErrorDismiss(guid));
      },
      onOutputSettingsOverrideChange: (value) => {
        dispatch(pdfToolsActions.outputSettingsOverrideChange(value));
      },
      onInkManagementChange: (value) => {
        dispatch(pdfToolsActions.inkManagementChange(value));
      },
      onImageSizePositionChange: (value) => {
        dispatch(pdfToolsActions.imageSizePositionChange(value));
      },
      onGenerate: (gen_type, filename) => {
        dispatch(pdfToolsActions.generate(gen_type, filename));
      },
      onOutputSelect: (filename) => {
        dispatch(pdfToolsActions.outputSelect(filename));
      },
      onOutputDownload: (filename) => {
        dispatch(pdfToolsActions.outputDownload(filename));
      },
      onOutputDownloadZip: () => {
        dispatch(pdfToolsActions.outputDownloadZip());
      },
      onDeleteOutput: () => {
        dispatch(pdfToolsActions.deleteOutput());
      },
      onToggleUnit: () => {
        dispatch(toggleUnit());
      },
      onThumbnailPageNumberChange: (value) => {
        dispatch(pdfToolsActions.thumbnailPageNumberChange(value));
      },
      onUnmount: () => {
        dispatch(pdfToolsActions.unmount());
      },
      onLabelAdd: (guid_pdf, id_label) => {
        dispatch(pdfToolsActions.labelAdd(guid_pdf, id_label))
      },
      onLabelDelete: (guid_pdf, id_label) => {
        dispatch(pdfToolsActions.labelDelete(guid_pdf, id_label))
      },
      onFilterByLabel: (values) => {
        dispatch(pdfToolsActions.filterByLabel(values))
      },
      onSortInputByChange: () => {
        dispatch(pdfToolsActions.sortInputByChange())
      }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PDFSplittingPage);