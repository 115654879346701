import RIPComputersPage from '../RIPComputersPage';
import { connect } from 'react-redux';
import { getRIPComputers, ripComputersUnmount, deleteRIPComputer, saveRIPComputer, ripComputerNameChange } from '../actions/RIPComputersActions.js';

const mapStateToProps = (state, ownProps) => {
    return {
        ... state.rip_computers,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      onLoadData: () => {
        dispatch(getRIPComputers());
      },
      onUnmount: () => {
        dispatch(ripComputersUnmount());
      },
      onDelete: (id) => {
        dispatch(deleteRIPComputer(id));
      },
      onSave: (id) => {
        dispatch(saveRIPComputer(id));
      },
      onNameChange: (id, value) => {
        dispatch(ripComputerNameChange(id, value));
      }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RIPComputersPage);