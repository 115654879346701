export const selectPrintheadConfig = (id) => ({
    type: 'SELECT_PRINTHEAD_CONFIG',
    id: id
});

export const toggleUnit = () => ({
    type: 'TOGGLE_UNIT'
});

export const selectInputPdf = (guid) => ({
    type: 'SELECT_INPUT_PDF',
    guid: guid
});