import Settings from "./Settings";

import React, { Component } from 'react';
import {
  StyleSheet,
  Text,
  FlatList,
  TouchableOpacity,
  TouchableHighlight,
  ImageBackground,
  Image,
  View,
  TextInput,
  SafeAreaView,
  Picker,
  Switch,
  ScrollView
} from 'react-native';

import Utils from "./Utils";

import Messages from "./Messages";
import { withTranslation } from "react-i18next";

class FeedbackComponent extends Component {

  state = {
    feedbackMessages: []
  }

  componentWillUnmount() {
    Utils.removeHandler(Utils.MessageId.QR_MESSAGE, this.qrMessageHandler);
    Utils.removeHandler(Utils.MessageId.QR_CODE_AUTH_NOTIFICATION, this.qrCodeAuthNotificationHandler);
    Utils.removeHandler(Utils.MessageId.QR_CODE_AUTH_RECONNECT, this.qrCodeAuthReconnectHandler);
  }

  handleQrMessage(data) {

    const { t } = this.props;

    var qrMessage = Messages.QrMessageType.decode(data);
    var feedbackText = null;
    if (qrMessage.session_id === this.props.sessionId) {

      if (qrMessage.identifier == Utils.QrMessageId.GENERATE_STATUS) {
        var result = Messages.GenerateStatusType.decode(qrMessage.data);

        switch (result.status) {
          case 0:
            feedbackText = t("successfully_processed_pdf_file_with_configuration", { 0: result.filename, 1: result.config_name });
            break;
          case 1:
            feedbackText = `${t("error")}: ${t("no_such_pdf")} ${t("please_refresh_app")}`;
            break;
          case 2:
            feedbackText = `${t("error")}: ${t("no_such_config")} ${t("please_refresh_app")}`;
            break;
          case 3:
            feedbackText = `${t("error")}: ${t("heads_not_cover_total_print_width")}`;
            break;
          case 4:
            feedbackText = `${t("error")}: ${t("splitting_pdf_with_larger_width_than_total_print_width_is_not_supported")}`;
            break;
          case 5:
            feedbackText = `${t("error")}: ${t("splitting_pdf_with_smaller_width_than_total_print_width_is_not_supported")}`;
            break;
          case 6:
            feedbackText = `${t("error")}: ${t("config_with_varying_print_widths_is_not_supported")}`;
            break;
          case 7:
            feedbackText = `${t("error")}: ${t("pcl_can_only_be_used_with_one_head_configs")}`;
            break;
          case 8:
            feedbackText = `${t("error")}: ${t("image_dimensions_are_too_large")}`;
            break;
          case 9:
            feedbackText = `${t("error")}: ${t("dpi_x_and_dpi_y_must_be_equal_and_must_be")}`;
            break;
          case 10:
            feedbackText = t("feathering_cannot_be_applied_to_pdf_that_has_mixed_color_space");
            break;
          case 11:
            feedbackText = t("printer_does_not_exist");
            break;
          case 12:
            feedbackText = `${t("failed_to_send_data_to_printer")} ${t('service_is_not_connected_to_server')}`;
            break;
          case 13:
            feedbackText = t("sent_data_to_printer");
            break;
          case 14:
            feedbackText = t("started_to_processing_pdf_file_with_configuration", { 0: result.filename, 1: result.config_name });
            break;
          case 15:
            feedbackText = t("raw_image_generated");
            break;
          case 16:
            feedbackText = t("stream_compression_started");
            break;
          case 17:
            feedbackText = t("stream_compression_finished");
            break;
          case 18:
            feedbackText = `${t("failed_to_process_pdf_file_with_configuration", { 0: result.filename, 1: result.config_name })}: ${t("failed_to_generate_raw_image")}`;
            break;
          case 19:
            feedbackText = `${t("failed_to_process_pdf_file_with_configuration", { 0: result.filename, 1: result.config_name })}: ${t("failed_to_create_interim_stream")}`;
            break;
          case 20:
            feedbackText = `${t("failed_to_process_pdf_file_with_configuration", { 0: result.filename, 1: result.config_name })}: ${t("failed_to_prepare_stream_orientation")}`;
            break;
          case 21:
            feedbackText = `${t("failed_to_process_pdf_file_with_configuration", { 0: result.filename, 1: result.config_name })}: ${t("failed_to_compress_stream")}`;
            break;
          case 22:
            feedbackText = t("interim_stream_file_created");
            break;
          case 23:
            feedbackText = t("stream_orientation_preparation_successful");
            break;
          case 24:
            feedbackText = `${t("failed_to_process_pdf_file_with_configuration", { 0: result.filename, 1: result.config_name })}: ${t("failed_to_generate_stream_file")}`;
            break;
          case 25:
            feedbackText = t("stream_file_successfully_generated");
            break;
          case 26:
            feedbackText = `${t("failed_to_process_pdf_file_with_configuration", { 0: result.filename, 1: result.config_name })}: ${t("unknown_error_occured")}`;
            break;
          case 27:
            feedbackText = t("compressed_stream_file_successfully_generated");
            break;
        }

      } else if (qrMessage.identifier == Utils.QrMessageId.DELETE_INPUT_PDF_RESULT) {
        var delete_result = Messages.DeleteInputPDFResultType.decode(qrMessage.data);

        switch (delete_result.error_code) {
          case 0:
            feedbackText = t("successfully_deleted_pdf_file", { 0: delete_result.filename });
            break;
          case 1:
          case 2:
            feedbackText = `${t("pdf_file_cannot_be_deleted_at_the_moment", { 0: delete_result.filename })} ${t("please_try_again_later")}`;
            break;
        }

      } else if (qrMessage.identifier == Utils.QrMessageId.DELETE_ICC_PROFILE_RESULT) {
        var delete_result = Messages.DeleteICCProfileResultType.decode(qrMessage.data);

        switch (delete_result.error_code) {
          case 0:
            feedbackText = t("successfully_deleted_profile_file", { 0: delete_result.name });
            break;
          case 1:
            feedbackText = `${t("profile_file_does_not_exists")} ${t("please_refresh_app")}`;
            break;
          case 2:
            feedbackText = `${t("profile_file_cannot_be_deleted", { 0: delete_result.name })} ${t("please_try_again_later")}`;
            break;
        }

      } else if (qrMessage.identifier == Utils.QrMessageId.DELETE_PRINTER_RESULT) {

        var delete_printer_result = Messages.DeletePrinterResultType.decode(qrMessage.data);

        switch (delete_printer_result.error_code) {
          case 0:
            feedbackText = t("successfully_deleted_printer", { 0: delete_printer_result.name });
            break;
          case 1:
            feedbackText = `${t("error")}: ${t("printer_does_not_exist")}`;
            break;
        }
      }

    }
    if (feedbackText != null) {
      this.state.feedbackMessages.push(this.createFeedbackMessage(feedbackText));
      this.setState({ feedbackMessages: this.state.feedbackMessages })
      this.scrollToEnd();
    }
  }

  componentWillMount() {
    this.state.feedbackMessages = this.props.feedbackMessages;

    this.qrMessageHandler = this.handleQrMessage.bind(this);
    this.qrCodeAuthNotificationHandler = this.handleQRCodeAuthNotification.bind(this);
    this.qrCodeAuthReconnectHandler = this.handleQRCodeAuthReconnect.bind(this);
    Utils.setHandler(Utils.MessageId.QR_MESSAGE, this.qrMessageHandler);
    Utils.setHandler(Utils.MessageId.QR_CODE_AUTH_NOTIFICATION, this.qrCodeAuthNotificationHandler);
    Utils.setHandler(Utils.MessageId.QR_CODE_AUTH_RECONNECT, this.qrCodeAuthReconnectHandler);
  }

  handleQRCodeAuthNotification(data) {
    const { t } = this.props;

    let qr_code_auth_notification = Messages.QRCodeAuthNotificationType.decode(data);
    if (qr_code_auth_notification.add_session) {

      this.state.feedbackMessages.push(
        this.createFeedbackMessage(t("successfully_logged_in")));
      this.setState({ feedbackMessages: this.state.feedbackMessages })
      this.scrollToEnd();
    }
  }

  handleQRCodeAuthReconnect(data) {

    const { t } = this.props;

    let qr_code_auth_reconnect = Messages.QRCodeAuthReconnectType.decode(data);
    if (qr_code_auth_reconnect.session_id.length > 0) {
      this.state.feedbackMessages.push(this.createFeedbackMessage(t("successfully_logged_out")));
      this.setState({ feedbackMessages: this.state.feedbackMessages })
      this.scrollToEnd();
    }
  }

  createFeedbackMessage(text) {
    return "[" + new Date().toLocaleTimeString() + "] " + text;
  }

  scrollToEnd() {
    this.scrollView.scrollToEnd({ animated: true });
  }

  render() {
    var texts = [];
    for (var i = 0; i < this.state.feedbackMessages.length; i++) {
      texts.push(<Text style={{ fontWeight: "500", marginBottom: 2 }}>{this.state.feedbackMessages[i]}</Text>);
    }

    return (
      <View style={[styles.rootContainerStyle, this.props.style]}>
        <ScrollView ref={(scrollView) => { this.scrollView = scrollView; }} style={{ padding: 10 }}>
          {texts}
        </ScrollView>
      </View>
    );
  }
}
const styles = StyleSheet.create({
  rootContainerStyle: {
    borderTopColor: "#75757d",
    borderTopWidth: 1,
    backgroundColor: "white"
  }
});

export default withTranslation("translation", { withRef: true })(FeedbackComponent);