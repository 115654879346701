import React from 'react';
import { Button } from 'react-native-paper';
import { PropsRipeButton } from './RipeButton'

interface PropsRipeButtonSave extends PropsRipeButton {
    saved: boolean;
    showTime: number;
}

interface StateRipeButtonSave extends PropsRipeButton {
    showIcon: string;
}

let save_progres_icon = "cloud-upload-outline";
let save_icon = "content-save";
let save_finished_icon = "check";

export class RipeButtonSave extends React.Component<PropsRipeButtonSave, StateRipeButtonSave> {

    public constructor(props: PropsRipeButtonSave) {
        super(props);
        this.state = {
            showIcon: save_icon
        }
    }

    render() {
        if (this.state.showIcon == save_progres_icon)
        {
            if (this.props.saved)
            {
                this.setState({showIcon : save_finished_icon});
                setTimeout(() => {
                    this.setState({showIcon: save_icon});
                }, this.props.showTime);
            }
        }
        return (
            <Button 
            style={[{borderColor: "gray", borderWidth: 1}, this.props.style]} labelStyle={this.props.labelStyle} color="#FFFFFF" icon={this.state.showIcon} mode="contained" onPress={() => { 
                if(this.props.onPress !== undefined) {
                    this.setState({showIcon : save_progres_icon});
                    this.props.onPress();
                }
             }}>
                {this.props.children}
             </Button>
        );
    }
}