// src/index.js
import React, { Suspense } from 'react';
import ReactDom from 'react-dom';
import { Provider as PaperProvider } from 'react-native-paper';
import App from './containers/App';
import { Platform } from 'react-native';
import './index.css';

import { Provider } from 'react-redux';
import { createStore, compose, applyMiddleware  } from 'redux';
import rootReducer from './reducers';
import MessageReceiver from "./containers/MessageReceiver";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import './i18n';
import { colors } from "./Styles";

const ripe_theme = createMuiTheme({
  palette: {
    type: "light",
    background: {
      default: "#f5f5f5",
    },
    primary: {
      main: "#00000"
    },
    text: {
      //primary: ""
    }
  },
  typography: {
    h6: {
      fontSize: "1.11rem"
    }
  },
  overrides: {
    /*MuiCheckbox: {
      colorPrimary: {
        color: colors.switchThumb,
        '&$checked': {
          color: colors.switchThumb,
        },
      },
    }*/
  }
});


const asyncDispatchMiddleware = (store) => (next) => (action) => {
  let syncActivityFinished = false;
  let actionQueue = [];

  function flushQueue() {
    actionQueue.forEach((a) => store.dispatch(a)); // flush queue
    actionQueue = [];
  }

  function asyncDispatch(asyncAction) {
    actionQueue = actionQueue.concat([asyncAction]);

    if (syncActivityFinished) {
      flushQueue();
    }
  }

  const actionWithAsyncDispatch =
    Object.assign({}, action, { asyncDispatch });

  const res = next(actionWithAsyncDispatch);

  syncActivityFinished = true;
  flushQueue();

  return res;
};



const store = createStore(rootReducer, compose(
  applyMiddleware(asyncDispatchMiddleware),
  window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
));

ReactDom.render(
<PaperProvider>
	<React.Fragment>
	{Platform.OS === 'web' ? (
      <style type="text/css">{`
        @font-face {
          font-family: 'MaterialCommunityIcons';
          src: url(${require('react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf')}) format('truetype');
        }
      `}</style>
    ) : null}
    <Provider store={store}>
      <MessageReceiver></MessageReceiver>
      <Suspense fallback="Loading...">
        <ThemeProvider theme={ripe_theme}>
		      <App />
        </ThemeProvider>
      </Suspense>
    </Provider>
	</React.Fragment>
</PaperProvider>, 
document.getElementById("root"));
