import React, { Dispatch } from 'react';
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core/styles';
import { connect, ConnectedProps } from 'react-redux';
import { StateRoot } from "../StateRoot";
import { ActionsRIPComputerPrinterMapping, UserPrinter, RIPComputer } from "./ReducerRIPComputerPrinterMapping";
import { withTranslation, WithTranslation } from "react-i18next";
import Typography from '@material-ui/core/Typography';
import SvgIcon from '@material-ui/core/SvgIcon';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import ComponentTable from "./ComponentTable";
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';

const styles = (theme: Theme) => createStyles({ 

});

const mapStateToProps = (state: StateRoot) => ({
  ... state.rip_computer_printer_mapping
})

const mapDispatchToProps = (dispatch: Dispatch<{type: string, data?: string | number | { table_id: string, param_name: string, param_value: string }}>) => 
({
  onLoadData: () => {
    dispatch({ type: ActionsRIPComputerPrinterMapping.LOAD_DATA });
  },
  onUnmount: () => {
    dispatch({ type: ActionsRIPComputerPrinterMapping.UNMOUNT });
  },
  onSelectPrinter: (id: number) => {
    dispatch({ type: ActionsRIPComputerPrinterMapping.SELECT_PRINTER, data: id });
  },
  onSelectRIPPC: (id: number) => {
    dispatch({ type: ActionsRIPComputerPrinterMapping.SELECT_RIP_COMPUTER, data: id });
  },
  onDelete: (id: number) => {
    dispatch({ type: ActionsRIPComputerPrinterMapping.REMOVE, data: id });
  },
  onAdd: (id: number) => {
    dispatch({ type: ActionsRIPComputerPrinterMapping.ADD, data: id });
  },
  onQuickConnectAdd: () => {
    dispatch({ type: ActionsRIPComputerPrinterMapping.QUICK_CONNECT_ADD });
  }
});

interface PropsComponentRIPComputerPrinterMapping {

}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsComponentRIPComputerPrinterMappingWithReduxAndStyles = PropsFromRedux & PropsComponentRIPComputerPrinterMapping & WithStyles<typeof styles> & WithTranslation;

class ComponentRIPComputerPrinterMapping extends React.Component<PropsComponentRIPComputerPrinterMappingWithReduxAndStyles, {}> {

  constructor(props: PropsComponentRIPComputerPrinterMappingWithReduxAndStyles) {
    super(props);
  }

  componentWillMount() {
    this.props.onLoadData();
  }

  componentWillUnmount() {
      this.props.onUnmount();
  }

  renderDeleteButton(id: number) {
    return (
      <IconButton style={{padding: 0}} onClick={() => { this.props.onDelete(id) }}>
        <SvgIcon>
          <path fill="currentColor" d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z" />
        </SvgIcon>
      </IconButton>
    );
  }

  renderAddButton(id: number) {
    return (
    <IconButton style={{padding: 0}} onClick={() => { this.props.onAdd(id) }}>
    <AddIcon></AddIcon>
    </IconButton>
    );
  }

  getSelectedPrinter(): UserPrinter {
    return this.props.user_printers.find((item) => item.selected);
  }

  
  getSelectedRIPComputer(): RIPComputer {
    return this.props.rip_computers.find((item) => item.selected);
  }

  getAssignedRIPComputers(): Array<RIPComputer> {
    
    let printer = this.getSelectedPrinter();
    let rip_computers = this.props.rip_computers.filter((item) => {
      return item.user_printer_ids.findIndex((printer_id) => printer_id == printer.id) != -1;
    });
    return rip_computers;
  }

  getAssignableRIPComputers(): Array<RIPComputer> {
    
    let printer = this.getSelectedPrinter();
    let rip_computers = this.props.rip_computers.filter((item) => {
      return item.user_printer_ids.findIndex((printer_id) => printer_id == printer.id) == -1;
    });
    return rip_computers;
  }

  getAssignedPrinters(): Array<UserPrinter> {
    
    let rip_computer = this.getSelectedRIPComputer();
    let printers = this.props.user_printers.filter((item) => {
      return item.rip_computer_ids.findIndex((rip_computer_id) => rip_computer_id == rip_computer.id) != -1;
    });
    return printers;
  }

  getAssignablePrinters(): Array<UserPrinter> {
    
    let rip_computer = this.getSelectedRIPComputer();
    let printers = this.props.user_printers.filter((item) => {
      return item.rip_computer_ids.findIndex((rip_computer_id) => rip_computer_id == rip_computer.id) == -1;
    });
    return printers;
  }

  renderSelectedItem() {

    const { t } = this.props;

    if(this.props.printer_selected) {
      return (<Typography variant="h6" id="tableTitle" component="div">
      {`${t("printer")}: ${this.getSelectedPrinter().name}`}
      </Typography>);
    } else if (this.props.rip_computer_selected) {
      return (<Typography variant="h6" id="tableTitle" component="div">
      {`${t("rip_computer")}: ${this.getSelectedRIPComputer().name}`}
      </Typography>);
    }
    return null;
  }

  formatDate(date_prm: string) {
    let date = new Date(date_prm);
    console.log(date.toISOString());
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
  }

  renderQuickConnect() {

    const { t } = this.props;
    let rip_computer = this.props.quick_connect.rip_computer;
    let printer = this.props.quick_connect.user_printer;
    
    return (
      <Alert severity="info"
        action={
          <div>

          <IconButton
          style={{marginRight: 10}}
          color="inherit"
          size="small"
          onClick={() => {
            this.props.onQuickConnectAdd()
          }}
        ><DoneIcon fontSize="inherit" />   </IconButton>
          </div>
        }
       >
        {`${t("rip_computer_printer_mapping_quick_connect_info")} 
        RIP Computer: ${rip_computer.name} (${this.formatDate(rip_computer.registration_date)}). 
        Printer: ${printer.name} (${this.formatDate(printer.registration_date)}).`}

      </Alert>);
  }
  
  render() {

    if(!this.props.data_loaded) {
      return null;
    }

    const { t } = this.props;

    return (
      <div style={{ padding: 15, width: "100%" }}>
        { this.props.quick_connect != undefined ? this.renderQuickConnect() : null}
        <div style={{display: "flex", flex: 1, flexDirection: "row", justifyContent: "center"}}>
          <ComponentTable title={t("rip_computers")} rows={this.props.rip_computers} onSelectRow={this.props.onSelectRIPPC.bind(this)}></ComponentTable>
          <div style={{marginLeft: 20}}></div>
          <ComponentTable title={t("printers")} rows={this.props.user_printers} onSelectRow={this.props.onSelectPrinter.bind(this)}></ComponentTable>
        </div>

        <div style={{display: "flex", flex: 1, flexDirection: "column", alignItems: "center",  marginTop: 40}}>
          { this.renderSelectedItem() }
          <div style={{display: "flex", flex: 1, flexDirection: "row", justifyContent: "center", marginTop: 5}}>
          { this.props.printer_selected ? <ComponentTable title={t("assigned_rip_computers")} rows={this.getAssignedRIPComputers()} onSelectRow={() => {}} renderButtons={this.renderDeleteButton.bind(this)}></ComponentTable>: null }
          { this.props.printer_selected ? (<div style={{marginLeft: 20}}></div>) : null }
          { this.props.printer_selected ? <ComponentTable title={t("assignable_rip_computers")} rows={this.getAssignableRIPComputers()} onSelectRow={() => {}} renderButtons={this.renderAddButton.bind(this)}></ComponentTable>: null }
          { this.props.rip_computer_selected ? <ComponentTable title={t("assigned_printers")} rows={this.getAssignedPrinters()} onSelectRow={() => {}} renderButtons={this.renderDeleteButton.bind(this)}></ComponentTable>: null }
          { this.props.rip_computer_selected ? (<div style={{marginLeft: 20}}></div>) : null }
          { this.props.rip_computer_selected ? <ComponentTable title={t("assignable_printers")} rows={this.getAssignablePrinters()} onSelectRow={() => {}} renderButtons={this.renderAddButton.bind(this)}></ComponentTable>: null }
          </div>
        </div>
      </div>

    )
  }
}

let componentRIPComputerPrinterMapping = withTranslation()(withStyles(styles, {withTheme: true})(connector(ComponentRIPComputerPrinterMapping)));
export { componentRIPComputerPrinterMapping as ComponentRIPComputerPrinterMapping };
