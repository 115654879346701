var Settings = {
    debug : {
        "routedSocketioUrl": "http://localhost:4000",
        "backendUrl": "http://localhost:8082",
        "play_store_url": "",
        "apple_store_url" : ""
    },
    release: {
        "routedSocketioUrl": "https://cirrus.printcloud.digital",
        "backendUrl": "https://cirrus.printcloud.digital/backend",
        "play_store_url": "https://play.google.com/store/apps/details?id=systems.ginf.appcore_ripe_client",
        "apple_store_url" : "https://apps.apple.com/us/app/ripe-digital/id1483568720?l=en&ls=1"
    },
    dev: {
        "routedSocketioUrl": "http://dev.ripe.digital",
        "backendUrl": "http://dev.ripe.digital/backend",
        "play_store_url": "",
        "apple_store_url" : ""
    },
    standalone: {
        "routedSocketioUrl": "http://localhost:58420",
        "backendUrl": "http://localhost:58421",
        "play_store_url": "",
        "apple_store_url" : ""
    },
    "environment": "release",
    "version": "cirrus", /* stratus  | cirrus   | altostratus */
    "variant": "quickrip" /* quickrip | variorip | instarip  */
}

Settings.routedSocketioUrl = Settings[Settings.environment].routedSocketioUrl;
Settings.backendUrl = Settings[Settings.environment].backendUrl;



export default Settings;