import React from 'react';
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core/styles';
import { withTranslation, WithTranslation } from "react-i18next";
import { RipeButton } from "../RipeButton";
import { appcore } from '../appcore_ripe_proto';
import Utils from '../Utils';
import { Typography } from '@material-ui/core';
import ComponentPagesToPrint from "../ComponentPagesToPrint/ComponentPagesToPrint";
import Input from '@material-ui/core/Input';
import { Switch } from 'react-native';
import { colors, themes} from "../Styles";

interface PropsComponentPrintControl {
  output_settings_override: appcore.ripe.OutputSettingsOverride;
  can_generate_output: boolean;
  can_clear_output: boolean;
  onGenerate: (generate_type_id: number) => void;
  onDeleteOutput: () => void;
  onOutputSettingsOverrideChange: (value: { pages: string, selected_pages_to_print: string }) => void;
  pages: number;
  outputs: Array<appcore.ripe.Output>;
}

interface StateComponentPrintControl {
  output_settings_override: appcore.ripe.OutputSettingsOverride;
}

const styles = (theme: Theme) => createStyles({ 
  input: {
    textAlign: "right",
    width: 90
  }
});

type PropsComponentPrintControlWithStylesAndTranslation = 
PropsComponentPrintControl & WithStyles<typeof styles> & WithTranslation & { pages_to_print_disabled: boolean };

class ComponentPrintControl extends React.Component<PropsComponentPrintControlWithStylesAndTranslation, StateComponentPrintControl> {

  constructor(props: PropsComponentPrintControlWithStylesAndTranslation) {
    super(props);

    this.state = {
      output_settings_override: appcore.ripe.OutputSettingsOverride.create()
    };
    Object.assign(this.state.output_settings_override, this.props.output_settings_override);
  }

  componentWillReceiveProps(newProps) {
    Object.assign(this.state.output_settings_override, newProps.output_settings_override);
    this.setState(this.state);
  }


  componentWillMount() { }

  componentWillUnmount() { }

  renderGenerateTextHint() {
    const { t } = this.props;

    if (this.props.can_generate_output || this.props.can_clear_output) {

      let text = "";

      if (this.props.output_settings_override.printer_output) {
        text = `${t("press_generate_to_generate_output_files")} ${t("press_send_to_printer_and_send_files_to_printer")} ${t("press_send_to_job_library_and_send_files_to_printers_job_library")}`;
      } else {
        text = t("press_generate_to_generate_output_files");
      }

      return (
        <Typography variant="subtitle2" style={{ marginTop: 10 }}>{text}</Typography>
      );
    }
    return null;
  }

  copiesChange(e: React.ChangeEvent<HTMLInputElement>) {
    let value = (e.currentTarget as HTMLInputElement).value;
    let reg = new RegExp('^[0-9]+$');
    if(reg.test(value)) {
      let new_copies = parseInt(value);
      if(new_copies > 0) {
        this.state.output_settings_override.copies = new_copies;
        this.props.onOutputSettingsOverrideChange(this.state.output_settings_override);
      }
    }
  }

  renderCopiesSettings() {

        const { t } = this.props;

        let selected_output = this.props.outputs.find((value) => { return value.code == this.state.output_settings_override.output_code && value.printer_output == this.state.output_settings_override.printer_output; });
    
        if (this.props.can_generate_output || this.props.can_clear_output) {

          let single_row = !this.props.output_settings_override.printer_output;

          return (
          <div style={{flexDirection: single_row ? "row" : "column", display: "flex", justifyContent: single_row? "flex-start" : "space-evenly"}}>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", marginRight: single_row ? 12 : 0}}>
              <Typography variant="subtitle2" style={{marginRight: 10}}>{t("copies")}</Typography>
              <Input classes={{input: this.props.classes.input}} value={this.state.output_settings_override.copies} onChange={ this.copiesChange.bind(this) }/>
            </div>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
              <Typography variant="subtitle2" style={{marginRight: 10}}>{t("collate")}</Typography>
              {/*@ts-ignore*/}
              <Switch activeThumbColor={colors.switchThumb} 
                      onTintColor={colors.switchTint} 
                      value={selected_output.only_collate_is_supported ? true : this.state.output_settings_override.collate } 
                      disabled={selected_output.only_collate_is_supported ? true : this.state.output_settings_override.copies == 1 || this.props.pages == 1} 
                      onValueChange={
                          () => {
                            this.state.output_settings_override.collate = !this.state.output_settings_override.collate;
                            this.props.onOutputSettingsOverrideChange(this.state.output_settings_override);
                          }
                      }>
                </Switch>
            </div>
          </div>);
        }

        return null;
  }

  renderPagesToPrint() {

    if (this.props.can_generate_output || this.props.can_clear_output) {
      return (
        <div>
          <ComponentPagesToPrint style={{marginTop: 10}} 
            disabled={this.props.pages_to_print_disabled}
            selected_pages_to_print={this.state.output_settings_override.selected_pages_to_print}
            pages={this.state.output_settings_override.pages}
            onChange={(pages, selected_pages_to_print) => { 
              this.state.output_settings_override.pages = pages;
              this.state.output_settings_override.selected_pages_to_print = selected_pages_to_print;
              this.props.onOutputSettingsOverrideChange(this.state.output_settings_override);}}>
          </ComponentPagesToPrint>

        </div>
      );
    }
    return null;
  }

  renderControlButtons() {

    const { t } = this.props;

    let control_buttons = [];

    let button_style= { marginTop: 10, marginRight: 10 };

    if (this.props.can_generate_output) {

      control_buttons.push(
        <RipeButton 
          style={button_style}
          icon="image" 
          onPress={ () => { this.props.onGenerate(Utils.GenerateTypeId.GENERATE_FILE)} }>
            {t("generate")}
        </RipeButton>);

    }
    
    if(this.props.can_clear_output) {

      control_buttons.push(
        <RipeButton 
          style={button_style}
          icon="image" 
          onPress={ () => { this.props.onGenerate(Utils.GenerateTypeId.GENERATE_FILE)} }>
            {t("regenerate")}
        </RipeButton>);
    }

    if ((this.props.can_generate_output || this.props.can_clear_output) && this.props.output_settings_override.printer_output) {

      control_buttons.push(
        <RipeButton 
          style={button_style}
          icon="printer-wireless" 
          onPress={ () => { this.props.onGenerate(Utils.GenerateTypeId.GENERATE_AND_SEND_TO_PRINTER)} }>
            {t("send_to_print")}
        </RipeButton>);

      control_buttons.push(
        <RipeButton 
          style={button_style}
          /* @ts-ignore */
          icon={{ uri: "bookshelf.svg" }} 
          onPress={ () => { this.props.onGenerate(Utils.GenerateTypeId.GENERATE_AND_SEND_TO_JOB_LIB)} }>
            {t("send_to_job_library")}
        </RipeButton>);
    }

    return (
      <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", width: 510 }}>
        {control_buttons}
      </div>
    );
  }

  render() {

    const { t } = this.props;

    return (
      <div>
        <div style={{display: "flex", flexDirection: "row"}}>
        {this.renderControlButtons()}
        {this.renderCopiesSettings()}
        </div>
        {this.renderPagesToPrint()}
        {this.renderGenerateTextHint()}
      </div>
    )
  }
}

export default withTranslation()(withStyles(styles, {withTheme: true})(ComponentPrintControl));