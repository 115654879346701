import { withStyles } from '@material-ui/core';
import { globalStyles, colors, themes} from "./Styles";
import Slider from '@material-ui/core/Slider';
import React, { Component } from 'react';
import {
    Text,
    View,
    TouchableOpacity,
    Image,
    Picker,
    Switch,
    StyleSheet
  } from 'react-native';

import {RipeSlider} from "./RipeSlider";
import { TextInput, IconButton } from "react-native-paper";
import { withTranslation } from "react-i18next";

class CMYKSliders extends Component {

    state = {
        values: {all: 0, C: 0, M: 0, Y: 0, K: 0}
    }

    static defaultProps = {
        min: -100,
        max: 100,
        defaultValue: 0
    }

    constructor(props) {
        super(props);

        Object.assign(this.state.values, props.values);
    }

    componentWillReceiveProps(newProps) {

        
        if(newProps.values === undefined) {
            this.setState({values: {all: 0, C: 0, M: 0, Y: 0, K: 0}});
        } else {
            Object.assign(this.state.values, newProps.values);
            this.setState(this.state);
        }
    }
    
    onValueChange(prop, newValue) {
        let values = this.state.values;
        values[prop] = newValue;
        if(prop == "all") {
            values["C"] = newValue;
            values["M"] = newValue;
            values["Y"] = newValue;
            values["K"] = newValue;
        } else {
            values["all"] =  (values["C"] + values["M"] + values["Y"] + values["K"]) / 4;
        }

        clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(this.timeoutChange.bind(this), 500);

        this.setState(this.state);
    }

    timeoutChange() {
        if(this.props.onChange != undefined) {
            this.props.onChange(this.state.values);
        }
    }

    render() {

        const { t } = this.props;

        return(
            <View style={{flex: 1, marginBottom: 20}}>
                        
                <RipeSlider 
                    stepMinus={this.props.stepMinus}
                    stepPlus={this.props.stepPlus}
                    label={this.props.label} 
                    icon={this.props.icon}
                    iconColor="#75757d"
                    value={this.state.values.all} 
                    min={this.props.min} 
                    max={this.props.max} 
                    onChange={this.onValueChange.bind(this, "all")}>
                </RipeSlider>

                <RipeSlider 
                    stepMinus={this.props.stepMinus}
                    stepPlus={this.props.stepPlus}
                    label={t("cyan").toUpperCase()}
                    icon={this.props.icon}
                    iconColor="#007ea6"
                    value={this.state.values.C} 
                    min={this.props.min} 
                    max={this.props.max} 
                    onChange={this.onValueChange.bind(this, "C")}>
                </RipeSlider>

                <RipeSlider 
                    stepMinus={this.props.stepMinus}
                    stepPlus={this.props.stepPlus}
                    label={t("magenta").toUpperCase()}
                    icon={this.props.icon}
                    iconColor="#b31e7b"
                    value={this.state.values.M} 
                    min={this.props.min} 
                    max={this.props.max} 
                    onChange={this.onValueChange.bind(this, "M")}>
                </RipeSlider>

                <RipeSlider 
                    stepMinus={this.props.stepMinus}
                    stepPlus={this.props.stepPlus} 
                    label={t("yellow").toUpperCase()}
                    icon={this.props.icon}
                    iconColor="#c3b900"
                    value={this.state.values.Y} 
                    min={this.props.min} 
                    max={this.props.max} 
                    onChange={this.onValueChange.bind(this, "Y")}>
                </RipeSlider>

                <RipeSlider 
                    stepMinus={this.props.stepMinus}
                    stepPlus={this.props.stepPlus} 
                    label={t("black").toUpperCase()}
                    icon={this.props.icon}
                    iconColor="#000000"
                    value={this.state.values.K} 
                    min={this.props.min} 
                    max={this.props.max} 
                    onChange={this.onValueChange.bind(this, "K")}>
                </RipeSlider>
            </View>
        );
    }

  }

  
const styles = StyleSheet.create({
    sliderLabelStyle: {
        width: 150,
        alignSelf: "center"
    }
});

export default withTranslation()(CMYKSliders);
