import React from 'react';
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import { withTranslation, WithTranslation } from "react-i18next";
import { colors } from "../Styles";
import ComponentLabel from "../ComponentLabel/ComponentLabel";
import { Label } from "../ComponentSettingsLabel/ReducerSettingsLabel";

interface PropsComponentFilterByLabel {
    labels: Array<Label>;
    selected_labels: Array<number>;
    onSelectLabel: (labels: Array<number>) => void;
    style?: any;
}

interface StateComponentFilterByLabel {
    detailed_view: boolean;
}
  
const styles = (theme: Theme) => createStyles({ 
    form_control: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
        marginTop: -16,
        marginRight: 0,
        marginBottom: 0
    },
    root_checkbox: {
        color: colors.switchThumb,
        "&$checked": {
          color: colors.switchThumb
        }
    },
    checked_checkbox: {}
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null
};

type PropsComponentFilterByLabelWithStylesAndTranslation = 
PropsComponentFilterByLabel & WithStyles<typeof styles> & WithTranslation;

class ComponentFilterByLabel extends React.Component<PropsComponentFilterByLabelWithStylesAndTranslation, StateComponentFilterByLabel> {
    
    ref_labels: Array<React.RefObject<HTMLDivElement>>;
    ref_labels_container: React.RefObject<HTMLDivElement>;

    constructor(props: PropsComponentFilterByLabelWithStylesAndTranslation) {
        super(props);
        this.ref_labels = new Array<React.RefObject<HTMLDivElement>>();
        for(let i = 0; i < props.labels.length; i++) {
            this.ref_labels.push(React.createRef());
        }
        this.ref_labels_container = React.createRef();

        this.state = {
            detailed_view: true
        };
    }

    

    private checkLabelWidths() {
        
        let width_labels = 0;
        for(let i = 0; i < this.ref_labels.length; i++) {
            let ref = this.ref_labels[i];
            if(ref.current != undefined) {
                width_labels += ref.current.offsetWidth;
            }
        }
        
        if(this.ref_labels_container.current != undefined) {
            let div_labels_wider_than_container = width_labels > this.ref_labels_container.current.offsetWidth;
            if(div_labels_wider_than_container) {
                this.setState({detailed_view: false });
            } else {
                this.setState({detailed_view: true });
            }
        }
    }

    private handleChange(event) {

        this.props.onSelectLabel(event.target.value);

        this.setState({detailed_view: true}, () => {
            
            this.checkLabelWidths();

        });
    }

    componentDidMount() {
        this.checkLabelWidths();
    }

    render() {
        
        const { classes, labels, selected_labels, t } = this.props;

        return (
            <FormControl className={classes.form_control} style={this.props.style}>
                <InputLabel  style={{ opacity: selected_labels.length == 0 ? "0.7": "1" }} shrink={false}>{ selected_labels.length == 0 ? t("filter_by_label") : ""}</InputLabel>
                <Select
                disabled={labels.length == 0}
                multiple
                value={selected_labels}
                onChange={this.handleChange.bind(this)}
                input={<Input style={{borderBottom: 0, height: 34}}/>}
                renderValue={(selected) => {
                        let selected_labels = this.props.labels.filter((label) => { 
                            return (selected as Array<number>).indexOf(label.id) != -1;
                        });
                        
                        let labels = selected_labels.map((label, index) => {
                            return (
                            <div ref={this.ref_labels[index]} style={{display: "flex", flexDirection: "row", alignItems: "flex-end"}}>
                                {this.state.detailed_view ? <span style={{marginRight: 2}}>{label.name}</span>: <span></span> }
                                <ComponentLabel color={label.color} text={label.name} style={{width: 21, height: 21}}></ComponentLabel>
                                {index < selected_labels.length - 1 ? <span>{","}&nbsp;</span>: <span></span>}
                            </div>);
                        });

                        return (
                            <div ref={this.ref_labels_container} style={{display: "flex", flexDirection: "row", overflow: "hidden"}}>
                                {labels}
                            </div>
                        );
                    }
                }
                MenuProps={MenuProps}
                >
                {labels.map((label) => (
                    <MenuItem key={label.id} value={label.id}>
                        <Checkbox color="primary" checked={selected_labels.indexOf(label.id) > -1} />
                        <ListItemText primary={label.name} primaryTypographyProps={{style: {overflow: "hidden", textOverflow: "ellipsis"}}}/>
                        <ComponentLabel color={label.color} text={label.name} style={{width: 30, height: 30}}></ComponentLabel>
                    </MenuItem>
                ))}
                </Select>
            </FormControl>
        );

    }
}

export default withTranslation()(withStyles(styles, {withTheme: true})(ComponentFilterByLabel));