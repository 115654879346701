import Messages from "../Messages";
import Utils from "../Utils";

export enum ActionsRIPComputerPrinterMapping {
    LOAD_DATA = "RIP_COMPUTER_PRINTER_MAPPING/LOAD_DATA",
    DATA = "RIP_COMPUTER_PRINTER_MAPPING/DATA",
    UNMOUNT = "RIP_COMPUTER_PRINTER_MAPPING/UNMOUNT",
    SELECT_PRINTER = "RIP_COMPUTER_PRINTER_MAPPING/SELECT_PRINTER", 
    SELECT_RIP_COMPUTER = "RIP_COMPUTER_PRINTER_MAPPING/SELECT_RIP_COMPUTER", 
    ADD = "RIP_COMPUTER_PRINTER_MAPPING/ADD",
    REMOVE = "RIP_COMPUTER_PRINTER_MAPPING/DELETE",
    QUICK_CONNECT_ADD = "RIP_COMPUTER_PRINTER_MAPPING/QUICK_CONNECT_ADD"   
}

export interface UserPrinter {
    id: number;
    name: string;
    registration_date: string;
    rip_computer_ids: Array<number>;
    selected: boolean;
}

export interface RIPComputer {
    id: number;
    name: string;
    registration_date: string;
    user_printer_ids: Array<number>;
    selected: boolean;
}

export interface QuickConnect {
    user_printer: UserPrinter;
    rip_computer: RIPComputer;
}

export interface StateRIPComputerPrinterMapping {
    user_printers: Array<UserPrinter>;
    rip_computers: Array<RIPComputer>;
    data_loaded: boolean;
    printer_selected: boolean;
    rip_computer_selected: boolean;
    quick_connect?: QuickConnect;
}

var initial_state: StateRIPComputerPrinterMapping = {
    user_printers: [],
    rip_computers: [],
    data_loaded: false,
    printer_selected: false,
    rip_computer_selected: false
  };

function selectItem(items_prm: Array<{selected: boolean, id: number}>, id_prm: number): Array<{selected: boolean, id: number}> {
    let new_items = items_prm.map((item) => {
        if(item.id == id_prm) {
            return {
                ...item,
                selected: true
            }
        }
        return {
            ...item,
            selected: false
        }
    });
    return new_items;
}

function getIDsForAddRemove(state: StateRIPComputerPrinterMapping, action_id: number): { user_printer_id: number, rip_computer_id: number} {
    
    if(state.printer_selected) {
        return {
            user_printer_id: state.user_printers.find((item) => { return item.selected}).id,
            rip_computer_id: action_id
        };
    } else {
        return {
            rip_computer_id: state.rip_computers.find((item) => { return item.selected}).id,
            user_printer_id: action_id
        };
    }
}

function getLatestRegistrationDateItemId(items: Array<{ registration_date: string }>): number {
    let registration_date_latest_id = 0;
    for(let i = 1; i < items.length; i++) {
        if(items[registration_date_latest_id].registration_date < items[i].registration_date) {
            registration_date_latest_id = i;
        }
    }
    return registration_date_latest_id;
}

let quick_connect_reg_date_diff_minutes = 15;

export const reducerRipComputerPrinterMapping = (app_state: any, shared_state: any, state = initial_state, action: {type: string, data: string | number | Uint8Array}) => {
    switch(action.type) {
        case ActionsRIPComputerPrinterMapping.LOAD_DATA:
            {
                let qrMessage = Messages.QrMessageType.encode({ session_id: app_state.session_id, identifier: Utils.QrMessageId.GET_RIP_COMPUTER_PRINTER_MAPPING_DATA }).finish();
                Utils.sendMessage(Utils.MessageId.QR_MESSAGE, qrMessage);
                return state;
            }
            case ActionsRIPComputerPrinterMapping.DATA:
            {
                let data = Messages.RIPComputerPrinterMappingDataType.toObject(Messages.RIPComputerPrinterMappingDataType.decode(action.data as Uint8Array), { defaults: true});
                
                let user_printers = data.user_printers_rip_computers;
                let rip_computers = data.rip_computers_user_printers;
                let printer_selected = state.printer_selected;
                let rip_computer_selected = state.rip_computer_selected;

                // Set selected from prev state
                if(printer_selected) {
                    let selected_id = state.user_printers.find((item) => { return item.selected}).id;
                    let user_printer = user_printers.find((item) => { return item.id == selected_id; });
                    if(user_printer != undefined) {
                        user_printer.selected = true;
                    } else {
                        printer_selected = false;
                    }
                }

                if(rip_computer_selected) {
                    let selected_id = state.rip_computers.find((item) => { return item.selected}).id;
                    let rip_computer = rip_computers.find((item) => { return item.id == selected_id; });
                    if(rip_computer != undefined) {
                        rip_computer.selected = true;
                    } else {
                        rip_computer_selected = false;
                    }
                }

                let quick_connect: QuickConnect;
                // Search for quick connect items
                if(rip_computers.length > 0) {

                    let rip_computer_reg_date_latest_id = getLatestRegistrationDateItemId(rip_computers);
                
                    let diff = Date.now() - new Date(rip_computers[rip_computer_reg_date_latest_id].registration_date).getTime();
                    if(diff < 60 * quick_connect_reg_date_diff_minutes * 1000 && rip_computers[rip_computer_reg_date_latest_id].user_printer_ids.length == 0) {

                        if(user_printers.length > 0) {

                            let printer_reg_date_latest_id = getLatestRegistrationDateItemId(user_printers);
                            diff = Date.now() - new Date(user_printers[printer_reg_date_latest_id].registration_date).getTime();
                            if(diff < 60 * quick_connect_reg_date_diff_minutes * 1000 && user_printers[printer_reg_date_latest_id].rip_computer_ids.length == 0) {

                                quick_connect = {
                                    rip_computer: rip_computers[rip_computer_reg_date_latest_id],
                                    user_printer: user_printers[printer_reg_date_latest_id]
                                }
                            }
                        }
                    }
                }
                

                return Object.assign({}, state, {
                    data_loaded: true,
                    user_printers: data.user_printers_rip_computers,
                    rip_computers: data.rip_computers_user_printers,
                    rip_computer_selected: rip_computer_selected,
                    printer_selected: printer_selected,
                    quick_connect: quick_connect
                });
            }
            case ActionsRIPComputerPrinterMapping.UNMOUNT: 
            {
                return Object.assign({}, state, {
                    data_loaded: false,
                    user_printers: [],
                    rip_computers: [],
                    rip_computer_selected: false,
                    printer_selected: false,
                    quick_connect: undefined
                });
            }
            case ActionsRIPComputerPrinterMapping.SELECT_PRINTER: 
            {
                return Object.assign({}, state, {
                    user_printers: selectItem(state.user_printers, action.data as number),
                    rip_computers: selectItem(state.rip_computers, -1),
                    printer_selected: true,
                    rip_computer_selected: false
                });
            }
            case ActionsRIPComputerPrinterMapping.SELECT_RIP_COMPUTER: 
            {
                return Object.assign({}, state, {
                    rip_computers: selectItem(state.rip_computers, action.data as number),
                    user_printers: selectItem(state.user_printers, -1),
                    printer_selected: false,
                    rip_computer_selected: true
                });
            }
            case ActionsRIPComputerPrinterMapping.ADD: 
            {
                let ids = getIDsForAddRemove(state, action.data as number);
                let data = Messages.AddRIPComputerPrinterMappingType.encode(ids).finish();
                let qrMessage = Messages.QrMessageType.encode({ session_id: app_state.session_id, identifier: Utils.QrMessageId.ADD_RIP_COMPUTER_PRINTER_MAPPING, data: data }).finish();
                Utils.sendMessage(Utils.MessageId.QR_MESSAGE, qrMessage);
                return state;
            }
            case ActionsRIPComputerPrinterMapping.REMOVE: 
            {
                let ids = getIDsForAddRemove(state, action.data as number);
                let data = Messages.RemoveRIPComputerPrinterMappingType.encode(ids).finish();
                let qrMessage = Messages.QrMessageType.encode({ session_id: app_state.session_id, identifier: Utils.QrMessageId.REMOVE_RIP_COMPUTER_PRINTER_MAPPING, data: data }).finish();
                Utils.sendMessage(Utils.MessageId.QR_MESSAGE, qrMessage);
                return state;
            }
            case ActionsRIPComputerPrinterMapping.QUICK_CONNECT_ADD:
            {
                let data = Messages.AddRIPComputerPrinterMappingType.encode(
                    { 
                        rip_computer_id: state.quick_connect.rip_computer.id, 
                        user_printer_id: state.quick_connect.user_printer.id
                    }).finish();
                let qrMessage = Messages.QrMessageType.encode({ session_id: app_state.session_id, identifier: Utils.QrMessageId.ADD_RIP_COMPUTER_PRINTER_MAPPING, data: data }).finish();
                Utils.sendMessage(Utils.MessageId.QR_MESSAGE, qrMessage);
                return state;
            }
        default:
            return state;
    }
};