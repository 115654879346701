
import React from 'react';
import { TextStyle, ViewStyle, StyleProp } from 'react-native';
import { Button } from 'react-native-paper';

export interface PropsRipeButton {
    onPress?: () => void;
    style?: StyleProp<ViewStyle>;
    labelStyle?: StyleProp<TextStyle>;
    icon?: string;
    disabled?: boolean;
}

export class RipeButton extends React.Component<PropsRipeButton, {}> {
    render() {
        return (
            <Button disabled={this.props.disabled}
            style={[{borderColor: "gray", borderWidth: 1}, this.props.style]} labelStyle={this.props.labelStyle} color="#FFFFFF" icon={this.props.icon} mode="contained" onPress={() => { 
                if(this.props.onPress !== undefined) {
                    this.props.onPress();
                }
             }}>
                {this.props.children}
             </Button>
        );
    }
}