import React, { Dispatch } from 'react';
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core/styles';
import { connect, ConnectedProps } from 'react-redux';
import { StateRoot } from "../StateRoot";
import { ActionsComponentSettings } from "./ReducerSettings";
import { i18n, supported_languages } from '../i18n';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { withTranslation, WithTranslation } from "react-i18next";
import InputLabel from '@material-ui/core/InputLabel';
import ComponentSettingsLabel from "../ComponentSettingsLabel/ComponentSettingsLabel";

const styles = (theme: Theme) => createStyles({

});

const mapStateToProps = (state: StateRoot) => ({
  ... state.settings
})

const mapDispatchToProps = (dispatch: Dispatch<{type: string, data?: string}>) => 
({

});

interface PropsComponentSettings {

}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsComponentSettingsWithReduxAndStyles = PropsFromRedux & PropsComponentSettings & WithStyles<typeof styles> & WithTranslation;

class ComponentSettings extends React.Component<PropsComponentSettingsWithReduxAndStyles, {}> {

  constructor(props: PropsComponentSettingsWithReduxAndStyles) {
    super(props);
  }

  async handleLanguageChange(e: React.ChangeEvent<{value: string}>) {
    i18n.changeLanguage(e.target.value);
  }

  render() {
    
    let current_lng = i18n.language;
    let menu_items = [];
    supported_languages.forEach((lng) => {
      menu_items.push(<MenuItem value={lng}>{lng}</MenuItem>);
    });

    const { t } = this.props;

    return (
      <div style={{display: "flex", flexDirection: "column", height: "100%", width: "100%", alignItems: "center"}}>
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 50}}>
          <InputLabel id="label_language" style={{marginRight: 50}}>{t("language")}</InputLabel>
          <Select labelId="label_language" id="select" value={current_lng} onChange={this.handleLanguageChange.bind(this)}>
            { menu_items }
          </Select>
        </div>
        <ComponentSettingsLabel></ComponentSettingsLabel>
      </div>
    );
  }
}

export default withTranslation()(withStyles(styles, {withTheme: true})(connector(ComponentSettings)));
