import { Action } from "redux";
import { appcore } from "../appcore_ripe_proto";
import { ActionAsyncDispatch } from "../reducers";

export class ActionGetPrintheadConfigurations extends ActionAsyncDispatch {

    public static getType() {
        return 'GET_PRINTHEAD_CONFIGURATIONS';
    }

    public constructor() {
        super(ActionGetPrintheadConfigurations.getType());
    }
}

export class ActionPrintheadConfigurations extends ActionAsyncDispatch {

    public data: Uint8Array;

    public static getType() {
        return 'PRINTHEAD_CONFIGURATIONS';
    }

    public constructor(data_prm: Uint8Array) {
        super(ActionPrintheadConfigurations.getType());
        this.data = data_prm;
    }
}

export class ActionPrintModePCLChange extends ActionAsyncDispatch {
    
    public id: number;
    public value: appcore.ripe.PrintModePCL;

    public static getType() {
        return 'PRINTHEAD_CONFIGURATIONS/PRINT_MODE_PCL_CHANGE';
    }

    public constructor(id_prm: number, value_prm: appcore.ripe.PrintModePCL) {
        super(ActionPrintModePCLChange.getType());
        this.id = id_prm;
        this.value = value_prm;
    }
}
  
export const newConfig = (size) => ({
    type: 'PRINTHEAD_CONFIGURATIONS_NEW_CONFIG',
    size: size
});

export const savePrintheadConfigurations = () => ({
    type: 'SAVE_PRINTHEAD_CONFIGURATIONS'
});

export const deleteConfig = (id, size) => ({
    type: 'PRINTHEAD_CONFIGURATIONS_DELETE_CONFIG',
    id: id,
    size: size
});

export const copyConfig = (id, size) => ({
    type: 'PRINTHEAD_CONFIGURATIONS_COPY_CONFIG',
    id: id,
    size: size
});

export const toggleHead = (id, head_id) => ({
    type: 'PRINTHEAD_CONFIGURATIONS_TOGGLE_HEAD',
    id: id,
    head_id: head_id
});

export const totalPrintWidthChange = (id, value, inch) => ({
    type: 'PRINTHEAD_CONFIGURATIONS_TOTAL_PRINT_WIDTH_CHANGE',
    id: id,
    value: value,
    inch: inch
});

export const nameChange = (id, value) => ({
    type: 'PRINTHEAD_CONFIGURATIONS_NAME_CHANGE',
    id: id,
    value: value
});

export const leftChange = (id, head_id, value, inch) => ({
    type: 'PRINTHEAD_CONFIGURATIONS_LEFT_CHANGE',
    id: id,
    head_id: head_id,
    value: value,
    inch: inch
});

export const rightChange = (id, head_id, value, inch) => ({
    type: 'PRINTHEAD_CONFIGURATIONS_RIGHT_CHANGE',
    id: id,
    head_id: head_id,
    value: value,
    inch: inch
});

export const outputChange = (id, value) => ({
    type: 'PRINTHEAD_CONFIGURATIONS_OUTPUT_CHANGE',
    id: id,
    value: value
});

export const screeningChange = (id, value) => ({
    type: 'PRINTHEAD_CONFIGURATIONS_SCREENING_CHANGE',
    id: id,
    value: value
});

export const intentChange = (id, value) => ({
    type: 'PRINTHEAD_CONFIGURATIONS_INTENT_CHANGE',
    id: id,
    value: value
});

export const preserveChange = (id, value) => ({
    type: 'PRINTHEAD_CONFIGURATIONS_PRESERVE_CHANGE',
    id: id,
    value: value
});

export const profileChange = (id, value) => ({
    type: 'PRINTHEAD_CONFIGURATIONS_PROFILE_CHANGE',
    id: id,
    value: value
});

export const dpiChange = (id, value) => ({
    type: 'PRINTHEAD_CONFIGURATIONS_DPI_CHANGE',
    id: id,
    value: value
});

export const stitchingChange = (id, value) => ({
    type: 'PRINTHEAD_CONFIGURATIONS_STITCHING_CHANGE',
    id: id,
    value: value
});

export const toggleRawThumbnail = (id) => ({
    type: 'PRINTHEAD_CONFIGURATIONS_TOOGLE_RAW_THUMBNAIL',
    id: id
});

export const overlapDotPitchChange = (id, value) => ({
    type: 'PRINTHEAD_CONFIGURATIONS_OVERLAP_DOT_PITCH_CHANGE',
    id: id,
    value: value
});

export const unmount = () => ({
    type: 'PRINTHEAD_CONFIGURATIONS_UNMOUNT'
});

export const copiesChange = (id, value) => ({
    type: 'PRINTHEAD_CONFIGURATIONS_COPIES_CHANGE',
    id: id,
    value: value
});

export const toggleCollate = (id) => ({
    type: 'PRINTHEAD_CONFIGURATIONS_TOGGLE_COLLATE',
    id: id
});

export const bottomMarginPageChange = (id, value, inch) => ({
    type: 'PRINTHEAD_CONFIGURATIONS_BOTTOM_MARGIN_PAGE_CHANGE',
    id: id,
    value: value,
    inch: inch
});

export const bottomMarginCopyChange = (id, value, inch) => ({
    type: 'PRINTHEAD_CONFIGURATIONS_BOTTOM_MARGIN_COPY_CHANGE',
    id: id,
    value: value,
    inch: inch
});

export const toggleAlwaysGenerateHeadWidthRaster = (id) => ({
    type: 'PRINTHEAD_CONFIGURATIONS_TOGGLE_ALWAYS_GENERATE_HEAD_WIDTH_RASTER',
    id: id
});

export const deviceLinkUpload = (id, files) => ({
    type: 'PRINTHEAD_CONFIGURATIONS/DEVICE_LINK_UPLOAD',
    id: id,
    files: files
});

export const deviceLinkDelete = (id, config_id) => ({
    type: 'PRINTHEAD_CONFIGURATIONS/DEVICE_LINK_DELETE',
    id: id,
    config_id: config_id
});

export const deviceLinkDownload = (id, config_id) => ({
    type: 'PRINTHEAD_CONFIGURATIONS/DEVICE_LINK_DOWNLOAD',
    id: id,
    config_id: config_id
});

export const deviceLinkMoveUp = (id, config_id) => ({
    type: 'PRINTHEAD_CONFIGURATIONS/DEVICE_LINK_MOVE_UP',
    id: id,
    config_id: config_id
});

export const deviceLinkMoveDown = (id, config_id) => ({
    type: 'PRINTHEAD_CONFIGURATIONS/DEVICE_LINK_MOVE_DOWN',
    id: id,
    config_id: config_id
});

export const pagesToPrintChange = (id, pages, selected_pages_to_print) => ({
    type: 'PRINTHEAD_CONFIGURATIONS/PAGES_TO_PRINT',
    id: id,
    pages: pages,
    selected_pages_to_print: selected_pages_to_print
})