import Utils from "../Utils";
import Settings from "../Settings";

import { qrCodeAuthNotification, qrCodeAuthReconnect, qrCodeSessionEnd, ripPCRegistrationData } from '../actions/AppActions';

var messageIdActionMap = { };

messageIdActionMap[Utils.MessageId.QR_CODE_AUTH_NOTIFICATION] = qrCodeAuthNotification;
messageIdActionMap[Utils.MessageId.QR_CODE_AUTH_RECONNECT] = qrCodeAuthReconnect;
messageIdActionMap[Utils.MessageId.QR_CODE_SESSION_END] = qrCodeSessionEnd;
if(Settings.environment == "standalone") {
    messageIdActionMap[Utils.MessageId.RIP_PC_REGISTRATION_DATA] = ripPCRegistrationData;
}


export default messageIdActionMap;