import React, { Dispatch } from 'react';
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core/styles';
import { connect, ConnectedProps } from 'react-redux';
import { StateRoot } from "../StateRoot";
import { ActionsComponentSettingsLabel } from "./ReducerSettingsLabel";
import Input from '@material-ui/core/Input';
import { withTranslation, WithTranslation } from "react-i18next";
import ComponentSettingsLabelTable from "./ComponentSettingsLabelTable";
import { SketchPicker } from 'react-color';
import { Typography } from '@material-ui/core';
import ComponentLabel from "../ComponentLabel/ComponentLabel";
import { RipeButton } from "../RipeButton";

const styles = (theme: Theme) => createStyles({
  input: {
    textAlign: "right",
    width: "100%"
  },
  sketch_picker: {
    fontFamily: "Roboto"
  }
});

const mapStateToProps = (state: StateRoot) => ({
  ... state.settings_label
})

const mapDispatchToProps = (dispatch: Dispatch<{type: string, data?: string | number}>) => 
({
  onAddLabelColorChange: (color_hex: string) => {
    dispatch({ type: ActionsComponentSettingsLabel.LABEL_COLOR_CHANGE, data: color_hex });
  },
  onAddLabelNameChange: (name: string) => {
    dispatch({ type: ActionsComponentSettingsLabel.LABEL_NAME_CHANGE, data: name });
  },
  onAddOrSaveLabel: () => {
    dispatch({ type: ActionsComponentSettingsLabel.ADD_OR_SAVE_LABEL });
  },
  onDeleteLabel: (id: number) => { 
    dispatch({ type: ActionsComponentSettingsLabel.DELETE_LABEL, data: id });
  },
  onEditLabel: (id: number) => {
    dispatch({ type: ActionsComponentSettingsLabel.EDIT_LABEL, data: id });
  },
  onLoadData: () => {
    dispatch({ type: ActionsComponentSettingsLabel.LOAD_DATA });
  },
  onUnmount: () => {
    dispatch({ type: ActionsComponentSettingsLabel.UNMOUNT });
  }
});

interface PropsComponentSettingsLabel {

}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsComponentSettingsLabelWithReduxAndStyles = PropsFromRedux & PropsComponentSettingsLabel & WithStyles<typeof styles> & WithTranslation;

class ComponentSettingsLabel extends React.Component<PropsComponentSettingsLabelWithReduxAndStyles, {}> {

  constructor(props: PropsComponentSettingsLabelWithReduxAndStyles) {
    super(props);
  }

  handleDeleteLabel(id: number) {
    this.props.onDeleteLabel(id);
  }

  handleEditLabel(id: number) {
    this.props.onEditLabel(id);
  }

  handleAddLabelNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    let value = (e.currentTarget as HTMLInputElement).value;
    this.props.onAddLabelNameChange(value);
  }

  componentWillMount() {
    this.props.onLoadData();
  }

  componentWillUnmount() {
      this.props.onUnmount();
  }

  render() {

    if(!this.props.data_loaded) {
      return null;
    }

    const { t, classes } = this.props;

    return (
      <div style={{display: "flex", flexDirection: "row"}}>
        <ComponentSettingsLabelTable rows={this.props.labels} onDelete={this.handleDeleteLabel.bind(this)} onEdit={this.handleEditLabel.bind(this)}></ComponentSettingsLabelTable>
        <div style={{width: 220, marginLeft: 50}}>
          <div style={{display: "flex", flexDirection: "row", alignItems: "center", width: 220, justifyContent: "space-between"}}>
            <Typography>{ this.props.edit ? t("edit_label") : t("add_label")}</Typography>
            <ComponentLabel text={this.props.label_name} color={this.props.label_color} style={{width: 30, height: 30}}></ComponentLabel>
          </div>
          <div style={{display: "flex", flexDirection: "row", alignItems: "center", marginTop: 10, marginBottom: 10}}>
              <Typography variant="subtitle2" style={{marginRight: 10, width: 68}}>{t("name")}</Typography>
              <Input classes={{input: this.props.classes.input}} value={this.props.label_name} onChange={ this.handleAddLabelNameChange.bind(this) }/>
          </div>
          <Typography variant="subtitle2" style={{marginBottom: 10}}>{t("color")}</Typography>
          {/* @ts-ignore */}
          <SketchPicker className={classes.sketch_picker} width={276} color={this.props.label_color}  presetColors={this.props.preset_colors} disableAlpha={true} onChangeComplete={(color) => { this.props.onAddLabelColorChange(color.hex)}}/>
          <RipeButton style={{marginTop: 10, width: 100}} disabled={this.props.label_name == ""} onPress={() => { this.props.onAddOrSaveLabel()}}>{ this.props.edit ? t("save") : t("add")}</RipeButton>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withStyles(styles, {withTheme: true})(connector(ComponentSettingsLabel)));
