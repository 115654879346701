import React from "react";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from "@material-ui/core/IconButton";
import SvgIcon from "@material-ui/core/SvgIcon";
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Divider from "@material-ui/core/Divider";
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core/styles';
import { withTranslation, WithTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import { RipeButton } from "./RipeButton";
import { DeviceLinkUploading } from "./reducers/PrintheadConfigurationsReducer";
import { appcore } from "./appcore_ripe_proto";

const styles = (theme: Theme) => createStyles({
  
    root: {
        backgroundColor: theme.palette.background.paper,
        fontFamily: "inherit"
    },
    list_item_root: {
        paddingLeft: 10,
        paddingRight: 10
    },
    icon_button: {
        padding: 5,
        color: theme.palette.text.primary
    }
});

interface PropsDeviceLinks extends WithStyles<typeof styles> {
    onUpload?: (files: FileList) => void;
    onDelete?: (id: number) => void;
    onDownload?: (id: number) => void;
    onMoveUp?: (id: number) => void;
    onMoveDown?: (id: number) => void;
    device_links_uploading: DeviceLinkUploading[];
    device_links: Array<appcore.ripe.DeviceLink>;
}

class DeviceLinks extends React.Component<PropsDeviceLinks & WithTranslation, {}> {

    public constructor(props: PropsDeviceLinks & WithTranslation) {
        super(props);
        this.file_input_ref = React.createRef<HTMLInputElement>();
    }

    stopPropagation(e: MouseEvent) {
        e.stopPropagation();
    }

    onDownloadPress(id: number, e: MouseEvent) {
        if(this.props.onDownload) {
            this.props.onDownload(id);
        }
    }

    onDeletePress(id: number, e: MouseEvent) {
        if(this.props.onDelete) {
            this.props.onDelete(id);
        }
    }

    onMoveUpPress(id: number, e: MouseEvent) {
        if(this.props.onMoveUp) {
            this.props.onMoveUp(id);
        }
    }

    onMoveDownPress(id: number, e: MouseEvent) {
        if(this.props.onMoveDown) {
            this.props.onMoveDown(id);
        }
    }

    file_input_ref: React.RefObject<HTMLInputElement>;

    profileFilesSelectedForUpload() {

        if(this.props.onUpload && this.file_input_ref.current.files.length > 0) {
            this.props.onUpload(this.file_input_ref.current.files);
        }
  
        this.file_input_ref.current.value = "";
      }

    render() {
        const { classes, t } = this.props;
        let device_links = new Array<{ id: number |Long, name: string, progress: number, uploading: boolean, error: boolean }>();

        for(let i = 0; i < this.props.device_links.length; i++) {
            let dl = this.props.device_links[i];
            device_links.push({ id: dl.id, name: dl.name, progress: 0, uploading: false, error: false });
        }

        if(this.props.device_links_uploading != undefined) {

            for(let i = 0; i < this.props.device_links_uploading.length; i++) {
                let dl = this.props.device_links_uploading[i];
                device_links.push({ id: -1, name: dl.file.name, progress: dl.progress, uploading: true, error: dl.error });
            }
        }

        let list_items = device_links.map((item) => {

            let uploading_text = "";
            if(item.uploading) {
                if(item.error) {
                    uploading_text = ` (${t("upload_error")} ${t("please_try_again_later")})`;
                } else {
                    uploading_text = ` (${t("uploading")} ... ${item.progress}%)`;
                }
                
            }

            return (
                <div className={classes.root}>
                    <ListItem key={item.id.toString()} button classes={{root: classes.list_item_root}}>
                        <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", alignContent: "space-between", width: "100%", alignItems: "center"}}>
                            <ListItemText primary={item.name + uploading_text } />
                            <IconButton disabled={item.uploading} onClick={this.onDownloadPress.bind(this, item.id)} className={classes.icon_button} onMouseDown={this.stopPropagation.bind(this)}>
                                <SvgIcon>
                                    <path fill="currentColor" d="M8,13H10.55V10H13.45V13H16L12,17L8,13M19.35,10.04C21.95,10.22 24,12.36 24,15A5,5 0 0,1 19,20H6A6,6 0 0,1 0,14C0,10.91 2.34,8.36 5.35,8.04C6.6,5.64 9.11,4 12,4C15.64,4 18.67,6.59 19.35,10.04M19,18A3,3 0 0,0 22,15C22,13.45 20.78,12.14 19.22,12.04L17.69,11.93L17.39,10.43C16.88,7.86 14.62,6 12,6C9.94,6 8.08,7.14 7.13,8.97L6.63,9.92L5.56,10.03C3.53,10.24 2,11.95 2,14A4,4 0 0,0 6,18H19Z" />
                                </SvgIcon>
                            </IconButton>
                            <IconButton disabled={item.uploading} onClick={this.onDeletePress.bind(this, item.id)} style={{marginLeft: 6}} className={classes.icon_button} onMouseDown={this.stopPropagation.bind(this)}>
                                <SvgIcon>
                                    <path fill="currentColor" d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z" /> 
                                </SvgIcon>
                            </IconButton>
                            
                            <IconButton disabled={item.uploading} onClick={this.onMoveUpPress.bind(this, item.id)} className={classes.icon_button} onMouseDown={this.stopPropagation.bind(this)}>
                                <KeyboardArrowUpIcon></KeyboardArrowUpIcon>
                            </IconButton>
                            <IconButton disabled={item.uploading} onClick={this.onMoveDownPress.bind(this, item.id)} className={classes.icon_button} onMouseDown={this.stopPropagation.bind(this)}>
                                <KeyboardArrowDownIcon></KeyboardArrowDownIcon>
                            </IconButton>
                        </div>
                    </ListItem>
                    <Divider/>
                </div>
            );
        })

        return (
        <div>
            <Typography style={{marginBottom: 10}} variant="subtitle2">{t("device_link_profiles")}</Typography>
            <List component="nav" style={{flex: 1, zIndex: 1, padding: 0}}>
                { list_items }
            </List>
            <RipeButton onPress={() => { this.file_input_ref.current.click() }} icon="file-upload-outline" style={{marginTop: 10}}>{t("upload_device_link_profiles")}</RipeButton>
            <input ref={this.file_input_ref} type='file' hidden onChange={this.profileFilesSelectedForUpload.bind(this)} multiple accept=".icc,.icm"/>
        </div>
        );
    }
}

export default withTranslation()(withStyles(styles, { withTheme: true })(DeviceLinks));