import React, { Component } from 'react';
import { CollapseView } from "./CollapseView";

import {
    Text,
    View,
    TouchableOpacity,
    Image,
    Picker,
    Switch,
    TextInput as ReactTextInput
  } from 'react-native';

  import { DataTable, TextInput} from "react-native-paper";

  import { styles, colors, themes } from "./Styles";
import Messages from './Messages';
import utils from './Utils';
import { mdiImageFilterTiltShift } from '@mdi/js';
import { withTranslation } from "react-i18next";

class SizeAndPositionControlsView extends Component {

    state = { }

    collapse = true;

    constructor(props) {
        super(props);

        Object.assign(this.state, props.imageSizePosition);
        const size_and_position_collapse_config = JSON.parse(localStorage.getItem('size_and_position_collapse_config'))

        if(size_and_position_collapse_config != null) {
            this.collapse = size_and_position_collapse_config.collapse;
        }
    }

    saveCollapseConfig() {
        localStorage.setItem('size_and_position_collapse_config', JSON.stringify({ collapse: this.collapse }))
    }

    convertUnitValue(value) {
        let ret;
        if(this.props.inch) {
            ret = utils.convertToMM(value);
        } else {
            ret = value;
        }
        return ret;
    }

    offsetUnitChange(value) {
        let offset_unit = this.convertUnitValue(value);
        this.setState({offset_unit: offset_unit}, () => {
            this.onImageSizePositionChange();
        });
    }

    resizeUnitChange(value) {
        let resize_unit = this.convertUnitValue(value);
        this.setState({resize_unit: resize_unit}, () => {
            this.onImageSizePositionChange();
        });
    }

    render() {
        
        const { t } = this.props;

        return (
            <CollapseView headerText={t("size_and_position").toUpperCase()} collapse={this.collapse} onCollapse={ (collapse) => { this.collapse = collapse; this.saveCollapseConfig(); }} renderView={this.renderView.bind(this)}>
                
            </CollapseView>
        );
    }

    offsetUpPress() {
        var y_offset = parseFloat(this.state.y_offset) - parseFloat(this.state.offset_unit);
        this.setState({y_offset: y_offset}, () => {
            this.onImageSizePositionChange();
        });
    }

    offsetDownPress() {
        var y_offset = parseFloat(this.state.y_offset) + parseFloat(this.state.offset_unit);
        this.setState({y_offset: y_offset}, () => {
            this.onImageSizePositionChange();
        });
    }

    offsetLeftPress() {
        var x_offset = parseFloat(this.state.x_offset) - parseFloat(this.state.offset_unit);
        this.setState({x_offset: x_offset}, () => {
            this.onImageSizePositionChange();
        });
    }

    offsetRightPress() {
        var x_offset = parseFloat(this.state.x_offset) + parseFloat(this.state.offset_unit);
        this.setState({x_offset: x_offset}, () => {
            this.onImageSizePositionChange();
        });
    }

    alignLeftPress() {
        this.setState({x_offset: 0}, () => {
            this.onImageSizePositionChange();
        });
    }

    alignCenterPress() {
        var x_offset = (this.props.totalPrintWidth - parseFloat(this.state.width)) / 2;
        this.setState({x_offset: x_offset}, () => {
            this.onImageSizePositionChange();
        });
    }

    alignRightPress() {
        var x_offset = this.props.totalPrintWidth - parseFloat(this.state.width)
        this.setState({x_offset: x_offset}, () => {
            this.onImageSizePositionChange();
        });
    }

    rotationPress(value) {
        var rotation = parseInt(this.state.rotation);
        
        rotation += value;


        if(rotation == -90) {
            rotation = 270;
        }
        if(rotation == 360) {
            rotation = 0;
        }
        if(rotation > 360) {
            rotation = rotation - 360;
        }

        var width = parseFloat(this.state.width);
        var height = parseFloat(this.state.height);
        if(value != 180 && (rotation == 90 || rotation == 270)) {
            var tmp = width;
            width = height;
            height = tmp;
        } else if(value != 180) {
            var tmp = width;
            width = height;
            height = tmp;
        }

        this.setState({rotation: rotation, width: width, height: height}, () => {
            this.onImageSizePositionChange();
        });
    }

    resizePress(value) {

        var width = parseFloat(this.state.width);
        var height = parseFloat(this.state.height);
        width += value;
        if(width < 0) {
            width = 0;
            height = 0;
        } else {
            var percent = (this.state.rotation == 0 || this.state.rotation == 180) ? (width / this.props.origWidth) : (width / this.props.origHeight);
            height =  (this.state.rotation == 0 || this.state.rotation == 180) ? (this.props.origHeight * percent) : (this.props.origWidth * percent);
        }
        this.setState({width: width, height: height}, () => {
            this.onImageSizePositionChange();
        });
    }

    fitWidthPress() {
        var width = this.props.totalPrintWidth;
        var percent = (this.state.rotation == 0 || this.state.rotation == 180) ? (width / this.props.origWidth) : (width / this.props.origHeight);
        var height = (this.state.rotation == 0 || this.state.rotation == 180) ? (this.props.origHeight * percent) : (this.props.origWidth * percent);

        this.setState({width: width, height: height, x_offset: 0}, () => {
            this.onImageSizePositionChange();
        });
    }

    revertToOriginalWidthPress() {

        let width = (this.state.rotation == 0 || this.state.rotation == 180) ? this.props.origWidth : this.props.origHeight;
        let height = (this.state.rotation == 0 || this.state.rotation == 180) ? this.props.origHeight : this.props.origWidth;

        this.setState({width: width, height: height}, () => {
            this.onImageSizePositionChange();
        });
    }

    renderView() {

        const { t } = this.props;

        return (
            <View style={{flexDirection: "row", paddingTop: 10, paddingLeft: 2, paddingRight: 2}}>

                <View style={{marginRight: 50}}>
                    <View style={{alignItems:"center"}}>
                        <View style={{ width: 71, alignItems: "center"}}>
                            <TouchableOpacity onPress={this.offsetUpPress.bind(this)}>
                                <Image style={{width: 20, height: 10}} source={{uri: "up2_c.png"}}></Image>
                            </TouchableOpacity>
                        </View>
                        <View style={{flexDirection: "row", alignItems: "center"}}>
                            <TouchableOpacity onPress={this.offsetLeftPress.bind(this)}>
                                <Image style={{width: 10, height: 20}} source={{uri: "left2_c.png"}}></Image>
                            </TouchableOpacity>
                            <ReactTextInput keyboardType="numeric" style={[styles.inputStyle, {width: 60, textAlign: "center", margin: 8, height: 32}]} value={ this.convertIfInch(this.state.offset_unit, this.props.inch)} onChangeText={this.offsetUnitChange.bind(this)}></ReactTextInput>
                            <TouchableOpacity onPress={this.offsetRightPress.bind(this)}>
                                <Image style={{width: 10, height: 20}} source={{uri: "right2_c.png"}}></Image>
                            </TouchableOpacity>
                        </View>
                        <View style={{ width: 71, alignItems: "center"}}>
                            <TouchableOpacity onPress={this.offsetDownPress.bind(this)}>
                                <Image style={{width: 20, height: 10}} source={{uri: "down2_c.png"}}></Image>
                            </TouchableOpacity>
                        </View>
                    </View>
                    <View style={{flexDirection: "row", justifyContent: "space-between", width: 145, marginTop: 20}}>
                        <TouchableOpacity onPress={this.alignLeftPress.bind(this)}>
                            <Image style={{width: 32, height: 32}} source={{uri: "align_left.png"}}></Image>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={this.alignCenterPress.bind(this)}>
                            <Image style={{width: 32, height: 32}} source={{uri: "align_center.png"}}></Image>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={this.alignRightPress.bind(this)}>
                            <Image style={{width: 32, height: 32}} source={{uri: "align_right.png"}}></Image>
                        </TouchableOpacity>
                    </View>
                    <View style={{flexDirection: "row", justifyContent: "space-between", width: 145, marginTop: 20}}>
                        <TouchableOpacity onPress={this.rotationPress.bind(this, -90)}>
                            <Image style={{width: 33, height: 26}} source={{uri: "rotate_90_left.png"}}></Image>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={this.rotationPress.bind(this, 90)}>
                            <Image style={{width: 32, height: 26}} source={{uri: "rotate_90_right.png"}}></Image>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={this.rotationPress.bind(this, 180)}>
                            <Image style={{width: 32, height: 38}} source={{uri: "rotate_180.png"}}></Image>
                        </TouchableOpacity>
                    </View>
                    <View style={{flexDirection: "row", justifyContent: "space-between", width: 145, marginTop: 20}}>
                        <TouchableOpacity onPress={this.resizePress.bind(this, parseFloat(this.state.resize_unit))}>
                            <Image style={{width: 32, height: 39}} source={{uri: "enlarge.png"}}></Image>
                        </TouchableOpacity>
                        <ReactTextInput keyboardType="numeric" style={[styles.inputStyle, {width: 60, textAlign: "center", margin: 8, height: 32}]} value={this.convertIfInch(this.state.resize_unit, this.props.inch)} onChangeText={this.resizeUnitChange.bind(this)}></ReactTextInput>
                        <TouchableOpacity onPress={this.resizePress.bind(this, -1* parseFloat(this.state.resize_unit))}>
                            <Image style={{width: 32, height: 39}} source={{uri: "shrink.png"}}></Image>
                        </TouchableOpacity>
                    </View>
                    <View style={{flexDirection: "row", justifyContent: "space-evenly", width: 145, marginTop: 20}}>
                        <TouchableOpacity onPress={this.fitWidthPress.bind(this)}>
                            <Image style={{width: 40, height: 39}} source={{uri: "fit.png"}}></Image>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={this.revertToOriginalWidthPress.bind(this)}>
                            <Image style={{width: 40, height: 39}} source={{uri: "no_fit.png"}}></Image>
                        </TouchableOpacity>
                    </View>
                    
                </View>
                <View>
                    <View style={{flexDirection: "row"}}>
                        <Text style={ !this.props.inch ? {fontWeight: "bold"}:{}}>{t("mm")}</Text>
                        <Switch value={this.props.inch} onValueChange={ this.onUnitChange.bind(this) } style={{marginLeft: 5, marginRight: 5}} thumbColor={colors.switchThumb} activeThumbColor={colors.switchThumb} tintColor={colors.switchTint} onTintColor={colors.switchTint}/>
                        <Text style={ this.props.inch ? {fontWeight: "bold"}:{}}>{t("inch")}</Text>
                    </View>
                    <View style={{flexDirection: "row", alignItems: "center", marginTop: 10}}>
                        <TextInput disabled theme={themes.inputTheme} label={t("x_offset")} mode="flat" style={{width: 84}} value={this.convertIfInch(this.state.x_offset, this.props.inch)} onChangeText={this.xOffsetChange.bind(this)}></TextInput>
                        <TextInput disabled theme={themes.inputTheme} label={t("y_offset")} mode="flat" style={{width: 84, marginLeft: 10}} value={this.convertIfInch(this.state.y_offset, this.props.inch)} onChangeText={this.yOffsetChange.bind(this)}></TextInput>
                        <TextInput disabled theme={themes.inputTheme} label={t("rotation")} mode="flat" style={{width: 84, marginLeft: 10}} value={this.state.rotation.toString()} onChangeText={this.rotationChange.bind(this)}></TextInput>
                    </View>
                    <View style={{flexDirection: "row", alignItems: "center", marginTop: 10}}>
                        <TextInput disabled theme={themes.inputTheme} label={t("width")} mode="flat" style={{width: 84}} value={this.convertIfInch(parseFloat(this.state.width).toFixed(2), this.props.inch)} onChangeText={this.widthChange.bind(this)}></TextInput>
                        <TextInput disabled theme={themes.inputTheme} label={t("height")} mode="flat" style={{width: 84, marginLeft: 10}} value={this.convertIfInch(parseFloat(this.state.height).toFixed(2), this.props.inch)} onChangeText={this.heightChange.bind(this)}></TextInput>
                    </View>
                </View>
            </View>
        );
    }

    onUnitChange() {
        this.props.onInchChange();
    }

    convertIfInch(value, inch) {
        if(inch) {
            return  (Math.round(value  / 25.4 * 100) / 100).toString(); 
        }
        return value.toString();
    }

    xOffsetChange(value) {
        this.setState({x_offset: value});
    }

    yOffsetChange(value) {
        this.setState({y_offset: value});
    }

    rotationChange(value) {
        this.setState({rotation: parseInt(value)});
    }

    widthChange(value) {
        this.setState({width: value});
    }

    heightChange(value) {
        this.setState({height: value});
    }

    onImageSizePositionChange() {
        
        if(this.props.onImageSizePositionChange !== undefined) {

            this.props.onImageSizePositionChange(this.state);

            // TODO: move this to redux pdf tools reducer.
            var qrMessage = Messages.QrMessageType.encode({ session_id: this.props.sessionId, identifier: utils.QrMessageId.SAVE_IMAGE_SIZE_POSITION, data: Messages.ImageSizePositionType.encode(this.state).finish() }).finish();            
            utils.sendMessage(utils.MessageId.QR_MESSAGE, qrMessage);
        }
    }

    componentWillReceiveProps(newProps) {
        Object.assign(this.state, { ... newProps.imageSizePosition,  inch: newProps.inch});

        this.setState(this.state);
    }
}

export default withTranslation()(SizeAndPositionControlsView);