import Settings from "./Settings";

import React, { Component } from 'react';
import {
    StyleSheet,
    Text,
    FlatList,
    TouchableOpacity,
    TouchableHighlight,
    ImageBackground,
    Image,
    View,
    TextInput,
    SafeAreaView,
    Picker,
    Switch
} from 'react-native';

import Magnifier from "react-magnifier";

import { withTranslation } from "react-i18next";

import uuidv4 from "uuid/v4";

import Utils from "./Utils";

import Messages from "./Messages";

import { styles, colors, themes } from "./Styles";
import { RipeButton } from "./RipeButton";
import { RipeButtonSave } from "./RipeButtonSave";
import RipeButtonDelete from "./RipeButtonDelete";
import CMYKSliders from "./CMYKSliders";

import { Button, DataTable, IconButton } from "react-native-paper";

import PGMUploadComponent from "./PGMUploadComponent";


class ScreeningConfigurationPage extends Component {

    state = {
        thresholdValues: { all: 128, C: 128, M: 128, Y: 128, K: 128 },
        selectedConfigValue: -1,
        configs: [],
        algos: [],
        selectedAlgo: 1,
        pgm_c: {},
        pgm_m: {},
        pgm_y: {},
        pgm_k: {},
        configSaved: false,
        shownImage: "./screening_sample/cloud_idle.png",
        isPGM: false
    }

    componentWillUnmount() {
        Utils.removeHandler(Utils.MessageId.QR_MESSAGE, this.qrMessageHandler);
    }

    handleQrMessage(data) {
        var qrMessage = Messages.QrMessageType.decode(data);
        if (qrMessage.session_id === this.props.sessionId) {
            if (qrMessage.identifier === Utils.QrMessageId.SCREENING_ALGOS) {
                var screening_algos = Messages.ScreeningAlgosType.decode(qrMessage.data);
                this.setState({ algos: screening_algos.algo });
            }
            else if (qrMessage.identifier === Utils.QrMessageId.SCREENING_CONFIGURATIONS) {
                var selectedConfigValue = this.state.selectedConfigValue;
                var screening_configs = Messages.ScreeningConfigsType.decode(qrMessage.data);
                var configs = screening_configs.config;

                // Restore all value in protobuf.
                for(var i = 0; i < configs.length; i++) {
                    configs[i].all = (configs[i].c + configs[i].m + configs[i].y + configs[i].k) / 4;
                }
                
                if (this.state.selectedConfigValue === -1 && configs.length > 0) {
                    selectedConfigValue = 0;
                    this.state.thresholdValues.all = configs[0].all;
                    this.state.thresholdValues.C = configs[0].c;
                    this.state.thresholdValues.M = configs[0].m;
                    this.state.thresholdValues.Y = configs[0].y;
                    this.state.thresholdValues.K = configs[0].k;
                    this.state.selectedAlgo = configs[0].algorithm;
                    this.state.pgm_c = {name : configs[0].pgm_c_name, filename : configs[0].pgm_c_filename};
                    this.state.pgm_m = {name : configs[0].pgm_m_name, filename : configs[0].pgm_m_filename};
                    this.state.pgm_y = {name : configs[0].pgm_y_name, filename : configs[0].pgm_y_filename};
                    this.state.pgm_k = {name : configs[0].pgm_k_name, filename : configs[0].pgm_k_filename};
                    this.state.isPGM = (configs[0].is_pgm == "0" ? false : true);
                }


                if (this.state.selectedConfigValue >= configs.length) {
                    selectedConfigValue = -1;
                }

                if (selectedConfigValue >= 0) {
                    this.state.pgm_c = {name : configs[selectedConfigValue].pgm_c_name, filename : configs[selectedConfigValue].pgm_c_filename};
                    this.state.pgm_m = {name : configs[selectedConfigValue].pgm_m_name, filename : configs[selectedConfigValue].pgm_m_filename};
                    this.state.pgm_y = {name : configs[selectedConfigValue].pgm_y_name, filename : configs[selectedConfigValue].pgm_y_filename};
                    this.state.pgm_k = {name : configs[selectedConfigValue].pgm_k_name, filename : configs[selectedConfigValue].pgm_k_filename};
                }

                this.setState({ configs: configs, selectedConfigValue: selectedConfigValue, configSaved: true });
            }
            else if (qrMessage.identifier === Utils.QrMessageId.GENERATE_SCREENING_PREVIEW_RESULT) {
                var result = Messages.GenerateScreeningPreviewResultType.decode(qrMessage.data);
                var image_url = Settings.backendUrl + "/" + result.resultUrl + "?session_id=" + encodeURIComponent(this.props.sessionId);
                this.setState({ shownImage: image_url });
            }
        }
    }



    componentWillMount() {
        this.qrMessageHandler = this.handleQrMessage.bind(this);
        Utils.setHandler(Utils.MessageId.QR_MESSAGE, this.qrMessageHandler);
        var qrMessage = Messages.QrMessageType.encode({ session_id: this.props.sessionId, identifier: Utils.QrMessageId.GET_SCREENING_CONFIGURATIONS }).finish();
        Utils.sendMessage(Utils.MessageId.QR_MESSAGE, qrMessage);
        qrMessage = Messages.QrMessageType.encode({ session_id: this.props.sessionId, identifier: Utils.QrMessageId.GET_SCREENING_ALGOS }).finish();
        Utils.sendMessage(Utils.MessageId.QR_MESSAGE, qrMessage);
    }

    constructor() {
        super();
    }

    onPrinterNameChange() { }

    stepMinus(value) {
        return Math.round(value) - 1;
    }

    stepPlus(value) {
        return Math.round(value) + 1;
    }

    thresholdValuesChange(values) {
        
        this.state.thresholdValues.C = values.C;
        this.state.thresholdValues.M = values.M;
        this.state.thresholdValues.Y = values.Y;
        this.state.thresholdValues.K = values.K;
        this.state.thresholdValues.all = values.all;

        this.state.configs[this.state.selectedConfigValue].c = this.state.thresholdValues.C;
        this.state.configs[this.state.selectedConfigValue].m = this.state.thresholdValues.M;
        this.state.configs[this.state.selectedConfigValue].y = this.state.thresholdValues.Y;
        this.state.configs[this.state.selectedConfigValue].k = this.state.thresholdValues.K;
        this.state.configs[this.state.selectedConfigValue].all = this.state.thresholdValues.all;
    }

    selectedConfigChange(itemValue) {

        var config = this.state.configs[itemValue];

        this.state.thresholdValues.C = config.c;
        this.state.thresholdValues.M = config.m;
        this.state.thresholdValues.Y = config.y;
        this.state.thresholdValues.K = config.k;
        this.state.thresholdValues.all = config.all;

        this.state.pgm_c = { name: config.pgm_c_name, filename: config.pgm_c_filename };
        this.state.pgm_m = { name: config.pgm_m_name, filename: config.pgm_m_filename };
        this.state.pgm_y = { name: config.pgm_y_name, filename: config.pgm_y_filename };
        this.state.pgm_k = { name: config.pgm_k_name, filename: config.pgm_k_filename };

        this.state.selectedAlgo = config.algorithm;
        this.state.selectedConfigValue = itemValue;
        this.state.isPGM = config.is_pgm;
        this.setState({selectedConfigValue : itemValue});

    }

    selectedAlgoChange(itemValue) {
        this.state.configs[this.state.selectedConfigValue].algorithm = itemValue;
        this.setState({ selectedAlgo: itemValue });
    }

    isConfigSaved() {
        return this.state.configSaved;
    }

    renderButtons() {

        const { t } = this.props;

        var buttons = [];

        if (this.state.selectedConfigValue !== -1) {
            buttons.push(<RipeButtonDelete style={[{ marginLeft: 10 }]} timeout="3000" onPress={this.deleteConfigPress.bind(this)}></RipeButtonDelete>);
        }

        buttons.push(<RipeButtonSave style={[{ marginLeft: 10 }]} saved={this.state.configSaved} showTime="3000" onPress={this.saveConfigPress.bind(this)}>{t("save")}</RipeButtonSave>);

        buttons.push(<RipeButton style={[{ marginLeft: 10 }]} icon="plus" onPress={this.newConfigPress.bind(this)}>{t("new")}</RipeButton>);

        return buttons;
    }

    deleteConfigPress() {
        if (this.state.configs.length > 0) {
            this.state.configs.splice(this.state.selectedConfigValue, 1);
            if (this.state.selectedConfigValue > 0 && this.state.configs.length > 0) {
                this.state.selectedConfigValue--;
                this.state.thresholdValues.C = this.state.configs[this.state.selectedConfigValue].c;
                this.state.thresholdValues.M = this.state.configs[this.state.selectedConfigValue].m;
                this.state.thresholdValues.Y = this.state.configs[this.state.selectedConfigValue].y;
                this.state.thresholdValues.K = this.state.configs[this.state.selectedConfigValue].k;
                this.setState({ selectedAlgo: this.state.configs[this.state.selectedConfigValue].algorithm });
            } else if (this.state.configs.length == 0) {
                this.state.selectedConfigValue = -1;
            }
            var d = Messages.QrMessageType.encode({
                identifier: Utils.QrMessageId.SAVE_SCREENING_CONFIGURATIONS,
                session_id: this.props.sessionId,
                data: Messages.ScreeningConfigsType.encode({ config: this.state.configs }).finish()
            })
                .finish();
            Utils.sendMessage(Utils.MessageId.QR_MESSAGE, d);
        }
    }

    saveConfigPress() {
        this.setState({ configSaved: false });
        var d = Messages.QrMessageType.encode({
            identifier: Utils.QrMessageId.SAVE_SCREENING_CONFIGURATIONS,
            session_id: this.props.sessionId,
            data: Messages.ScreeningConfigsType.encode({ config: this.state.configs }).finish()
        })
            .finish();
        Utils.sendMessage(Utils.MessageId.QR_MESSAGE, d);

    }

    newConfigPress() {

        const { t } = this.props;

        var newConfig = {
            name: t("new_config"),
            guid: uuidv4(),
            algorithm: 1,
            c: 128,
            m: 128,
            y: 128,
            k: 128,
            is_pgm: 0,
            pgm_c_name: "",
            pgm_m_name: "",
            pgm_y_name: "",
            pgm_k_name: "",
            pgm_c_filename: "",
            pgm_m_filename: "",
            pgm_y_filename: "",
            pgm_k_filename: "",
        };

        var uuidUnique = false;

        do {
            uuidUnique = this.state.configs.find((item) => { return item.guid == newConfig.guid; }) == null;
            if (!uuidUnique) {
                newConfig.guid = uuidv4();
            }
        } while (!uuidUnique);

        this.state.configs.push(newConfig);
        this.state.isPGM = 0;
        this.state.thresholdValues.all = 128;
        this.state.thresholdValues.C = 128;
        this.state.thresholdValues.M = 128;
        this.state.thresholdValues.Y = 128;
        this.state.thresholdValues.K = 128;
        this.state.selectedAlgo = 1;
        this.setState({ selectedConfigValue: this.state.configs.length - 1 });


        this.saveConfigPress();
    }

    onConfigNameChange(value) {
        this.state.configs[this.state.selectedConfigValue].name = value;
        this.setState({ configs: this.state.configs });
    }

    generatePreview() {
        this.setState({ shownImage: "screening_sample/loading.gif" });
        var conf = { c: this.state.thresholdValues.C, m: this.state.thresholdValues.M, y: this.state.thresholdValues.Y, k: this.state.thresholdValues.K, algorithm: this.state.selectedAlgo, is_pgm: this.state.isPGM, guid: this.state.configs[this.state.selectedConfigValue].guid };
        var filename = 'cloud.cbz';

        var d = Messages.QrMessageType.encode({
            identifier: Utils.QrMessageId.GENERATE_SCREENING_PREVIEW,
            session_id: this.props.sessionId,
            data: Messages.GenerateScreeningPreviewType.encode({ config: conf, filename: filename }).finish()
        })
            .finish();
        Utils.sendMessage(Utils.MessageId.QR_MESSAGE, d);

    }

    renderConfigsPicker() {
        var pickerItems = [];
        for (var i = 0; i < this.state.configs.length; i++) {
            pickerItems.push(<Picker.Item label={this.state.configs[i].name} value={i}></Picker.Item>);
        }
        return (
            <Picker selectedValue={this.state.selectedConfigValue} onValueChange={this.selectedConfigChange.bind(this)} style={{ flex: 1 }}>
                {pickerItems}
            </Picker>
        );
    }

    selectedMethodChanged(value) {
        if (this.state.selectedConfigValue == -1) {
            return;
        }
        if (value == false) {
            this.state.configs[this.state.selectedConfigValue].is_pgm = 0;
        }
        else {
            this.state.configs[this.state.selectedConfigValue].is_pgm = 1;
        }
        this.setState({ isPGM: value });
    }

    renderSettingsEditor() {

        const { t } = this.props;

        var pickerAlgoItems = [];
        for (var i = 0; i < this.state.algos.length; i++) {
            pickerAlgoItems.push(<Picker.Item label={this.state.algos[i].name} value={this.state.algos[i].id}></Picker.Item>);
        }



        if (this.state.isPGM == true) {

            var configGuid = this.state.configs[this.state.selectedConfigValue].guid;

            return (
                <View key={configGuid}>
                    <PGMUploadComponent  style={{marginBottom: 10}} title={t("cyan_dither_matrix").toUpperCase()}    item={this.state.pgm_c} sessionId={this.props.sessionId} configGuid={configGuid} color={'c'}></PGMUploadComponent>
                    <PGMUploadComponent  style={{marginBottom: 10}} title={t("magenta_dither_matrix").toUpperCase()} item={this.state.pgm_m} sessionId={this.props.sessionId} configGuid={configGuid} color={'m'}></PGMUploadComponent>
                    <PGMUploadComponent  style={{marginBottom: 10}} title={t("yellow_dither_matrix").toUpperCase()}  item={this.state.pgm_y} sessionId={this.props.sessionId} configGuid={configGuid} color={'y'}></PGMUploadComponent>
                    <PGMUploadComponent                             title={t("black_dither_matrix").toUpperCase()}   item={this.state.pgm_k} sessionId={this.props.sessionId} configGuid={configGuid} color={'k'}></PGMUploadComponent>
                </View>
            );

        }
        else {
            return (
                <View>
                    <Text>{t("select_algorithm")}:</Text>
                    <Picker style={{ marginBottom: 20 }} selectedValue={this.state.selectedAlgo} onValueChange={this.selectedAlgoChange.bind(this)} >
                        {pickerAlgoItems}
                    </Picker>
                    <CMYKSliders onChange={this.thresholdValuesChange.bind(this)} label={t("threshold").toUpperCase()} icon="apps-box" values={this.state.thresholdValues} stepMinus={this.stepMinus.bind(this)} stepPlus={this.stepPlus.bind(this)} min={0} max={255}></CMYKSliders>
                </View>
            );
        }

    }

    renderConfigEditor() {

        const { t } = this.props;

        if (this.state.selectedConfigValue != -1) {

            var config = this.state.configs[this.state.selectedConfigValue];


            return (
                <View style={{ flex: 1, justifyContent: 'center', flexDirection: 'row' }}>


                    <Image style={styles.screeningImageStyle} source={{ uri: "screening_sample/cloud.png" }} />
                    <div style={{position: "relative"}}>
                        <Text style={{zIndex:9, position: "absolute", top:"40%", left: "50%", transform: "translate(-50%, -50%)",
                            fontSize: 26, maxWidth: "300px", textAlign: "center", display: (this.state.shownImage.indexOf("loading") != -1 ? "none": "initial" )}}>
                            {t("press_generate_preview_to_see_result")}
                        </Text>
                        <Magnifier zoomFactor="2" mgShape="square" src={this.state.shownImage} width={500} />
                    </div>
                    <View style={{ alignSelf: 'center', width: 500, marginLeft: 20 }}>
                        <View style={{ flexDirection: "row", justifyContent: "center" }}>
                            <Text style={!this.state.isPGM ? { fontWeight: "bold" } : {}}>{t("error_diffusion")}</Text>
                            <Switch style={{ marginLeft: 5, marginRight: 5, marginBottom: 20 }} value={this.state.isPGM} onValueChange={this.selectedMethodChanged.bind(this)} thumbColor={colors.switchThumb} activeThumbColor={colors.switchThumb} tintColor={colors.switchTint} onTintColor={colors.switchTint} />
                            <Text style={this.state.isPGM ? { fontWeight: "bold" } : {}}>{t("dithering")}</Text>
                        </View>
                        {this.renderSettingsEditor()}
                        <View style={{ flexDirection: "row", marginTop: 10 }}>
                            <Text style={{ width: 90 }}>{`${t("config_name")}:`}</Text>
                            <TextInput onChangeText={this.onConfigNameChange.bind(this)} style={[styles.inputStyle, { textAlign: "left", flex: 1, marginLeft: 10 }]} value={config.name}></TextInput>
                        </View>
                        <RipeButton style={[{ marginTop: 10 }]} icon="file-find" onPress={this.generatePreview.bind(this)}>{t("generate_preview")}</RipeButton>
                    </View>
                </View>
            );


        }
        else {
            return null;
        }
    }

    render() {
        return (
            <View style={{ flex: 1, justifyContent: 'center', alignContent: 'center' }}>
                <View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center", width: "50%", alignSelf: "center" }}>
                    {this.renderConfigsPicker()}
                    {this.renderButtons()}
                </View>
                {this.renderConfigEditor()}
            </View>
        );
    }
}

const itemColor = "#FFFFFF";

const localStyles = StyleSheet.create({
    item: {
        backgroundColor: itemColor,
        height: 46,
        justifyContent: "center",
        marginVertical: 1,
    }
});

export default withTranslation()(ScreeningConfigurationPage);