
export const connectionState = (state, qr_code_data) => ({
    type: 'CONNECTION_STATE',
    state: state,
    qr_code_data: qr_code_data
});

export const qrCodeAuthNotification = (data) => ({
  type: 'QR_CODE_AUTH_NOTIFICATION',
  data: data
});

export const qrCodeAuthReconnect = (data) => ({
  type: 'QR_CODE_AUTH_RECONNECT',
  data: data
});

export const qrCodeSessionEnd = (data) => ({
  type: 'QR_CODE_SESSION_END',
  data: data
});

export const ripPCRegistrationData = (data) => ({
  type: 'RIP_PC_REGISTRATION_DATA',
  data: data
});