import Settings from "./Settings";

import React, { Component } from 'react';
import {
  StyleSheet,
  Text,
  FlatList,
  TouchableOpacity,
  TouchableHighlight,
  ImageBackground,
  Image,
  View,
  TextInput,
  SafeAreaView,
  Picker,
  Switch
} from 'react-native';

import { Button, DataTable } from "react-native-paper";

import { withTranslation } from "react-i18next";

import uuidv4 from "uuid/v4";

import Utils from "./Utils";

import Messages from "./Messages";

import { styles, colors, themes } from "./Styles";
import { RipeButton } from "./RipeButton";
import { RipeButtonSave } from "./RipeButtonSave";
import RipeButtonDelete from "./RipeButtonDelete";

import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import MenuItem from '@material-ui/core/MenuItem';

class PrintersConfigurationPage extends Component {

    state = {
        printers: [],
        server_ips: [],
        default_server_ip: ""
    }

    componentWillUnmount() {    
        Utils.removeHandler(Utils.MessageId.QR_MESSAGE, this.qrMessageHandler);
    }

    handleQrMessage(data)  {
        var qrMessage = Messages.QrMessageType.decode(data);
        if(qrMessage.session_id === this.props.sessionId) {
          if(qrMessage.identifier === Utils.QrMessageId.PRINTERS) {
            var printers = Messages.PrintersType.decode(qrMessage.data);
            this.setState( { printers: printers.printers, server_ips: printers.server_ips, default_server_ip: printers.default_printer_server_ip } );
          } else if(qrMessage.identifier == Utils.QrMessageId.DELETE_PRINTER_RESULT) {
            var deletePrinterResult = Messages.DeletePrinterResultType.decode(qrMessage.data);
            if(deletePrinterResult.error_code == 0) {
                var printerIndex = this.state.printers.findIndex((value) => { return deletePrinterResult.id == value.id; });
                this.state.printers.splice(printerIndex, 1);
                this.setState( { printers: this.state.printers } );
            }
          } else if(qrMessage.identifier == Utils.QrMessageId.SAVE_PRINTER_RESULT) {
            var result = Messages.SavePrinterType.decode(qrMessage.data);
            var printer = this.state.printers.find((value) => { return result.id == value.id; });
            printer.printerSaved = true;
            this.setState( { printers: this.state.printers } ); 
          }
        }
      }
        
    componentWillMount() {
        this.qrMessageHandler = this.handleQrMessage.bind(this);
        Utils.setHandler(Utils.MessageId.QR_MESSAGE, this.qrMessageHandler);
        var qrMessage = Messages.QrMessageType.encode({ session_id: this.props.sessionId, identifier: Utils.QrMessageId.GET_PRINTERS }).finish();
        Utils.sendMessage(Utils.MessageId.QR_MESSAGE, qrMessage);
    }

    constructor () {
        super();
    }

    onPrinterNameChange(printerId, value) {

        var printer = this.state.printers.find((item) => { return item.id === printerId; });
        printer.name = value;
        this.setState({printers: this.state.printers})
    }

    handleDeletePress(printerId) {
        var qrMessageData = Messages.QrMessageType.encode({ identifier: Utils.QrMessageId.DELETE_PRINTER, data: Messages.DeletePrinterType.encode({ id: printerId }).finish(), session_id: this.props.sessionId }).finish();
        Utils.sendMessage(Utils.MessageId.QR_MESSAGE, qrMessageData);
    }

    savePrinterPress(printerId) {

        var printer = this.state.printers.find((item) => { return item.id === printerId; });
        if(printer !== undefined) {
            printer.printerSaved = false;
            this.setState({printers: this.state.printers});

            var d = Messages.QrMessageType.encode({ 
                identifier: Utils.QrMessageId.SAVE_PRINTER, 
                session_id: this.props.sessionId, 
                data:  Messages.SavePrinterType.encode({ id: printer.id, name: printer.name }).finish() })
            .finish();
            Utils.sendMessage(Utils.MessageId.QR_MESSAGE, d);
        }

    }

    render() {
        
        const { t } = this.props;

        var printerRows = [];

        for(var i = 0; i < this.state.printers.length; i++) {
            
            let cells = [];

            cells.push(
            <DataTable.Cell style={{maxWidth: 300}}>
                <View style={{flexDirection: "row"}}>
                    <RipeButtonDelete timeout="3000" onPress={this.handleDeletePress.bind(this, this.state.printers[i].id)}></RipeButtonDelete>
                    <RipeButtonSave showTime={3000} style={{marginLeft: 10}} saved={this.state.printers[i].printerSaved} showTime="3000" onPress={this.savePrinterPress.bind(this, this.state.printers[i].id)}>{t("save")}</RipeButtonSave>
                </View>
            </DataTable.Cell>);

            cells.push(
            <DataTable.Cell>
                <TextInput onChangeText={this.onPrinterNameChange.bind(this, this.state.printers[i].id)} value={this.state.printers[i].name} style={[styles.inputStyle, {textAlign: "left"}]}></TextInput>
            </DataTable.Cell>
            );

            cells.push(
            <DataTable.Cell>
                <Text>{this.state.printers[i].output_name}</Text>
            </DataTable.Cell>
            );

            cells.push(
            <DataTable.Cell>
                <Text>{this.state.printers[i].online ? t("online") : t("offline") }</Text>
            </DataTable.Cell>
            );

            if(Settings.environment == "standalone") {

                let options = [];

                for(let i = 0; i < this.state.server_ips.length; i++) {
                    options.push(<MenuItem style={{fontSize: 12}} value={this.state.server_ips[i]}>{this.state.server_ips[i]}</MenuItem>);
                }

                let printer_ip_in_list = this.state.server_ips.find((ip) => { return ip == this.state.printers[i].server_ip; });
                let picker_style = { fontSize: 12 };
                if(printer_ip_in_list == undefined) {
                    options.push(<MenuItem style={{fontSize: 12, color: "red" }} value={this.state.printers[i].server_ip}>{this.state.printers[i].server_ip}</MenuItem>);
                    picker_style.color = "red";
                }

                cells.push(
                <DataTable.Cell>
                    <Select
                            id={"printer_" + this.state.printers[i].id}
                            style={picker_style}
                            value={this.state.printers[i].server_ip}
                            onChange={this.handlePrinterServerIPChange.bind(this, this.state.printers[i].id)}
                            >
                            {options}
                    </Select>
                </DataTable.Cell>
                );
            }

            printerRows.push(
                <DataTable.Row>
                    {cells}
                </DataTable.Row>
            );
        }

        let header;
        if(Settings.environment == "standalone") {

            let options = [];

            for(let i = 0; i < this.state.server_ips.length; i++) {
                options.push(<MenuItem style={{fontSize: 12}} value={this.state.server_ips[i]}>{this.state.server_ips[i]}</MenuItem>);
            }

            let default_printer_ip_in_list = this.state.server_ips.find((ip) => { return ip == this.state.default_server_ip; });
            let picker_style = { fontSize: 12 };
            if(default_printer_ip_in_list == undefined) {
                options.push(<MenuItem style={{fontSize: 12, color: "red" }} value={this.state.default_server_ip}>{this.state.default_server_ip}</MenuItem>);
                picker_style.color = "red";
            }


            header = 
            <DataTable.Header>
                <DataTable.Title></DataTable.Title>
                <DataTable.Title><Text style={styles.tableTextStyle}>{t("name")}</Text></DataTable.Title>
                <DataTable.Title><Text style={styles.tableTextStyle}>{t("output_format")}</Text></DataTable.Title>
                <DataTable.Title><Text style={styles.tableTextStyle}>{t("status")}</Text></DataTable.Title>
                <DataTable.Title>
                    <View style={{flexDirection: "row"}}>
                        <Text style={[styles.tableTextStyle, {marginRight: 10}]}>{t("default_server_IP")}</Text>
                        <Select
                            style={picker_style}
                            value={this.state.default_server_ip}
                            onChange={this.handleDefaultServerIPChange.bind(this)}
                            >
                            {options}
                        </Select>
                        </View>
                </DataTable.Title>
            </DataTable.Header>;
        } else {
            header = 
            <DataTable.Header>
                <DataTable.Title></DataTable.Title>
                <DataTable.Title><Text style={styles.tableTextStyle}>{t("name")}</Text></DataTable.Title>
                <DataTable.Title><Text style={styles.tableTextStyle}>{t("output_format")}</Text></DataTable.Title>
                <DataTable.Title><Text style={styles.tableTextStyle}>{t("status")}</Text></DataTable.Title>
            </DataTable.Header>;
        }

        return (
            <View style={[this.props.style, { alignItems:"center" }]}>
                <DataTable  style={{maxWidth: (Settings.environment == "standalone" ? 1300: 1200)}}> 
                    { header }
                    { printerRows }
                </DataTable>
            </View>
        );
    }

    handleDefaultServerIPChange(e) {
        this.setState({ default_server_ip: e.target.value});

        var d = Messages.QrMessageType.encode({ 
            identifier: Utils.QrMessageId.SAVE_DEFAULT_PRINTER_SERVER_IP, 
            session_id: this.props.sessionId, 
            data:  Messages.SaveDefaultPrinterServerIPType.encode({ address: e.target.value }).finish() })
        .finish();
        Utils.sendMessage(Utils.MessageId.QR_MESSAGE, d);
    }

    handlePrinterServerIPChange(id, e) {
        let printer = this.state.printers.find((printer) => { return printer.id == id; });
        printer.server_ip = e.target.value;
        this.setState(this.state);

        var d = Messages.QrMessageType.encode({ 
            identifier: Utils.QrMessageId.SAVE_PRINTER_SERVER_IP, 
            session_id: this.props.sessionId, 
            data:  Messages.SavePrinterServerIPType.encode({ id: id, address: e.target.value }).finish() })
        .finish();
        Utils.sendMessage(Utils.MessageId.QR_MESSAGE, d);
    }
}

export default withTranslation()(PrintersConfigurationPage);