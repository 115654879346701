import React from 'react';
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core/styles';
import { withTranslation, WithTranslation } from "react-i18next";
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import { colors } from "../Styles";

const styles = (theme: Theme) => createStyles({ 
    row: {
      '&.Mui-selected, &.Mui-selected:hover': {
        backgroundColor: colors.highlight
      }
    }
  });
  
  interface PropsComponentTable {
    title: string;
    rows: Array<{id: number, name: string, registration_date: string, selected: boolean}>,
    onSelectRow: (number) => void;
    renderButtons?: (id: number) => JSX.Element;
  }
  
  interface StateComponentTable {
    filter_name: string;
    filter_registration_date: string;
    order: "desc" | "asc";
  }
  
  type PropsComponentTableWithStylesAndTranslation = PropsComponentTable & WithStyles<typeof styles> & WithTranslation;
  
  class ComponentTable extends React.Component<PropsComponentTableWithStylesAndTranslation, StateComponentTable> {
  
    constructor(props: PropsComponentTableWithStylesAndTranslation) {
      super(props);
      this.state = {
        filter_name: "",
        filter_registration_date: "",
        order: "desc"
      }
    }
  
      render() {
    
          const { t } = this.props;
      
          return (
            <div>
              <Typography variant="subtitle1" id="tableTitle" component="div">
              {this.props.title}
            </Typography>
            <TableContainer component={Paper} style={{minWidth: 600, maxHeight: 275}}>
            <Table stickyHeader> 
              <TableHead>
                <TableRow>

                    { this.props.renderButtons != undefined ? <TableCell></TableCell> : null }

                  <TableCell>
                    <Input placeholder={t("name")} value={this.state.filter_name} 
                    onChange={ (e) => { this.setState({filter_name: e.currentTarget.value}) } }/>
                    </TableCell>
                  <TableCell align="right">
                  <TableSortLabel
                    onClick={ () => {this.state.order == "asc" ? this.setState({order: "desc"}) : this.setState({order: "asc"})}}
                    active={true}
                    direction={this.state.order}>
                    </TableSortLabel>
                    <Input placeholder={t("registration_date")} 
                    onChange={ (e) => { 
                        this.setState({filter_registration_date: e.currentTarget.value}) 
                    }}/></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.rows.filter(
                    (row) => {
                        let registration_date = new Date(row.registration_date);
                        return row.name.toLowerCase().includes(this.state.filter_name.toLowerCase()) && 
                        `${registration_date.toLocaleDateString()} ${registration_date.toLocaleTimeString()}`.includes(this.state.filter_registration_date)
                    })
                .sort((a,b) => {

                    if(a.registration_date < b.registration_date) {
                        if(this.state.order == "desc") {
                            return 1;
                        } else {
                            return -1;
                        }
                    }
                    if(a.registration_date > b.registration_date) {
                        if(this.state.order == "desc") {
                            return -1;
                        } else {
                            return 1;
                        }
                    }
                    return 0;
                })  
                .map((row) => {
                  
                  let registration_date = new Date(row.registration_date);
      
                  return (
                    <TableRow  hover onClick={() => { this.props.onSelectRow(row.id); }} 
                    selected={row.selected} 
                    classes={{selected: this.props.classes.row}}>
                      { 
                        this.props.renderButtons != undefined ? (<TableCell component="th" scope="row">{this.props.renderButtons(row.id)}</TableCell>) : null 
                      }
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="right">{`${registration_date.toLocaleDateString()} ${registration_date.toLocaleTimeString()}`}</TableCell>
                    </TableRow>
                  )}
                )}
              </TableBody>
            </Table>
          </TableContainer>
          </div>
          )
        }
    }
  
export default withTranslation()(withStyles(styles, {withTheme: true})(ComponentTable));
  