import React, { Component } from 'react';

import { Tabs } from "./Tabs";
import { Tab } from "./Tab";

import { Image, View, StyleSheet, Text, TextInput } from 'react-native';
import { IconButton } from 'react-native-paper';
import { withTranslation } from "react-i18next";

class ThumbnailView extends React.Component {

    state = {
        image_container_width: -1,
        selected_image_tab_id: 0,
        page_number: 1
    }

    constructor(props) {
        super(props);
        this.state.page_number = props.page_number;
        if(props.thumbnail_url != "") {
            Image.getSize(props.thumbnail_url, this.handleThumbnailGetSizeResult.bind(this));
        }

        if(props.live_thumbnail_url != "") {
            Image.getSize(props.live_thumbnail_url, this.handleLiveThumbnailGetSizeResult.bind(this));
        }
    }

    componentWillReceiveProps(newProps) {
        if(newProps.thumbnail_url != this.props.thumbnail_url && newProps.thumbnail_url != "") {
            Image.getSize(newProps.thumbnail_url, this.handleThumbnailGetSizeResult.bind(this));
        }

        if(newProps.live_thumbnail_url != "") {
            Image.getSize(newProps.live_thumbnail_url, this.handleLiveThumbnailGetSizeResult.bind(this));
        }
        this.state.page_number = newProps.page_number;
        this.setState({ page_number: newProps.page_number });
    }

    handleThumbnailGetSizeResult(width, height) {
        this.setState({
            thumbnail_width: width,
            thumbnail_height: height
        });
    }

    handleLiveThumbnailGetSizeResult(width, height) {
        this.setState({
            live_thumbnail_width: width,
            live_thumbnail_height: height
        });
    }

    handleSelectedImageTabChange(id) {
        this.setState({ selected_image_tab_id: id});
    }

    renderImageTabs() {

        const { t } = this.props;

        var tabs = 
        <Tabs selected={this.state.selected_image_tab_id} onSelectedChange={this.handleSelectedImageTabChange.bind(this)} style={{ alignSelf: "center" }}>
          <Tab title={t("preview")} style={{ width: 100 }}></Tab>
          <Tab title={t("live_view")} style={{ width: 100 }}></Tab>
        </Tabs>;
  
        
       if(this.state.image_container_width > 550) {
  
        return (
          <View style={{flexDirection: "row", marginTop: 10, alignItems: "center" }}>
            <View style={{flex: 1}}></View>
            <View style={{ flex: 1 }}>
              { tabs }
            </View>
            <View style={{flex: 1, flexDirection: "row", justifyContent: "flex-end" }}>
              {this.renderPager()}
            </View>
          </View>
        );
       } else {
        return (
          <View style={{flexDirection: "columns", marginTop: 10 }}>
            <View style={{ alignSelf: "center" }}>
              { tabs }
            </View>
            <View style={{alignSelf: "center"}}>
              {this.renderPager()}
            </View>
          </View>
        );
       }
    }

    render() {
        return (<View style={{flex: 1, minWidth: 200}} onLayout={(event) => { 
            var {x, y, width, height} = event.nativeEvent.layout;
            if(this.state.image_container_width == -1 || (this.state.image_container_width !== -1 && Math.abs(this.state.image_container_width - width) > 50)) {
              this.setState({image_container_width: width});
            }
          }}>

          

          { this.renderImageTabs() }
          
          { this.renderImage() }
          <View style={{alignSelf: "center"}}>
          { this.renderPager() }
          </View>
          
          
        </View>);
    }

    onRequestedPageNumberChanged(requested)
    {
        this.setState({page_number : parseInt(requested)});
    }

    onRequestedPageNumberSubmit()
    {
        if (this.state.page_number < 1 || this.state.page_number > this.props.input.num_of_pages) 
        {
            this.setState({page_number: this.props.page_number});
            return;
        }
        this.props.onPageNumberChange(this.state.page_number);
    }

    actualPageNumberChange(num)
    {
        var curr = this.props.page_number;

        if (curr + num < 1) return;
        if (curr + num > this.props.input.num_of_pages) return;

        this.setState({ page_number : curr + num });

        this.props.onPageNumberChange(curr + num);
    }

    renderPager() {

       const { t } = this.props;

        if(this.props.input.num_of_pages == 1) {
        return null;
        }

        return (
        <View style={{ flexDirection: "row"}}>
            <IconButton icon="chevron-left" style={{ margin: 0 }} size={24} onPress={this.actualPageNumberChange.bind(this, -1)} />
            <Text style={{ paddingTop: 8 }}>{`${t("page")} `}</Text>
            <TextInput style={{width:30, textAlign: "center"}} keyboardType="numeric" value={ this.state.page_number } onChangeText={text => this.onRequestedPageNumberChanged(text)} onSubmitEditing={this.onRequestedPageNumberSubmit.bind(this)}></TextInput>
            <Text style={{ paddingTop: 8 }}>{ `${t("of")} ${this.props.input.num_of_pages}`} </Text>
            <IconButton icon="chevron-right" style={{ margin: 0 }} size={24} onPress={this.actualPageNumberChange.bind(this, 1)} />

        </View>
        );
    }

    renderImage() {
        var imageContainerChild = null;
        
        if(this.state.image_container_width !== -1) {

            var views = [];
            var imageWidth;
            var boxHeight;
            var imageHeight;
            var boxWidth = this.state.image_container_width;

            if(this.state.selectedConfigValue == -1 || this.state.selectedItemId == "") {
                return (<View style={{width: "404px", margin: 10}}></View>);
            }

            var config = this.props.config;
            
            for(var i = 0; i < config.head_params.length; i++) {
            if(config.head_params[i].enabled && i + 1 < config.head_params.length && config.head_params[i + 1].enabled  && config.head_params[i].left_mm < config.total_print_width_mm && config.head_params[i].right_mm <= config.total_print_width_mm) {
                var printWidth = config.head_params[i].print_width_mm;
                var percent = (printWidth / config.total_print_width_mm * 100);
                views.push(<View style={[styles.imageOverlayArea, { width: percent + "%" }]}></View>);
            }
            }

            views.push(<View style={styles.imageOverlayAreaLast}></View>);
            
            var item = this.props.input;
            var resizeFactor = this.getImageSizePositionResizeFactor(item);

            var oneMMToPix = boxWidth / config.total_print_width_mm;

            imageWidth =  this.getImageSizePositionWidth(resizeFactor, item, config, boxWidth);
            imageHeight = this.getImageSizePositionHeight(resizeFactor, item, config, boxWidth, 
                this.state.selected_image_tab_id == 0 ? this.state.thumbnail_width : this.state.live_thumbnail_width,
                this.state.selected_image_tab_id == 0 ? this.state.thumbnail_height : this.state.live_thumbnail_height);

            boxHeight = imageHeight;

            var xOffset = (Math.abs(this.props.image_size_position.x_offset * oneMMToPix) / boxWidth) * 100;
            if(this.props.image_size_position.x_offset < 0) {
            xOffset *= -1;
            }

            boxHeight += this.props.image_size_position.y_offset * oneMMToPix;
            var yOffset  = Math.abs(this.props.image_size_position.y_offset * oneMMToPix) / boxHeight * 100;
            if(this.props.image_size_position.y_offset < 0) {
            yOffset *= -1;
            }
            
            var image = null;
            var url = this.state.selected_image_tab_id == 0 ? this.props.thumbnail_url : this.props.live_thumbnail_url;

            if(url === "") {
                image =  <Image style={{ width: imageWidth, height: imageHeight + "px", resizeMode: "contain" }} source={{uri: "loading.gif" }}></Image>;
            } else {
                image =  <Image style={{ width: imageWidth, height: imageHeight + "px", resizeMode: "stretch" }} source={{uri: url }}></Image>;
            }

            imageContainerChild = 
            <View style={{flex: 1}}>
                <View style={{position: "absolute", width: "100%", left: xOffset + "%", top: yOffset + "%", height: "100%" }}>
                    {image}
                </View>
                <View style={{flexDirection: "row", flex: 1}}>
                    {views}
                </View>
            </View>;
        }

        return (
        <View style={{ 
            borderWidth: "2px", 
            borderColor: "#757575", 
            margin: 10, 
            width: boxWidth + 4 + "px",
            height: boxHeight + "px",
            overflow: "hidden",
            alignSelf: "center"
            }} 
            >
            { imageContainerChild }
        </View>
        );
    }

    getImageSizePositionWidth(resizeFactor, sourcePdf, config, boxWidth) {
        if(this.props.image_size_position.rotation == 0 || this.props.image_size_position.rotation == 180) {
          return (sourcePdf.width_mm / config.total_print_width_mm * 100)  * resizeFactor + "%";
        } else {
          return (sourcePdf.height_mm / config.total_print_width_mm * 100)  * resizeFactor + "%";
        }
      }

      getImageSizePositionHeight(resizeFactor, sourcePdf, config, boxWidth, thumbnail_width, thumbnail_height) {
        if(this.props.image_size_position.rotation == 0 || this.props.image_size_position.rotation == 180) {
          return (thumbnail_height * sourcePdf.width_mm / config.total_print_width_mm  * boxWidth / thumbnail_width) * resizeFactor;
        } else {
          return (thumbnail_height * sourcePdf.height_mm / config.total_print_width_mm * boxWidth / thumbnail_width) * resizeFactor;
        }
      }

      getImageSizePositionResizeFactor(item) {
        if(this.props.image_size_position.rotation == 0 || this.props.image_size_position.rotation == 180) {
          return this.props.image_size_position.width / item.width_mm;
        } else {
          return this.props.image_size_position.height / item.width_mm;
        }
      }
}

const styles = StyleSheet.create({
    imageOverlayArea: {
        borderRightWidth: 2, borderColor: "#757575", flex: 1, borderStyle: "dashed"
    },
    imageOverlayAreaLast: {
        flex: 1, height: "100%"
    },
});

export default withTranslation()(ThumbnailView);