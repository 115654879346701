import React, { Component } from 'react';
import { Text, View, TextInput  } from 'react-native';
import { DataTable } from "react-native-paper";
import { styles, colors } from "./Styles";
import RipeButtonDelete from "./RipeButtonDelete";
import { RipeButtonSave } from "./RipeButtonSave";
import { Checkbox } from 'react-native-paper';
import TextField from '@material-ui/core/TextField';
import moment from "moment";
import { withTranslation } from "react-i18next";

class RIPComputersPage extends Component {

    componentWillUnmount() {    
        this.props.onUnmount();
    }
        
    componentWillMount() {
        this.props.onLoadData();
    }

    handleDeletePress(id) {
        this.props.onDelete(id);
    }

    handleSavePress(id) {
        this.props.onSave(id);
    }

    handleRIPPCNameChange(id, value) {
        this.props.onNameChange(id, value);
    }

    render() {

        const { t } = this.props;

        if(!this.props.data_loaded) {
            return null;
        }
        
        var rows = [];

       

        for(var i = 0; i < this.props.rip_computers.length; i++) {
            let rip_computer = this.props.rip_computers[i];
            let expiration_date = "";
            if(rip_computer.has_license) {
                if(rip_computer.license_expiration != 0) {
                    expiration_date = moment(rip_computer.license_expiration * 1000).format("YYYY-MM-DDTHH:mm");
                }
            }

            rows.push(
                <DataTable.Row>
                    <DataTable.Cell style={{maxWidth: 300}}>
                        <View style={{flexDirection: "row"}}>
                            <RipeButtonDelete timeout="3000" onPress={this.handleDeletePress.bind(this, this.props.rip_computers[i].id)}></RipeButtonDelete>
                            <RipeButtonSave showTime={3000} style={{marginLeft: 10}} saved={this.props.rip_computers[i].saved} showTime="3000" onPress={this.handleSavePress.bind(this, this.props.rip_computers[i].id)}>{t("save")}</RipeButtonSave>
                        </View>
                    </DataTable.Cell>
                    <DataTable.Cell>
                        <TextInput onChangeText={this.handleRIPPCNameChange.bind(this, this.props.rip_computers[i].id)} value={this.props.rip_computers[i].name} style={[styles.inputStyle, {textAlign: "left"}]}></TextInput>
                    </DataTable.Cell>


                    <DataTable.Cell>
                        <Checkbox
                            disabled
                            color={colors.checkbox} 
                            status={this.props.rip_computers[i].has_license ? 'checked' : 'unchecked'}/>
                        </DataTable.Cell>
                        <DataTable.Cell>
                            <form  noValidate>
                                <TextField
                                    disabled
                                    id="datetime-local"
                                    type="datetime-local"
                                    value={expiration_date}
                                    InputLabelProps={{
                                    shrink: true
                                    }}
                                />
                            </form>
                        </DataTable.Cell>
                </DataTable.Row>
            );
        }

        return (
            <View style={[this.props.style, { alignItems:"center" }]}>
                <DataTable  style={{maxWidth: 1100}}> 
                    <DataTable.Header>
                        <DataTable.Title></DataTable.Title>
                        <DataTable.Title><Text style={styles.tableTextStyle}>{t("name")}</Text></DataTable.Title>
                        <DataTable.Title><Text>{t("has_license")}</Text></DataTable.Title>
                        <DataTable.Title><Text>{t("license_expiration")}</Text></DataTable.Title>
                    </DataTable.Header>
                    { rows }
                </DataTable>
            </View>
        );
    }
}

export default withTranslation()(RIPComputersPage);