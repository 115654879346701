import {
    StyleSheet
  } from 'react-native';

const primaryColor = "#cee9ff";
const primaryColorDarker = "#005aa4";

export const colors = {
    containerBackground: "#F5F5F5",
    highlight: primaryColor,
    switchThumb: primaryColorDarker,
    switchTint: primaryColor,
    checkbox: primaryColorDarker,
    headerBackground: "#FFFFFF",
    headerShadow: "#757575",
    item: "#FFFFFF",
    button: "#FFFFFF"
}

export const styles = StyleSheet.create({
    inputStyle: {
        borderBottomWidth: 2,
        borderBottomColor: "#c9c9c9",
        textAlign: "right",
        backgroundColor: "#e7e7e7"
    },
    numberInputStyle: {
        textAlign: "right"
    },
    offsetInputStyle: {
        textAlign: "center"
    },

    screeningImageStyle: {
        width: 500, 
        height: 500, 
        borderRadius:85, 
        margin:10

    },
    dropdown: {
        justifyContent: "center", alignItems: "center", height: "100px", borderWidth: "2px", borderColor: "#75757d", borderStyle: "dashed", backgroundColor: "white", paddingLeft: 10, paddingRight: 10
    },
    uploadIcon: {
        width: 48,
        height: 48
    },
});

export const themes = {
    inputTheme: {
        colors: {
            primary: primaryColorDarker
        }
    }
}