import Messages from "../Messages";
import Settings from "../Settings";
import Utils from "../Utils";

let qr_code_data = "";

export const appReducer = (state = { }, action) => {
    switch (action.type) {
      case 'CONNECTION_STATE':

        qr_code_data = action.qr_code_data;

        if(Settings.environment != "standalone" && action.state) {

          let saved = localStorage.getItem('session_id');
          let reconnect = Messages.QRCodeAuthReconnectType.create();
          if(saved != null) {
              reconnect.session_id.push(saved);
          }
          Utils.sendMessage(Utils.MessageId.QR_CODE_AUTH_RECONNECT, Messages.QRCodeAuthReconnectType.encode(reconnect).finish());
        }

        if(Settings.environment == "standalone") {
          return Object.assign({}, state, { connected:  action.state, qr_code_data: "", session_id: "", session_valid: false });
        } else {
          return Object.assign({}, state, { connected:  action.state, qr_code_data: "", session_id: "", session_valid: false });
        }
      case 'QR_CODE_AUTH_NOTIFICATION':
        let qr_code_auth_notification = Messages.QRCodeAuthNotificationType.decode(action.data);
        if(qr_code_auth_notification.add_session) {
          localStorage.setItem('session_id', qr_code_auth_notification.session_id);
          return Object.assign({}, state, {  session_id: qr_code_auth_notification.session_id, session_valid: true });
        }
        return state;
      case 'QR_CODE_AUTH_RECONNECT':
        let qr_code_auth_reconnect = Messages.QRCodeAuthReconnectType.decode(action.data);
        if(qr_code_auth_reconnect.session_id.length > 0) {
          let session_id = qr_code_auth_reconnect.session_id[0];
          localStorage.setItem('session_id', session_id);
          return Object.assign({}, state, {  session_id: session_id, session_valid: true,  qr_code_data: qr_code_data });
        } else {
          localStorage.removeItem('session_id');
          return Object.assign({}, state, { qr_code_data: qr_code_data });
        }
      case 'QR_CODE_SESSION_END':
        localStorage.removeItem('session_id');
        return Object.assign({}, state, {  session_id: "", session_valid: false, logout: true });
      case 'RIP_PC_REGISTRATION_DATA':
        if(action.data.length == 0) {
          return Object.assign({}, state, { qr_code_data: "" });
        } else {
          let registration_qr_code_data = new TextDecoder("utf-8").decode(action.data);
          return Object.assign({}, state, { qr_code_data: registration_qr_code_data });
        }
        
      default:
        return state;
    }
};