import { withStyles } from '@material-ui/core';
import { globalStyles, colors, themes} from "./Styles";
import Slider from '@material-ui/core/Slider';
import React, { Component } from 'react';
import {
    Text,
    View,
    TouchableOpacity,
    Image,
    Picker,
    Switch,
    StyleSheet
  } from 'react-native';

import { TextInput, IconButton, Button } from "react-native-paper";

import { RipeButton } from "./RipeButton";

var valueLabel = {
    left: 'calc(-50% - 4px)',
    top: -15,
    '& *': {
      background: 'transparent',
      color: '#000',
    },
};

var CustomSlider;

  export class RipeSlider extends Component {

    state = {
        value: 0
    }

    static defaultProps = {
        valueLabelFormat: (value) => { return value; },
        min: -100,
        max: 100
    }

    constructor(props) {
        super(props);

        this.state.value = props.value;

        this.stepMinus = (value) => {
            var newValue = value - 1;
            if(this.props.stepMinus !== undefined) {
                newValue = this.props.stepMinus(value);
            }

            this.setState({value: newValue});

            if(this.props.onChange != undefined) {
                this.props.onChange( newValue);
            }
        };

        this.stepPlus = (value) => {
            var newValue = value + 1;
            if(this.props.stepPlus !== undefined) {
                newValue = this.props.stepPlus(value);
            }

            this.setState({value: newValue});
            
            if(this.props.onChange != undefined) {
                this.props.onChange( newValue);
            }
        };


        CustomSlider = withStyles({
            root: {
                color: '#75757d',
            },
            thumb: {
              backgroundColor: colors.switchThumb
            },
            valueLabel: valueLabel
        })(Slider);
    }

    onValueChange(e, newValue) {
        this.setState({value: newValue});
        if(this.props.onChange != undefined) {
            this.props.onChange(newValue);
        }
    }

    componentWillReceiveProps(newProps) {
        this.setState({value: newProps.value});
    }

// 
    render() {
        return (
            <View style={{flexDirection: "row", alignItems: "center"}}>
                <IconButton icon={this.props.icon} size={26} color={this.props.iconColor} style={{margin: 0}}/>
                
                <Text style={styles.sliderLabelStyle}>{this.props.label}</Text>
                
                <IconButton icon="minus" size="15" onPress={ () => { this.stepMinus(this.state.value); } } style={{backgroundColor: "white", borderWidth: 1, borderColor: "gray"}}></IconButton>

                <CustomSlider track={false} valueLabelFormat={this.props.valueLabelFormat} value={this.state.value} min={this.props.min} max={this.props.max}  
                            onChange={this.onValueChange.bind(this)}
                            valueLabelDisplay="on" />

                <IconButton icon="plus" size="15" onPress={ () => { this.stepPlus(this.state.value); } } style={{backgroundColor: "white", borderWidth: 1, borderColor: "gray"}}></IconButton>
            </View>
        );
    }


  }

  const styles = StyleSheet.create({
    sliderLabelStyle: {
        width: 150,
        alignSelf: "center"
    }
});

