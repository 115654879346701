/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const appcore = $root.appcore = (() => {

    /**
     * Namespace appcore.
     * @exports appcore
     * @namespace
     */
    const appcore = {};

    appcore.ripe = (function() {

        /**
         * Namespace ripe.
         * @memberof appcore
         * @namespace
         */
        const ripe = {};

        ripe.ActivateUser = (function() {

            /**
             * Properties of an ActivateUser.
             * @memberof appcore.ripe
             * @interface IActivateUser
             * @property {number|Long|null} [user_id] ActivateUser user_id
             */

            /**
             * Constructs a new ActivateUser.
             * @memberof appcore.ripe
             * @classdesc Represents an ActivateUser.
             * @implements IActivateUser
             * @constructor
             * @param {appcore.ripe.IActivateUser=} [properties] Properties to set
             */
            function ActivateUser(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ActivateUser user_id.
             * @member {number|Long} user_id
             * @memberof appcore.ripe.ActivateUser
             * @instance
             */
            ActivateUser.prototype.user_id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new ActivateUser instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.ActivateUser
             * @static
             * @param {appcore.ripe.IActivateUser=} [properties] Properties to set
             * @returns {appcore.ripe.ActivateUser} ActivateUser instance
             */
            ActivateUser.create = function create(properties) {
                return new ActivateUser(properties);
            };

            /**
             * Encodes the specified ActivateUser message. Does not implicitly {@link appcore.ripe.ActivateUser.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.ActivateUser
             * @static
             * @param {appcore.ripe.IActivateUser} message ActivateUser message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ActivateUser.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.user_id != null && Object.hasOwnProperty.call(message, "user_id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.user_id);
                return writer;
            };

            /**
             * Encodes the specified ActivateUser message, length delimited. Does not implicitly {@link appcore.ripe.ActivateUser.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.ActivateUser
             * @static
             * @param {appcore.ripe.IActivateUser} message ActivateUser message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ActivateUser.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an ActivateUser message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.ActivateUser
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.ActivateUser} ActivateUser
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ActivateUser.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.ActivateUser();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.user_id = reader.int64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an ActivateUser message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.ActivateUser
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.ActivateUser} ActivateUser
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ActivateUser.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an ActivateUser message.
             * @function verify
             * @memberof appcore.ripe.ActivateUser
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ActivateUser.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.user_id != null && message.hasOwnProperty("user_id"))
                    if (!$util.isInteger(message.user_id) && !(message.user_id && $util.isInteger(message.user_id.low) && $util.isInteger(message.user_id.high)))
                        return "user_id: integer|Long expected";
                return null;
            };

            /**
             * Creates an ActivateUser message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.ActivateUser
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.ActivateUser} ActivateUser
             */
            ActivateUser.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.ActivateUser)
                    return object;
                let message = new $root.appcore.ripe.ActivateUser();
                if (object.user_id != null)
                    if ($util.Long)
                        (message.user_id = $util.Long.fromValue(object.user_id)).unsigned = false;
                    else if (typeof object.user_id === "string")
                        message.user_id = parseInt(object.user_id, 10);
                    else if (typeof object.user_id === "number")
                        message.user_id = object.user_id;
                    else if (typeof object.user_id === "object")
                        message.user_id = new $util.LongBits(object.user_id.low >>> 0, object.user_id.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from an ActivateUser message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.ActivateUser
             * @static
             * @param {appcore.ripe.ActivateUser} message ActivateUser
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ActivateUser.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.user_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.user_id = options.longs === String ? "0" : 0;
                if (message.user_id != null && message.hasOwnProperty("user_id"))
                    if (typeof message.user_id === "number")
                        object.user_id = options.longs === String ? String(message.user_id) : message.user_id;
                    else
                        object.user_id = options.longs === String ? $util.Long.prototype.toString.call(message.user_id) : options.longs === Number ? new $util.LongBits(message.user_id.low >>> 0, message.user_id.high >>> 0).toNumber() : message.user_id;
                return object;
            };

            /**
             * Converts this ActivateUser to JSON.
             * @function toJSON
             * @memberof appcore.ripe.ActivateUser
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ActivateUser.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ActivateUser;
        })();

        ripe.AddRIPComputerPrinterMapping = (function() {

            /**
             * Properties of an AddRIPComputerPrinterMapping.
             * @memberof appcore.ripe
             * @interface IAddRIPComputerPrinterMapping
             * @property {number|Long|null} [user_printer_id] AddRIPComputerPrinterMapping user_printer_id
             * @property {number|Long|null} [rip_computer_id] AddRIPComputerPrinterMapping rip_computer_id
             */

            /**
             * Constructs a new AddRIPComputerPrinterMapping.
             * @memberof appcore.ripe
             * @classdesc Represents an AddRIPComputerPrinterMapping.
             * @implements IAddRIPComputerPrinterMapping
             * @constructor
             * @param {appcore.ripe.IAddRIPComputerPrinterMapping=} [properties] Properties to set
             */
            function AddRIPComputerPrinterMapping(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AddRIPComputerPrinterMapping user_printer_id.
             * @member {number|Long} user_printer_id
             * @memberof appcore.ripe.AddRIPComputerPrinterMapping
             * @instance
             */
            AddRIPComputerPrinterMapping.prototype.user_printer_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * AddRIPComputerPrinterMapping rip_computer_id.
             * @member {number|Long} rip_computer_id
             * @memberof appcore.ripe.AddRIPComputerPrinterMapping
             * @instance
             */
            AddRIPComputerPrinterMapping.prototype.rip_computer_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new AddRIPComputerPrinterMapping instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.AddRIPComputerPrinterMapping
             * @static
             * @param {appcore.ripe.IAddRIPComputerPrinterMapping=} [properties] Properties to set
             * @returns {appcore.ripe.AddRIPComputerPrinterMapping} AddRIPComputerPrinterMapping instance
             */
            AddRIPComputerPrinterMapping.create = function create(properties) {
                return new AddRIPComputerPrinterMapping(properties);
            };

            /**
             * Encodes the specified AddRIPComputerPrinterMapping message. Does not implicitly {@link appcore.ripe.AddRIPComputerPrinterMapping.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.AddRIPComputerPrinterMapping
             * @static
             * @param {appcore.ripe.IAddRIPComputerPrinterMapping} message AddRIPComputerPrinterMapping message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddRIPComputerPrinterMapping.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.user_printer_id != null && Object.hasOwnProperty.call(message, "user_printer_id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.user_printer_id);
                if (message.rip_computer_id != null && Object.hasOwnProperty.call(message, "rip_computer_id"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.rip_computer_id);
                return writer;
            };

            /**
             * Encodes the specified AddRIPComputerPrinterMapping message, length delimited. Does not implicitly {@link appcore.ripe.AddRIPComputerPrinterMapping.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.AddRIPComputerPrinterMapping
             * @static
             * @param {appcore.ripe.IAddRIPComputerPrinterMapping} message AddRIPComputerPrinterMapping message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddRIPComputerPrinterMapping.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AddRIPComputerPrinterMapping message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.AddRIPComputerPrinterMapping
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.AddRIPComputerPrinterMapping} AddRIPComputerPrinterMapping
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddRIPComputerPrinterMapping.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.AddRIPComputerPrinterMapping();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.user_printer_id = reader.uint64();
                        break;
                    case 2:
                        message.rip_computer_id = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AddRIPComputerPrinterMapping message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.AddRIPComputerPrinterMapping
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.AddRIPComputerPrinterMapping} AddRIPComputerPrinterMapping
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddRIPComputerPrinterMapping.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AddRIPComputerPrinterMapping message.
             * @function verify
             * @memberof appcore.ripe.AddRIPComputerPrinterMapping
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AddRIPComputerPrinterMapping.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.user_printer_id != null && message.hasOwnProperty("user_printer_id"))
                    if (!$util.isInteger(message.user_printer_id) && !(message.user_printer_id && $util.isInteger(message.user_printer_id.low) && $util.isInteger(message.user_printer_id.high)))
                        return "user_printer_id: integer|Long expected";
                if (message.rip_computer_id != null && message.hasOwnProperty("rip_computer_id"))
                    if (!$util.isInteger(message.rip_computer_id) && !(message.rip_computer_id && $util.isInteger(message.rip_computer_id.low) && $util.isInteger(message.rip_computer_id.high)))
                        return "rip_computer_id: integer|Long expected";
                return null;
            };

            /**
             * Creates an AddRIPComputerPrinterMapping message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.AddRIPComputerPrinterMapping
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.AddRIPComputerPrinterMapping} AddRIPComputerPrinterMapping
             */
            AddRIPComputerPrinterMapping.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.AddRIPComputerPrinterMapping)
                    return object;
                let message = new $root.appcore.ripe.AddRIPComputerPrinterMapping();
                if (object.user_printer_id != null)
                    if ($util.Long)
                        (message.user_printer_id = $util.Long.fromValue(object.user_printer_id)).unsigned = true;
                    else if (typeof object.user_printer_id === "string")
                        message.user_printer_id = parseInt(object.user_printer_id, 10);
                    else if (typeof object.user_printer_id === "number")
                        message.user_printer_id = object.user_printer_id;
                    else if (typeof object.user_printer_id === "object")
                        message.user_printer_id = new $util.LongBits(object.user_printer_id.low >>> 0, object.user_printer_id.high >>> 0).toNumber(true);
                if (object.rip_computer_id != null)
                    if ($util.Long)
                        (message.rip_computer_id = $util.Long.fromValue(object.rip_computer_id)).unsigned = true;
                    else if (typeof object.rip_computer_id === "string")
                        message.rip_computer_id = parseInt(object.rip_computer_id, 10);
                    else if (typeof object.rip_computer_id === "number")
                        message.rip_computer_id = object.rip_computer_id;
                    else if (typeof object.rip_computer_id === "object")
                        message.rip_computer_id = new $util.LongBits(object.rip_computer_id.low >>> 0, object.rip_computer_id.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from an AddRIPComputerPrinterMapping message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.AddRIPComputerPrinterMapping
             * @static
             * @param {appcore.ripe.AddRIPComputerPrinterMapping} message AddRIPComputerPrinterMapping
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AddRIPComputerPrinterMapping.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.user_printer_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.user_printer_id = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.rip_computer_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.rip_computer_id = options.longs === String ? "0" : 0;
                }
                if (message.user_printer_id != null && message.hasOwnProperty("user_printer_id"))
                    if (typeof message.user_printer_id === "number")
                        object.user_printer_id = options.longs === String ? String(message.user_printer_id) : message.user_printer_id;
                    else
                        object.user_printer_id = options.longs === String ? $util.Long.prototype.toString.call(message.user_printer_id) : options.longs === Number ? new $util.LongBits(message.user_printer_id.low >>> 0, message.user_printer_id.high >>> 0).toNumber(true) : message.user_printer_id;
                if (message.rip_computer_id != null && message.hasOwnProperty("rip_computer_id"))
                    if (typeof message.rip_computer_id === "number")
                        object.rip_computer_id = options.longs === String ? String(message.rip_computer_id) : message.rip_computer_id;
                    else
                        object.rip_computer_id = options.longs === String ? $util.Long.prototype.toString.call(message.rip_computer_id) : options.longs === Number ? new $util.LongBits(message.rip_computer_id.low >>> 0, message.rip_computer_id.high >>> 0).toNumber(true) : message.rip_computer_id;
                return object;
            };

            /**
             * Converts this AddRIPComputerPrinterMapping to JSON.
             * @function toJSON
             * @memberof appcore.ripe.AddRIPComputerPrinterMapping
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AddRIPComputerPrinterMapping.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return AddRIPComputerPrinterMapping;
        })();

        ripe.AdministrationData = (function() {

            /**
             * Properties of an AdministrationData.
             * @memberof appcore.ripe
             * @interface IAdministrationData
             * @property {Array.<appcore.ripe.IUser>|null} [users] AdministrationData users
             * @property {Array.<appcore.ripe.IOutput>|null} [outputs] AdministrationData outputs
             */

            /**
             * Constructs a new AdministrationData.
             * @memberof appcore.ripe
             * @classdesc Represents an AdministrationData.
             * @implements IAdministrationData
             * @constructor
             * @param {appcore.ripe.IAdministrationData=} [properties] Properties to set
             */
            function AdministrationData(properties) {
                this.users = [];
                this.outputs = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AdministrationData users.
             * @member {Array.<appcore.ripe.IUser>} users
             * @memberof appcore.ripe.AdministrationData
             * @instance
             */
            AdministrationData.prototype.users = $util.emptyArray;

            /**
             * AdministrationData outputs.
             * @member {Array.<appcore.ripe.IOutput>} outputs
             * @memberof appcore.ripe.AdministrationData
             * @instance
             */
            AdministrationData.prototype.outputs = $util.emptyArray;

            /**
             * Creates a new AdministrationData instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.AdministrationData
             * @static
             * @param {appcore.ripe.IAdministrationData=} [properties] Properties to set
             * @returns {appcore.ripe.AdministrationData} AdministrationData instance
             */
            AdministrationData.create = function create(properties) {
                return new AdministrationData(properties);
            };

            /**
             * Encodes the specified AdministrationData message. Does not implicitly {@link appcore.ripe.AdministrationData.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.AdministrationData
             * @static
             * @param {appcore.ripe.IAdministrationData} message AdministrationData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AdministrationData.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.users != null && message.users.length)
                    for (let i = 0; i < message.users.length; ++i)
                        $root.appcore.ripe.User.encode(message.users[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.outputs != null && message.outputs.length)
                    for (let i = 0; i < message.outputs.length; ++i)
                        $root.appcore.ripe.Output.encode(message.outputs[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified AdministrationData message, length delimited. Does not implicitly {@link appcore.ripe.AdministrationData.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.AdministrationData
             * @static
             * @param {appcore.ripe.IAdministrationData} message AdministrationData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AdministrationData.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AdministrationData message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.AdministrationData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.AdministrationData} AdministrationData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AdministrationData.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.AdministrationData();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.users && message.users.length))
                            message.users = [];
                        message.users.push($root.appcore.ripe.User.decode(reader, reader.uint32()));
                        break;
                    case 2:
                        if (!(message.outputs && message.outputs.length))
                            message.outputs = [];
                        message.outputs.push($root.appcore.ripe.Output.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AdministrationData message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.AdministrationData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.AdministrationData} AdministrationData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AdministrationData.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AdministrationData message.
             * @function verify
             * @memberof appcore.ripe.AdministrationData
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AdministrationData.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.users != null && message.hasOwnProperty("users")) {
                    if (!Array.isArray(message.users))
                        return "users: array expected";
                    for (let i = 0; i < message.users.length; ++i) {
                        let error = $root.appcore.ripe.User.verify(message.users[i]);
                        if (error)
                            return "users." + error;
                    }
                }
                if (message.outputs != null && message.hasOwnProperty("outputs")) {
                    if (!Array.isArray(message.outputs))
                        return "outputs: array expected";
                    for (let i = 0; i < message.outputs.length; ++i) {
                        let error = $root.appcore.ripe.Output.verify(message.outputs[i]);
                        if (error)
                            return "outputs." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an AdministrationData message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.AdministrationData
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.AdministrationData} AdministrationData
             */
            AdministrationData.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.AdministrationData)
                    return object;
                let message = new $root.appcore.ripe.AdministrationData();
                if (object.users) {
                    if (!Array.isArray(object.users))
                        throw TypeError(".appcore.ripe.AdministrationData.users: array expected");
                    message.users = [];
                    for (let i = 0; i < object.users.length; ++i) {
                        if (typeof object.users[i] !== "object")
                            throw TypeError(".appcore.ripe.AdministrationData.users: object expected");
                        message.users[i] = $root.appcore.ripe.User.fromObject(object.users[i]);
                    }
                }
                if (object.outputs) {
                    if (!Array.isArray(object.outputs))
                        throw TypeError(".appcore.ripe.AdministrationData.outputs: array expected");
                    message.outputs = [];
                    for (let i = 0; i < object.outputs.length; ++i) {
                        if (typeof object.outputs[i] !== "object")
                            throw TypeError(".appcore.ripe.AdministrationData.outputs: object expected");
                        message.outputs[i] = $root.appcore.ripe.Output.fromObject(object.outputs[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an AdministrationData message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.AdministrationData
             * @static
             * @param {appcore.ripe.AdministrationData} message AdministrationData
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AdministrationData.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults) {
                    object.users = [];
                    object.outputs = [];
                }
                if (message.users && message.users.length) {
                    object.users = [];
                    for (let j = 0; j < message.users.length; ++j)
                        object.users[j] = $root.appcore.ripe.User.toObject(message.users[j], options);
                }
                if (message.outputs && message.outputs.length) {
                    object.outputs = [];
                    for (let j = 0; j < message.outputs.length; ++j)
                        object.outputs[j] = $root.appcore.ripe.Output.toObject(message.outputs[j], options);
                }
                return object;
            };

            /**
             * Converts this AdministrationData to JSON.
             * @function toJSON
             * @memberof appcore.ripe.AdministrationData
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AdministrationData.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return AdministrationData;
        })();

        ripe.User = (function() {

            /**
             * Properties of a User.
             * @memberof appcore.ripe
             * @interface IUser
             * @property {number|Long|null} [id] User id
             * @property {string|null} [name] User name
             * @property {string|null} [email] User email
             * @property {string|null} [phone] User phone
             * @property {boolean|null} [active] User active
             * @property {Array.<number>|null} [output_codes] User output_codes
             * @property {number|Long|null} [last_login_time] User last_login_time
             * @property {Array.<appcore.ripe.IRIPComputer>|null} [rip_computers] User rip_computers
             */

            /**
             * Constructs a new User.
             * @memberof appcore.ripe
             * @classdesc Represents a User.
             * @implements IUser
             * @constructor
             * @param {appcore.ripe.IUser=} [properties] Properties to set
             */
            function User(properties) {
                this.output_codes = [];
                this.rip_computers = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * User id.
             * @member {number|Long} id
             * @memberof appcore.ripe.User
             * @instance
             */
            User.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * User name.
             * @member {string} name
             * @memberof appcore.ripe.User
             * @instance
             */
            User.prototype.name = "";

            /**
             * User email.
             * @member {string} email
             * @memberof appcore.ripe.User
             * @instance
             */
            User.prototype.email = "";

            /**
             * User phone.
             * @member {string} phone
             * @memberof appcore.ripe.User
             * @instance
             */
            User.prototype.phone = "";

            /**
             * User active.
             * @member {boolean} active
             * @memberof appcore.ripe.User
             * @instance
             */
            User.prototype.active = false;

            /**
             * User output_codes.
             * @member {Array.<number>} output_codes
             * @memberof appcore.ripe.User
             * @instance
             */
            User.prototype.output_codes = $util.emptyArray;

            /**
             * User last_login_time.
             * @member {number|Long} last_login_time
             * @memberof appcore.ripe.User
             * @instance
             */
            User.prototype.last_login_time = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * User rip_computers.
             * @member {Array.<appcore.ripe.IRIPComputer>} rip_computers
             * @memberof appcore.ripe.User
             * @instance
             */
            User.prototype.rip_computers = $util.emptyArray;

            /**
             * Creates a new User instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.User
             * @static
             * @param {appcore.ripe.IUser=} [properties] Properties to set
             * @returns {appcore.ripe.User} User instance
             */
            User.create = function create(properties) {
                return new User(properties);
            };

            /**
             * Encodes the specified User message. Does not implicitly {@link appcore.ripe.User.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.User
             * @static
             * @param {appcore.ripe.IUser} message User message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            User.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.email);
                if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.phone);
                if (message.active != null && Object.hasOwnProperty.call(message, "active"))
                    writer.uint32(/* id 5, wireType 0 =*/40).bool(message.active);
                if (message.output_codes != null && message.output_codes.length) {
                    writer.uint32(/* id 6, wireType 2 =*/50).fork();
                    for (let i = 0; i < message.output_codes.length; ++i)
                        writer.int32(message.output_codes[i]);
                    writer.ldelim();
                }
                if (message.last_login_time != null && Object.hasOwnProperty.call(message, "last_login_time"))
                    writer.uint32(/* id 7, wireType 0 =*/56).uint64(message.last_login_time);
                if (message.rip_computers != null && message.rip_computers.length)
                    for (let i = 0; i < message.rip_computers.length; ++i)
                        $root.appcore.ripe.RIPComputer.encode(message.rip_computers[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified User message, length delimited. Does not implicitly {@link appcore.ripe.User.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.User
             * @static
             * @param {appcore.ripe.IUser} message User message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            User.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a User message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.User
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.User} User
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            User.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.User();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.int64();
                        break;
                    case 2:
                        message.name = reader.string();
                        break;
                    case 3:
                        message.email = reader.string();
                        break;
                    case 4:
                        message.phone = reader.string();
                        break;
                    case 5:
                        message.active = reader.bool();
                        break;
                    case 6:
                        if (!(message.output_codes && message.output_codes.length))
                            message.output_codes = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.output_codes.push(reader.int32());
                        } else
                            message.output_codes.push(reader.int32());
                        break;
                    case 7:
                        message.last_login_time = reader.uint64();
                        break;
                    case 8:
                        if (!(message.rip_computers && message.rip_computers.length))
                            message.rip_computers = [];
                        message.rip_computers.push($root.appcore.ripe.RIPComputer.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a User message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.User
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.User} User
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            User.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a User message.
             * @function verify
             * @memberof appcore.ripe.User
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            User.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.email != null && message.hasOwnProperty("email"))
                    if (!$util.isString(message.email))
                        return "email: string expected";
                if (message.phone != null && message.hasOwnProperty("phone"))
                    if (!$util.isString(message.phone))
                        return "phone: string expected";
                if (message.active != null && message.hasOwnProperty("active"))
                    if (typeof message.active !== "boolean")
                        return "active: boolean expected";
                if (message.output_codes != null && message.hasOwnProperty("output_codes")) {
                    if (!Array.isArray(message.output_codes))
                        return "output_codes: array expected";
                    for (let i = 0; i < message.output_codes.length; ++i)
                        if (!$util.isInteger(message.output_codes[i]))
                            return "output_codes: integer[] expected";
                }
                if (message.last_login_time != null && message.hasOwnProperty("last_login_time"))
                    if (!$util.isInteger(message.last_login_time) && !(message.last_login_time && $util.isInteger(message.last_login_time.low) && $util.isInteger(message.last_login_time.high)))
                        return "last_login_time: integer|Long expected";
                if (message.rip_computers != null && message.hasOwnProperty("rip_computers")) {
                    if (!Array.isArray(message.rip_computers))
                        return "rip_computers: array expected";
                    for (let i = 0; i < message.rip_computers.length; ++i) {
                        let error = $root.appcore.ripe.RIPComputer.verify(message.rip_computers[i]);
                        if (error)
                            return "rip_computers." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a User message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.User
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.User} User
             */
            User.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.User)
                    return object;
                let message = new $root.appcore.ripe.User();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.email != null)
                    message.email = String(object.email);
                if (object.phone != null)
                    message.phone = String(object.phone);
                if (object.active != null)
                    message.active = Boolean(object.active);
                if (object.output_codes) {
                    if (!Array.isArray(object.output_codes))
                        throw TypeError(".appcore.ripe.User.output_codes: array expected");
                    message.output_codes = [];
                    for (let i = 0; i < object.output_codes.length; ++i)
                        message.output_codes[i] = object.output_codes[i] | 0;
                }
                if (object.last_login_time != null)
                    if ($util.Long)
                        (message.last_login_time = $util.Long.fromValue(object.last_login_time)).unsigned = true;
                    else if (typeof object.last_login_time === "string")
                        message.last_login_time = parseInt(object.last_login_time, 10);
                    else if (typeof object.last_login_time === "number")
                        message.last_login_time = object.last_login_time;
                    else if (typeof object.last_login_time === "object")
                        message.last_login_time = new $util.LongBits(object.last_login_time.low >>> 0, object.last_login_time.high >>> 0).toNumber(true);
                if (object.rip_computers) {
                    if (!Array.isArray(object.rip_computers))
                        throw TypeError(".appcore.ripe.User.rip_computers: array expected");
                    message.rip_computers = [];
                    for (let i = 0; i < object.rip_computers.length; ++i) {
                        if (typeof object.rip_computers[i] !== "object")
                            throw TypeError(".appcore.ripe.User.rip_computers: object expected");
                        message.rip_computers[i] = $root.appcore.ripe.RIPComputer.fromObject(object.rip_computers[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a User message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.User
             * @static
             * @param {appcore.ripe.User} message User
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            User.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults) {
                    object.output_codes = [];
                    object.rip_computers = [];
                }
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                    object.name = "";
                    object.email = "";
                    object.phone = "";
                    object.active = false;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.last_login_time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.last_login_time = options.longs === String ? "0" : 0;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.email != null && message.hasOwnProperty("email"))
                    object.email = message.email;
                if (message.phone != null && message.hasOwnProperty("phone"))
                    object.phone = message.phone;
                if (message.active != null && message.hasOwnProperty("active"))
                    object.active = message.active;
                if (message.output_codes && message.output_codes.length) {
                    object.output_codes = [];
                    for (let j = 0; j < message.output_codes.length; ++j)
                        object.output_codes[j] = message.output_codes[j];
                }
                if (message.last_login_time != null && message.hasOwnProperty("last_login_time"))
                    if (typeof message.last_login_time === "number")
                        object.last_login_time = options.longs === String ? String(message.last_login_time) : message.last_login_time;
                    else
                        object.last_login_time = options.longs === String ? $util.Long.prototype.toString.call(message.last_login_time) : options.longs === Number ? new $util.LongBits(message.last_login_time.low >>> 0, message.last_login_time.high >>> 0).toNumber(true) : message.last_login_time;
                if (message.rip_computers && message.rip_computers.length) {
                    object.rip_computers = [];
                    for (let j = 0; j < message.rip_computers.length; ++j)
                        object.rip_computers[j] = $root.appcore.ripe.RIPComputer.toObject(message.rip_computers[j], options);
                }
                return object;
            };

            /**
             * Converts this User to JSON.
             * @function toJSON
             * @memberof appcore.ripe.User
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            User.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return User;
        })();

        ripe.RIPComputer = (function() {

            /**
             * Properties of a RIPComputer.
             * @memberof appcore.ripe
             * @interface IRIPComputer
             * @property {number|Long|null} [id] RIPComputer id
             * @property {string|null} [name] RIPComputer name
             * @property {boolean|null} [has_license] RIPComputer has_license
             * @property {number|Long|null} [license_expiration] RIPComputer license_expiration
             */

            /**
             * Constructs a new RIPComputer.
             * @memberof appcore.ripe
             * @classdesc Represents a RIPComputer.
             * @implements IRIPComputer
             * @constructor
             * @param {appcore.ripe.IRIPComputer=} [properties] Properties to set
             */
            function RIPComputer(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * RIPComputer id.
             * @member {number|Long} id
             * @memberof appcore.ripe.RIPComputer
             * @instance
             */
            RIPComputer.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * RIPComputer name.
             * @member {string} name
             * @memberof appcore.ripe.RIPComputer
             * @instance
             */
            RIPComputer.prototype.name = "";

            /**
             * RIPComputer has_license.
             * @member {boolean} has_license
             * @memberof appcore.ripe.RIPComputer
             * @instance
             */
            RIPComputer.prototype.has_license = false;

            /**
             * RIPComputer license_expiration.
             * @member {number|Long} license_expiration
             * @memberof appcore.ripe.RIPComputer
             * @instance
             */
            RIPComputer.prototype.license_expiration = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new RIPComputer instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.RIPComputer
             * @static
             * @param {appcore.ripe.IRIPComputer=} [properties] Properties to set
             * @returns {appcore.ripe.RIPComputer} RIPComputer instance
             */
            RIPComputer.create = function create(properties) {
                return new RIPComputer(properties);
            };

            /**
             * Encodes the specified RIPComputer message. Does not implicitly {@link appcore.ripe.RIPComputer.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.RIPComputer
             * @static
             * @param {appcore.ripe.IRIPComputer} message RIPComputer message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RIPComputer.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.id);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.has_license != null && Object.hasOwnProperty.call(message, "has_license"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.has_license);
                if (message.license_expiration != null && Object.hasOwnProperty.call(message, "license_expiration"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.license_expiration);
                return writer;
            };

            /**
             * Encodes the specified RIPComputer message, length delimited. Does not implicitly {@link appcore.ripe.RIPComputer.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.RIPComputer
             * @static
             * @param {appcore.ripe.IRIPComputer} message RIPComputer message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RIPComputer.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a RIPComputer message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.RIPComputer
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.RIPComputer} RIPComputer
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RIPComputer.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.RIPComputer();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.uint64();
                        break;
                    case 2:
                        message.name = reader.string();
                        break;
                    case 3:
                        message.has_license = reader.bool();
                        break;
                    case 4:
                        message.license_expiration = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a RIPComputer message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.RIPComputer
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.RIPComputer} RIPComputer
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RIPComputer.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a RIPComputer message.
             * @function verify
             * @memberof appcore.ripe.RIPComputer
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RIPComputer.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.has_license != null && message.hasOwnProperty("has_license"))
                    if (typeof message.has_license !== "boolean")
                        return "has_license: boolean expected";
                if (message.license_expiration != null && message.hasOwnProperty("license_expiration"))
                    if (!$util.isInteger(message.license_expiration) && !(message.license_expiration && $util.isInteger(message.license_expiration.low) && $util.isInteger(message.license_expiration.high)))
                        return "license_expiration: integer|Long expected";
                return null;
            };

            /**
             * Creates a RIPComputer message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.RIPComputer
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.RIPComputer} RIPComputer
             */
            RIPComputer.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.RIPComputer)
                    return object;
                let message = new $root.appcore.ripe.RIPComputer();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.has_license != null)
                    message.has_license = Boolean(object.has_license);
                if (object.license_expiration != null)
                    if ($util.Long)
                        (message.license_expiration = $util.Long.fromValue(object.license_expiration)).unsigned = true;
                    else if (typeof object.license_expiration === "string")
                        message.license_expiration = parseInt(object.license_expiration, 10);
                    else if (typeof object.license_expiration === "number")
                        message.license_expiration = object.license_expiration;
                    else if (typeof object.license_expiration === "object")
                        message.license_expiration = new $util.LongBits(object.license_expiration.low >>> 0, object.license_expiration.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a RIPComputer message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.RIPComputer
             * @static
             * @param {appcore.ripe.RIPComputer} message RIPComputer
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RIPComputer.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                    object.name = "";
                    object.has_license = false;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.license_expiration = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.license_expiration = options.longs === String ? "0" : 0;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.has_license != null && message.hasOwnProperty("has_license"))
                    object.has_license = message.has_license;
                if (message.license_expiration != null && message.hasOwnProperty("license_expiration"))
                    if (typeof message.license_expiration === "number")
                        object.license_expiration = options.longs === String ? String(message.license_expiration) : message.license_expiration;
                    else
                        object.license_expiration = options.longs === String ? $util.Long.prototype.toString.call(message.license_expiration) : options.longs === Number ? new $util.LongBits(message.license_expiration.low >>> 0, message.license_expiration.high >>> 0).toNumber(true) : message.license_expiration;
                return object;
            };

            /**
             * Converts this RIPComputer to JSON.
             * @function toJSON
             * @memberof appcore.ripe.RIPComputer
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RIPComputer.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return RIPComputer;
        })();

        ripe.Output = (function() {

            /**
             * Properties of an Output.
             * @memberof appcore.ripe
             * @interface IOutput
             * @property {string|null} [name] Output name
             * @property {number|null} [code] Output code
             * @property {boolean|null} [printer_output] Output printer_output
             * @property {boolean|null} [show_dpi] Output show_dpi
             * @property {boolean|null} [show_profile] Output show_profile
             * @property {boolean|null} [show_use_raw_thumbnail] Output show_use_raw_thumbnail
             * @property {boolean|null} [show_screening] Output show_screening
             * @property {boolean|null} [show_ink_levels] Output show_ink_levels
             * @property {Array.<string>|null} [dpi_options] Output dpi_options
             * @property {boolean|null} [show_copies_config] Output show_copies_config
             * @property {boolean|null} [only_collate_is_supported] Output only_collate_is_supported
             * @property {boolean|null} [show_bottom_margin_page] Output show_bottom_margin_page
             * @property {boolean|null} [show_bottom_margin_copy] Output show_bottom_margin_copy
             * @property {boolean|null} [show_always_generate_head_width_raster] Output show_always_generate_head_width_raster
             * @property {boolean|null} [always_generate_head_width_raster_enabled] Output always_generate_head_width_raster_enabled
             * @property {boolean|null} [show_device_links] Output show_device_links
             * @property {boolean|null} [show_intent] Output show_intent
             * @property {boolean|null} [show_preserve] Output show_preserve
             * @property {boolean|null} [show_print_mode_pcl] Output show_print_mode_pcl
             */

            /**
             * Constructs a new Output.
             * @memberof appcore.ripe
             * @classdesc Represents an Output.
             * @implements IOutput
             * @constructor
             * @param {appcore.ripe.IOutput=} [properties] Properties to set
             */
            function Output(properties) {
                this.dpi_options = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Output name.
             * @member {string} name
             * @memberof appcore.ripe.Output
             * @instance
             */
            Output.prototype.name = "";

            /**
             * Output code.
             * @member {number} code
             * @memberof appcore.ripe.Output
             * @instance
             */
            Output.prototype.code = 0;

            /**
             * Output printer_output.
             * @member {boolean} printer_output
             * @memberof appcore.ripe.Output
             * @instance
             */
            Output.prototype.printer_output = false;

            /**
             * Output show_dpi.
             * @member {boolean} show_dpi
             * @memberof appcore.ripe.Output
             * @instance
             */
            Output.prototype.show_dpi = false;

            /**
             * Output show_profile.
             * @member {boolean} show_profile
             * @memberof appcore.ripe.Output
             * @instance
             */
            Output.prototype.show_profile = false;

            /**
             * Output show_use_raw_thumbnail.
             * @member {boolean} show_use_raw_thumbnail
             * @memberof appcore.ripe.Output
             * @instance
             */
            Output.prototype.show_use_raw_thumbnail = false;

            /**
             * Output show_screening.
             * @member {boolean} show_screening
             * @memberof appcore.ripe.Output
             * @instance
             */
            Output.prototype.show_screening = false;

            /**
             * Output show_ink_levels.
             * @member {boolean} show_ink_levels
             * @memberof appcore.ripe.Output
             * @instance
             */
            Output.prototype.show_ink_levels = false;

            /**
             * Output dpi_options.
             * @member {Array.<string>} dpi_options
             * @memberof appcore.ripe.Output
             * @instance
             */
            Output.prototype.dpi_options = $util.emptyArray;

            /**
             * Output show_copies_config.
             * @member {boolean} show_copies_config
             * @memberof appcore.ripe.Output
             * @instance
             */
            Output.prototype.show_copies_config = false;

            /**
             * Output only_collate_is_supported.
             * @member {boolean} only_collate_is_supported
             * @memberof appcore.ripe.Output
             * @instance
             */
            Output.prototype.only_collate_is_supported = false;

            /**
             * Output show_bottom_margin_page.
             * @member {boolean} show_bottom_margin_page
             * @memberof appcore.ripe.Output
             * @instance
             */
            Output.prototype.show_bottom_margin_page = false;

            /**
             * Output show_bottom_margin_copy.
             * @member {boolean} show_bottom_margin_copy
             * @memberof appcore.ripe.Output
             * @instance
             */
            Output.prototype.show_bottom_margin_copy = false;

            /**
             * Output show_always_generate_head_width_raster.
             * @member {boolean} show_always_generate_head_width_raster
             * @memberof appcore.ripe.Output
             * @instance
             */
            Output.prototype.show_always_generate_head_width_raster = false;

            /**
             * Output always_generate_head_width_raster_enabled.
             * @member {boolean} always_generate_head_width_raster_enabled
             * @memberof appcore.ripe.Output
             * @instance
             */
            Output.prototype.always_generate_head_width_raster_enabled = false;

            /**
             * Output show_device_links.
             * @member {boolean} show_device_links
             * @memberof appcore.ripe.Output
             * @instance
             */
            Output.prototype.show_device_links = false;

            /**
             * Output show_intent.
             * @member {boolean} show_intent
             * @memberof appcore.ripe.Output
             * @instance
             */
            Output.prototype.show_intent = false;

            /**
             * Output show_preserve.
             * @member {boolean} show_preserve
             * @memberof appcore.ripe.Output
             * @instance
             */
            Output.prototype.show_preserve = false;

            /**
             * Output show_print_mode_pcl.
             * @member {boolean} show_print_mode_pcl
             * @memberof appcore.ripe.Output
             * @instance
             */
            Output.prototype.show_print_mode_pcl = false;

            /**
             * Creates a new Output instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.Output
             * @static
             * @param {appcore.ripe.IOutput=} [properties] Properties to set
             * @returns {appcore.ripe.Output} Output instance
             */
            Output.create = function create(properties) {
                return new Output(properties);
            };

            /**
             * Encodes the specified Output message. Does not implicitly {@link appcore.ripe.Output.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.Output
             * @static
             * @param {appcore.ripe.IOutput} message Output message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Output.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.code);
                if (message.printer_output != null && Object.hasOwnProperty.call(message, "printer_output"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.printer_output);
                if (message.show_dpi != null && Object.hasOwnProperty.call(message, "show_dpi"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.show_dpi);
                if (message.show_profile != null && Object.hasOwnProperty.call(message, "show_profile"))
                    writer.uint32(/* id 5, wireType 0 =*/40).bool(message.show_profile);
                if (message.show_use_raw_thumbnail != null && Object.hasOwnProperty.call(message, "show_use_raw_thumbnail"))
                    writer.uint32(/* id 6, wireType 0 =*/48).bool(message.show_use_raw_thumbnail);
                if (message.show_screening != null && Object.hasOwnProperty.call(message, "show_screening"))
                    writer.uint32(/* id 7, wireType 0 =*/56).bool(message.show_screening);
                if (message.show_ink_levels != null && Object.hasOwnProperty.call(message, "show_ink_levels"))
                    writer.uint32(/* id 8, wireType 0 =*/64).bool(message.show_ink_levels);
                if (message.dpi_options != null && message.dpi_options.length)
                    for (let i = 0; i < message.dpi_options.length; ++i)
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.dpi_options[i]);
                if (message.show_copies_config != null && Object.hasOwnProperty.call(message, "show_copies_config"))
                    writer.uint32(/* id 10, wireType 0 =*/80).bool(message.show_copies_config);
                if (message.only_collate_is_supported != null && Object.hasOwnProperty.call(message, "only_collate_is_supported"))
                    writer.uint32(/* id 11, wireType 0 =*/88).bool(message.only_collate_is_supported);
                if (message.show_bottom_margin_page != null && Object.hasOwnProperty.call(message, "show_bottom_margin_page"))
                    writer.uint32(/* id 12, wireType 0 =*/96).bool(message.show_bottom_margin_page);
                if (message.show_bottom_margin_copy != null && Object.hasOwnProperty.call(message, "show_bottom_margin_copy"))
                    writer.uint32(/* id 13, wireType 0 =*/104).bool(message.show_bottom_margin_copy);
                if (message.show_always_generate_head_width_raster != null && Object.hasOwnProperty.call(message, "show_always_generate_head_width_raster"))
                    writer.uint32(/* id 14, wireType 0 =*/112).bool(message.show_always_generate_head_width_raster);
                if (message.always_generate_head_width_raster_enabled != null && Object.hasOwnProperty.call(message, "always_generate_head_width_raster_enabled"))
                    writer.uint32(/* id 15, wireType 0 =*/120).bool(message.always_generate_head_width_raster_enabled);
                if (message.show_device_links != null && Object.hasOwnProperty.call(message, "show_device_links"))
                    writer.uint32(/* id 16, wireType 0 =*/128).bool(message.show_device_links);
                if (message.show_intent != null && Object.hasOwnProperty.call(message, "show_intent"))
                    writer.uint32(/* id 17, wireType 0 =*/136).bool(message.show_intent);
                if (message.show_preserve != null && Object.hasOwnProperty.call(message, "show_preserve"))
                    writer.uint32(/* id 18, wireType 0 =*/144).bool(message.show_preserve);
                if (message.show_print_mode_pcl != null && Object.hasOwnProperty.call(message, "show_print_mode_pcl"))
                    writer.uint32(/* id 19, wireType 0 =*/152).bool(message.show_print_mode_pcl);
                return writer;
            };

            /**
             * Encodes the specified Output message, length delimited. Does not implicitly {@link appcore.ripe.Output.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.Output
             * @static
             * @param {appcore.ripe.IOutput} message Output message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Output.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an Output message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.Output
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.Output} Output
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Output.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.Output();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.name = reader.string();
                        break;
                    case 2:
                        message.code = reader.int32();
                        break;
                    case 3:
                        message.printer_output = reader.bool();
                        break;
                    case 4:
                        message.show_dpi = reader.bool();
                        break;
                    case 5:
                        message.show_profile = reader.bool();
                        break;
                    case 6:
                        message.show_use_raw_thumbnail = reader.bool();
                        break;
                    case 7:
                        message.show_screening = reader.bool();
                        break;
                    case 8:
                        message.show_ink_levels = reader.bool();
                        break;
                    case 9:
                        if (!(message.dpi_options && message.dpi_options.length))
                            message.dpi_options = [];
                        message.dpi_options.push(reader.string());
                        break;
                    case 10:
                        message.show_copies_config = reader.bool();
                        break;
                    case 11:
                        message.only_collate_is_supported = reader.bool();
                        break;
                    case 12:
                        message.show_bottom_margin_page = reader.bool();
                        break;
                    case 13:
                        message.show_bottom_margin_copy = reader.bool();
                        break;
                    case 14:
                        message.show_always_generate_head_width_raster = reader.bool();
                        break;
                    case 15:
                        message.always_generate_head_width_raster_enabled = reader.bool();
                        break;
                    case 16:
                        message.show_device_links = reader.bool();
                        break;
                    case 17:
                        message.show_intent = reader.bool();
                        break;
                    case 18:
                        message.show_preserve = reader.bool();
                        break;
                    case 19:
                        message.show_print_mode_pcl = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an Output message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.Output
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.Output} Output
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Output.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an Output message.
             * @function verify
             * @memberof appcore.ripe.Output
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Output.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.code != null && message.hasOwnProperty("code"))
                    if (!$util.isInteger(message.code))
                        return "code: integer expected";
                if (message.printer_output != null && message.hasOwnProperty("printer_output"))
                    if (typeof message.printer_output !== "boolean")
                        return "printer_output: boolean expected";
                if (message.show_dpi != null && message.hasOwnProperty("show_dpi"))
                    if (typeof message.show_dpi !== "boolean")
                        return "show_dpi: boolean expected";
                if (message.show_profile != null && message.hasOwnProperty("show_profile"))
                    if (typeof message.show_profile !== "boolean")
                        return "show_profile: boolean expected";
                if (message.show_use_raw_thumbnail != null && message.hasOwnProperty("show_use_raw_thumbnail"))
                    if (typeof message.show_use_raw_thumbnail !== "boolean")
                        return "show_use_raw_thumbnail: boolean expected";
                if (message.show_screening != null && message.hasOwnProperty("show_screening"))
                    if (typeof message.show_screening !== "boolean")
                        return "show_screening: boolean expected";
                if (message.show_ink_levels != null && message.hasOwnProperty("show_ink_levels"))
                    if (typeof message.show_ink_levels !== "boolean")
                        return "show_ink_levels: boolean expected";
                if (message.dpi_options != null && message.hasOwnProperty("dpi_options")) {
                    if (!Array.isArray(message.dpi_options))
                        return "dpi_options: array expected";
                    for (let i = 0; i < message.dpi_options.length; ++i)
                        if (!$util.isString(message.dpi_options[i]))
                            return "dpi_options: string[] expected";
                }
                if (message.show_copies_config != null && message.hasOwnProperty("show_copies_config"))
                    if (typeof message.show_copies_config !== "boolean")
                        return "show_copies_config: boolean expected";
                if (message.only_collate_is_supported != null && message.hasOwnProperty("only_collate_is_supported"))
                    if (typeof message.only_collate_is_supported !== "boolean")
                        return "only_collate_is_supported: boolean expected";
                if (message.show_bottom_margin_page != null && message.hasOwnProperty("show_bottom_margin_page"))
                    if (typeof message.show_bottom_margin_page !== "boolean")
                        return "show_bottom_margin_page: boolean expected";
                if (message.show_bottom_margin_copy != null && message.hasOwnProperty("show_bottom_margin_copy"))
                    if (typeof message.show_bottom_margin_copy !== "boolean")
                        return "show_bottom_margin_copy: boolean expected";
                if (message.show_always_generate_head_width_raster != null && message.hasOwnProperty("show_always_generate_head_width_raster"))
                    if (typeof message.show_always_generate_head_width_raster !== "boolean")
                        return "show_always_generate_head_width_raster: boolean expected";
                if (message.always_generate_head_width_raster_enabled != null && message.hasOwnProperty("always_generate_head_width_raster_enabled"))
                    if (typeof message.always_generate_head_width_raster_enabled !== "boolean")
                        return "always_generate_head_width_raster_enabled: boolean expected";
                if (message.show_device_links != null && message.hasOwnProperty("show_device_links"))
                    if (typeof message.show_device_links !== "boolean")
                        return "show_device_links: boolean expected";
                if (message.show_intent != null && message.hasOwnProperty("show_intent"))
                    if (typeof message.show_intent !== "boolean")
                        return "show_intent: boolean expected";
                if (message.show_preserve != null && message.hasOwnProperty("show_preserve"))
                    if (typeof message.show_preserve !== "boolean")
                        return "show_preserve: boolean expected";
                if (message.show_print_mode_pcl != null && message.hasOwnProperty("show_print_mode_pcl"))
                    if (typeof message.show_print_mode_pcl !== "boolean")
                        return "show_print_mode_pcl: boolean expected";
                return null;
            };

            /**
             * Creates an Output message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.Output
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.Output} Output
             */
            Output.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.Output)
                    return object;
                let message = new $root.appcore.ripe.Output();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.code != null)
                    message.code = object.code | 0;
                if (object.printer_output != null)
                    message.printer_output = Boolean(object.printer_output);
                if (object.show_dpi != null)
                    message.show_dpi = Boolean(object.show_dpi);
                if (object.show_profile != null)
                    message.show_profile = Boolean(object.show_profile);
                if (object.show_use_raw_thumbnail != null)
                    message.show_use_raw_thumbnail = Boolean(object.show_use_raw_thumbnail);
                if (object.show_screening != null)
                    message.show_screening = Boolean(object.show_screening);
                if (object.show_ink_levels != null)
                    message.show_ink_levels = Boolean(object.show_ink_levels);
                if (object.dpi_options) {
                    if (!Array.isArray(object.dpi_options))
                        throw TypeError(".appcore.ripe.Output.dpi_options: array expected");
                    message.dpi_options = [];
                    for (let i = 0; i < object.dpi_options.length; ++i)
                        message.dpi_options[i] = String(object.dpi_options[i]);
                }
                if (object.show_copies_config != null)
                    message.show_copies_config = Boolean(object.show_copies_config);
                if (object.only_collate_is_supported != null)
                    message.only_collate_is_supported = Boolean(object.only_collate_is_supported);
                if (object.show_bottom_margin_page != null)
                    message.show_bottom_margin_page = Boolean(object.show_bottom_margin_page);
                if (object.show_bottom_margin_copy != null)
                    message.show_bottom_margin_copy = Boolean(object.show_bottom_margin_copy);
                if (object.show_always_generate_head_width_raster != null)
                    message.show_always_generate_head_width_raster = Boolean(object.show_always_generate_head_width_raster);
                if (object.always_generate_head_width_raster_enabled != null)
                    message.always_generate_head_width_raster_enabled = Boolean(object.always_generate_head_width_raster_enabled);
                if (object.show_device_links != null)
                    message.show_device_links = Boolean(object.show_device_links);
                if (object.show_intent != null)
                    message.show_intent = Boolean(object.show_intent);
                if (object.show_preserve != null)
                    message.show_preserve = Boolean(object.show_preserve);
                if (object.show_print_mode_pcl != null)
                    message.show_print_mode_pcl = Boolean(object.show_print_mode_pcl);
                return message;
            };

            /**
             * Creates a plain object from an Output message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.Output
             * @static
             * @param {appcore.ripe.Output} message Output
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Output.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.dpi_options = [];
                if (options.defaults) {
                    object.name = "";
                    object.code = 0;
                    object.printer_output = false;
                    object.show_dpi = false;
                    object.show_profile = false;
                    object.show_use_raw_thumbnail = false;
                    object.show_screening = false;
                    object.show_ink_levels = false;
                    object.show_copies_config = false;
                    object.only_collate_is_supported = false;
                    object.show_bottom_margin_page = false;
                    object.show_bottom_margin_copy = false;
                    object.show_always_generate_head_width_raster = false;
                    object.always_generate_head_width_raster_enabled = false;
                    object.show_device_links = false;
                    object.show_intent = false;
                    object.show_preserve = false;
                    object.show_print_mode_pcl = false;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.code != null && message.hasOwnProperty("code"))
                    object.code = message.code;
                if (message.printer_output != null && message.hasOwnProperty("printer_output"))
                    object.printer_output = message.printer_output;
                if (message.show_dpi != null && message.hasOwnProperty("show_dpi"))
                    object.show_dpi = message.show_dpi;
                if (message.show_profile != null && message.hasOwnProperty("show_profile"))
                    object.show_profile = message.show_profile;
                if (message.show_use_raw_thumbnail != null && message.hasOwnProperty("show_use_raw_thumbnail"))
                    object.show_use_raw_thumbnail = message.show_use_raw_thumbnail;
                if (message.show_screening != null && message.hasOwnProperty("show_screening"))
                    object.show_screening = message.show_screening;
                if (message.show_ink_levels != null && message.hasOwnProperty("show_ink_levels"))
                    object.show_ink_levels = message.show_ink_levels;
                if (message.dpi_options && message.dpi_options.length) {
                    object.dpi_options = [];
                    for (let j = 0; j < message.dpi_options.length; ++j)
                        object.dpi_options[j] = message.dpi_options[j];
                }
                if (message.show_copies_config != null && message.hasOwnProperty("show_copies_config"))
                    object.show_copies_config = message.show_copies_config;
                if (message.only_collate_is_supported != null && message.hasOwnProperty("only_collate_is_supported"))
                    object.only_collate_is_supported = message.only_collate_is_supported;
                if (message.show_bottom_margin_page != null && message.hasOwnProperty("show_bottom_margin_page"))
                    object.show_bottom_margin_page = message.show_bottom_margin_page;
                if (message.show_bottom_margin_copy != null && message.hasOwnProperty("show_bottom_margin_copy"))
                    object.show_bottom_margin_copy = message.show_bottom_margin_copy;
                if (message.show_always_generate_head_width_raster != null && message.hasOwnProperty("show_always_generate_head_width_raster"))
                    object.show_always_generate_head_width_raster = message.show_always_generate_head_width_raster;
                if (message.always_generate_head_width_raster_enabled != null && message.hasOwnProperty("always_generate_head_width_raster_enabled"))
                    object.always_generate_head_width_raster_enabled = message.always_generate_head_width_raster_enabled;
                if (message.show_device_links != null && message.hasOwnProperty("show_device_links"))
                    object.show_device_links = message.show_device_links;
                if (message.show_intent != null && message.hasOwnProperty("show_intent"))
                    object.show_intent = message.show_intent;
                if (message.show_preserve != null && message.hasOwnProperty("show_preserve"))
                    object.show_preserve = message.show_preserve;
                if (message.show_print_mode_pcl != null && message.hasOwnProperty("show_print_mode_pcl"))
                    object.show_print_mode_pcl = message.show_print_mode_pcl;
                return object;
            };

            /**
             * Converts this Output to JSON.
             * @function toJSON
             * @memberof appcore.ripe.Output
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Output.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Output;
        })();

        ripe.CancelGenerateLiveView = (function() {

            /**
             * Properties of a CancelGenerateLiveView.
             * @memberof appcore.ripe
             * @interface ICancelGenerateLiveView
             * @property {string|null} [guid] CancelGenerateLiveView guid
             */

            /**
             * Constructs a new CancelGenerateLiveView.
             * @memberof appcore.ripe
             * @classdesc Represents a CancelGenerateLiveView.
             * @implements ICancelGenerateLiveView
             * @constructor
             * @param {appcore.ripe.ICancelGenerateLiveView=} [properties] Properties to set
             */
            function CancelGenerateLiveView(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CancelGenerateLiveView guid.
             * @member {string} guid
             * @memberof appcore.ripe.CancelGenerateLiveView
             * @instance
             */
            CancelGenerateLiveView.prototype.guid = "";

            /**
             * Creates a new CancelGenerateLiveView instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.CancelGenerateLiveView
             * @static
             * @param {appcore.ripe.ICancelGenerateLiveView=} [properties] Properties to set
             * @returns {appcore.ripe.CancelGenerateLiveView} CancelGenerateLiveView instance
             */
            CancelGenerateLiveView.create = function create(properties) {
                return new CancelGenerateLiveView(properties);
            };

            /**
             * Encodes the specified CancelGenerateLiveView message. Does not implicitly {@link appcore.ripe.CancelGenerateLiveView.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.CancelGenerateLiveView
             * @static
             * @param {appcore.ripe.ICancelGenerateLiveView} message CancelGenerateLiveView message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CancelGenerateLiveView.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.guid != null && Object.hasOwnProperty.call(message, "guid"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.guid);
                return writer;
            };

            /**
             * Encodes the specified CancelGenerateLiveView message, length delimited. Does not implicitly {@link appcore.ripe.CancelGenerateLiveView.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.CancelGenerateLiveView
             * @static
             * @param {appcore.ripe.ICancelGenerateLiveView} message CancelGenerateLiveView message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CancelGenerateLiveView.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CancelGenerateLiveView message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.CancelGenerateLiveView
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.CancelGenerateLiveView} CancelGenerateLiveView
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CancelGenerateLiveView.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.CancelGenerateLiveView();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.guid = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CancelGenerateLiveView message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.CancelGenerateLiveView
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.CancelGenerateLiveView} CancelGenerateLiveView
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CancelGenerateLiveView.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CancelGenerateLiveView message.
             * @function verify
             * @memberof appcore.ripe.CancelGenerateLiveView
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CancelGenerateLiveView.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.guid != null && message.hasOwnProperty("guid"))
                    if (!$util.isString(message.guid))
                        return "guid: string expected";
                return null;
            };

            /**
             * Creates a CancelGenerateLiveView message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.CancelGenerateLiveView
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.CancelGenerateLiveView} CancelGenerateLiveView
             */
            CancelGenerateLiveView.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.CancelGenerateLiveView)
                    return object;
                let message = new $root.appcore.ripe.CancelGenerateLiveView();
                if (object.guid != null)
                    message.guid = String(object.guid);
                return message;
            };

            /**
             * Creates a plain object from a CancelGenerateLiveView message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.CancelGenerateLiveView
             * @static
             * @param {appcore.ripe.CancelGenerateLiveView} message CancelGenerateLiveView
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CancelGenerateLiveView.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.guid = "";
                if (message.guid != null && message.hasOwnProperty("guid"))
                    object.guid = message.guid;
                return object;
            };

            /**
             * Converts this CancelGenerateLiveView to JSON.
             * @function toJSON
             * @memberof appcore.ripe.CancelGenerateLiveView
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CancelGenerateLiveView.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return CancelGenerateLiveView;
        })();

        ripe.Rectangle = (function() {

            /**
             * Properties of a Rectangle.
             * @memberof appcore.ripe
             * @interface IRectangle
             * @property {Array.<number|Long>|null} [params] Rectangle params
             */

            /**
             * Constructs a new Rectangle.
             * @memberof appcore.ripe
             * @classdesc Represents a Rectangle.
             * @implements IRectangle
             * @constructor
             * @param {appcore.ripe.IRectangle=} [properties] Properties to set
             */
            function Rectangle(properties) {
                this.params = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Rectangle params.
             * @member {Array.<number|Long>} params
             * @memberof appcore.ripe.Rectangle
             * @instance
             */
            Rectangle.prototype.params = $util.emptyArray;

            /**
             * Creates a new Rectangle instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.Rectangle
             * @static
             * @param {appcore.ripe.IRectangle=} [properties] Properties to set
             * @returns {appcore.ripe.Rectangle} Rectangle instance
             */
            Rectangle.create = function create(properties) {
                return new Rectangle(properties);
            };

            /**
             * Encodes the specified Rectangle message. Does not implicitly {@link appcore.ripe.Rectangle.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.Rectangle
             * @static
             * @param {appcore.ripe.IRectangle} message Rectangle message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Rectangle.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.params != null && message.params.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (let i = 0; i < message.params.length; ++i)
                        writer.uint64(message.params[i]);
                    writer.ldelim();
                }
                return writer;
            };

            /**
             * Encodes the specified Rectangle message, length delimited. Does not implicitly {@link appcore.ripe.Rectangle.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.Rectangle
             * @static
             * @param {appcore.ripe.IRectangle} message Rectangle message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Rectangle.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Rectangle message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.Rectangle
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.Rectangle} Rectangle
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Rectangle.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.Rectangle();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.params && message.params.length))
                            message.params = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.params.push(reader.uint64());
                        } else
                            message.params.push(reader.uint64());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Rectangle message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.Rectangle
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.Rectangle} Rectangle
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Rectangle.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Rectangle message.
             * @function verify
             * @memberof appcore.ripe.Rectangle
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Rectangle.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.params != null && message.hasOwnProperty("params")) {
                    if (!Array.isArray(message.params))
                        return "params: array expected";
                    for (let i = 0; i < message.params.length; ++i)
                        if (!$util.isInteger(message.params[i]) && !(message.params[i] && $util.isInteger(message.params[i].low) && $util.isInteger(message.params[i].high)))
                            return "params: integer|Long[] expected";
                }
                return null;
            };

            /**
             * Creates a Rectangle message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.Rectangle
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.Rectangle} Rectangle
             */
            Rectangle.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.Rectangle)
                    return object;
                let message = new $root.appcore.ripe.Rectangle();
                if (object.params) {
                    if (!Array.isArray(object.params))
                        throw TypeError(".appcore.ripe.Rectangle.params: array expected");
                    message.params = [];
                    for (let i = 0; i < object.params.length; ++i)
                        if ($util.Long)
                            (message.params[i] = $util.Long.fromValue(object.params[i])).unsigned = true;
                        else if (typeof object.params[i] === "string")
                            message.params[i] = parseInt(object.params[i], 10);
                        else if (typeof object.params[i] === "number")
                            message.params[i] = object.params[i];
                        else if (typeof object.params[i] === "object")
                            message.params[i] = new $util.LongBits(object.params[i].low >>> 0, object.params[i].high >>> 0).toNumber(true);
                }
                return message;
            };

            /**
             * Creates a plain object from a Rectangle message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.Rectangle
             * @static
             * @param {appcore.ripe.Rectangle} message Rectangle
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Rectangle.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.params = [];
                if (message.params && message.params.length) {
                    object.params = [];
                    for (let j = 0; j < message.params.length; ++j)
                        if (typeof message.params[j] === "number")
                            object.params[j] = options.longs === String ? String(message.params[j]) : message.params[j];
                        else
                            object.params[j] = options.longs === String ? $util.Long.prototype.toString.call(message.params[j]) : options.longs === Number ? new $util.LongBits(message.params[j].low >>> 0, message.params[j].high >>> 0).toNumber(true) : message.params[j];
                }
                return object;
            };

            /**
             * Converts this Rectangle to JSON.
             * @function toJSON
             * @memberof appcore.ripe.Rectangle
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Rectangle.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Rectangle;
        })();

        ripe.CLICommand = (function() {

            /**
             * Properties of a CLICommand.
             * @memberof appcore.ripe
             * @interface ICLICommand
             * @property {string|null} [input_file] CLICommand input_file
             * @property {string|null} [output_file] CLICommand output_file
             * @property {number|null} [page_number] CLICommand page_number
             * @property {number|null} [dpi_x] CLICommand dpi_x
             * @property {number|null} [dpi_y] CLICommand dpi_y
             * @property {string|null} [icc] CLICommand icc
             * @property {Array.<appcore.ripe.IRectangle>|null} [pb_rectangles] CLICommand pb_rectangles
             * @property {number|null} [pb_threshold_c] CLICommand pb_threshold_c
             * @property {number|null} [pb_threshold_m] CLICommand pb_threshold_m
             * @property {number|null} [pb_threshold_y] CLICommand pb_threshold_y
             * @property {number|null} [pb_threshold_k] CLICommand pb_threshold_k
             * @property {number|null} [pb_compensation_c] CLICommand pb_compensation_c
             * @property {number|null} [pb_compensation_m] CLICommand pb_compensation_m
             * @property {number|null} [pb_compensation_y] CLICommand pb_compensation_y
             * @property {number|null} [pb_compensation_k] CLICommand pb_compensation_k
             * @property {boolean|null} [orf] CLICommand orf
             * @property {number|null} [intent_id] CLICommand intent_id
             * @property {Array.<string>|null} [device_link] CLICommand device_link
             * @property {boolean|null} [trf] CLICommand trf
             * @property {appcore.ripe.IRectangle|null} [cb_rectangle] CLICommand cb_rectangle
             * @property {string|null} [pages] CLICommand pages
             * @property {string|null} [compression] CLICommand compression
             * @property {number|null} [compression_level] CLICommand compression_level
             * @property {number|null} [compression_workers] CLICommand compression_workers
             * @property {number|null} [page_workers] CLICommand page_workers
             * @property {number|null} [band_size] CLICommand band_size
             * @property {boolean|null} [disable_icc_profiling] CLICommand disable_icc_profiling
             */

            /**
             * Constructs a new CLICommand.
             * @memberof appcore.ripe
             * @classdesc Represents a CLICommand.
             * @implements ICLICommand
             * @constructor
             * @param {appcore.ripe.ICLICommand=} [properties] Properties to set
             */
            function CLICommand(properties) {
                this.pb_rectangles = [];
                this.device_link = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CLICommand input_file.
             * @member {string} input_file
             * @memberof appcore.ripe.CLICommand
             * @instance
             */
            CLICommand.prototype.input_file = "";

            /**
             * CLICommand output_file.
             * @member {string} output_file
             * @memberof appcore.ripe.CLICommand
             * @instance
             */
            CLICommand.prototype.output_file = "";

            /**
             * CLICommand page_number.
             * @member {number} page_number
             * @memberof appcore.ripe.CLICommand
             * @instance
             */
            CLICommand.prototype.page_number = 0;

            /**
             * CLICommand dpi_x.
             * @member {number} dpi_x
             * @memberof appcore.ripe.CLICommand
             * @instance
             */
            CLICommand.prototype.dpi_x = 0;

            /**
             * CLICommand dpi_y.
             * @member {number} dpi_y
             * @memberof appcore.ripe.CLICommand
             * @instance
             */
            CLICommand.prototype.dpi_y = 0;

            /**
             * CLICommand icc.
             * @member {string} icc
             * @memberof appcore.ripe.CLICommand
             * @instance
             */
            CLICommand.prototype.icc = "";

            /**
             * CLICommand pb_rectangles.
             * @member {Array.<appcore.ripe.IRectangle>} pb_rectangles
             * @memberof appcore.ripe.CLICommand
             * @instance
             */
            CLICommand.prototype.pb_rectangles = $util.emptyArray;

            /**
             * CLICommand pb_threshold_c.
             * @member {number} pb_threshold_c
             * @memberof appcore.ripe.CLICommand
             * @instance
             */
            CLICommand.prototype.pb_threshold_c = 0;

            /**
             * CLICommand pb_threshold_m.
             * @member {number} pb_threshold_m
             * @memberof appcore.ripe.CLICommand
             * @instance
             */
            CLICommand.prototype.pb_threshold_m = 0;

            /**
             * CLICommand pb_threshold_y.
             * @member {number} pb_threshold_y
             * @memberof appcore.ripe.CLICommand
             * @instance
             */
            CLICommand.prototype.pb_threshold_y = 0;

            /**
             * CLICommand pb_threshold_k.
             * @member {number} pb_threshold_k
             * @memberof appcore.ripe.CLICommand
             * @instance
             */
            CLICommand.prototype.pb_threshold_k = 0;

            /**
             * CLICommand pb_compensation_c.
             * @member {number} pb_compensation_c
             * @memberof appcore.ripe.CLICommand
             * @instance
             */
            CLICommand.prototype.pb_compensation_c = 0;

            /**
             * CLICommand pb_compensation_m.
             * @member {number} pb_compensation_m
             * @memberof appcore.ripe.CLICommand
             * @instance
             */
            CLICommand.prototype.pb_compensation_m = 0;

            /**
             * CLICommand pb_compensation_y.
             * @member {number} pb_compensation_y
             * @memberof appcore.ripe.CLICommand
             * @instance
             */
            CLICommand.prototype.pb_compensation_y = 0;

            /**
             * CLICommand pb_compensation_k.
             * @member {number} pb_compensation_k
             * @memberof appcore.ripe.CLICommand
             * @instance
             */
            CLICommand.prototype.pb_compensation_k = 0;

            /**
             * CLICommand orf.
             * @member {boolean} orf
             * @memberof appcore.ripe.CLICommand
             * @instance
             */
            CLICommand.prototype.orf = false;

            /**
             * CLICommand intent_id.
             * @member {number} intent_id
             * @memberof appcore.ripe.CLICommand
             * @instance
             */
            CLICommand.prototype.intent_id = 0;

            /**
             * CLICommand device_link.
             * @member {Array.<string>} device_link
             * @memberof appcore.ripe.CLICommand
             * @instance
             */
            CLICommand.prototype.device_link = $util.emptyArray;

            /**
             * CLICommand trf.
             * @member {boolean} trf
             * @memberof appcore.ripe.CLICommand
             * @instance
             */
            CLICommand.prototype.trf = false;

            /**
             * CLICommand cb_rectangle.
             * @member {appcore.ripe.IRectangle|null|undefined} cb_rectangle
             * @memberof appcore.ripe.CLICommand
             * @instance
             */
            CLICommand.prototype.cb_rectangle = null;

            /**
             * CLICommand pages.
             * @member {string} pages
             * @memberof appcore.ripe.CLICommand
             * @instance
             */
            CLICommand.prototype.pages = "";

            /**
             * CLICommand compression.
             * @member {string} compression
             * @memberof appcore.ripe.CLICommand
             * @instance
             */
            CLICommand.prototype.compression = "";

            /**
             * CLICommand compression_level.
             * @member {number} compression_level
             * @memberof appcore.ripe.CLICommand
             * @instance
             */
            CLICommand.prototype.compression_level = 0;

            /**
             * CLICommand compression_workers.
             * @member {number} compression_workers
             * @memberof appcore.ripe.CLICommand
             * @instance
             */
            CLICommand.prototype.compression_workers = 0;

            /**
             * CLICommand page_workers.
             * @member {number} page_workers
             * @memberof appcore.ripe.CLICommand
             * @instance
             */
            CLICommand.prototype.page_workers = 0;

            /**
             * CLICommand band_size.
             * @member {number} band_size
             * @memberof appcore.ripe.CLICommand
             * @instance
             */
            CLICommand.prototype.band_size = 0;

            /**
             * CLICommand disable_icc_profiling.
             * @member {boolean} disable_icc_profiling
             * @memberof appcore.ripe.CLICommand
             * @instance
             */
            CLICommand.prototype.disable_icc_profiling = false;

            /**
             * Creates a new CLICommand instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.CLICommand
             * @static
             * @param {appcore.ripe.ICLICommand=} [properties] Properties to set
             * @returns {appcore.ripe.CLICommand} CLICommand instance
             */
            CLICommand.create = function create(properties) {
                return new CLICommand(properties);
            };

            /**
             * Encodes the specified CLICommand message. Does not implicitly {@link appcore.ripe.CLICommand.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.CLICommand
             * @static
             * @param {appcore.ripe.ICLICommand} message CLICommand message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CLICommand.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.input_file != null && Object.hasOwnProperty.call(message, "input_file"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.input_file);
                if (message.output_file != null && Object.hasOwnProperty.call(message, "output_file"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.output_file);
                if (message.page_number != null && Object.hasOwnProperty.call(message, "page_number"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.page_number);
                if (message.dpi_x != null && Object.hasOwnProperty.call(message, "dpi_x"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.dpi_x);
                if (message.dpi_y != null && Object.hasOwnProperty.call(message, "dpi_y"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.dpi_y);
                if (message.icc != null && Object.hasOwnProperty.call(message, "icc"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.icc);
                if (message.pb_rectangles != null && message.pb_rectangles.length)
                    for (let i = 0; i < message.pb_rectangles.length; ++i)
                        $root.appcore.ripe.Rectangle.encode(message.pb_rectangles[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.pb_threshold_c != null && Object.hasOwnProperty.call(message, "pb_threshold_c"))
                    writer.uint32(/* id 8, wireType 0 =*/64).int32(message.pb_threshold_c);
                if (message.pb_threshold_m != null && Object.hasOwnProperty.call(message, "pb_threshold_m"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int32(message.pb_threshold_m);
                if (message.pb_threshold_y != null && Object.hasOwnProperty.call(message, "pb_threshold_y"))
                    writer.uint32(/* id 10, wireType 0 =*/80).int32(message.pb_threshold_y);
                if (message.pb_threshold_k != null && Object.hasOwnProperty.call(message, "pb_threshold_k"))
                    writer.uint32(/* id 11, wireType 0 =*/88).int32(message.pb_threshold_k);
                if (message.pb_compensation_c != null && Object.hasOwnProperty.call(message, "pb_compensation_c"))
                    writer.uint32(/* id 12, wireType 5 =*/101).float(message.pb_compensation_c);
                if (message.pb_compensation_m != null && Object.hasOwnProperty.call(message, "pb_compensation_m"))
                    writer.uint32(/* id 13, wireType 5 =*/109).float(message.pb_compensation_m);
                if (message.pb_compensation_y != null && Object.hasOwnProperty.call(message, "pb_compensation_y"))
                    writer.uint32(/* id 14, wireType 5 =*/117).float(message.pb_compensation_y);
                if (message.pb_compensation_k != null && Object.hasOwnProperty.call(message, "pb_compensation_k"))
                    writer.uint32(/* id 15, wireType 5 =*/125).float(message.pb_compensation_k);
                if (message.orf != null && Object.hasOwnProperty.call(message, "orf"))
                    writer.uint32(/* id 16, wireType 0 =*/128).bool(message.orf);
                if (message.intent_id != null && Object.hasOwnProperty.call(message, "intent_id"))
                    writer.uint32(/* id 17, wireType 0 =*/136).int32(message.intent_id);
                if (message.device_link != null && message.device_link.length)
                    for (let i = 0; i < message.device_link.length; ++i)
                        writer.uint32(/* id 18, wireType 2 =*/146).string(message.device_link[i]);
                if (message.trf != null && Object.hasOwnProperty.call(message, "trf"))
                    writer.uint32(/* id 19, wireType 0 =*/152).bool(message.trf);
                if (message.cb_rectangle != null && Object.hasOwnProperty.call(message, "cb_rectangle"))
                    $root.appcore.ripe.Rectangle.encode(message.cb_rectangle, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
                if (message.pages != null && Object.hasOwnProperty.call(message, "pages"))
                    writer.uint32(/* id 21, wireType 2 =*/170).string(message.pages);
                if (message.compression != null && Object.hasOwnProperty.call(message, "compression"))
                    writer.uint32(/* id 22, wireType 2 =*/178).string(message.compression);
                if (message.compression_level != null && Object.hasOwnProperty.call(message, "compression_level"))
                    writer.uint32(/* id 23, wireType 0 =*/184).int32(message.compression_level);
                if (message.compression_workers != null && Object.hasOwnProperty.call(message, "compression_workers"))
                    writer.uint32(/* id 24, wireType 0 =*/192).int32(message.compression_workers);
                if (message.page_workers != null && Object.hasOwnProperty.call(message, "page_workers"))
                    writer.uint32(/* id 25, wireType 0 =*/200).int32(message.page_workers);
                if (message.band_size != null && Object.hasOwnProperty.call(message, "band_size"))
                    writer.uint32(/* id 26, wireType 0 =*/208).int32(message.band_size);
                if (message.disable_icc_profiling != null && Object.hasOwnProperty.call(message, "disable_icc_profiling"))
                    writer.uint32(/* id 27, wireType 0 =*/216).bool(message.disable_icc_profiling);
                return writer;
            };

            /**
             * Encodes the specified CLICommand message, length delimited. Does not implicitly {@link appcore.ripe.CLICommand.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.CLICommand
             * @static
             * @param {appcore.ripe.ICLICommand} message CLICommand message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CLICommand.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CLICommand message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.CLICommand
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.CLICommand} CLICommand
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CLICommand.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.CLICommand();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.input_file = reader.string();
                        break;
                    case 2:
                        message.output_file = reader.string();
                        break;
                    case 3:
                        message.page_number = reader.int32();
                        break;
                    case 4:
                        message.dpi_x = reader.int32();
                        break;
                    case 5:
                        message.dpi_y = reader.int32();
                        break;
                    case 6:
                        message.icc = reader.string();
                        break;
                    case 7:
                        if (!(message.pb_rectangles && message.pb_rectangles.length))
                            message.pb_rectangles = [];
                        message.pb_rectangles.push($root.appcore.ripe.Rectangle.decode(reader, reader.uint32()));
                        break;
                    case 8:
                        message.pb_threshold_c = reader.int32();
                        break;
                    case 9:
                        message.pb_threshold_m = reader.int32();
                        break;
                    case 10:
                        message.pb_threshold_y = reader.int32();
                        break;
                    case 11:
                        message.pb_threshold_k = reader.int32();
                        break;
                    case 12:
                        message.pb_compensation_c = reader.float();
                        break;
                    case 13:
                        message.pb_compensation_m = reader.float();
                        break;
                    case 14:
                        message.pb_compensation_y = reader.float();
                        break;
                    case 15:
                        message.pb_compensation_k = reader.float();
                        break;
                    case 16:
                        message.orf = reader.bool();
                        break;
                    case 17:
                        message.intent_id = reader.int32();
                        break;
                    case 18:
                        if (!(message.device_link && message.device_link.length))
                            message.device_link = [];
                        message.device_link.push(reader.string());
                        break;
                    case 19:
                        message.trf = reader.bool();
                        break;
                    case 20:
                        message.cb_rectangle = $root.appcore.ripe.Rectangle.decode(reader, reader.uint32());
                        break;
                    case 21:
                        message.pages = reader.string();
                        break;
                    case 22:
                        message.compression = reader.string();
                        break;
                    case 23:
                        message.compression_level = reader.int32();
                        break;
                    case 24:
                        message.compression_workers = reader.int32();
                        break;
                    case 25:
                        message.page_workers = reader.int32();
                        break;
                    case 26:
                        message.band_size = reader.int32();
                        break;
                    case 27:
                        message.disable_icc_profiling = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CLICommand message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.CLICommand
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.CLICommand} CLICommand
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CLICommand.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CLICommand message.
             * @function verify
             * @memberof appcore.ripe.CLICommand
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CLICommand.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.input_file != null && message.hasOwnProperty("input_file"))
                    if (!$util.isString(message.input_file))
                        return "input_file: string expected";
                if (message.output_file != null && message.hasOwnProperty("output_file"))
                    if (!$util.isString(message.output_file))
                        return "output_file: string expected";
                if (message.page_number != null && message.hasOwnProperty("page_number"))
                    if (!$util.isInteger(message.page_number))
                        return "page_number: integer expected";
                if (message.dpi_x != null && message.hasOwnProperty("dpi_x"))
                    if (!$util.isInteger(message.dpi_x))
                        return "dpi_x: integer expected";
                if (message.dpi_y != null && message.hasOwnProperty("dpi_y"))
                    if (!$util.isInteger(message.dpi_y))
                        return "dpi_y: integer expected";
                if (message.icc != null && message.hasOwnProperty("icc"))
                    if (!$util.isString(message.icc))
                        return "icc: string expected";
                if (message.pb_rectangles != null && message.hasOwnProperty("pb_rectangles")) {
                    if (!Array.isArray(message.pb_rectangles))
                        return "pb_rectangles: array expected";
                    for (let i = 0; i < message.pb_rectangles.length; ++i) {
                        let error = $root.appcore.ripe.Rectangle.verify(message.pb_rectangles[i]);
                        if (error)
                            return "pb_rectangles." + error;
                    }
                }
                if (message.pb_threshold_c != null && message.hasOwnProperty("pb_threshold_c"))
                    if (!$util.isInteger(message.pb_threshold_c))
                        return "pb_threshold_c: integer expected";
                if (message.pb_threshold_m != null && message.hasOwnProperty("pb_threshold_m"))
                    if (!$util.isInteger(message.pb_threshold_m))
                        return "pb_threshold_m: integer expected";
                if (message.pb_threshold_y != null && message.hasOwnProperty("pb_threshold_y"))
                    if (!$util.isInteger(message.pb_threshold_y))
                        return "pb_threshold_y: integer expected";
                if (message.pb_threshold_k != null && message.hasOwnProperty("pb_threshold_k"))
                    if (!$util.isInteger(message.pb_threshold_k))
                        return "pb_threshold_k: integer expected";
                if (message.pb_compensation_c != null && message.hasOwnProperty("pb_compensation_c"))
                    if (typeof message.pb_compensation_c !== "number")
                        return "pb_compensation_c: number expected";
                if (message.pb_compensation_m != null && message.hasOwnProperty("pb_compensation_m"))
                    if (typeof message.pb_compensation_m !== "number")
                        return "pb_compensation_m: number expected";
                if (message.pb_compensation_y != null && message.hasOwnProperty("pb_compensation_y"))
                    if (typeof message.pb_compensation_y !== "number")
                        return "pb_compensation_y: number expected";
                if (message.pb_compensation_k != null && message.hasOwnProperty("pb_compensation_k"))
                    if (typeof message.pb_compensation_k !== "number")
                        return "pb_compensation_k: number expected";
                if (message.orf != null && message.hasOwnProperty("orf"))
                    if (typeof message.orf !== "boolean")
                        return "orf: boolean expected";
                if (message.intent_id != null && message.hasOwnProperty("intent_id"))
                    if (!$util.isInteger(message.intent_id))
                        return "intent_id: integer expected";
                if (message.device_link != null && message.hasOwnProperty("device_link")) {
                    if (!Array.isArray(message.device_link))
                        return "device_link: array expected";
                    for (let i = 0; i < message.device_link.length; ++i)
                        if (!$util.isString(message.device_link[i]))
                            return "device_link: string[] expected";
                }
                if (message.trf != null && message.hasOwnProperty("trf"))
                    if (typeof message.trf !== "boolean")
                        return "trf: boolean expected";
                if (message.cb_rectangle != null && message.hasOwnProperty("cb_rectangle")) {
                    let error = $root.appcore.ripe.Rectangle.verify(message.cb_rectangle);
                    if (error)
                        return "cb_rectangle." + error;
                }
                if (message.pages != null && message.hasOwnProperty("pages"))
                    if (!$util.isString(message.pages))
                        return "pages: string expected";
                if (message.compression != null && message.hasOwnProperty("compression"))
                    if (!$util.isString(message.compression))
                        return "compression: string expected";
                if (message.compression_level != null && message.hasOwnProperty("compression_level"))
                    if (!$util.isInteger(message.compression_level))
                        return "compression_level: integer expected";
                if (message.compression_workers != null && message.hasOwnProperty("compression_workers"))
                    if (!$util.isInteger(message.compression_workers))
                        return "compression_workers: integer expected";
                if (message.page_workers != null && message.hasOwnProperty("page_workers"))
                    if (!$util.isInteger(message.page_workers))
                        return "page_workers: integer expected";
                if (message.band_size != null && message.hasOwnProperty("band_size"))
                    if (!$util.isInteger(message.band_size))
                        return "band_size: integer expected";
                if (message.disable_icc_profiling != null && message.hasOwnProperty("disable_icc_profiling"))
                    if (typeof message.disable_icc_profiling !== "boolean")
                        return "disable_icc_profiling: boolean expected";
                return null;
            };

            /**
             * Creates a CLICommand message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.CLICommand
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.CLICommand} CLICommand
             */
            CLICommand.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.CLICommand)
                    return object;
                let message = new $root.appcore.ripe.CLICommand();
                if (object.input_file != null)
                    message.input_file = String(object.input_file);
                if (object.output_file != null)
                    message.output_file = String(object.output_file);
                if (object.page_number != null)
                    message.page_number = object.page_number | 0;
                if (object.dpi_x != null)
                    message.dpi_x = object.dpi_x | 0;
                if (object.dpi_y != null)
                    message.dpi_y = object.dpi_y | 0;
                if (object.icc != null)
                    message.icc = String(object.icc);
                if (object.pb_rectangles) {
                    if (!Array.isArray(object.pb_rectangles))
                        throw TypeError(".appcore.ripe.CLICommand.pb_rectangles: array expected");
                    message.pb_rectangles = [];
                    for (let i = 0; i < object.pb_rectangles.length; ++i) {
                        if (typeof object.pb_rectangles[i] !== "object")
                            throw TypeError(".appcore.ripe.CLICommand.pb_rectangles: object expected");
                        message.pb_rectangles[i] = $root.appcore.ripe.Rectangle.fromObject(object.pb_rectangles[i]);
                    }
                }
                if (object.pb_threshold_c != null)
                    message.pb_threshold_c = object.pb_threshold_c | 0;
                if (object.pb_threshold_m != null)
                    message.pb_threshold_m = object.pb_threshold_m | 0;
                if (object.pb_threshold_y != null)
                    message.pb_threshold_y = object.pb_threshold_y | 0;
                if (object.pb_threshold_k != null)
                    message.pb_threshold_k = object.pb_threshold_k | 0;
                if (object.pb_compensation_c != null)
                    message.pb_compensation_c = Number(object.pb_compensation_c);
                if (object.pb_compensation_m != null)
                    message.pb_compensation_m = Number(object.pb_compensation_m);
                if (object.pb_compensation_y != null)
                    message.pb_compensation_y = Number(object.pb_compensation_y);
                if (object.pb_compensation_k != null)
                    message.pb_compensation_k = Number(object.pb_compensation_k);
                if (object.orf != null)
                    message.orf = Boolean(object.orf);
                if (object.intent_id != null)
                    message.intent_id = object.intent_id | 0;
                if (object.device_link) {
                    if (!Array.isArray(object.device_link))
                        throw TypeError(".appcore.ripe.CLICommand.device_link: array expected");
                    message.device_link = [];
                    for (let i = 0; i < object.device_link.length; ++i)
                        message.device_link[i] = String(object.device_link[i]);
                }
                if (object.trf != null)
                    message.trf = Boolean(object.trf);
                if (object.cb_rectangle != null) {
                    if (typeof object.cb_rectangle !== "object")
                        throw TypeError(".appcore.ripe.CLICommand.cb_rectangle: object expected");
                    message.cb_rectangle = $root.appcore.ripe.Rectangle.fromObject(object.cb_rectangle);
                }
                if (object.pages != null)
                    message.pages = String(object.pages);
                if (object.compression != null)
                    message.compression = String(object.compression);
                if (object.compression_level != null)
                    message.compression_level = object.compression_level | 0;
                if (object.compression_workers != null)
                    message.compression_workers = object.compression_workers | 0;
                if (object.page_workers != null)
                    message.page_workers = object.page_workers | 0;
                if (object.band_size != null)
                    message.band_size = object.band_size | 0;
                if (object.disable_icc_profiling != null)
                    message.disable_icc_profiling = Boolean(object.disable_icc_profiling);
                return message;
            };

            /**
             * Creates a plain object from a CLICommand message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.CLICommand
             * @static
             * @param {appcore.ripe.CLICommand} message CLICommand
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CLICommand.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults) {
                    object.pb_rectangles = [];
                    object.device_link = [];
                }
                if (options.defaults) {
                    object.input_file = "";
                    object.output_file = "";
                    object.page_number = 0;
                    object.dpi_x = 0;
                    object.dpi_y = 0;
                    object.icc = "";
                    object.pb_threshold_c = 0;
                    object.pb_threshold_m = 0;
                    object.pb_threshold_y = 0;
                    object.pb_threshold_k = 0;
                    object.pb_compensation_c = 0;
                    object.pb_compensation_m = 0;
                    object.pb_compensation_y = 0;
                    object.pb_compensation_k = 0;
                    object.orf = false;
                    object.intent_id = 0;
                    object.trf = false;
                    object.cb_rectangle = null;
                    object.pages = "";
                    object.compression = "";
                    object.compression_level = 0;
                    object.compression_workers = 0;
                    object.page_workers = 0;
                    object.band_size = 0;
                    object.disable_icc_profiling = false;
                }
                if (message.input_file != null && message.hasOwnProperty("input_file"))
                    object.input_file = message.input_file;
                if (message.output_file != null && message.hasOwnProperty("output_file"))
                    object.output_file = message.output_file;
                if (message.page_number != null && message.hasOwnProperty("page_number"))
                    object.page_number = message.page_number;
                if (message.dpi_x != null && message.hasOwnProperty("dpi_x"))
                    object.dpi_x = message.dpi_x;
                if (message.dpi_y != null && message.hasOwnProperty("dpi_y"))
                    object.dpi_y = message.dpi_y;
                if (message.icc != null && message.hasOwnProperty("icc"))
                    object.icc = message.icc;
                if (message.pb_rectangles && message.pb_rectangles.length) {
                    object.pb_rectangles = [];
                    for (let j = 0; j < message.pb_rectangles.length; ++j)
                        object.pb_rectangles[j] = $root.appcore.ripe.Rectangle.toObject(message.pb_rectangles[j], options);
                }
                if (message.pb_threshold_c != null && message.hasOwnProperty("pb_threshold_c"))
                    object.pb_threshold_c = message.pb_threshold_c;
                if (message.pb_threshold_m != null && message.hasOwnProperty("pb_threshold_m"))
                    object.pb_threshold_m = message.pb_threshold_m;
                if (message.pb_threshold_y != null && message.hasOwnProperty("pb_threshold_y"))
                    object.pb_threshold_y = message.pb_threshold_y;
                if (message.pb_threshold_k != null && message.hasOwnProperty("pb_threshold_k"))
                    object.pb_threshold_k = message.pb_threshold_k;
                if (message.pb_compensation_c != null && message.hasOwnProperty("pb_compensation_c"))
                    object.pb_compensation_c = options.json && !isFinite(message.pb_compensation_c) ? String(message.pb_compensation_c) : message.pb_compensation_c;
                if (message.pb_compensation_m != null && message.hasOwnProperty("pb_compensation_m"))
                    object.pb_compensation_m = options.json && !isFinite(message.pb_compensation_m) ? String(message.pb_compensation_m) : message.pb_compensation_m;
                if (message.pb_compensation_y != null && message.hasOwnProperty("pb_compensation_y"))
                    object.pb_compensation_y = options.json && !isFinite(message.pb_compensation_y) ? String(message.pb_compensation_y) : message.pb_compensation_y;
                if (message.pb_compensation_k != null && message.hasOwnProperty("pb_compensation_k"))
                    object.pb_compensation_k = options.json && !isFinite(message.pb_compensation_k) ? String(message.pb_compensation_k) : message.pb_compensation_k;
                if (message.orf != null && message.hasOwnProperty("orf"))
                    object.orf = message.orf;
                if (message.intent_id != null && message.hasOwnProperty("intent_id"))
                    object.intent_id = message.intent_id;
                if (message.device_link && message.device_link.length) {
                    object.device_link = [];
                    for (let j = 0; j < message.device_link.length; ++j)
                        object.device_link[j] = message.device_link[j];
                }
                if (message.trf != null && message.hasOwnProperty("trf"))
                    object.trf = message.trf;
                if (message.cb_rectangle != null && message.hasOwnProperty("cb_rectangle"))
                    object.cb_rectangle = $root.appcore.ripe.Rectangle.toObject(message.cb_rectangle, options);
                if (message.pages != null && message.hasOwnProperty("pages"))
                    object.pages = message.pages;
                if (message.compression != null && message.hasOwnProperty("compression"))
                    object.compression = message.compression;
                if (message.compression_level != null && message.hasOwnProperty("compression_level"))
                    object.compression_level = message.compression_level;
                if (message.compression_workers != null && message.hasOwnProperty("compression_workers"))
                    object.compression_workers = message.compression_workers;
                if (message.page_workers != null && message.hasOwnProperty("page_workers"))
                    object.page_workers = message.page_workers;
                if (message.band_size != null && message.hasOwnProperty("band_size"))
                    object.band_size = message.band_size;
                if (message.disable_icc_profiling != null && message.hasOwnProperty("disable_icc_profiling"))
                    object.disable_icc_profiling = message.disable_icc_profiling;
                return object;
            };

            /**
             * Converts this CLICommand to JSON.
             * @function toJSON
             * @memberof appcore.ripe.CLICommand
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CLICommand.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return CLICommand;
        })();

        ripe.CLICommandResult = (function() {

            /**
             * Properties of a CLICommandResult.
             * @memberof appcore.ripe
             * @interface ICLICommandResult
             * @property {number|null} [error_code] CLICommandResult error_code
             * @property {string|null} [error_msg] CLICommandResult error_msg
             */

            /**
             * Constructs a new CLICommandResult.
             * @memberof appcore.ripe
             * @classdesc Represents a CLICommandResult.
             * @implements ICLICommandResult
             * @constructor
             * @param {appcore.ripe.ICLICommandResult=} [properties] Properties to set
             */
            function CLICommandResult(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CLICommandResult error_code.
             * @member {number} error_code
             * @memberof appcore.ripe.CLICommandResult
             * @instance
             */
            CLICommandResult.prototype.error_code = 0;

            /**
             * CLICommandResult error_msg.
             * @member {string} error_msg
             * @memberof appcore.ripe.CLICommandResult
             * @instance
             */
            CLICommandResult.prototype.error_msg = "";

            /**
             * Creates a new CLICommandResult instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.CLICommandResult
             * @static
             * @param {appcore.ripe.ICLICommandResult=} [properties] Properties to set
             * @returns {appcore.ripe.CLICommandResult} CLICommandResult instance
             */
            CLICommandResult.create = function create(properties) {
                return new CLICommandResult(properties);
            };

            /**
             * Encodes the specified CLICommandResult message. Does not implicitly {@link appcore.ripe.CLICommandResult.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.CLICommandResult
             * @static
             * @param {appcore.ripe.ICLICommandResult} message CLICommandResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CLICommandResult.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.error_code != null && Object.hasOwnProperty.call(message, "error_code"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.error_code);
                if (message.error_msg != null && Object.hasOwnProperty.call(message, "error_msg"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.error_msg);
                return writer;
            };

            /**
             * Encodes the specified CLICommandResult message, length delimited. Does not implicitly {@link appcore.ripe.CLICommandResult.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.CLICommandResult
             * @static
             * @param {appcore.ripe.ICLICommandResult} message CLICommandResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CLICommandResult.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CLICommandResult message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.CLICommandResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.CLICommandResult} CLICommandResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CLICommandResult.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.CLICommandResult();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.error_code = reader.int32();
                        break;
                    case 2:
                        message.error_msg = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CLICommandResult message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.CLICommandResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.CLICommandResult} CLICommandResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CLICommandResult.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CLICommandResult message.
             * @function verify
             * @memberof appcore.ripe.CLICommandResult
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CLICommandResult.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.error_code != null && message.hasOwnProperty("error_code"))
                    if (!$util.isInteger(message.error_code))
                        return "error_code: integer expected";
                if (message.error_msg != null && message.hasOwnProperty("error_msg"))
                    if (!$util.isString(message.error_msg))
                        return "error_msg: string expected";
                return null;
            };

            /**
             * Creates a CLICommandResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.CLICommandResult
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.CLICommandResult} CLICommandResult
             */
            CLICommandResult.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.CLICommandResult)
                    return object;
                let message = new $root.appcore.ripe.CLICommandResult();
                if (object.error_code != null)
                    message.error_code = object.error_code | 0;
                if (object.error_msg != null)
                    message.error_msg = String(object.error_msg);
                return message;
            };

            /**
             * Creates a plain object from a CLICommandResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.CLICommandResult
             * @static
             * @param {appcore.ripe.CLICommandResult} message CLICommandResult
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CLICommandResult.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.error_code = 0;
                    object.error_msg = "";
                }
                if (message.error_code != null && message.hasOwnProperty("error_code"))
                    object.error_code = message.error_code;
                if (message.error_msg != null && message.hasOwnProperty("error_msg"))
                    object.error_msg = message.error_msg;
                return object;
            };

            /**
             * Converts this CLICommandResult to JSON.
             * @function toJSON
             * @memberof appcore.ripe.CLICommandResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CLICommandResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return CLICommandResult;
        })();

        ripe.ColorManagementConfig = (function() {

            /**
             * Properties of a ColorManagementConfig.
             * @memberof appcore.ripe
             * @interface IColorManagementConfig
             * @property {appcore.ripe.IColorManagementValues|null} [brightness] ColorManagementConfig brightness
             * @property {appcore.ripe.IColorManagementValues|null} [contrast] ColorManagementConfig contrast
             * @property {string|null} [config_guid] ColorManagementConfig config_guid
             * @property {string|null} [pdf_guid] ColorManagementConfig pdf_guid
             */

            /**
             * Constructs a new ColorManagementConfig.
             * @memberof appcore.ripe
             * @classdesc Represents a ColorManagementConfig.
             * @implements IColorManagementConfig
             * @constructor
             * @param {appcore.ripe.IColorManagementConfig=} [properties] Properties to set
             */
            function ColorManagementConfig(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ColorManagementConfig brightness.
             * @member {appcore.ripe.IColorManagementValues|null|undefined} brightness
             * @memberof appcore.ripe.ColorManagementConfig
             * @instance
             */
            ColorManagementConfig.prototype.brightness = null;

            /**
             * ColorManagementConfig contrast.
             * @member {appcore.ripe.IColorManagementValues|null|undefined} contrast
             * @memberof appcore.ripe.ColorManagementConfig
             * @instance
             */
            ColorManagementConfig.prototype.contrast = null;

            /**
             * ColorManagementConfig config_guid.
             * @member {string} config_guid
             * @memberof appcore.ripe.ColorManagementConfig
             * @instance
             */
            ColorManagementConfig.prototype.config_guid = "";

            /**
             * ColorManagementConfig pdf_guid.
             * @member {string} pdf_guid
             * @memberof appcore.ripe.ColorManagementConfig
             * @instance
             */
            ColorManagementConfig.prototype.pdf_guid = "";

            /**
             * Creates a new ColorManagementConfig instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.ColorManagementConfig
             * @static
             * @param {appcore.ripe.IColorManagementConfig=} [properties] Properties to set
             * @returns {appcore.ripe.ColorManagementConfig} ColorManagementConfig instance
             */
            ColorManagementConfig.create = function create(properties) {
                return new ColorManagementConfig(properties);
            };

            /**
             * Encodes the specified ColorManagementConfig message. Does not implicitly {@link appcore.ripe.ColorManagementConfig.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.ColorManagementConfig
             * @static
             * @param {appcore.ripe.IColorManagementConfig} message ColorManagementConfig message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ColorManagementConfig.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.brightness != null && Object.hasOwnProperty.call(message, "brightness"))
                    $root.appcore.ripe.ColorManagementValues.encode(message.brightness, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.contrast != null && Object.hasOwnProperty.call(message, "contrast"))
                    $root.appcore.ripe.ColorManagementValues.encode(message.contrast, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.config_guid != null && Object.hasOwnProperty.call(message, "config_guid"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.config_guid);
                if (message.pdf_guid != null && Object.hasOwnProperty.call(message, "pdf_guid"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.pdf_guid);
                return writer;
            };

            /**
             * Encodes the specified ColorManagementConfig message, length delimited. Does not implicitly {@link appcore.ripe.ColorManagementConfig.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.ColorManagementConfig
             * @static
             * @param {appcore.ripe.IColorManagementConfig} message ColorManagementConfig message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ColorManagementConfig.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ColorManagementConfig message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.ColorManagementConfig
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.ColorManagementConfig} ColorManagementConfig
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ColorManagementConfig.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.ColorManagementConfig();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.brightness = $root.appcore.ripe.ColorManagementValues.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.contrast = $root.appcore.ripe.ColorManagementValues.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.config_guid = reader.string();
                        break;
                    case 4:
                        message.pdf_guid = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ColorManagementConfig message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.ColorManagementConfig
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.ColorManagementConfig} ColorManagementConfig
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ColorManagementConfig.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ColorManagementConfig message.
             * @function verify
             * @memberof appcore.ripe.ColorManagementConfig
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ColorManagementConfig.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.brightness != null && message.hasOwnProperty("brightness")) {
                    let error = $root.appcore.ripe.ColorManagementValues.verify(message.brightness);
                    if (error)
                        return "brightness." + error;
                }
                if (message.contrast != null && message.hasOwnProperty("contrast")) {
                    let error = $root.appcore.ripe.ColorManagementValues.verify(message.contrast);
                    if (error)
                        return "contrast." + error;
                }
                if (message.config_guid != null && message.hasOwnProperty("config_guid"))
                    if (!$util.isString(message.config_guid))
                        return "config_guid: string expected";
                if (message.pdf_guid != null && message.hasOwnProperty("pdf_guid"))
                    if (!$util.isString(message.pdf_guid))
                        return "pdf_guid: string expected";
                return null;
            };

            /**
             * Creates a ColorManagementConfig message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.ColorManagementConfig
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.ColorManagementConfig} ColorManagementConfig
             */
            ColorManagementConfig.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.ColorManagementConfig)
                    return object;
                let message = new $root.appcore.ripe.ColorManagementConfig();
                if (object.brightness != null) {
                    if (typeof object.brightness !== "object")
                        throw TypeError(".appcore.ripe.ColorManagementConfig.brightness: object expected");
                    message.brightness = $root.appcore.ripe.ColorManagementValues.fromObject(object.brightness);
                }
                if (object.contrast != null) {
                    if (typeof object.contrast !== "object")
                        throw TypeError(".appcore.ripe.ColorManagementConfig.contrast: object expected");
                    message.contrast = $root.appcore.ripe.ColorManagementValues.fromObject(object.contrast);
                }
                if (object.config_guid != null)
                    message.config_guid = String(object.config_guid);
                if (object.pdf_guid != null)
                    message.pdf_guid = String(object.pdf_guid);
                return message;
            };

            /**
             * Creates a plain object from a ColorManagementConfig message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.ColorManagementConfig
             * @static
             * @param {appcore.ripe.ColorManagementConfig} message ColorManagementConfig
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ColorManagementConfig.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.brightness = null;
                    object.contrast = null;
                    object.config_guid = "";
                    object.pdf_guid = "";
                }
                if (message.brightness != null && message.hasOwnProperty("brightness"))
                    object.brightness = $root.appcore.ripe.ColorManagementValues.toObject(message.brightness, options);
                if (message.contrast != null && message.hasOwnProperty("contrast"))
                    object.contrast = $root.appcore.ripe.ColorManagementValues.toObject(message.contrast, options);
                if (message.config_guid != null && message.hasOwnProperty("config_guid"))
                    object.config_guid = message.config_guid;
                if (message.pdf_guid != null && message.hasOwnProperty("pdf_guid"))
                    object.pdf_guid = message.pdf_guid;
                return object;
            };

            /**
             * Converts this ColorManagementConfig to JSON.
             * @function toJSON
             * @memberof appcore.ripe.ColorManagementConfig
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ColorManagementConfig.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ColorManagementConfig;
        })();

        ripe.ColorManagementValues = (function() {

            /**
             * Properties of a ColorManagementValues.
             * @memberof appcore.ripe
             * @interface IColorManagementValues
             * @property {number|null} [all] ColorManagementValues all
             * @property {number|null} [C] ColorManagementValues C
             * @property {number|null} [M] ColorManagementValues M
             * @property {number|null} [Y] ColorManagementValues Y
             * @property {number|null} [K] ColorManagementValues K
             */

            /**
             * Constructs a new ColorManagementValues.
             * @memberof appcore.ripe
             * @classdesc Represents a ColorManagementValues.
             * @implements IColorManagementValues
             * @constructor
             * @param {appcore.ripe.IColorManagementValues=} [properties] Properties to set
             */
            function ColorManagementValues(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ColorManagementValues all.
             * @member {number} all
             * @memberof appcore.ripe.ColorManagementValues
             * @instance
             */
            ColorManagementValues.prototype.all = 0;

            /**
             * ColorManagementValues C.
             * @member {number} C
             * @memberof appcore.ripe.ColorManagementValues
             * @instance
             */
            ColorManagementValues.prototype.C = 0;

            /**
             * ColorManagementValues M.
             * @member {number} M
             * @memberof appcore.ripe.ColorManagementValues
             * @instance
             */
            ColorManagementValues.prototype.M = 0;

            /**
             * ColorManagementValues Y.
             * @member {number} Y
             * @memberof appcore.ripe.ColorManagementValues
             * @instance
             */
            ColorManagementValues.prototype.Y = 0;

            /**
             * ColorManagementValues K.
             * @member {number} K
             * @memberof appcore.ripe.ColorManagementValues
             * @instance
             */
            ColorManagementValues.prototype.K = 0;

            /**
             * Creates a new ColorManagementValues instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.ColorManagementValues
             * @static
             * @param {appcore.ripe.IColorManagementValues=} [properties] Properties to set
             * @returns {appcore.ripe.ColorManagementValues} ColorManagementValues instance
             */
            ColorManagementValues.create = function create(properties) {
                return new ColorManagementValues(properties);
            };

            /**
             * Encodes the specified ColorManagementValues message. Does not implicitly {@link appcore.ripe.ColorManagementValues.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.ColorManagementValues
             * @static
             * @param {appcore.ripe.IColorManagementValues} message ColorManagementValues message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ColorManagementValues.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.all != null && Object.hasOwnProperty.call(message, "all"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.all);
                if (message.C != null && Object.hasOwnProperty.call(message, "C"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.C);
                if (message.M != null && Object.hasOwnProperty.call(message, "M"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.M);
                if (message.Y != null && Object.hasOwnProperty.call(message, "Y"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.Y);
                if (message.K != null && Object.hasOwnProperty.call(message, "K"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.K);
                return writer;
            };

            /**
             * Encodes the specified ColorManagementValues message, length delimited. Does not implicitly {@link appcore.ripe.ColorManagementValues.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.ColorManagementValues
             * @static
             * @param {appcore.ripe.IColorManagementValues} message ColorManagementValues message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ColorManagementValues.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ColorManagementValues message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.ColorManagementValues
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.ColorManagementValues} ColorManagementValues
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ColorManagementValues.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.ColorManagementValues();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.all = reader.int32();
                        break;
                    case 2:
                        message.C = reader.int32();
                        break;
                    case 3:
                        message.M = reader.int32();
                        break;
                    case 4:
                        message.Y = reader.int32();
                        break;
                    case 5:
                        message.K = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ColorManagementValues message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.ColorManagementValues
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.ColorManagementValues} ColorManagementValues
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ColorManagementValues.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ColorManagementValues message.
             * @function verify
             * @memberof appcore.ripe.ColorManagementValues
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ColorManagementValues.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.all != null && message.hasOwnProperty("all"))
                    if (!$util.isInteger(message.all))
                        return "all: integer expected";
                if (message.C != null && message.hasOwnProperty("C"))
                    if (!$util.isInteger(message.C))
                        return "C: integer expected";
                if (message.M != null && message.hasOwnProperty("M"))
                    if (!$util.isInteger(message.M))
                        return "M: integer expected";
                if (message.Y != null && message.hasOwnProperty("Y"))
                    if (!$util.isInteger(message.Y))
                        return "Y: integer expected";
                if (message.K != null && message.hasOwnProperty("K"))
                    if (!$util.isInteger(message.K))
                        return "K: integer expected";
                return null;
            };

            /**
             * Creates a ColorManagementValues message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.ColorManagementValues
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.ColorManagementValues} ColorManagementValues
             */
            ColorManagementValues.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.ColorManagementValues)
                    return object;
                let message = new $root.appcore.ripe.ColorManagementValues();
                if (object.all != null)
                    message.all = object.all | 0;
                if (object.C != null)
                    message.C = object.C | 0;
                if (object.M != null)
                    message.M = object.M | 0;
                if (object.Y != null)
                    message.Y = object.Y | 0;
                if (object.K != null)
                    message.K = object.K | 0;
                return message;
            };

            /**
             * Creates a plain object from a ColorManagementValues message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.ColorManagementValues
             * @static
             * @param {appcore.ripe.ColorManagementValues} message ColorManagementValues
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ColorManagementValues.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.all = 0;
                    object.C = 0;
                    object.M = 0;
                    object.Y = 0;
                    object.K = 0;
                }
                if (message.all != null && message.hasOwnProperty("all"))
                    object.all = message.all;
                if (message.C != null && message.hasOwnProperty("C"))
                    object.C = message.C;
                if (message.M != null && message.hasOwnProperty("M"))
                    object.M = message.M;
                if (message.Y != null && message.hasOwnProperty("Y"))
                    object.Y = message.Y;
                if (message.K != null && message.hasOwnProperty("K"))
                    object.K = message.K;
                return object;
            };

            /**
             * Converts this ColorManagementValues to JSON.
             * @function toJSON
             * @memberof appcore.ripe.ColorManagementValues
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ColorManagementValues.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ColorManagementValues;
        })();

        ripe.ColorManagementConfigs = (function() {

            /**
             * Properties of a ColorManagementConfigs.
             * @memberof appcore.ripe
             * @interface IColorManagementConfigs
             * @property {Array.<appcore.ripe.IColorManagementConfig>|null} [configs] ColorManagementConfigs configs
             */

            /**
             * Constructs a new ColorManagementConfigs.
             * @memberof appcore.ripe
             * @classdesc Represents a ColorManagementConfigs.
             * @implements IColorManagementConfigs
             * @constructor
             * @param {appcore.ripe.IColorManagementConfigs=} [properties] Properties to set
             */
            function ColorManagementConfigs(properties) {
                this.configs = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ColorManagementConfigs configs.
             * @member {Array.<appcore.ripe.IColorManagementConfig>} configs
             * @memberof appcore.ripe.ColorManagementConfigs
             * @instance
             */
            ColorManagementConfigs.prototype.configs = $util.emptyArray;

            /**
             * Creates a new ColorManagementConfigs instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.ColorManagementConfigs
             * @static
             * @param {appcore.ripe.IColorManagementConfigs=} [properties] Properties to set
             * @returns {appcore.ripe.ColorManagementConfigs} ColorManagementConfigs instance
             */
            ColorManagementConfigs.create = function create(properties) {
                return new ColorManagementConfigs(properties);
            };

            /**
             * Encodes the specified ColorManagementConfigs message. Does not implicitly {@link appcore.ripe.ColorManagementConfigs.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.ColorManagementConfigs
             * @static
             * @param {appcore.ripe.IColorManagementConfigs} message ColorManagementConfigs message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ColorManagementConfigs.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.configs != null && message.configs.length)
                    for (let i = 0; i < message.configs.length; ++i)
                        $root.appcore.ripe.ColorManagementConfig.encode(message.configs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified ColorManagementConfigs message, length delimited. Does not implicitly {@link appcore.ripe.ColorManagementConfigs.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.ColorManagementConfigs
             * @static
             * @param {appcore.ripe.IColorManagementConfigs} message ColorManagementConfigs message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ColorManagementConfigs.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ColorManagementConfigs message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.ColorManagementConfigs
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.ColorManagementConfigs} ColorManagementConfigs
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ColorManagementConfigs.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.ColorManagementConfigs();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.configs && message.configs.length))
                            message.configs = [];
                        message.configs.push($root.appcore.ripe.ColorManagementConfig.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ColorManagementConfigs message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.ColorManagementConfigs
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.ColorManagementConfigs} ColorManagementConfigs
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ColorManagementConfigs.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ColorManagementConfigs message.
             * @function verify
             * @memberof appcore.ripe.ColorManagementConfigs
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ColorManagementConfigs.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.configs != null && message.hasOwnProperty("configs")) {
                    if (!Array.isArray(message.configs))
                        return "configs: array expected";
                    for (let i = 0; i < message.configs.length; ++i) {
                        let error = $root.appcore.ripe.ColorManagementConfig.verify(message.configs[i]);
                        if (error)
                            return "configs." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a ColorManagementConfigs message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.ColorManagementConfigs
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.ColorManagementConfigs} ColorManagementConfigs
             */
            ColorManagementConfigs.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.ColorManagementConfigs)
                    return object;
                let message = new $root.appcore.ripe.ColorManagementConfigs();
                if (object.configs) {
                    if (!Array.isArray(object.configs))
                        throw TypeError(".appcore.ripe.ColorManagementConfigs.configs: array expected");
                    message.configs = [];
                    for (let i = 0; i < object.configs.length; ++i) {
                        if (typeof object.configs[i] !== "object")
                            throw TypeError(".appcore.ripe.ColorManagementConfigs.configs: object expected");
                        message.configs[i] = $root.appcore.ripe.ColorManagementConfig.fromObject(object.configs[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a ColorManagementConfigs message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.ColorManagementConfigs
             * @static
             * @param {appcore.ripe.ColorManagementConfigs} message ColorManagementConfigs
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ColorManagementConfigs.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.configs = [];
                if (message.configs && message.configs.length) {
                    object.configs = [];
                    for (let j = 0; j < message.configs.length; ++j)
                        object.configs[j] = $root.appcore.ripe.ColorManagementConfig.toObject(message.configs[j], options);
                }
                return object;
            };

            /**
             * Converts this ColorManagementConfigs to JSON.
             * @function toJSON
             * @memberof appcore.ripe.ColorManagementConfigs
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ColorManagementConfigs.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ColorManagementConfigs;
        })();

        ripe.ConnectionState = (function() {

            /**
             * Properties of a ConnectionState.
             * @memberof appcore.ripe
             * @interface IConnectionState
             * @property {boolean|null} [connected] ConnectionState connected
             * @property {string|null} [qr_code_data] ConnectionState qr_code_data
             */

            /**
             * Constructs a new ConnectionState.
             * @memberof appcore.ripe
             * @classdesc Represents a ConnectionState.
             * @implements IConnectionState
             * @constructor
             * @param {appcore.ripe.IConnectionState=} [properties] Properties to set
             */
            function ConnectionState(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ConnectionState connected.
             * @member {boolean} connected
             * @memberof appcore.ripe.ConnectionState
             * @instance
             */
            ConnectionState.prototype.connected = false;

            /**
             * ConnectionState qr_code_data.
             * @member {string} qr_code_data
             * @memberof appcore.ripe.ConnectionState
             * @instance
             */
            ConnectionState.prototype.qr_code_data = "";

            /**
             * Creates a new ConnectionState instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.ConnectionState
             * @static
             * @param {appcore.ripe.IConnectionState=} [properties] Properties to set
             * @returns {appcore.ripe.ConnectionState} ConnectionState instance
             */
            ConnectionState.create = function create(properties) {
                return new ConnectionState(properties);
            };

            /**
             * Encodes the specified ConnectionState message. Does not implicitly {@link appcore.ripe.ConnectionState.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.ConnectionState
             * @static
             * @param {appcore.ripe.IConnectionState} message ConnectionState message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ConnectionState.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.connected != null && Object.hasOwnProperty.call(message, "connected"))
                    writer.uint32(/* id 1, wireType 0 =*/8).bool(message.connected);
                if (message.qr_code_data != null && Object.hasOwnProperty.call(message, "qr_code_data"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.qr_code_data);
                return writer;
            };

            /**
             * Encodes the specified ConnectionState message, length delimited. Does not implicitly {@link appcore.ripe.ConnectionState.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.ConnectionState
             * @static
             * @param {appcore.ripe.IConnectionState} message ConnectionState message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ConnectionState.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ConnectionState message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.ConnectionState
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.ConnectionState} ConnectionState
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ConnectionState.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.ConnectionState();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.connected = reader.bool();
                        break;
                    case 2:
                        message.qr_code_data = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ConnectionState message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.ConnectionState
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.ConnectionState} ConnectionState
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ConnectionState.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ConnectionState message.
             * @function verify
             * @memberof appcore.ripe.ConnectionState
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ConnectionState.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.connected != null && message.hasOwnProperty("connected"))
                    if (typeof message.connected !== "boolean")
                        return "connected: boolean expected";
                if (message.qr_code_data != null && message.hasOwnProperty("qr_code_data"))
                    if (!$util.isString(message.qr_code_data))
                        return "qr_code_data: string expected";
                return null;
            };

            /**
             * Creates a ConnectionState message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.ConnectionState
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.ConnectionState} ConnectionState
             */
            ConnectionState.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.ConnectionState)
                    return object;
                let message = new $root.appcore.ripe.ConnectionState();
                if (object.connected != null)
                    message.connected = Boolean(object.connected);
                if (object.qr_code_data != null)
                    message.qr_code_data = String(object.qr_code_data);
                return message;
            };

            /**
             * Creates a plain object from a ConnectionState message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.ConnectionState
             * @static
             * @param {appcore.ripe.ConnectionState} message ConnectionState
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ConnectionState.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.connected = false;
                    object.qr_code_data = "";
                }
                if (message.connected != null && message.hasOwnProperty("connected"))
                    object.connected = message.connected;
                if (message.qr_code_data != null && message.hasOwnProperty("qr_code_data"))
                    object.qr_code_data = message.qr_code_data;
                return object;
            };

            /**
             * Converts this ConnectionState to JSON.
             * @function toJSON
             * @memberof appcore.ripe.ConnectionState
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ConnectionState.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ConnectionState;
        })();

        ripe.Decrypt = (function() {

            /**
             * Properties of a Decrypt.
             * @memberof appcore.ripe
             * @interface IDecrypt
             * @property {Uint8Array|null} [data] Decrypt data
             * @property {Uint8Array|null} [iv] Decrypt iv
             * @property {string|null} [alias] Decrypt alias
             */

            /**
             * Constructs a new Decrypt.
             * @memberof appcore.ripe
             * @classdesc Represents a Decrypt.
             * @implements IDecrypt
             * @constructor
             * @param {appcore.ripe.IDecrypt=} [properties] Properties to set
             */
            function Decrypt(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Decrypt data.
             * @member {Uint8Array} data
             * @memberof appcore.ripe.Decrypt
             * @instance
             */
            Decrypt.prototype.data = $util.newBuffer([]);

            /**
             * Decrypt iv.
             * @member {Uint8Array} iv
             * @memberof appcore.ripe.Decrypt
             * @instance
             */
            Decrypt.prototype.iv = $util.newBuffer([]);

            /**
             * Decrypt alias.
             * @member {string} alias
             * @memberof appcore.ripe.Decrypt
             * @instance
             */
            Decrypt.prototype.alias = "";

            /**
             * Creates a new Decrypt instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.Decrypt
             * @static
             * @param {appcore.ripe.IDecrypt=} [properties] Properties to set
             * @returns {appcore.ripe.Decrypt} Decrypt instance
             */
            Decrypt.create = function create(properties) {
                return new Decrypt(properties);
            };

            /**
             * Encodes the specified Decrypt message. Does not implicitly {@link appcore.ripe.Decrypt.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.Decrypt
             * @static
             * @param {appcore.ripe.IDecrypt} message Decrypt message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Decrypt.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                    writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.data);
                if (message.iv != null && Object.hasOwnProperty.call(message, "iv"))
                    writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.iv);
                if (message.alias != null && Object.hasOwnProperty.call(message, "alias"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.alias);
                return writer;
            };

            /**
             * Encodes the specified Decrypt message, length delimited. Does not implicitly {@link appcore.ripe.Decrypt.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.Decrypt
             * @static
             * @param {appcore.ripe.IDecrypt} message Decrypt message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Decrypt.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Decrypt message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.Decrypt
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.Decrypt} Decrypt
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Decrypt.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.Decrypt();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.data = reader.bytes();
                        break;
                    case 2:
                        message.iv = reader.bytes();
                        break;
                    case 3:
                        message.alias = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Decrypt message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.Decrypt
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.Decrypt} Decrypt
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Decrypt.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Decrypt message.
             * @function verify
             * @memberof appcore.ripe.Decrypt
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Decrypt.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.data != null && message.hasOwnProperty("data"))
                    if (!(message.data && typeof message.data.length === "number" || $util.isString(message.data)))
                        return "data: buffer expected";
                if (message.iv != null && message.hasOwnProperty("iv"))
                    if (!(message.iv && typeof message.iv.length === "number" || $util.isString(message.iv)))
                        return "iv: buffer expected";
                if (message.alias != null && message.hasOwnProperty("alias"))
                    if (!$util.isString(message.alias))
                        return "alias: string expected";
                return null;
            };

            /**
             * Creates a Decrypt message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.Decrypt
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.Decrypt} Decrypt
             */
            Decrypt.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.Decrypt)
                    return object;
                let message = new $root.appcore.ripe.Decrypt();
                if (object.data != null)
                    if (typeof object.data === "string")
                        $util.base64.decode(object.data, message.data = $util.newBuffer($util.base64.length(object.data)), 0);
                    else if (object.data.length)
                        message.data = object.data;
                if (object.iv != null)
                    if (typeof object.iv === "string")
                        $util.base64.decode(object.iv, message.iv = $util.newBuffer($util.base64.length(object.iv)), 0);
                    else if (object.iv.length)
                        message.iv = object.iv;
                if (object.alias != null)
                    message.alias = String(object.alias);
                return message;
            };

            /**
             * Creates a plain object from a Decrypt message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.Decrypt
             * @static
             * @param {appcore.ripe.Decrypt} message Decrypt
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Decrypt.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if (options.bytes === String)
                        object.data = "";
                    else {
                        object.data = [];
                        if (options.bytes !== Array)
                            object.data = $util.newBuffer(object.data);
                    }
                    if (options.bytes === String)
                        object.iv = "";
                    else {
                        object.iv = [];
                        if (options.bytes !== Array)
                            object.iv = $util.newBuffer(object.iv);
                    }
                    object.alias = "";
                }
                if (message.data != null && message.hasOwnProperty("data"))
                    object.data = options.bytes === String ? $util.base64.encode(message.data, 0, message.data.length) : options.bytes === Array ? Array.prototype.slice.call(message.data) : message.data;
                if (message.iv != null && message.hasOwnProperty("iv"))
                    object.iv = options.bytes === String ? $util.base64.encode(message.iv, 0, message.iv.length) : options.bytes === Array ? Array.prototype.slice.call(message.iv) : message.iv;
                if (message.alias != null && message.hasOwnProperty("alias"))
                    object.alias = message.alias;
                return object;
            };

            /**
             * Converts this Decrypt to JSON.
             * @function toJSON
             * @memberof appcore.ripe.Decrypt
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Decrypt.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Decrypt;
        })();

        ripe.DecryptResult = (function() {

            /**
             * Properties of a DecryptResult.
             * @memberof appcore.ripe
             * @interface IDecryptResult
             * @property {Uint8Array|null} [data] DecryptResult data
             */

            /**
             * Constructs a new DecryptResult.
             * @memberof appcore.ripe
             * @classdesc Represents a DecryptResult.
             * @implements IDecryptResult
             * @constructor
             * @param {appcore.ripe.IDecryptResult=} [properties] Properties to set
             */
            function DecryptResult(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DecryptResult data.
             * @member {Uint8Array} data
             * @memberof appcore.ripe.DecryptResult
             * @instance
             */
            DecryptResult.prototype.data = $util.newBuffer([]);

            /**
             * Creates a new DecryptResult instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.DecryptResult
             * @static
             * @param {appcore.ripe.IDecryptResult=} [properties] Properties to set
             * @returns {appcore.ripe.DecryptResult} DecryptResult instance
             */
            DecryptResult.create = function create(properties) {
                return new DecryptResult(properties);
            };

            /**
             * Encodes the specified DecryptResult message. Does not implicitly {@link appcore.ripe.DecryptResult.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.DecryptResult
             * @static
             * @param {appcore.ripe.IDecryptResult} message DecryptResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DecryptResult.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                    writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.data);
                return writer;
            };

            /**
             * Encodes the specified DecryptResult message, length delimited. Does not implicitly {@link appcore.ripe.DecryptResult.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.DecryptResult
             * @static
             * @param {appcore.ripe.IDecryptResult} message DecryptResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DecryptResult.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DecryptResult message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.DecryptResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.DecryptResult} DecryptResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DecryptResult.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.DecryptResult();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.data = reader.bytes();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DecryptResult message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.DecryptResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.DecryptResult} DecryptResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DecryptResult.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DecryptResult message.
             * @function verify
             * @memberof appcore.ripe.DecryptResult
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DecryptResult.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.data != null && message.hasOwnProperty("data"))
                    if (!(message.data && typeof message.data.length === "number" || $util.isString(message.data)))
                        return "data: buffer expected";
                return null;
            };

            /**
             * Creates a DecryptResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.DecryptResult
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.DecryptResult} DecryptResult
             */
            DecryptResult.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.DecryptResult)
                    return object;
                let message = new $root.appcore.ripe.DecryptResult();
                if (object.data != null)
                    if (typeof object.data === "string")
                        $util.base64.decode(object.data, message.data = $util.newBuffer($util.base64.length(object.data)), 0);
                    else if (object.data.length)
                        message.data = object.data;
                return message;
            };

            /**
             * Creates a plain object from a DecryptResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.DecryptResult
             * @static
             * @param {appcore.ripe.DecryptResult} message DecryptResult
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DecryptResult.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    if (options.bytes === String)
                        object.data = "";
                    else {
                        object.data = [];
                        if (options.bytes !== Array)
                            object.data = $util.newBuffer(object.data);
                    }
                if (message.data != null && message.hasOwnProperty("data"))
                    object.data = options.bytes === String ? $util.base64.encode(message.data, 0, message.data.length) : options.bytes === Array ? Array.prototype.slice.call(message.data) : message.data;
                return object;
            };

            /**
             * Converts this DecryptResult to JSON.
             * @function toJSON
             * @memberof appcore.ripe.DecryptResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DecryptResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return DecryptResult;
        })();

        ripe.DeleteICCProfile = (function() {

            /**
             * Properties of a DeleteICCProfile.
             * @memberof appcore.ripe
             * @interface IDeleteICCProfile
             * @property {string|null} [guid] DeleteICCProfile guid
             */

            /**
             * Constructs a new DeleteICCProfile.
             * @memberof appcore.ripe
             * @classdesc Represents a DeleteICCProfile.
             * @implements IDeleteICCProfile
             * @constructor
             * @param {appcore.ripe.IDeleteICCProfile=} [properties] Properties to set
             */
            function DeleteICCProfile(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeleteICCProfile guid.
             * @member {string} guid
             * @memberof appcore.ripe.DeleteICCProfile
             * @instance
             */
            DeleteICCProfile.prototype.guid = "";

            /**
             * Creates a new DeleteICCProfile instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.DeleteICCProfile
             * @static
             * @param {appcore.ripe.IDeleteICCProfile=} [properties] Properties to set
             * @returns {appcore.ripe.DeleteICCProfile} DeleteICCProfile instance
             */
            DeleteICCProfile.create = function create(properties) {
                return new DeleteICCProfile(properties);
            };

            /**
             * Encodes the specified DeleteICCProfile message. Does not implicitly {@link appcore.ripe.DeleteICCProfile.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.DeleteICCProfile
             * @static
             * @param {appcore.ripe.IDeleteICCProfile} message DeleteICCProfile message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteICCProfile.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.guid != null && Object.hasOwnProperty.call(message, "guid"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.guid);
                return writer;
            };

            /**
             * Encodes the specified DeleteICCProfile message, length delimited. Does not implicitly {@link appcore.ripe.DeleteICCProfile.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.DeleteICCProfile
             * @static
             * @param {appcore.ripe.IDeleteICCProfile} message DeleteICCProfile message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteICCProfile.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DeleteICCProfile message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.DeleteICCProfile
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.DeleteICCProfile} DeleteICCProfile
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteICCProfile.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.DeleteICCProfile();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.guid = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DeleteICCProfile message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.DeleteICCProfile
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.DeleteICCProfile} DeleteICCProfile
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteICCProfile.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DeleteICCProfile message.
             * @function verify
             * @memberof appcore.ripe.DeleteICCProfile
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeleteICCProfile.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.guid != null && message.hasOwnProperty("guid"))
                    if (!$util.isString(message.guid))
                        return "guid: string expected";
                return null;
            };

            /**
             * Creates a DeleteICCProfile message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.DeleteICCProfile
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.DeleteICCProfile} DeleteICCProfile
             */
            DeleteICCProfile.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.DeleteICCProfile)
                    return object;
                let message = new $root.appcore.ripe.DeleteICCProfile();
                if (object.guid != null)
                    message.guid = String(object.guid);
                return message;
            };

            /**
             * Creates a plain object from a DeleteICCProfile message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.DeleteICCProfile
             * @static
             * @param {appcore.ripe.DeleteICCProfile} message DeleteICCProfile
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteICCProfile.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.guid = "";
                if (message.guid != null && message.hasOwnProperty("guid"))
                    object.guid = message.guid;
                return object;
            };

            /**
             * Converts this DeleteICCProfile to JSON.
             * @function toJSON
             * @memberof appcore.ripe.DeleteICCProfile
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteICCProfile.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return DeleteICCProfile;
        })();

        ripe.DeleteICCProfileResult = (function() {

            /**
             * Properties of a DeleteICCProfileResult.
             * @memberof appcore.ripe
             * @interface IDeleteICCProfileResult
             * @property {number|null} [error_code] DeleteICCProfileResult error_code
             * @property {string|null} [guid] DeleteICCProfileResult guid
             * @property {string|null} [name] DeleteICCProfileResult name
             */

            /**
             * Constructs a new DeleteICCProfileResult.
             * @memberof appcore.ripe
             * @classdesc Represents a DeleteICCProfileResult.
             * @implements IDeleteICCProfileResult
             * @constructor
             * @param {appcore.ripe.IDeleteICCProfileResult=} [properties] Properties to set
             */
            function DeleteICCProfileResult(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeleteICCProfileResult error_code.
             * @member {number} error_code
             * @memberof appcore.ripe.DeleteICCProfileResult
             * @instance
             */
            DeleteICCProfileResult.prototype.error_code = 0;

            /**
             * DeleteICCProfileResult guid.
             * @member {string} guid
             * @memberof appcore.ripe.DeleteICCProfileResult
             * @instance
             */
            DeleteICCProfileResult.prototype.guid = "";

            /**
             * DeleteICCProfileResult name.
             * @member {string} name
             * @memberof appcore.ripe.DeleteICCProfileResult
             * @instance
             */
            DeleteICCProfileResult.prototype.name = "";

            /**
             * Creates a new DeleteICCProfileResult instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.DeleteICCProfileResult
             * @static
             * @param {appcore.ripe.IDeleteICCProfileResult=} [properties] Properties to set
             * @returns {appcore.ripe.DeleteICCProfileResult} DeleteICCProfileResult instance
             */
            DeleteICCProfileResult.create = function create(properties) {
                return new DeleteICCProfileResult(properties);
            };

            /**
             * Encodes the specified DeleteICCProfileResult message. Does not implicitly {@link appcore.ripe.DeleteICCProfileResult.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.DeleteICCProfileResult
             * @static
             * @param {appcore.ripe.IDeleteICCProfileResult} message DeleteICCProfileResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteICCProfileResult.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.error_code != null && Object.hasOwnProperty.call(message, "error_code"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.error_code);
                if (message.guid != null && Object.hasOwnProperty.call(message, "guid"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.guid);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
                return writer;
            };

            /**
             * Encodes the specified DeleteICCProfileResult message, length delimited. Does not implicitly {@link appcore.ripe.DeleteICCProfileResult.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.DeleteICCProfileResult
             * @static
             * @param {appcore.ripe.IDeleteICCProfileResult} message DeleteICCProfileResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteICCProfileResult.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DeleteICCProfileResult message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.DeleteICCProfileResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.DeleteICCProfileResult} DeleteICCProfileResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteICCProfileResult.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.DeleteICCProfileResult();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.error_code = reader.int32();
                        break;
                    case 2:
                        message.guid = reader.string();
                        break;
                    case 3:
                        message.name = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DeleteICCProfileResult message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.DeleteICCProfileResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.DeleteICCProfileResult} DeleteICCProfileResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteICCProfileResult.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DeleteICCProfileResult message.
             * @function verify
             * @memberof appcore.ripe.DeleteICCProfileResult
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeleteICCProfileResult.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.error_code != null && message.hasOwnProperty("error_code"))
                    if (!$util.isInteger(message.error_code))
                        return "error_code: integer expected";
                if (message.guid != null && message.hasOwnProperty("guid"))
                    if (!$util.isString(message.guid))
                        return "guid: string expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                return null;
            };

            /**
             * Creates a DeleteICCProfileResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.DeleteICCProfileResult
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.DeleteICCProfileResult} DeleteICCProfileResult
             */
            DeleteICCProfileResult.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.DeleteICCProfileResult)
                    return object;
                let message = new $root.appcore.ripe.DeleteICCProfileResult();
                if (object.error_code != null)
                    message.error_code = object.error_code | 0;
                if (object.guid != null)
                    message.guid = String(object.guid);
                if (object.name != null)
                    message.name = String(object.name);
                return message;
            };

            /**
             * Creates a plain object from a DeleteICCProfileResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.DeleteICCProfileResult
             * @static
             * @param {appcore.ripe.DeleteICCProfileResult} message DeleteICCProfileResult
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteICCProfileResult.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.error_code = 0;
                    object.guid = "";
                    object.name = "";
                }
                if (message.error_code != null && message.hasOwnProperty("error_code"))
                    object.error_code = message.error_code;
                if (message.guid != null && message.hasOwnProperty("guid"))
                    object.guid = message.guid;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                return object;
            };

            /**
             * Converts this DeleteICCProfileResult to JSON.
             * @function toJSON
             * @memberof appcore.ripe.DeleteICCProfileResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteICCProfileResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return DeleteICCProfileResult;
        })();

        ripe.DeleteInputPDF = (function() {

            /**
             * Properties of a DeleteInputPDF.
             * @memberof appcore.ripe
             * @interface IDeleteInputPDF
             * @property {string|null} [guid] DeleteInputPDF guid
             */

            /**
             * Constructs a new DeleteInputPDF.
             * @memberof appcore.ripe
             * @classdesc Represents a DeleteInputPDF.
             * @implements IDeleteInputPDF
             * @constructor
             * @param {appcore.ripe.IDeleteInputPDF=} [properties] Properties to set
             */
            function DeleteInputPDF(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeleteInputPDF guid.
             * @member {string} guid
             * @memberof appcore.ripe.DeleteInputPDF
             * @instance
             */
            DeleteInputPDF.prototype.guid = "";

            /**
             * Creates a new DeleteInputPDF instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.DeleteInputPDF
             * @static
             * @param {appcore.ripe.IDeleteInputPDF=} [properties] Properties to set
             * @returns {appcore.ripe.DeleteInputPDF} DeleteInputPDF instance
             */
            DeleteInputPDF.create = function create(properties) {
                return new DeleteInputPDF(properties);
            };

            /**
             * Encodes the specified DeleteInputPDF message. Does not implicitly {@link appcore.ripe.DeleteInputPDF.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.DeleteInputPDF
             * @static
             * @param {appcore.ripe.IDeleteInputPDF} message DeleteInputPDF message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteInputPDF.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.guid != null && Object.hasOwnProperty.call(message, "guid"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.guid);
                return writer;
            };

            /**
             * Encodes the specified DeleteInputPDF message, length delimited. Does not implicitly {@link appcore.ripe.DeleteInputPDF.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.DeleteInputPDF
             * @static
             * @param {appcore.ripe.IDeleteInputPDF} message DeleteInputPDF message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteInputPDF.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DeleteInputPDF message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.DeleteInputPDF
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.DeleteInputPDF} DeleteInputPDF
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteInputPDF.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.DeleteInputPDF();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.guid = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DeleteInputPDF message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.DeleteInputPDF
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.DeleteInputPDF} DeleteInputPDF
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteInputPDF.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DeleteInputPDF message.
             * @function verify
             * @memberof appcore.ripe.DeleteInputPDF
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeleteInputPDF.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.guid != null && message.hasOwnProperty("guid"))
                    if (!$util.isString(message.guid))
                        return "guid: string expected";
                return null;
            };

            /**
             * Creates a DeleteInputPDF message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.DeleteInputPDF
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.DeleteInputPDF} DeleteInputPDF
             */
            DeleteInputPDF.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.DeleteInputPDF)
                    return object;
                let message = new $root.appcore.ripe.DeleteInputPDF();
                if (object.guid != null)
                    message.guid = String(object.guid);
                return message;
            };

            /**
             * Creates a plain object from a DeleteInputPDF message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.DeleteInputPDF
             * @static
             * @param {appcore.ripe.DeleteInputPDF} message DeleteInputPDF
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteInputPDF.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.guid = "";
                if (message.guid != null && message.hasOwnProperty("guid"))
                    object.guid = message.guid;
                return object;
            };

            /**
             * Converts this DeleteInputPDF to JSON.
             * @function toJSON
             * @memberof appcore.ripe.DeleteInputPDF
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteInputPDF.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return DeleteInputPDF;
        })();

        ripe.DeleteInputPDFResult = (function() {

            /**
             * Properties of a DeleteInputPDFResult.
             * @memberof appcore.ripe
             * @interface IDeleteInputPDFResult
             * @property {number|null} [error_code] DeleteInputPDFResult error_code
             * @property {string|null} [guid] DeleteInputPDFResult guid
             * @property {string|null} [filename] DeleteInputPDFResult filename
             */

            /**
             * Constructs a new DeleteInputPDFResult.
             * @memberof appcore.ripe
             * @classdesc Represents a DeleteInputPDFResult.
             * @implements IDeleteInputPDFResult
             * @constructor
             * @param {appcore.ripe.IDeleteInputPDFResult=} [properties] Properties to set
             */
            function DeleteInputPDFResult(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeleteInputPDFResult error_code.
             * @member {number} error_code
             * @memberof appcore.ripe.DeleteInputPDFResult
             * @instance
             */
            DeleteInputPDFResult.prototype.error_code = 0;

            /**
             * DeleteInputPDFResult guid.
             * @member {string} guid
             * @memberof appcore.ripe.DeleteInputPDFResult
             * @instance
             */
            DeleteInputPDFResult.prototype.guid = "";

            /**
             * DeleteInputPDFResult filename.
             * @member {string} filename
             * @memberof appcore.ripe.DeleteInputPDFResult
             * @instance
             */
            DeleteInputPDFResult.prototype.filename = "";

            /**
             * Creates a new DeleteInputPDFResult instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.DeleteInputPDFResult
             * @static
             * @param {appcore.ripe.IDeleteInputPDFResult=} [properties] Properties to set
             * @returns {appcore.ripe.DeleteInputPDFResult} DeleteInputPDFResult instance
             */
            DeleteInputPDFResult.create = function create(properties) {
                return new DeleteInputPDFResult(properties);
            };

            /**
             * Encodes the specified DeleteInputPDFResult message. Does not implicitly {@link appcore.ripe.DeleteInputPDFResult.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.DeleteInputPDFResult
             * @static
             * @param {appcore.ripe.IDeleteInputPDFResult} message DeleteInputPDFResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteInputPDFResult.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.error_code != null && Object.hasOwnProperty.call(message, "error_code"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.error_code);
                if (message.guid != null && Object.hasOwnProperty.call(message, "guid"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.guid);
                if (message.filename != null && Object.hasOwnProperty.call(message, "filename"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.filename);
                return writer;
            };

            /**
             * Encodes the specified DeleteInputPDFResult message, length delimited. Does not implicitly {@link appcore.ripe.DeleteInputPDFResult.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.DeleteInputPDFResult
             * @static
             * @param {appcore.ripe.IDeleteInputPDFResult} message DeleteInputPDFResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteInputPDFResult.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DeleteInputPDFResult message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.DeleteInputPDFResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.DeleteInputPDFResult} DeleteInputPDFResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteInputPDFResult.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.DeleteInputPDFResult();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.error_code = reader.int32();
                        break;
                    case 2:
                        message.guid = reader.string();
                        break;
                    case 3:
                        message.filename = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DeleteInputPDFResult message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.DeleteInputPDFResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.DeleteInputPDFResult} DeleteInputPDFResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteInputPDFResult.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DeleteInputPDFResult message.
             * @function verify
             * @memberof appcore.ripe.DeleteInputPDFResult
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeleteInputPDFResult.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.error_code != null && message.hasOwnProperty("error_code"))
                    if (!$util.isInteger(message.error_code))
                        return "error_code: integer expected";
                if (message.guid != null && message.hasOwnProperty("guid"))
                    if (!$util.isString(message.guid))
                        return "guid: string expected";
                if (message.filename != null && message.hasOwnProperty("filename"))
                    if (!$util.isString(message.filename))
                        return "filename: string expected";
                return null;
            };

            /**
             * Creates a DeleteInputPDFResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.DeleteInputPDFResult
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.DeleteInputPDFResult} DeleteInputPDFResult
             */
            DeleteInputPDFResult.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.DeleteInputPDFResult)
                    return object;
                let message = new $root.appcore.ripe.DeleteInputPDFResult();
                if (object.error_code != null)
                    message.error_code = object.error_code | 0;
                if (object.guid != null)
                    message.guid = String(object.guid);
                if (object.filename != null)
                    message.filename = String(object.filename);
                return message;
            };

            /**
             * Creates a plain object from a DeleteInputPDFResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.DeleteInputPDFResult
             * @static
             * @param {appcore.ripe.DeleteInputPDFResult} message DeleteInputPDFResult
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteInputPDFResult.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.error_code = 0;
                    object.guid = "";
                    object.filename = "";
                }
                if (message.error_code != null && message.hasOwnProperty("error_code"))
                    object.error_code = message.error_code;
                if (message.guid != null && message.hasOwnProperty("guid"))
                    object.guid = message.guid;
                if (message.filename != null && message.hasOwnProperty("filename"))
                    object.filename = message.filename;
                return object;
            };

            /**
             * Converts this DeleteInputPDFResult to JSON.
             * @function toJSON
             * @memberof appcore.ripe.DeleteInputPDFResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteInputPDFResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return DeleteInputPDFResult;
        })();

        ripe.DeletePGMFile = (function() {

            /**
             * Properties of a DeletePGMFile.
             * @memberof appcore.ripe
             * @interface IDeletePGMFile
             * @property {string|null} [guid] DeletePGMFile guid
             * @property {string|null} [color] DeletePGMFile color
             */

            /**
             * Constructs a new DeletePGMFile.
             * @memberof appcore.ripe
             * @classdesc Represents a DeletePGMFile.
             * @implements IDeletePGMFile
             * @constructor
             * @param {appcore.ripe.IDeletePGMFile=} [properties] Properties to set
             */
            function DeletePGMFile(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeletePGMFile guid.
             * @member {string} guid
             * @memberof appcore.ripe.DeletePGMFile
             * @instance
             */
            DeletePGMFile.prototype.guid = "";

            /**
             * DeletePGMFile color.
             * @member {string} color
             * @memberof appcore.ripe.DeletePGMFile
             * @instance
             */
            DeletePGMFile.prototype.color = "";

            /**
             * Creates a new DeletePGMFile instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.DeletePGMFile
             * @static
             * @param {appcore.ripe.IDeletePGMFile=} [properties] Properties to set
             * @returns {appcore.ripe.DeletePGMFile} DeletePGMFile instance
             */
            DeletePGMFile.create = function create(properties) {
                return new DeletePGMFile(properties);
            };

            /**
             * Encodes the specified DeletePGMFile message. Does not implicitly {@link appcore.ripe.DeletePGMFile.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.DeletePGMFile
             * @static
             * @param {appcore.ripe.IDeletePGMFile} message DeletePGMFile message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeletePGMFile.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.guid != null && Object.hasOwnProperty.call(message, "guid"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.guid);
                if (message.color != null && Object.hasOwnProperty.call(message, "color"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.color);
                return writer;
            };

            /**
             * Encodes the specified DeletePGMFile message, length delimited. Does not implicitly {@link appcore.ripe.DeletePGMFile.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.DeletePGMFile
             * @static
             * @param {appcore.ripe.IDeletePGMFile} message DeletePGMFile message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeletePGMFile.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DeletePGMFile message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.DeletePGMFile
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.DeletePGMFile} DeletePGMFile
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeletePGMFile.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.DeletePGMFile();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.guid = reader.string();
                        break;
                    case 2:
                        message.color = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DeletePGMFile message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.DeletePGMFile
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.DeletePGMFile} DeletePGMFile
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeletePGMFile.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DeletePGMFile message.
             * @function verify
             * @memberof appcore.ripe.DeletePGMFile
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeletePGMFile.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.guid != null && message.hasOwnProperty("guid"))
                    if (!$util.isString(message.guid))
                        return "guid: string expected";
                if (message.color != null && message.hasOwnProperty("color"))
                    if (!$util.isString(message.color))
                        return "color: string expected";
                return null;
            };

            /**
             * Creates a DeletePGMFile message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.DeletePGMFile
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.DeletePGMFile} DeletePGMFile
             */
            DeletePGMFile.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.DeletePGMFile)
                    return object;
                let message = new $root.appcore.ripe.DeletePGMFile();
                if (object.guid != null)
                    message.guid = String(object.guid);
                if (object.color != null)
                    message.color = String(object.color);
                return message;
            };

            /**
             * Creates a plain object from a DeletePGMFile message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.DeletePGMFile
             * @static
             * @param {appcore.ripe.DeletePGMFile} message DeletePGMFile
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeletePGMFile.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.guid = "";
                    object.color = "";
                }
                if (message.guid != null && message.hasOwnProperty("guid"))
                    object.guid = message.guid;
                if (message.color != null && message.hasOwnProperty("color"))
                    object.color = message.color;
                return object;
            };

            /**
             * Converts this DeletePGMFile to JSON.
             * @function toJSON
             * @memberof appcore.ripe.DeletePGMFile
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeletePGMFile.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return DeletePGMFile;
        })();

        ripe.DeletePrinter = (function() {

            /**
             * Properties of a DeletePrinter.
             * @memberof appcore.ripe
             * @interface IDeletePrinter
             * @property {number|Long|null} [id] DeletePrinter id
             */

            /**
             * Constructs a new DeletePrinter.
             * @memberof appcore.ripe
             * @classdesc Represents a DeletePrinter.
             * @implements IDeletePrinter
             * @constructor
             * @param {appcore.ripe.IDeletePrinter=} [properties] Properties to set
             */
            function DeletePrinter(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeletePrinter id.
             * @member {number|Long} id
             * @memberof appcore.ripe.DeletePrinter
             * @instance
             */
            DeletePrinter.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new DeletePrinter instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.DeletePrinter
             * @static
             * @param {appcore.ripe.IDeletePrinter=} [properties] Properties to set
             * @returns {appcore.ripe.DeletePrinter} DeletePrinter instance
             */
            DeletePrinter.create = function create(properties) {
                return new DeletePrinter(properties);
            };

            /**
             * Encodes the specified DeletePrinter message. Does not implicitly {@link appcore.ripe.DeletePrinter.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.DeletePrinter
             * @static
             * @param {appcore.ripe.IDeletePrinter} message DeletePrinter message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeletePrinter.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.id);
                return writer;
            };

            /**
             * Encodes the specified DeletePrinter message, length delimited. Does not implicitly {@link appcore.ripe.DeletePrinter.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.DeletePrinter
             * @static
             * @param {appcore.ripe.IDeletePrinter} message DeletePrinter message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeletePrinter.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DeletePrinter message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.DeletePrinter
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.DeletePrinter} DeletePrinter
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeletePrinter.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.DeletePrinter();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DeletePrinter message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.DeletePrinter
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.DeletePrinter} DeletePrinter
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeletePrinter.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DeletePrinter message.
             * @function verify
             * @memberof appcore.ripe.DeletePrinter
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeletePrinter.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                return null;
            };

            /**
             * Creates a DeletePrinter message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.DeletePrinter
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.DeletePrinter} DeletePrinter
             */
            DeletePrinter.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.DeletePrinter)
                    return object;
                let message = new $root.appcore.ripe.DeletePrinter();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a DeletePrinter message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.DeletePrinter
             * @static
             * @param {appcore.ripe.DeletePrinter} message DeletePrinter
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeletePrinter.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
                return object;
            };

            /**
             * Converts this DeletePrinter to JSON.
             * @function toJSON
             * @memberof appcore.ripe.DeletePrinter
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeletePrinter.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return DeletePrinter;
        })();

        ripe.DeletePrinterResult = (function() {

            /**
             * Properties of a DeletePrinterResult.
             * @memberof appcore.ripe
             * @interface IDeletePrinterResult
             * @property {number|null} [error_code] DeletePrinterResult error_code
             * @property {number|Long|null} [id] DeletePrinterResult id
             * @property {string|null} [name] DeletePrinterResult name
             */

            /**
             * Constructs a new DeletePrinterResult.
             * @memberof appcore.ripe
             * @classdesc Represents a DeletePrinterResult.
             * @implements IDeletePrinterResult
             * @constructor
             * @param {appcore.ripe.IDeletePrinterResult=} [properties] Properties to set
             */
            function DeletePrinterResult(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeletePrinterResult error_code.
             * @member {number} error_code
             * @memberof appcore.ripe.DeletePrinterResult
             * @instance
             */
            DeletePrinterResult.prototype.error_code = 0;

            /**
             * DeletePrinterResult id.
             * @member {number|Long} id
             * @memberof appcore.ripe.DeletePrinterResult
             * @instance
             */
            DeletePrinterResult.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * DeletePrinterResult name.
             * @member {string} name
             * @memberof appcore.ripe.DeletePrinterResult
             * @instance
             */
            DeletePrinterResult.prototype.name = "";

            /**
             * Creates a new DeletePrinterResult instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.DeletePrinterResult
             * @static
             * @param {appcore.ripe.IDeletePrinterResult=} [properties] Properties to set
             * @returns {appcore.ripe.DeletePrinterResult} DeletePrinterResult instance
             */
            DeletePrinterResult.create = function create(properties) {
                return new DeletePrinterResult(properties);
            };

            /**
             * Encodes the specified DeletePrinterResult message. Does not implicitly {@link appcore.ripe.DeletePrinterResult.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.DeletePrinterResult
             * @static
             * @param {appcore.ripe.IDeletePrinterResult} message DeletePrinterResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeletePrinterResult.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.error_code != null && Object.hasOwnProperty.call(message, "error_code"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.error_code);
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.id);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
                return writer;
            };

            /**
             * Encodes the specified DeletePrinterResult message, length delimited. Does not implicitly {@link appcore.ripe.DeletePrinterResult.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.DeletePrinterResult
             * @static
             * @param {appcore.ripe.IDeletePrinterResult} message DeletePrinterResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeletePrinterResult.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DeletePrinterResult message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.DeletePrinterResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.DeletePrinterResult} DeletePrinterResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeletePrinterResult.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.DeletePrinterResult();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.error_code = reader.int32();
                        break;
                    case 2:
                        message.id = reader.uint64();
                        break;
                    case 3:
                        message.name = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DeletePrinterResult message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.DeletePrinterResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.DeletePrinterResult} DeletePrinterResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeletePrinterResult.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DeletePrinterResult message.
             * @function verify
             * @memberof appcore.ripe.DeletePrinterResult
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeletePrinterResult.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.error_code != null && message.hasOwnProperty("error_code"))
                    if (!$util.isInteger(message.error_code))
                        return "error_code: integer expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                return null;
            };

            /**
             * Creates a DeletePrinterResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.DeletePrinterResult
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.DeletePrinterResult} DeletePrinterResult
             */
            DeletePrinterResult.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.DeletePrinterResult)
                    return object;
                let message = new $root.appcore.ripe.DeletePrinterResult();
                if (object.error_code != null)
                    message.error_code = object.error_code | 0;
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
                if (object.name != null)
                    message.name = String(object.name);
                return message;
            };

            /**
             * Creates a plain object from a DeletePrinterResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.DeletePrinterResult
             * @static
             * @param {appcore.ripe.DeletePrinterResult} message DeletePrinterResult
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeletePrinterResult.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.error_code = 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                    object.name = "";
                }
                if (message.error_code != null && message.hasOwnProperty("error_code"))
                    object.error_code = message.error_code;
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                return object;
            };

            /**
             * Converts this DeletePrinterResult to JSON.
             * @function toJSON
             * @memberof appcore.ripe.DeletePrinterResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeletePrinterResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return DeletePrinterResult;
        })();

        ripe.DeleteRIPComputer = (function() {

            /**
             * Properties of a DeleteRIPComputer.
             * @memberof appcore.ripe
             * @interface IDeleteRIPComputer
             * @property {number|Long|null} [id] DeleteRIPComputer id
             */

            /**
             * Constructs a new DeleteRIPComputer.
             * @memberof appcore.ripe
             * @classdesc Represents a DeleteRIPComputer.
             * @implements IDeleteRIPComputer
             * @constructor
             * @param {appcore.ripe.IDeleteRIPComputer=} [properties] Properties to set
             */
            function DeleteRIPComputer(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeleteRIPComputer id.
             * @member {number|Long} id
             * @memberof appcore.ripe.DeleteRIPComputer
             * @instance
             */
            DeleteRIPComputer.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new DeleteRIPComputer instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.DeleteRIPComputer
             * @static
             * @param {appcore.ripe.IDeleteRIPComputer=} [properties] Properties to set
             * @returns {appcore.ripe.DeleteRIPComputer} DeleteRIPComputer instance
             */
            DeleteRIPComputer.create = function create(properties) {
                return new DeleteRIPComputer(properties);
            };

            /**
             * Encodes the specified DeleteRIPComputer message. Does not implicitly {@link appcore.ripe.DeleteRIPComputer.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.DeleteRIPComputer
             * @static
             * @param {appcore.ripe.IDeleteRIPComputer} message DeleteRIPComputer message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteRIPComputer.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.id);
                return writer;
            };

            /**
             * Encodes the specified DeleteRIPComputer message, length delimited. Does not implicitly {@link appcore.ripe.DeleteRIPComputer.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.DeleteRIPComputer
             * @static
             * @param {appcore.ripe.IDeleteRIPComputer} message DeleteRIPComputer message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteRIPComputer.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DeleteRIPComputer message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.DeleteRIPComputer
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.DeleteRIPComputer} DeleteRIPComputer
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteRIPComputer.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.DeleteRIPComputer();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DeleteRIPComputer message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.DeleteRIPComputer
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.DeleteRIPComputer} DeleteRIPComputer
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteRIPComputer.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DeleteRIPComputer message.
             * @function verify
             * @memberof appcore.ripe.DeleteRIPComputer
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeleteRIPComputer.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                return null;
            };

            /**
             * Creates a DeleteRIPComputer message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.DeleteRIPComputer
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.DeleteRIPComputer} DeleteRIPComputer
             */
            DeleteRIPComputer.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.DeleteRIPComputer)
                    return object;
                let message = new $root.appcore.ripe.DeleteRIPComputer();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a DeleteRIPComputer message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.DeleteRIPComputer
             * @static
             * @param {appcore.ripe.DeleteRIPComputer} message DeleteRIPComputer
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteRIPComputer.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
                return object;
            };

            /**
             * Converts this DeleteRIPComputer to JSON.
             * @function toJSON
             * @memberof appcore.ripe.DeleteRIPComputer
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteRIPComputer.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return DeleteRIPComputer;
        })();

        ripe.DeleteSplitFiles = (function() {

            /**
             * Properties of a DeleteSplitFiles.
             * @memberof appcore.ripe
             * @interface IDeleteSplitFiles
             * @property {string|null} [guid] DeleteSplitFiles guid
             * @property {string|null} [config_guid] DeleteSplitFiles config_guid
             */

            /**
             * Constructs a new DeleteSplitFiles.
             * @memberof appcore.ripe
             * @classdesc Represents a DeleteSplitFiles.
             * @implements IDeleteSplitFiles
             * @constructor
             * @param {appcore.ripe.IDeleteSplitFiles=} [properties] Properties to set
             */
            function DeleteSplitFiles(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeleteSplitFiles guid.
             * @member {string} guid
             * @memberof appcore.ripe.DeleteSplitFiles
             * @instance
             */
            DeleteSplitFiles.prototype.guid = "";

            /**
             * DeleteSplitFiles config_guid.
             * @member {string} config_guid
             * @memberof appcore.ripe.DeleteSplitFiles
             * @instance
             */
            DeleteSplitFiles.prototype.config_guid = "";

            /**
             * Creates a new DeleteSplitFiles instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.DeleteSplitFiles
             * @static
             * @param {appcore.ripe.IDeleteSplitFiles=} [properties] Properties to set
             * @returns {appcore.ripe.DeleteSplitFiles} DeleteSplitFiles instance
             */
            DeleteSplitFiles.create = function create(properties) {
                return new DeleteSplitFiles(properties);
            };

            /**
             * Encodes the specified DeleteSplitFiles message. Does not implicitly {@link appcore.ripe.DeleteSplitFiles.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.DeleteSplitFiles
             * @static
             * @param {appcore.ripe.IDeleteSplitFiles} message DeleteSplitFiles message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteSplitFiles.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.guid != null && Object.hasOwnProperty.call(message, "guid"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.guid);
                if (message.config_guid != null && Object.hasOwnProperty.call(message, "config_guid"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.config_guid);
                return writer;
            };

            /**
             * Encodes the specified DeleteSplitFiles message, length delimited. Does not implicitly {@link appcore.ripe.DeleteSplitFiles.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.DeleteSplitFiles
             * @static
             * @param {appcore.ripe.IDeleteSplitFiles} message DeleteSplitFiles message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteSplitFiles.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DeleteSplitFiles message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.DeleteSplitFiles
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.DeleteSplitFiles} DeleteSplitFiles
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteSplitFiles.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.DeleteSplitFiles();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.guid = reader.string();
                        break;
                    case 2:
                        message.config_guid = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DeleteSplitFiles message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.DeleteSplitFiles
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.DeleteSplitFiles} DeleteSplitFiles
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteSplitFiles.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DeleteSplitFiles message.
             * @function verify
             * @memberof appcore.ripe.DeleteSplitFiles
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeleteSplitFiles.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.guid != null && message.hasOwnProperty("guid"))
                    if (!$util.isString(message.guid))
                        return "guid: string expected";
                if (message.config_guid != null && message.hasOwnProperty("config_guid"))
                    if (!$util.isString(message.config_guid))
                        return "config_guid: string expected";
                return null;
            };

            /**
             * Creates a DeleteSplitFiles message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.DeleteSplitFiles
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.DeleteSplitFiles} DeleteSplitFiles
             */
            DeleteSplitFiles.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.DeleteSplitFiles)
                    return object;
                let message = new $root.appcore.ripe.DeleteSplitFiles();
                if (object.guid != null)
                    message.guid = String(object.guid);
                if (object.config_guid != null)
                    message.config_guid = String(object.config_guid);
                return message;
            };

            /**
             * Creates a plain object from a DeleteSplitFiles message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.DeleteSplitFiles
             * @static
             * @param {appcore.ripe.DeleteSplitFiles} message DeleteSplitFiles
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteSplitFiles.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.guid = "";
                    object.config_guid = "";
                }
                if (message.guid != null && message.hasOwnProperty("guid"))
                    object.guid = message.guid;
                if (message.config_guid != null && message.hasOwnProperty("config_guid"))
                    object.config_guid = message.config_guid;
                return object;
            };

            /**
             * Converts this DeleteSplitFiles to JSON.
             * @function toJSON
             * @memberof appcore.ripe.DeleteSplitFiles
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteSplitFiles.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return DeleteSplitFiles;
        })();

        ripe.DeleteUser = (function() {

            /**
             * Properties of a DeleteUser.
             * @memberof appcore.ripe
             * @interface IDeleteUser
             * @property {number|Long|null} [id] DeleteUser id
             */

            /**
             * Constructs a new DeleteUser.
             * @memberof appcore.ripe
             * @classdesc Represents a DeleteUser.
             * @implements IDeleteUser
             * @constructor
             * @param {appcore.ripe.IDeleteUser=} [properties] Properties to set
             */
            function DeleteUser(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeleteUser id.
             * @member {number|Long} id
             * @memberof appcore.ripe.DeleteUser
             * @instance
             */
            DeleteUser.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new DeleteUser instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.DeleteUser
             * @static
             * @param {appcore.ripe.IDeleteUser=} [properties] Properties to set
             * @returns {appcore.ripe.DeleteUser} DeleteUser instance
             */
            DeleteUser.create = function create(properties) {
                return new DeleteUser(properties);
            };

            /**
             * Encodes the specified DeleteUser message. Does not implicitly {@link appcore.ripe.DeleteUser.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.DeleteUser
             * @static
             * @param {appcore.ripe.IDeleteUser} message DeleteUser message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteUser.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                return writer;
            };

            /**
             * Encodes the specified DeleteUser message, length delimited. Does not implicitly {@link appcore.ripe.DeleteUser.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.DeleteUser
             * @static
             * @param {appcore.ripe.IDeleteUser} message DeleteUser message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteUser.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DeleteUser message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.DeleteUser
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.DeleteUser} DeleteUser
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteUser.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.DeleteUser();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.int64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DeleteUser message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.DeleteUser
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.DeleteUser} DeleteUser
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteUser.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DeleteUser message.
             * @function verify
             * @memberof appcore.ripe.DeleteUser
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeleteUser.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                return null;
            };

            /**
             * Creates a DeleteUser message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.DeleteUser
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.DeleteUser} DeleteUser
             */
            DeleteUser.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.DeleteUser)
                    return object;
                let message = new $root.appcore.ripe.DeleteUser();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from a DeleteUser message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.DeleteUser
             * @static
             * @param {appcore.ripe.DeleteUser} message DeleteUser
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteUser.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                return object;
            };

            /**
             * Converts this DeleteUser to JSON.
             * @function toJSON
             * @memberof appcore.ripe.DeleteUser
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteUser.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return DeleteUser;
        })();

        ripe.DeviceLink = (function() {

            /**
             * Properties of a DeviceLink.
             * @memberof appcore.ripe
             * @interface IDeviceLink
             * @property {number|Long|null} [id] DeviceLink id
             * @property {string|null} [name] DeviceLink name
             */

            /**
             * Constructs a new DeviceLink.
             * @memberof appcore.ripe
             * @classdesc Represents a DeviceLink.
             * @implements IDeviceLink
             * @constructor
             * @param {appcore.ripe.IDeviceLink=} [properties] Properties to set
             */
            function DeviceLink(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeviceLink id.
             * @member {number|Long} id
             * @memberof appcore.ripe.DeviceLink
             * @instance
             */
            DeviceLink.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * DeviceLink name.
             * @member {string} name
             * @memberof appcore.ripe.DeviceLink
             * @instance
             */
            DeviceLink.prototype.name = "";

            /**
             * Creates a new DeviceLink instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.DeviceLink
             * @static
             * @param {appcore.ripe.IDeviceLink=} [properties] Properties to set
             * @returns {appcore.ripe.DeviceLink} DeviceLink instance
             */
            DeviceLink.create = function create(properties) {
                return new DeviceLink(properties);
            };

            /**
             * Encodes the specified DeviceLink message. Does not implicitly {@link appcore.ripe.DeviceLink.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.DeviceLink
             * @static
             * @param {appcore.ripe.IDeviceLink} message DeviceLink message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeviceLink.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.id);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                return writer;
            };

            /**
             * Encodes the specified DeviceLink message, length delimited. Does not implicitly {@link appcore.ripe.DeviceLink.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.DeviceLink
             * @static
             * @param {appcore.ripe.IDeviceLink} message DeviceLink message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeviceLink.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DeviceLink message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.DeviceLink
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.DeviceLink} DeviceLink
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeviceLink.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.DeviceLink();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.uint64();
                        break;
                    case 2:
                        message.name = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DeviceLink message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.DeviceLink
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.DeviceLink} DeviceLink
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeviceLink.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DeviceLink message.
             * @function verify
             * @memberof appcore.ripe.DeviceLink
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeviceLink.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                return null;
            };

            /**
             * Creates a DeviceLink message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.DeviceLink
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.DeviceLink} DeviceLink
             */
            DeviceLink.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.DeviceLink)
                    return object;
                let message = new $root.appcore.ripe.DeviceLink();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
                if (object.name != null)
                    message.name = String(object.name);
                return message;
            };

            /**
             * Creates a plain object from a DeviceLink message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.DeviceLink
             * @static
             * @param {appcore.ripe.DeviceLink} message DeviceLink
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeviceLink.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                    object.name = "";
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                return object;
            };

            /**
             * Converts this DeviceLink to JSON.
             * @function toJSON
             * @memberof appcore.ripe.DeviceLink
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeviceLink.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return DeviceLink;
        })();

        ripe.DeviceLinks = (function() {

            /**
             * Properties of a DeviceLinks.
             * @memberof appcore.ripe
             * @interface IDeviceLinks
             * @property {Array.<appcore.ripe.IDeviceLink>|null} [device_links] DeviceLinks device_links
             */

            /**
             * Constructs a new DeviceLinks.
             * @memberof appcore.ripe
             * @classdesc Represents a DeviceLinks.
             * @implements IDeviceLinks
             * @constructor
             * @param {appcore.ripe.IDeviceLinks=} [properties] Properties to set
             */
            function DeviceLinks(properties) {
                this.device_links = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeviceLinks device_links.
             * @member {Array.<appcore.ripe.IDeviceLink>} device_links
             * @memberof appcore.ripe.DeviceLinks
             * @instance
             */
            DeviceLinks.prototype.device_links = $util.emptyArray;

            /**
             * Creates a new DeviceLinks instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.DeviceLinks
             * @static
             * @param {appcore.ripe.IDeviceLinks=} [properties] Properties to set
             * @returns {appcore.ripe.DeviceLinks} DeviceLinks instance
             */
            DeviceLinks.create = function create(properties) {
                return new DeviceLinks(properties);
            };

            /**
             * Encodes the specified DeviceLinks message. Does not implicitly {@link appcore.ripe.DeviceLinks.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.DeviceLinks
             * @static
             * @param {appcore.ripe.IDeviceLinks} message DeviceLinks message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeviceLinks.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.device_links != null && message.device_links.length)
                    for (let i = 0; i < message.device_links.length; ++i)
                        $root.appcore.ripe.DeviceLink.encode(message.device_links[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified DeviceLinks message, length delimited. Does not implicitly {@link appcore.ripe.DeviceLinks.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.DeviceLinks
             * @static
             * @param {appcore.ripe.IDeviceLinks} message DeviceLinks message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeviceLinks.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DeviceLinks message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.DeviceLinks
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.DeviceLinks} DeviceLinks
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeviceLinks.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.DeviceLinks();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.device_links && message.device_links.length))
                            message.device_links = [];
                        message.device_links.push($root.appcore.ripe.DeviceLink.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DeviceLinks message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.DeviceLinks
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.DeviceLinks} DeviceLinks
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeviceLinks.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DeviceLinks message.
             * @function verify
             * @memberof appcore.ripe.DeviceLinks
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeviceLinks.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.device_links != null && message.hasOwnProperty("device_links")) {
                    if (!Array.isArray(message.device_links))
                        return "device_links: array expected";
                    for (let i = 0; i < message.device_links.length; ++i) {
                        let error = $root.appcore.ripe.DeviceLink.verify(message.device_links[i]);
                        if (error)
                            return "device_links." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a DeviceLinks message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.DeviceLinks
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.DeviceLinks} DeviceLinks
             */
            DeviceLinks.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.DeviceLinks)
                    return object;
                let message = new $root.appcore.ripe.DeviceLinks();
                if (object.device_links) {
                    if (!Array.isArray(object.device_links))
                        throw TypeError(".appcore.ripe.DeviceLinks.device_links: array expected");
                    message.device_links = [];
                    for (let i = 0; i < object.device_links.length; ++i) {
                        if (typeof object.device_links[i] !== "object")
                            throw TypeError(".appcore.ripe.DeviceLinks.device_links: object expected");
                        message.device_links[i] = $root.appcore.ripe.DeviceLink.fromObject(object.device_links[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a DeviceLinks message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.DeviceLinks
             * @static
             * @param {appcore.ripe.DeviceLinks} message DeviceLinks
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeviceLinks.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.device_links = [];
                if (message.device_links && message.device_links.length) {
                    object.device_links = [];
                    for (let j = 0; j < message.device_links.length; ++j)
                        object.device_links[j] = $root.appcore.ripe.DeviceLink.toObject(message.device_links[j], options);
                }
                return object;
            };

            /**
             * Converts this DeviceLinks to JSON.
             * @function toJSON
             * @memberof appcore.ripe.DeviceLinks
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeviceLinks.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return DeviceLinks;
        })();

        ripe.DeviceLocale = (function() {

            /**
             * Properties of a DeviceLocale.
             * @memberof appcore.ripe
             * @interface IDeviceLocale
             * @property {string|null} [locale] DeviceLocale locale
             */

            /**
             * Constructs a new DeviceLocale.
             * @memberof appcore.ripe
             * @classdesc Represents a DeviceLocale.
             * @implements IDeviceLocale
             * @constructor
             * @param {appcore.ripe.IDeviceLocale=} [properties] Properties to set
             */
            function DeviceLocale(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeviceLocale locale.
             * @member {string} locale
             * @memberof appcore.ripe.DeviceLocale
             * @instance
             */
            DeviceLocale.prototype.locale = "";

            /**
             * Creates a new DeviceLocale instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.DeviceLocale
             * @static
             * @param {appcore.ripe.IDeviceLocale=} [properties] Properties to set
             * @returns {appcore.ripe.DeviceLocale} DeviceLocale instance
             */
            DeviceLocale.create = function create(properties) {
                return new DeviceLocale(properties);
            };

            /**
             * Encodes the specified DeviceLocale message. Does not implicitly {@link appcore.ripe.DeviceLocale.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.DeviceLocale
             * @static
             * @param {appcore.ripe.IDeviceLocale} message DeviceLocale message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeviceLocale.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.locale != null && Object.hasOwnProperty.call(message, "locale"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.locale);
                return writer;
            };

            /**
             * Encodes the specified DeviceLocale message, length delimited. Does not implicitly {@link appcore.ripe.DeviceLocale.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.DeviceLocale
             * @static
             * @param {appcore.ripe.IDeviceLocale} message DeviceLocale message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeviceLocale.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DeviceLocale message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.DeviceLocale
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.DeviceLocale} DeviceLocale
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeviceLocale.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.DeviceLocale();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.locale = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DeviceLocale message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.DeviceLocale
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.DeviceLocale} DeviceLocale
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeviceLocale.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DeviceLocale message.
             * @function verify
             * @memberof appcore.ripe.DeviceLocale
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeviceLocale.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.locale != null && message.hasOwnProperty("locale"))
                    if (!$util.isString(message.locale))
                        return "locale: string expected";
                return null;
            };

            /**
             * Creates a DeviceLocale message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.DeviceLocale
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.DeviceLocale} DeviceLocale
             */
            DeviceLocale.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.DeviceLocale)
                    return object;
                let message = new $root.appcore.ripe.DeviceLocale();
                if (object.locale != null)
                    message.locale = String(object.locale);
                return message;
            };

            /**
             * Creates a plain object from a DeviceLocale message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.DeviceLocale
             * @static
             * @param {appcore.ripe.DeviceLocale} message DeviceLocale
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeviceLocale.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.locale = "";
                if (message.locale != null && message.hasOwnProperty("locale"))
                    object.locale = message.locale;
                return object;
            };

            /**
             * Converts this DeviceLocale to JSON.
             * @function toJSON
             * @memberof appcore.ripe.DeviceLocale
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeviceLocale.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return DeviceLocale;
        })();

        ripe.Encrypt = (function() {

            /**
             * Properties of an Encrypt.
             * @memberof appcore.ripe
             * @interface IEncrypt
             * @property {Uint8Array|null} [data] Encrypt data
             * @property {string|null} [alias] Encrypt alias
             */

            /**
             * Constructs a new Encrypt.
             * @memberof appcore.ripe
             * @classdesc Represents an Encrypt.
             * @implements IEncrypt
             * @constructor
             * @param {appcore.ripe.IEncrypt=} [properties] Properties to set
             */
            function Encrypt(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Encrypt data.
             * @member {Uint8Array} data
             * @memberof appcore.ripe.Encrypt
             * @instance
             */
            Encrypt.prototype.data = $util.newBuffer([]);

            /**
             * Encrypt alias.
             * @member {string} alias
             * @memberof appcore.ripe.Encrypt
             * @instance
             */
            Encrypt.prototype.alias = "";

            /**
             * Creates a new Encrypt instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.Encrypt
             * @static
             * @param {appcore.ripe.IEncrypt=} [properties] Properties to set
             * @returns {appcore.ripe.Encrypt} Encrypt instance
             */
            Encrypt.create = function create(properties) {
                return new Encrypt(properties);
            };

            /**
             * Encodes the specified Encrypt message. Does not implicitly {@link appcore.ripe.Encrypt.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.Encrypt
             * @static
             * @param {appcore.ripe.IEncrypt} message Encrypt message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Encrypt.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                    writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.data);
                if (message.alias != null && Object.hasOwnProperty.call(message, "alias"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.alias);
                return writer;
            };

            /**
             * Encodes the specified Encrypt message, length delimited. Does not implicitly {@link appcore.ripe.Encrypt.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.Encrypt
             * @static
             * @param {appcore.ripe.IEncrypt} message Encrypt message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Encrypt.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an Encrypt message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.Encrypt
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.Encrypt} Encrypt
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Encrypt.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.Encrypt();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.data = reader.bytes();
                        break;
                    case 2:
                        message.alias = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an Encrypt message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.Encrypt
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.Encrypt} Encrypt
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Encrypt.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an Encrypt message.
             * @function verify
             * @memberof appcore.ripe.Encrypt
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Encrypt.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.data != null && message.hasOwnProperty("data"))
                    if (!(message.data && typeof message.data.length === "number" || $util.isString(message.data)))
                        return "data: buffer expected";
                if (message.alias != null && message.hasOwnProperty("alias"))
                    if (!$util.isString(message.alias))
                        return "alias: string expected";
                return null;
            };

            /**
             * Creates an Encrypt message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.Encrypt
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.Encrypt} Encrypt
             */
            Encrypt.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.Encrypt)
                    return object;
                let message = new $root.appcore.ripe.Encrypt();
                if (object.data != null)
                    if (typeof object.data === "string")
                        $util.base64.decode(object.data, message.data = $util.newBuffer($util.base64.length(object.data)), 0);
                    else if (object.data.length)
                        message.data = object.data;
                if (object.alias != null)
                    message.alias = String(object.alias);
                return message;
            };

            /**
             * Creates a plain object from an Encrypt message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.Encrypt
             * @static
             * @param {appcore.ripe.Encrypt} message Encrypt
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Encrypt.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if (options.bytes === String)
                        object.data = "";
                    else {
                        object.data = [];
                        if (options.bytes !== Array)
                            object.data = $util.newBuffer(object.data);
                    }
                    object.alias = "";
                }
                if (message.data != null && message.hasOwnProperty("data"))
                    object.data = options.bytes === String ? $util.base64.encode(message.data, 0, message.data.length) : options.bytes === Array ? Array.prototype.slice.call(message.data) : message.data;
                if (message.alias != null && message.hasOwnProperty("alias"))
                    object.alias = message.alias;
                return object;
            };

            /**
             * Converts this Encrypt to JSON.
             * @function toJSON
             * @memberof appcore.ripe.Encrypt
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Encrypt.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Encrypt;
        })();

        ripe.EncryptResult = (function() {

            /**
             * Properties of an EncryptResult.
             * @memberof appcore.ripe
             * @interface IEncryptResult
             * @property {Uint8Array|null} [data] EncryptResult data
             * @property {Uint8Array|null} [iv] EncryptResult iv
             */

            /**
             * Constructs a new EncryptResult.
             * @memberof appcore.ripe
             * @classdesc Represents an EncryptResult.
             * @implements IEncryptResult
             * @constructor
             * @param {appcore.ripe.IEncryptResult=} [properties] Properties to set
             */
            function EncryptResult(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * EncryptResult data.
             * @member {Uint8Array} data
             * @memberof appcore.ripe.EncryptResult
             * @instance
             */
            EncryptResult.prototype.data = $util.newBuffer([]);

            /**
             * EncryptResult iv.
             * @member {Uint8Array} iv
             * @memberof appcore.ripe.EncryptResult
             * @instance
             */
            EncryptResult.prototype.iv = $util.newBuffer([]);

            /**
             * Creates a new EncryptResult instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.EncryptResult
             * @static
             * @param {appcore.ripe.IEncryptResult=} [properties] Properties to set
             * @returns {appcore.ripe.EncryptResult} EncryptResult instance
             */
            EncryptResult.create = function create(properties) {
                return new EncryptResult(properties);
            };

            /**
             * Encodes the specified EncryptResult message. Does not implicitly {@link appcore.ripe.EncryptResult.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.EncryptResult
             * @static
             * @param {appcore.ripe.IEncryptResult} message EncryptResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EncryptResult.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                    writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.data);
                if (message.iv != null && Object.hasOwnProperty.call(message, "iv"))
                    writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.iv);
                return writer;
            };

            /**
             * Encodes the specified EncryptResult message, length delimited. Does not implicitly {@link appcore.ripe.EncryptResult.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.EncryptResult
             * @static
             * @param {appcore.ripe.IEncryptResult} message EncryptResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EncryptResult.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an EncryptResult message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.EncryptResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.EncryptResult} EncryptResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EncryptResult.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.EncryptResult();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.data = reader.bytes();
                        break;
                    case 2:
                        message.iv = reader.bytes();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an EncryptResult message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.EncryptResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.EncryptResult} EncryptResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EncryptResult.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an EncryptResult message.
             * @function verify
             * @memberof appcore.ripe.EncryptResult
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EncryptResult.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.data != null && message.hasOwnProperty("data"))
                    if (!(message.data && typeof message.data.length === "number" || $util.isString(message.data)))
                        return "data: buffer expected";
                if (message.iv != null && message.hasOwnProperty("iv"))
                    if (!(message.iv && typeof message.iv.length === "number" || $util.isString(message.iv)))
                        return "iv: buffer expected";
                return null;
            };

            /**
             * Creates an EncryptResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.EncryptResult
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.EncryptResult} EncryptResult
             */
            EncryptResult.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.EncryptResult)
                    return object;
                let message = new $root.appcore.ripe.EncryptResult();
                if (object.data != null)
                    if (typeof object.data === "string")
                        $util.base64.decode(object.data, message.data = $util.newBuffer($util.base64.length(object.data)), 0);
                    else if (object.data.length)
                        message.data = object.data;
                if (object.iv != null)
                    if (typeof object.iv === "string")
                        $util.base64.decode(object.iv, message.iv = $util.newBuffer($util.base64.length(object.iv)), 0);
                    else if (object.iv.length)
                        message.iv = object.iv;
                return message;
            };

            /**
             * Creates a plain object from an EncryptResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.EncryptResult
             * @static
             * @param {appcore.ripe.EncryptResult} message EncryptResult
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EncryptResult.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if (options.bytes === String)
                        object.data = "";
                    else {
                        object.data = [];
                        if (options.bytes !== Array)
                            object.data = $util.newBuffer(object.data);
                    }
                    if (options.bytes === String)
                        object.iv = "";
                    else {
                        object.iv = [];
                        if (options.bytes !== Array)
                            object.iv = $util.newBuffer(object.iv);
                    }
                }
                if (message.data != null && message.hasOwnProperty("data"))
                    object.data = options.bytes === String ? $util.base64.encode(message.data, 0, message.data.length) : options.bytes === Array ? Array.prototype.slice.call(message.data) : message.data;
                if (message.iv != null && message.hasOwnProperty("iv"))
                    object.iv = options.bytes === String ? $util.base64.encode(message.iv, 0, message.iv.length) : options.bytes === Array ? Array.prototype.slice.call(message.iv) : message.iv;
                return object;
            };

            /**
             * Converts this EncryptResult to JSON.
             * @function toJSON
             * @memberof appcore.ripe.EncryptResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EncryptResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return EncryptResult;
        })();

        ripe.Generate = (function() {

            /**
             * Properties of a Generate.
             * @memberof appcore.ripe
             * @interface IGenerate
             * @property {string|null} [guid] Generate guid
             * @property {string|null} [config_guid] Generate config_guid
             * @property {appcore.ripe.IOutputSettingsOverride|null} [settings_override] Generate settings_override
             * @property {number|null} [generate_type] Generate generate_type
             * @property {string|null} [filename] Generate filename
             */

            /**
             * Constructs a new Generate.
             * @memberof appcore.ripe
             * @classdesc Represents a Generate.
             * @implements IGenerate
             * @constructor
             * @param {appcore.ripe.IGenerate=} [properties] Properties to set
             */
            function Generate(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Generate guid.
             * @member {string} guid
             * @memberof appcore.ripe.Generate
             * @instance
             */
            Generate.prototype.guid = "";

            /**
             * Generate config_guid.
             * @member {string} config_guid
             * @memberof appcore.ripe.Generate
             * @instance
             */
            Generate.prototype.config_guid = "";

            /**
             * Generate settings_override.
             * @member {appcore.ripe.IOutputSettingsOverride|null|undefined} settings_override
             * @memberof appcore.ripe.Generate
             * @instance
             */
            Generate.prototype.settings_override = null;

            /**
             * Generate generate_type.
             * @member {number} generate_type
             * @memberof appcore.ripe.Generate
             * @instance
             */
            Generate.prototype.generate_type = 0;

            /**
             * Generate filename.
             * @member {string} filename
             * @memberof appcore.ripe.Generate
             * @instance
             */
            Generate.prototype.filename = "";

            /**
             * Creates a new Generate instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.Generate
             * @static
             * @param {appcore.ripe.IGenerate=} [properties] Properties to set
             * @returns {appcore.ripe.Generate} Generate instance
             */
            Generate.create = function create(properties) {
                return new Generate(properties);
            };

            /**
             * Encodes the specified Generate message. Does not implicitly {@link appcore.ripe.Generate.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.Generate
             * @static
             * @param {appcore.ripe.IGenerate} message Generate message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Generate.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.guid != null && Object.hasOwnProperty.call(message, "guid"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.guid);
                if (message.config_guid != null && Object.hasOwnProperty.call(message, "config_guid"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.config_guid);
                if (message.settings_override != null && Object.hasOwnProperty.call(message, "settings_override"))
                    $root.appcore.ripe.OutputSettingsOverride.encode(message.settings_override, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.generate_type != null && Object.hasOwnProperty.call(message, "generate_type"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.generate_type);
                if (message.filename != null && Object.hasOwnProperty.call(message, "filename"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.filename);
                return writer;
            };

            /**
             * Encodes the specified Generate message, length delimited. Does not implicitly {@link appcore.ripe.Generate.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.Generate
             * @static
             * @param {appcore.ripe.IGenerate} message Generate message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Generate.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Generate message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.Generate
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.Generate} Generate
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Generate.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.Generate();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.guid = reader.string();
                        break;
                    case 2:
                        message.config_guid = reader.string();
                        break;
                    case 3:
                        message.settings_override = $root.appcore.ripe.OutputSettingsOverride.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.generate_type = reader.int32();
                        break;
                    case 5:
                        message.filename = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Generate message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.Generate
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.Generate} Generate
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Generate.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Generate message.
             * @function verify
             * @memberof appcore.ripe.Generate
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Generate.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.guid != null && message.hasOwnProperty("guid"))
                    if (!$util.isString(message.guid))
                        return "guid: string expected";
                if (message.config_guid != null && message.hasOwnProperty("config_guid"))
                    if (!$util.isString(message.config_guid))
                        return "config_guid: string expected";
                if (message.settings_override != null && message.hasOwnProperty("settings_override")) {
                    let error = $root.appcore.ripe.OutputSettingsOverride.verify(message.settings_override);
                    if (error)
                        return "settings_override." + error;
                }
                if (message.generate_type != null && message.hasOwnProperty("generate_type"))
                    if (!$util.isInteger(message.generate_type))
                        return "generate_type: integer expected";
                if (message.filename != null && message.hasOwnProperty("filename"))
                    if (!$util.isString(message.filename))
                        return "filename: string expected";
                return null;
            };

            /**
             * Creates a Generate message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.Generate
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.Generate} Generate
             */
            Generate.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.Generate)
                    return object;
                let message = new $root.appcore.ripe.Generate();
                if (object.guid != null)
                    message.guid = String(object.guid);
                if (object.config_guid != null)
                    message.config_guid = String(object.config_guid);
                if (object.settings_override != null) {
                    if (typeof object.settings_override !== "object")
                        throw TypeError(".appcore.ripe.Generate.settings_override: object expected");
                    message.settings_override = $root.appcore.ripe.OutputSettingsOverride.fromObject(object.settings_override);
                }
                if (object.generate_type != null)
                    message.generate_type = object.generate_type | 0;
                if (object.filename != null)
                    message.filename = String(object.filename);
                return message;
            };

            /**
             * Creates a plain object from a Generate message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.Generate
             * @static
             * @param {appcore.ripe.Generate} message Generate
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Generate.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.guid = "";
                    object.config_guid = "";
                    object.settings_override = null;
                    object.generate_type = 0;
                    object.filename = "";
                }
                if (message.guid != null && message.hasOwnProperty("guid"))
                    object.guid = message.guid;
                if (message.config_guid != null && message.hasOwnProperty("config_guid"))
                    object.config_guid = message.config_guid;
                if (message.settings_override != null && message.hasOwnProperty("settings_override"))
                    object.settings_override = $root.appcore.ripe.OutputSettingsOverride.toObject(message.settings_override, options);
                if (message.generate_type != null && message.hasOwnProperty("generate_type"))
                    object.generate_type = message.generate_type;
                if (message.filename != null && message.hasOwnProperty("filename"))
                    object.filename = message.filename;
                return object;
            };

            /**
             * Converts this Generate to JSON.
             * @function toJSON
             * @memberof appcore.ripe.Generate
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Generate.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Generate;
        })();

        ripe.OutputSettingsOverride = (function() {

            /**
             * Properties of an OutputSettingsOverride.
             * @memberof appcore.ripe
             * @interface IOutputSettingsOverride
             * @property {string|null} [config_guid] OutputSettingsOverride config_guid
             * @property {string|null} [pdf_guid] OutputSettingsOverride pdf_guid
             * @property {number|null} [output_code] OutputSettingsOverride output_code
             * @property {number|null} [dpi_x] OutputSettingsOverride dpi_x
             * @property {number|null} [dpi_y] OutputSettingsOverride dpi_y
             * @property {string|null} [icc_profile_guid] OutputSettingsOverride icc_profile_guid
             * @property {boolean|null} [printer_output] OutputSettingsOverride printer_output
             * @property {boolean|null} [use_raw_thumbnail] OutputSettingsOverride use_raw_thumbnail
             * @property {string|null} [screening_config_guid] OutputSettingsOverride screening_config_guid
             * @property {number|null} [copies] OutputSettingsOverride copies
             * @property {boolean|null} [collate] OutputSettingsOverride collate
             * @property {number|null} [bottom_margin_page] OutputSettingsOverride bottom_margin_page
             * @property {number|null} [bottom_margin_copy] OutputSettingsOverride bottom_margin_copy
             * @property {number|null} [intent] OutputSettingsOverride intent
             * @property {number|null} [preserve] OutputSettingsOverride preserve
             * @property {string|null} [selected_pages_to_print] OutputSettingsOverride selected_pages_to_print
             * @property {string|null} [pages] OutputSettingsOverride pages
             * @property {number|null} [print_mode_pcl] OutputSettingsOverride print_mode_pcl
             */

            /**
             * Constructs a new OutputSettingsOverride.
             * @memberof appcore.ripe
             * @classdesc Represents an OutputSettingsOverride.
             * @implements IOutputSettingsOverride
             * @constructor
             * @param {appcore.ripe.IOutputSettingsOverride=} [properties] Properties to set
             */
            function OutputSettingsOverride(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OutputSettingsOverride config_guid.
             * @member {string} config_guid
             * @memberof appcore.ripe.OutputSettingsOverride
             * @instance
             */
            OutputSettingsOverride.prototype.config_guid = "";

            /**
             * OutputSettingsOverride pdf_guid.
             * @member {string} pdf_guid
             * @memberof appcore.ripe.OutputSettingsOverride
             * @instance
             */
            OutputSettingsOverride.prototype.pdf_guid = "";

            /**
             * OutputSettingsOverride output_code.
             * @member {number} output_code
             * @memberof appcore.ripe.OutputSettingsOverride
             * @instance
             */
            OutputSettingsOverride.prototype.output_code = 0;

            /**
             * OutputSettingsOverride dpi_x.
             * @member {number} dpi_x
             * @memberof appcore.ripe.OutputSettingsOverride
             * @instance
             */
            OutputSettingsOverride.prototype.dpi_x = 0;

            /**
             * OutputSettingsOverride dpi_y.
             * @member {number} dpi_y
             * @memberof appcore.ripe.OutputSettingsOverride
             * @instance
             */
            OutputSettingsOverride.prototype.dpi_y = 0;

            /**
             * OutputSettingsOverride icc_profile_guid.
             * @member {string} icc_profile_guid
             * @memberof appcore.ripe.OutputSettingsOverride
             * @instance
             */
            OutputSettingsOverride.prototype.icc_profile_guid = "";

            /**
             * OutputSettingsOverride printer_output.
             * @member {boolean} printer_output
             * @memberof appcore.ripe.OutputSettingsOverride
             * @instance
             */
            OutputSettingsOverride.prototype.printer_output = false;

            /**
             * OutputSettingsOverride use_raw_thumbnail.
             * @member {boolean} use_raw_thumbnail
             * @memberof appcore.ripe.OutputSettingsOverride
             * @instance
             */
            OutputSettingsOverride.prototype.use_raw_thumbnail = false;

            /**
             * OutputSettingsOverride screening_config_guid.
             * @member {string} screening_config_guid
             * @memberof appcore.ripe.OutputSettingsOverride
             * @instance
             */
            OutputSettingsOverride.prototype.screening_config_guid = "";

            /**
             * OutputSettingsOverride copies.
             * @member {number} copies
             * @memberof appcore.ripe.OutputSettingsOverride
             * @instance
             */
            OutputSettingsOverride.prototype.copies = 0;

            /**
             * OutputSettingsOverride collate.
             * @member {boolean} collate
             * @memberof appcore.ripe.OutputSettingsOverride
             * @instance
             */
            OutputSettingsOverride.prototype.collate = false;

            /**
             * OutputSettingsOverride bottom_margin_page.
             * @member {number} bottom_margin_page
             * @memberof appcore.ripe.OutputSettingsOverride
             * @instance
             */
            OutputSettingsOverride.prototype.bottom_margin_page = 0;

            /**
             * OutputSettingsOverride bottom_margin_copy.
             * @member {number} bottom_margin_copy
             * @memberof appcore.ripe.OutputSettingsOverride
             * @instance
             */
            OutputSettingsOverride.prototype.bottom_margin_copy = 0;

            /**
             * OutputSettingsOverride intent.
             * @member {number} intent
             * @memberof appcore.ripe.OutputSettingsOverride
             * @instance
             */
            OutputSettingsOverride.prototype.intent = 0;

            /**
             * OutputSettingsOverride preserve.
             * @member {number} preserve
             * @memberof appcore.ripe.OutputSettingsOverride
             * @instance
             */
            OutputSettingsOverride.prototype.preserve = 0;

            /**
             * OutputSettingsOverride selected_pages_to_print.
             * @member {string} selected_pages_to_print
             * @memberof appcore.ripe.OutputSettingsOverride
             * @instance
             */
            OutputSettingsOverride.prototype.selected_pages_to_print = "";

            /**
             * OutputSettingsOverride pages.
             * @member {string} pages
             * @memberof appcore.ripe.OutputSettingsOverride
             * @instance
             */
            OutputSettingsOverride.prototype.pages = "";

            /**
             * OutputSettingsOverride print_mode_pcl.
             * @member {number} print_mode_pcl
             * @memberof appcore.ripe.OutputSettingsOverride
             * @instance
             */
            OutputSettingsOverride.prototype.print_mode_pcl = 0;

            /**
             * Creates a new OutputSettingsOverride instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.OutputSettingsOverride
             * @static
             * @param {appcore.ripe.IOutputSettingsOverride=} [properties] Properties to set
             * @returns {appcore.ripe.OutputSettingsOverride} OutputSettingsOverride instance
             */
            OutputSettingsOverride.create = function create(properties) {
                return new OutputSettingsOverride(properties);
            };

            /**
             * Encodes the specified OutputSettingsOverride message. Does not implicitly {@link appcore.ripe.OutputSettingsOverride.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.OutputSettingsOverride
             * @static
             * @param {appcore.ripe.IOutputSettingsOverride} message OutputSettingsOverride message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OutputSettingsOverride.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.config_guid != null && Object.hasOwnProperty.call(message, "config_guid"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.config_guid);
                if (message.pdf_guid != null && Object.hasOwnProperty.call(message, "pdf_guid"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.pdf_guid);
                if (message.output_code != null && Object.hasOwnProperty.call(message, "output_code"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.output_code);
                if (message.dpi_x != null && Object.hasOwnProperty.call(message, "dpi_x"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.dpi_x);
                if (message.dpi_y != null && Object.hasOwnProperty.call(message, "dpi_y"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.dpi_y);
                if (message.icc_profile_guid != null && Object.hasOwnProperty.call(message, "icc_profile_guid"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.icc_profile_guid);
                if (message.printer_output != null && Object.hasOwnProperty.call(message, "printer_output"))
                    writer.uint32(/* id 7, wireType 0 =*/56).bool(message.printer_output);
                if (message.use_raw_thumbnail != null && Object.hasOwnProperty.call(message, "use_raw_thumbnail"))
                    writer.uint32(/* id 8, wireType 0 =*/64).bool(message.use_raw_thumbnail);
                if (message.screening_config_guid != null && Object.hasOwnProperty.call(message, "screening_config_guid"))
                    writer.uint32(/* id 9, wireType 2 =*/74).string(message.screening_config_guid);
                if (message.copies != null && Object.hasOwnProperty.call(message, "copies"))
                    writer.uint32(/* id 10, wireType 0 =*/80).int32(message.copies);
                if (message.collate != null && Object.hasOwnProperty.call(message, "collate"))
                    writer.uint32(/* id 11, wireType 0 =*/88).bool(message.collate);
                if (message.bottom_margin_page != null && Object.hasOwnProperty.call(message, "bottom_margin_page"))
                    writer.uint32(/* id 12, wireType 1 =*/97).double(message.bottom_margin_page);
                if (message.bottom_margin_copy != null && Object.hasOwnProperty.call(message, "bottom_margin_copy"))
                    writer.uint32(/* id 13, wireType 1 =*/105).double(message.bottom_margin_copy);
                if (message.intent != null && Object.hasOwnProperty.call(message, "intent"))
                    writer.uint32(/* id 14, wireType 0 =*/112).int32(message.intent);
                if (message.preserve != null && Object.hasOwnProperty.call(message, "preserve"))
                    writer.uint32(/* id 15, wireType 0 =*/120).int32(message.preserve);
                if (message.selected_pages_to_print != null && Object.hasOwnProperty.call(message, "selected_pages_to_print"))
                    writer.uint32(/* id 16, wireType 2 =*/130).string(message.selected_pages_to_print);
                if (message.pages != null && Object.hasOwnProperty.call(message, "pages"))
                    writer.uint32(/* id 17, wireType 2 =*/138).string(message.pages);
                if (message.print_mode_pcl != null && Object.hasOwnProperty.call(message, "print_mode_pcl"))
                    writer.uint32(/* id 18, wireType 0 =*/144).int32(message.print_mode_pcl);
                return writer;
            };

            /**
             * Encodes the specified OutputSettingsOverride message, length delimited. Does not implicitly {@link appcore.ripe.OutputSettingsOverride.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.OutputSettingsOverride
             * @static
             * @param {appcore.ripe.IOutputSettingsOverride} message OutputSettingsOverride message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OutputSettingsOverride.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an OutputSettingsOverride message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.OutputSettingsOverride
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.OutputSettingsOverride} OutputSettingsOverride
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OutputSettingsOverride.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.OutputSettingsOverride();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.config_guid = reader.string();
                        break;
                    case 2:
                        message.pdf_guid = reader.string();
                        break;
                    case 3:
                        message.output_code = reader.int32();
                        break;
                    case 4:
                        message.dpi_x = reader.int32();
                        break;
                    case 5:
                        message.dpi_y = reader.int32();
                        break;
                    case 6:
                        message.icc_profile_guid = reader.string();
                        break;
                    case 7:
                        message.printer_output = reader.bool();
                        break;
                    case 8:
                        message.use_raw_thumbnail = reader.bool();
                        break;
                    case 9:
                        message.screening_config_guid = reader.string();
                        break;
                    case 10:
                        message.copies = reader.int32();
                        break;
                    case 11:
                        message.collate = reader.bool();
                        break;
                    case 12:
                        message.bottom_margin_page = reader.double();
                        break;
                    case 13:
                        message.bottom_margin_copy = reader.double();
                        break;
                    case 14:
                        message.intent = reader.int32();
                        break;
                    case 15:
                        message.preserve = reader.int32();
                        break;
                    case 16:
                        message.selected_pages_to_print = reader.string();
                        break;
                    case 17:
                        message.pages = reader.string();
                        break;
                    case 18:
                        message.print_mode_pcl = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an OutputSettingsOverride message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.OutputSettingsOverride
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.OutputSettingsOverride} OutputSettingsOverride
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OutputSettingsOverride.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an OutputSettingsOverride message.
             * @function verify
             * @memberof appcore.ripe.OutputSettingsOverride
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OutputSettingsOverride.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.config_guid != null && message.hasOwnProperty("config_guid"))
                    if (!$util.isString(message.config_guid))
                        return "config_guid: string expected";
                if (message.pdf_guid != null && message.hasOwnProperty("pdf_guid"))
                    if (!$util.isString(message.pdf_guid))
                        return "pdf_guid: string expected";
                if (message.output_code != null && message.hasOwnProperty("output_code"))
                    if (!$util.isInteger(message.output_code))
                        return "output_code: integer expected";
                if (message.dpi_x != null && message.hasOwnProperty("dpi_x"))
                    if (!$util.isInteger(message.dpi_x))
                        return "dpi_x: integer expected";
                if (message.dpi_y != null && message.hasOwnProperty("dpi_y"))
                    if (!$util.isInteger(message.dpi_y))
                        return "dpi_y: integer expected";
                if (message.icc_profile_guid != null && message.hasOwnProperty("icc_profile_guid"))
                    if (!$util.isString(message.icc_profile_guid))
                        return "icc_profile_guid: string expected";
                if (message.printer_output != null && message.hasOwnProperty("printer_output"))
                    if (typeof message.printer_output !== "boolean")
                        return "printer_output: boolean expected";
                if (message.use_raw_thumbnail != null && message.hasOwnProperty("use_raw_thumbnail"))
                    if (typeof message.use_raw_thumbnail !== "boolean")
                        return "use_raw_thumbnail: boolean expected";
                if (message.screening_config_guid != null && message.hasOwnProperty("screening_config_guid"))
                    if (!$util.isString(message.screening_config_guid))
                        return "screening_config_guid: string expected";
                if (message.copies != null && message.hasOwnProperty("copies"))
                    if (!$util.isInteger(message.copies))
                        return "copies: integer expected";
                if (message.collate != null && message.hasOwnProperty("collate"))
                    if (typeof message.collate !== "boolean")
                        return "collate: boolean expected";
                if (message.bottom_margin_page != null && message.hasOwnProperty("bottom_margin_page"))
                    if (typeof message.bottom_margin_page !== "number")
                        return "bottom_margin_page: number expected";
                if (message.bottom_margin_copy != null && message.hasOwnProperty("bottom_margin_copy"))
                    if (typeof message.bottom_margin_copy !== "number")
                        return "bottom_margin_copy: number expected";
                if (message.intent != null && message.hasOwnProperty("intent"))
                    if (!$util.isInteger(message.intent))
                        return "intent: integer expected";
                if (message.preserve != null && message.hasOwnProperty("preserve"))
                    if (!$util.isInteger(message.preserve))
                        return "preserve: integer expected";
                if (message.selected_pages_to_print != null && message.hasOwnProperty("selected_pages_to_print"))
                    if (!$util.isString(message.selected_pages_to_print))
                        return "selected_pages_to_print: string expected";
                if (message.pages != null && message.hasOwnProperty("pages"))
                    if (!$util.isString(message.pages))
                        return "pages: string expected";
                if (message.print_mode_pcl != null && message.hasOwnProperty("print_mode_pcl"))
                    if (!$util.isInteger(message.print_mode_pcl))
                        return "print_mode_pcl: integer expected";
                return null;
            };

            /**
             * Creates an OutputSettingsOverride message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.OutputSettingsOverride
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.OutputSettingsOverride} OutputSettingsOverride
             */
            OutputSettingsOverride.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.OutputSettingsOverride)
                    return object;
                let message = new $root.appcore.ripe.OutputSettingsOverride();
                if (object.config_guid != null)
                    message.config_guid = String(object.config_guid);
                if (object.pdf_guid != null)
                    message.pdf_guid = String(object.pdf_guid);
                if (object.output_code != null)
                    message.output_code = object.output_code | 0;
                if (object.dpi_x != null)
                    message.dpi_x = object.dpi_x | 0;
                if (object.dpi_y != null)
                    message.dpi_y = object.dpi_y | 0;
                if (object.icc_profile_guid != null)
                    message.icc_profile_guid = String(object.icc_profile_guid);
                if (object.printer_output != null)
                    message.printer_output = Boolean(object.printer_output);
                if (object.use_raw_thumbnail != null)
                    message.use_raw_thumbnail = Boolean(object.use_raw_thumbnail);
                if (object.screening_config_guid != null)
                    message.screening_config_guid = String(object.screening_config_guid);
                if (object.copies != null)
                    message.copies = object.copies | 0;
                if (object.collate != null)
                    message.collate = Boolean(object.collate);
                if (object.bottom_margin_page != null)
                    message.bottom_margin_page = Number(object.bottom_margin_page);
                if (object.bottom_margin_copy != null)
                    message.bottom_margin_copy = Number(object.bottom_margin_copy);
                if (object.intent != null)
                    message.intent = object.intent | 0;
                if (object.preserve != null)
                    message.preserve = object.preserve | 0;
                if (object.selected_pages_to_print != null)
                    message.selected_pages_to_print = String(object.selected_pages_to_print);
                if (object.pages != null)
                    message.pages = String(object.pages);
                if (object.print_mode_pcl != null)
                    message.print_mode_pcl = object.print_mode_pcl | 0;
                return message;
            };

            /**
             * Creates a plain object from an OutputSettingsOverride message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.OutputSettingsOverride
             * @static
             * @param {appcore.ripe.OutputSettingsOverride} message OutputSettingsOverride
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OutputSettingsOverride.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.config_guid = "";
                    object.pdf_guid = "";
                    object.output_code = 0;
                    object.dpi_x = 0;
                    object.dpi_y = 0;
                    object.icc_profile_guid = "";
                    object.printer_output = false;
                    object.use_raw_thumbnail = false;
                    object.screening_config_guid = "";
                    object.copies = 0;
                    object.collate = false;
                    object.bottom_margin_page = 0;
                    object.bottom_margin_copy = 0;
                    object.intent = 0;
                    object.preserve = 0;
                    object.selected_pages_to_print = "";
                    object.pages = "";
                    object.print_mode_pcl = 0;
                }
                if (message.config_guid != null && message.hasOwnProperty("config_guid"))
                    object.config_guid = message.config_guid;
                if (message.pdf_guid != null && message.hasOwnProperty("pdf_guid"))
                    object.pdf_guid = message.pdf_guid;
                if (message.output_code != null && message.hasOwnProperty("output_code"))
                    object.output_code = message.output_code;
                if (message.dpi_x != null && message.hasOwnProperty("dpi_x"))
                    object.dpi_x = message.dpi_x;
                if (message.dpi_y != null && message.hasOwnProperty("dpi_y"))
                    object.dpi_y = message.dpi_y;
                if (message.icc_profile_guid != null && message.hasOwnProperty("icc_profile_guid"))
                    object.icc_profile_guid = message.icc_profile_guid;
                if (message.printer_output != null && message.hasOwnProperty("printer_output"))
                    object.printer_output = message.printer_output;
                if (message.use_raw_thumbnail != null && message.hasOwnProperty("use_raw_thumbnail"))
                    object.use_raw_thumbnail = message.use_raw_thumbnail;
                if (message.screening_config_guid != null && message.hasOwnProperty("screening_config_guid"))
                    object.screening_config_guid = message.screening_config_guid;
                if (message.copies != null && message.hasOwnProperty("copies"))
                    object.copies = message.copies;
                if (message.collate != null && message.hasOwnProperty("collate"))
                    object.collate = message.collate;
                if (message.bottom_margin_page != null && message.hasOwnProperty("bottom_margin_page"))
                    object.bottom_margin_page = options.json && !isFinite(message.bottom_margin_page) ? String(message.bottom_margin_page) : message.bottom_margin_page;
                if (message.bottom_margin_copy != null && message.hasOwnProperty("bottom_margin_copy"))
                    object.bottom_margin_copy = options.json && !isFinite(message.bottom_margin_copy) ? String(message.bottom_margin_copy) : message.bottom_margin_copy;
                if (message.intent != null && message.hasOwnProperty("intent"))
                    object.intent = message.intent;
                if (message.preserve != null && message.hasOwnProperty("preserve"))
                    object.preserve = message.preserve;
                if (message.selected_pages_to_print != null && message.hasOwnProperty("selected_pages_to_print"))
                    object.selected_pages_to_print = message.selected_pages_to_print;
                if (message.pages != null && message.hasOwnProperty("pages"))
                    object.pages = message.pages;
                if (message.print_mode_pcl != null && message.hasOwnProperty("print_mode_pcl"))
                    object.print_mode_pcl = message.print_mode_pcl;
                return object;
            };

            /**
             * Converts this OutputSettingsOverride to JSON.
             * @function toJSON
             * @memberof appcore.ripe.OutputSettingsOverride
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OutputSettingsOverride.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return OutputSettingsOverride;
        })();

        ripe.GenerateLiveView = (function() {

            /**
             * Properties of a GenerateLiveView.
             * @memberof appcore.ripe
             * @interface IGenerateLiveView
             * @property {string|null} [guid] GenerateLiveView guid
             * @property {string|null} [pdf_guid] GenerateLiveView pdf_guid
             * @property {string|null} [profile_guid] GenerateLiveView profile_guid
             * @property {string|null} [config_guid] GenerateLiveView config_guid
             * @property {appcore.ripe.IColorManagementConfig|null} [color_management_config] GenerateLiveView color_management_config
             * @property {appcore.ripe.IInkManagementConfig|null} [ink_management_config] GenerateLiveView ink_management_config
             * @property {number|null} [page_number] GenerateLiveView page_number
             */

            /**
             * Constructs a new GenerateLiveView.
             * @memberof appcore.ripe
             * @classdesc Represents a GenerateLiveView.
             * @implements IGenerateLiveView
             * @constructor
             * @param {appcore.ripe.IGenerateLiveView=} [properties] Properties to set
             */
            function GenerateLiveView(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GenerateLiveView guid.
             * @member {string} guid
             * @memberof appcore.ripe.GenerateLiveView
             * @instance
             */
            GenerateLiveView.prototype.guid = "";

            /**
             * GenerateLiveView pdf_guid.
             * @member {string} pdf_guid
             * @memberof appcore.ripe.GenerateLiveView
             * @instance
             */
            GenerateLiveView.prototype.pdf_guid = "";

            /**
             * GenerateLiveView profile_guid.
             * @member {string} profile_guid
             * @memberof appcore.ripe.GenerateLiveView
             * @instance
             */
            GenerateLiveView.prototype.profile_guid = "";

            /**
             * GenerateLiveView config_guid.
             * @member {string} config_guid
             * @memberof appcore.ripe.GenerateLiveView
             * @instance
             */
            GenerateLiveView.prototype.config_guid = "";

            /**
             * GenerateLiveView color_management_config.
             * @member {appcore.ripe.IColorManagementConfig|null|undefined} color_management_config
             * @memberof appcore.ripe.GenerateLiveView
             * @instance
             */
            GenerateLiveView.prototype.color_management_config = null;

            /**
             * GenerateLiveView ink_management_config.
             * @member {appcore.ripe.IInkManagementConfig|null|undefined} ink_management_config
             * @memberof appcore.ripe.GenerateLiveView
             * @instance
             */
            GenerateLiveView.prototype.ink_management_config = null;

            /**
             * GenerateLiveView page_number.
             * @member {number} page_number
             * @memberof appcore.ripe.GenerateLiveView
             * @instance
             */
            GenerateLiveView.prototype.page_number = 0;

            /**
             * Creates a new GenerateLiveView instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.GenerateLiveView
             * @static
             * @param {appcore.ripe.IGenerateLiveView=} [properties] Properties to set
             * @returns {appcore.ripe.GenerateLiveView} GenerateLiveView instance
             */
            GenerateLiveView.create = function create(properties) {
                return new GenerateLiveView(properties);
            };

            /**
             * Encodes the specified GenerateLiveView message. Does not implicitly {@link appcore.ripe.GenerateLiveView.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.GenerateLiveView
             * @static
             * @param {appcore.ripe.IGenerateLiveView} message GenerateLiveView message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GenerateLiveView.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.guid != null && Object.hasOwnProperty.call(message, "guid"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.guid);
                if (message.pdf_guid != null && Object.hasOwnProperty.call(message, "pdf_guid"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.pdf_guid);
                if (message.profile_guid != null && Object.hasOwnProperty.call(message, "profile_guid"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.profile_guid);
                if (message.config_guid != null && Object.hasOwnProperty.call(message, "config_guid"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.config_guid);
                if (message.color_management_config != null && Object.hasOwnProperty.call(message, "color_management_config"))
                    $root.appcore.ripe.ColorManagementConfig.encode(message.color_management_config, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.ink_management_config != null && Object.hasOwnProperty.call(message, "ink_management_config"))
                    $root.appcore.ripe.InkManagementConfig.encode(message.ink_management_config, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.page_number != null && Object.hasOwnProperty.call(message, "page_number"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.page_number);
                return writer;
            };

            /**
             * Encodes the specified GenerateLiveView message, length delimited. Does not implicitly {@link appcore.ripe.GenerateLiveView.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.GenerateLiveView
             * @static
             * @param {appcore.ripe.IGenerateLiveView} message GenerateLiveView message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GenerateLiveView.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GenerateLiveView message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.GenerateLiveView
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.GenerateLiveView} GenerateLiveView
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GenerateLiveView.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.GenerateLiveView();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.guid = reader.string();
                        break;
                    case 2:
                        message.pdf_guid = reader.string();
                        break;
                    case 3:
                        message.profile_guid = reader.string();
                        break;
                    case 4:
                        message.config_guid = reader.string();
                        break;
                    case 5:
                        message.color_management_config = $root.appcore.ripe.ColorManagementConfig.decode(reader, reader.uint32());
                        break;
                    case 6:
                        message.ink_management_config = $root.appcore.ripe.InkManagementConfig.decode(reader, reader.uint32());
                        break;
                    case 7:
                        message.page_number = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GenerateLiveView message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.GenerateLiveView
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.GenerateLiveView} GenerateLiveView
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GenerateLiveView.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GenerateLiveView message.
             * @function verify
             * @memberof appcore.ripe.GenerateLiveView
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GenerateLiveView.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.guid != null && message.hasOwnProperty("guid"))
                    if (!$util.isString(message.guid))
                        return "guid: string expected";
                if (message.pdf_guid != null && message.hasOwnProperty("pdf_guid"))
                    if (!$util.isString(message.pdf_guid))
                        return "pdf_guid: string expected";
                if (message.profile_guid != null && message.hasOwnProperty("profile_guid"))
                    if (!$util.isString(message.profile_guid))
                        return "profile_guid: string expected";
                if (message.config_guid != null && message.hasOwnProperty("config_guid"))
                    if (!$util.isString(message.config_guid))
                        return "config_guid: string expected";
                if (message.color_management_config != null && message.hasOwnProperty("color_management_config")) {
                    let error = $root.appcore.ripe.ColorManagementConfig.verify(message.color_management_config);
                    if (error)
                        return "color_management_config." + error;
                }
                if (message.ink_management_config != null && message.hasOwnProperty("ink_management_config")) {
                    let error = $root.appcore.ripe.InkManagementConfig.verify(message.ink_management_config);
                    if (error)
                        return "ink_management_config." + error;
                }
                if (message.page_number != null && message.hasOwnProperty("page_number"))
                    if (!$util.isInteger(message.page_number))
                        return "page_number: integer expected";
                return null;
            };

            /**
             * Creates a GenerateLiveView message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.GenerateLiveView
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.GenerateLiveView} GenerateLiveView
             */
            GenerateLiveView.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.GenerateLiveView)
                    return object;
                let message = new $root.appcore.ripe.GenerateLiveView();
                if (object.guid != null)
                    message.guid = String(object.guid);
                if (object.pdf_guid != null)
                    message.pdf_guid = String(object.pdf_guid);
                if (object.profile_guid != null)
                    message.profile_guid = String(object.profile_guid);
                if (object.config_guid != null)
                    message.config_guid = String(object.config_guid);
                if (object.color_management_config != null) {
                    if (typeof object.color_management_config !== "object")
                        throw TypeError(".appcore.ripe.GenerateLiveView.color_management_config: object expected");
                    message.color_management_config = $root.appcore.ripe.ColorManagementConfig.fromObject(object.color_management_config);
                }
                if (object.ink_management_config != null) {
                    if (typeof object.ink_management_config !== "object")
                        throw TypeError(".appcore.ripe.GenerateLiveView.ink_management_config: object expected");
                    message.ink_management_config = $root.appcore.ripe.InkManagementConfig.fromObject(object.ink_management_config);
                }
                if (object.page_number != null)
                    message.page_number = object.page_number | 0;
                return message;
            };

            /**
             * Creates a plain object from a GenerateLiveView message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.GenerateLiveView
             * @static
             * @param {appcore.ripe.GenerateLiveView} message GenerateLiveView
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GenerateLiveView.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.guid = "";
                    object.pdf_guid = "";
                    object.profile_guid = "";
                    object.config_guid = "";
                    object.color_management_config = null;
                    object.ink_management_config = null;
                    object.page_number = 0;
                }
                if (message.guid != null && message.hasOwnProperty("guid"))
                    object.guid = message.guid;
                if (message.pdf_guid != null && message.hasOwnProperty("pdf_guid"))
                    object.pdf_guid = message.pdf_guid;
                if (message.profile_guid != null && message.hasOwnProperty("profile_guid"))
                    object.profile_guid = message.profile_guid;
                if (message.config_guid != null && message.hasOwnProperty("config_guid"))
                    object.config_guid = message.config_guid;
                if (message.color_management_config != null && message.hasOwnProperty("color_management_config"))
                    object.color_management_config = $root.appcore.ripe.ColorManagementConfig.toObject(message.color_management_config, options);
                if (message.ink_management_config != null && message.hasOwnProperty("ink_management_config"))
                    object.ink_management_config = $root.appcore.ripe.InkManagementConfig.toObject(message.ink_management_config, options);
                if (message.page_number != null && message.hasOwnProperty("page_number"))
                    object.page_number = message.page_number;
                return object;
            };

            /**
             * Converts this GenerateLiveView to JSON.
             * @function toJSON
             * @memberof appcore.ripe.GenerateLiveView
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GenerateLiveView.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GenerateLiveView;
        })();

        ripe.InkManagementConfig = (function() {

            /**
             * Properties of an InkManagementConfig.
             * @memberof appcore.ripe
             * @interface IInkManagementConfig
             * @property {number|null} [all] InkManagementConfig all
             * @property {number|null} [C] InkManagementConfig C
             * @property {number|null} [M] InkManagementConfig M
             * @property {number|null} [Y] InkManagementConfig Y
             * @property {number|null} [K] InkManagementConfig K
             * @property {string|null} [config_guid] InkManagementConfig config_guid
             * @property {string|null} [pdf_guid] InkManagementConfig pdf_guid
             */

            /**
             * Constructs a new InkManagementConfig.
             * @memberof appcore.ripe
             * @classdesc Represents an InkManagementConfig.
             * @implements IInkManagementConfig
             * @constructor
             * @param {appcore.ripe.IInkManagementConfig=} [properties] Properties to set
             */
            function InkManagementConfig(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * InkManagementConfig all.
             * @member {number} all
             * @memberof appcore.ripe.InkManagementConfig
             * @instance
             */
            InkManagementConfig.prototype.all = 0;

            /**
             * InkManagementConfig C.
             * @member {number} C
             * @memberof appcore.ripe.InkManagementConfig
             * @instance
             */
            InkManagementConfig.prototype.C = 0;

            /**
             * InkManagementConfig M.
             * @member {number} M
             * @memberof appcore.ripe.InkManagementConfig
             * @instance
             */
            InkManagementConfig.prototype.M = 0;

            /**
             * InkManagementConfig Y.
             * @member {number} Y
             * @memberof appcore.ripe.InkManagementConfig
             * @instance
             */
            InkManagementConfig.prototype.Y = 0;

            /**
             * InkManagementConfig K.
             * @member {number} K
             * @memberof appcore.ripe.InkManagementConfig
             * @instance
             */
            InkManagementConfig.prototype.K = 0;

            /**
             * InkManagementConfig config_guid.
             * @member {string} config_guid
             * @memberof appcore.ripe.InkManagementConfig
             * @instance
             */
            InkManagementConfig.prototype.config_guid = "";

            /**
             * InkManagementConfig pdf_guid.
             * @member {string} pdf_guid
             * @memberof appcore.ripe.InkManagementConfig
             * @instance
             */
            InkManagementConfig.prototype.pdf_guid = "";

            /**
             * Creates a new InkManagementConfig instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.InkManagementConfig
             * @static
             * @param {appcore.ripe.IInkManagementConfig=} [properties] Properties to set
             * @returns {appcore.ripe.InkManagementConfig} InkManagementConfig instance
             */
            InkManagementConfig.create = function create(properties) {
                return new InkManagementConfig(properties);
            };

            /**
             * Encodes the specified InkManagementConfig message. Does not implicitly {@link appcore.ripe.InkManagementConfig.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.InkManagementConfig
             * @static
             * @param {appcore.ripe.IInkManagementConfig} message InkManagementConfig message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            InkManagementConfig.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.all != null && Object.hasOwnProperty.call(message, "all"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.all);
                if (message.C != null && Object.hasOwnProperty.call(message, "C"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.C);
                if (message.M != null && Object.hasOwnProperty.call(message, "M"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.M);
                if (message.Y != null && Object.hasOwnProperty.call(message, "Y"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.Y);
                if (message.K != null && Object.hasOwnProperty.call(message, "K"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.K);
                if (message.config_guid != null && Object.hasOwnProperty.call(message, "config_guid"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.config_guid);
                if (message.pdf_guid != null && Object.hasOwnProperty.call(message, "pdf_guid"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.pdf_guid);
                return writer;
            };

            /**
             * Encodes the specified InkManagementConfig message, length delimited. Does not implicitly {@link appcore.ripe.InkManagementConfig.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.InkManagementConfig
             * @static
             * @param {appcore.ripe.IInkManagementConfig} message InkManagementConfig message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            InkManagementConfig.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an InkManagementConfig message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.InkManagementConfig
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.InkManagementConfig} InkManagementConfig
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            InkManagementConfig.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.InkManagementConfig();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.all = reader.int32();
                        break;
                    case 2:
                        message.C = reader.int32();
                        break;
                    case 3:
                        message.M = reader.int32();
                        break;
                    case 4:
                        message.Y = reader.int32();
                        break;
                    case 5:
                        message.K = reader.int32();
                        break;
                    case 6:
                        message.config_guid = reader.string();
                        break;
                    case 7:
                        message.pdf_guid = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an InkManagementConfig message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.InkManagementConfig
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.InkManagementConfig} InkManagementConfig
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            InkManagementConfig.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an InkManagementConfig message.
             * @function verify
             * @memberof appcore.ripe.InkManagementConfig
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            InkManagementConfig.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.all != null && message.hasOwnProperty("all"))
                    if (!$util.isInteger(message.all))
                        return "all: integer expected";
                if (message.C != null && message.hasOwnProperty("C"))
                    if (!$util.isInteger(message.C))
                        return "C: integer expected";
                if (message.M != null && message.hasOwnProperty("M"))
                    if (!$util.isInteger(message.M))
                        return "M: integer expected";
                if (message.Y != null && message.hasOwnProperty("Y"))
                    if (!$util.isInteger(message.Y))
                        return "Y: integer expected";
                if (message.K != null && message.hasOwnProperty("K"))
                    if (!$util.isInteger(message.K))
                        return "K: integer expected";
                if (message.config_guid != null && message.hasOwnProperty("config_guid"))
                    if (!$util.isString(message.config_guid))
                        return "config_guid: string expected";
                if (message.pdf_guid != null && message.hasOwnProperty("pdf_guid"))
                    if (!$util.isString(message.pdf_guid))
                        return "pdf_guid: string expected";
                return null;
            };

            /**
             * Creates an InkManagementConfig message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.InkManagementConfig
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.InkManagementConfig} InkManagementConfig
             */
            InkManagementConfig.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.InkManagementConfig)
                    return object;
                let message = new $root.appcore.ripe.InkManagementConfig();
                if (object.all != null)
                    message.all = object.all | 0;
                if (object.C != null)
                    message.C = object.C | 0;
                if (object.M != null)
                    message.M = object.M | 0;
                if (object.Y != null)
                    message.Y = object.Y | 0;
                if (object.K != null)
                    message.K = object.K | 0;
                if (object.config_guid != null)
                    message.config_guid = String(object.config_guid);
                if (object.pdf_guid != null)
                    message.pdf_guid = String(object.pdf_guid);
                return message;
            };

            /**
             * Creates a plain object from an InkManagementConfig message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.InkManagementConfig
             * @static
             * @param {appcore.ripe.InkManagementConfig} message InkManagementConfig
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            InkManagementConfig.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.all = 0;
                    object.C = 0;
                    object.M = 0;
                    object.Y = 0;
                    object.K = 0;
                    object.config_guid = "";
                    object.pdf_guid = "";
                }
                if (message.all != null && message.hasOwnProperty("all"))
                    object.all = message.all;
                if (message.C != null && message.hasOwnProperty("C"))
                    object.C = message.C;
                if (message.M != null && message.hasOwnProperty("M"))
                    object.M = message.M;
                if (message.Y != null && message.hasOwnProperty("Y"))
                    object.Y = message.Y;
                if (message.K != null && message.hasOwnProperty("K"))
                    object.K = message.K;
                if (message.config_guid != null && message.hasOwnProperty("config_guid"))
                    object.config_guid = message.config_guid;
                if (message.pdf_guid != null && message.hasOwnProperty("pdf_guid"))
                    object.pdf_guid = message.pdf_guid;
                return object;
            };

            /**
             * Converts this InkManagementConfig to JSON.
             * @function toJSON
             * @memberof appcore.ripe.InkManagementConfig
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            InkManagementConfig.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return InkManagementConfig;
        })();

        ripe.GenerateLiveViewResult = (function() {

            /**
             * Properties of a GenerateLiveViewResult.
             * @memberof appcore.ripe
             * @interface IGenerateLiveViewResult
             * @property {string|null} [guid] GenerateLiveViewResult guid
             */

            /**
             * Constructs a new GenerateLiveViewResult.
             * @memberof appcore.ripe
             * @classdesc Represents a GenerateLiveViewResult.
             * @implements IGenerateLiveViewResult
             * @constructor
             * @param {appcore.ripe.IGenerateLiveViewResult=} [properties] Properties to set
             */
            function GenerateLiveViewResult(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GenerateLiveViewResult guid.
             * @member {string} guid
             * @memberof appcore.ripe.GenerateLiveViewResult
             * @instance
             */
            GenerateLiveViewResult.prototype.guid = "";

            /**
             * Creates a new GenerateLiveViewResult instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.GenerateLiveViewResult
             * @static
             * @param {appcore.ripe.IGenerateLiveViewResult=} [properties] Properties to set
             * @returns {appcore.ripe.GenerateLiveViewResult} GenerateLiveViewResult instance
             */
            GenerateLiveViewResult.create = function create(properties) {
                return new GenerateLiveViewResult(properties);
            };

            /**
             * Encodes the specified GenerateLiveViewResult message. Does not implicitly {@link appcore.ripe.GenerateLiveViewResult.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.GenerateLiveViewResult
             * @static
             * @param {appcore.ripe.IGenerateLiveViewResult} message GenerateLiveViewResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GenerateLiveViewResult.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.guid != null && Object.hasOwnProperty.call(message, "guid"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.guid);
                return writer;
            };

            /**
             * Encodes the specified GenerateLiveViewResult message, length delimited. Does not implicitly {@link appcore.ripe.GenerateLiveViewResult.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.GenerateLiveViewResult
             * @static
             * @param {appcore.ripe.IGenerateLiveViewResult} message GenerateLiveViewResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GenerateLiveViewResult.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GenerateLiveViewResult message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.GenerateLiveViewResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.GenerateLiveViewResult} GenerateLiveViewResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GenerateLiveViewResult.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.GenerateLiveViewResult();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.guid = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GenerateLiveViewResult message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.GenerateLiveViewResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.GenerateLiveViewResult} GenerateLiveViewResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GenerateLiveViewResult.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GenerateLiveViewResult message.
             * @function verify
             * @memberof appcore.ripe.GenerateLiveViewResult
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GenerateLiveViewResult.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.guid != null && message.hasOwnProperty("guid"))
                    if (!$util.isString(message.guid))
                        return "guid: string expected";
                return null;
            };

            /**
             * Creates a GenerateLiveViewResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.GenerateLiveViewResult
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.GenerateLiveViewResult} GenerateLiveViewResult
             */
            GenerateLiveViewResult.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.GenerateLiveViewResult)
                    return object;
                let message = new $root.appcore.ripe.GenerateLiveViewResult();
                if (object.guid != null)
                    message.guid = String(object.guid);
                return message;
            };

            /**
             * Creates a plain object from a GenerateLiveViewResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.GenerateLiveViewResult
             * @static
             * @param {appcore.ripe.GenerateLiveViewResult} message GenerateLiveViewResult
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GenerateLiveViewResult.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.guid = "";
                if (message.guid != null && message.hasOwnProperty("guid"))
                    object.guid = message.guid;
                return object;
            };

            /**
             * Converts this GenerateLiveViewResult to JSON.
             * @function toJSON
             * @memberof appcore.ripe.GenerateLiveViewResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GenerateLiveViewResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GenerateLiveViewResult;
        })();

        ripe.GenerateScreeningPreview = (function() {

            /**
             * Properties of a GenerateScreeningPreview.
             * @memberof appcore.ripe
             * @interface IGenerateScreeningPreview
             * @property {appcore.ripe.IScreeningConfig|null} [config] GenerateScreeningPreview config
             * @property {string|null} [filename] GenerateScreeningPreview filename
             */

            /**
             * Constructs a new GenerateScreeningPreview.
             * @memberof appcore.ripe
             * @classdesc Represents a GenerateScreeningPreview.
             * @implements IGenerateScreeningPreview
             * @constructor
             * @param {appcore.ripe.IGenerateScreeningPreview=} [properties] Properties to set
             */
            function GenerateScreeningPreview(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GenerateScreeningPreview config.
             * @member {appcore.ripe.IScreeningConfig|null|undefined} config
             * @memberof appcore.ripe.GenerateScreeningPreview
             * @instance
             */
            GenerateScreeningPreview.prototype.config = null;

            /**
             * GenerateScreeningPreview filename.
             * @member {string} filename
             * @memberof appcore.ripe.GenerateScreeningPreview
             * @instance
             */
            GenerateScreeningPreview.prototype.filename = "";

            /**
             * Creates a new GenerateScreeningPreview instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.GenerateScreeningPreview
             * @static
             * @param {appcore.ripe.IGenerateScreeningPreview=} [properties] Properties to set
             * @returns {appcore.ripe.GenerateScreeningPreview} GenerateScreeningPreview instance
             */
            GenerateScreeningPreview.create = function create(properties) {
                return new GenerateScreeningPreview(properties);
            };

            /**
             * Encodes the specified GenerateScreeningPreview message. Does not implicitly {@link appcore.ripe.GenerateScreeningPreview.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.GenerateScreeningPreview
             * @static
             * @param {appcore.ripe.IGenerateScreeningPreview} message GenerateScreeningPreview message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GenerateScreeningPreview.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.config != null && Object.hasOwnProperty.call(message, "config"))
                    $root.appcore.ripe.ScreeningConfig.encode(message.config, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.filename != null && Object.hasOwnProperty.call(message, "filename"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.filename);
                return writer;
            };

            /**
             * Encodes the specified GenerateScreeningPreview message, length delimited. Does not implicitly {@link appcore.ripe.GenerateScreeningPreview.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.GenerateScreeningPreview
             * @static
             * @param {appcore.ripe.IGenerateScreeningPreview} message GenerateScreeningPreview message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GenerateScreeningPreview.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GenerateScreeningPreview message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.GenerateScreeningPreview
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.GenerateScreeningPreview} GenerateScreeningPreview
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GenerateScreeningPreview.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.GenerateScreeningPreview();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.config = $root.appcore.ripe.ScreeningConfig.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.filename = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GenerateScreeningPreview message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.GenerateScreeningPreview
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.GenerateScreeningPreview} GenerateScreeningPreview
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GenerateScreeningPreview.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GenerateScreeningPreview message.
             * @function verify
             * @memberof appcore.ripe.GenerateScreeningPreview
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GenerateScreeningPreview.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.config != null && message.hasOwnProperty("config")) {
                    let error = $root.appcore.ripe.ScreeningConfig.verify(message.config);
                    if (error)
                        return "config." + error;
                }
                if (message.filename != null && message.hasOwnProperty("filename"))
                    if (!$util.isString(message.filename))
                        return "filename: string expected";
                return null;
            };

            /**
             * Creates a GenerateScreeningPreview message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.GenerateScreeningPreview
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.GenerateScreeningPreview} GenerateScreeningPreview
             */
            GenerateScreeningPreview.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.GenerateScreeningPreview)
                    return object;
                let message = new $root.appcore.ripe.GenerateScreeningPreview();
                if (object.config != null) {
                    if (typeof object.config !== "object")
                        throw TypeError(".appcore.ripe.GenerateScreeningPreview.config: object expected");
                    message.config = $root.appcore.ripe.ScreeningConfig.fromObject(object.config);
                }
                if (object.filename != null)
                    message.filename = String(object.filename);
                return message;
            };

            /**
             * Creates a plain object from a GenerateScreeningPreview message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.GenerateScreeningPreview
             * @static
             * @param {appcore.ripe.GenerateScreeningPreview} message GenerateScreeningPreview
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GenerateScreeningPreview.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.config = null;
                    object.filename = "";
                }
                if (message.config != null && message.hasOwnProperty("config"))
                    object.config = $root.appcore.ripe.ScreeningConfig.toObject(message.config, options);
                if (message.filename != null && message.hasOwnProperty("filename"))
                    object.filename = message.filename;
                return object;
            };

            /**
             * Converts this GenerateScreeningPreview to JSON.
             * @function toJSON
             * @memberof appcore.ripe.GenerateScreeningPreview
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GenerateScreeningPreview.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GenerateScreeningPreview;
        })();

        ripe.ScreeningConfig = (function() {

            /**
             * Properties of a ScreeningConfig.
             * @memberof appcore.ripe
             * @interface IScreeningConfig
             * @property {string|null} [guid] ScreeningConfig guid
             * @property {number|null} [algorithm] ScreeningConfig algorithm
             * @property {number|null} [c] ScreeningConfig c
             * @property {number|null} [m] ScreeningConfig m
             * @property {number|null} [y] ScreeningConfig y
             * @property {number|null} [k] ScreeningConfig k
             * @property {string|null} [name] ScreeningConfig name
             * @property {number|null} [is_pgm] ScreeningConfig is_pgm
             * @property {string|null} [pgm_c_name] ScreeningConfig pgm_c_name
             * @property {string|null} [pgm_m_name] ScreeningConfig pgm_m_name
             * @property {string|null} [pgm_y_name] ScreeningConfig pgm_y_name
             * @property {string|null} [pgm_k_name] ScreeningConfig pgm_k_name
             * @property {string|null} [pgm_c_filename] ScreeningConfig pgm_c_filename
             * @property {string|null} [pgm_m_filename] ScreeningConfig pgm_m_filename
             * @property {string|null} [pgm_y_filename] ScreeningConfig pgm_y_filename
             * @property {string|null} [pgm_k_filename] ScreeningConfig pgm_k_filename
             */

            /**
             * Constructs a new ScreeningConfig.
             * @memberof appcore.ripe
             * @classdesc Represents a ScreeningConfig.
             * @implements IScreeningConfig
             * @constructor
             * @param {appcore.ripe.IScreeningConfig=} [properties] Properties to set
             */
            function ScreeningConfig(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ScreeningConfig guid.
             * @member {string} guid
             * @memberof appcore.ripe.ScreeningConfig
             * @instance
             */
            ScreeningConfig.prototype.guid = "";

            /**
             * ScreeningConfig algorithm.
             * @member {number} algorithm
             * @memberof appcore.ripe.ScreeningConfig
             * @instance
             */
            ScreeningConfig.prototype.algorithm = 0;

            /**
             * ScreeningConfig c.
             * @member {number} c
             * @memberof appcore.ripe.ScreeningConfig
             * @instance
             */
            ScreeningConfig.prototype.c = 0;

            /**
             * ScreeningConfig m.
             * @member {number} m
             * @memberof appcore.ripe.ScreeningConfig
             * @instance
             */
            ScreeningConfig.prototype.m = 0;

            /**
             * ScreeningConfig y.
             * @member {number} y
             * @memberof appcore.ripe.ScreeningConfig
             * @instance
             */
            ScreeningConfig.prototype.y = 0;

            /**
             * ScreeningConfig k.
             * @member {number} k
             * @memberof appcore.ripe.ScreeningConfig
             * @instance
             */
            ScreeningConfig.prototype.k = 0;

            /**
             * ScreeningConfig name.
             * @member {string} name
             * @memberof appcore.ripe.ScreeningConfig
             * @instance
             */
            ScreeningConfig.prototype.name = "";

            /**
             * ScreeningConfig is_pgm.
             * @member {number} is_pgm
             * @memberof appcore.ripe.ScreeningConfig
             * @instance
             */
            ScreeningConfig.prototype.is_pgm = 0;

            /**
             * ScreeningConfig pgm_c_name.
             * @member {string} pgm_c_name
             * @memberof appcore.ripe.ScreeningConfig
             * @instance
             */
            ScreeningConfig.prototype.pgm_c_name = "";

            /**
             * ScreeningConfig pgm_m_name.
             * @member {string} pgm_m_name
             * @memberof appcore.ripe.ScreeningConfig
             * @instance
             */
            ScreeningConfig.prototype.pgm_m_name = "";

            /**
             * ScreeningConfig pgm_y_name.
             * @member {string} pgm_y_name
             * @memberof appcore.ripe.ScreeningConfig
             * @instance
             */
            ScreeningConfig.prototype.pgm_y_name = "";

            /**
             * ScreeningConfig pgm_k_name.
             * @member {string} pgm_k_name
             * @memberof appcore.ripe.ScreeningConfig
             * @instance
             */
            ScreeningConfig.prototype.pgm_k_name = "";

            /**
             * ScreeningConfig pgm_c_filename.
             * @member {string} pgm_c_filename
             * @memberof appcore.ripe.ScreeningConfig
             * @instance
             */
            ScreeningConfig.prototype.pgm_c_filename = "";

            /**
             * ScreeningConfig pgm_m_filename.
             * @member {string} pgm_m_filename
             * @memberof appcore.ripe.ScreeningConfig
             * @instance
             */
            ScreeningConfig.prototype.pgm_m_filename = "";

            /**
             * ScreeningConfig pgm_y_filename.
             * @member {string} pgm_y_filename
             * @memberof appcore.ripe.ScreeningConfig
             * @instance
             */
            ScreeningConfig.prototype.pgm_y_filename = "";

            /**
             * ScreeningConfig pgm_k_filename.
             * @member {string} pgm_k_filename
             * @memberof appcore.ripe.ScreeningConfig
             * @instance
             */
            ScreeningConfig.prototype.pgm_k_filename = "";

            /**
             * Creates a new ScreeningConfig instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.ScreeningConfig
             * @static
             * @param {appcore.ripe.IScreeningConfig=} [properties] Properties to set
             * @returns {appcore.ripe.ScreeningConfig} ScreeningConfig instance
             */
            ScreeningConfig.create = function create(properties) {
                return new ScreeningConfig(properties);
            };

            /**
             * Encodes the specified ScreeningConfig message. Does not implicitly {@link appcore.ripe.ScreeningConfig.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.ScreeningConfig
             * @static
             * @param {appcore.ripe.IScreeningConfig} message ScreeningConfig message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ScreeningConfig.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.guid != null && Object.hasOwnProperty.call(message, "guid"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.guid);
                if (message.algorithm != null && Object.hasOwnProperty.call(message, "algorithm"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.algorithm);
                if (message.c != null && Object.hasOwnProperty.call(message, "c"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.c);
                if (message.m != null && Object.hasOwnProperty.call(message, "m"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.m);
                if (message.y != null && Object.hasOwnProperty.call(message, "y"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.y);
                if (message.k != null && Object.hasOwnProperty.call(message, "k"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int32(message.k);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.name);
                if (message.is_pgm != null && Object.hasOwnProperty.call(message, "is_pgm"))
                    writer.uint32(/* id 8, wireType 0 =*/64).int32(message.is_pgm);
                if (message.pgm_c_name != null && Object.hasOwnProperty.call(message, "pgm_c_name"))
                    writer.uint32(/* id 9, wireType 2 =*/74).string(message.pgm_c_name);
                if (message.pgm_m_name != null && Object.hasOwnProperty.call(message, "pgm_m_name"))
                    writer.uint32(/* id 10, wireType 2 =*/82).string(message.pgm_m_name);
                if (message.pgm_y_name != null && Object.hasOwnProperty.call(message, "pgm_y_name"))
                    writer.uint32(/* id 11, wireType 2 =*/90).string(message.pgm_y_name);
                if (message.pgm_k_name != null && Object.hasOwnProperty.call(message, "pgm_k_name"))
                    writer.uint32(/* id 12, wireType 2 =*/98).string(message.pgm_k_name);
                if (message.pgm_c_filename != null && Object.hasOwnProperty.call(message, "pgm_c_filename"))
                    writer.uint32(/* id 13, wireType 2 =*/106).string(message.pgm_c_filename);
                if (message.pgm_m_filename != null && Object.hasOwnProperty.call(message, "pgm_m_filename"))
                    writer.uint32(/* id 14, wireType 2 =*/114).string(message.pgm_m_filename);
                if (message.pgm_y_filename != null && Object.hasOwnProperty.call(message, "pgm_y_filename"))
                    writer.uint32(/* id 15, wireType 2 =*/122).string(message.pgm_y_filename);
                if (message.pgm_k_filename != null && Object.hasOwnProperty.call(message, "pgm_k_filename"))
                    writer.uint32(/* id 16, wireType 2 =*/130).string(message.pgm_k_filename);
                return writer;
            };

            /**
             * Encodes the specified ScreeningConfig message, length delimited. Does not implicitly {@link appcore.ripe.ScreeningConfig.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.ScreeningConfig
             * @static
             * @param {appcore.ripe.IScreeningConfig} message ScreeningConfig message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ScreeningConfig.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ScreeningConfig message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.ScreeningConfig
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.ScreeningConfig} ScreeningConfig
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ScreeningConfig.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.ScreeningConfig();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.guid = reader.string();
                        break;
                    case 2:
                        message.algorithm = reader.int32();
                        break;
                    case 3:
                        message.c = reader.int32();
                        break;
                    case 4:
                        message.m = reader.int32();
                        break;
                    case 5:
                        message.y = reader.int32();
                        break;
                    case 6:
                        message.k = reader.int32();
                        break;
                    case 7:
                        message.name = reader.string();
                        break;
                    case 8:
                        message.is_pgm = reader.int32();
                        break;
                    case 9:
                        message.pgm_c_name = reader.string();
                        break;
                    case 10:
                        message.pgm_m_name = reader.string();
                        break;
                    case 11:
                        message.pgm_y_name = reader.string();
                        break;
                    case 12:
                        message.pgm_k_name = reader.string();
                        break;
                    case 13:
                        message.pgm_c_filename = reader.string();
                        break;
                    case 14:
                        message.pgm_m_filename = reader.string();
                        break;
                    case 15:
                        message.pgm_y_filename = reader.string();
                        break;
                    case 16:
                        message.pgm_k_filename = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ScreeningConfig message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.ScreeningConfig
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.ScreeningConfig} ScreeningConfig
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ScreeningConfig.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ScreeningConfig message.
             * @function verify
             * @memberof appcore.ripe.ScreeningConfig
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ScreeningConfig.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.guid != null && message.hasOwnProperty("guid"))
                    if (!$util.isString(message.guid))
                        return "guid: string expected";
                if (message.algorithm != null && message.hasOwnProperty("algorithm"))
                    if (!$util.isInteger(message.algorithm))
                        return "algorithm: integer expected";
                if (message.c != null && message.hasOwnProperty("c"))
                    if (!$util.isInteger(message.c))
                        return "c: integer expected";
                if (message.m != null && message.hasOwnProperty("m"))
                    if (!$util.isInteger(message.m))
                        return "m: integer expected";
                if (message.y != null && message.hasOwnProperty("y"))
                    if (!$util.isInteger(message.y))
                        return "y: integer expected";
                if (message.k != null && message.hasOwnProperty("k"))
                    if (!$util.isInteger(message.k))
                        return "k: integer expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.is_pgm != null && message.hasOwnProperty("is_pgm"))
                    if (!$util.isInteger(message.is_pgm))
                        return "is_pgm: integer expected";
                if (message.pgm_c_name != null && message.hasOwnProperty("pgm_c_name"))
                    if (!$util.isString(message.pgm_c_name))
                        return "pgm_c_name: string expected";
                if (message.pgm_m_name != null && message.hasOwnProperty("pgm_m_name"))
                    if (!$util.isString(message.pgm_m_name))
                        return "pgm_m_name: string expected";
                if (message.pgm_y_name != null && message.hasOwnProperty("pgm_y_name"))
                    if (!$util.isString(message.pgm_y_name))
                        return "pgm_y_name: string expected";
                if (message.pgm_k_name != null && message.hasOwnProperty("pgm_k_name"))
                    if (!$util.isString(message.pgm_k_name))
                        return "pgm_k_name: string expected";
                if (message.pgm_c_filename != null && message.hasOwnProperty("pgm_c_filename"))
                    if (!$util.isString(message.pgm_c_filename))
                        return "pgm_c_filename: string expected";
                if (message.pgm_m_filename != null && message.hasOwnProperty("pgm_m_filename"))
                    if (!$util.isString(message.pgm_m_filename))
                        return "pgm_m_filename: string expected";
                if (message.pgm_y_filename != null && message.hasOwnProperty("pgm_y_filename"))
                    if (!$util.isString(message.pgm_y_filename))
                        return "pgm_y_filename: string expected";
                if (message.pgm_k_filename != null && message.hasOwnProperty("pgm_k_filename"))
                    if (!$util.isString(message.pgm_k_filename))
                        return "pgm_k_filename: string expected";
                return null;
            };

            /**
             * Creates a ScreeningConfig message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.ScreeningConfig
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.ScreeningConfig} ScreeningConfig
             */
            ScreeningConfig.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.ScreeningConfig)
                    return object;
                let message = new $root.appcore.ripe.ScreeningConfig();
                if (object.guid != null)
                    message.guid = String(object.guid);
                if (object.algorithm != null)
                    message.algorithm = object.algorithm | 0;
                if (object.c != null)
                    message.c = object.c | 0;
                if (object.m != null)
                    message.m = object.m | 0;
                if (object.y != null)
                    message.y = object.y | 0;
                if (object.k != null)
                    message.k = object.k | 0;
                if (object.name != null)
                    message.name = String(object.name);
                if (object.is_pgm != null)
                    message.is_pgm = object.is_pgm | 0;
                if (object.pgm_c_name != null)
                    message.pgm_c_name = String(object.pgm_c_name);
                if (object.pgm_m_name != null)
                    message.pgm_m_name = String(object.pgm_m_name);
                if (object.pgm_y_name != null)
                    message.pgm_y_name = String(object.pgm_y_name);
                if (object.pgm_k_name != null)
                    message.pgm_k_name = String(object.pgm_k_name);
                if (object.pgm_c_filename != null)
                    message.pgm_c_filename = String(object.pgm_c_filename);
                if (object.pgm_m_filename != null)
                    message.pgm_m_filename = String(object.pgm_m_filename);
                if (object.pgm_y_filename != null)
                    message.pgm_y_filename = String(object.pgm_y_filename);
                if (object.pgm_k_filename != null)
                    message.pgm_k_filename = String(object.pgm_k_filename);
                return message;
            };

            /**
             * Creates a plain object from a ScreeningConfig message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.ScreeningConfig
             * @static
             * @param {appcore.ripe.ScreeningConfig} message ScreeningConfig
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ScreeningConfig.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.guid = "";
                    object.algorithm = 0;
                    object.c = 0;
                    object.m = 0;
                    object.y = 0;
                    object.k = 0;
                    object.name = "";
                    object.is_pgm = 0;
                    object.pgm_c_name = "";
                    object.pgm_m_name = "";
                    object.pgm_y_name = "";
                    object.pgm_k_name = "";
                    object.pgm_c_filename = "";
                    object.pgm_m_filename = "";
                    object.pgm_y_filename = "";
                    object.pgm_k_filename = "";
                }
                if (message.guid != null && message.hasOwnProperty("guid"))
                    object.guid = message.guid;
                if (message.algorithm != null && message.hasOwnProperty("algorithm"))
                    object.algorithm = message.algorithm;
                if (message.c != null && message.hasOwnProperty("c"))
                    object.c = message.c;
                if (message.m != null && message.hasOwnProperty("m"))
                    object.m = message.m;
                if (message.y != null && message.hasOwnProperty("y"))
                    object.y = message.y;
                if (message.k != null && message.hasOwnProperty("k"))
                    object.k = message.k;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.is_pgm != null && message.hasOwnProperty("is_pgm"))
                    object.is_pgm = message.is_pgm;
                if (message.pgm_c_name != null && message.hasOwnProperty("pgm_c_name"))
                    object.pgm_c_name = message.pgm_c_name;
                if (message.pgm_m_name != null && message.hasOwnProperty("pgm_m_name"))
                    object.pgm_m_name = message.pgm_m_name;
                if (message.pgm_y_name != null && message.hasOwnProperty("pgm_y_name"))
                    object.pgm_y_name = message.pgm_y_name;
                if (message.pgm_k_name != null && message.hasOwnProperty("pgm_k_name"))
                    object.pgm_k_name = message.pgm_k_name;
                if (message.pgm_c_filename != null && message.hasOwnProperty("pgm_c_filename"))
                    object.pgm_c_filename = message.pgm_c_filename;
                if (message.pgm_m_filename != null && message.hasOwnProperty("pgm_m_filename"))
                    object.pgm_m_filename = message.pgm_m_filename;
                if (message.pgm_y_filename != null && message.hasOwnProperty("pgm_y_filename"))
                    object.pgm_y_filename = message.pgm_y_filename;
                if (message.pgm_k_filename != null && message.hasOwnProperty("pgm_k_filename"))
                    object.pgm_k_filename = message.pgm_k_filename;
                return object;
            };

            /**
             * Converts this ScreeningConfig to JSON.
             * @function toJSON
             * @memberof appcore.ripe.ScreeningConfig
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ScreeningConfig.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ScreeningConfig;
        })();

        ripe.GenerateScreeningPreviewResult = (function() {

            /**
             * Properties of a GenerateScreeningPreviewResult.
             * @memberof appcore.ripe
             * @interface IGenerateScreeningPreviewResult
             * @property {string|null} [resultUrl] GenerateScreeningPreviewResult resultUrl
             */

            /**
             * Constructs a new GenerateScreeningPreviewResult.
             * @memberof appcore.ripe
             * @classdesc Represents a GenerateScreeningPreviewResult.
             * @implements IGenerateScreeningPreviewResult
             * @constructor
             * @param {appcore.ripe.IGenerateScreeningPreviewResult=} [properties] Properties to set
             */
            function GenerateScreeningPreviewResult(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GenerateScreeningPreviewResult resultUrl.
             * @member {string} resultUrl
             * @memberof appcore.ripe.GenerateScreeningPreviewResult
             * @instance
             */
            GenerateScreeningPreviewResult.prototype.resultUrl = "";

            /**
             * Creates a new GenerateScreeningPreviewResult instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.GenerateScreeningPreviewResult
             * @static
             * @param {appcore.ripe.IGenerateScreeningPreviewResult=} [properties] Properties to set
             * @returns {appcore.ripe.GenerateScreeningPreviewResult} GenerateScreeningPreviewResult instance
             */
            GenerateScreeningPreviewResult.create = function create(properties) {
                return new GenerateScreeningPreviewResult(properties);
            };

            /**
             * Encodes the specified GenerateScreeningPreviewResult message. Does not implicitly {@link appcore.ripe.GenerateScreeningPreviewResult.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.GenerateScreeningPreviewResult
             * @static
             * @param {appcore.ripe.IGenerateScreeningPreviewResult} message GenerateScreeningPreviewResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GenerateScreeningPreviewResult.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.resultUrl != null && Object.hasOwnProperty.call(message, "resultUrl"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.resultUrl);
                return writer;
            };

            /**
             * Encodes the specified GenerateScreeningPreviewResult message, length delimited. Does not implicitly {@link appcore.ripe.GenerateScreeningPreviewResult.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.GenerateScreeningPreviewResult
             * @static
             * @param {appcore.ripe.IGenerateScreeningPreviewResult} message GenerateScreeningPreviewResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GenerateScreeningPreviewResult.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GenerateScreeningPreviewResult message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.GenerateScreeningPreviewResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.GenerateScreeningPreviewResult} GenerateScreeningPreviewResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GenerateScreeningPreviewResult.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.GenerateScreeningPreviewResult();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.resultUrl = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GenerateScreeningPreviewResult message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.GenerateScreeningPreviewResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.GenerateScreeningPreviewResult} GenerateScreeningPreviewResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GenerateScreeningPreviewResult.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GenerateScreeningPreviewResult message.
             * @function verify
             * @memberof appcore.ripe.GenerateScreeningPreviewResult
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GenerateScreeningPreviewResult.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.resultUrl != null && message.hasOwnProperty("resultUrl"))
                    if (!$util.isString(message.resultUrl))
                        return "resultUrl: string expected";
                return null;
            };

            /**
             * Creates a GenerateScreeningPreviewResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.GenerateScreeningPreviewResult
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.GenerateScreeningPreviewResult} GenerateScreeningPreviewResult
             */
            GenerateScreeningPreviewResult.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.GenerateScreeningPreviewResult)
                    return object;
                let message = new $root.appcore.ripe.GenerateScreeningPreviewResult();
                if (object.resultUrl != null)
                    message.resultUrl = String(object.resultUrl);
                return message;
            };

            /**
             * Creates a plain object from a GenerateScreeningPreviewResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.GenerateScreeningPreviewResult
             * @static
             * @param {appcore.ripe.GenerateScreeningPreviewResult} message GenerateScreeningPreviewResult
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GenerateScreeningPreviewResult.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.resultUrl = "";
                if (message.resultUrl != null && message.hasOwnProperty("resultUrl"))
                    object.resultUrl = message.resultUrl;
                return object;
            };

            /**
             * Converts this GenerateScreeningPreviewResult to JSON.
             * @function toJSON
             * @memberof appcore.ripe.GenerateScreeningPreviewResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GenerateScreeningPreviewResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GenerateScreeningPreviewResult;
        })();

        ripe.GenerateStatus = (function() {

            /**
             * Properties of a GenerateStatus.
             * @memberof appcore.ripe
             * @interface IGenerateStatus
             * @property {number|null} [status] GenerateStatus status
             * @property {string|null} [guid] GenerateStatus guid
             * @property {string|null} [config_guid] GenerateStatus config_guid
             * @property {string|null} [filename] GenerateStatus filename
             * @property {string|null} [config_name] GenerateStatus config_name
             */

            /**
             * Constructs a new GenerateStatus.
             * @memberof appcore.ripe
             * @classdesc Represents a GenerateStatus.
             * @implements IGenerateStatus
             * @constructor
             * @param {appcore.ripe.IGenerateStatus=} [properties] Properties to set
             */
            function GenerateStatus(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GenerateStatus status.
             * @member {number} status
             * @memberof appcore.ripe.GenerateStatus
             * @instance
             */
            GenerateStatus.prototype.status = 0;

            /**
             * GenerateStatus guid.
             * @member {string} guid
             * @memberof appcore.ripe.GenerateStatus
             * @instance
             */
            GenerateStatus.prototype.guid = "";

            /**
             * GenerateStatus config_guid.
             * @member {string} config_guid
             * @memberof appcore.ripe.GenerateStatus
             * @instance
             */
            GenerateStatus.prototype.config_guid = "";

            /**
             * GenerateStatus filename.
             * @member {string} filename
             * @memberof appcore.ripe.GenerateStatus
             * @instance
             */
            GenerateStatus.prototype.filename = "";

            /**
             * GenerateStatus config_name.
             * @member {string} config_name
             * @memberof appcore.ripe.GenerateStatus
             * @instance
             */
            GenerateStatus.prototype.config_name = "";

            /**
             * Creates a new GenerateStatus instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.GenerateStatus
             * @static
             * @param {appcore.ripe.IGenerateStatus=} [properties] Properties to set
             * @returns {appcore.ripe.GenerateStatus} GenerateStatus instance
             */
            GenerateStatus.create = function create(properties) {
                return new GenerateStatus(properties);
            };

            /**
             * Encodes the specified GenerateStatus message. Does not implicitly {@link appcore.ripe.GenerateStatus.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.GenerateStatus
             * @static
             * @param {appcore.ripe.IGenerateStatus} message GenerateStatus message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GenerateStatus.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
                if (message.guid != null && Object.hasOwnProperty.call(message, "guid"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.guid);
                if (message.config_guid != null && Object.hasOwnProperty.call(message, "config_guid"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.config_guid);
                if (message.filename != null && Object.hasOwnProperty.call(message, "filename"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.filename);
                if (message.config_name != null && Object.hasOwnProperty.call(message, "config_name"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.config_name);
                return writer;
            };

            /**
             * Encodes the specified GenerateStatus message, length delimited. Does not implicitly {@link appcore.ripe.GenerateStatus.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.GenerateStatus
             * @static
             * @param {appcore.ripe.IGenerateStatus} message GenerateStatus message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GenerateStatus.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GenerateStatus message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.GenerateStatus
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.GenerateStatus} GenerateStatus
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GenerateStatus.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.GenerateStatus();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.status = reader.int32();
                        break;
                    case 2:
                        message.guid = reader.string();
                        break;
                    case 3:
                        message.config_guid = reader.string();
                        break;
                    case 4:
                        message.filename = reader.string();
                        break;
                    case 5:
                        message.config_name = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GenerateStatus message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.GenerateStatus
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.GenerateStatus} GenerateStatus
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GenerateStatus.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GenerateStatus message.
             * @function verify
             * @memberof appcore.ripe.GenerateStatus
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GenerateStatus.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.status != null && message.hasOwnProperty("status"))
                    if (!$util.isInteger(message.status))
                        return "status: integer expected";
                if (message.guid != null && message.hasOwnProperty("guid"))
                    if (!$util.isString(message.guid))
                        return "guid: string expected";
                if (message.config_guid != null && message.hasOwnProperty("config_guid"))
                    if (!$util.isString(message.config_guid))
                        return "config_guid: string expected";
                if (message.filename != null && message.hasOwnProperty("filename"))
                    if (!$util.isString(message.filename))
                        return "filename: string expected";
                if (message.config_name != null && message.hasOwnProperty("config_name"))
                    if (!$util.isString(message.config_name))
                        return "config_name: string expected";
                return null;
            };

            /**
             * Creates a GenerateStatus message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.GenerateStatus
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.GenerateStatus} GenerateStatus
             */
            GenerateStatus.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.GenerateStatus)
                    return object;
                let message = new $root.appcore.ripe.GenerateStatus();
                if (object.status != null)
                    message.status = object.status | 0;
                if (object.guid != null)
                    message.guid = String(object.guid);
                if (object.config_guid != null)
                    message.config_guid = String(object.config_guid);
                if (object.filename != null)
                    message.filename = String(object.filename);
                if (object.config_name != null)
                    message.config_name = String(object.config_name);
                return message;
            };

            /**
             * Creates a plain object from a GenerateStatus message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.GenerateStatus
             * @static
             * @param {appcore.ripe.GenerateStatus} message GenerateStatus
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GenerateStatus.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.status = 0;
                    object.guid = "";
                    object.config_guid = "";
                    object.filename = "";
                    object.config_name = "";
                }
                if (message.status != null && message.hasOwnProperty("status"))
                    object.status = message.status;
                if (message.guid != null && message.hasOwnProperty("guid"))
                    object.guid = message.guid;
                if (message.config_guid != null && message.hasOwnProperty("config_guid"))
                    object.config_guid = message.config_guid;
                if (message.filename != null && message.hasOwnProperty("filename"))
                    object.filename = message.filename;
                if (message.config_name != null && message.hasOwnProperty("config_name"))
                    object.config_name = message.config_name;
                return object;
            };

            /**
             * Converts this GenerateStatus to JSON.
             * @function toJSON
             * @memberof appcore.ripe.GenerateStatus
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GenerateStatus.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GenerateStatus;
        })();

        ripe.HWEntryProperty = (function() {

            /**
             * Properties of a HWEntryProperty.
             * @memberof appcore.ripe
             * @interface IHWEntryProperty
             * @property {string|null} [key] HWEntryProperty key
             * @property {Uint8Array|null} [value] HWEntryProperty value
             */

            /**
             * Constructs a new HWEntryProperty.
             * @memberof appcore.ripe
             * @classdesc Represents a HWEntryProperty.
             * @implements IHWEntryProperty
             * @constructor
             * @param {appcore.ripe.IHWEntryProperty=} [properties] Properties to set
             */
            function HWEntryProperty(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * HWEntryProperty key.
             * @member {string} key
             * @memberof appcore.ripe.HWEntryProperty
             * @instance
             */
            HWEntryProperty.prototype.key = "";

            /**
             * HWEntryProperty value.
             * @member {Uint8Array} value
             * @memberof appcore.ripe.HWEntryProperty
             * @instance
             */
            HWEntryProperty.prototype.value = $util.newBuffer([]);

            /**
             * Creates a new HWEntryProperty instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.HWEntryProperty
             * @static
             * @param {appcore.ripe.IHWEntryProperty=} [properties] Properties to set
             * @returns {appcore.ripe.HWEntryProperty} HWEntryProperty instance
             */
            HWEntryProperty.create = function create(properties) {
                return new HWEntryProperty(properties);
            };

            /**
             * Encodes the specified HWEntryProperty message. Does not implicitly {@link appcore.ripe.HWEntryProperty.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.HWEntryProperty
             * @static
             * @param {appcore.ripe.IHWEntryProperty} message HWEntryProperty message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            HWEntryProperty.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.value);
                return writer;
            };

            /**
             * Encodes the specified HWEntryProperty message, length delimited. Does not implicitly {@link appcore.ripe.HWEntryProperty.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.HWEntryProperty
             * @static
             * @param {appcore.ripe.IHWEntryProperty} message HWEntryProperty message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            HWEntryProperty.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a HWEntryProperty message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.HWEntryProperty
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.HWEntryProperty} HWEntryProperty
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            HWEntryProperty.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.HWEntryProperty();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.key = reader.string();
                        break;
                    case 2:
                        message.value = reader.bytes();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a HWEntryProperty message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.HWEntryProperty
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.HWEntryProperty} HWEntryProperty
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            HWEntryProperty.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a HWEntryProperty message.
             * @function verify
             * @memberof appcore.ripe.HWEntryProperty
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            HWEntryProperty.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.key != null && message.hasOwnProperty("key"))
                    if (!$util.isString(message.key))
                        return "key: string expected";
                if (message.value != null && message.hasOwnProperty("value"))
                    if (!(message.value && typeof message.value.length === "number" || $util.isString(message.value)))
                        return "value: buffer expected";
                return null;
            };

            /**
             * Creates a HWEntryProperty message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.HWEntryProperty
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.HWEntryProperty} HWEntryProperty
             */
            HWEntryProperty.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.HWEntryProperty)
                    return object;
                let message = new $root.appcore.ripe.HWEntryProperty();
                if (object.key != null)
                    message.key = String(object.key);
                if (object.value != null)
                    if (typeof object.value === "string")
                        $util.base64.decode(object.value, message.value = $util.newBuffer($util.base64.length(object.value)), 0);
                    else if (object.value.length)
                        message.value = object.value;
                return message;
            };

            /**
             * Creates a plain object from a HWEntryProperty message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.HWEntryProperty
             * @static
             * @param {appcore.ripe.HWEntryProperty} message HWEntryProperty
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            HWEntryProperty.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.key = "";
                    if (options.bytes === String)
                        object.value = "";
                    else {
                        object.value = [];
                        if (options.bytes !== Array)
                            object.value = $util.newBuffer(object.value);
                    }
                }
                if (message.key != null && message.hasOwnProperty("key"))
                    object.key = message.key;
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = options.bytes === String ? $util.base64.encode(message.value, 0, message.value.length) : options.bytes === Array ? Array.prototype.slice.call(message.value) : message.value;
                return object;
            };

            /**
             * Converts this HWEntryProperty to JSON.
             * @function toJSON
             * @memberof appcore.ripe.HWEntryProperty
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            HWEntryProperty.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return HWEntryProperty;
        })();

        ripe.HWEntry = (function() {

            /**
             * Properties of a HWEntry.
             * @memberof appcore.ripe
             * @interface IHWEntry
             * @property {string|null} [name] HWEntry name
             * @property {Array.<appcore.ripe.IHWEntryProperty>|null} [properties] HWEntry properties
             */

            /**
             * Constructs a new HWEntry.
             * @memberof appcore.ripe
             * @classdesc Represents a HWEntry.
             * @implements IHWEntry
             * @constructor
             * @param {appcore.ripe.IHWEntry=} [properties] Properties to set
             */
            function HWEntry(properties) {
                this.properties = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * HWEntry name.
             * @member {string} name
             * @memberof appcore.ripe.HWEntry
             * @instance
             */
            HWEntry.prototype.name = "";

            /**
             * HWEntry properties.
             * @member {Array.<appcore.ripe.IHWEntryProperty>} properties
             * @memberof appcore.ripe.HWEntry
             * @instance
             */
            HWEntry.prototype.properties = $util.emptyArray;

            /**
             * Creates a new HWEntry instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.HWEntry
             * @static
             * @param {appcore.ripe.IHWEntry=} [properties] Properties to set
             * @returns {appcore.ripe.HWEntry} HWEntry instance
             */
            HWEntry.create = function create(properties) {
                return new HWEntry(properties);
            };

            /**
             * Encodes the specified HWEntry message. Does not implicitly {@link appcore.ripe.HWEntry.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.HWEntry
             * @static
             * @param {appcore.ripe.IHWEntry} message HWEntry message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            HWEntry.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.properties != null && message.properties.length)
                    for (let i = 0; i < message.properties.length; ++i)
                        $root.appcore.ripe.HWEntryProperty.encode(message.properties[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified HWEntry message, length delimited. Does not implicitly {@link appcore.ripe.HWEntry.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.HWEntry
             * @static
             * @param {appcore.ripe.IHWEntry} message HWEntry message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            HWEntry.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a HWEntry message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.HWEntry
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.HWEntry} HWEntry
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            HWEntry.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.HWEntry();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.name = reader.string();
                        break;
                    case 2:
                        if (!(message.properties && message.properties.length))
                            message.properties = [];
                        message.properties.push($root.appcore.ripe.HWEntryProperty.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a HWEntry message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.HWEntry
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.HWEntry} HWEntry
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            HWEntry.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a HWEntry message.
             * @function verify
             * @memberof appcore.ripe.HWEntry
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            HWEntry.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.properties != null && message.hasOwnProperty("properties")) {
                    if (!Array.isArray(message.properties))
                        return "properties: array expected";
                    for (let i = 0; i < message.properties.length; ++i) {
                        let error = $root.appcore.ripe.HWEntryProperty.verify(message.properties[i]);
                        if (error)
                            return "properties." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a HWEntry message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.HWEntry
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.HWEntry} HWEntry
             */
            HWEntry.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.HWEntry)
                    return object;
                let message = new $root.appcore.ripe.HWEntry();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.properties) {
                    if (!Array.isArray(object.properties))
                        throw TypeError(".appcore.ripe.HWEntry.properties: array expected");
                    message.properties = [];
                    for (let i = 0; i < object.properties.length; ++i) {
                        if (typeof object.properties[i] !== "object")
                            throw TypeError(".appcore.ripe.HWEntry.properties: object expected");
                        message.properties[i] = $root.appcore.ripe.HWEntryProperty.fromObject(object.properties[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a HWEntry message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.HWEntry
             * @static
             * @param {appcore.ripe.HWEntry} message HWEntry
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            HWEntry.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.properties = [];
                if (options.defaults)
                    object.name = "";
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.properties && message.properties.length) {
                    object.properties = [];
                    for (let j = 0; j < message.properties.length; ++j)
                        object.properties[j] = $root.appcore.ripe.HWEntryProperty.toObject(message.properties[j], options);
                }
                return object;
            };

            /**
             * Converts this HWEntry to JSON.
             * @function toJSON
             * @memberof appcore.ripe.HWEntry
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            HWEntry.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return HWEntry;
        })();

        ripe.HWEntries = (function() {

            /**
             * Properties of a HWEntries.
             * @memberof appcore.ripe
             * @interface IHWEntries
             * @property {Array.<appcore.ripe.IHWEntry>|null} [hw_entries] HWEntries hw_entries
             */

            /**
             * Constructs a new HWEntries.
             * @memberof appcore.ripe
             * @classdesc Represents a HWEntries.
             * @implements IHWEntries
             * @constructor
             * @param {appcore.ripe.IHWEntries=} [properties] Properties to set
             */
            function HWEntries(properties) {
                this.hw_entries = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * HWEntries hw_entries.
             * @member {Array.<appcore.ripe.IHWEntry>} hw_entries
             * @memberof appcore.ripe.HWEntries
             * @instance
             */
            HWEntries.prototype.hw_entries = $util.emptyArray;

            /**
             * Creates a new HWEntries instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.HWEntries
             * @static
             * @param {appcore.ripe.IHWEntries=} [properties] Properties to set
             * @returns {appcore.ripe.HWEntries} HWEntries instance
             */
            HWEntries.create = function create(properties) {
                return new HWEntries(properties);
            };

            /**
             * Encodes the specified HWEntries message. Does not implicitly {@link appcore.ripe.HWEntries.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.HWEntries
             * @static
             * @param {appcore.ripe.IHWEntries} message HWEntries message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            HWEntries.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.hw_entries != null && message.hw_entries.length)
                    for (let i = 0; i < message.hw_entries.length; ++i)
                        $root.appcore.ripe.HWEntry.encode(message.hw_entries[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified HWEntries message, length delimited. Does not implicitly {@link appcore.ripe.HWEntries.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.HWEntries
             * @static
             * @param {appcore.ripe.IHWEntries} message HWEntries message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            HWEntries.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a HWEntries message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.HWEntries
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.HWEntries} HWEntries
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            HWEntries.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.HWEntries();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.hw_entries && message.hw_entries.length))
                            message.hw_entries = [];
                        message.hw_entries.push($root.appcore.ripe.HWEntry.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a HWEntries message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.HWEntries
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.HWEntries} HWEntries
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            HWEntries.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a HWEntries message.
             * @function verify
             * @memberof appcore.ripe.HWEntries
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            HWEntries.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.hw_entries != null && message.hasOwnProperty("hw_entries")) {
                    if (!Array.isArray(message.hw_entries))
                        return "hw_entries: array expected";
                    for (let i = 0; i < message.hw_entries.length; ++i) {
                        let error = $root.appcore.ripe.HWEntry.verify(message.hw_entries[i]);
                        if (error)
                            return "hw_entries." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a HWEntries message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.HWEntries
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.HWEntries} HWEntries
             */
            HWEntries.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.HWEntries)
                    return object;
                let message = new $root.appcore.ripe.HWEntries();
                if (object.hw_entries) {
                    if (!Array.isArray(object.hw_entries))
                        throw TypeError(".appcore.ripe.HWEntries.hw_entries: array expected");
                    message.hw_entries = [];
                    for (let i = 0; i < object.hw_entries.length; ++i) {
                        if (typeof object.hw_entries[i] !== "object")
                            throw TypeError(".appcore.ripe.HWEntries.hw_entries: object expected");
                        message.hw_entries[i] = $root.appcore.ripe.HWEntry.fromObject(object.hw_entries[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a HWEntries message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.HWEntries
             * @static
             * @param {appcore.ripe.HWEntries} message HWEntries
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            HWEntries.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.hw_entries = [];
                if (message.hw_entries && message.hw_entries.length) {
                    object.hw_entries = [];
                    for (let j = 0; j < message.hw_entries.length; ++j)
                        object.hw_entries[j] = $root.appcore.ripe.HWEntry.toObject(message.hw_entries[j], options);
                }
                return object;
            };

            /**
             * Converts this HWEntries to JSON.
             * @function toJSON
             * @memberof appcore.ripe.HWEntries
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            HWEntries.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return HWEntries;
        })();

        ripe.ICCProfile = (function() {

            /**
             * Properties of a ICCProfile.
             * @memberof appcore.ripe
             * @interface IICCProfile
             * @property {string|null} [name] ICCProfile name
             * @property {string|null} [guid] ICCProfile guid
             * @property {boolean|null} [downloadable] ICCProfile downloadable
             */

            /**
             * Constructs a new ICCProfile.
             * @memberof appcore.ripe
             * @classdesc Represents a ICCProfile.
             * @implements IICCProfile
             * @constructor
             * @param {appcore.ripe.IICCProfile=} [properties] Properties to set
             */
            function ICCProfile(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ICCProfile name.
             * @member {string} name
             * @memberof appcore.ripe.ICCProfile
             * @instance
             */
            ICCProfile.prototype.name = "";

            /**
             * ICCProfile guid.
             * @member {string} guid
             * @memberof appcore.ripe.ICCProfile
             * @instance
             */
            ICCProfile.prototype.guid = "";

            /**
             * ICCProfile downloadable.
             * @member {boolean} downloadable
             * @memberof appcore.ripe.ICCProfile
             * @instance
             */
            ICCProfile.prototype.downloadable = false;

            /**
             * Creates a new ICCProfile instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.ICCProfile
             * @static
             * @param {appcore.ripe.IICCProfile=} [properties] Properties to set
             * @returns {appcore.ripe.ICCProfile} ICCProfile instance
             */
            ICCProfile.create = function create(properties) {
                return new ICCProfile(properties);
            };

            /**
             * Encodes the specified ICCProfile message. Does not implicitly {@link appcore.ripe.ICCProfile.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.ICCProfile
             * @static
             * @param {appcore.ripe.IICCProfile} message ICCProfile message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ICCProfile.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.guid != null && Object.hasOwnProperty.call(message, "guid"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.guid);
                if (message.downloadable != null && Object.hasOwnProperty.call(message, "downloadable"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.downloadable);
                return writer;
            };

            /**
             * Encodes the specified ICCProfile message, length delimited. Does not implicitly {@link appcore.ripe.ICCProfile.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.ICCProfile
             * @static
             * @param {appcore.ripe.IICCProfile} message ICCProfile message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ICCProfile.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ICCProfile message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.ICCProfile
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.ICCProfile} ICCProfile
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ICCProfile.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.ICCProfile();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.name = reader.string();
                        break;
                    case 2:
                        message.guid = reader.string();
                        break;
                    case 3:
                        message.downloadable = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ICCProfile message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.ICCProfile
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.ICCProfile} ICCProfile
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ICCProfile.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ICCProfile message.
             * @function verify
             * @memberof appcore.ripe.ICCProfile
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ICCProfile.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.guid != null && message.hasOwnProperty("guid"))
                    if (!$util.isString(message.guid))
                        return "guid: string expected";
                if (message.downloadable != null && message.hasOwnProperty("downloadable"))
                    if (typeof message.downloadable !== "boolean")
                        return "downloadable: boolean expected";
                return null;
            };

            /**
             * Creates a ICCProfile message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.ICCProfile
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.ICCProfile} ICCProfile
             */
            ICCProfile.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.ICCProfile)
                    return object;
                let message = new $root.appcore.ripe.ICCProfile();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.guid != null)
                    message.guid = String(object.guid);
                if (object.downloadable != null)
                    message.downloadable = Boolean(object.downloadable);
                return message;
            };

            /**
             * Creates a plain object from a ICCProfile message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.ICCProfile
             * @static
             * @param {appcore.ripe.ICCProfile} message ICCProfile
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ICCProfile.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.name = "";
                    object.guid = "";
                    object.downloadable = false;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.guid != null && message.hasOwnProperty("guid"))
                    object.guid = message.guid;
                if (message.downloadable != null && message.hasOwnProperty("downloadable"))
                    object.downloadable = message.downloadable;
                return object;
            };

            /**
             * Converts this ICCProfile to JSON.
             * @function toJSON
             * @memberof appcore.ripe.ICCProfile
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ICCProfile.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ICCProfile;
        })();

        ripe.ICCProfiles = (function() {

            /**
             * Properties of a ICCProfiles.
             * @memberof appcore.ripe
             * @interface IICCProfiles
             * @property {Array.<appcore.ripe.IICCProfile>|null} [icc_profiles] ICCProfiles icc_profiles
             */

            /**
             * Constructs a new ICCProfiles.
             * @memberof appcore.ripe
             * @classdesc Represents a ICCProfiles.
             * @implements IICCProfiles
             * @constructor
             * @param {appcore.ripe.IICCProfiles=} [properties] Properties to set
             */
            function ICCProfiles(properties) {
                this.icc_profiles = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ICCProfiles icc_profiles.
             * @member {Array.<appcore.ripe.IICCProfile>} icc_profiles
             * @memberof appcore.ripe.ICCProfiles
             * @instance
             */
            ICCProfiles.prototype.icc_profiles = $util.emptyArray;

            /**
             * Creates a new ICCProfiles instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.ICCProfiles
             * @static
             * @param {appcore.ripe.IICCProfiles=} [properties] Properties to set
             * @returns {appcore.ripe.ICCProfiles} ICCProfiles instance
             */
            ICCProfiles.create = function create(properties) {
                return new ICCProfiles(properties);
            };

            /**
             * Encodes the specified ICCProfiles message. Does not implicitly {@link appcore.ripe.ICCProfiles.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.ICCProfiles
             * @static
             * @param {appcore.ripe.IICCProfiles} message ICCProfiles message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ICCProfiles.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.icc_profiles != null && message.icc_profiles.length)
                    for (let i = 0; i < message.icc_profiles.length; ++i)
                        $root.appcore.ripe.ICCProfile.encode(message.icc_profiles[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified ICCProfiles message, length delimited. Does not implicitly {@link appcore.ripe.ICCProfiles.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.ICCProfiles
             * @static
             * @param {appcore.ripe.IICCProfiles} message ICCProfiles message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ICCProfiles.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ICCProfiles message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.ICCProfiles
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.ICCProfiles} ICCProfiles
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ICCProfiles.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.ICCProfiles();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.icc_profiles && message.icc_profiles.length))
                            message.icc_profiles = [];
                        message.icc_profiles.push($root.appcore.ripe.ICCProfile.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ICCProfiles message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.ICCProfiles
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.ICCProfiles} ICCProfiles
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ICCProfiles.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ICCProfiles message.
             * @function verify
             * @memberof appcore.ripe.ICCProfiles
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ICCProfiles.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.icc_profiles != null && message.hasOwnProperty("icc_profiles")) {
                    if (!Array.isArray(message.icc_profiles))
                        return "icc_profiles: array expected";
                    for (let i = 0; i < message.icc_profiles.length; ++i) {
                        let error = $root.appcore.ripe.ICCProfile.verify(message.icc_profiles[i]);
                        if (error)
                            return "icc_profiles." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a ICCProfiles message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.ICCProfiles
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.ICCProfiles} ICCProfiles
             */
            ICCProfiles.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.ICCProfiles)
                    return object;
                let message = new $root.appcore.ripe.ICCProfiles();
                if (object.icc_profiles) {
                    if (!Array.isArray(object.icc_profiles))
                        throw TypeError(".appcore.ripe.ICCProfiles.icc_profiles: array expected");
                    message.icc_profiles = [];
                    for (let i = 0; i < object.icc_profiles.length; ++i) {
                        if (typeof object.icc_profiles[i] !== "object")
                            throw TypeError(".appcore.ripe.ICCProfiles.icc_profiles: object expected");
                        message.icc_profiles[i] = $root.appcore.ripe.ICCProfile.fromObject(object.icc_profiles[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a ICCProfiles message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.ICCProfiles
             * @static
             * @param {appcore.ripe.ICCProfiles} message ICCProfiles
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ICCProfiles.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.icc_profiles = [];
                if (message.icc_profiles && message.icc_profiles.length) {
                    object.icc_profiles = [];
                    for (let j = 0; j < message.icc_profiles.length; ++j)
                        object.icc_profiles[j] = $root.appcore.ripe.ICCProfile.toObject(message.icc_profiles[j], options);
                }
                return object;
            };

            /**
             * Converts this ICCProfiles to JSON.
             * @function toJSON
             * @memberof appcore.ripe.ICCProfiles
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ICCProfiles.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ICCProfiles;
        })();

        ripe.ImageSizePosition = (function() {

            /**
             * Properties of an ImageSizePosition.
             * @memberof appcore.ripe
             * @interface IImageSizePosition
             * @property {boolean|null} [inch] ImageSizePosition inch
             * @property {number|null} [offset_unit] ImageSizePosition offset_unit
             * @property {number|null} [resize_unit] ImageSizePosition resize_unit
             * @property {number|null} [x_offset] ImageSizePosition x_offset
             * @property {number|null} [y_offset] ImageSizePosition y_offset
             * @property {number|null} [rotation] ImageSizePosition rotation
             * @property {number|null} [width] ImageSizePosition width
             * @property {number|null} [height] ImageSizePosition height
             * @property {string|null} [config_guid] ImageSizePosition config_guid
             * @property {string|null} [pdf_guid] ImageSizePosition pdf_guid
             */

            /**
             * Constructs a new ImageSizePosition.
             * @memberof appcore.ripe
             * @classdesc Represents an ImageSizePosition.
             * @implements IImageSizePosition
             * @constructor
             * @param {appcore.ripe.IImageSizePosition=} [properties] Properties to set
             */
            function ImageSizePosition(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ImageSizePosition inch.
             * @member {boolean} inch
             * @memberof appcore.ripe.ImageSizePosition
             * @instance
             */
            ImageSizePosition.prototype.inch = false;

            /**
             * ImageSizePosition offset_unit.
             * @member {number} offset_unit
             * @memberof appcore.ripe.ImageSizePosition
             * @instance
             */
            ImageSizePosition.prototype.offset_unit = 0;

            /**
             * ImageSizePosition resize_unit.
             * @member {number} resize_unit
             * @memberof appcore.ripe.ImageSizePosition
             * @instance
             */
            ImageSizePosition.prototype.resize_unit = 0;

            /**
             * ImageSizePosition x_offset.
             * @member {number} x_offset
             * @memberof appcore.ripe.ImageSizePosition
             * @instance
             */
            ImageSizePosition.prototype.x_offset = 0;

            /**
             * ImageSizePosition y_offset.
             * @member {number} y_offset
             * @memberof appcore.ripe.ImageSizePosition
             * @instance
             */
            ImageSizePosition.prototype.y_offset = 0;

            /**
             * ImageSizePosition rotation.
             * @member {number} rotation
             * @memberof appcore.ripe.ImageSizePosition
             * @instance
             */
            ImageSizePosition.prototype.rotation = 0;

            /**
             * ImageSizePosition width.
             * @member {number} width
             * @memberof appcore.ripe.ImageSizePosition
             * @instance
             */
            ImageSizePosition.prototype.width = 0;

            /**
             * ImageSizePosition height.
             * @member {number} height
             * @memberof appcore.ripe.ImageSizePosition
             * @instance
             */
            ImageSizePosition.prototype.height = 0;

            /**
             * ImageSizePosition config_guid.
             * @member {string} config_guid
             * @memberof appcore.ripe.ImageSizePosition
             * @instance
             */
            ImageSizePosition.prototype.config_guid = "";

            /**
             * ImageSizePosition pdf_guid.
             * @member {string} pdf_guid
             * @memberof appcore.ripe.ImageSizePosition
             * @instance
             */
            ImageSizePosition.prototype.pdf_guid = "";

            /**
             * Creates a new ImageSizePosition instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.ImageSizePosition
             * @static
             * @param {appcore.ripe.IImageSizePosition=} [properties] Properties to set
             * @returns {appcore.ripe.ImageSizePosition} ImageSizePosition instance
             */
            ImageSizePosition.create = function create(properties) {
                return new ImageSizePosition(properties);
            };

            /**
             * Encodes the specified ImageSizePosition message. Does not implicitly {@link appcore.ripe.ImageSizePosition.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.ImageSizePosition
             * @static
             * @param {appcore.ripe.IImageSizePosition} message ImageSizePosition message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ImageSizePosition.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.inch != null && Object.hasOwnProperty.call(message, "inch"))
                    writer.uint32(/* id 1, wireType 0 =*/8).bool(message.inch);
                if (message.offset_unit != null && Object.hasOwnProperty.call(message, "offset_unit"))
                    writer.uint32(/* id 2, wireType 5 =*/21).float(message.offset_unit);
                if (message.resize_unit != null && Object.hasOwnProperty.call(message, "resize_unit"))
                    writer.uint32(/* id 3, wireType 5 =*/29).float(message.resize_unit);
                if (message.x_offset != null && Object.hasOwnProperty.call(message, "x_offset"))
                    writer.uint32(/* id 4, wireType 5 =*/37).float(message.x_offset);
                if (message.y_offset != null && Object.hasOwnProperty.call(message, "y_offset"))
                    writer.uint32(/* id 5, wireType 5 =*/45).float(message.y_offset);
                if (message.rotation != null && Object.hasOwnProperty.call(message, "rotation"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int32(message.rotation);
                if (message.width != null && Object.hasOwnProperty.call(message, "width"))
                    writer.uint32(/* id 7, wireType 5 =*/61).float(message.width);
                if (message.height != null && Object.hasOwnProperty.call(message, "height"))
                    writer.uint32(/* id 8, wireType 5 =*/69).float(message.height);
                if (message.config_guid != null && Object.hasOwnProperty.call(message, "config_guid"))
                    writer.uint32(/* id 9, wireType 2 =*/74).string(message.config_guid);
                if (message.pdf_guid != null && Object.hasOwnProperty.call(message, "pdf_guid"))
                    writer.uint32(/* id 10, wireType 2 =*/82).string(message.pdf_guid);
                return writer;
            };

            /**
             * Encodes the specified ImageSizePosition message, length delimited. Does not implicitly {@link appcore.ripe.ImageSizePosition.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.ImageSizePosition
             * @static
             * @param {appcore.ripe.IImageSizePosition} message ImageSizePosition message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ImageSizePosition.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an ImageSizePosition message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.ImageSizePosition
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.ImageSizePosition} ImageSizePosition
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ImageSizePosition.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.ImageSizePosition();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.inch = reader.bool();
                        break;
                    case 2:
                        message.offset_unit = reader.float();
                        break;
                    case 3:
                        message.resize_unit = reader.float();
                        break;
                    case 4:
                        message.x_offset = reader.float();
                        break;
                    case 5:
                        message.y_offset = reader.float();
                        break;
                    case 6:
                        message.rotation = reader.int32();
                        break;
                    case 7:
                        message.width = reader.float();
                        break;
                    case 8:
                        message.height = reader.float();
                        break;
                    case 9:
                        message.config_guid = reader.string();
                        break;
                    case 10:
                        message.pdf_guid = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an ImageSizePosition message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.ImageSizePosition
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.ImageSizePosition} ImageSizePosition
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ImageSizePosition.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an ImageSizePosition message.
             * @function verify
             * @memberof appcore.ripe.ImageSizePosition
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ImageSizePosition.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.inch != null && message.hasOwnProperty("inch"))
                    if (typeof message.inch !== "boolean")
                        return "inch: boolean expected";
                if (message.offset_unit != null && message.hasOwnProperty("offset_unit"))
                    if (typeof message.offset_unit !== "number")
                        return "offset_unit: number expected";
                if (message.resize_unit != null && message.hasOwnProperty("resize_unit"))
                    if (typeof message.resize_unit !== "number")
                        return "resize_unit: number expected";
                if (message.x_offset != null && message.hasOwnProperty("x_offset"))
                    if (typeof message.x_offset !== "number")
                        return "x_offset: number expected";
                if (message.y_offset != null && message.hasOwnProperty("y_offset"))
                    if (typeof message.y_offset !== "number")
                        return "y_offset: number expected";
                if (message.rotation != null && message.hasOwnProperty("rotation"))
                    if (!$util.isInteger(message.rotation))
                        return "rotation: integer expected";
                if (message.width != null && message.hasOwnProperty("width"))
                    if (typeof message.width !== "number")
                        return "width: number expected";
                if (message.height != null && message.hasOwnProperty("height"))
                    if (typeof message.height !== "number")
                        return "height: number expected";
                if (message.config_guid != null && message.hasOwnProperty("config_guid"))
                    if (!$util.isString(message.config_guid))
                        return "config_guid: string expected";
                if (message.pdf_guid != null && message.hasOwnProperty("pdf_guid"))
                    if (!$util.isString(message.pdf_guid))
                        return "pdf_guid: string expected";
                return null;
            };

            /**
             * Creates an ImageSizePosition message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.ImageSizePosition
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.ImageSizePosition} ImageSizePosition
             */
            ImageSizePosition.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.ImageSizePosition)
                    return object;
                let message = new $root.appcore.ripe.ImageSizePosition();
                if (object.inch != null)
                    message.inch = Boolean(object.inch);
                if (object.offset_unit != null)
                    message.offset_unit = Number(object.offset_unit);
                if (object.resize_unit != null)
                    message.resize_unit = Number(object.resize_unit);
                if (object.x_offset != null)
                    message.x_offset = Number(object.x_offset);
                if (object.y_offset != null)
                    message.y_offset = Number(object.y_offset);
                if (object.rotation != null)
                    message.rotation = object.rotation | 0;
                if (object.width != null)
                    message.width = Number(object.width);
                if (object.height != null)
                    message.height = Number(object.height);
                if (object.config_guid != null)
                    message.config_guid = String(object.config_guid);
                if (object.pdf_guid != null)
                    message.pdf_guid = String(object.pdf_guid);
                return message;
            };

            /**
             * Creates a plain object from an ImageSizePosition message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.ImageSizePosition
             * @static
             * @param {appcore.ripe.ImageSizePosition} message ImageSizePosition
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ImageSizePosition.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.inch = false;
                    object.offset_unit = 0;
                    object.resize_unit = 0;
                    object.x_offset = 0;
                    object.y_offset = 0;
                    object.rotation = 0;
                    object.width = 0;
                    object.height = 0;
                    object.config_guid = "";
                    object.pdf_guid = "";
                }
                if (message.inch != null && message.hasOwnProperty("inch"))
                    object.inch = message.inch;
                if (message.offset_unit != null && message.hasOwnProperty("offset_unit"))
                    object.offset_unit = options.json && !isFinite(message.offset_unit) ? String(message.offset_unit) : message.offset_unit;
                if (message.resize_unit != null && message.hasOwnProperty("resize_unit"))
                    object.resize_unit = options.json && !isFinite(message.resize_unit) ? String(message.resize_unit) : message.resize_unit;
                if (message.x_offset != null && message.hasOwnProperty("x_offset"))
                    object.x_offset = options.json && !isFinite(message.x_offset) ? String(message.x_offset) : message.x_offset;
                if (message.y_offset != null && message.hasOwnProperty("y_offset"))
                    object.y_offset = options.json && !isFinite(message.y_offset) ? String(message.y_offset) : message.y_offset;
                if (message.rotation != null && message.hasOwnProperty("rotation"))
                    object.rotation = message.rotation;
                if (message.width != null && message.hasOwnProperty("width"))
                    object.width = options.json && !isFinite(message.width) ? String(message.width) : message.width;
                if (message.height != null && message.hasOwnProperty("height"))
                    object.height = options.json && !isFinite(message.height) ? String(message.height) : message.height;
                if (message.config_guid != null && message.hasOwnProperty("config_guid"))
                    object.config_guid = message.config_guid;
                if (message.pdf_guid != null && message.hasOwnProperty("pdf_guid"))
                    object.pdf_guid = message.pdf_guid;
                return object;
            };

            /**
             * Converts this ImageSizePosition to JSON.
             * @function toJSON
             * @memberof appcore.ripe.ImageSizePosition
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ImageSizePosition.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ImageSizePosition;
        })();

        ripe.ImageSizePositions = (function() {

            /**
             * Properties of an ImageSizePositions.
             * @memberof appcore.ripe
             * @interface IImageSizePositions
             * @property {Array.<appcore.ripe.IImageSizePosition>|null} [size_positions] ImageSizePositions size_positions
             */

            /**
             * Constructs a new ImageSizePositions.
             * @memberof appcore.ripe
             * @classdesc Represents an ImageSizePositions.
             * @implements IImageSizePositions
             * @constructor
             * @param {appcore.ripe.IImageSizePositions=} [properties] Properties to set
             */
            function ImageSizePositions(properties) {
                this.size_positions = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ImageSizePositions size_positions.
             * @member {Array.<appcore.ripe.IImageSizePosition>} size_positions
             * @memberof appcore.ripe.ImageSizePositions
             * @instance
             */
            ImageSizePositions.prototype.size_positions = $util.emptyArray;

            /**
             * Creates a new ImageSizePositions instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.ImageSizePositions
             * @static
             * @param {appcore.ripe.IImageSizePositions=} [properties] Properties to set
             * @returns {appcore.ripe.ImageSizePositions} ImageSizePositions instance
             */
            ImageSizePositions.create = function create(properties) {
                return new ImageSizePositions(properties);
            };

            /**
             * Encodes the specified ImageSizePositions message. Does not implicitly {@link appcore.ripe.ImageSizePositions.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.ImageSizePositions
             * @static
             * @param {appcore.ripe.IImageSizePositions} message ImageSizePositions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ImageSizePositions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.size_positions != null && message.size_positions.length)
                    for (let i = 0; i < message.size_positions.length; ++i)
                        $root.appcore.ripe.ImageSizePosition.encode(message.size_positions[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified ImageSizePositions message, length delimited. Does not implicitly {@link appcore.ripe.ImageSizePositions.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.ImageSizePositions
             * @static
             * @param {appcore.ripe.IImageSizePositions} message ImageSizePositions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ImageSizePositions.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an ImageSizePositions message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.ImageSizePositions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.ImageSizePositions} ImageSizePositions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ImageSizePositions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.ImageSizePositions();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.size_positions && message.size_positions.length))
                            message.size_positions = [];
                        message.size_positions.push($root.appcore.ripe.ImageSizePosition.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an ImageSizePositions message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.ImageSizePositions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.ImageSizePositions} ImageSizePositions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ImageSizePositions.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an ImageSizePositions message.
             * @function verify
             * @memberof appcore.ripe.ImageSizePositions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ImageSizePositions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.size_positions != null && message.hasOwnProperty("size_positions")) {
                    if (!Array.isArray(message.size_positions))
                        return "size_positions: array expected";
                    for (let i = 0; i < message.size_positions.length; ++i) {
                        let error = $root.appcore.ripe.ImageSizePosition.verify(message.size_positions[i]);
                        if (error)
                            return "size_positions." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an ImageSizePositions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.ImageSizePositions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.ImageSizePositions} ImageSizePositions
             */
            ImageSizePositions.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.ImageSizePositions)
                    return object;
                let message = new $root.appcore.ripe.ImageSizePositions();
                if (object.size_positions) {
                    if (!Array.isArray(object.size_positions))
                        throw TypeError(".appcore.ripe.ImageSizePositions.size_positions: array expected");
                    message.size_positions = [];
                    for (let i = 0; i < object.size_positions.length; ++i) {
                        if (typeof object.size_positions[i] !== "object")
                            throw TypeError(".appcore.ripe.ImageSizePositions.size_positions: object expected");
                        message.size_positions[i] = $root.appcore.ripe.ImageSizePosition.fromObject(object.size_positions[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an ImageSizePositions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.ImageSizePositions
             * @static
             * @param {appcore.ripe.ImageSizePositions} message ImageSizePositions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ImageSizePositions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.size_positions = [];
                if (message.size_positions && message.size_positions.length) {
                    object.size_positions = [];
                    for (let j = 0; j < message.size_positions.length; ++j)
                        object.size_positions[j] = $root.appcore.ripe.ImageSizePosition.toObject(message.size_positions[j], options);
                }
                return object;
            };

            /**
             * Converts this ImageSizePositions to JSON.
             * @function toJSON
             * @memberof appcore.ripe.ImageSizePositions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ImageSizePositions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ImageSizePositions;
        })();

        ripe.InkManagementConfigs = (function() {

            /**
             * Properties of an InkManagementConfigs.
             * @memberof appcore.ripe
             * @interface IInkManagementConfigs
             * @property {Array.<appcore.ripe.IInkManagementConfig>|null} [configs] InkManagementConfigs configs
             */

            /**
             * Constructs a new InkManagementConfigs.
             * @memberof appcore.ripe
             * @classdesc Represents an InkManagementConfigs.
             * @implements IInkManagementConfigs
             * @constructor
             * @param {appcore.ripe.IInkManagementConfigs=} [properties] Properties to set
             */
            function InkManagementConfigs(properties) {
                this.configs = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * InkManagementConfigs configs.
             * @member {Array.<appcore.ripe.IInkManagementConfig>} configs
             * @memberof appcore.ripe.InkManagementConfigs
             * @instance
             */
            InkManagementConfigs.prototype.configs = $util.emptyArray;

            /**
             * Creates a new InkManagementConfigs instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.InkManagementConfigs
             * @static
             * @param {appcore.ripe.IInkManagementConfigs=} [properties] Properties to set
             * @returns {appcore.ripe.InkManagementConfigs} InkManagementConfigs instance
             */
            InkManagementConfigs.create = function create(properties) {
                return new InkManagementConfigs(properties);
            };

            /**
             * Encodes the specified InkManagementConfigs message. Does not implicitly {@link appcore.ripe.InkManagementConfigs.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.InkManagementConfigs
             * @static
             * @param {appcore.ripe.IInkManagementConfigs} message InkManagementConfigs message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            InkManagementConfigs.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.configs != null && message.configs.length)
                    for (let i = 0; i < message.configs.length; ++i)
                        $root.appcore.ripe.InkManagementConfig.encode(message.configs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified InkManagementConfigs message, length delimited. Does not implicitly {@link appcore.ripe.InkManagementConfigs.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.InkManagementConfigs
             * @static
             * @param {appcore.ripe.IInkManagementConfigs} message InkManagementConfigs message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            InkManagementConfigs.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an InkManagementConfigs message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.InkManagementConfigs
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.InkManagementConfigs} InkManagementConfigs
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            InkManagementConfigs.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.InkManagementConfigs();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.configs && message.configs.length))
                            message.configs = [];
                        message.configs.push($root.appcore.ripe.InkManagementConfig.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an InkManagementConfigs message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.InkManagementConfigs
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.InkManagementConfigs} InkManagementConfigs
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            InkManagementConfigs.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an InkManagementConfigs message.
             * @function verify
             * @memberof appcore.ripe.InkManagementConfigs
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            InkManagementConfigs.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.configs != null && message.hasOwnProperty("configs")) {
                    if (!Array.isArray(message.configs))
                        return "configs: array expected";
                    for (let i = 0; i < message.configs.length; ++i) {
                        let error = $root.appcore.ripe.InkManagementConfig.verify(message.configs[i]);
                        if (error)
                            return "configs." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an InkManagementConfigs message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.InkManagementConfigs
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.InkManagementConfigs} InkManagementConfigs
             */
            InkManagementConfigs.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.InkManagementConfigs)
                    return object;
                let message = new $root.appcore.ripe.InkManagementConfigs();
                if (object.configs) {
                    if (!Array.isArray(object.configs))
                        throw TypeError(".appcore.ripe.InkManagementConfigs.configs: array expected");
                    message.configs = [];
                    for (let i = 0; i < object.configs.length; ++i) {
                        if (typeof object.configs[i] !== "object")
                            throw TypeError(".appcore.ripe.InkManagementConfigs.configs: object expected");
                        message.configs[i] = $root.appcore.ripe.InkManagementConfig.fromObject(object.configs[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an InkManagementConfigs message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.InkManagementConfigs
             * @static
             * @param {appcore.ripe.InkManagementConfigs} message InkManagementConfigs
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            InkManagementConfigs.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.configs = [];
                if (message.configs && message.configs.length) {
                    object.configs = [];
                    for (let j = 0; j < message.configs.length; ++j)
                        object.configs[j] = $root.appcore.ripe.InkManagementConfig.toObject(message.configs[j], options);
                }
                return object;
            };

            /**
             * Converts this InkManagementConfigs to JSON.
             * @function toJSON
             * @memberof appcore.ripe.InkManagementConfigs
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            InkManagementConfigs.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return InkManagementConfigs;
        })();

        ripe.JobDescription = (function() {

            /**
             * Properties of a JobDescription.
             * @memberof appcore.ripe
             * @interface IJobDescription
             * @property {number|Long|null} [job_length_dots] JobDescription job_length_dots
             * @property {number|null} [job_length_mm] JobDescription job_length_mm
             * @property {number|null} [job_length_inch] JobDescription job_length_inch
             * @property {string|null} [job_name] JobDescription job_name
             * @property {string|null} [rip_version] JobDescription rip_version
             * @property {number|Long|null} [total_job_length_dots] JobDescription total_job_length_dots
             * @property {number|null} [total_job_length_mm] JobDescription total_job_length_mm
             * @property {number|null} [total_job_length_inch] JobDescription total_job_length_inch
             * @property {number|Long|null} [bottom_margin_page_dots] JobDescription bottom_margin_page_dots
             * @property {number|null} [bottom_margin_page_mm] JobDescription bottom_margin_page_mm
             * @property {number|null} [bottom_margin_page_inch] JobDescription bottom_margin_page_inch
             * @property {number|Long|null} [bottom_margin_copy_dots] JobDescription bottom_margin_copy_dots
             * @property {number|null} [bottom_margin_copy_mm] JobDescription bottom_margin_copy_mm
             * @property {number|null} [bottom_margin_copy_inch] JobDescription bottom_margin_copy_inch
             * @property {number|null} [copies] JobDescription copies
             * @property {boolean|null} [collate] JobDescription collate
             * @property {number|Long|null} [page_length_dots] JobDescription page_length_dots
             * @property {number|null} [page_length_mm] JobDescription page_length_mm
             * @property {number|null} [page_length_inch] JobDescription page_length_inch
             * @property {number|Long|null} [pages] JobDescription pages
             * @property {number|null} [dpi_x] JobDescription dpi_x
             * @property {number|null} [dpi_y] JobDescription dpi_y
             * @property {number|Long|null} [c_drops] JobDescription c_drops
             * @property {number|Long|null} [m_drops] JobDescription m_drops
             * @property {number|Long|null} [y_drops] JobDescription y_drops
             * @property {number|Long|null} [k_drops] JobDescription k_drops
             */

            /**
             * Constructs a new JobDescription.
             * @memberof appcore.ripe
             * @classdesc Represents a JobDescription.
             * @implements IJobDescription
             * @constructor
             * @param {appcore.ripe.IJobDescription=} [properties] Properties to set
             */
            function JobDescription(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * JobDescription job_length_dots.
             * @member {number|Long} job_length_dots
             * @memberof appcore.ripe.JobDescription
             * @instance
             */
            JobDescription.prototype.job_length_dots = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * JobDescription job_length_mm.
             * @member {number} job_length_mm
             * @memberof appcore.ripe.JobDescription
             * @instance
             */
            JobDescription.prototype.job_length_mm = 0;

            /**
             * JobDescription job_length_inch.
             * @member {number} job_length_inch
             * @memberof appcore.ripe.JobDescription
             * @instance
             */
            JobDescription.prototype.job_length_inch = 0;

            /**
             * JobDescription job_name.
             * @member {string} job_name
             * @memberof appcore.ripe.JobDescription
             * @instance
             */
            JobDescription.prototype.job_name = "";

            /**
             * JobDescription rip_version.
             * @member {string} rip_version
             * @memberof appcore.ripe.JobDescription
             * @instance
             */
            JobDescription.prototype.rip_version = "";

            /**
             * JobDescription total_job_length_dots.
             * @member {number|Long} total_job_length_dots
             * @memberof appcore.ripe.JobDescription
             * @instance
             */
            JobDescription.prototype.total_job_length_dots = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * JobDescription total_job_length_mm.
             * @member {number} total_job_length_mm
             * @memberof appcore.ripe.JobDescription
             * @instance
             */
            JobDescription.prototype.total_job_length_mm = 0;

            /**
             * JobDescription total_job_length_inch.
             * @member {number} total_job_length_inch
             * @memberof appcore.ripe.JobDescription
             * @instance
             */
            JobDescription.prototype.total_job_length_inch = 0;

            /**
             * JobDescription bottom_margin_page_dots.
             * @member {number|Long} bottom_margin_page_dots
             * @memberof appcore.ripe.JobDescription
             * @instance
             */
            JobDescription.prototype.bottom_margin_page_dots = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * JobDescription bottom_margin_page_mm.
             * @member {number} bottom_margin_page_mm
             * @memberof appcore.ripe.JobDescription
             * @instance
             */
            JobDescription.prototype.bottom_margin_page_mm = 0;

            /**
             * JobDescription bottom_margin_page_inch.
             * @member {number} bottom_margin_page_inch
             * @memberof appcore.ripe.JobDescription
             * @instance
             */
            JobDescription.prototype.bottom_margin_page_inch = 0;

            /**
             * JobDescription bottom_margin_copy_dots.
             * @member {number|Long} bottom_margin_copy_dots
             * @memberof appcore.ripe.JobDescription
             * @instance
             */
            JobDescription.prototype.bottom_margin_copy_dots = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * JobDescription bottom_margin_copy_mm.
             * @member {number} bottom_margin_copy_mm
             * @memberof appcore.ripe.JobDescription
             * @instance
             */
            JobDescription.prototype.bottom_margin_copy_mm = 0;

            /**
             * JobDescription bottom_margin_copy_inch.
             * @member {number} bottom_margin_copy_inch
             * @memberof appcore.ripe.JobDescription
             * @instance
             */
            JobDescription.prototype.bottom_margin_copy_inch = 0;

            /**
             * JobDescription copies.
             * @member {number} copies
             * @memberof appcore.ripe.JobDescription
             * @instance
             */
            JobDescription.prototype.copies = 0;

            /**
             * JobDescription collate.
             * @member {boolean} collate
             * @memberof appcore.ripe.JobDescription
             * @instance
             */
            JobDescription.prototype.collate = false;

            /**
             * JobDescription page_length_dots.
             * @member {number|Long} page_length_dots
             * @memberof appcore.ripe.JobDescription
             * @instance
             */
            JobDescription.prototype.page_length_dots = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * JobDescription page_length_mm.
             * @member {number} page_length_mm
             * @memberof appcore.ripe.JobDescription
             * @instance
             */
            JobDescription.prototype.page_length_mm = 0;

            /**
             * JobDescription page_length_inch.
             * @member {number} page_length_inch
             * @memberof appcore.ripe.JobDescription
             * @instance
             */
            JobDescription.prototype.page_length_inch = 0;

            /**
             * JobDescription pages.
             * @member {number|Long} pages
             * @memberof appcore.ripe.JobDescription
             * @instance
             */
            JobDescription.prototype.pages = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * JobDescription dpi_x.
             * @member {number} dpi_x
             * @memberof appcore.ripe.JobDescription
             * @instance
             */
            JobDescription.prototype.dpi_x = 0;

            /**
             * JobDescription dpi_y.
             * @member {number} dpi_y
             * @memberof appcore.ripe.JobDescription
             * @instance
             */
            JobDescription.prototype.dpi_y = 0;

            /**
             * JobDescription c_drops.
             * @member {number|Long} c_drops
             * @memberof appcore.ripe.JobDescription
             * @instance
             */
            JobDescription.prototype.c_drops = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * JobDescription m_drops.
             * @member {number|Long} m_drops
             * @memberof appcore.ripe.JobDescription
             * @instance
             */
            JobDescription.prototype.m_drops = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * JobDescription y_drops.
             * @member {number|Long} y_drops
             * @memberof appcore.ripe.JobDescription
             * @instance
             */
            JobDescription.prototype.y_drops = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * JobDescription k_drops.
             * @member {number|Long} k_drops
             * @memberof appcore.ripe.JobDescription
             * @instance
             */
            JobDescription.prototype.k_drops = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new JobDescription instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.JobDescription
             * @static
             * @param {appcore.ripe.IJobDescription=} [properties] Properties to set
             * @returns {appcore.ripe.JobDescription} JobDescription instance
             */
            JobDescription.create = function create(properties) {
                return new JobDescription(properties);
            };

            /**
             * Encodes the specified JobDescription message. Does not implicitly {@link appcore.ripe.JobDescription.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.JobDescription
             * @static
             * @param {appcore.ripe.IJobDescription} message JobDescription message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            JobDescription.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.job_length_dots != null && Object.hasOwnProperty.call(message, "job_length_dots"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.job_length_dots);
                if (message.job_length_mm != null && Object.hasOwnProperty.call(message, "job_length_mm"))
                    writer.uint32(/* id 2, wireType 1 =*/17).double(message.job_length_mm);
                if (message.job_length_inch != null && Object.hasOwnProperty.call(message, "job_length_inch"))
                    writer.uint32(/* id 3, wireType 1 =*/25).double(message.job_length_inch);
                if (message.job_name != null && Object.hasOwnProperty.call(message, "job_name"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.job_name);
                if (message.rip_version != null && Object.hasOwnProperty.call(message, "rip_version"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.rip_version);
                if (message.total_job_length_dots != null && Object.hasOwnProperty.call(message, "total_job_length_dots"))
                    writer.uint32(/* id 6, wireType 0 =*/48).uint64(message.total_job_length_dots);
                if (message.total_job_length_mm != null && Object.hasOwnProperty.call(message, "total_job_length_mm"))
                    writer.uint32(/* id 7, wireType 1 =*/57).double(message.total_job_length_mm);
                if (message.total_job_length_inch != null && Object.hasOwnProperty.call(message, "total_job_length_inch"))
                    writer.uint32(/* id 8, wireType 1 =*/65).double(message.total_job_length_inch);
                if (message.bottom_margin_page_dots != null && Object.hasOwnProperty.call(message, "bottom_margin_page_dots"))
                    writer.uint32(/* id 9, wireType 0 =*/72).uint64(message.bottom_margin_page_dots);
                if (message.bottom_margin_page_mm != null && Object.hasOwnProperty.call(message, "bottom_margin_page_mm"))
                    writer.uint32(/* id 10, wireType 1 =*/81).double(message.bottom_margin_page_mm);
                if (message.bottom_margin_page_inch != null && Object.hasOwnProperty.call(message, "bottom_margin_page_inch"))
                    writer.uint32(/* id 11, wireType 1 =*/89).double(message.bottom_margin_page_inch);
                if (message.bottom_margin_copy_dots != null && Object.hasOwnProperty.call(message, "bottom_margin_copy_dots"))
                    writer.uint32(/* id 12, wireType 0 =*/96).uint64(message.bottom_margin_copy_dots);
                if (message.bottom_margin_copy_mm != null && Object.hasOwnProperty.call(message, "bottom_margin_copy_mm"))
                    writer.uint32(/* id 13, wireType 1 =*/105).double(message.bottom_margin_copy_mm);
                if (message.bottom_margin_copy_inch != null && Object.hasOwnProperty.call(message, "bottom_margin_copy_inch"))
                    writer.uint32(/* id 14, wireType 1 =*/113).double(message.bottom_margin_copy_inch);
                if (message.copies != null && Object.hasOwnProperty.call(message, "copies"))
                    writer.uint32(/* id 15, wireType 0 =*/120).int32(message.copies);
                if (message.collate != null && Object.hasOwnProperty.call(message, "collate"))
                    writer.uint32(/* id 16, wireType 0 =*/128).bool(message.collate);
                if (message.page_length_dots != null && Object.hasOwnProperty.call(message, "page_length_dots"))
                    writer.uint32(/* id 17, wireType 0 =*/136).uint64(message.page_length_dots);
                if (message.page_length_mm != null && Object.hasOwnProperty.call(message, "page_length_mm"))
                    writer.uint32(/* id 18, wireType 1 =*/145).double(message.page_length_mm);
                if (message.page_length_inch != null && Object.hasOwnProperty.call(message, "page_length_inch"))
                    writer.uint32(/* id 19, wireType 1 =*/153).double(message.page_length_inch);
                if (message.pages != null && Object.hasOwnProperty.call(message, "pages"))
                    writer.uint32(/* id 20, wireType 0 =*/160).uint64(message.pages);
                if (message.dpi_x != null && Object.hasOwnProperty.call(message, "dpi_x"))
                    writer.uint32(/* id 21, wireType 0 =*/168).int32(message.dpi_x);
                if (message.dpi_y != null && Object.hasOwnProperty.call(message, "dpi_y"))
                    writer.uint32(/* id 22, wireType 0 =*/176).int32(message.dpi_y);
                if (message.c_drops != null && Object.hasOwnProperty.call(message, "c_drops"))
                    writer.uint32(/* id 23, wireType 0 =*/184).uint64(message.c_drops);
                if (message.m_drops != null && Object.hasOwnProperty.call(message, "m_drops"))
                    writer.uint32(/* id 24, wireType 0 =*/192).uint64(message.m_drops);
                if (message.y_drops != null && Object.hasOwnProperty.call(message, "y_drops"))
                    writer.uint32(/* id 25, wireType 0 =*/200).uint64(message.y_drops);
                if (message.k_drops != null && Object.hasOwnProperty.call(message, "k_drops"))
                    writer.uint32(/* id 26, wireType 0 =*/208).uint64(message.k_drops);
                return writer;
            };

            /**
             * Encodes the specified JobDescription message, length delimited. Does not implicitly {@link appcore.ripe.JobDescription.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.JobDescription
             * @static
             * @param {appcore.ripe.IJobDescription} message JobDescription message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            JobDescription.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a JobDescription message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.JobDescription
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.JobDescription} JobDescription
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            JobDescription.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.JobDescription();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.job_length_dots = reader.uint64();
                        break;
                    case 2:
                        message.job_length_mm = reader.double();
                        break;
                    case 3:
                        message.job_length_inch = reader.double();
                        break;
                    case 4:
                        message.job_name = reader.string();
                        break;
                    case 5:
                        message.rip_version = reader.string();
                        break;
                    case 6:
                        message.total_job_length_dots = reader.uint64();
                        break;
                    case 7:
                        message.total_job_length_mm = reader.double();
                        break;
                    case 8:
                        message.total_job_length_inch = reader.double();
                        break;
                    case 9:
                        message.bottom_margin_page_dots = reader.uint64();
                        break;
                    case 10:
                        message.bottom_margin_page_mm = reader.double();
                        break;
                    case 11:
                        message.bottom_margin_page_inch = reader.double();
                        break;
                    case 12:
                        message.bottom_margin_copy_dots = reader.uint64();
                        break;
                    case 13:
                        message.bottom_margin_copy_mm = reader.double();
                        break;
                    case 14:
                        message.bottom_margin_copy_inch = reader.double();
                        break;
                    case 15:
                        message.copies = reader.int32();
                        break;
                    case 16:
                        message.collate = reader.bool();
                        break;
                    case 17:
                        message.page_length_dots = reader.uint64();
                        break;
                    case 18:
                        message.page_length_mm = reader.double();
                        break;
                    case 19:
                        message.page_length_inch = reader.double();
                        break;
                    case 20:
                        message.pages = reader.uint64();
                        break;
                    case 21:
                        message.dpi_x = reader.int32();
                        break;
                    case 22:
                        message.dpi_y = reader.int32();
                        break;
                    case 23:
                        message.c_drops = reader.uint64();
                        break;
                    case 24:
                        message.m_drops = reader.uint64();
                        break;
                    case 25:
                        message.y_drops = reader.uint64();
                        break;
                    case 26:
                        message.k_drops = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a JobDescription message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.JobDescription
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.JobDescription} JobDescription
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            JobDescription.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a JobDescription message.
             * @function verify
             * @memberof appcore.ripe.JobDescription
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            JobDescription.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.job_length_dots != null && message.hasOwnProperty("job_length_dots"))
                    if (!$util.isInteger(message.job_length_dots) && !(message.job_length_dots && $util.isInteger(message.job_length_dots.low) && $util.isInteger(message.job_length_dots.high)))
                        return "job_length_dots: integer|Long expected";
                if (message.job_length_mm != null && message.hasOwnProperty("job_length_mm"))
                    if (typeof message.job_length_mm !== "number")
                        return "job_length_mm: number expected";
                if (message.job_length_inch != null && message.hasOwnProperty("job_length_inch"))
                    if (typeof message.job_length_inch !== "number")
                        return "job_length_inch: number expected";
                if (message.job_name != null && message.hasOwnProperty("job_name"))
                    if (!$util.isString(message.job_name))
                        return "job_name: string expected";
                if (message.rip_version != null && message.hasOwnProperty("rip_version"))
                    if (!$util.isString(message.rip_version))
                        return "rip_version: string expected";
                if (message.total_job_length_dots != null && message.hasOwnProperty("total_job_length_dots"))
                    if (!$util.isInteger(message.total_job_length_dots) && !(message.total_job_length_dots && $util.isInteger(message.total_job_length_dots.low) && $util.isInteger(message.total_job_length_dots.high)))
                        return "total_job_length_dots: integer|Long expected";
                if (message.total_job_length_mm != null && message.hasOwnProperty("total_job_length_mm"))
                    if (typeof message.total_job_length_mm !== "number")
                        return "total_job_length_mm: number expected";
                if (message.total_job_length_inch != null && message.hasOwnProperty("total_job_length_inch"))
                    if (typeof message.total_job_length_inch !== "number")
                        return "total_job_length_inch: number expected";
                if (message.bottom_margin_page_dots != null && message.hasOwnProperty("bottom_margin_page_dots"))
                    if (!$util.isInteger(message.bottom_margin_page_dots) && !(message.bottom_margin_page_dots && $util.isInteger(message.bottom_margin_page_dots.low) && $util.isInteger(message.bottom_margin_page_dots.high)))
                        return "bottom_margin_page_dots: integer|Long expected";
                if (message.bottom_margin_page_mm != null && message.hasOwnProperty("bottom_margin_page_mm"))
                    if (typeof message.bottom_margin_page_mm !== "number")
                        return "bottom_margin_page_mm: number expected";
                if (message.bottom_margin_page_inch != null && message.hasOwnProperty("bottom_margin_page_inch"))
                    if (typeof message.bottom_margin_page_inch !== "number")
                        return "bottom_margin_page_inch: number expected";
                if (message.bottom_margin_copy_dots != null && message.hasOwnProperty("bottom_margin_copy_dots"))
                    if (!$util.isInteger(message.bottom_margin_copy_dots) && !(message.bottom_margin_copy_dots && $util.isInteger(message.bottom_margin_copy_dots.low) && $util.isInteger(message.bottom_margin_copy_dots.high)))
                        return "bottom_margin_copy_dots: integer|Long expected";
                if (message.bottom_margin_copy_mm != null && message.hasOwnProperty("bottom_margin_copy_mm"))
                    if (typeof message.bottom_margin_copy_mm !== "number")
                        return "bottom_margin_copy_mm: number expected";
                if (message.bottom_margin_copy_inch != null && message.hasOwnProperty("bottom_margin_copy_inch"))
                    if (typeof message.bottom_margin_copy_inch !== "number")
                        return "bottom_margin_copy_inch: number expected";
                if (message.copies != null && message.hasOwnProperty("copies"))
                    if (!$util.isInteger(message.copies))
                        return "copies: integer expected";
                if (message.collate != null && message.hasOwnProperty("collate"))
                    if (typeof message.collate !== "boolean")
                        return "collate: boolean expected";
                if (message.page_length_dots != null && message.hasOwnProperty("page_length_dots"))
                    if (!$util.isInteger(message.page_length_dots) && !(message.page_length_dots && $util.isInteger(message.page_length_dots.low) && $util.isInteger(message.page_length_dots.high)))
                        return "page_length_dots: integer|Long expected";
                if (message.page_length_mm != null && message.hasOwnProperty("page_length_mm"))
                    if (typeof message.page_length_mm !== "number")
                        return "page_length_mm: number expected";
                if (message.page_length_inch != null && message.hasOwnProperty("page_length_inch"))
                    if (typeof message.page_length_inch !== "number")
                        return "page_length_inch: number expected";
                if (message.pages != null && message.hasOwnProperty("pages"))
                    if (!$util.isInteger(message.pages) && !(message.pages && $util.isInteger(message.pages.low) && $util.isInteger(message.pages.high)))
                        return "pages: integer|Long expected";
                if (message.dpi_x != null && message.hasOwnProperty("dpi_x"))
                    if (!$util.isInteger(message.dpi_x))
                        return "dpi_x: integer expected";
                if (message.dpi_y != null && message.hasOwnProperty("dpi_y"))
                    if (!$util.isInteger(message.dpi_y))
                        return "dpi_y: integer expected";
                if (message.c_drops != null && message.hasOwnProperty("c_drops"))
                    if (!$util.isInteger(message.c_drops) && !(message.c_drops && $util.isInteger(message.c_drops.low) && $util.isInteger(message.c_drops.high)))
                        return "c_drops: integer|Long expected";
                if (message.m_drops != null && message.hasOwnProperty("m_drops"))
                    if (!$util.isInteger(message.m_drops) && !(message.m_drops && $util.isInteger(message.m_drops.low) && $util.isInteger(message.m_drops.high)))
                        return "m_drops: integer|Long expected";
                if (message.y_drops != null && message.hasOwnProperty("y_drops"))
                    if (!$util.isInteger(message.y_drops) && !(message.y_drops && $util.isInteger(message.y_drops.low) && $util.isInteger(message.y_drops.high)))
                        return "y_drops: integer|Long expected";
                if (message.k_drops != null && message.hasOwnProperty("k_drops"))
                    if (!$util.isInteger(message.k_drops) && !(message.k_drops && $util.isInteger(message.k_drops.low) && $util.isInteger(message.k_drops.high)))
                        return "k_drops: integer|Long expected";
                return null;
            };

            /**
             * Creates a JobDescription message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.JobDescription
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.JobDescription} JobDescription
             */
            JobDescription.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.JobDescription)
                    return object;
                let message = new $root.appcore.ripe.JobDescription();
                if (object.job_length_dots != null)
                    if ($util.Long)
                        (message.job_length_dots = $util.Long.fromValue(object.job_length_dots)).unsigned = true;
                    else if (typeof object.job_length_dots === "string")
                        message.job_length_dots = parseInt(object.job_length_dots, 10);
                    else if (typeof object.job_length_dots === "number")
                        message.job_length_dots = object.job_length_dots;
                    else if (typeof object.job_length_dots === "object")
                        message.job_length_dots = new $util.LongBits(object.job_length_dots.low >>> 0, object.job_length_dots.high >>> 0).toNumber(true);
                if (object.job_length_mm != null)
                    message.job_length_mm = Number(object.job_length_mm);
                if (object.job_length_inch != null)
                    message.job_length_inch = Number(object.job_length_inch);
                if (object.job_name != null)
                    message.job_name = String(object.job_name);
                if (object.rip_version != null)
                    message.rip_version = String(object.rip_version);
                if (object.total_job_length_dots != null)
                    if ($util.Long)
                        (message.total_job_length_dots = $util.Long.fromValue(object.total_job_length_dots)).unsigned = true;
                    else if (typeof object.total_job_length_dots === "string")
                        message.total_job_length_dots = parseInt(object.total_job_length_dots, 10);
                    else if (typeof object.total_job_length_dots === "number")
                        message.total_job_length_dots = object.total_job_length_dots;
                    else if (typeof object.total_job_length_dots === "object")
                        message.total_job_length_dots = new $util.LongBits(object.total_job_length_dots.low >>> 0, object.total_job_length_dots.high >>> 0).toNumber(true);
                if (object.total_job_length_mm != null)
                    message.total_job_length_mm = Number(object.total_job_length_mm);
                if (object.total_job_length_inch != null)
                    message.total_job_length_inch = Number(object.total_job_length_inch);
                if (object.bottom_margin_page_dots != null)
                    if ($util.Long)
                        (message.bottom_margin_page_dots = $util.Long.fromValue(object.bottom_margin_page_dots)).unsigned = true;
                    else if (typeof object.bottom_margin_page_dots === "string")
                        message.bottom_margin_page_dots = parseInt(object.bottom_margin_page_dots, 10);
                    else if (typeof object.bottom_margin_page_dots === "number")
                        message.bottom_margin_page_dots = object.bottom_margin_page_dots;
                    else if (typeof object.bottom_margin_page_dots === "object")
                        message.bottom_margin_page_dots = new $util.LongBits(object.bottom_margin_page_dots.low >>> 0, object.bottom_margin_page_dots.high >>> 0).toNumber(true);
                if (object.bottom_margin_page_mm != null)
                    message.bottom_margin_page_mm = Number(object.bottom_margin_page_mm);
                if (object.bottom_margin_page_inch != null)
                    message.bottom_margin_page_inch = Number(object.bottom_margin_page_inch);
                if (object.bottom_margin_copy_dots != null)
                    if ($util.Long)
                        (message.bottom_margin_copy_dots = $util.Long.fromValue(object.bottom_margin_copy_dots)).unsigned = true;
                    else if (typeof object.bottom_margin_copy_dots === "string")
                        message.bottom_margin_copy_dots = parseInt(object.bottom_margin_copy_dots, 10);
                    else if (typeof object.bottom_margin_copy_dots === "number")
                        message.bottom_margin_copy_dots = object.bottom_margin_copy_dots;
                    else if (typeof object.bottom_margin_copy_dots === "object")
                        message.bottom_margin_copy_dots = new $util.LongBits(object.bottom_margin_copy_dots.low >>> 0, object.bottom_margin_copy_dots.high >>> 0).toNumber(true);
                if (object.bottom_margin_copy_mm != null)
                    message.bottom_margin_copy_mm = Number(object.bottom_margin_copy_mm);
                if (object.bottom_margin_copy_inch != null)
                    message.bottom_margin_copy_inch = Number(object.bottom_margin_copy_inch);
                if (object.copies != null)
                    message.copies = object.copies | 0;
                if (object.collate != null)
                    message.collate = Boolean(object.collate);
                if (object.page_length_dots != null)
                    if ($util.Long)
                        (message.page_length_dots = $util.Long.fromValue(object.page_length_dots)).unsigned = true;
                    else if (typeof object.page_length_dots === "string")
                        message.page_length_dots = parseInt(object.page_length_dots, 10);
                    else if (typeof object.page_length_dots === "number")
                        message.page_length_dots = object.page_length_dots;
                    else if (typeof object.page_length_dots === "object")
                        message.page_length_dots = new $util.LongBits(object.page_length_dots.low >>> 0, object.page_length_dots.high >>> 0).toNumber(true);
                if (object.page_length_mm != null)
                    message.page_length_mm = Number(object.page_length_mm);
                if (object.page_length_inch != null)
                    message.page_length_inch = Number(object.page_length_inch);
                if (object.pages != null)
                    if ($util.Long)
                        (message.pages = $util.Long.fromValue(object.pages)).unsigned = true;
                    else if (typeof object.pages === "string")
                        message.pages = parseInt(object.pages, 10);
                    else if (typeof object.pages === "number")
                        message.pages = object.pages;
                    else if (typeof object.pages === "object")
                        message.pages = new $util.LongBits(object.pages.low >>> 0, object.pages.high >>> 0).toNumber(true);
                if (object.dpi_x != null)
                    message.dpi_x = object.dpi_x | 0;
                if (object.dpi_y != null)
                    message.dpi_y = object.dpi_y | 0;
                if (object.c_drops != null)
                    if ($util.Long)
                        (message.c_drops = $util.Long.fromValue(object.c_drops)).unsigned = true;
                    else if (typeof object.c_drops === "string")
                        message.c_drops = parseInt(object.c_drops, 10);
                    else if (typeof object.c_drops === "number")
                        message.c_drops = object.c_drops;
                    else if (typeof object.c_drops === "object")
                        message.c_drops = new $util.LongBits(object.c_drops.low >>> 0, object.c_drops.high >>> 0).toNumber(true);
                if (object.m_drops != null)
                    if ($util.Long)
                        (message.m_drops = $util.Long.fromValue(object.m_drops)).unsigned = true;
                    else if (typeof object.m_drops === "string")
                        message.m_drops = parseInt(object.m_drops, 10);
                    else if (typeof object.m_drops === "number")
                        message.m_drops = object.m_drops;
                    else if (typeof object.m_drops === "object")
                        message.m_drops = new $util.LongBits(object.m_drops.low >>> 0, object.m_drops.high >>> 0).toNumber(true);
                if (object.y_drops != null)
                    if ($util.Long)
                        (message.y_drops = $util.Long.fromValue(object.y_drops)).unsigned = true;
                    else if (typeof object.y_drops === "string")
                        message.y_drops = parseInt(object.y_drops, 10);
                    else if (typeof object.y_drops === "number")
                        message.y_drops = object.y_drops;
                    else if (typeof object.y_drops === "object")
                        message.y_drops = new $util.LongBits(object.y_drops.low >>> 0, object.y_drops.high >>> 0).toNumber(true);
                if (object.k_drops != null)
                    if ($util.Long)
                        (message.k_drops = $util.Long.fromValue(object.k_drops)).unsigned = true;
                    else if (typeof object.k_drops === "string")
                        message.k_drops = parseInt(object.k_drops, 10);
                    else if (typeof object.k_drops === "number")
                        message.k_drops = object.k_drops;
                    else if (typeof object.k_drops === "object")
                        message.k_drops = new $util.LongBits(object.k_drops.low >>> 0, object.k_drops.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a JobDescription message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.JobDescription
             * @static
             * @param {appcore.ripe.JobDescription} message JobDescription
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            JobDescription.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.job_length_dots = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.job_length_dots = options.longs === String ? "0" : 0;
                    object.job_length_mm = 0;
                    object.job_length_inch = 0;
                    object.job_name = "";
                    object.rip_version = "";
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.total_job_length_dots = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.total_job_length_dots = options.longs === String ? "0" : 0;
                    object.total_job_length_mm = 0;
                    object.total_job_length_inch = 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.bottom_margin_page_dots = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.bottom_margin_page_dots = options.longs === String ? "0" : 0;
                    object.bottom_margin_page_mm = 0;
                    object.bottom_margin_page_inch = 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.bottom_margin_copy_dots = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.bottom_margin_copy_dots = options.longs === String ? "0" : 0;
                    object.bottom_margin_copy_mm = 0;
                    object.bottom_margin_copy_inch = 0;
                    object.copies = 0;
                    object.collate = false;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.page_length_dots = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.page_length_dots = options.longs === String ? "0" : 0;
                    object.page_length_mm = 0;
                    object.page_length_inch = 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.pages = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.pages = options.longs === String ? "0" : 0;
                    object.dpi_x = 0;
                    object.dpi_y = 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.c_drops = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.c_drops = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.m_drops = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.m_drops = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.y_drops = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.y_drops = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.k_drops = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.k_drops = options.longs === String ? "0" : 0;
                }
                if (message.job_length_dots != null && message.hasOwnProperty("job_length_dots"))
                    if (typeof message.job_length_dots === "number")
                        object.job_length_dots = options.longs === String ? String(message.job_length_dots) : message.job_length_dots;
                    else
                        object.job_length_dots = options.longs === String ? $util.Long.prototype.toString.call(message.job_length_dots) : options.longs === Number ? new $util.LongBits(message.job_length_dots.low >>> 0, message.job_length_dots.high >>> 0).toNumber(true) : message.job_length_dots;
                if (message.job_length_mm != null && message.hasOwnProperty("job_length_mm"))
                    object.job_length_mm = options.json && !isFinite(message.job_length_mm) ? String(message.job_length_mm) : message.job_length_mm;
                if (message.job_length_inch != null && message.hasOwnProperty("job_length_inch"))
                    object.job_length_inch = options.json && !isFinite(message.job_length_inch) ? String(message.job_length_inch) : message.job_length_inch;
                if (message.job_name != null && message.hasOwnProperty("job_name"))
                    object.job_name = message.job_name;
                if (message.rip_version != null && message.hasOwnProperty("rip_version"))
                    object.rip_version = message.rip_version;
                if (message.total_job_length_dots != null && message.hasOwnProperty("total_job_length_dots"))
                    if (typeof message.total_job_length_dots === "number")
                        object.total_job_length_dots = options.longs === String ? String(message.total_job_length_dots) : message.total_job_length_dots;
                    else
                        object.total_job_length_dots = options.longs === String ? $util.Long.prototype.toString.call(message.total_job_length_dots) : options.longs === Number ? new $util.LongBits(message.total_job_length_dots.low >>> 0, message.total_job_length_dots.high >>> 0).toNumber(true) : message.total_job_length_dots;
                if (message.total_job_length_mm != null && message.hasOwnProperty("total_job_length_mm"))
                    object.total_job_length_mm = options.json && !isFinite(message.total_job_length_mm) ? String(message.total_job_length_mm) : message.total_job_length_mm;
                if (message.total_job_length_inch != null && message.hasOwnProperty("total_job_length_inch"))
                    object.total_job_length_inch = options.json && !isFinite(message.total_job_length_inch) ? String(message.total_job_length_inch) : message.total_job_length_inch;
                if (message.bottom_margin_page_dots != null && message.hasOwnProperty("bottom_margin_page_dots"))
                    if (typeof message.bottom_margin_page_dots === "number")
                        object.bottom_margin_page_dots = options.longs === String ? String(message.bottom_margin_page_dots) : message.bottom_margin_page_dots;
                    else
                        object.bottom_margin_page_dots = options.longs === String ? $util.Long.prototype.toString.call(message.bottom_margin_page_dots) : options.longs === Number ? new $util.LongBits(message.bottom_margin_page_dots.low >>> 0, message.bottom_margin_page_dots.high >>> 0).toNumber(true) : message.bottom_margin_page_dots;
                if (message.bottom_margin_page_mm != null && message.hasOwnProperty("bottom_margin_page_mm"))
                    object.bottom_margin_page_mm = options.json && !isFinite(message.bottom_margin_page_mm) ? String(message.bottom_margin_page_mm) : message.bottom_margin_page_mm;
                if (message.bottom_margin_page_inch != null && message.hasOwnProperty("bottom_margin_page_inch"))
                    object.bottom_margin_page_inch = options.json && !isFinite(message.bottom_margin_page_inch) ? String(message.bottom_margin_page_inch) : message.bottom_margin_page_inch;
                if (message.bottom_margin_copy_dots != null && message.hasOwnProperty("bottom_margin_copy_dots"))
                    if (typeof message.bottom_margin_copy_dots === "number")
                        object.bottom_margin_copy_dots = options.longs === String ? String(message.bottom_margin_copy_dots) : message.bottom_margin_copy_dots;
                    else
                        object.bottom_margin_copy_dots = options.longs === String ? $util.Long.prototype.toString.call(message.bottom_margin_copy_dots) : options.longs === Number ? new $util.LongBits(message.bottom_margin_copy_dots.low >>> 0, message.bottom_margin_copy_dots.high >>> 0).toNumber(true) : message.bottom_margin_copy_dots;
                if (message.bottom_margin_copy_mm != null && message.hasOwnProperty("bottom_margin_copy_mm"))
                    object.bottom_margin_copy_mm = options.json && !isFinite(message.bottom_margin_copy_mm) ? String(message.bottom_margin_copy_mm) : message.bottom_margin_copy_mm;
                if (message.bottom_margin_copy_inch != null && message.hasOwnProperty("bottom_margin_copy_inch"))
                    object.bottom_margin_copy_inch = options.json && !isFinite(message.bottom_margin_copy_inch) ? String(message.bottom_margin_copy_inch) : message.bottom_margin_copy_inch;
                if (message.copies != null && message.hasOwnProperty("copies"))
                    object.copies = message.copies;
                if (message.collate != null && message.hasOwnProperty("collate"))
                    object.collate = message.collate;
                if (message.page_length_dots != null && message.hasOwnProperty("page_length_dots"))
                    if (typeof message.page_length_dots === "number")
                        object.page_length_dots = options.longs === String ? String(message.page_length_dots) : message.page_length_dots;
                    else
                        object.page_length_dots = options.longs === String ? $util.Long.prototype.toString.call(message.page_length_dots) : options.longs === Number ? new $util.LongBits(message.page_length_dots.low >>> 0, message.page_length_dots.high >>> 0).toNumber(true) : message.page_length_dots;
                if (message.page_length_mm != null && message.hasOwnProperty("page_length_mm"))
                    object.page_length_mm = options.json && !isFinite(message.page_length_mm) ? String(message.page_length_mm) : message.page_length_mm;
                if (message.page_length_inch != null && message.hasOwnProperty("page_length_inch"))
                    object.page_length_inch = options.json && !isFinite(message.page_length_inch) ? String(message.page_length_inch) : message.page_length_inch;
                if (message.pages != null && message.hasOwnProperty("pages"))
                    if (typeof message.pages === "number")
                        object.pages = options.longs === String ? String(message.pages) : message.pages;
                    else
                        object.pages = options.longs === String ? $util.Long.prototype.toString.call(message.pages) : options.longs === Number ? new $util.LongBits(message.pages.low >>> 0, message.pages.high >>> 0).toNumber(true) : message.pages;
                if (message.dpi_x != null && message.hasOwnProperty("dpi_x"))
                    object.dpi_x = message.dpi_x;
                if (message.dpi_y != null && message.hasOwnProperty("dpi_y"))
                    object.dpi_y = message.dpi_y;
                if (message.c_drops != null && message.hasOwnProperty("c_drops"))
                    if (typeof message.c_drops === "number")
                        object.c_drops = options.longs === String ? String(message.c_drops) : message.c_drops;
                    else
                        object.c_drops = options.longs === String ? $util.Long.prototype.toString.call(message.c_drops) : options.longs === Number ? new $util.LongBits(message.c_drops.low >>> 0, message.c_drops.high >>> 0).toNumber(true) : message.c_drops;
                if (message.m_drops != null && message.hasOwnProperty("m_drops"))
                    if (typeof message.m_drops === "number")
                        object.m_drops = options.longs === String ? String(message.m_drops) : message.m_drops;
                    else
                        object.m_drops = options.longs === String ? $util.Long.prototype.toString.call(message.m_drops) : options.longs === Number ? new $util.LongBits(message.m_drops.low >>> 0, message.m_drops.high >>> 0).toNumber(true) : message.m_drops;
                if (message.y_drops != null && message.hasOwnProperty("y_drops"))
                    if (typeof message.y_drops === "number")
                        object.y_drops = options.longs === String ? String(message.y_drops) : message.y_drops;
                    else
                        object.y_drops = options.longs === String ? $util.Long.prototype.toString.call(message.y_drops) : options.longs === Number ? new $util.LongBits(message.y_drops.low >>> 0, message.y_drops.high >>> 0).toNumber(true) : message.y_drops;
                if (message.k_drops != null && message.hasOwnProperty("k_drops"))
                    if (typeof message.k_drops === "number")
                        object.k_drops = options.longs === String ? String(message.k_drops) : message.k_drops;
                    else
                        object.k_drops = options.longs === String ? $util.Long.prototype.toString.call(message.k_drops) : options.longs === Number ? new $util.LongBits(message.k_drops.low >>> 0, message.k_drops.high >>> 0).toNumber(true) : message.k_drops;
                return object;
            };

            /**
             * Converts this JobDescription to JSON.
             * @function toJSON
             * @memberof appcore.ripe.JobDescription
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            JobDescription.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return JobDescription;
        })();

        ripe.Label = (function() {

            /**
             * Properties of a Label.
             * @memberof appcore.ripe
             * @interface ILabel
             * @property {number|Long|null} [id] Label id
             * @property {string|null} [name] Label name
             * @property {string|null} [color] Label color
             */

            /**
             * Constructs a new Label.
             * @memberof appcore.ripe
             * @classdesc Represents a Label.
             * @implements ILabel
             * @constructor
             * @param {appcore.ripe.ILabel=} [properties] Properties to set
             */
            function Label(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Label id.
             * @member {number|Long} id
             * @memberof appcore.ripe.Label
             * @instance
             */
            Label.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Label name.
             * @member {string} name
             * @memberof appcore.ripe.Label
             * @instance
             */
            Label.prototype.name = "";

            /**
             * Label color.
             * @member {string} color
             * @memberof appcore.ripe.Label
             * @instance
             */
            Label.prototype.color = "";

            /**
             * Creates a new Label instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.Label
             * @static
             * @param {appcore.ripe.ILabel=} [properties] Properties to set
             * @returns {appcore.ripe.Label} Label instance
             */
            Label.create = function create(properties) {
                return new Label(properties);
            };

            /**
             * Encodes the specified Label message. Does not implicitly {@link appcore.ripe.Label.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.Label
             * @static
             * @param {appcore.ripe.ILabel} message Label message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Label.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.id);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.color != null && Object.hasOwnProperty.call(message, "color"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.color);
                return writer;
            };

            /**
             * Encodes the specified Label message, length delimited. Does not implicitly {@link appcore.ripe.Label.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.Label
             * @static
             * @param {appcore.ripe.ILabel} message Label message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Label.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Label message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.Label
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.Label} Label
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Label.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.Label();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.uint64();
                        break;
                    case 2:
                        message.name = reader.string();
                        break;
                    case 3:
                        message.color = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Label message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.Label
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.Label} Label
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Label.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Label message.
             * @function verify
             * @memberof appcore.ripe.Label
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Label.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.color != null && message.hasOwnProperty("color"))
                    if (!$util.isString(message.color))
                        return "color: string expected";
                return null;
            };

            /**
             * Creates a Label message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.Label
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.Label} Label
             */
            Label.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.Label)
                    return object;
                let message = new $root.appcore.ripe.Label();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.color != null)
                    message.color = String(object.color);
                return message;
            };

            /**
             * Creates a plain object from a Label message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.Label
             * @static
             * @param {appcore.ripe.Label} message Label
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Label.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                    object.name = "";
                    object.color = "";
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.color != null && message.hasOwnProperty("color"))
                    object.color = message.color;
                return object;
            };

            /**
             * Converts this Label to JSON.
             * @function toJSON
             * @memberof appcore.ripe.Label
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Label.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Label;
        })();

        ripe.LabelOperation = (function() {

            /**
             * Properties of a LabelOperation.
             * @memberof appcore.ripe
             * @interface ILabelOperation
             * @property {appcore.ripe.LabelOperation.Operation|null} [op] LabelOperation op
             * @property {appcore.ripe.ILabel|null} [label] LabelOperation label
             * @property {string|null} [pdf_guid] LabelOperation pdf_guid
             */

            /**
             * Constructs a new LabelOperation.
             * @memberof appcore.ripe
             * @classdesc Represents a LabelOperation.
             * @implements ILabelOperation
             * @constructor
             * @param {appcore.ripe.ILabelOperation=} [properties] Properties to set
             */
            function LabelOperation(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * LabelOperation op.
             * @member {appcore.ripe.LabelOperation.Operation} op
             * @memberof appcore.ripe.LabelOperation
             * @instance
             */
            LabelOperation.prototype.op = 0;

            /**
             * LabelOperation label.
             * @member {appcore.ripe.ILabel|null|undefined} label
             * @memberof appcore.ripe.LabelOperation
             * @instance
             */
            LabelOperation.prototype.label = null;

            /**
             * LabelOperation pdf_guid.
             * @member {string} pdf_guid
             * @memberof appcore.ripe.LabelOperation
             * @instance
             */
            LabelOperation.prototype.pdf_guid = "";

            /**
             * Creates a new LabelOperation instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.LabelOperation
             * @static
             * @param {appcore.ripe.ILabelOperation=} [properties] Properties to set
             * @returns {appcore.ripe.LabelOperation} LabelOperation instance
             */
            LabelOperation.create = function create(properties) {
                return new LabelOperation(properties);
            };

            /**
             * Encodes the specified LabelOperation message. Does not implicitly {@link appcore.ripe.LabelOperation.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.LabelOperation
             * @static
             * @param {appcore.ripe.ILabelOperation} message LabelOperation message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LabelOperation.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.op != null && Object.hasOwnProperty.call(message, "op"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.op);
                if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                    $root.appcore.ripe.Label.encode(message.label, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.pdf_guid != null && Object.hasOwnProperty.call(message, "pdf_guid"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.pdf_guid);
                return writer;
            };

            /**
             * Encodes the specified LabelOperation message, length delimited. Does not implicitly {@link appcore.ripe.LabelOperation.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.LabelOperation
             * @static
             * @param {appcore.ripe.ILabelOperation} message LabelOperation message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LabelOperation.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a LabelOperation message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.LabelOperation
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.LabelOperation} LabelOperation
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LabelOperation.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.LabelOperation();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.op = reader.int32();
                        break;
                    case 2:
                        message.label = $root.appcore.ripe.Label.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.pdf_guid = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a LabelOperation message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.LabelOperation
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.LabelOperation} LabelOperation
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LabelOperation.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a LabelOperation message.
             * @function verify
             * @memberof appcore.ripe.LabelOperation
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            LabelOperation.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.op != null && message.hasOwnProperty("op"))
                    switch (message.op) {
                    default:
                        return "op: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        break;
                    }
                if (message.label != null && message.hasOwnProperty("label")) {
                    let error = $root.appcore.ripe.Label.verify(message.label);
                    if (error)
                        return "label." + error;
                }
                if (message.pdf_guid != null && message.hasOwnProperty("pdf_guid"))
                    if (!$util.isString(message.pdf_guid))
                        return "pdf_guid: string expected";
                return null;
            };

            /**
             * Creates a LabelOperation message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.LabelOperation
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.LabelOperation} LabelOperation
             */
            LabelOperation.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.LabelOperation)
                    return object;
                let message = new $root.appcore.ripe.LabelOperation();
                switch (object.op) {
                case "ADD_LABEL":
                case 0:
                    message.op = 0;
                    break;
                case "DELETE_LABEL":
                case 1:
                    message.op = 1;
                    break;
                case "EDIT_LABEL":
                case 2:
                    message.op = 2;
                    break;
                case "ADD_LABEL_TO_PDF":
                case 3:
                    message.op = 3;
                    break;
                case "DELETE_LABEL_FROM_PDF":
                case 4:
                    message.op = 4;
                    break;
                }
                if (object.label != null) {
                    if (typeof object.label !== "object")
                        throw TypeError(".appcore.ripe.LabelOperation.label: object expected");
                    message.label = $root.appcore.ripe.Label.fromObject(object.label);
                }
                if (object.pdf_guid != null)
                    message.pdf_guid = String(object.pdf_guid);
                return message;
            };

            /**
             * Creates a plain object from a LabelOperation message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.LabelOperation
             * @static
             * @param {appcore.ripe.LabelOperation} message LabelOperation
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LabelOperation.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.op = options.enums === String ? "ADD_LABEL" : 0;
                    object.label = null;
                    object.pdf_guid = "";
                }
                if (message.op != null && message.hasOwnProperty("op"))
                    object.op = options.enums === String ? $root.appcore.ripe.LabelOperation.Operation[message.op] : message.op;
                if (message.label != null && message.hasOwnProperty("label"))
                    object.label = $root.appcore.ripe.Label.toObject(message.label, options);
                if (message.pdf_guid != null && message.hasOwnProperty("pdf_guid"))
                    object.pdf_guid = message.pdf_guid;
                return object;
            };

            /**
             * Converts this LabelOperation to JSON.
             * @function toJSON
             * @memberof appcore.ripe.LabelOperation
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LabelOperation.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Operation enum.
             * @name appcore.ripe.LabelOperation.Operation
             * @enum {number}
             * @property {number} ADD_LABEL=0 ADD_LABEL value
             * @property {number} DELETE_LABEL=1 DELETE_LABEL value
             * @property {number} EDIT_LABEL=2 EDIT_LABEL value
             * @property {number} ADD_LABEL_TO_PDF=3 ADD_LABEL_TO_PDF value
             * @property {number} DELETE_LABEL_FROM_PDF=4 DELETE_LABEL_FROM_PDF value
             */
            LabelOperation.Operation = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "ADD_LABEL"] = 0;
                values[valuesById[1] = "DELETE_LABEL"] = 1;
                values[valuesById[2] = "EDIT_LABEL"] = 2;
                values[valuesById[3] = "ADD_LABEL_TO_PDF"] = 3;
                values[valuesById[4] = "DELETE_LABEL_FROM_PDF"] = 4;
                return values;
            })();

            return LabelOperation;
        })();

        ripe.Labels = (function() {

            /**
             * Properties of a Labels.
             * @memberof appcore.ripe
             * @interface ILabels
             * @property {Array.<appcore.ripe.ILabel>|null} [labels] Labels labels
             */

            /**
             * Constructs a new Labels.
             * @memberof appcore.ripe
             * @classdesc Represents a Labels.
             * @implements ILabels
             * @constructor
             * @param {appcore.ripe.ILabels=} [properties] Properties to set
             */
            function Labels(properties) {
                this.labels = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Labels labels.
             * @member {Array.<appcore.ripe.ILabel>} labels
             * @memberof appcore.ripe.Labels
             * @instance
             */
            Labels.prototype.labels = $util.emptyArray;

            /**
             * Creates a new Labels instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.Labels
             * @static
             * @param {appcore.ripe.ILabels=} [properties] Properties to set
             * @returns {appcore.ripe.Labels} Labels instance
             */
            Labels.create = function create(properties) {
                return new Labels(properties);
            };

            /**
             * Encodes the specified Labels message. Does not implicitly {@link appcore.ripe.Labels.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.Labels
             * @static
             * @param {appcore.ripe.ILabels} message Labels message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Labels.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.labels != null && message.labels.length)
                    for (let i = 0; i < message.labels.length; ++i)
                        $root.appcore.ripe.Label.encode(message.labels[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified Labels message, length delimited. Does not implicitly {@link appcore.ripe.Labels.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.Labels
             * @static
             * @param {appcore.ripe.ILabels} message Labels message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Labels.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Labels message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.Labels
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.Labels} Labels
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Labels.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.Labels();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.labels && message.labels.length))
                            message.labels = [];
                        message.labels.push($root.appcore.ripe.Label.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Labels message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.Labels
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.Labels} Labels
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Labels.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Labels message.
             * @function verify
             * @memberof appcore.ripe.Labels
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Labels.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.labels != null && message.hasOwnProperty("labels")) {
                    if (!Array.isArray(message.labels))
                        return "labels: array expected";
                    for (let i = 0; i < message.labels.length; ++i) {
                        let error = $root.appcore.ripe.Label.verify(message.labels[i]);
                        if (error)
                            return "labels." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a Labels message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.Labels
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.Labels} Labels
             */
            Labels.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.Labels)
                    return object;
                let message = new $root.appcore.ripe.Labels();
                if (object.labels) {
                    if (!Array.isArray(object.labels))
                        throw TypeError(".appcore.ripe.Labels.labels: array expected");
                    message.labels = [];
                    for (let i = 0; i < object.labels.length; ++i) {
                        if (typeof object.labels[i] !== "object")
                            throw TypeError(".appcore.ripe.Labels.labels: object expected");
                        message.labels[i] = $root.appcore.ripe.Label.fromObject(object.labels[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a Labels message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.Labels
             * @static
             * @param {appcore.ripe.Labels} message Labels
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Labels.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.labels = [];
                if (message.labels && message.labels.length) {
                    object.labels = [];
                    for (let j = 0; j < message.labels.length; ++j)
                        object.labels[j] = $root.appcore.ripe.Label.toObject(message.labels[j], options);
                }
                return object;
            };

            /**
             * Converts this Labels to JSON.
             * @function toJSON
             * @memberof appcore.ripe.Labels
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Labels.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Labels;
        })();

        ripe.License = (function() {

            /**
             * Properties of a License.
             * @memberof appcore.ripe
             * @interface ILicense
             * @property {string|null} [text] License text
             * @property {number|null} [version] License version
             * @property {string|null} [name] License name
             * @property {boolean|null} [accepted] License accepted
             */

            /**
             * Constructs a new License.
             * @memberof appcore.ripe
             * @classdesc Represents a License.
             * @implements ILicense
             * @constructor
             * @param {appcore.ripe.ILicense=} [properties] Properties to set
             */
            function License(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * License text.
             * @member {string} text
             * @memberof appcore.ripe.License
             * @instance
             */
            License.prototype.text = "";

            /**
             * License version.
             * @member {number} version
             * @memberof appcore.ripe.License
             * @instance
             */
            License.prototype.version = 0;

            /**
             * License name.
             * @member {string} name
             * @memberof appcore.ripe.License
             * @instance
             */
            License.prototype.name = "";

            /**
             * License accepted.
             * @member {boolean} accepted
             * @memberof appcore.ripe.License
             * @instance
             */
            License.prototype.accepted = false;

            /**
             * Creates a new License instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.License
             * @static
             * @param {appcore.ripe.ILicense=} [properties] Properties to set
             * @returns {appcore.ripe.License} License instance
             */
            License.create = function create(properties) {
                return new License(properties);
            };

            /**
             * Encodes the specified License message. Does not implicitly {@link appcore.ripe.License.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.License
             * @static
             * @param {appcore.ripe.ILicense} message License message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            License.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.text);
                if (message.version != null && Object.hasOwnProperty.call(message, "version"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.version);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
                if (message.accepted != null && Object.hasOwnProperty.call(message, "accepted"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.accepted);
                return writer;
            };

            /**
             * Encodes the specified License message, length delimited. Does not implicitly {@link appcore.ripe.License.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.License
             * @static
             * @param {appcore.ripe.ILicense} message License message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            License.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a License message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.License
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.License} License
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            License.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.License();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.text = reader.string();
                        break;
                    case 2:
                        message.version = reader.int32();
                        break;
                    case 3:
                        message.name = reader.string();
                        break;
                    case 4:
                        message.accepted = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a License message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.License
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.License} License
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            License.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a License message.
             * @function verify
             * @memberof appcore.ripe.License
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            License.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.text != null && message.hasOwnProperty("text"))
                    if (!$util.isString(message.text))
                        return "text: string expected";
                if (message.version != null && message.hasOwnProperty("version"))
                    if (!$util.isInteger(message.version))
                        return "version: integer expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.accepted != null && message.hasOwnProperty("accepted"))
                    if (typeof message.accepted !== "boolean")
                        return "accepted: boolean expected";
                return null;
            };

            /**
             * Creates a License message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.License
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.License} License
             */
            License.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.License)
                    return object;
                let message = new $root.appcore.ripe.License();
                if (object.text != null)
                    message.text = String(object.text);
                if (object.version != null)
                    message.version = object.version | 0;
                if (object.name != null)
                    message.name = String(object.name);
                if (object.accepted != null)
                    message.accepted = Boolean(object.accepted);
                return message;
            };

            /**
             * Creates a plain object from a License message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.License
             * @static
             * @param {appcore.ripe.License} message License
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            License.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.text = "";
                    object.version = 0;
                    object.name = "";
                    object.accepted = false;
                }
                if (message.text != null && message.hasOwnProperty("text"))
                    object.text = message.text;
                if (message.version != null && message.hasOwnProperty("version"))
                    object.version = message.version;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.accepted != null && message.hasOwnProperty("accepted"))
                    object.accepted = message.accepted;
                return object;
            };

            /**
             * Converts this License to JSON.
             * @function toJSON
             * @memberof appcore.ripe.License
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            License.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return License;
        })();

        ripe.LoginAsUser = (function() {

            /**
             * Properties of a LoginAsUser.
             * @memberof appcore.ripe
             * @interface ILoginAsUser
             * @property {number|null} [user_id] LoginAsUser user_id
             */

            /**
             * Constructs a new LoginAsUser.
             * @memberof appcore.ripe
             * @classdesc Represents a LoginAsUser.
             * @implements ILoginAsUser
             * @constructor
             * @param {appcore.ripe.ILoginAsUser=} [properties] Properties to set
             */
            function LoginAsUser(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * LoginAsUser user_id.
             * @member {number} user_id
             * @memberof appcore.ripe.LoginAsUser
             * @instance
             */
            LoginAsUser.prototype.user_id = 0;

            /**
             * Creates a new LoginAsUser instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.LoginAsUser
             * @static
             * @param {appcore.ripe.ILoginAsUser=} [properties] Properties to set
             * @returns {appcore.ripe.LoginAsUser} LoginAsUser instance
             */
            LoginAsUser.create = function create(properties) {
                return new LoginAsUser(properties);
            };

            /**
             * Encodes the specified LoginAsUser message. Does not implicitly {@link appcore.ripe.LoginAsUser.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.LoginAsUser
             * @static
             * @param {appcore.ripe.ILoginAsUser} message LoginAsUser message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LoginAsUser.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.user_id != null && Object.hasOwnProperty.call(message, "user_id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.user_id);
                return writer;
            };

            /**
             * Encodes the specified LoginAsUser message, length delimited. Does not implicitly {@link appcore.ripe.LoginAsUser.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.LoginAsUser
             * @static
             * @param {appcore.ripe.ILoginAsUser} message LoginAsUser message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LoginAsUser.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a LoginAsUser message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.LoginAsUser
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.LoginAsUser} LoginAsUser
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LoginAsUser.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.LoginAsUser();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.user_id = reader.uint32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a LoginAsUser message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.LoginAsUser
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.LoginAsUser} LoginAsUser
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LoginAsUser.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a LoginAsUser message.
             * @function verify
             * @memberof appcore.ripe.LoginAsUser
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            LoginAsUser.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.user_id != null && message.hasOwnProperty("user_id"))
                    if (!$util.isInteger(message.user_id))
                        return "user_id: integer expected";
                return null;
            };

            /**
             * Creates a LoginAsUser message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.LoginAsUser
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.LoginAsUser} LoginAsUser
             */
            LoginAsUser.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.LoginAsUser)
                    return object;
                let message = new $root.appcore.ripe.LoginAsUser();
                if (object.user_id != null)
                    message.user_id = object.user_id >>> 0;
                return message;
            };

            /**
             * Creates a plain object from a LoginAsUser message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.LoginAsUser
             * @static
             * @param {appcore.ripe.LoginAsUser} message LoginAsUser
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LoginAsUser.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.user_id = 0;
                if (message.user_id != null && message.hasOwnProperty("user_id"))
                    object.user_id = message.user_id;
                return object;
            };

            /**
             * Converts this LoginAsUser to JSON.
             * @function toJSON
             * @memberof appcore.ripe.LoginAsUser
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LoginAsUser.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return LoginAsUser;
        })();

        ripe.MapOEMLabel = (function() {

            /**
             * Properties of a MapOEMLabel.
             * @memberof appcore.ripe
             * @interface IMapOEMLabel
             * @property {Object.<string,string>|null} [mapping] MapOEMLabel mapping
             */

            /**
             * Constructs a new MapOEMLabel.
             * @memberof appcore.ripe
             * @classdesc Represents a MapOEMLabel.
             * @implements IMapOEMLabel
             * @constructor
             * @param {appcore.ripe.IMapOEMLabel=} [properties] Properties to set
             */
            function MapOEMLabel(properties) {
                this.mapping = {};
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MapOEMLabel mapping.
             * @member {Object.<string,string>} mapping
             * @memberof appcore.ripe.MapOEMLabel
             * @instance
             */
            MapOEMLabel.prototype.mapping = $util.emptyObject;

            /**
             * Creates a new MapOEMLabel instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.MapOEMLabel
             * @static
             * @param {appcore.ripe.IMapOEMLabel=} [properties] Properties to set
             * @returns {appcore.ripe.MapOEMLabel} MapOEMLabel instance
             */
            MapOEMLabel.create = function create(properties) {
                return new MapOEMLabel(properties);
            };

            /**
             * Encodes the specified MapOEMLabel message. Does not implicitly {@link appcore.ripe.MapOEMLabel.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.MapOEMLabel
             * @static
             * @param {appcore.ripe.IMapOEMLabel} message MapOEMLabel message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MapOEMLabel.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.mapping != null && Object.hasOwnProperty.call(message, "mapping"))
                    for (let keys = Object.keys(message.mapping), i = 0; i < keys.length; ++i)
                        writer.uint32(/* id 1, wireType 2 =*/10).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.mapping[keys[i]]).ldelim();
                return writer;
            };

            /**
             * Encodes the specified MapOEMLabel message, length delimited. Does not implicitly {@link appcore.ripe.MapOEMLabel.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.MapOEMLabel
             * @static
             * @param {appcore.ripe.IMapOEMLabel} message MapOEMLabel message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MapOEMLabel.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a MapOEMLabel message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.MapOEMLabel
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.MapOEMLabel} MapOEMLabel
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MapOEMLabel.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.MapOEMLabel(), key, value;
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (message.mapping === $util.emptyObject)
                            message.mapping = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = "";
                        value = "";
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.string();
                                break;
                            case 2:
                                value = reader.string();
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.mapping[key] = value;
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a MapOEMLabel message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.MapOEMLabel
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.MapOEMLabel} MapOEMLabel
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MapOEMLabel.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a MapOEMLabel message.
             * @function verify
             * @memberof appcore.ripe.MapOEMLabel
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MapOEMLabel.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.mapping != null && message.hasOwnProperty("mapping")) {
                    if (!$util.isObject(message.mapping))
                        return "mapping: object expected";
                    let key = Object.keys(message.mapping);
                    for (let i = 0; i < key.length; ++i)
                        if (!$util.isString(message.mapping[key[i]]))
                            return "mapping: string{k:string} expected";
                }
                return null;
            };

            /**
             * Creates a MapOEMLabel message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.MapOEMLabel
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.MapOEMLabel} MapOEMLabel
             */
            MapOEMLabel.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.MapOEMLabel)
                    return object;
                let message = new $root.appcore.ripe.MapOEMLabel();
                if (object.mapping) {
                    if (typeof object.mapping !== "object")
                        throw TypeError(".appcore.ripe.MapOEMLabel.mapping: object expected");
                    message.mapping = {};
                    for (let keys = Object.keys(object.mapping), i = 0; i < keys.length; ++i)
                        message.mapping[keys[i]] = String(object.mapping[keys[i]]);
                }
                return message;
            };

            /**
             * Creates a plain object from a MapOEMLabel message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.MapOEMLabel
             * @static
             * @param {appcore.ripe.MapOEMLabel} message MapOEMLabel
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MapOEMLabel.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.objects || options.defaults)
                    object.mapping = {};
                let keys2;
                if (message.mapping && (keys2 = Object.keys(message.mapping)).length) {
                    object.mapping = {};
                    for (let j = 0; j < keys2.length; ++j)
                        object.mapping[keys2[j]] = message.mapping[keys2[j]];
                }
                return object;
            };

            /**
             * Converts this MapOEMLabel to JSON.
             * @function toJSON
             * @memberof appcore.ripe.MapOEMLabel
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MapOEMLabel.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return MapOEMLabel;
        })();

        ripe.MessageJobDescription = (function() {

            /**
             * Properties of a MessageJobDescription.
             * @memberof appcore.ripe
             * @interface IMessageJobDescription
             * @property {appcore.ripe.IJobDescription|null} [job_description] MessageJobDescription job_description
             * @property {appcore.ripe.ISliceDescription|null} [slice_description] MessageJobDescription slice_description
             * @property {string|null} [job_id] MessageJobDescription job_id
             * @property {string|null} [job_uri] MessageJobDescription job_uri
             */

            /**
             * Constructs a new MessageJobDescription.
             * @memberof appcore.ripe
             * @classdesc Represents a MessageJobDescription.
             * @implements IMessageJobDescription
             * @constructor
             * @param {appcore.ripe.IMessageJobDescription=} [properties] Properties to set
             */
            function MessageJobDescription(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MessageJobDescription job_description.
             * @member {appcore.ripe.IJobDescription|null|undefined} job_description
             * @memberof appcore.ripe.MessageJobDescription
             * @instance
             */
            MessageJobDescription.prototype.job_description = null;

            /**
             * MessageJobDescription slice_description.
             * @member {appcore.ripe.ISliceDescription|null|undefined} slice_description
             * @memberof appcore.ripe.MessageJobDescription
             * @instance
             */
            MessageJobDescription.prototype.slice_description = null;

            /**
             * MessageJobDescription job_id.
             * @member {string} job_id
             * @memberof appcore.ripe.MessageJobDescription
             * @instance
             */
            MessageJobDescription.prototype.job_id = "";

            /**
             * MessageJobDescription job_uri.
             * @member {string} job_uri
             * @memberof appcore.ripe.MessageJobDescription
             * @instance
             */
            MessageJobDescription.prototype.job_uri = "";

            /**
             * Creates a new MessageJobDescription instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.MessageJobDescription
             * @static
             * @param {appcore.ripe.IMessageJobDescription=} [properties] Properties to set
             * @returns {appcore.ripe.MessageJobDescription} MessageJobDescription instance
             */
            MessageJobDescription.create = function create(properties) {
                return new MessageJobDescription(properties);
            };

            /**
             * Encodes the specified MessageJobDescription message. Does not implicitly {@link appcore.ripe.MessageJobDescription.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.MessageJobDescription
             * @static
             * @param {appcore.ripe.IMessageJobDescription} message MessageJobDescription message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MessageJobDescription.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.job_description != null && Object.hasOwnProperty.call(message, "job_description"))
                    $root.appcore.ripe.JobDescription.encode(message.job_description, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.slice_description != null && Object.hasOwnProperty.call(message, "slice_description"))
                    $root.appcore.ripe.SliceDescription.encode(message.slice_description, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.job_id != null && Object.hasOwnProperty.call(message, "job_id"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.job_id);
                if (message.job_uri != null && Object.hasOwnProperty.call(message, "job_uri"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.job_uri);
                return writer;
            };

            /**
             * Encodes the specified MessageJobDescription message, length delimited. Does not implicitly {@link appcore.ripe.MessageJobDescription.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.MessageJobDescription
             * @static
             * @param {appcore.ripe.IMessageJobDescription} message MessageJobDescription message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MessageJobDescription.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a MessageJobDescription message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.MessageJobDescription
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.MessageJobDescription} MessageJobDescription
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MessageJobDescription.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.MessageJobDescription();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.job_description = $root.appcore.ripe.JobDescription.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.slice_description = $root.appcore.ripe.SliceDescription.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.job_id = reader.string();
                        break;
                    case 4:
                        message.job_uri = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a MessageJobDescription message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.MessageJobDescription
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.MessageJobDescription} MessageJobDescription
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MessageJobDescription.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a MessageJobDescription message.
             * @function verify
             * @memberof appcore.ripe.MessageJobDescription
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MessageJobDescription.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.job_description != null && message.hasOwnProperty("job_description")) {
                    let error = $root.appcore.ripe.JobDescription.verify(message.job_description);
                    if (error)
                        return "job_description." + error;
                }
                if (message.slice_description != null && message.hasOwnProperty("slice_description")) {
                    let error = $root.appcore.ripe.SliceDescription.verify(message.slice_description);
                    if (error)
                        return "slice_description." + error;
                }
                if (message.job_id != null && message.hasOwnProperty("job_id"))
                    if (!$util.isString(message.job_id))
                        return "job_id: string expected";
                if (message.job_uri != null && message.hasOwnProperty("job_uri"))
                    if (!$util.isString(message.job_uri))
                        return "job_uri: string expected";
                return null;
            };

            /**
             * Creates a MessageJobDescription message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.MessageJobDescription
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.MessageJobDescription} MessageJobDescription
             */
            MessageJobDescription.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.MessageJobDescription)
                    return object;
                let message = new $root.appcore.ripe.MessageJobDescription();
                if (object.job_description != null) {
                    if (typeof object.job_description !== "object")
                        throw TypeError(".appcore.ripe.MessageJobDescription.job_description: object expected");
                    message.job_description = $root.appcore.ripe.JobDescription.fromObject(object.job_description);
                }
                if (object.slice_description != null) {
                    if (typeof object.slice_description !== "object")
                        throw TypeError(".appcore.ripe.MessageJobDescription.slice_description: object expected");
                    message.slice_description = $root.appcore.ripe.SliceDescription.fromObject(object.slice_description);
                }
                if (object.job_id != null)
                    message.job_id = String(object.job_id);
                if (object.job_uri != null)
                    message.job_uri = String(object.job_uri);
                return message;
            };

            /**
             * Creates a plain object from a MessageJobDescription message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.MessageJobDescription
             * @static
             * @param {appcore.ripe.MessageJobDescription} message MessageJobDescription
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MessageJobDescription.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.job_description = null;
                    object.slice_description = null;
                    object.job_id = "";
                    object.job_uri = "";
                }
                if (message.job_description != null && message.hasOwnProperty("job_description"))
                    object.job_description = $root.appcore.ripe.JobDescription.toObject(message.job_description, options);
                if (message.slice_description != null && message.hasOwnProperty("slice_description"))
                    object.slice_description = $root.appcore.ripe.SliceDescription.toObject(message.slice_description, options);
                if (message.job_id != null && message.hasOwnProperty("job_id"))
                    object.job_id = message.job_id;
                if (message.job_uri != null && message.hasOwnProperty("job_uri"))
                    object.job_uri = message.job_uri;
                return object;
            };

            /**
             * Converts this MessageJobDescription to JSON.
             * @function toJSON
             * @memberof appcore.ripe.MessageJobDescription
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MessageJobDescription.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return MessageJobDescription;
        })();

        ripe.SliceDescription = (function() {

            /**
             * Properties of a SliceDescription.
             * @memberof appcore.ripe
             * @interface ISliceDescription
             * @property {number|null} [engine_stage] SliceDescription engine_stage
             * @property {number|null} [strip_index] SliceDescription strip_index
             */

            /**
             * Constructs a new SliceDescription.
             * @memberof appcore.ripe
             * @classdesc Represents a SliceDescription.
             * @implements ISliceDescription
             * @constructor
             * @param {appcore.ripe.ISliceDescription=} [properties] Properties to set
             */
            function SliceDescription(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SliceDescription engine_stage.
             * @member {number} engine_stage
             * @memberof appcore.ripe.SliceDescription
             * @instance
             */
            SliceDescription.prototype.engine_stage = 0;

            /**
             * SliceDescription strip_index.
             * @member {number} strip_index
             * @memberof appcore.ripe.SliceDescription
             * @instance
             */
            SliceDescription.prototype.strip_index = 0;

            /**
             * Creates a new SliceDescription instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.SliceDescription
             * @static
             * @param {appcore.ripe.ISliceDescription=} [properties] Properties to set
             * @returns {appcore.ripe.SliceDescription} SliceDescription instance
             */
            SliceDescription.create = function create(properties) {
                return new SliceDescription(properties);
            };

            /**
             * Encodes the specified SliceDescription message. Does not implicitly {@link appcore.ripe.SliceDescription.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.SliceDescription
             * @static
             * @param {appcore.ripe.ISliceDescription} message SliceDescription message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SliceDescription.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.engine_stage != null && Object.hasOwnProperty.call(message, "engine_stage"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.engine_stage);
                if (message.strip_index != null && Object.hasOwnProperty.call(message, "strip_index"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.strip_index);
                return writer;
            };

            /**
             * Encodes the specified SliceDescription message, length delimited. Does not implicitly {@link appcore.ripe.SliceDescription.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.SliceDescription
             * @static
             * @param {appcore.ripe.ISliceDescription} message SliceDescription message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SliceDescription.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SliceDescription message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.SliceDescription
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.SliceDescription} SliceDescription
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SliceDescription.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.SliceDescription();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.engine_stage = reader.uint32();
                        break;
                    case 2:
                        message.strip_index = reader.uint32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SliceDescription message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.SliceDescription
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.SliceDescription} SliceDescription
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SliceDescription.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SliceDescription message.
             * @function verify
             * @memberof appcore.ripe.SliceDescription
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SliceDescription.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.engine_stage != null && message.hasOwnProperty("engine_stage"))
                    if (!$util.isInteger(message.engine_stage))
                        return "engine_stage: integer expected";
                if (message.strip_index != null && message.hasOwnProperty("strip_index"))
                    if (!$util.isInteger(message.strip_index))
                        return "strip_index: integer expected";
                return null;
            };

            /**
             * Creates a SliceDescription message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.SliceDescription
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.SliceDescription} SliceDescription
             */
            SliceDescription.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.SliceDescription)
                    return object;
                let message = new $root.appcore.ripe.SliceDescription();
                if (object.engine_stage != null)
                    message.engine_stage = object.engine_stage >>> 0;
                if (object.strip_index != null)
                    message.strip_index = object.strip_index >>> 0;
                return message;
            };

            /**
             * Creates a plain object from a SliceDescription message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.SliceDescription
             * @static
             * @param {appcore.ripe.SliceDescription} message SliceDescription
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SliceDescription.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.engine_stage = 0;
                    object.strip_index = 0;
                }
                if (message.engine_stage != null && message.hasOwnProperty("engine_stage"))
                    object.engine_stage = message.engine_stage;
                if (message.strip_index != null && message.hasOwnProperty("strip_index"))
                    object.strip_index = message.strip_index;
                return object;
            };

            /**
             * Converts this SliceDescription to JSON.
             * @function toJSON
             * @memberof appcore.ripe.SliceDescription
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SliceDescription.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return SliceDescription;
        })();

        ripe.PDFList = (function() {

            /**
             * Properties of a PDFList.
             * @memberof appcore.ripe
             * @interface IPDFList
             * @property {Array.<appcore.ripe.ISourcePDF>|null} [source_pdfs] PDFList source_pdfs
             */

            /**
             * Constructs a new PDFList.
             * @memberof appcore.ripe
             * @classdesc Represents a PDFList.
             * @implements IPDFList
             * @constructor
             * @param {appcore.ripe.IPDFList=} [properties] Properties to set
             */
            function PDFList(properties) {
                this.source_pdfs = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PDFList source_pdfs.
             * @member {Array.<appcore.ripe.ISourcePDF>} source_pdfs
             * @memberof appcore.ripe.PDFList
             * @instance
             */
            PDFList.prototype.source_pdfs = $util.emptyArray;

            /**
             * Creates a new PDFList instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.PDFList
             * @static
             * @param {appcore.ripe.IPDFList=} [properties] Properties to set
             * @returns {appcore.ripe.PDFList} PDFList instance
             */
            PDFList.create = function create(properties) {
                return new PDFList(properties);
            };

            /**
             * Encodes the specified PDFList message. Does not implicitly {@link appcore.ripe.PDFList.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.PDFList
             * @static
             * @param {appcore.ripe.IPDFList} message PDFList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PDFList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.source_pdfs != null && message.source_pdfs.length)
                    for (let i = 0; i < message.source_pdfs.length; ++i)
                        $root.appcore.ripe.SourcePDF.encode(message.source_pdfs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified PDFList message, length delimited. Does not implicitly {@link appcore.ripe.PDFList.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.PDFList
             * @static
             * @param {appcore.ripe.IPDFList} message PDFList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PDFList.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PDFList message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.PDFList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.PDFList} PDFList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PDFList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.PDFList();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.source_pdfs && message.source_pdfs.length))
                            message.source_pdfs = [];
                        message.source_pdfs.push($root.appcore.ripe.SourcePDF.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PDFList message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.PDFList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.PDFList} PDFList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PDFList.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PDFList message.
             * @function verify
             * @memberof appcore.ripe.PDFList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PDFList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.source_pdfs != null && message.hasOwnProperty("source_pdfs")) {
                    if (!Array.isArray(message.source_pdfs))
                        return "source_pdfs: array expected";
                    for (let i = 0; i < message.source_pdfs.length; ++i) {
                        let error = $root.appcore.ripe.SourcePDF.verify(message.source_pdfs[i]);
                        if (error)
                            return "source_pdfs." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a PDFList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.PDFList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.PDFList} PDFList
             */
            PDFList.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.PDFList)
                    return object;
                let message = new $root.appcore.ripe.PDFList();
                if (object.source_pdfs) {
                    if (!Array.isArray(object.source_pdfs))
                        throw TypeError(".appcore.ripe.PDFList.source_pdfs: array expected");
                    message.source_pdfs = [];
                    for (let i = 0; i < object.source_pdfs.length; ++i) {
                        if (typeof object.source_pdfs[i] !== "object")
                            throw TypeError(".appcore.ripe.PDFList.source_pdfs: object expected");
                        message.source_pdfs[i] = $root.appcore.ripe.SourcePDF.fromObject(object.source_pdfs[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a PDFList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.PDFList
             * @static
             * @param {appcore.ripe.PDFList} message PDFList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PDFList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.source_pdfs = [];
                if (message.source_pdfs && message.source_pdfs.length) {
                    object.source_pdfs = [];
                    for (let j = 0; j < message.source_pdfs.length; ++j)
                        object.source_pdfs[j] = $root.appcore.ripe.SourcePDF.toObject(message.source_pdfs[j], options);
                }
                return object;
            };

            /**
             * Converts this PDFList to JSON.
             * @function toJSON
             * @memberof appcore.ripe.PDFList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PDFList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return PDFList;
        })();

        ripe.SourcePDF = (function() {

            /**
             * Properties of a SourcePDF.
             * @memberof appcore.ripe
             * @interface ISourcePDF
             * @property {string|null} [guid] SourcePDF guid
             * @property {string|null} [filename] SourcePDF filename
             * @property {number|null} [width_mm] SourcePDF width_mm
             * @property {number|null} [height_mm] SourcePDF height_mm
             * @property {Object.<string,appcore.ripe.ISplitPDFResult>|null} [split_pdf_results] SourcePDF split_pdf_results
             * @property {boolean|null} [pdf_a1b_compliant] SourcePDF pdf_a1b_compliant
             * @property {number|null} [num_of_pages] SourcePDF num_of_pages
             * @property {Array.<number|Long>|null} [id_labels] SourcePDF id_labels
             * @property {string|null} [uploaded_at] SourcePDF uploaded_at
             * @property {string|null} [configs_updated_at] SourcePDF configs_updated_at
             */

            /**
             * Constructs a new SourcePDF.
             * @memberof appcore.ripe
             * @classdesc Represents a SourcePDF.
             * @implements ISourcePDF
             * @constructor
             * @param {appcore.ripe.ISourcePDF=} [properties] Properties to set
             */
            function SourcePDF(properties) {
                this.split_pdf_results = {};
                this.id_labels = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SourcePDF guid.
             * @member {string} guid
             * @memberof appcore.ripe.SourcePDF
             * @instance
             */
            SourcePDF.prototype.guid = "";

            /**
             * SourcePDF filename.
             * @member {string} filename
             * @memberof appcore.ripe.SourcePDF
             * @instance
             */
            SourcePDF.prototype.filename = "";

            /**
             * SourcePDF width_mm.
             * @member {number} width_mm
             * @memberof appcore.ripe.SourcePDF
             * @instance
             */
            SourcePDF.prototype.width_mm = 0;

            /**
             * SourcePDF height_mm.
             * @member {number} height_mm
             * @memberof appcore.ripe.SourcePDF
             * @instance
             */
            SourcePDF.prototype.height_mm = 0;

            /**
             * SourcePDF split_pdf_results.
             * @member {Object.<string,appcore.ripe.ISplitPDFResult>} split_pdf_results
             * @memberof appcore.ripe.SourcePDF
             * @instance
             */
            SourcePDF.prototype.split_pdf_results = $util.emptyObject;

            /**
             * SourcePDF pdf_a1b_compliant.
             * @member {boolean} pdf_a1b_compliant
             * @memberof appcore.ripe.SourcePDF
             * @instance
             */
            SourcePDF.prototype.pdf_a1b_compliant = false;

            /**
             * SourcePDF num_of_pages.
             * @member {number} num_of_pages
             * @memberof appcore.ripe.SourcePDF
             * @instance
             */
            SourcePDF.prototype.num_of_pages = 0;

            /**
             * SourcePDF id_labels.
             * @member {Array.<number|Long>} id_labels
             * @memberof appcore.ripe.SourcePDF
             * @instance
             */
            SourcePDF.prototype.id_labels = $util.emptyArray;

            /**
             * SourcePDF uploaded_at.
             * @member {string} uploaded_at
             * @memberof appcore.ripe.SourcePDF
             * @instance
             */
            SourcePDF.prototype.uploaded_at = "";

            /**
             * SourcePDF configs_updated_at.
             * @member {string} configs_updated_at
             * @memberof appcore.ripe.SourcePDF
             * @instance
             */
            SourcePDF.prototype.configs_updated_at = "";

            /**
             * Creates a new SourcePDF instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.SourcePDF
             * @static
             * @param {appcore.ripe.ISourcePDF=} [properties] Properties to set
             * @returns {appcore.ripe.SourcePDF} SourcePDF instance
             */
            SourcePDF.create = function create(properties) {
                return new SourcePDF(properties);
            };

            /**
             * Encodes the specified SourcePDF message. Does not implicitly {@link appcore.ripe.SourcePDF.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.SourcePDF
             * @static
             * @param {appcore.ripe.ISourcePDF} message SourcePDF message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SourcePDF.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.guid != null && Object.hasOwnProperty.call(message, "guid"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.guid);
                if (message.filename != null && Object.hasOwnProperty.call(message, "filename"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.filename);
                if (message.width_mm != null && Object.hasOwnProperty.call(message, "width_mm"))
                    writer.uint32(/* id 3, wireType 5 =*/29).float(message.width_mm);
                if (message.height_mm != null && Object.hasOwnProperty.call(message, "height_mm"))
                    writer.uint32(/* id 4, wireType 5 =*/37).float(message.height_mm);
                if (message.split_pdf_results != null && Object.hasOwnProperty.call(message, "split_pdf_results"))
                    for (let keys = Object.keys(message.split_pdf_results), i = 0; i < keys.length; ++i) {
                        writer.uint32(/* id 5, wireType 2 =*/42).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                        $root.appcore.ripe.SplitPDFResult.encode(message.split_pdf_results[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                    }
                if (message.pdf_a1b_compliant != null && Object.hasOwnProperty.call(message, "pdf_a1b_compliant"))
                    writer.uint32(/* id 6, wireType 0 =*/48).bool(message.pdf_a1b_compliant);
                if (message.num_of_pages != null && Object.hasOwnProperty.call(message, "num_of_pages"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.num_of_pages);
                if (message.id_labels != null && message.id_labels.length) {
                    writer.uint32(/* id 8, wireType 2 =*/66).fork();
                    for (let i = 0; i < message.id_labels.length; ++i)
                        writer.uint64(message.id_labels[i]);
                    writer.ldelim();
                }
                if (message.uploaded_at != null && Object.hasOwnProperty.call(message, "uploaded_at"))
                    writer.uint32(/* id 9, wireType 2 =*/74).string(message.uploaded_at);
                if (message.configs_updated_at != null && Object.hasOwnProperty.call(message, "configs_updated_at"))
                    writer.uint32(/* id 10, wireType 2 =*/82).string(message.configs_updated_at);
                return writer;
            };

            /**
             * Encodes the specified SourcePDF message, length delimited. Does not implicitly {@link appcore.ripe.SourcePDF.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.SourcePDF
             * @static
             * @param {appcore.ripe.ISourcePDF} message SourcePDF message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SourcePDF.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SourcePDF message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.SourcePDF
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.SourcePDF} SourcePDF
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SourcePDF.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.SourcePDF(), key, value;
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.guid = reader.string();
                        break;
                    case 2:
                        message.filename = reader.string();
                        break;
                    case 3:
                        message.width_mm = reader.float();
                        break;
                    case 4:
                        message.height_mm = reader.float();
                        break;
                    case 5:
                        if (message.split_pdf_results === $util.emptyObject)
                            message.split_pdf_results = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = "";
                        value = null;
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.string();
                                break;
                            case 2:
                                value = $root.appcore.ripe.SplitPDFResult.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.split_pdf_results[key] = value;
                        break;
                    case 6:
                        message.pdf_a1b_compliant = reader.bool();
                        break;
                    case 7:
                        message.num_of_pages = reader.int32();
                        break;
                    case 8:
                        if (!(message.id_labels && message.id_labels.length))
                            message.id_labels = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.id_labels.push(reader.uint64());
                        } else
                            message.id_labels.push(reader.uint64());
                        break;
                    case 9:
                        message.uploaded_at = reader.string();
                        break;
                    case 10:
                        message.configs_updated_at = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SourcePDF message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.SourcePDF
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.SourcePDF} SourcePDF
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SourcePDF.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SourcePDF message.
             * @function verify
             * @memberof appcore.ripe.SourcePDF
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SourcePDF.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.guid != null && message.hasOwnProperty("guid"))
                    if (!$util.isString(message.guid))
                        return "guid: string expected";
                if (message.filename != null && message.hasOwnProperty("filename"))
                    if (!$util.isString(message.filename))
                        return "filename: string expected";
                if (message.width_mm != null && message.hasOwnProperty("width_mm"))
                    if (typeof message.width_mm !== "number")
                        return "width_mm: number expected";
                if (message.height_mm != null && message.hasOwnProperty("height_mm"))
                    if (typeof message.height_mm !== "number")
                        return "height_mm: number expected";
                if (message.split_pdf_results != null && message.hasOwnProperty("split_pdf_results")) {
                    if (!$util.isObject(message.split_pdf_results))
                        return "split_pdf_results: object expected";
                    let key = Object.keys(message.split_pdf_results);
                    for (let i = 0; i < key.length; ++i) {
                        let error = $root.appcore.ripe.SplitPDFResult.verify(message.split_pdf_results[key[i]]);
                        if (error)
                            return "split_pdf_results." + error;
                    }
                }
                if (message.pdf_a1b_compliant != null && message.hasOwnProperty("pdf_a1b_compliant"))
                    if (typeof message.pdf_a1b_compliant !== "boolean")
                        return "pdf_a1b_compliant: boolean expected";
                if (message.num_of_pages != null && message.hasOwnProperty("num_of_pages"))
                    if (!$util.isInteger(message.num_of_pages))
                        return "num_of_pages: integer expected";
                if (message.id_labels != null && message.hasOwnProperty("id_labels")) {
                    if (!Array.isArray(message.id_labels))
                        return "id_labels: array expected";
                    for (let i = 0; i < message.id_labels.length; ++i)
                        if (!$util.isInteger(message.id_labels[i]) && !(message.id_labels[i] && $util.isInteger(message.id_labels[i].low) && $util.isInteger(message.id_labels[i].high)))
                            return "id_labels: integer|Long[] expected";
                }
                if (message.uploaded_at != null && message.hasOwnProperty("uploaded_at"))
                    if (!$util.isString(message.uploaded_at))
                        return "uploaded_at: string expected";
                if (message.configs_updated_at != null && message.hasOwnProperty("configs_updated_at"))
                    if (!$util.isString(message.configs_updated_at))
                        return "configs_updated_at: string expected";
                return null;
            };

            /**
             * Creates a SourcePDF message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.SourcePDF
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.SourcePDF} SourcePDF
             */
            SourcePDF.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.SourcePDF)
                    return object;
                let message = new $root.appcore.ripe.SourcePDF();
                if (object.guid != null)
                    message.guid = String(object.guid);
                if (object.filename != null)
                    message.filename = String(object.filename);
                if (object.width_mm != null)
                    message.width_mm = Number(object.width_mm);
                if (object.height_mm != null)
                    message.height_mm = Number(object.height_mm);
                if (object.split_pdf_results) {
                    if (typeof object.split_pdf_results !== "object")
                        throw TypeError(".appcore.ripe.SourcePDF.split_pdf_results: object expected");
                    message.split_pdf_results = {};
                    for (let keys = Object.keys(object.split_pdf_results), i = 0; i < keys.length; ++i) {
                        if (typeof object.split_pdf_results[keys[i]] !== "object")
                            throw TypeError(".appcore.ripe.SourcePDF.split_pdf_results: object expected");
                        message.split_pdf_results[keys[i]] = $root.appcore.ripe.SplitPDFResult.fromObject(object.split_pdf_results[keys[i]]);
                    }
                }
                if (object.pdf_a1b_compliant != null)
                    message.pdf_a1b_compliant = Boolean(object.pdf_a1b_compliant);
                if (object.num_of_pages != null)
                    message.num_of_pages = object.num_of_pages | 0;
                if (object.id_labels) {
                    if (!Array.isArray(object.id_labels))
                        throw TypeError(".appcore.ripe.SourcePDF.id_labels: array expected");
                    message.id_labels = [];
                    for (let i = 0; i < object.id_labels.length; ++i)
                        if ($util.Long)
                            (message.id_labels[i] = $util.Long.fromValue(object.id_labels[i])).unsigned = true;
                        else if (typeof object.id_labels[i] === "string")
                            message.id_labels[i] = parseInt(object.id_labels[i], 10);
                        else if (typeof object.id_labels[i] === "number")
                            message.id_labels[i] = object.id_labels[i];
                        else if (typeof object.id_labels[i] === "object")
                            message.id_labels[i] = new $util.LongBits(object.id_labels[i].low >>> 0, object.id_labels[i].high >>> 0).toNumber(true);
                }
                if (object.uploaded_at != null)
                    message.uploaded_at = String(object.uploaded_at);
                if (object.configs_updated_at != null)
                    message.configs_updated_at = String(object.configs_updated_at);
                return message;
            };

            /**
             * Creates a plain object from a SourcePDF message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.SourcePDF
             * @static
             * @param {appcore.ripe.SourcePDF} message SourcePDF
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SourcePDF.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.id_labels = [];
                if (options.objects || options.defaults)
                    object.split_pdf_results = {};
                if (options.defaults) {
                    object.guid = "";
                    object.filename = "";
                    object.width_mm = 0;
                    object.height_mm = 0;
                    object.pdf_a1b_compliant = false;
                    object.num_of_pages = 0;
                    object.uploaded_at = "";
                    object.configs_updated_at = "";
                }
                if (message.guid != null && message.hasOwnProperty("guid"))
                    object.guid = message.guid;
                if (message.filename != null && message.hasOwnProperty("filename"))
                    object.filename = message.filename;
                if (message.width_mm != null && message.hasOwnProperty("width_mm"))
                    object.width_mm = options.json && !isFinite(message.width_mm) ? String(message.width_mm) : message.width_mm;
                if (message.height_mm != null && message.hasOwnProperty("height_mm"))
                    object.height_mm = options.json && !isFinite(message.height_mm) ? String(message.height_mm) : message.height_mm;
                let keys2;
                if (message.split_pdf_results && (keys2 = Object.keys(message.split_pdf_results)).length) {
                    object.split_pdf_results = {};
                    for (let j = 0; j < keys2.length; ++j)
                        object.split_pdf_results[keys2[j]] = $root.appcore.ripe.SplitPDFResult.toObject(message.split_pdf_results[keys2[j]], options);
                }
                if (message.pdf_a1b_compliant != null && message.hasOwnProperty("pdf_a1b_compliant"))
                    object.pdf_a1b_compliant = message.pdf_a1b_compliant;
                if (message.num_of_pages != null && message.hasOwnProperty("num_of_pages"))
                    object.num_of_pages = message.num_of_pages;
                if (message.id_labels && message.id_labels.length) {
                    object.id_labels = [];
                    for (let j = 0; j < message.id_labels.length; ++j)
                        if (typeof message.id_labels[j] === "number")
                            object.id_labels[j] = options.longs === String ? String(message.id_labels[j]) : message.id_labels[j];
                        else
                            object.id_labels[j] = options.longs === String ? $util.Long.prototype.toString.call(message.id_labels[j]) : options.longs === Number ? new $util.LongBits(message.id_labels[j].low >>> 0, message.id_labels[j].high >>> 0).toNumber(true) : message.id_labels[j];
                }
                if (message.uploaded_at != null && message.hasOwnProperty("uploaded_at"))
                    object.uploaded_at = message.uploaded_at;
                if (message.configs_updated_at != null && message.hasOwnProperty("configs_updated_at"))
                    object.configs_updated_at = message.configs_updated_at;
                return object;
            };

            /**
             * Converts this SourcePDF to JSON.
             * @function toJSON
             * @memberof appcore.ripe.SourcePDF
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SourcePDF.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return SourcePDF;
        })();

        ripe.SplitPDFResult = (function() {

            /**
             * Properties of a SplitPDFResult.
             * @memberof appcore.ripe
             * @interface ISplitPDFResult
             * @property {Array.<appcore.ripe.ISplitPDF>|null} [split_pdfs] SplitPDFResult split_pdfs
             */

            /**
             * Constructs a new SplitPDFResult.
             * @memberof appcore.ripe
             * @classdesc Represents a SplitPDFResult.
             * @implements ISplitPDFResult
             * @constructor
             * @param {appcore.ripe.ISplitPDFResult=} [properties] Properties to set
             */
            function SplitPDFResult(properties) {
                this.split_pdfs = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SplitPDFResult split_pdfs.
             * @member {Array.<appcore.ripe.ISplitPDF>} split_pdfs
             * @memberof appcore.ripe.SplitPDFResult
             * @instance
             */
            SplitPDFResult.prototype.split_pdfs = $util.emptyArray;

            /**
             * Creates a new SplitPDFResult instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.SplitPDFResult
             * @static
             * @param {appcore.ripe.ISplitPDFResult=} [properties] Properties to set
             * @returns {appcore.ripe.SplitPDFResult} SplitPDFResult instance
             */
            SplitPDFResult.create = function create(properties) {
                return new SplitPDFResult(properties);
            };

            /**
             * Encodes the specified SplitPDFResult message. Does not implicitly {@link appcore.ripe.SplitPDFResult.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.SplitPDFResult
             * @static
             * @param {appcore.ripe.ISplitPDFResult} message SplitPDFResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SplitPDFResult.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.split_pdfs != null && message.split_pdfs.length)
                    for (let i = 0; i < message.split_pdfs.length; ++i)
                        $root.appcore.ripe.SplitPDF.encode(message.split_pdfs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified SplitPDFResult message, length delimited. Does not implicitly {@link appcore.ripe.SplitPDFResult.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.SplitPDFResult
             * @static
             * @param {appcore.ripe.ISplitPDFResult} message SplitPDFResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SplitPDFResult.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SplitPDFResult message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.SplitPDFResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.SplitPDFResult} SplitPDFResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SplitPDFResult.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.SplitPDFResult();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.split_pdfs && message.split_pdfs.length))
                            message.split_pdfs = [];
                        message.split_pdfs.push($root.appcore.ripe.SplitPDF.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SplitPDFResult message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.SplitPDFResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.SplitPDFResult} SplitPDFResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SplitPDFResult.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SplitPDFResult message.
             * @function verify
             * @memberof appcore.ripe.SplitPDFResult
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SplitPDFResult.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.split_pdfs != null && message.hasOwnProperty("split_pdfs")) {
                    if (!Array.isArray(message.split_pdfs))
                        return "split_pdfs: array expected";
                    for (let i = 0; i < message.split_pdfs.length; ++i) {
                        let error = $root.appcore.ripe.SplitPDF.verify(message.split_pdfs[i]);
                        if (error)
                            return "split_pdfs." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a SplitPDFResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.SplitPDFResult
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.SplitPDFResult} SplitPDFResult
             */
            SplitPDFResult.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.SplitPDFResult)
                    return object;
                let message = new $root.appcore.ripe.SplitPDFResult();
                if (object.split_pdfs) {
                    if (!Array.isArray(object.split_pdfs))
                        throw TypeError(".appcore.ripe.SplitPDFResult.split_pdfs: array expected");
                    message.split_pdfs = [];
                    for (let i = 0; i < object.split_pdfs.length; ++i) {
                        if (typeof object.split_pdfs[i] !== "object")
                            throw TypeError(".appcore.ripe.SplitPDFResult.split_pdfs: object expected");
                        message.split_pdfs[i] = $root.appcore.ripe.SplitPDF.fromObject(object.split_pdfs[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a SplitPDFResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.SplitPDFResult
             * @static
             * @param {appcore.ripe.SplitPDFResult} message SplitPDFResult
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SplitPDFResult.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.split_pdfs = [];
                if (message.split_pdfs && message.split_pdfs.length) {
                    object.split_pdfs = [];
                    for (let j = 0; j < message.split_pdfs.length; ++j)
                        object.split_pdfs[j] = $root.appcore.ripe.SplitPDF.toObject(message.split_pdfs[j], options);
                }
                return object;
            };

            /**
             * Converts this SplitPDFResult to JSON.
             * @function toJSON
             * @memberof appcore.ripe.SplitPDFResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SplitPDFResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return SplitPDFResult;
        })();

        ripe.SplitPDF = (function() {

            /**
             * Properties of a SplitPDF.
             * @memberof appcore.ripe
             * @interface ISplitPDF
             * @property {string|null} [filename] SplitPDF filename
             * @property {string|null} [display_filename] SplitPDF display_filename
             */

            /**
             * Constructs a new SplitPDF.
             * @memberof appcore.ripe
             * @classdesc Represents a SplitPDF.
             * @implements ISplitPDF
             * @constructor
             * @param {appcore.ripe.ISplitPDF=} [properties] Properties to set
             */
            function SplitPDF(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SplitPDF filename.
             * @member {string} filename
             * @memberof appcore.ripe.SplitPDF
             * @instance
             */
            SplitPDF.prototype.filename = "";

            /**
             * SplitPDF display_filename.
             * @member {string} display_filename
             * @memberof appcore.ripe.SplitPDF
             * @instance
             */
            SplitPDF.prototype.display_filename = "";

            /**
             * Creates a new SplitPDF instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.SplitPDF
             * @static
             * @param {appcore.ripe.ISplitPDF=} [properties] Properties to set
             * @returns {appcore.ripe.SplitPDF} SplitPDF instance
             */
            SplitPDF.create = function create(properties) {
                return new SplitPDF(properties);
            };

            /**
             * Encodes the specified SplitPDF message. Does not implicitly {@link appcore.ripe.SplitPDF.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.SplitPDF
             * @static
             * @param {appcore.ripe.ISplitPDF} message SplitPDF message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SplitPDF.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.filename != null && Object.hasOwnProperty.call(message, "filename"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.filename);
                if (message.display_filename != null && Object.hasOwnProperty.call(message, "display_filename"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.display_filename);
                return writer;
            };

            /**
             * Encodes the specified SplitPDF message, length delimited. Does not implicitly {@link appcore.ripe.SplitPDF.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.SplitPDF
             * @static
             * @param {appcore.ripe.ISplitPDF} message SplitPDF message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SplitPDF.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SplitPDF message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.SplitPDF
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.SplitPDF} SplitPDF
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SplitPDF.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.SplitPDF();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.filename = reader.string();
                        break;
                    case 2:
                        message.display_filename = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SplitPDF message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.SplitPDF
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.SplitPDF} SplitPDF
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SplitPDF.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SplitPDF message.
             * @function verify
             * @memberof appcore.ripe.SplitPDF
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SplitPDF.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.filename != null && message.hasOwnProperty("filename"))
                    if (!$util.isString(message.filename))
                        return "filename: string expected";
                if (message.display_filename != null && message.hasOwnProperty("display_filename"))
                    if (!$util.isString(message.display_filename))
                        return "display_filename: string expected";
                return null;
            };

            /**
             * Creates a SplitPDF message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.SplitPDF
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.SplitPDF} SplitPDF
             */
            SplitPDF.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.SplitPDF)
                    return object;
                let message = new $root.appcore.ripe.SplitPDF();
                if (object.filename != null)
                    message.filename = String(object.filename);
                if (object.display_filename != null)
                    message.display_filename = String(object.display_filename);
                return message;
            };

            /**
             * Creates a plain object from a SplitPDF message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.SplitPDF
             * @static
             * @param {appcore.ripe.SplitPDF} message SplitPDF
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SplitPDF.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.filename = "";
                    object.display_filename = "";
                }
                if (message.filename != null && message.hasOwnProperty("filename"))
                    object.filename = message.filename;
                if (message.display_filename != null && message.hasOwnProperty("display_filename"))
                    object.display_filename = message.display_filename;
                return object;
            };

            /**
             * Converts this SplitPDF to JSON.
             * @function toJSON
             * @memberof appcore.ripe.SplitPDF
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SplitPDF.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return SplitPDF;
        })();

        ripe.PDFToolsData = (function() {

            /**
             * Properties of a PDFToolsData.
             * @memberof appcore.ripe
             * @interface IPDFToolsData
             * @property {appcore.ripe.IPDFList|null} [source_pdfs] PDFToolsData source_pdfs
             * @property {appcore.ripe.IPrintheadConfigs|null} [configs] PDFToolsData configs
             * @property {appcore.ripe.IImageSizePositions|null} [size_positions] PDFToolsData size_positions
             * @property {appcore.ripe.IColorManagementConfigs|null} [color_management_configs] PDFToolsData color_management_configs
             * @property {appcore.ripe.IInkManagementConfigs|null} [ink_management_configs] PDFToolsData ink_management_configs
             * @property {appcore.ripe.ILabels|null} [labels] PDFToolsData labels
             * @property {Array.<appcore.ripe.IOutputSettingsOverride>|null} [output_settings_overrides] PDFToolsData output_settings_overrides
             * @property {Object.<string,appcore.ripe.IMapOEMLabel>|null} [maps_oem_label] PDFToolsData maps_oem_label
             */

            /**
             * Constructs a new PDFToolsData.
             * @memberof appcore.ripe
             * @classdesc Represents a PDFToolsData.
             * @implements IPDFToolsData
             * @constructor
             * @param {appcore.ripe.IPDFToolsData=} [properties] Properties to set
             */
            function PDFToolsData(properties) {
                this.output_settings_overrides = [];
                this.maps_oem_label = {};
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PDFToolsData source_pdfs.
             * @member {appcore.ripe.IPDFList|null|undefined} source_pdfs
             * @memberof appcore.ripe.PDFToolsData
             * @instance
             */
            PDFToolsData.prototype.source_pdfs = null;

            /**
             * PDFToolsData configs.
             * @member {appcore.ripe.IPrintheadConfigs|null|undefined} configs
             * @memberof appcore.ripe.PDFToolsData
             * @instance
             */
            PDFToolsData.prototype.configs = null;

            /**
             * PDFToolsData size_positions.
             * @member {appcore.ripe.IImageSizePositions|null|undefined} size_positions
             * @memberof appcore.ripe.PDFToolsData
             * @instance
             */
            PDFToolsData.prototype.size_positions = null;

            /**
             * PDFToolsData color_management_configs.
             * @member {appcore.ripe.IColorManagementConfigs|null|undefined} color_management_configs
             * @memberof appcore.ripe.PDFToolsData
             * @instance
             */
            PDFToolsData.prototype.color_management_configs = null;

            /**
             * PDFToolsData ink_management_configs.
             * @member {appcore.ripe.IInkManagementConfigs|null|undefined} ink_management_configs
             * @memberof appcore.ripe.PDFToolsData
             * @instance
             */
            PDFToolsData.prototype.ink_management_configs = null;

            /**
             * PDFToolsData labels.
             * @member {appcore.ripe.ILabels|null|undefined} labels
             * @memberof appcore.ripe.PDFToolsData
             * @instance
             */
            PDFToolsData.prototype.labels = null;

            /**
             * PDFToolsData output_settings_overrides.
             * @member {Array.<appcore.ripe.IOutputSettingsOverride>} output_settings_overrides
             * @memberof appcore.ripe.PDFToolsData
             * @instance
             */
            PDFToolsData.prototype.output_settings_overrides = $util.emptyArray;

            /**
             * PDFToolsData maps_oem_label.
             * @member {Object.<string,appcore.ripe.IMapOEMLabel>} maps_oem_label
             * @memberof appcore.ripe.PDFToolsData
             * @instance
             */
            PDFToolsData.prototype.maps_oem_label = $util.emptyObject;

            /**
             * Creates a new PDFToolsData instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.PDFToolsData
             * @static
             * @param {appcore.ripe.IPDFToolsData=} [properties] Properties to set
             * @returns {appcore.ripe.PDFToolsData} PDFToolsData instance
             */
            PDFToolsData.create = function create(properties) {
                return new PDFToolsData(properties);
            };

            /**
             * Encodes the specified PDFToolsData message. Does not implicitly {@link appcore.ripe.PDFToolsData.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.PDFToolsData
             * @static
             * @param {appcore.ripe.IPDFToolsData} message PDFToolsData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PDFToolsData.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.source_pdfs != null && Object.hasOwnProperty.call(message, "source_pdfs"))
                    $root.appcore.ripe.PDFList.encode(message.source_pdfs, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.configs != null && Object.hasOwnProperty.call(message, "configs"))
                    $root.appcore.ripe.PrintheadConfigs.encode(message.configs, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.size_positions != null && Object.hasOwnProperty.call(message, "size_positions"))
                    $root.appcore.ripe.ImageSizePositions.encode(message.size_positions, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.color_management_configs != null && Object.hasOwnProperty.call(message, "color_management_configs"))
                    $root.appcore.ripe.ColorManagementConfigs.encode(message.color_management_configs, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.ink_management_configs != null && Object.hasOwnProperty.call(message, "ink_management_configs"))
                    $root.appcore.ripe.InkManagementConfigs.encode(message.ink_management_configs, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.labels != null && Object.hasOwnProperty.call(message, "labels"))
                    $root.appcore.ripe.Labels.encode(message.labels, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.output_settings_overrides != null && message.output_settings_overrides.length)
                    for (let i = 0; i < message.output_settings_overrides.length; ++i)
                        $root.appcore.ripe.OutputSettingsOverride.encode(message.output_settings_overrides[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.maps_oem_label != null && Object.hasOwnProperty.call(message, "maps_oem_label"))
                    for (let keys = Object.keys(message.maps_oem_label), i = 0; i < keys.length; ++i) {
                        writer.uint32(/* id 8, wireType 2 =*/66).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                        $root.appcore.ripe.MapOEMLabel.encode(message.maps_oem_label[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                    }
                return writer;
            };

            /**
             * Encodes the specified PDFToolsData message, length delimited. Does not implicitly {@link appcore.ripe.PDFToolsData.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.PDFToolsData
             * @static
             * @param {appcore.ripe.IPDFToolsData} message PDFToolsData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PDFToolsData.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PDFToolsData message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.PDFToolsData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.PDFToolsData} PDFToolsData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PDFToolsData.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.PDFToolsData(), key, value;
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.source_pdfs = $root.appcore.ripe.PDFList.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.configs = $root.appcore.ripe.PrintheadConfigs.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.size_positions = $root.appcore.ripe.ImageSizePositions.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.color_management_configs = $root.appcore.ripe.ColorManagementConfigs.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.ink_management_configs = $root.appcore.ripe.InkManagementConfigs.decode(reader, reader.uint32());
                        break;
                    case 6:
                        message.labels = $root.appcore.ripe.Labels.decode(reader, reader.uint32());
                        break;
                    case 7:
                        if (!(message.output_settings_overrides && message.output_settings_overrides.length))
                            message.output_settings_overrides = [];
                        message.output_settings_overrides.push($root.appcore.ripe.OutputSettingsOverride.decode(reader, reader.uint32()));
                        break;
                    case 8:
                        if (message.maps_oem_label === $util.emptyObject)
                            message.maps_oem_label = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = "";
                        value = null;
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.string();
                                break;
                            case 2:
                                value = $root.appcore.ripe.MapOEMLabel.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.maps_oem_label[key] = value;
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PDFToolsData message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.PDFToolsData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.PDFToolsData} PDFToolsData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PDFToolsData.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PDFToolsData message.
             * @function verify
             * @memberof appcore.ripe.PDFToolsData
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PDFToolsData.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.source_pdfs != null && message.hasOwnProperty("source_pdfs")) {
                    let error = $root.appcore.ripe.PDFList.verify(message.source_pdfs);
                    if (error)
                        return "source_pdfs." + error;
                }
                if (message.configs != null && message.hasOwnProperty("configs")) {
                    let error = $root.appcore.ripe.PrintheadConfigs.verify(message.configs);
                    if (error)
                        return "configs." + error;
                }
                if (message.size_positions != null && message.hasOwnProperty("size_positions")) {
                    let error = $root.appcore.ripe.ImageSizePositions.verify(message.size_positions);
                    if (error)
                        return "size_positions." + error;
                }
                if (message.color_management_configs != null && message.hasOwnProperty("color_management_configs")) {
                    let error = $root.appcore.ripe.ColorManagementConfigs.verify(message.color_management_configs);
                    if (error)
                        return "color_management_configs." + error;
                }
                if (message.ink_management_configs != null && message.hasOwnProperty("ink_management_configs")) {
                    let error = $root.appcore.ripe.InkManagementConfigs.verify(message.ink_management_configs);
                    if (error)
                        return "ink_management_configs." + error;
                }
                if (message.labels != null && message.hasOwnProperty("labels")) {
                    let error = $root.appcore.ripe.Labels.verify(message.labels);
                    if (error)
                        return "labels." + error;
                }
                if (message.output_settings_overrides != null && message.hasOwnProperty("output_settings_overrides")) {
                    if (!Array.isArray(message.output_settings_overrides))
                        return "output_settings_overrides: array expected";
                    for (let i = 0; i < message.output_settings_overrides.length; ++i) {
                        let error = $root.appcore.ripe.OutputSettingsOverride.verify(message.output_settings_overrides[i]);
                        if (error)
                            return "output_settings_overrides." + error;
                    }
                }
                if (message.maps_oem_label != null && message.hasOwnProperty("maps_oem_label")) {
                    if (!$util.isObject(message.maps_oem_label))
                        return "maps_oem_label: object expected";
                    let key = Object.keys(message.maps_oem_label);
                    for (let i = 0; i < key.length; ++i) {
                        let error = $root.appcore.ripe.MapOEMLabel.verify(message.maps_oem_label[key[i]]);
                        if (error)
                            return "maps_oem_label." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a PDFToolsData message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.PDFToolsData
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.PDFToolsData} PDFToolsData
             */
            PDFToolsData.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.PDFToolsData)
                    return object;
                let message = new $root.appcore.ripe.PDFToolsData();
                if (object.source_pdfs != null) {
                    if (typeof object.source_pdfs !== "object")
                        throw TypeError(".appcore.ripe.PDFToolsData.source_pdfs: object expected");
                    message.source_pdfs = $root.appcore.ripe.PDFList.fromObject(object.source_pdfs);
                }
                if (object.configs != null) {
                    if (typeof object.configs !== "object")
                        throw TypeError(".appcore.ripe.PDFToolsData.configs: object expected");
                    message.configs = $root.appcore.ripe.PrintheadConfigs.fromObject(object.configs);
                }
                if (object.size_positions != null) {
                    if (typeof object.size_positions !== "object")
                        throw TypeError(".appcore.ripe.PDFToolsData.size_positions: object expected");
                    message.size_positions = $root.appcore.ripe.ImageSizePositions.fromObject(object.size_positions);
                }
                if (object.color_management_configs != null) {
                    if (typeof object.color_management_configs !== "object")
                        throw TypeError(".appcore.ripe.PDFToolsData.color_management_configs: object expected");
                    message.color_management_configs = $root.appcore.ripe.ColorManagementConfigs.fromObject(object.color_management_configs);
                }
                if (object.ink_management_configs != null) {
                    if (typeof object.ink_management_configs !== "object")
                        throw TypeError(".appcore.ripe.PDFToolsData.ink_management_configs: object expected");
                    message.ink_management_configs = $root.appcore.ripe.InkManagementConfigs.fromObject(object.ink_management_configs);
                }
                if (object.labels != null) {
                    if (typeof object.labels !== "object")
                        throw TypeError(".appcore.ripe.PDFToolsData.labels: object expected");
                    message.labels = $root.appcore.ripe.Labels.fromObject(object.labels);
                }
                if (object.output_settings_overrides) {
                    if (!Array.isArray(object.output_settings_overrides))
                        throw TypeError(".appcore.ripe.PDFToolsData.output_settings_overrides: array expected");
                    message.output_settings_overrides = [];
                    for (let i = 0; i < object.output_settings_overrides.length; ++i) {
                        if (typeof object.output_settings_overrides[i] !== "object")
                            throw TypeError(".appcore.ripe.PDFToolsData.output_settings_overrides: object expected");
                        message.output_settings_overrides[i] = $root.appcore.ripe.OutputSettingsOverride.fromObject(object.output_settings_overrides[i]);
                    }
                }
                if (object.maps_oem_label) {
                    if (typeof object.maps_oem_label !== "object")
                        throw TypeError(".appcore.ripe.PDFToolsData.maps_oem_label: object expected");
                    message.maps_oem_label = {};
                    for (let keys = Object.keys(object.maps_oem_label), i = 0; i < keys.length; ++i) {
                        if (typeof object.maps_oem_label[keys[i]] !== "object")
                            throw TypeError(".appcore.ripe.PDFToolsData.maps_oem_label: object expected");
                        message.maps_oem_label[keys[i]] = $root.appcore.ripe.MapOEMLabel.fromObject(object.maps_oem_label[keys[i]]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a PDFToolsData message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.PDFToolsData
             * @static
             * @param {appcore.ripe.PDFToolsData} message PDFToolsData
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PDFToolsData.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.output_settings_overrides = [];
                if (options.objects || options.defaults)
                    object.maps_oem_label = {};
                if (options.defaults) {
                    object.source_pdfs = null;
                    object.configs = null;
                    object.size_positions = null;
                    object.color_management_configs = null;
                    object.ink_management_configs = null;
                    object.labels = null;
                }
                if (message.source_pdfs != null && message.hasOwnProperty("source_pdfs"))
                    object.source_pdfs = $root.appcore.ripe.PDFList.toObject(message.source_pdfs, options);
                if (message.configs != null && message.hasOwnProperty("configs"))
                    object.configs = $root.appcore.ripe.PrintheadConfigs.toObject(message.configs, options);
                if (message.size_positions != null && message.hasOwnProperty("size_positions"))
                    object.size_positions = $root.appcore.ripe.ImageSizePositions.toObject(message.size_positions, options);
                if (message.color_management_configs != null && message.hasOwnProperty("color_management_configs"))
                    object.color_management_configs = $root.appcore.ripe.ColorManagementConfigs.toObject(message.color_management_configs, options);
                if (message.ink_management_configs != null && message.hasOwnProperty("ink_management_configs"))
                    object.ink_management_configs = $root.appcore.ripe.InkManagementConfigs.toObject(message.ink_management_configs, options);
                if (message.labels != null && message.hasOwnProperty("labels"))
                    object.labels = $root.appcore.ripe.Labels.toObject(message.labels, options);
                if (message.output_settings_overrides && message.output_settings_overrides.length) {
                    object.output_settings_overrides = [];
                    for (let j = 0; j < message.output_settings_overrides.length; ++j)
                        object.output_settings_overrides[j] = $root.appcore.ripe.OutputSettingsOverride.toObject(message.output_settings_overrides[j], options);
                }
                let keys2;
                if (message.maps_oem_label && (keys2 = Object.keys(message.maps_oem_label)).length) {
                    object.maps_oem_label = {};
                    for (let j = 0; j < keys2.length; ++j)
                        object.maps_oem_label[keys2[j]] = $root.appcore.ripe.MapOEMLabel.toObject(message.maps_oem_label[keys2[j]], options);
                }
                return object;
            };

            /**
             * Converts this PDFToolsData to JSON.
             * @function toJSON
             * @memberof appcore.ripe.PDFToolsData
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PDFToolsData.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return PDFToolsData;
        })();

        ripe.PrintheadConfigs = (function() {

            /**
             * Properties of a PrintheadConfigs.
             * @memberof appcore.ripe
             * @interface IPrintheadConfigs
             * @property {Array.<appcore.ripe.IPrintheadConfig>|null} [config] PrintheadConfigs config
             * @property {Array.<appcore.ripe.IOutput>|null} [selectable_outputs] PrintheadConfigs selectable_outputs
             * @property {appcore.ripe.IICCProfiles|null} [icc_profiles] PrintheadConfigs icc_profiles
             * @property {appcore.ripe.IPrinters|null} [printers] PrintheadConfigs printers
             * @property {appcore.ripe.IScreeningConfigs|null} [screening_configs] PrintheadConfigs screening_configs
             * @property {appcore.ripe.IDeviceLinks|null} [device_links] PrintheadConfigs device_links
             * @property {Object.<string,appcore.ripe.IMapOEMLabel>|null} [maps_oem_label] PrintheadConfigs maps_oem_label
             */

            /**
             * Constructs a new PrintheadConfigs.
             * @memberof appcore.ripe
             * @classdesc Represents a PrintheadConfigs.
             * @implements IPrintheadConfigs
             * @constructor
             * @param {appcore.ripe.IPrintheadConfigs=} [properties] Properties to set
             */
            function PrintheadConfigs(properties) {
                this.config = [];
                this.selectable_outputs = [];
                this.maps_oem_label = {};
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PrintheadConfigs config.
             * @member {Array.<appcore.ripe.IPrintheadConfig>} config
             * @memberof appcore.ripe.PrintheadConfigs
             * @instance
             */
            PrintheadConfigs.prototype.config = $util.emptyArray;

            /**
             * PrintheadConfigs selectable_outputs.
             * @member {Array.<appcore.ripe.IOutput>} selectable_outputs
             * @memberof appcore.ripe.PrintheadConfigs
             * @instance
             */
            PrintheadConfigs.prototype.selectable_outputs = $util.emptyArray;

            /**
             * PrintheadConfigs icc_profiles.
             * @member {appcore.ripe.IICCProfiles|null|undefined} icc_profiles
             * @memberof appcore.ripe.PrintheadConfigs
             * @instance
             */
            PrintheadConfigs.prototype.icc_profiles = null;

            /**
             * PrintheadConfigs printers.
             * @member {appcore.ripe.IPrinters|null|undefined} printers
             * @memberof appcore.ripe.PrintheadConfigs
             * @instance
             */
            PrintheadConfigs.prototype.printers = null;

            /**
             * PrintheadConfigs screening_configs.
             * @member {appcore.ripe.IScreeningConfigs|null|undefined} screening_configs
             * @memberof appcore.ripe.PrintheadConfigs
             * @instance
             */
            PrintheadConfigs.prototype.screening_configs = null;

            /**
             * PrintheadConfigs device_links.
             * @member {appcore.ripe.IDeviceLinks|null|undefined} device_links
             * @memberof appcore.ripe.PrintheadConfigs
             * @instance
             */
            PrintheadConfigs.prototype.device_links = null;

            /**
             * PrintheadConfigs maps_oem_label.
             * @member {Object.<string,appcore.ripe.IMapOEMLabel>} maps_oem_label
             * @memberof appcore.ripe.PrintheadConfigs
             * @instance
             */
            PrintheadConfigs.prototype.maps_oem_label = $util.emptyObject;

            /**
             * Creates a new PrintheadConfigs instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.PrintheadConfigs
             * @static
             * @param {appcore.ripe.IPrintheadConfigs=} [properties] Properties to set
             * @returns {appcore.ripe.PrintheadConfigs} PrintheadConfigs instance
             */
            PrintheadConfigs.create = function create(properties) {
                return new PrintheadConfigs(properties);
            };

            /**
             * Encodes the specified PrintheadConfigs message. Does not implicitly {@link appcore.ripe.PrintheadConfigs.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.PrintheadConfigs
             * @static
             * @param {appcore.ripe.IPrintheadConfigs} message PrintheadConfigs message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PrintheadConfigs.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.config != null && message.config.length)
                    for (let i = 0; i < message.config.length; ++i)
                        $root.appcore.ripe.PrintheadConfig.encode(message.config[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.selectable_outputs != null && message.selectable_outputs.length)
                    for (let i = 0; i < message.selectable_outputs.length; ++i)
                        $root.appcore.ripe.Output.encode(message.selectable_outputs[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.icc_profiles != null && Object.hasOwnProperty.call(message, "icc_profiles"))
                    $root.appcore.ripe.ICCProfiles.encode(message.icc_profiles, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.printers != null && Object.hasOwnProperty.call(message, "printers"))
                    $root.appcore.ripe.Printers.encode(message.printers, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.screening_configs != null && Object.hasOwnProperty.call(message, "screening_configs"))
                    $root.appcore.ripe.ScreeningConfigs.encode(message.screening_configs, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.device_links != null && Object.hasOwnProperty.call(message, "device_links"))
                    $root.appcore.ripe.DeviceLinks.encode(message.device_links, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.maps_oem_label != null && Object.hasOwnProperty.call(message, "maps_oem_label"))
                    for (let keys = Object.keys(message.maps_oem_label), i = 0; i < keys.length; ++i) {
                        writer.uint32(/* id 7, wireType 2 =*/58).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                        $root.appcore.ripe.MapOEMLabel.encode(message.maps_oem_label[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                    }
                return writer;
            };

            /**
             * Encodes the specified PrintheadConfigs message, length delimited. Does not implicitly {@link appcore.ripe.PrintheadConfigs.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.PrintheadConfigs
             * @static
             * @param {appcore.ripe.IPrintheadConfigs} message PrintheadConfigs message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PrintheadConfigs.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PrintheadConfigs message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.PrintheadConfigs
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.PrintheadConfigs} PrintheadConfigs
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PrintheadConfigs.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.PrintheadConfigs(), key, value;
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.config && message.config.length))
                            message.config = [];
                        message.config.push($root.appcore.ripe.PrintheadConfig.decode(reader, reader.uint32()));
                        break;
                    case 2:
                        if (!(message.selectable_outputs && message.selectable_outputs.length))
                            message.selectable_outputs = [];
                        message.selectable_outputs.push($root.appcore.ripe.Output.decode(reader, reader.uint32()));
                        break;
                    case 3:
                        message.icc_profiles = $root.appcore.ripe.ICCProfiles.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.printers = $root.appcore.ripe.Printers.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.screening_configs = $root.appcore.ripe.ScreeningConfigs.decode(reader, reader.uint32());
                        break;
                    case 6:
                        message.device_links = $root.appcore.ripe.DeviceLinks.decode(reader, reader.uint32());
                        break;
                    case 7:
                        if (message.maps_oem_label === $util.emptyObject)
                            message.maps_oem_label = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = "";
                        value = null;
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.string();
                                break;
                            case 2:
                                value = $root.appcore.ripe.MapOEMLabel.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.maps_oem_label[key] = value;
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PrintheadConfigs message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.PrintheadConfigs
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.PrintheadConfigs} PrintheadConfigs
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PrintheadConfigs.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PrintheadConfigs message.
             * @function verify
             * @memberof appcore.ripe.PrintheadConfigs
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PrintheadConfigs.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.config != null && message.hasOwnProperty("config")) {
                    if (!Array.isArray(message.config))
                        return "config: array expected";
                    for (let i = 0; i < message.config.length; ++i) {
                        let error = $root.appcore.ripe.PrintheadConfig.verify(message.config[i]);
                        if (error)
                            return "config." + error;
                    }
                }
                if (message.selectable_outputs != null && message.hasOwnProperty("selectable_outputs")) {
                    if (!Array.isArray(message.selectable_outputs))
                        return "selectable_outputs: array expected";
                    for (let i = 0; i < message.selectable_outputs.length; ++i) {
                        let error = $root.appcore.ripe.Output.verify(message.selectable_outputs[i]);
                        if (error)
                            return "selectable_outputs." + error;
                    }
                }
                if (message.icc_profiles != null && message.hasOwnProperty("icc_profiles")) {
                    let error = $root.appcore.ripe.ICCProfiles.verify(message.icc_profiles);
                    if (error)
                        return "icc_profiles." + error;
                }
                if (message.printers != null && message.hasOwnProperty("printers")) {
                    let error = $root.appcore.ripe.Printers.verify(message.printers);
                    if (error)
                        return "printers." + error;
                }
                if (message.screening_configs != null && message.hasOwnProperty("screening_configs")) {
                    let error = $root.appcore.ripe.ScreeningConfigs.verify(message.screening_configs);
                    if (error)
                        return "screening_configs." + error;
                }
                if (message.device_links != null && message.hasOwnProperty("device_links")) {
                    let error = $root.appcore.ripe.DeviceLinks.verify(message.device_links);
                    if (error)
                        return "device_links." + error;
                }
                if (message.maps_oem_label != null && message.hasOwnProperty("maps_oem_label")) {
                    if (!$util.isObject(message.maps_oem_label))
                        return "maps_oem_label: object expected";
                    let key = Object.keys(message.maps_oem_label);
                    for (let i = 0; i < key.length; ++i) {
                        let error = $root.appcore.ripe.MapOEMLabel.verify(message.maps_oem_label[key[i]]);
                        if (error)
                            return "maps_oem_label." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a PrintheadConfigs message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.PrintheadConfigs
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.PrintheadConfigs} PrintheadConfigs
             */
            PrintheadConfigs.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.PrintheadConfigs)
                    return object;
                let message = new $root.appcore.ripe.PrintheadConfigs();
                if (object.config) {
                    if (!Array.isArray(object.config))
                        throw TypeError(".appcore.ripe.PrintheadConfigs.config: array expected");
                    message.config = [];
                    for (let i = 0; i < object.config.length; ++i) {
                        if (typeof object.config[i] !== "object")
                            throw TypeError(".appcore.ripe.PrintheadConfigs.config: object expected");
                        message.config[i] = $root.appcore.ripe.PrintheadConfig.fromObject(object.config[i]);
                    }
                }
                if (object.selectable_outputs) {
                    if (!Array.isArray(object.selectable_outputs))
                        throw TypeError(".appcore.ripe.PrintheadConfigs.selectable_outputs: array expected");
                    message.selectable_outputs = [];
                    for (let i = 0; i < object.selectable_outputs.length; ++i) {
                        if (typeof object.selectable_outputs[i] !== "object")
                            throw TypeError(".appcore.ripe.PrintheadConfigs.selectable_outputs: object expected");
                        message.selectable_outputs[i] = $root.appcore.ripe.Output.fromObject(object.selectable_outputs[i]);
                    }
                }
                if (object.icc_profiles != null) {
                    if (typeof object.icc_profiles !== "object")
                        throw TypeError(".appcore.ripe.PrintheadConfigs.icc_profiles: object expected");
                    message.icc_profiles = $root.appcore.ripe.ICCProfiles.fromObject(object.icc_profiles);
                }
                if (object.printers != null) {
                    if (typeof object.printers !== "object")
                        throw TypeError(".appcore.ripe.PrintheadConfigs.printers: object expected");
                    message.printers = $root.appcore.ripe.Printers.fromObject(object.printers);
                }
                if (object.screening_configs != null) {
                    if (typeof object.screening_configs !== "object")
                        throw TypeError(".appcore.ripe.PrintheadConfigs.screening_configs: object expected");
                    message.screening_configs = $root.appcore.ripe.ScreeningConfigs.fromObject(object.screening_configs);
                }
                if (object.device_links != null) {
                    if (typeof object.device_links !== "object")
                        throw TypeError(".appcore.ripe.PrintheadConfigs.device_links: object expected");
                    message.device_links = $root.appcore.ripe.DeviceLinks.fromObject(object.device_links);
                }
                if (object.maps_oem_label) {
                    if (typeof object.maps_oem_label !== "object")
                        throw TypeError(".appcore.ripe.PrintheadConfigs.maps_oem_label: object expected");
                    message.maps_oem_label = {};
                    for (let keys = Object.keys(object.maps_oem_label), i = 0; i < keys.length; ++i) {
                        if (typeof object.maps_oem_label[keys[i]] !== "object")
                            throw TypeError(".appcore.ripe.PrintheadConfigs.maps_oem_label: object expected");
                        message.maps_oem_label[keys[i]] = $root.appcore.ripe.MapOEMLabel.fromObject(object.maps_oem_label[keys[i]]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a PrintheadConfigs message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.PrintheadConfigs
             * @static
             * @param {appcore.ripe.PrintheadConfigs} message PrintheadConfigs
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PrintheadConfigs.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults) {
                    object.config = [];
                    object.selectable_outputs = [];
                }
                if (options.objects || options.defaults)
                    object.maps_oem_label = {};
                if (options.defaults) {
                    object.icc_profiles = null;
                    object.printers = null;
                    object.screening_configs = null;
                    object.device_links = null;
                }
                if (message.config && message.config.length) {
                    object.config = [];
                    for (let j = 0; j < message.config.length; ++j)
                        object.config[j] = $root.appcore.ripe.PrintheadConfig.toObject(message.config[j], options);
                }
                if (message.selectable_outputs && message.selectable_outputs.length) {
                    object.selectable_outputs = [];
                    for (let j = 0; j < message.selectable_outputs.length; ++j)
                        object.selectable_outputs[j] = $root.appcore.ripe.Output.toObject(message.selectable_outputs[j], options);
                }
                if (message.icc_profiles != null && message.hasOwnProperty("icc_profiles"))
                    object.icc_profiles = $root.appcore.ripe.ICCProfiles.toObject(message.icc_profiles, options);
                if (message.printers != null && message.hasOwnProperty("printers"))
                    object.printers = $root.appcore.ripe.Printers.toObject(message.printers, options);
                if (message.screening_configs != null && message.hasOwnProperty("screening_configs"))
                    object.screening_configs = $root.appcore.ripe.ScreeningConfigs.toObject(message.screening_configs, options);
                if (message.device_links != null && message.hasOwnProperty("device_links"))
                    object.device_links = $root.appcore.ripe.DeviceLinks.toObject(message.device_links, options);
                let keys2;
                if (message.maps_oem_label && (keys2 = Object.keys(message.maps_oem_label)).length) {
                    object.maps_oem_label = {};
                    for (let j = 0; j < keys2.length; ++j)
                        object.maps_oem_label[keys2[j]] = $root.appcore.ripe.MapOEMLabel.toObject(message.maps_oem_label[keys2[j]], options);
                }
                return object;
            };

            /**
             * Converts this PrintheadConfigs to JSON.
             * @function toJSON
             * @memberof appcore.ripe.PrintheadConfigs
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PrintheadConfigs.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return PrintheadConfigs;
        })();

        /**
         * Intent enum.
         * @name appcore.ripe.Intent
         * @enum {number}
         * @property {number} source=0 source value
         * @property {number} perceptual=1 perceptual value
         * @property {number} colorimetric_relative=2 colorimetric_relative value
         * @property {number} saturation=3 saturation value
         * @property {number} colorimetric_absolute=4 colorimetric_absolute value
         */
        ripe.Intent = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "source"] = 0;
            values[valuesById[1] = "perceptual"] = 1;
            values[valuesById[2] = "colorimetric_relative"] = 2;
            values[valuesById[3] = "saturation"] = 3;
            values[valuesById[4] = "colorimetric_absolute"] = 4;
            return values;
        })();

        /**
         * Preserve enum.
         * @name appcore.ripe.Preserve
         * @enum {number}
         * @property {number} none=0 none value
         * @property {number} black=1 black value
         * @property {number} black_plane=2 black_plane value
         */
        ripe.Preserve = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "none"] = 0;
            values[valuesById[1] = "black"] = 1;
            values[valuesById[2] = "black_plane"] = 2;
            return values;
        })();

        /**
         * PrintModePCL enum.
         * @name appcore.ripe.PrintModePCL
         * @enum {number}
         * @property {number} continuous=0 continuous value
         * @property {number} single_page=1 single_page value
         * @property {number} single_page_static=2 single_page_static value
         */
        ripe.PrintModePCL = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "continuous"] = 0;
            values[valuesById[1] = "single_page"] = 1;
            values[valuesById[2] = "single_page_static"] = 2;
            return values;
        })();

        ripe.PrintheadConfig = (function() {

            /**
             * Properties of a PrintheadConfig.
             * @memberof appcore.ripe
             * @interface IPrintheadConfig
             * @property {string|null} [guid] PrintheadConfig guid
             * @property {string|null} [name] PrintheadConfig name
             * @property {number|null} [total_print_width_mm] PrintheadConfig total_print_width_mm
             * @property {Array.<appcore.ripe.IPrintheadParams>|null} [head_params] PrintheadConfig head_params
             * @property {number|null} [overlap_dot_pitch] PrintheadConfig overlap_dot_pitch
             * @property {number|null} [stitching_id] PrintheadConfig stitching_id
             * @property {boolean|null} [inch] PrintheadConfig inch
             * @property {number|null} [output_code] PrintheadConfig output_code
             * @property {boolean|null} [printer_output] PrintheadConfig printer_output
             * @property {number|null} [dpi_x] PrintheadConfig dpi_x
             * @property {number|null} [dpi_y] PrintheadConfig dpi_y
             * @property {string|null} [icc_profile_guid] PrintheadConfig icc_profile_guid
             * @property {boolean|null} [use_raw_thumbnail] PrintheadConfig use_raw_thumbnail
             * @property {string|null} [screening_config_guid] PrintheadConfig screening_config_guid
             * @property {boolean|null} [collate] PrintheadConfig collate
             * @property {number|null} [copies] PrintheadConfig copies
             * @property {number|null} [bottom_margin_page] PrintheadConfig bottom_margin_page
             * @property {number|null} [bottom_margin_copy] PrintheadConfig bottom_margin_copy
             * @property {boolean|null} [always_generate_head_width_raster] PrintheadConfig always_generate_head_width_raster
             * @property {Array.<number|Long>|null} [device_link_ids] PrintheadConfig device_link_ids
             * @property {appcore.ripe.Intent|null} [intent] PrintheadConfig intent
             * @property {appcore.ripe.Preserve|null} [preserve] PrintheadConfig preserve
             * @property {string|null} [selected_pages_to_print] PrintheadConfig selected_pages_to_print
             * @property {string|null} [pages] PrintheadConfig pages
             * @property {string|null} [map_oem_label_guid] PrintheadConfig map_oem_label_guid
             * @property {appcore.ripe.PrintModePCL|null} [print_mode_pcl] PrintheadConfig print_mode_pcl
             */

            /**
             * Constructs a new PrintheadConfig.
             * @memberof appcore.ripe
             * @classdesc Represents a PrintheadConfig.
             * @implements IPrintheadConfig
             * @constructor
             * @param {appcore.ripe.IPrintheadConfig=} [properties] Properties to set
             */
            function PrintheadConfig(properties) {
                this.head_params = [];
                this.device_link_ids = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PrintheadConfig guid.
             * @member {string} guid
             * @memberof appcore.ripe.PrintheadConfig
             * @instance
             */
            PrintheadConfig.prototype.guid = "";

            /**
             * PrintheadConfig name.
             * @member {string} name
             * @memberof appcore.ripe.PrintheadConfig
             * @instance
             */
            PrintheadConfig.prototype.name = "";

            /**
             * PrintheadConfig total_print_width_mm.
             * @member {number} total_print_width_mm
             * @memberof appcore.ripe.PrintheadConfig
             * @instance
             */
            PrintheadConfig.prototype.total_print_width_mm = 0;

            /**
             * PrintheadConfig head_params.
             * @member {Array.<appcore.ripe.IPrintheadParams>} head_params
             * @memberof appcore.ripe.PrintheadConfig
             * @instance
             */
            PrintheadConfig.prototype.head_params = $util.emptyArray;

            /**
             * PrintheadConfig overlap_dot_pitch.
             * @member {number} overlap_dot_pitch
             * @memberof appcore.ripe.PrintheadConfig
             * @instance
             */
            PrintheadConfig.prototype.overlap_dot_pitch = 0;

            /**
             * PrintheadConfig stitching_id.
             * @member {number} stitching_id
             * @memberof appcore.ripe.PrintheadConfig
             * @instance
             */
            PrintheadConfig.prototype.stitching_id = 0;

            /**
             * PrintheadConfig inch.
             * @member {boolean} inch
             * @memberof appcore.ripe.PrintheadConfig
             * @instance
             */
            PrintheadConfig.prototype.inch = false;

            /**
             * PrintheadConfig output_code.
             * @member {number} output_code
             * @memberof appcore.ripe.PrintheadConfig
             * @instance
             */
            PrintheadConfig.prototype.output_code = 0;

            /**
             * PrintheadConfig printer_output.
             * @member {boolean} printer_output
             * @memberof appcore.ripe.PrintheadConfig
             * @instance
             */
            PrintheadConfig.prototype.printer_output = false;

            /**
             * PrintheadConfig dpi_x.
             * @member {number} dpi_x
             * @memberof appcore.ripe.PrintheadConfig
             * @instance
             */
            PrintheadConfig.prototype.dpi_x = 0;

            /**
             * PrintheadConfig dpi_y.
             * @member {number} dpi_y
             * @memberof appcore.ripe.PrintheadConfig
             * @instance
             */
            PrintheadConfig.prototype.dpi_y = 0;

            /**
             * PrintheadConfig icc_profile_guid.
             * @member {string} icc_profile_guid
             * @memberof appcore.ripe.PrintheadConfig
             * @instance
             */
            PrintheadConfig.prototype.icc_profile_guid = "";

            /**
             * PrintheadConfig use_raw_thumbnail.
             * @member {boolean} use_raw_thumbnail
             * @memberof appcore.ripe.PrintheadConfig
             * @instance
             */
            PrintheadConfig.prototype.use_raw_thumbnail = false;

            /**
             * PrintheadConfig screening_config_guid.
             * @member {string} screening_config_guid
             * @memberof appcore.ripe.PrintheadConfig
             * @instance
             */
            PrintheadConfig.prototype.screening_config_guid = "";

            /**
             * PrintheadConfig collate.
             * @member {boolean} collate
             * @memberof appcore.ripe.PrintheadConfig
             * @instance
             */
            PrintheadConfig.prototype.collate = false;

            /**
             * PrintheadConfig copies.
             * @member {number} copies
             * @memberof appcore.ripe.PrintheadConfig
             * @instance
             */
            PrintheadConfig.prototype.copies = 0;

            /**
             * PrintheadConfig bottom_margin_page.
             * @member {number} bottom_margin_page
             * @memberof appcore.ripe.PrintheadConfig
             * @instance
             */
            PrintheadConfig.prototype.bottom_margin_page = 0;

            /**
             * PrintheadConfig bottom_margin_copy.
             * @member {number} bottom_margin_copy
             * @memberof appcore.ripe.PrintheadConfig
             * @instance
             */
            PrintheadConfig.prototype.bottom_margin_copy = 0;

            /**
             * PrintheadConfig always_generate_head_width_raster.
             * @member {boolean} always_generate_head_width_raster
             * @memberof appcore.ripe.PrintheadConfig
             * @instance
             */
            PrintheadConfig.prototype.always_generate_head_width_raster = false;

            /**
             * PrintheadConfig device_link_ids.
             * @member {Array.<number|Long>} device_link_ids
             * @memberof appcore.ripe.PrintheadConfig
             * @instance
             */
            PrintheadConfig.prototype.device_link_ids = $util.emptyArray;

            /**
             * PrintheadConfig intent.
             * @member {appcore.ripe.Intent} intent
             * @memberof appcore.ripe.PrintheadConfig
             * @instance
             */
            PrintheadConfig.prototype.intent = 0;

            /**
             * PrintheadConfig preserve.
             * @member {appcore.ripe.Preserve} preserve
             * @memberof appcore.ripe.PrintheadConfig
             * @instance
             */
            PrintheadConfig.prototype.preserve = 0;

            /**
             * PrintheadConfig selected_pages_to_print.
             * @member {string} selected_pages_to_print
             * @memberof appcore.ripe.PrintheadConfig
             * @instance
             */
            PrintheadConfig.prototype.selected_pages_to_print = "";

            /**
             * PrintheadConfig pages.
             * @member {string} pages
             * @memberof appcore.ripe.PrintheadConfig
             * @instance
             */
            PrintheadConfig.prototype.pages = "";

            /**
             * PrintheadConfig map_oem_label_guid.
             * @member {string} map_oem_label_guid
             * @memberof appcore.ripe.PrintheadConfig
             * @instance
             */
            PrintheadConfig.prototype.map_oem_label_guid = "";

            /**
             * PrintheadConfig print_mode_pcl.
             * @member {appcore.ripe.PrintModePCL} print_mode_pcl
             * @memberof appcore.ripe.PrintheadConfig
             * @instance
             */
            PrintheadConfig.prototype.print_mode_pcl = 0;

            /**
             * Creates a new PrintheadConfig instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.PrintheadConfig
             * @static
             * @param {appcore.ripe.IPrintheadConfig=} [properties] Properties to set
             * @returns {appcore.ripe.PrintheadConfig} PrintheadConfig instance
             */
            PrintheadConfig.create = function create(properties) {
                return new PrintheadConfig(properties);
            };

            /**
             * Encodes the specified PrintheadConfig message. Does not implicitly {@link appcore.ripe.PrintheadConfig.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.PrintheadConfig
             * @static
             * @param {appcore.ripe.IPrintheadConfig} message PrintheadConfig message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PrintheadConfig.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.guid != null && Object.hasOwnProperty.call(message, "guid"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.guid);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.total_print_width_mm != null && Object.hasOwnProperty.call(message, "total_print_width_mm"))
                    writer.uint32(/* id 3, wireType 1 =*/25).double(message.total_print_width_mm);
                if (message.head_params != null && message.head_params.length)
                    for (let i = 0; i < message.head_params.length; ++i)
                        $root.appcore.ripe.PrintheadParams.encode(message.head_params[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.overlap_dot_pitch != null && Object.hasOwnProperty.call(message, "overlap_dot_pitch"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.overlap_dot_pitch);
                if (message.stitching_id != null && Object.hasOwnProperty.call(message, "stitching_id"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int32(message.stitching_id);
                if (message.inch != null && Object.hasOwnProperty.call(message, "inch"))
                    writer.uint32(/* id 7, wireType 0 =*/56).bool(message.inch);
                if (message.output_code != null && Object.hasOwnProperty.call(message, "output_code"))
                    writer.uint32(/* id 8, wireType 0 =*/64).int32(message.output_code);
                if (message.printer_output != null && Object.hasOwnProperty.call(message, "printer_output"))
                    writer.uint32(/* id 9, wireType 0 =*/72).bool(message.printer_output);
                if (message.dpi_x != null && Object.hasOwnProperty.call(message, "dpi_x"))
                    writer.uint32(/* id 10, wireType 0 =*/80).int32(message.dpi_x);
                if (message.dpi_y != null && Object.hasOwnProperty.call(message, "dpi_y"))
                    writer.uint32(/* id 11, wireType 0 =*/88).int32(message.dpi_y);
                if (message.icc_profile_guid != null && Object.hasOwnProperty.call(message, "icc_profile_guid"))
                    writer.uint32(/* id 12, wireType 2 =*/98).string(message.icc_profile_guid);
                if (message.use_raw_thumbnail != null && Object.hasOwnProperty.call(message, "use_raw_thumbnail"))
                    writer.uint32(/* id 13, wireType 0 =*/104).bool(message.use_raw_thumbnail);
                if (message.screening_config_guid != null && Object.hasOwnProperty.call(message, "screening_config_guid"))
                    writer.uint32(/* id 14, wireType 2 =*/114).string(message.screening_config_guid);
                if (message.collate != null && Object.hasOwnProperty.call(message, "collate"))
                    writer.uint32(/* id 15, wireType 0 =*/120).bool(message.collate);
                if (message.copies != null && Object.hasOwnProperty.call(message, "copies"))
                    writer.uint32(/* id 16, wireType 0 =*/128).int32(message.copies);
                if (message.bottom_margin_page != null && Object.hasOwnProperty.call(message, "bottom_margin_page"))
                    writer.uint32(/* id 17, wireType 1 =*/137).double(message.bottom_margin_page);
                if (message.bottom_margin_copy != null && Object.hasOwnProperty.call(message, "bottom_margin_copy"))
                    writer.uint32(/* id 18, wireType 1 =*/145).double(message.bottom_margin_copy);
                if (message.always_generate_head_width_raster != null && Object.hasOwnProperty.call(message, "always_generate_head_width_raster"))
                    writer.uint32(/* id 19, wireType 0 =*/152).bool(message.always_generate_head_width_raster);
                if (message.device_link_ids != null && message.device_link_ids.length) {
                    writer.uint32(/* id 20, wireType 2 =*/162).fork();
                    for (let i = 0; i < message.device_link_ids.length; ++i)
                        writer.uint64(message.device_link_ids[i]);
                    writer.ldelim();
                }
                if (message.intent != null && Object.hasOwnProperty.call(message, "intent"))
                    writer.uint32(/* id 21, wireType 0 =*/168).int32(message.intent);
                if (message.preserve != null && Object.hasOwnProperty.call(message, "preserve"))
                    writer.uint32(/* id 22, wireType 0 =*/176).int32(message.preserve);
                if (message.selected_pages_to_print != null && Object.hasOwnProperty.call(message, "selected_pages_to_print"))
                    writer.uint32(/* id 23, wireType 2 =*/186).string(message.selected_pages_to_print);
                if (message.pages != null && Object.hasOwnProperty.call(message, "pages"))
                    writer.uint32(/* id 24, wireType 2 =*/194).string(message.pages);
                if (message.map_oem_label_guid != null && Object.hasOwnProperty.call(message, "map_oem_label_guid"))
                    writer.uint32(/* id 25, wireType 2 =*/202).string(message.map_oem_label_guid);
                if (message.print_mode_pcl != null && Object.hasOwnProperty.call(message, "print_mode_pcl"))
                    writer.uint32(/* id 26, wireType 0 =*/208).int32(message.print_mode_pcl);
                return writer;
            };

            /**
             * Encodes the specified PrintheadConfig message, length delimited. Does not implicitly {@link appcore.ripe.PrintheadConfig.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.PrintheadConfig
             * @static
             * @param {appcore.ripe.IPrintheadConfig} message PrintheadConfig message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PrintheadConfig.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PrintheadConfig message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.PrintheadConfig
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.PrintheadConfig} PrintheadConfig
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PrintheadConfig.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.PrintheadConfig();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.guid = reader.string();
                        break;
                    case 2:
                        message.name = reader.string();
                        break;
                    case 3:
                        message.total_print_width_mm = reader.double();
                        break;
                    case 4:
                        if (!(message.head_params && message.head_params.length))
                            message.head_params = [];
                        message.head_params.push($root.appcore.ripe.PrintheadParams.decode(reader, reader.uint32()));
                        break;
                    case 5:
                        message.overlap_dot_pitch = reader.int32();
                        break;
                    case 6:
                        message.stitching_id = reader.int32();
                        break;
                    case 7:
                        message.inch = reader.bool();
                        break;
                    case 8:
                        message.output_code = reader.int32();
                        break;
                    case 9:
                        message.printer_output = reader.bool();
                        break;
                    case 10:
                        message.dpi_x = reader.int32();
                        break;
                    case 11:
                        message.dpi_y = reader.int32();
                        break;
                    case 12:
                        message.icc_profile_guid = reader.string();
                        break;
                    case 13:
                        message.use_raw_thumbnail = reader.bool();
                        break;
                    case 14:
                        message.screening_config_guid = reader.string();
                        break;
                    case 15:
                        message.collate = reader.bool();
                        break;
                    case 16:
                        message.copies = reader.int32();
                        break;
                    case 17:
                        message.bottom_margin_page = reader.double();
                        break;
                    case 18:
                        message.bottom_margin_copy = reader.double();
                        break;
                    case 19:
                        message.always_generate_head_width_raster = reader.bool();
                        break;
                    case 20:
                        if (!(message.device_link_ids && message.device_link_ids.length))
                            message.device_link_ids = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.device_link_ids.push(reader.uint64());
                        } else
                            message.device_link_ids.push(reader.uint64());
                        break;
                    case 21:
                        message.intent = reader.int32();
                        break;
                    case 22:
                        message.preserve = reader.int32();
                        break;
                    case 23:
                        message.selected_pages_to_print = reader.string();
                        break;
                    case 24:
                        message.pages = reader.string();
                        break;
                    case 25:
                        message.map_oem_label_guid = reader.string();
                        break;
                    case 26:
                        message.print_mode_pcl = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PrintheadConfig message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.PrintheadConfig
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.PrintheadConfig} PrintheadConfig
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PrintheadConfig.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PrintheadConfig message.
             * @function verify
             * @memberof appcore.ripe.PrintheadConfig
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PrintheadConfig.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.guid != null && message.hasOwnProperty("guid"))
                    if (!$util.isString(message.guid))
                        return "guid: string expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.total_print_width_mm != null && message.hasOwnProperty("total_print_width_mm"))
                    if (typeof message.total_print_width_mm !== "number")
                        return "total_print_width_mm: number expected";
                if (message.head_params != null && message.hasOwnProperty("head_params")) {
                    if (!Array.isArray(message.head_params))
                        return "head_params: array expected";
                    for (let i = 0; i < message.head_params.length; ++i) {
                        let error = $root.appcore.ripe.PrintheadParams.verify(message.head_params[i]);
                        if (error)
                            return "head_params." + error;
                    }
                }
                if (message.overlap_dot_pitch != null && message.hasOwnProperty("overlap_dot_pitch"))
                    if (!$util.isInteger(message.overlap_dot_pitch))
                        return "overlap_dot_pitch: integer expected";
                if (message.stitching_id != null && message.hasOwnProperty("stitching_id"))
                    if (!$util.isInteger(message.stitching_id))
                        return "stitching_id: integer expected";
                if (message.inch != null && message.hasOwnProperty("inch"))
                    if (typeof message.inch !== "boolean")
                        return "inch: boolean expected";
                if (message.output_code != null && message.hasOwnProperty("output_code"))
                    if (!$util.isInteger(message.output_code))
                        return "output_code: integer expected";
                if (message.printer_output != null && message.hasOwnProperty("printer_output"))
                    if (typeof message.printer_output !== "boolean")
                        return "printer_output: boolean expected";
                if (message.dpi_x != null && message.hasOwnProperty("dpi_x"))
                    if (!$util.isInteger(message.dpi_x))
                        return "dpi_x: integer expected";
                if (message.dpi_y != null && message.hasOwnProperty("dpi_y"))
                    if (!$util.isInteger(message.dpi_y))
                        return "dpi_y: integer expected";
                if (message.icc_profile_guid != null && message.hasOwnProperty("icc_profile_guid"))
                    if (!$util.isString(message.icc_profile_guid))
                        return "icc_profile_guid: string expected";
                if (message.use_raw_thumbnail != null && message.hasOwnProperty("use_raw_thumbnail"))
                    if (typeof message.use_raw_thumbnail !== "boolean")
                        return "use_raw_thumbnail: boolean expected";
                if (message.screening_config_guid != null && message.hasOwnProperty("screening_config_guid"))
                    if (!$util.isString(message.screening_config_guid))
                        return "screening_config_guid: string expected";
                if (message.collate != null && message.hasOwnProperty("collate"))
                    if (typeof message.collate !== "boolean")
                        return "collate: boolean expected";
                if (message.copies != null && message.hasOwnProperty("copies"))
                    if (!$util.isInteger(message.copies))
                        return "copies: integer expected";
                if (message.bottom_margin_page != null && message.hasOwnProperty("bottom_margin_page"))
                    if (typeof message.bottom_margin_page !== "number")
                        return "bottom_margin_page: number expected";
                if (message.bottom_margin_copy != null && message.hasOwnProperty("bottom_margin_copy"))
                    if (typeof message.bottom_margin_copy !== "number")
                        return "bottom_margin_copy: number expected";
                if (message.always_generate_head_width_raster != null && message.hasOwnProperty("always_generate_head_width_raster"))
                    if (typeof message.always_generate_head_width_raster !== "boolean")
                        return "always_generate_head_width_raster: boolean expected";
                if (message.device_link_ids != null && message.hasOwnProperty("device_link_ids")) {
                    if (!Array.isArray(message.device_link_ids))
                        return "device_link_ids: array expected";
                    for (let i = 0; i < message.device_link_ids.length; ++i)
                        if (!$util.isInteger(message.device_link_ids[i]) && !(message.device_link_ids[i] && $util.isInteger(message.device_link_ids[i].low) && $util.isInteger(message.device_link_ids[i].high)))
                            return "device_link_ids: integer|Long[] expected";
                }
                if (message.intent != null && message.hasOwnProperty("intent"))
                    switch (message.intent) {
                    default:
                        return "intent: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        break;
                    }
                if (message.preserve != null && message.hasOwnProperty("preserve"))
                    switch (message.preserve) {
                    default:
                        return "preserve: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.selected_pages_to_print != null && message.hasOwnProperty("selected_pages_to_print"))
                    if (!$util.isString(message.selected_pages_to_print))
                        return "selected_pages_to_print: string expected";
                if (message.pages != null && message.hasOwnProperty("pages"))
                    if (!$util.isString(message.pages))
                        return "pages: string expected";
                if (message.map_oem_label_guid != null && message.hasOwnProperty("map_oem_label_guid"))
                    if (!$util.isString(message.map_oem_label_guid))
                        return "map_oem_label_guid: string expected";
                if (message.print_mode_pcl != null && message.hasOwnProperty("print_mode_pcl"))
                    switch (message.print_mode_pcl) {
                    default:
                        return "print_mode_pcl: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                return null;
            };

            /**
             * Creates a PrintheadConfig message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.PrintheadConfig
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.PrintheadConfig} PrintheadConfig
             */
            PrintheadConfig.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.PrintheadConfig)
                    return object;
                let message = new $root.appcore.ripe.PrintheadConfig();
                if (object.guid != null)
                    message.guid = String(object.guid);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.total_print_width_mm != null)
                    message.total_print_width_mm = Number(object.total_print_width_mm);
                if (object.head_params) {
                    if (!Array.isArray(object.head_params))
                        throw TypeError(".appcore.ripe.PrintheadConfig.head_params: array expected");
                    message.head_params = [];
                    for (let i = 0; i < object.head_params.length; ++i) {
                        if (typeof object.head_params[i] !== "object")
                            throw TypeError(".appcore.ripe.PrintheadConfig.head_params: object expected");
                        message.head_params[i] = $root.appcore.ripe.PrintheadParams.fromObject(object.head_params[i]);
                    }
                }
                if (object.overlap_dot_pitch != null)
                    message.overlap_dot_pitch = object.overlap_dot_pitch | 0;
                if (object.stitching_id != null)
                    message.stitching_id = object.stitching_id | 0;
                if (object.inch != null)
                    message.inch = Boolean(object.inch);
                if (object.output_code != null)
                    message.output_code = object.output_code | 0;
                if (object.printer_output != null)
                    message.printer_output = Boolean(object.printer_output);
                if (object.dpi_x != null)
                    message.dpi_x = object.dpi_x | 0;
                if (object.dpi_y != null)
                    message.dpi_y = object.dpi_y | 0;
                if (object.icc_profile_guid != null)
                    message.icc_profile_guid = String(object.icc_profile_guid);
                if (object.use_raw_thumbnail != null)
                    message.use_raw_thumbnail = Boolean(object.use_raw_thumbnail);
                if (object.screening_config_guid != null)
                    message.screening_config_guid = String(object.screening_config_guid);
                if (object.collate != null)
                    message.collate = Boolean(object.collate);
                if (object.copies != null)
                    message.copies = object.copies | 0;
                if (object.bottom_margin_page != null)
                    message.bottom_margin_page = Number(object.bottom_margin_page);
                if (object.bottom_margin_copy != null)
                    message.bottom_margin_copy = Number(object.bottom_margin_copy);
                if (object.always_generate_head_width_raster != null)
                    message.always_generate_head_width_raster = Boolean(object.always_generate_head_width_raster);
                if (object.device_link_ids) {
                    if (!Array.isArray(object.device_link_ids))
                        throw TypeError(".appcore.ripe.PrintheadConfig.device_link_ids: array expected");
                    message.device_link_ids = [];
                    for (let i = 0; i < object.device_link_ids.length; ++i)
                        if ($util.Long)
                            (message.device_link_ids[i] = $util.Long.fromValue(object.device_link_ids[i])).unsigned = true;
                        else if (typeof object.device_link_ids[i] === "string")
                            message.device_link_ids[i] = parseInt(object.device_link_ids[i], 10);
                        else if (typeof object.device_link_ids[i] === "number")
                            message.device_link_ids[i] = object.device_link_ids[i];
                        else if (typeof object.device_link_ids[i] === "object")
                            message.device_link_ids[i] = new $util.LongBits(object.device_link_ids[i].low >>> 0, object.device_link_ids[i].high >>> 0).toNumber(true);
                }
                switch (object.intent) {
                case "source":
                case 0:
                    message.intent = 0;
                    break;
                case "perceptual":
                case 1:
                    message.intent = 1;
                    break;
                case "colorimetric_relative":
                case 2:
                    message.intent = 2;
                    break;
                case "saturation":
                case 3:
                    message.intent = 3;
                    break;
                case "colorimetric_absolute":
                case 4:
                    message.intent = 4;
                    break;
                }
                switch (object.preserve) {
                case "none":
                case 0:
                    message.preserve = 0;
                    break;
                case "black":
                case 1:
                    message.preserve = 1;
                    break;
                case "black_plane":
                case 2:
                    message.preserve = 2;
                    break;
                }
                if (object.selected_pages_to_print != null)
                    message.selected_pages_to_print = String(object.selected_pages_to_print);
                if (object.pages != null)
                    message.pages = String(object.pages);
                if (object.map_oem_label_guid != null)
                    message.map_oem_label_guid = String(object.map_oem_label_guid);
                switch (object.print_mode_pcl) {
                case "continuous":
                case 0:
                    message.print_mode_pcl = 0;
                    break;
                case "single_page":
                case 1:
                    message.print_mode_pcl = 1;
                    break;
                case "single_page_static":
                case 2:
                    message.print_mode_pcl = 2;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a PrintheadConfig message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.PrintheadConfig
             * @static
             * @param {appcore.ripe.PrintheadConfig} message PrintheadConfig
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PrintheadConfig.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults) {
                    object.head_params = [];
                    object.device_link_ids = [];
                }
                if (options.defaults) {
                    object.guid = "";
                    object.name = "";
                    object.total_print_width_mm = 0;
                    object.overlap_dot_pitch = 0;
                    object.stitching_id = 0;
                    object.inch = false;
                    object.output_code = 0;
                    object.printer_output = false;
                    object.dpi_x = 0;
                    object.dpi_y = 0;
                    object.icc_profile_guid = "";
                    object.use_raw_thumbnail = false;
                    object.screening_config_guid = "";
                    object.collate = false;
                    object.copies = 0;
                    object.bottom_margin_page = 0;
                    object.bottom_margin_copy = 0;
                    object.always_generate_head_width_raster = false;
                    object.intent = options.enums === String ? "source" : 0;
                    object.preserve = options.enums === String ? "none" : 0;
                    object.selected_pages_to_print = "";
                    object.pages = "";
                    object.map_oem_label_guid = "";
                    object.print_mode_pcl = options.enums === String ? "continuous" : 0;
                }
                if (message.guid != null && message.hasOwnProperty("guid"))
                    object.guid = message.guid;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.total_print_width_mm != null && message.hasOwnProperty("total_print_width_mm"))
                    object.total_print_width_mm = options.json && !isFinite(message.total_print_width_mm) ? String(message.total_print_width_mm) : message.total_print_width_mm;
                if (message.head_params && message.head_params.length) {
                    object.head_params = [];
                    for (let j = 0; j < message.head_params.length; ++j)
                        object.head_params[j] = $root.appcore.ripe.PrintheadParams.toObject(message.head_params[j], options);
                }
                if (message.overlap_dot_pitch != null && message.hasOwnProperty("overlap_dot_pitch"))
                    object.overlap_dot_pitch = message.overlap_dot_pitch;
                if (message.stitching_id != null && message.hasOwnProperty("stitching_id"))
                    object.stitching_id = message.stitching_id;
                if (message.inch != null && message.hasOwnProperty("inch"))
                    object.inch = message.inch;
                if (message.output_code != null && message.hasOwnProperty("output_code"))
                    object.output_code = message.output_code;
                if (message.printer_output != null && message.hasOwnProperty("printer_output"))
                    object.printer_output = message.printer_output;
                if (message.dpi_x != null && message.hasOwnProperty("dpi_x"))
                    object.dpi_x = message.dpi_x;
                if (message.dpi_y != null && message.hasOwnProperty("dpi_y"))
                    object.dpi_y = message.dpi_y;
                if (message.icc_profile_guid != null && message.hasOwnProperty("icc_profile_guid"))
                    object.icc_profile_guid = message.icc_profile_guid;
                if (message.use_raw_thumbnail != null && message.hasOwnProperty("use_raw_thumbnail"))
                    object.use_raw_thumbnail = message.use_raw_thumbnail;
                if (message.screening_config_guid != null && message.hasOwnProperty("screening_config_guid"))
                    object.screening_config_guid = message.screening_config_guid;
                if (message.collate != null && message.hasOwnProperty("collate"))
                    object.collate = message.collate;
                if (message.copies != null && message.hasOwnProperty("copies"))
                    object.copies = message.copies;
                if (message.bottom_margin_page != null && message.hasOwnProperty("bottom_margin_page"))
                    object.bottom_margin_page = options.json && !isFinite(message.bottom_margin_page) ? String(message.bottom_margin_page) : message.bottom_margin_page;
                if (message.bottom_margin_copy != null && message.hasOwnProperty("bottom_margin_copy"))
                    object.bottom_margin_copy = options.json && !isFinite(message.bottom_margin_copy) ? String(message.bottom_margin_copy) : message.bottom_margin_copy;
                if (message.always_generate_head_width_raster != null && message.hasOwnProperty("always_generate_head_width_raster"))
                    object.always_generate_head_width_raster = message.always_generate_head_width_raster;
                if (message.device_link_ids && message.device_link_ids.length) {
                    object.device_link_ids = [];
                    for (let j = 0; j < message.device_link_ids.length; ++j)
                        if (typeof message.device_link_ids[j] === "number")
                            object.device_link_ids[j] = options.longs === String ? String(message.device_link_ids[j]) : message.device_link_ids[j];
                        else
                            object.device_link_ids[j] = options.longs === String ? $util.Long.prototype.toString.call(message.device_link_ids[j]) : options.longs === Number ? new $util.LongBits(message.device_link_ids[j].low >>> 0, message.device_link_ids[j].high >>> 0).toNumber(true) : message.device_link_ids[j];
                }
                if (message.intent != null && message.hasOwnProperty("intent"))
                    object.intent = options.enums === String ? $root.appcore.ripe.Intent[message.intent] : message.intent;
                if (message.preserve != null && message.hasOwnProperty("preserve"))
                    object.preserve = options.enums === String ? $root.appcore.ripe.Preserve[message.preserve] : message.preserve;
                if (message.selected_pages_to_print != null && message.hasOwnProperty("selected_pages_to_print"))
                    object.selected_pages_to_print = message.selected_pages_to_print;
                if (message.pages != null && message.hasOwnProperty("pages"))
                    object.pages = message.pages;
                if (message.map_oem_label_guid != null && message.hasOwnProperty("map_oem_label_guid"))
                    object.map_oem_label_guid = message.map_oem_label_guid;
                if (message.print_mode_pcl != null && message.hasOwnProperty("print_mode_pcl"))
                    object.print_mode_pcl = options.enums === String ? $root.appcore.ripe.PrintModePCL[message.print_mode_pcl] : message.print_mode_pcl;
                return object;
            };

            /**
             * Converts this PrintheadConfig to JSON.
             * @function toJSON
             * @memberof appcore.ripe.PrintheadConfig
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PrintheadConfig.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return PrintheadConfig;
        })();

        ripe.PrintheadParams = (function() {

            /**
             * Properties of a PrintheadParams.
             * @memberof appcore.ripe
             * @interface IPrintheadParams
             * @property {boolean|null} [enabled] PrintheadParams enabled
             * @property {number|null} [left_mm] PrintheadParams left_mm
             * @property {number|null} [right_mm] PrintheadParams right_mm
             * @property {number|null} [print_width_mm] PrintheadParams print_width_mm
             */

            /**
             * Constructs a new PrintheadParams.
             * @memberof appcore.ripe
             * @classdesc Represents a PrintheadParams.
             * @implements IPrintheadParams
             * @constructor
             * @param {appcore.ripe.IPrintheadParams=} [properties] Properties to set
             */
            function PrintheadParams(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PrintheadParams enabled.
             * @member {boolean} enabled
             * @memberof appcore.ripe.PrintheadParams
             * @instance
             */
            PrintheadParams.prototype.enabled = false;

            /**
             * PrintheadParams left_mm.
             * @member {number} left_mm
             * @memberof appcore.ripe.PrintheadParams
             * @instance
             */
            PrintheadParams.prototype.left_mm = 0;

            /**
             * PrintheadParams right_mm.
             * @member {number} right_mm
             * @memberof appcore.ripe.PrintheadParams
             * @instance
             */
            PrintheadParams.prototype.right_mm = 0;

            /**
             * PrintheadParams print_width_mm.
             * @member {number} print_width_mm
             * @memberof appcore.ripe.PrintheadParams
             * @instance
             */
            PrintheadParams.prototype.print_width_mm = 0;

            /**
             * Creates a new PrintheadParams instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.PrintheadParams
             * @static
             * @param {appcore.ripe.IPrintheadParams=} [properties] Properties to set
             * @returns {appcore.ripe.PrintheadParams} PrintheadParams instance
             */
            PrintheadParams.create = function create(properties) {
                return new PrintheadParams(properties);
            };

            /**
             * Encodes the specified PrintheadParams message. Does not implicitly {@link appcore.ripe.PrintheadParams.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.PrintheadParams
             * @static
             * @param {appcore.ripe.IPrintheadParams} message PrintheadParams message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PrintheadParams.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.enabled != null && Object.hasOwnProperty.call(message, "enabled"))
                    writer.uint32(/* id 1, wireType 0 =*/8).bool(message.enabled);
                if (message.left_mm != null && Object.hasOwnProperty.call(message, "left_mm"))
                    writer.uint32(/* id 2, wireType 1 =*/17).double(message.left_mm);
                if (message.right_mm != null && Object.hasOwnProperty.call(message, "right_mm"))
                    writer.uint32(/* id 3, wireType 1 =*/25).double(message.right_mm);
                if (message.print_width_mm != null && Object.hasOwnProperty.call(message, "print_width_mm"))
                    writer.uint32(/* id 4, wireType 1 =*/33).double(message.print_width_mm);
                return writer;
            };

            /**
             * Encodes the specified PrintheadParams message, length delimited. Does not implicitly {@link appcore.ripe.PrintheadParams.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.PrintheadParams
             * @static
             * @param {appcore.ripe.IPrintheadParams} message PrintheadParams message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PrintheadParams.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PrintheadParams message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.PrintheadParams
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.PrintheadParams} PrintheadParams
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PrintheadParams.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.PrintheadParams();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.enabled = reader.bool();
                        break;
                    case 2:
                        message.left_mm = reader.double();
                        break;
                    case 3:
                        message.right_mm = reader.double();
                        break;
                    case 4:
                        message.print_width_mm = reader.double();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PrintheadParams message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.PrintheadParams
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.PrintheadParams} PrintheadParams
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PrintheadParams.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PrintheadParams message.
             * @function verify
             * @memberof appcore.ripe.PrintheadParams
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PrintheadParams.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.enabled != null && message.hasOwnProperty("enabled"))
                    if (typeof message.enabled !== "boolean")
                        return "enabled: boolean expected";
                if (message.left_mm != null && message.hasOwnProperty("left_mm"))
                    if (typeof message.left_mm !== "number")
                        return "left_mm: number expected";
                if (message.right_mm != null && message.hasOwnProperty("right_mm"))
                    if (typeof message.right_mm !== "number")
                        return "right_mm: number expected";
                if (message.print_width_mm != null && message.hasOwnProperty("print_width_mm"))
                    if (typeof message.print_width_mm !== "number")
                        return "print_width_mm: number expected";
                return null;
            };

            /**
             * Creates a PrintheadParams message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.PrintheadParams
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.PrintheadParams} PrintheadParams
             */
            PrintheadParams.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.PrintheadParams)
                    return object;
                let message = new $root.appcore.ripe.PrintheadParams();
                if (object.enabled != null)
                    message.enabled = Boolean(object.enabled);
                if (object.left_mm != null)
                    message.left_mm = Number(object.left_mm);
                if (object.right_mm != null)
                    message.right_mm = Number(object.right_mm);
                if (object.print_width_mm != null)
                    message.print_width_mm = Number(object.print_width_mm);
                return message;
            };

            /**
             * Creates a plain object from a PrintheadParams message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.PrintheadParams
             * @static
             * @param {appcore.ripe.PrintheadParams} message PrintheadParams
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PrintheadParams.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.enabled = false;
                    object.left_mm = 0;
                    object.right_mm = 0;
                    object.print_width_mm = 0;
                }
                if (message.enabled != null && message.hasOwnProperty("enabled"))
                    object.enabled = message.enabled;
                if (message.left_mm != null && message.hasOwnProperty("left_mm"))
                    object.left_mm = options.json && !isFinite(message.left_mm) ? String(message.left_mm) : message.left_mm;
                if (message.right_mm != null && message.hasOwnProperty("right_mm"))
                    object.right_mm = options.json && !isFinite(message.right_mm) ? String(message.right_mm) : message.right_mm;
                if (message.print_width_mm != null && message.hasOwnProperty("print_width_mm"))
                    object.print_width_mm = options.json && !isFinite(message.print_width_mm) ? String(message.print_width_mm) : message.print_width_mm;
                return object;
            };

            /**
             * Converts this PrintheadParams to JSON.
             * @function toJSON
             * @memberof appcore.ripe.PrintheadParams
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PrintheadParams.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return PrintheadParams;
        })();

        ripe.Printers = (function() {

            /**
             * Properties of a Printers.
             * @memberof appcore.ripe
             * @interface IPrinters
             * @property {Array.<appcore.ripe.IPrinter>|null} [printers] Printers printers
             * @property {Array.<string>|null} [server_ips] Printers server_ips
             * @property {string|null} [default_printer_server_ip] Printers default_printer_server_ip
             */

            /**
             * Constructs a new Printers.
             * @memberof appcore.ripe
             * @classdesc Represents a Printers.
             * @implements IPrinters
             * @constructor
             * @param {appcore.ripe.IPrinters=} [properties] Properties to set
             */
            function Printers(properties) {
                this.printers = [];
                this.server_ips = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Printers printers.
             * @member {Array.<appcore.ripe.IPrinter>} printers
             * @memberof appcore.ripe.Printers
             * @instance
             */
            Printers.prototype.printers = $util.emptyArray;

            /**
             * Printers server_ips.
             * @member {Array.<string>} server_ips
             * @memberof appcore.ripe.Printers
             * @instance
             */
            Printers.prototype.server_ips = $util.emptyArray;

            /**
             * Printers default_printer_server_ip.
             * @member {string} default_printer_server_ip
             * @memberof appcore.ripe.Printers
             * @instance
             */
            Printers.prototype.default_printer_server_ip = "";

            /**
             * Creates a new Printers instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.Printers
             * @static
             * @param {appcore.ripe.IPrinters=} [properties] Properties to set
             * @returns {appcore.ripe.Printers} Printers instance
             */
            Printers.create = function create(properties) {
                return new Printers(properties);
            };

            /**
             * Encodes the specified Printers message. Does not implicitly {@link appcore.ripe.Printers.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.Printers
             * @static
             * @param {appcore.ripe.IPrinters} message Printers message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Printers.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.printers != null && message.printers.length)
                    for (let i = 0; i < message.printers.length; ++i)
                        $root.appcore.ripe.Printer.encode(message.printers[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.server_ips != null && message.server_ips.length)
                    for (let i = 0; i < message.server_ips.length; ++i)
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.server_ips[i]);
                if (message.default_printer_server_ip != null && Object.hasOwnProperty.call(message, "default_printer_server_ip"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.default_printer_server_ip);
                return writer;
            };

            /**
             * Encodes the specified Printers message, length delimited. Does not implicitly {@link appcore.ripe.Printers.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.Printers
             * @static
             * @param {appcore.ripe.IPrinters} message Printers message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Printers.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Printers message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.Printers
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.Printers} Printers
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Printers.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.Printers();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.printers && message.printers.length))
                            message.printers = [];
                        message.printers.push($root.appcore.ripe.Printer.decode(reader, reader.uint32()));
                        break;
                    case 2:
                        if (!(message.server_ips && message.server_ips.length))
                            message.server_ips = [];
                        message.server_ips.push(reader.string());
                        break;
                    case 3:
                        message.default_printer_server_ip = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Printers message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.Printers
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.Printers} Printers
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Printers.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Printers message.
             * @function verify
             * @memberof appcore.ripe.Printers
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Printers.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.printers != null && message.hasOwnProperty("printers")) {
                    if (!Array.isArray(message.printers))
                        return "printers: array expected";
                    for (let i = 0; i < message.printers.length; ++i) {
                        let error = $root.appcore.ripe.Printer.verify(message.printers[i]);
                        if (error)
                            return "printers." + error;
                    }
                }
                if (message.server_ips != null && message.hasOwnProperty("server_ips")) {
                    if (!Array.isArray(message.server_ips))
                        return "server_ips: array expected";
                    for (let i = 0; i < message.server_ips.length; ++i)
                        if (!$util.isString(message.server_ips[i]))
                            return "server_ips: string[] expected";
                }
                if (message.default_printer_server_ip != null && message.hasOwnProperty("default_printer_server_ip"))
                    if (!$util.isString(message.default_printer_server_ip))
                        return "default_printer_server_ip: string expected";
                return null;
            };

            /**
             * Creates a Printers message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.Printers
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.Printers} Printers
             */
            Printers.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.Printers)
                    return object;
                let message = new $root.appcore.ripe.Printers();
                if (object.printers) {
                    if (!Array.isArray(object.printers))
                        throw TypeError(".appcore.ripe.Printers.printers: array expected");
                    message.printers = [];
                    for (let i = 0; i < object.printers.length; ++i) {
                        if (typeof object.printers[i] !== "object")
                            throw TypeError(".appcore.ripe.Printers.printers: object expected");
                        message.printers[i] = $root.appcore.ripe.Printer.fromObject(object.printers[i]);
                    }
                }
                if (object.server_ips) {
                    if (!Array.isArray(object.server_ips))
                        throw TypeError(".appcore.ripe.Printers.server_ips: array expected");
                    message.server_ips = [];
                    for (let i = 0; i < object.server_ips.length; ++i)
                        message.server_ips[i] = String(object.server_ips[i]);
                }
                if (object.default_printer_server_ip != null)
                    message.default_printer_server_ip = String(object.default_printer_server_ip);
                return message;
            };

            /**
             * Creates a plain object from a Printers message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.Printers
             * @static
             * @param {appcore.ripe.Printers} message Printers
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Printers.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults) {
                    object.printers = [];
                    object.server_ips = [];
                }
                if (options.defaults)
                    object.default_printer_server_ip = "";
                if (message.printers && message.printers.length) {
                    object.printers = [];
                    for (let j = 0; j < message.printers.length; ++j)
                        object.printers[j] = $root.appcore.ripe.Printer.toObject(message.printers[j], options);
                }
                if (message.server_ips && message.server_ips.length) {
                    object.server_ips = [];
                    for (let j = 0; j < message.server_ips.length; ++j)
                        object.server_ips[j] = message.server_ips[j];
                }
                if (message.default_printer_server_ip != null && message.hasOwnProperty("default_printer_server_ip"))
                    object.default_printer_server_ip = message.default_printer_server_ip;
                return object;
            };

            /**
             * Converts this Printers to JSON.
             * @function toJSON
             * @memberof appcore.ripe.Printers
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Printers.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Printers;
        })();

        ripe.Printer = (function() {

            /**
             * Properties of a Printer.
             * @memberof appcore.ripe
             * @interface IPrinter
             * @property {number|Long|null} [id] Printer id
             * @property {string|null} [name] Printer name
             * @property {string|null} [output_name] Printer output_name
             * @property {number|null} [output_code] Printer output_code
             * @property {boolean|null} [online] Printer online
             * @property {string|null} [server_ip] Printer server_ip
             */

            /**
             * Constructs a new Printer.
             * @memberof appcore.ripe
             * @classdesc Represents a Printer.
             * @implements IPrinter
             * @constructor
             * @param {appcore.ripe.IPrinter=} [properties] Properties to set
             */
            function Printer(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Printer id.
             * @member {number|Long} id
             * @memberof appcore.ripe.Printer
             * @instance
             */
            Printer.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Printer name.
             * @member {string} name
             * @memberof appcore.ripe.Printer
             * @instance
             */
            Printer.prototype.name = "";

            /**
             * Printer output_name.
             * @member {string} output_name
             * @memberof appcore.ripe.Printer
             * @instance
             */
            Printer.prototype.output_name = "";

            /**
             * Printer output_code.
             * @member {number} output_code
             * @memberof appcore.ripe.Printer
             * @instance
             */
            Printer.prototype.output_code = 0;

            /**
             * Printer online.
             * @member {boolean} online
             * @memberof appcore.ripe.Printer
             * @instance
             */
            Printer.prototype.online = false;

            /**
             * Printer server_ip.
             * @member {string} server_ip
             * @memberof appcore.ripe.Printer
             * @instance
             */
            Printer.prototype.server_ip = "";

            /**
             * Creates a new Printer instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.Printer
             * @static
             * @param {appcore.ripe.IPrinter=} [properties] Properties to set
             * @returns {appcore.ripe.Printer} Printer instance
             */
            Printer.create = function create(properties) {
                return new Printer(properties);
            };

            /**
             * Encodes the specified Printer message. Does not implicitly {@link appcore.ripe.Printer.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.Printer
             * @static
             * @param {appcore.ripe.IPrinter} message Printer message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Printer.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.id);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.output_name != null && Object.hasOwnProperty.call(message, "output_name"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.output_name);
                if (message.output_code != null && Object.hasOwnProperty.call(message, "output_code"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.output_code);
                if (message.online != null && Object.hasOwnProperty.call(message, "online"))
                    writer.uint32(/* id 5, wireType 0 =*/40).bool(message.online);
                if (message.server_ip != null && Object.hasOwnProperty.call(message, "server_ip"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.server_ip);
                return writer;
            };

            /**
             * Encodes the specified Printer message, length delimited. Does not implicitly {@link appcore.ripe.Printer.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.Printer
             * @static
             * @param {appcore.ripe.IPrinter} message Printer message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Printer.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Printer message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.Printer
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.Printer} Printer
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Printer.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.Printer();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.uint64();
                        break;
                    case 2:
                        message.name = reader.string();
                        break;
                    case 3:
                        message.output_name = reader.string();
                        break;
                    case 4:
                        message.output_code = reader.int32();
                        break;
                    case 5:
                        message.online = reader.bool();
                        break;
                    case 6:
                        message.server_ip = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Printer message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.Printer
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.Printer} Printer
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Printer.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Printer message.
             * @function verify
             * @memberof appcore.ripe.Printer
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Printer.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.output_name != null && message.hasOwnProperty("output_name"))
                    if (!$util.isString(message.output_name))
                        return "output_name: string expected";
                if (message.output_code != null && message.hasOwnProperty("output_code"))
                    if (!$util.isInteger(message.output_code))
                        return "output_code: integer expected";
                if (message.online != null && message.hasOwnProperty("online"))
                    if (typeof message.online !== "boolean")
                        return "online: boolean expected";
                if (message.server_ip != null && message.hasOwnProperty("server_ip"))
                    if (!$util.isString(message.server_ip))
                        return "server_ip: string expected";
                return null;
            };

            /**
             * Creates a Printer message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.Printer
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.Printer} Printer
             */
            Printer.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.Printer)
                    return object;
                let message = new $root.appcore.ripe.Printer();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.output_name != null)
                    message.output_name = String(object.output_name);
                if (object.output_code != null)
                    message.output_code = object.output_code | 0;
                if (object.online != null)
                    message.online = Boolean(object.online);
                if (object.server_ip != null)
                    message.server_ip = String(object.server_ip);
                return message;
            };

            /**
             * Creates a plain object from a Printer message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.Printer
             * @static
             * @param {appcore.ripe.Printer} message Printer
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Printer.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                    object.name = "";
                    object.output_name = "";
                    object.output_code = 0;
                    object.online = false;
                    object.server_ip = "";
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.output_name != null && message.hasOwnProperty("output_name"))
                    object.output_name = message.output_name;
                if (message.output_code != null && message.hasOwnProperty("output_code"))
                    object.output_code = message.output_code;
                if (message.online != null && message.hasOwnProperty("online"))
                    object.online = message.online;
                if (message.server_ip != null && message.hasOwnProperty("server_ip"))
                    object.server_ip = message.server_ip;
                return object;
            };

            /**
             * Converts this Printer to JSON.
             * @function toJSON
             * @memberof appcore.ripe.Printer
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Printer.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Printer;
        })();

        ripe.ScreeningConfigs = (function() {

            /**
             * Properties of a ScreeningConfigs.
             * @memberof appcore.ripe
             * @interface IScreeningConfigs
             * @property {Array.<appcore.ripe.IScreeningConfig>|null} [config] ScreeningConfigs config
             */

            /**
             * Constructs a new ScreeningConfigs.
             * @memberof appcore.ripe
             * @classdesc Represents a ScreeningConfigs.
             * @implements IScreeningConfigs
             * @constructor
             * @param {appcore.ripe.IScreeningConfigs=} [properties] Properties to set
             */
            function ScreeningConfigs(properties) {
                this.config = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ScreeningConfigs config.
             * @member {Array.<appcore.ripe.IScreeningConfig>} config
             * @memberof appcore.ripe.ScreeningConfigs
             * @instance
             */
            ScreeningConfigs.prototype.config = $util.emptyArray;

            /**
             * Creates a new ScreeningConfigs instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.ScreeningConfigs
             * @static
             * @param {appcore.ripe.IScreeningConfigs=} [properties] Properties to set
             * @returns {appcore.ripe.ScreeningConfigs} ScreeningConfigs instance
             */
            ScreeningConfigs.create = function create(properties) {
                return new ScreeningConfigs(properties);
            };

            /**
             * Encodes the specified ScreeningConfigs message. Does not implicitly {@link appcore.ripe.ScreeningConfigs.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.ScreeningConfigs
             * @static
             * @param {appcore.ripe.IScreeningConfigs} message ScreeningConfigs message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ScreeningConfigs.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.config != null && message.config.length)
                    for (let i = 0; i < message.config.length; ++i)
                        $root.appcore.ripe.ScreeningConfig.encode(message.config[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified ScreeningConfigs message, length delimited. Does not implicitly {@link appcore.ripe.ScreeningConfigs.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.ScreeningConfigs
             * @static
             * @param {appcore.ripe.IScreeningConfigs} message ScreeningConfigs message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ScreeningConfigs.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ScreeningConfigs message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.ScreeningConfigs
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.ScreeningConfigs} ScreeningConfigs
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ScreeningConfigs.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.ScreeningConfigs();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.config && message.config.length))
                            message.config = [];
                        message.config.push($root.appcore.ripe.ScreeningConfig.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ScreeningConfigs message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.ScreeningConfigs
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.ScreeningConfigs} ScreeningConfigs
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ScreeningConfigs.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ScreeningConfigs message.
             * @function verify
             * @memberof appcore.ripe.ScreeningConfigs
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ScreeningConfigs.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.config != null && message.hasOwnProperty("config")) {
                    if (!Array.isArray(message.config))
                        return "config: array expected";
                    for (let i = 0; i < message.config.length; ++i) {
                        let error = $root.appcore.ripe.ScreeningConfig.verify(message.config[i]);
                        if (error)
                            return "config." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a ScreeningConfigs message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.ScreeningConfigs
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.ScreeningConfigs} ScreeningConfigs
             */
            ScreeningConfigs.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.ScreeningConfigs)
                    return object;
                let message = new $root.appcore.ripe.ScreeningConfigs();
                if (object.config) {
                    if (!Array.isArray(object.config))
                        throw TypeError(".appcore.ripe.ScreeningConfigs.config: array expected");
                    message.config = [];
                    for (let i = 0; i < object.config.length; ++i) {
                        if (typeof object.config[i] !== "object")
                            throw TypeError(".appcore.ripe.ScreeningConfigs.config: object expected");
                        message.config[i] = $root.appcore.ripe.ScreeningConfig.fromObject(object.config[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a ScreeningConfigs message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.ScreeningConfigs
             * @static
             * @param {appcore.ripe.ScreeningConfigs} message ScreeningConfigs
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ScreeningConfigs.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.config = [];
                if (message.config && message.config.length) {
                    object.config = [];
                    for (let j = 0; j < message.config.length; ++j)
                        object.config[j] = $root.appcore.ripe.ScreeningConfig.toObject(message.config[j], options);
                }
                return object;
            };

            /**
             * Converts this ScreeningConfigs to JSON.
             * @function toJSON
             * @memberof appcore.ripe.ScreeningConfigs
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ScreeningConfigs.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ScreeningConfigs;
        })();

        ripe.PrinterRegistration = (function() {

            /**
             * Properties of a PrinterRegistration.
             * @memberof appcore.ripe
             * @interface IPrinterRegistration
             * @property {string|null} [device_id] PrinterRegistration device_id
             * @property {string|null} [qr_data] PrinterRegistration qr_data
             */

            /**
             * Constructs a new PrinterRegistration.
             * @memberof appcore.ripe
             * @classdesc Represents a PrinterRegistration.
             * @implements IPrinterRegistration
             * @constructor
             * @param {appcore.ripe.IPrinterRegistration=} [properties] Properties to set
             */
            function PrinterRegistration(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PrinterRegistration device_id.
             * @member {string} device_id
             * @memberof appcore.ripe.PrinterRegistration
             * @instance
             */
            PrinterRegistration.prototype.device_id = "";

            /**
             * PrinterRegistration qr_data.
             * @member {string} qr_data
             * @memberof appcore.ripe.PrinterRegistration
             * @instance
             */
            PrinterRegistration.prototype.qr_data = "";

            /**
             * Creates a new PrinterRegistration instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.PrinterRegistration
             * @static
             * @param {appcore.ripe.IPrinterRegistration=} [properties] Properties to set
             * @returns {appcore.ripe.PrinterRegistration} PrinterRegistration instance
             */
            PrinterRegistration.create = function create(properties) {
                return new PrinterRegistration(properties);
            };

            /**
             * Encodes the specified PrinterRegistration message. Does not implicitly {@link appcore.ripe.PrinterRegistration.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.PrinterRegistration
             * @static
             * @param {appcore.ripe.IPrinterRegistration} message PrinterRegistration message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PrinterRegistration.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.device_id != null && Object.hasOwnProperty.call(message, "device_id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.device_id);
                if (message.qr_data != null && Object.hasOwnProperty.call(message, "qr_data"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.qr_data);
                return writer;
            };

            /**
             * Encodes the specified PrinterRegistration message, length delimited. Does not implicitly {@link appcore.ripe.PrinterRegistration.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.PrinterRegistration
             * @static
             * @param {appcore.ripe.IPrinterRegistration} message PrinterRegistration message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PrinterRegistration.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PrinterRegistration message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.PrinterRegistration
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.PrinterRegistration} PrinterRegistration
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PrinterRegistration.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.PrinterRegistration();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.device_id = reader.string();
                        break;
                    case 2:
                        message.qr_data = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PrinterRegistration message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.PrinterRegistration
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.PrinterRegistration} PrinterRegistration
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PrinterRegistration.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PrinterRegistration message.
             * @function verify
             * @memberof appcore.ripe.PrinterRegistration
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PrinterRegistration.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.device_id != null && message.hasOwnProperty("device_id"))
                    if (!$util.isString(message.device_id))
                        return "device_id: string expected";
                if (message.qr_data != null && message.hasOwnProperty("qr_data"))
                    if (!$util.isString(message.qr_data))
                        return "qr_data: string expected";
                return null;
            };

            /**
             * Creates a PrinterRegistration message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.PrinterRegistration
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.PrinterRegistration} PrinterRegistration
             */
            PrinterRegistration.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.PrinterRegistration)
                    return object;
                let message = new $root.appcore.ripe.PrinterRegistration();
                if (object.device_id != null)
                    message.device_id = String(object.device_id);
                if (object.qr_data != null)
                    message.qr_data = String(object.qr_data);
                return message;
            };

            /**
             * Creates a plain object from a PrinterRegistration message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.PrinterRegistration
             * @static
             * @param {appcore.ripe.PrinterRegistration} message PrinterRegistration
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PrinterRegistration.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.device_id = "";
                    object.qr_data = "";
                }
                if (message.device_id != null && message.hasOwnProperty("device_id"))
                    object.device_id = message.device_id;
                if (message.qr_data != null && message.hasOwnProperty("qr_data"))
                    object.qr_data = message.qr_data;
                return object;
            };

            /**
             * Converts this PrinterRegistration to JSON.
             * @function toJSON
             * @memberof appcore.ripe.PrinterRegistration
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PrinterRegistration.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return PrinterRegistration;
        })();

        ripe.PrinterRegistrationResult = (function() {

            /**
             * Properties of a PrinterRegistrationResult.
             * @memberof appcore.ripe
             * @interface IPrinterRegistrationResult
             * @property {number|null} [error_code] PrinterRegistrationResult error_code
             */

            /**
             * Constructs a new PrinterRegistrationResult.
             * @memberof appcore.ripe
             * @classdesc Represents a PrinterRegistrationResult.
             * @implements IPrinterRegistrationResult
             * @constructor
             * @param {appcore.ripe.IPrinterRegistrationResult=} [properties] Properties to set
             */
            function PrinterRegistrationResult(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PrinterRegistrationResult error_code.
             * @member {number} error_code
             * @memberof appcore.ripe.PrinterRegistrationResult
             * @instance
             */
            PrinterRegistrationResult.prototype.error_code = 0;

            /**
             * Creates a new PrinterRegistrationResult instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.PrinterRegistrationResult
             * @static
             * @param {appcore.ripe.IPrinterRegistrationResult=} [properties] Properties to set
             * @returns {appcore.ripe.PrinterRegistrationResult} PrinterRegistrationResult instance
             */
            PrinterRegistrationResult.create = function create(properties) {
                return new PrinterRegistrationResult(properties);
            };

            /**
             * Encodes the specified PrinterRegistrationResult message. Does not implicitly {@link appcore.ripe.PrinterRegistrationResult.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.PrinterRegistrationResult
             * @static
             * @param {appcore.ripe.IPrinterRegistrationResult} message PrinterRegistrationResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PrinterRegistrationResult.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.error_code != null && Object.hasOwnProperty.call(message, "error_code"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.error_code);
                return writer;
            };

            /**
             * Encodes the specified PrinterRegistrationResult message, length delimited. Does not implicitly {@link appcore.ripe.PrinterRegistrationResult.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.PrinterRegistrationResult
             * @static
             * @param {appcore.ripe.IPrinterRegistrationResult} message PrinterRegistrationResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PrinterRegistrationResult.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PrinterRegistrationResult message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.PrinterRegistrationResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.PrinterRegistrationResult} PrinterRegistrationResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PrinterRegistrationResult.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.PrinterRegistrationResult();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.error_code = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PrinterRegistrationResult message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.PrinterRegistrationResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.PrinterRegistrationResult} PrinterRegistrationResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PrinterRegistrationResult.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PrinterRegistrationResult message.
             * @function verify
             * @memberof appcore.ripe.PrinterRegistrationResult
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PrinterRegistrationResult.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.error_code != null && message.hasOwnProperty("error_code"))
                    if (!$util.isInteger(message.error_code))
                        return "error_code: integer expected";
                return null;
            };

            /**
             * Creates a PrinterRegistrationResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.PrinterRegistrationResult
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.PrinterRegistrationResult} PrinterRegistrationResult
             */
            PrinterRegistrationResult.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.PrinterRegistrationResult)
                    return object;
                let message = new $root.appcore.ripe.PrinterRegistrationResult();
                if (object.error_code != null)
                    message.error_code = object.error_code | 0;
                return message;
            };

            /**
             * Creates a plain object from a PrinterRegistrationResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.PrinterRegistrationResult
             * @static
             * @param {appcore.ripe.PrinterRegistrationResult} message PrinterRegistrationResult
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PrinterRegistrationResult.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.error_code = 0;
                if (message.error_code != null && message.hasOwnProperty("error_code"))
                    object.error_code = message.error_code;
                return object;
            };

            /**
             * Converts this PrinterRegistrationResult to JSON.
             * @function toJSON
             * @memberof appcore.ripe.PrinterRegistrationResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PrinterRegistrationResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return PrinterRegistrationResult;
        })();

        ripe.QRCodeBinaryTask = (function() {

            /**
             * Properties of a QRCodeBinaryTask.
             * @memberof appcore.ripe
             * @interface IQRCodeBinaryTask
             * @property {string|null} [device_id] QRCodeBinaryTask device_id
             * @property {Uint8Array|null} [data] QRCodeBinaryTask data
             */

            /**
             * Constructs a new QRCodeBinaryTask.
             * @memberof appcore.ripe
             * @classdesc Represents a QRCodeBinaryTask.
             * @implements IQRCodeBinaryTask
             * @constructor
             * @param {appcore.ripe.IQRCodeBinaryTask=} [properties] Properties to set
             */
            function QRCodeBinaryTask(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * QRCodeBinaryTask device_id.
             * @member {string} device_id
             * @memberof appcore.ripe.QRCodeBinaryTask
             * @instance
             */
            QRCodeBinaryTask.prototype.device_id = "";

            /**
             * QRCodeBinaryTask data.
             * @member {Uint8Array} data
             * @memberof appcore.ripe.QRCodeBinaryTask
             * @instance
             */
            QRCodeBinaryTask.prototype.data = $util.newBuffer([]);

            /**
             * Creates a new QRCodeBinaryTask instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.QRCodeBinaryTask
             * @static
             * @param {appcore.ripe.IQRCodeBinaryTask=} [properties] Properties to set
             * @returns {appcore.ripe.QRCodeBinaryTask} QRCodeBinaryTask instance
             */
            QRCodeBinaryTask.create = function create(properties) {
                return new QRCodeBinaryTask(properties);
            };

            /**
             * Encodes the specified QRCodeBinaryTask message. Does not implicitly {@link appcore.ripe.QRCodeBinaryTask.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.QRCodeBinaryTask
             * @static
             * @param {appcore.ripe.IQRCodeBinaryTask} message QRCodeBinaryTask message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QRCodeBinaryTask.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.device_id != null && Object.hasOwnProperty.call(message, "device_id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.device_id);
                if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                    writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.data);
                return writer;
            };

            /**
             * Encodes the specified QRCodeBinaryTask message, length delimited. Does not implicitly {@link appcore.ripe.QRCodeBinaryTask.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.QRCodeBinaryTask
             * @static
             * @param {appcore.ripe.IQRCodeBinaryTask} message QRCodeBinaryTask message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QRCodeBinaryTask.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a QRCodeBinaryTask message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.QRCodeBinaryTask
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.QRCodeBinaryTask} QRCodeBinaryTask
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QRCodeBinaryTask.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.QRCodeBinaryTask();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.device_id = reader.string();
                        break;
                    case 2:
                        message.data = reader.bytes();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a QRCodeBinaryTask message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.QRCodeBinaryTask
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.QRCodeBinaryTask} QRCodeBinaryTask
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QRCodeBinaryTask.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a QRCodeBinaryTask message.
             * @function verify
             * @memberof appcore.ripe.QRCodeBinaryTask
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            QRCodeBinaryTask.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.device_id != null && message.hasOwnProperty("device_id"))
                    if (!$util.isString(message.device_id))
                        return "device_id: string expected";
                if (message.data != null && message.hasOwnProperty("data"))
                    if (!(message.data && typeof message.data.length === "number" || $util.isString(message.data)))
                        return "data: buffer expected";
                return null;
            };

            /**
             * Creates a QRCodeBinaryTask message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.QRCodeBinaryTask
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.QRCodeBinaryTask} QRCodeBinaryTask
             */
            QRCodeBinaryTask.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.QRCodeBinaryTask)
                    return object;
                let message = new $root.appcore.ripe.QRCodeBinaryTask();
                if (object.device_id != null)
                    message.device_id = String(object.device_id);
                if (object.data != null)
                    if (typeof object.data === "string")
                        $util.base64.decode(object.data, message.data = $util.newBuffer($util.base64.length(object.data)), 0);
                    else if (object.data.length)
                        message.data = object.data;
                return message;
            };

            /**
             * Creates a plain object from a QRCodeBinaryTask message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.QRCodeBinaryTask
             * @static
             * @param {appcore.ripe.QRCodeBinaryTask} message QRCodeBinaryTask
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            QRCodeBinaryTask.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.device_id = "";
                    if (options.bytes === String)
                        object.data = "";
                    else {
                        object.data = [];
                        if (options.bytes !== Array)
                            object.data = $util.newBuffer(object.data);
                    }
                }
                if (message.device_id != null && message.hasOwnProperty("device_id"))
                    object.device_id = message.device_id;
                if (message.data != null && message.hasOwnProperty("data"))
                    object.data = options.bytes === String ? $util.base64.encode(message.data, 0, message.data.length) : options.bytes === Array ? Array.prototype.slice.call(message.data) : message.data;
                return object;
            };

            /**
             * Converts this QRCodeBinaryTask to JSON.
             * @function toJSON
             * @memberof appcore.ripe.QRCodeBinaryTask
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            QRCodeBinaryTask.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return QRCodeBinaryTask;
        })();

        ripe.QRCodeData = (function() {

            /**
             * Properties of a QRCodeData.
             * @memberof appcore.ripe
             * @interface IQRCodeData
             * @property {string|null} [data] QRCodeData data
             */

            /**
             * Constructs a new QRCodeData.
             * @memberof appcore.ripe
             * @classdesc Represents a QRCodeData.
             * @implements IQRCodeData
             * @constructor
             * @param {appcore.ripe.IQRCodeData=} [properties] Properties to set
             */
            function QRCodeData(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * QRCodeData data.
             * @member {string} data
             * @memberof appcore.ripe.QRCodeData
             * @instance
             */
            QRCodeData.prototype.data = "";

            /**
             * Creates a new QRCodeData instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.QRCodeData
             * @static
             * @param {appcore.ripe.IQRCodeData=} [properties] Properties to set
             * @returns {appcore.ripe.QRCodeData} QRCodeData instance
             */
            QRCodeData.create = function create(properties) {
                return new QRCodeData(properties);
            };

            /**
             * Encodes the specified QRCodeData message. Does not implicitly {@link appcore.ripe.QRCodeData.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.QRCodeData
             * @static
             * @param {appcore.ripe.IQRCodeData} message QRCodeData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QRCodeData.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.data);
                return writer;
            };

            /**
             * Encodes the specified QRCodeData message, length delimited. Does not implicitly {@link appcore.ripe.QRCodeData.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.QRCodeData
             * @static
             * @param {appcore.ripe.IQRCodeData} message QRCodeData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QRCodeData.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a QRCodeData message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.QRCodeData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.QRCodeData} QRCodeData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QRCodeData.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.QRCodeData();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.data = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a QRCodeData message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.QRCodeData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.QRCodeData} QRCodeData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QRCodeData.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a QRCodeData message.
             * @function verify
             * @memberof appcore.ripe.QRCodeData
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            QRCodeData.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.data != null && message.hasOwnProperty("data"))
                    if (!$util.isString(message.data))
                        return "data: string expected";
                return null;
            };

            /**
             * Creates a QRCodeData message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.QRCodeData
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.QRCodeData} QRCodeData
             */
            QRCodeData.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.QRCodeData)
                    return object;
                let message = new $root.appcore.ripe.QRCodeData();
                if (object.data != null)
                    message.data = String(object.data);
                return message;
            };

            /**
             * Creates a plain object from a QRCodeData message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.QRCodeData
             * @static
             * @param {appcore.ripe.QRCodeData} message QRCodeData
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            QRCodeData.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.data = "";
                if (message.data != null && message.hasOwnProperty("data"))
                    object.data = message.data;
                return object;
            };

            /**
             * Converts this QRCodeData to JSON.
             * @function toJSON
             * @memberof appcore.ripe.QRCodeData
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            QRCodeData.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return QRCodeData;
        })();

        ripe.QRTaskResponse = (function() {

            /**
             * Properties of a QRTaskResponse.
             * @memberof appcore.ripe
             * @interface IQRTaskResponse
             * @property {string|null} [text] QRTaskResponse text
             * @property {boolean|null} [show_in_modal] QRTaskResponse show_in_modal
             */

            /**
             * Constructs a new QRTaskResponse.
             * @memberof appcore.ripe
             * @classdesc Represents a QRTaskResponse.
             * @implements IQRTaskResponse
             * @constructor
             * @param {appcore.ripe.IQRTaskResponse=} [properties] Properties to set
             */
            function QRTaskResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * QRTaskResponse text.
             * @member {string} text
             * @memberof appcore.ripe.QRTaskResponse
             * @instance
             */
            QRTaskResponse.prototype.text = "";

            /**
             * QRTaskResponse show_in_modal.
             * @member {boolean} show_in_modal
             * @memberof appcore.ripe.QRTaskResponse
             * @instance
             */
            QRTaskResponse.prototype.show_in_modal = false;

            /**
             * Creates a new QRTaskResponse instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.QRTaskResponse
             * @static
             * @param {appcore.ripe.IQRTaskResponse=} [properties] Properties to set
             * @returns {appcore.ripe.QRTaskResponse} QRTaskResponse instance
             */
            QRTaskResponse.create = function create(properties) {
                return new QRTaskResponse(properties);
            };

            /**
             * Encodes the specified QRTaskResponse message. Does not implicitly {@link appcore.ripe.QRTaskResponse.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.QRTaskResponse
             * @static
             * @param {appcore.ripe.IQRTaskResponse} message QRTaskResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QRTaskResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.text);
                if (message.show_in_modal != null && Object.hasOwnProperty.call(message, "show_in_modal"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.show_in_modal);
                return writer;
            };

            /**
             * Encodes the specified QRTaskResponse message, length delimited. Does not implicitly {@link appcore.ripe.QRTaskResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.QRTaskResponse
             * @static
             * @param {appcore.ripe.IQRTaskResponse} message QRTaskResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QRTaskResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a QRTaskResponse message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.QRTaskResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.QRTaskResponse} QRTaskResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QRTaskResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.QRTaskResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.text = reader.string();
                        break;
                    case 2:
                        message.show_in_modal = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a QRTaskResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.QRTaskResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.QRTaskResponse} QRTaskResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QRTaskResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a QRTaskResponse message.
             * @function verify
             * @memberof appcore.ripe.QRTaskResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            QRTaskResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.text != null && message.hasOwnProperty("text"))
                    if (!$util.isString(message.text))
                        return "text: string expected";
                if (message.show_in_modal != null && message.hasOwnProperty("show_in_modal"))
                    if (typeof message.show_in_modal !== "boolean")
                        return "show_in_modal: boolean expected";
                return null;
            };

            /**
             * Creates a QRTaskResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.QRTaskResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.QRTaskResponse} QRTaskResponse
             */
            QRTaskResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.QRTaskResponse)
                    return object;
                let message = new $root.appcore.ripe.QRTaskResponse();
                if (object.text != null)
                    message.text = String(object.text);
                if (object.show_in_modal != null)
                    message.show_in_modal = Boolean(object.show_in_modal);
                return message;
            };

            /**
             * Creates a plain object from a QRTaskResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.QRTaskResponse
             * @static
             * @param {appcore.ripe.QRTaskResponse} message QRTaskResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            QRTaskResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.text = "";
                    object.show_in_modal = false;
                }
                if (message.text != null && message.hasOwnProperty("text"))
                    object.text = message.text;
                if (message.show_in_modal != null && message.hasOwnProperty("show_in_modal"))
                    object.show_in_modal = message.show_in_modal;
                return object;
            };

            /**
             * Converts this QRTaskResponse to JSON.
             * @function toJSON
             * @memberof appcore.ripe.QRTaskResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            QRTaskResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return QRTaskResponse;
        })();

        ripe.Registration = (function() {

            /**
             * Properties of a Registration.
             * @memberof appcore.ripe
             * @interface IRegistration
             * @property {string|null} [name] Registration name
             * @property {string|null} [email] Registration email
             * @property {string|null} [phone] Registration phone
             */

            /**
             * Constructs a new Registration.
             * @memberof appcore.ripe
             * @classdesc Represents a Registration.
             * @implements IRegistration
             * @constructor
             * @param {appcore.ripe.IRegistration=} [properties] Properties to set
             */
            function Registration(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Registration name.
             * @member {string} name
             * @memberof appcore.ripe.Registration
             * @instance
             */
            Registration.prototype.name = "";

            /**
             * Registration email.
             * @member {string} email
             * @memberof appcore.ripe.Registration
             * @instance
             */
            Registration.prototype.email = "";

            /**
             * Registration phone.
             * @member {string} phone
             * @memberof appcore.ripe.Registration
             * @instance
             */
            Registration.prototype.phone = "";

            /**
             * Creates a new Registration instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.Registration
             * @static
             * @param {appcore.ripe.IRegistration=} [properties] Properties to set
             * @returns {appcore.ripe.Registration} Registration instance
             */
            Registration.create = function create(properties) {
                return new Registration(properties);
            };

            /**
             * Encodes the specified Registration message. Does not implicitly {@link appcore.ripe.Registration.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.Registration
             * @static
             * @param {appcore.ripe.IRegistration} message Registration message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Registration.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.email);
                if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.phone);
                return writer;
            };

            /**
             * Encodes the specified Registration message, length delimited. Does not implicitly {@link appcore.ripe.Registration.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.Registration
             * @static
             * @param {appcore.ripe.IRegistration} message Registration message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Registration.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Registration message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.Registration
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.Registration} Registration
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Registration.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.Registration();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.name = reader.string();
                        break;
                    case 2:
                        message.email = reader.string();
                        break;
                    case 3:
                        message.phone = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Registration message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.Registration
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.Registration} Registration
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Registration.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Registration message.
             * @function verify
             * @memberof appcore.ripe.Registration
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Registration.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.email != null && message.hasOwnProperty("email"))
                    if (!$util.isString(message.email))
                        return "email: string expected";
                if (message.phone != null && message.hasOwnProperty("phone"))
                    if (!$util.isString(message.phone))
                        return "phone: string expected";
                return null;
            };

            /**
             * Creates a Registration message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.Registration
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.Registration} Registration
             */
            Registration.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.Registration)
                    return object;
                let message = new $root.appcore.ripe.Registration();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.email != null)
                    message.email = String(object.email);
                if (object.phone != null)
                    message.phone = String(object.phone);
                return message;
            };

            /**
             * Creates a plain object from a Registration message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.Registration
             * @static
             * @param {appcore.ripe.Registration} message Registration
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Registration.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.name = "";
                    object.email = "";
                    object.phone = "";
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.email != null && message.hasOwnProperty("email"))
                    object.email = message.email;
                if (message.phone != null && message.hasOwnProperty("phone"))
                    object.phone = message.phone;
                return object;
            };

            /**
             * Converts this Registration to JSON.
             * @function toJSON
             * @memberof appcore.ripe.Registration
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Registration.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Registration;
        })();

        ripe.RegistrationResult = (function() {

            /**
             * Properties of a RegistrationResult.
             * @memberof appcore.ripe
             * @interface IRegistrationResult
             * @property {number|null} [error_code] RegistrationResult error_code
             */

            /**
             * Constructs a new RegistrationResult.
             * @memberof appcore.ripe
             * @classdesc Represents a RegistrationResult.
             * @implements IRegistrationResult
             * @constructor
             * @param {appcore.ripe.IRegistrationResult=} [properties] Properties to set
             */
            function RegistrationResult(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * RegistrationResult error_code.
             * @member {number} error_code
             * @memberof appcore.ripe.RegistrationResult
             * @instance
             */
            RegistrationResult.prototype.error_code = 0;

            /**
             * Creates a new RegistrationResult instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.RegistrationResult
             * @static
             * @param {appcore.ripe.IRegistrationResult=} [properties] Properties to set
             * @returns {appcore.ripe.RegistrationResult} RegistrationResult instance
             */
            RegistrationResult.create = function create(properties) {
                return new RegistrationResult(properties);
            };

            /**
             * Encodes the specified RegistrationResult message. Does not implicitly {@link appcore.ripe.RegistrationResult.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.RegistrationResult
             * @static
             * @param {appcore.ripe.IRegistrationResult} message RegistrationResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RegistrationResult.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.error_code != null && Object.hasOwnProperty.call(message, "error_code"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.error_code);
                return writer;
            };

            /**
             * Encodes the specified RegistrationResult message, length delimited. Does not implicitly {@link appcore.ripe.RegistrationResult.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.RegistrationResult
             * @static
             * @param {appcore.ripe.IRegistrationResult} message RegistrationResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RegistrationResult.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a RegistrationResult message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.RegistrationResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.RegistrationResult} RegistrationResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RegistrationResult.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.RegistrationResult();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.error_code = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a RegistrationResult message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.RegistrationResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.RegistrationResult} RegistrationResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RegistrationResult.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a RegistrationResult message.
             * @function verify
             * @memberof appcore.ripe.RegistrationResult
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RegistrationResult.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.error_code != null && message.hasOwnProperty("error_code"))
                    if (!$util.isInteger(message.error_code))
                        return "error_code: integer expected";
                return null;
            };

            /**
             * Creates a RegistrationResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.RegistrationResult
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.RegistrationResult} RegistrationResult
             */
            RegistrationResult.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.RegistrationResult)
                    return object;
                let message = new $root.appcore.ripe.RegistrationResult();
                if (object.error_code != null)
                    message.error_code = object.error_code | 0;
                return message;
            };

            /**
             * Creates a plain object from a RegistrationResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.RegistrationResult
             * @static
             * @param {appcore.ripe.RegistrationResult} message RegistrationResult
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RegistrationResult.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.error_code = 0;
                if (message.error_code != null && message.hasOwnProperty("error_code"))
                    object.error_code = message.error_code;
                return object;
            };

            /**
             * Converts this RegistrationResult to JSON.
             * @function toJSON
             * @memberof appcore.ripe.RegistrationResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RegistrationResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return RegistrationResult;
        })();

        ripe.RemoveRIPComputerPrinterMapping = (function() {

            /**
             * Properties of a RemoveRIPComputerPrinterMapping.
             * @memberof appcore.ripe
             * @interface IRemoveRIPComputerPrinterMapping
             * @property {number|Long|null} [user_printer_id] RemoveRIPComputerPrinterMapping user_printer_id
             * @property {number|Long|null} [rip_computer_id] RemoveRIPComputerPrinterMapping rip_computer_id
             */

            /**
             * Constructs a new RemoveRIPComputerPrinterMapping.
             * @memberof appcore.ripe
             * @classdesc Represents a RemoveRIPComputerPrinterMapping.
             * @implements IRemoveRIPComputerPrinterMapping
             * @constructor
             * @param {appcore.ripe.IRemoveRIPComputerPrinterMapping=} [properties] Properties to set
             */
            function RemoveRIPComputerPrinterMapping(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * RemoveRIPComputerPrinterMapping user_printer_id.
             * @member {number|Long} user_printer_id
             * @memberof appcore.ripe.RemoveRIPComputerPrinterMapping
             * @instance
             */
            RemoveRIPComputerPrinterMapping.prototype.user_printer_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * RemoveRIPComputerPrinterMapping rip_computer_id.
             * @member {number|Long} rip_computer_id
             * @memberof appcore.ripe.RemoveRIPComputerPrinterMapping
             * @instance
             */
            RemoveRIPComputerPrinterMapping.prototype.rip_computer_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new RemoveRIPComputerPrinterMapping instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.RemoveRIPComputerPrinterMapping
             * @static
             * @param {appcore.ripe.IRemoveRIPComputerPrinterMapping=} [properties] Properties to set
             * @returns {appcore.ripe.RemoveRIPComputerPrinterMapping} RemoveRIPComputerPrinterMapping instance
             */
            RemoveRIPComputerPrinterMapping.create = function create(properties) {
                return new RemoveRIPComputerPrinterMapping(properties);
            };

            /**
             * Encodes the specified RemoveRIPComputerPrinterMapping message. Does not implicitly {@link appcore.ripe.RemoveRIPComputerPrinterMapping.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.RemoveRIPComputerPrinterMapping
             * @static
             * @param {appcore.ripe.IRemoveRIPComputerPrinterMapping} message RemoveRIPComputerPrinterMapping message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RemoveRIPComputerPrinterMapping.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.user_printer_id != null && Object.hasOwnProperty.call(message, "user_printer_id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.user_printer_id);
                if (message.rip_computer_id != null && Object.hasOwnProperty.call(message, "rip_computer_id"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.rip_computer_id);
                return writer;
            };

            /**
             * Encodes the specified RemoveRIPComputerPrinterMapping message, length delimited. Does not implicitly {@link appcore.ripe.RemoveRIPComputerPrinterMapping.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.RemoveRIPComputerPrinterMapping
             * @static
             * @param {appcore.ripe.IRemoveRIPComputerPrinterMapping} message RemoveRIPComputerPrinterMapping message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RemoveRIPComputerPrinterMapping.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a RemoveRIPComputerPrinterMapping message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.RemoveRIPComputerPrinterMapping
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.RemoveRIPComputerPrinterMapping} RemoveRIPComputerPrinterMapping
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RemoveRIPComputerPrinterMapping.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.RemoveRIPComputerPrinterMapping();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.user_printer_id = reader.uint64();
                        break;
                    case 2:
                        message.rip_computer_id = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a RemoveRIPComputerPrinterMapping message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.RemoveRIPComputerPrinterMapping
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.RemoveRIPComputerPrinterMapping} RemoveRIPComputerPrinterMapping
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RemoveRIPComputerPrinterMapping.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a RemoveRIPComputerPrinterMapping message.
             * @function verify
             * @memberof appcore.ripe.RemoveRIPComputerPrinterMapping
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RemoveRIPComputerPrinterMapping.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.user_printer_id != null && message.hasOwnProperty("user_printer_id"))
                    if (!$util.isInteger(message.user_printer_id) && !(message.user_printer_id && $util.isInteger(message.user_printer_id.low) && $util.isInteger(message.user_printer_id.high)))
                        return "user_printer_id: integer|Long expected";
                if (message.rip_computer_id != null && message.hasOwnProperty("rip_computer_id"))
                    if (!$util.isInteger(message.rip_computer_id) && !(message.rip_computer_id && $util.isInteger(message.rip_computer_id.low) && $util.isInteger(message.rip_computer_id.high)))
                        return "rip_computer_id: integer|Long expected";
                return null;
            };

            /**
             * Creates a RemoveRIPComputerPrinterMapping message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.RemoveRIPComputerPrinterMapping
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.RemoveRIPComputerPrinterMapping} RemoveRIPComputerPrinterMapping
             */
            RemoveRIPComputerPrinterMapping.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.RemoveRIPComputerPrinterMapping)
                    return object;
                let message = new $root.appcore.ripe.RemoveRIPComputerPrinterMapping();
                if (object.user_printer_id != null)
                    if ($util.Long)
                        (message.user_printer_id = $util.Long.fromValue(object.user_printer_id)).unsigned = true;
                    else if (typeof object.user_printer_id === "string")
                        message.user_printer_id = parseInt(object.user_printer_id, 10);
                    else if (typeof object.user_printer_id === "number")
                        message.user_printer_id = object.user_printer_id;
                    else if (typeof object.user_printer_id === "object")
                        message.user_printer_id = new $util.LongBits(object.user_printer_id.low >>> 0, object.user_printer_id.high >>> 0).toNumber(true);
                if (object.rip_computer_id != null)
                    if ($util.Long)
                        (message.rip_computer_id = $util.Long.fromValue(object.rip_computer_id)).unsigned = true;
                    else if (typeof object.rip_computer_id === "string")
                        message.rip_computer_id = parseInt(object.rip_computer_id, 10);
                    else if (typeof object.rip_computer_id === "number")
                        message.rip_computer_id = object.rip_computer_id;
                    else if (typeof object.rip_computer_id === "object")
                        message.rip_computer_id = new $util.LongBits(object.rip_computer_id.low >>> 0, object.rip_computer_id.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a RemoveRIPComputerPrinterMapping message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.RemoveRIPComputerPrinterMapping
             * @static
             * @param {appcore.ripe.RemoveRIPComputerPrinterMapping} message RemoveRIPComputerPrinterMapping
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RemoveRIPComputerPrinterMapping.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.user_printer_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.user_printer_id = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.rip_computer_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.rip_computer_id = options.longs === String ? "0" : 0;
                }
                if (message.user_printer_id != null && message.hasOwnProperty("user_printer_id"))
                    if (typeof message.user_printer_id === "number")
                        object.user_printer_id = options.longs === String ? String(message.user_printer_id) : message.user_printer_id;
                    else
                        object.user_printer_id = options.longs === String ? $util.Long.prototype.toString.call(message.user_printer_id) : options.longs === Number ? new $util.LongBits(message.user_printer_id.low >>> 0, message.user_printer_id.high >>> 0).toNumber(true) : message.user_printer_id;
                if (message.rip_computer_id != null && message.hasOwnProperty("rip_computer_id"))
                    if (typeof message.rip_computer_id === "number")
                        object.rip_computer_id = options.longs === String ? String(message.rip_computer_id) : message.rip_computer_id;
                    else
                        object.rip_computer_id = options.longs === String ? $util.Long.prototype.toString.call(message.rip_computer_id) : options.longs === Number ? new $util.LongBits(message.rip_computer_id.low >>> 0, message.rip_computer_id.high >>> 0).toNumber(true) : message.rip_computer_id;
                return object;
            };

            /**
             * Converts this RemoveRIPComputerPrinterMapping to JSON.
             * @function toJSON
             * @memberof appcore.ripe.RemoveRIPComputerPrinterMapping
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RemoveRIPComputerPrinterMapping.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return RemoveRIPComputerPrinterMapping;
        })();

        ripe.MappingUserPrinterRIPComputer = (function() {

            /**
             * Properties of a MappingUserPrinterRIPComputer.
             * @memberof appcore.ripe
             * @interface IMappingUserPrinterRIPComputer
             * @property {number|Long|null} [id] MappingUserPrinterRIPComputer id
             * @property {string|null} [name] MappingUserPrinterRIPComputer name
             * @property {string|null} [registration_date] MappingUserPrinterRIPComputer registration_date
             * @property {Array.<number|Long>|null} [rip_computer_ids] MappingUserPrinterRIPComputer rip_computer_ids
             */

            /**
             * Constructs a new MappingUserPrinterRIPComputer.
             * @memberof appcore.ripe
             * @classdesc Represents a MappingUserPrinterRIPComputer.
             * @implements IMappingUserPrinterRIPComputer
             * @constructor
             * @param {appcore.ripe.IMappingUserPrinterRIPComputer=} [properties] Properties to set
             */
            function MappingUserPrinterRIPComputer(properties) {
                this.rip_computer_ids = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MappingUserPrinterRIPComputer id.
             * @member {number|Long} id
             * @memberof appcore.ripe.MappingUserPrinterRIPComputer
             * @instance
             */
            MappingUserPrinterRIPComputer.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * MappingUserPrinterRIPComputer name.
             * @member {string} name
             * @memberof appcore.ripe.MappingUserPrinterRIPComputer
             * @instance
             */
            MappingUserPrinterRIPComputer.prototype.name = "";

            /**
             * MappingUserPrinterRIPComputer registration_date.
             * @member {string} registration_date
             * @memberof appcore.ripe.MappingUserPrinterRIPComputer
             * @instance
             */
            MappingUserPrinterRIPComputer.prototype.registration_date = "";

            /**
             * MappingUserPrinterRIPComputer rip_computer_ids.
             * @member {Array.<number|Long>} rip_computer_ids
             * @memberof appcore.ripe.MappingUserPrinterRIPComputer
             * @instance
             */
            MappingUserPrinterRIPComputer.prototype.rip_computer_ids = $util.emptyArray;

            /**
             * Creates a new MappingUserPrinterRIPComputer instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.MappingUserPrinterRIPComputer
             * @static
             * @param {appcore.ripe.IMappingUserPrinterRIPComputer=} [properties] Properties to set
             * @returns {appcore.ripe.MappingUserPrinterRIPComputer} MappingUserPrinterRIPComputer instance
             */
            MappingUserPrinterRIPComputer.create = function create(properties) {
                return new MappingUserPrinterRIPComputer(properties);
            };

            /**
             * Encodes the specified MappingUserPrinterRIPComputer message. Does not implicitly {@link appcore.ripe.MappingUserPrinterRIPComputer.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.MappingUserPrinterRIPComputer
             * @static
             * @param {appcore.ripe.IMappingUserPrinterRIPComputer} message MappingUserPrinterRIPComputer message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MappingUserPrinterRIPComputer.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.id);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.registration_date != null && Object.hasOwnProperty.call(message, "registration_date"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.registration_date);
                if (message.rip_computer_ids != null && message.rip_computer_ids.length) {
                    writer.uint32(/* id 4, wireType 2 =*/34).fork();
                    for (let i = 0; i < message.rip_computer_ids.length; ++i)
                        writer.uint64(message.rip_computer_ids[i]);
                    writer.ldelim();
                }
                return writer;
            };

            /**
             * Encodes the specified MappingUserPrinterRIPComputer message, length delimited. Does not implicitly {@link appcore.ripe.MappingUserPrinterRIPComputer.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.MappingUserPrinterRIPComputer
             * @static
             * @param {appcore.ripe.IMappingUserPrinterRIPComputer} message MappingUserPrinterRIPComputer message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MappingUserPrinterRIPComputer.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a MappingUserPrinterRIPComputer message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.MappingUserPrinterRIPComputer
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.MappingUserPrinterRIPComputer} MappingUserPrinterRIPComputer
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MappingUserPrinterRIPComputer.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.MappingUserPrinterRIPComputer();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.uint64();
                        break;
                    case 2:
                        message.name = reader.string();
                        break;
                    case 3:
                        message.registration_date = reader.string();
                        break;
                    case 4:
                        if (!(message.rip_computer_ids && message.rip_computer_ids.length))
                            message.rip_computer_ids = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.rip_computer_ids.push(reader.uint64());
                        } else
                            message.rip_computer_ids.push(reader.uint64());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a MappingUserPrinterRIPComputer message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.MappingUserPrinterRIPComputer
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.MappingUserPrinterRIPComputer} MappingUserPrinterRIPComputer
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MappingUserPrinterRIPComputer.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a MappingUserPrinterRIPComputer message.
             * @function verify
             * @memberof appcore.ripe.MappingUserPrinterRIPComputer
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MappingUserPrinterRIPComputer.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.registration_date != null && message.hasOwnProperty("registration_date"))
                    if (!$util.isString(message.registration_date))
                        return "registration_date: string expected";
                if (message.rip_computer_ids != null && message.hasOwnProperty("rip_computer_ids")) {
                    if (!Array.isArray(message.rip_computer_ids))
                        return "rip_computer_ids: array expected";
                    for (let i = 0; i < message.rip_computer_ids.length; ++i)
                        if (!$util.isInteger(message.rip_computer_ids[i]) && !(message.rip_computer_ids[i] && $util.isInteger(message.rip_computer_ids[i].low) && $util.isInteger(message.rip_computer_ids[i].high)))
                            return "rip_computer_ids: integer|Long[] expected";
                }
                return null;
            };

            /**
             * Creates a MappingUserPrinterRIPComputer message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.MappingUserPrinterRIPComputer
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.MappingUserPrinterRIPComputer} MappingUserPrinterRIPComputer
             */
            MappingUserPrinterRIPComputer.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.MappingUserPrinterRIPComputer)
                    return object;
                let message = new $root.appcore.ripe.MappingUserPrinterRIPComputer();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.registration_date != null)
                    message.registration_date = String(object.registration_date);
                if (object.rip_computer_ids) {
                    if (!Array.isArray(object.rip_computer_ids))
                        throw TypeError(".appcore.ripe.MappingUserPrinterRIPComputer.rip_computer_ids: array expected");
                    message.rip_computer_ids = [];
                    for (let i = 0; i < object.rip_computer_ids.length; ++i)
                        if ($util.Long)
                            (message.rip_computer_ids[i] = $util.Long.fromValue(object.rip_computer_ids[i])).unsigned = true;
                        else if (typeof object.rip_computer_ids[i] === "string")
                            message.rip_computer_ids[i] = parseInt(object.rip_computer_ids[i], 10);
                        else if (typeof object.rip_computer_ids[i] === "number")
                            message.rip_computer_ids[i] = object.rip_computer_ids[i];
                        else if (typeof object.rip_computer_ids[i] === "object")
                            message.rip_computer_ids[i] = new $util.LongBits(object.rip_computer_ids[i].low >>> 0, object.rip_computer_ids[i].high >>> 0).toNumber(true);
                }
                return message;
            };

            /**
             * Creates a plain object from a MappingUserPrinterRIPComputer message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.MappingUserPrinterRIPComputer
             * @static
             * @param {appcore.ripe.MappingUserPrinterRIPComputer} message MappingUserPrinterRIPComputer
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MappingUserPrinterRIPComputer.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.rip_computer_ids = [];
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                    object.name = "";
                    object.registration_date = "";
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.registration_date != null && message.hasOwnProperty("registration_date"))
                    object.registration_date = message.registration_date;
                if (message.rip_computer_ids && message.rip_computer_ids.length) {
                    object.rip_computer_ids = [];
                    for (let j = 0; j < message.rip_computer_ids.length; ++j)
                        if (typeof message.rip_computer_ids[j] === "number")
                            object.rip_computer_ids[j] = options.longs === String ? String(message.rip_computer_ids[j]) : message.rip_computer_ids[j];
                        else
                            object.rip_computer_ids[j] = options.longs === String ? $util.Long.prototype.toString.call(message.rip_computer_ids[j]) : options.longs === Number ? new $util.LongBits(message.rip_computer_ids[j].low >>> 0, message.rip_computer_ids[j].high >>> 0).toNumber(true) : message.rip_computer_ids[j];
                }
                return object;
            };

            /**
             * Converts this MappingUserPrinterRIPComputer to JSON.
             * @function toJSON
             * @memberof appcore.ripe.MappingUserPrinterRIPComputer
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MappingUserPrinterRIPComputer.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return MappingUserPrinterRIPComputer;
        })();

        ripe.MappingRIPComputerUserPrinter = (function() {

            /**
             * Properties of a MappingRIPComputerUserPrinter.
             * @memberof appcore.ripe
             * @interface IMappingRIPComputerUserPrinter
             * @property {number|Long|null} [id] MappingRIPComputerUserPrinter id
             * @property {string|null} [name] MappingRIPComputerUserPrinter name
             * @property {string|null} [registration_date] MappingRIPComputerUserPrinter registration_date
             * @property {Array.<number|Long>|null} [user_printer_ids] MappingRIPComputerUserPrinter user_printer_ids
             */

            /**
             * Constructs a new MappingRIPComputerUserPrinter.
             * @memberof appcore.ripe
             * @classdesc Represents a MappingRIPComputerUserPrinter.
             * @implements IMappingRIPComputerUserPrinter
             * @constructor
             * @param {appcore.ripe.IMappingRIPComputerUserPrinter=} [properties] Properties to set
             */
            function MappingRIPComputerUserPrinter(properties) {
                this.user_printer_ids = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MappingRIPComputerUserPrinter id.
             * @member {number|Long} id
             * @memberof appcore.ripe.MappingRIPComputerUserPrinter
             * @instance
             */
            MappingRIPComputerUserPrinter.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * MappingRIPComputerUserPrinter name.
             * @member {string} name
             * @memberof appcore.ripe.MappingRIPComputerUserPrinter
             * @instance
             */
            MappingRIPComputerUserPrinter.prototype.name = "";

            /**
             * MappingRIPComputerUserPrinter registration_date.
             * @member {string} registration_date
             * @memberof appcore.ripe.MappingRIPComputerUserPrinter
             * @instance
             */
            MappingRIPComputerUserPrinter.prototype.registration_date = "";

            /**
             * MappingRIPComputerUserPrinter user_printer_ids.
             * @member {Array.<number|Long>} user_printer_ids
             * @memberof appcore.ripe.MappingRIPComputerUserPrinter
             * @instance
             */
            MappingRIPComputerUserPrinter.prototype.user_printer_ids = $util.emptyArray;

            /**
             * Creates a new MappingRIPComputerUserPrinter instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.MappingRIPComputerUserPrinter
             * @static
             * @param {appcore.ripe.IMappingRIPComputerUserPrinter=} [properties] Properties to set
             * @returns {appcore.ripe.MappingRIPComputerUserPrinter} MappingRIPComputerUserPrinter instance
             */
            MappingRIPComputerUserPrinter.create = function create(properties) {
                return new MappingRIPComputerUserPrinter(properties);
            };

            /**
             * Encodes the specified MappingRIPComputerUserPrinter message. Does not implicitly {@link appcore.ripe.MappingRIPComputerUserPrinter.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.MappingRIPComputerUserPrinter
             * @static
             * @param {appcore.ripe.IMappingRIPComputerUserPrinter} message MappingRIPComputerUserPrinter message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MappingRIPComputerUserPrinter.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.id);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.registration_date != null && Object.hasOwnProperty.call(message, "registration_date"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.registration_date);
                if (message.user_printer_ids != null && message.user_printer_ids.length) {
                    writer.uint32(/* id 4, wireType 2 =*/34).fork();
                    for (let i = 0; i < message.user_printer_ids.length; ++i)
                        writer.uint64(message.user_printer_ids[i]);
                    writer.ldelim();
                }
                return writer;
            };

            /**
             * Encodes the specified MappingRIPComputerUserPrinter message, length delimited. Does not implicitly {@link appcore.ripe.MappingRIPComputerUserPrinter.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.MappingRIPComputerUserPrinter
             * @static
             * @param {appcore.ripe.IMappingRIPComputerUserPrinter} message MappingRIPComputerUserPrinter message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MappingRIPComputerUserPrinter.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a MappingRIPComputerUserPrinter message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.MappingRIPComputerUserPrinter
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.MappingRIPComputerUserPrinter} MappingRIPComputerUserPrinter
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MappingRIPComputerUserPrinter.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.MappingRIPComputerUserPrinter();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.uint64();
                        break;
                    case 2:
                        message.name = reader.string();
                        break;
                    case 3:
                        message.registration_date = reader.string();
                        break;
                    case 4:
                        if (!(message.user_printer_ids && message.user_printer_ids.length))
                            message.user_printer_ids = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.user_printer_ids.push(reader.uint64());
                        } else
                            message.user_printer_ids.push(reader.uint64());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a MappingRIPComputerUserPrinter message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.MappingRIPComputerUserPrinter
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.MappingRIPComputerUserPrinter} MappingRIPComputerUserPrinter
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MappingRIPComputerUserPrinter.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a MappingRIPComputerUserPrinter message.
             * @function verify
             * @memberof appcore.ripe.MappingRIPComputerUserPrinter
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MappingRIPComputerUserPrinter.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.registration_date != null && message.hasOwnProperty("registration_date"))
                    if (!$util.isString(message.registration_date))
                        return "registration_date: string expected";
                if (message.user_printer_ids != null && message.hasOwnProperty("user_printer_ids")) {
                    if (!Array.isArray(message.user_printer_ids))
                        return "user_printer_ids: array expected";
                    for (let i = 0; i < message.user_printer_ids.length; ++i)
                        if (!$util.isInteger(message.user_printer_ids[i]) && !(message.user_printer_ids[i] && $util.isInteger(message.user_printer_ids[i].low) && $util.isInteger(message.user_printer_ids[i].high)))
                            return "user_printer_ids: integer|Long[] expected";
                }
                return null;
            };

            /**
             * Creates a MappingRIPComputerUserPrinter message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.MappingRIPComputerUserPrinter
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.MappingRIPComputerUserPrinter} MappingRIPComputerUserPrinter
             */
            MappingRIPComputerUserPrinter.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.MappingRIPComputerUserPrinter)
                    return object;
                let message = new $root.appcore.ripe.MappingRIPComputerUserPrinter();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.registration_date != null)
                    message.registration_date = String(object.registration_date);
                if (object.user_printer_ids) {
                    if (!Array.isArray(object.user_printer_ids))
                        throw TypeError(".appcore.ripe.MappingRIPComputerUserPrinter.user_printer_ids: array expected");
                    message.user_printer_ids = [];
                    for (let i = 0; i < object.user_printer_ids.length; ++i)
                        if ($util.Long)
                            (message.user_printer_ids[i] = $util.Long.fromValue(object.user_printer_ids[i])).unsigned = true;
                        else if (typeof object.user_printer_ids[i] === "string")
                            message.user_printer_ids[i] = parseInt(object.user_printer_ids[i], 10);
                        else if (typeof object.user_printer_ids[i] === "number")
                            message.user_printer_ids[i] = object.user_printer_ids[i];
                        else if (typeof object.user_printer_ids[i] === "object")
                            message.user_printer_ids[i] = new $util.LongBits(object.user_printer_ids[i].low >>> 0, object.user_printer_ids[i].high >>> 0).toNumber(true);
                }
                return message;
            };

            /**
             * Creates a plain object from a MappingRIPComputerUserPrinter message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.MappingRIPComputerUserPrinter
             * @static
             * @param {appcore.ripe.MappingRIPComputerUserPrinter} message MappingRIPComputerUserPrinter
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MappingRIPComputerUserPrinter.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.user_printer_ids = [];
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                    object.name = "";
                    object.registration_date = "";
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.registration_date != null && message.hasOwnProperty("registration_date"))
                    object.registration_date = message.registration_date;
                if (message.user_printer_ids && message.user_printer_ids.length) {
                    object.user_printer_ids = [];
                    for (let j = 0; j < message.user_printer_ids.length; ++j)
                        if (typeof message.user_printer_ids[j] === "number")
                            object.user_printer_ids[j] = options.longs === String ? String(message.user_printer_ids[j]) : message.user_printer_ids[j];
                        else
                            object.user_printer_ids[j] = options.longs === String ? $util.Long.prototype.toString.call(message.user_printer_ids[j]) : options.longs === Number ? new $util.LongBits(message.user_printer_ids[j].low >>> 0, message.user_printer_ids[j].high >>> 0).toNumber(true) : message.user_printer_ids[j];
                }
                return object;
            };

            /**
             * Converts this MappingRIPComputerUserPrinter to JSON.
             * @function toJSON
             * @memberof appcore.ripe.MappingRIPComputerUserPrinter
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MappingRIPComputerUserPrinter.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return MappingRIPComputerUserPrinter;
        })();

        ripe.RIPComputerPrinterMappingData = (function() {

            /**
             * Properties of a RIPComputerPrinterMappingData.
             * @memberof appcore.ripe
             * @interface IRIPComputerPrinterMappingData
             * @property {Array.<appcore.ripe.IMappingUserPrinterRIPComputer>|null} [user_printers_rip_computers] RIPComputerPrinterMappingData user_printers_rip_computers
             * @property {Array.<appcore.ripe.IMappingRIPComputerUserPrinter>|null} [rip_computers_user_printers] RIPComputerPrinterMappingData rip_computers_user_printers
             */

            /**
             * Constructs a new RIPComputerPrinterMappingData.
             * @memberof appcore.ripe
             * @classdesc Represents a RIPComputerPrinterMappingData.
             * @implements IRIPComputerPrinterMappingData
             * @constructor
             * @param {appcore.ripe.IRIPComputerPrinterMappingData=} [properties] Properties to set
             */
            function RIPComputerPrinterMappingData(properties) {
                this.user_printers_rip_computers = [];
                this.rip_computers_user_printers = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * RIPComputerPrinterMappingData user_printers_rip_computers.
             * @member {Array.<appcore.ripe.IMappingUserPrinterRIPComputer>} user_printers_rip_computers
             * @memberof appcore.ripe.RIPComputerPrinterMappingData
             * @instance
             */
            RIPComputerPrinterMappingData.prototype.user_printers_rip_computers = $util.emptyArray;

            /**
             * RIPComputerPrinterMappingData rip_computers_user_printers.
             * @member {Array.<appcore.ripe.IMappingRIPComputerUserPrinter>} rip_computers_user_printers
             * @memberof appcore.ripe.RIPComputerPrinterMappingData
             * @instance
             */
            RIPComputerPrinterMappingData.prototype.rip_computers_user_printers = $util.emptyArray;

            /**
             * Creates a new RIPComputerPrinterMappingData instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.RIPComputerPrinterMappingData
             * @static
             * @param {appcore.ripe.IRIPComputerPrinterMappingData=} [properties] Properties to set
             * @returns {appcore.ripe.RIPComputerPrinterMappingData} RIPComputerPrinterMappingData instance
             */
            RIPComputerPrinterMappingData.create = function create(properties) {
                return new RIPComputerPrinterMappingData(properties);
            };

            /**
             * Encodes the specified RIPComputerPrinterMappingData message. Does not implicitly {@link appcore.ripe.RIPComputerPrinterMappingData.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.RIPComputerPrinterMappingData
             * @static
             * @param {appcore.ripe.IRIPComputerPrinterMappingData} message RIPComputerPrinterMappingData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RIPComputerPrinterMappingData.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.user_printers_rip_computers != null && message.user_printers_rip_computers.length)
                    for (let i = 0; i < message.user_printers_rip_computers.length; ++i)
                        $root.appcore.ripe.MappingUserPrinterRIPComputer.encode(message.user_printers_rip_computers[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.rip_computers_user_printers != null && message.rip_computers_user_printers.length)
                    for (let i = 0; i < message.rip_computers_user_printers.length; ++i)
                        $root.appcore.ripe.MappingRIPComputerUserPrinter.encode(message.rip_computers_user_printers[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified RIPComputerPrinterMappingData message, length delimited. Does not implicitly {@link appcore.ripe.RIPComputerPrinterMappingData.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.RIPComputerPrinterMappingData
             * @static
             * @param {appcore.ripe.IRIPComputerPrinterMappingData} message RIPComputerPrinterMappingData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RIPComputerPrinterMappingData.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a RIPComputerPrinterMappingData message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.RIPComputerPrinterMappingData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.RIPComputerPrinterMappingData} RIPComputerPrinterMappingData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RIPComputerPrinterMappingData.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.RIPComputerPrinterMappingData();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.user_printers_rip_computers && message.user_printers_rip_computers.length))
                            message.user_printers_rip_computers = [];
                        message.user_printers_rip_computers.push($root.appcore.ripe.MappingUserPrinterRIPComputer.decode(reader, reader.uint32()));
                        break;
                    case 2:
                        if (!(message.rip_computers_user_printers && message.rip_computers_user_printers.length))
                            message.rip_computers_user_printers = [];
                        message.rip_computers_user_printers.push($root.appcore.ripe.MappingRIPComputerUserPrinter.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a RIPComputerPrinterMappingData message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.RIPComputerPrinterMappingData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.RIPComputerPrinterMappingData} RIPComputerPrinterMappingData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RIPComputerPrinterMappingData.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a RIPComputerPrinterMappingData message.
             * @function verify
             * @memberof appcore.ripe.RIPComputerPrinterMappingData
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RIPComputerPrinterMappingData.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.user_printers_rip_computers != null && message.hasOwnProperty("user_printers_rip_computers")) {
                    if (!Array.isArray(message.user_printers_rip_computers))
                        return "user_printers_rip_computers: array expected";
                    for (let i = 0; i < message.user_printers_rip_computers.length; ++i) {
                        let error = $root.appcore.ripe.MappingUserPrinterRIPComputer.verify(message.user_printers_rip_computers[i]);
                        if (error)
                            return "user_printers_rip_computers." + error;
                    }
                }
                if (message.rip_computers_user_printers != null && message.hasOwnProperty("rip_computers_user_printers")) {
                    if (!Array.isArray(message.rip_computers_user_printers))
                        return "rip_computers_user_printers: array expected";
                    for (let i = 0; i < message.rip_computers_user_printers.length; ++i) {
                        let error = $root.appcore.ripe.MappingRIPComputerUserPrinter.verify(message.rip_computers_user_printers[i]);
                        if (error)
                            return "rip_computers_user_printers." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a RIPComputerPrinterMappingData message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.RIPComputerPrinterMappingData
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.RIPComputerPrinterMappingData} RIPComputerPrinterMappingData
             */
            RIPComputerPrinterMappingData.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.RIPComputerPrinterMappingData)
                    return object;
                let message = new $root.appcore.ripe.RIPComputerPrinterMappingData();
                if (object.user_printers_rip_computers) {
                    if (!Array.isArray(object.user_printers_rip_computers))
                        throw TypeError(".appcore.ripe.RIPComputerPrinterMappingData.user_printers_rip_computers: array expected");
                    message.user_printers_rip_computers = [];
                    for (let i = 0; i < object.user_printers_rip_computers.length; ++i) {
                        if (typeof object.user_printers_rip_computers[i] !== "object")
                            throw TypeError(".appcore.ripe.RIPComputerPrinterMappingData.user_printers_rip_computers: object expected");
                        message.user_printers_rip_computers[i] = $root.appcore.ripe.MappingUserPrinterRIPComputer.fromObject(object.user_printers_rip_computers[i]);
                    }
                }
                if (object.rip_computers_user_printers) {
                    if (!Array.isArray(object.rip_computers_user_printers))
                        throw TypeError(".appcore.ripe.RIPComputerPrinterMappingData.rip_computers_user_printers: array expected");
                    message.rip_computers_user_printers = [];
                    for (let i = 0; i < object.rip_computers_user_printers.length; ++i) {
                        if (typeof object.rip_computers_user_printers[i] !== "object")
                            throw TypeError(".appcore.ripe.RIPComputerPrinterMappingData.rip_computers_user_printers: object expected");
                        message.rip_computers_user_printers[i] = $root.appcore.ripe.MappingRIPComputerUserPrinter.fromObject(object.rip_computers_user_printers[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a RIPComputerPrinterMappingData message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.RIPComputerPrinterMappingData
             * @static
             * @param {appcore.ripe.RIPComputerPrinterMappingData} message RIPComputerPrinterMappingData
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RIPComputerPrinterMappingData.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults) {
                    object.user_printers_rip_computers = [];
                    object.rip_computers_user_printers = [];
                }
                if (message.user_printers_rip_computers && message.user_printers_rip_computers.length) {
                    object.user_printers_rip_computers = [];
                    for (let j = 0; j < message.user_printers_rip_computers.length; ++j)
                        object.user_printers_rip_computers[j] = $root.appcore.ripe.MappingUserPrinterRIPComputer.toObject(message.user_printers_rip_computers[j], options);
                }
                if (message.rip_computers_user_printers && message.rip_computers_user_printers.length) {
                    object.rip_computers_user_printers = [];
                    for (let j = 0; j < message.rip_computers_user_printers.length; ++j)
                        object.rip_computers_user_printers[j] = $root.appcore.ripe.MappingRIPComputerUserPrinter.toObject(message.rip_computers_user_printers[j], options);
                }
                return object;
            };

            /**
             * Converts this RIPComputerPrinterMappingData to JSON.
             * @function toJSON
             * @memberof appcore.ripe.RIPComputerPrinterMappingData
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RIPComputerPrinterMappingData.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return RIPComputerPrinterMappingData;
        })();

        ripe.RIPComputers = (function() {

            /**
             * Properties of a RIPComputers.
             * @memberof appcore.ripe
             * @interface IRIPComputers
             * @property {Array.<appcore.ripe.IRIPComputer>|null} [rip_computers] RIPComputers rip_computers
             */

            /**
             * Constructs a new RIPComputers.
             * @memberof appcore.ripe
             * @classdesc Represents a RIPComputers.
             * @implements IRIPComputers
             * @constructor
             * @param {appcore.ripe.IRIPComputers=} [properties] Properties to set
             */
            function RIPComputers(properties) {
                this.rip_computers = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * RIPComputers rip_computers.
             * @member {Array.<appcore.ripe.IRIPComputer>} rip_computers
             * @memberof appcore.ripe.RIPComputers
             * @instance
             */
            RIPComputers.prototype.rip_computers = $util.emptyArray;

            /**
             * Creates a new RIPComputers instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.RIPComputers
             * @static
             * @param {appcore.ripe.IRIPComputers=} [properties] Properties to set
             * @returns {appcore.ripe.RIPComputers} RIPComputers instance
             */
            RIPComputers.create = function create(properties) {
                return new RIPComputers(properties);
            };

            /**
             * Encodes the specified RIPComputers message. Does not implicitly {@link appcore.ripe.RIPComputers.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.RIPComputers
             * @static
             * @param {appcore.ripe.IRIPComputers} message RIPComputers message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RIPComputers.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.rip_computers != null && message.rip_computers.length)
                    for (let i = 0; i < message.rip_computers.length; ++i)
                        $root.appcore.ripe.RIPComputer.encode(message.rip_computers[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified RIPComputers message, length delimited. Does not implicitly {@link appcore.ripe.RIPComputers.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.RIPComputers
             * @static
             * @param {appcore.ripe.IRIPComputers} message RIPComputers message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RIPComputers.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a RIPComputers message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.RIPComputers
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.RIPComputers} RIPComputers
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RIPComputers.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.RIPComputers();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.rip_computers && message.rip_computers.length))
                            message.rip_computers = [];
                        message.rip_computers.push($root.appcore.ripe.RIPComputer.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a RIPComputers message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.RIPComputers
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.RIPComputers} RIPComputers
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RIPComputers.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a RIPComputers message.
             * @function verify
             * @memberof appcore.ripe.RIPComputers
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RIPComputers.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.rip_computers != null && message.hasOwnProperty("rip_computers")) {
                    if (!Array.isArray(message.rip_computers))
                        return "rip_computers: array expected";
                    for (let i = 0; i < message.rip_computers.length; ++i) {
                        let error = $root.appcore.ripe.RIPComputer.verify(message.rip_computers[i]);
                        if (error)
                            return "rip_computers." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a RIPComputers message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.RIPComputers
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.RIPComputers} RIPComputers
             */
            RIPComputers.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.RIPComputers)
                    return object;
                let message = new $root.appcore.ripe.RIPComputers();
                if (object.rip_computers) {
                    if (!Array.isArray(object.rip_computers))
                        throw TypeError(".appcore.ripe.RIPComputers.rip_computers: array expected");
                    message.rip_computers = [];
                    for (let i = 0; i < object.rip_computers.length; ++i) {
                        if (typeof object.rip_computers[i] !== "object")
                            throw TypeError(".appcore.ripe.RIPComputers.rip_computers: object expected");
                        message.rip_computers[i] = $root.appcore.ripe.RIPComputer.fromObject(object.rip_computers[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a RIPComputers message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.RIPComputers
             * @static
             * @param {appcore.ripe.RIPComputers} message RIPComputers
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RIPComputers.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.rip_computers = [];
                if (message.rip_computers && message.rip_computers.length) {
                    object.rip_computers = [];
                    for (let j = 0; j < message.rip_computers.length; ++j)
                        object.rip_computers[j] = $root.appcore.ripe.RIPComputer.toObject(message.rip_computers[j], options);
                }
                return object;
            };

            /**
             * Converts this RIPComputers to JSON.
             * @function toJSON
             * @memberof appcore.ripe.RIPComputers
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RIPComputers.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return RIPComputers;
        })();

        /**
         * RipeServiceControlType enum.
         * @name appcore.ripe.RipeServiceControlType
         * @enum {number}
         * @property {number} SHUTDOWN=0 SHUTDOWN value
         */
        ripe.RipeServiceControlType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "SHUTDOWN"] = 0;
            return values;
        })();

        ripe.RipeServiceControl = (function() {

            /**
             * Properties of a RipeServiceControl.
             * @memberof appcore.ripe
             * @interface IRipeServiceControl
             * @property {appcore.ripe.RipeServiceControlType|null} [type] RipeServiceControl type
             */

            /**
             * Constructs a new RipeServiceControl.
             * @memberof appcore.ripe
             * @classdesc Represents a RipeServiceControl.
             * @implements IRipeServiceControl
             * @constructor
             * @param {appcore.ripe.IRipeServiceControl=} [properties] Properties to set
             */
            function RipeServiceControl(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * RipeServiceControl type.
             * @member {appcore.ripe.RipeServiceControlType} type
             * @memberof appcore.ripe.RipeServiceControl
             * @instance
             */
            RipeServiceControl.prototype.type = 0;

            /**
             * Creates a new RipeServiceControl instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.RipeServiceControl
             * @static
             * @param {appcore.ripe.IRipeServiceControl=} [properties] Properties to set
             * @returns {appcore.ripe.RipeServiceControl} RipeServiceControl instance
             */
            RipeServiceControl.create = function create(properties) {
                return new RipeServiceControl(properties);
            };

            /**
             * Encodes the specified RipeServiceControl message. Does not implicitly {@link appcore.ripe.RipeServiceControl.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.RipeServiceControl
             * @static
             * @param {appcore.ripe.IRipeServiceControl} message RipeServiceControl message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RipeServiceControl.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                return writer;
            };

            /**
             * Encodes the specified RipeServiceControl message, length delimited. Does not implicitly {@link appcore.ripe.RipeServiceControl.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.RipeServiceControl
             * @static
             * @param {appcore.ripe.IRipeServiceControl} message RipeServiceControl message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RipeServiceControl.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a RipeServiceControl message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.RipeServiceControl
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.RipeServiceControl} RipeServiceControl
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RipeServiceControl.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.RipeServiceControl();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.type = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a RipeServiceControl message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.RipeServiceControl
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.RipeServiceControl} RipeServiceControl
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RipeServiceControl.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a RipeServiceControl message.
             * @function verify
             * @memberof appcore.ripe.RipeServiceControl
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RipeServiceControl.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.type != null && message.hasOwnProperty("type"))
                    switch (message.type) {
                    default:
                        return "type: enum value expected";
                    case 0:
                        break;
                    }
                return null;
            };

            /**
             * Creates a RipeServiceControl message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.RipeServiceControl
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.RipeServiceControl} RipeServiceControl
             */
            RipeServiceControl.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.RipeServiceControl)
                    return object;
                let message = new $root.appcore.ripe.RipeServiceControl();
                switch (object.type) {
                case "SHUTDOWN":
                case 0:
                    message.type = 0;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a RipeServiceControl message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.RipeServiceControl
             * @static
             * @param {appcore.ripe.RipeServiceControl} message RipeServiceControl
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RipeServiceControl.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.type = options.enums === String ? "SHUTDOWN" : 0;
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = options.enums === String ? $root.appcore.ripe.RipeServiceControlType[message.type] : message.type;
                return object;
            };

            /**
             * Converts this RipeServiceControl to JSON.
             * @function toJSON
             * @memberof appcore.ripe.RipeServiceControl
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RipeServiceControl.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return RipeServiceControl;
        })();

        ripe.RIPPCFullHWEntries = (function() {

            /**
             * Properties of a RIPPCFullHWEntries.
             * @memberof appcore.ripe
             * @interface IRIPPCFullHWEntries
             * @property {number|Long|null} [rip_pc_id] RIPPCFullHWEntries rip_pc_id
             * @property {appcore.ripe.IHWEntries|null} [hw_entries] RIPPCFullHWEntries hw_entries
             */

            /**
             * Constructs a new RIPPCFullHWEntries.
             * @memberof appcore.ripe
             * @classdesc Represents a RIPPCFullHWEntries.
             * @implements IRIPPCFullHWEntries
             * @constructor
             * @param {appcore.ripe.IRIPPCFullHWEntries=} [properties] Properties to set
             */
            function RIPPCFullHWEntries(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * RIPPCFullHWEntries rip_pc_id.
             * @member {number|Long} rip_pc_id
             * @memberof appcore.ripe.RIPPCFullHWEntries
             * @instance
             */
            RIPPCFullHWEntries.prototype.rip_pc_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * RIPPCFullHWEntries hw_entries.
             * @member {appcore.ripe.IHWEntries|null|undefined} hw_entries
             * @memberof appcore.ripe.RIPPCFullHWEntries
             * @instance
             */
            RIPPCFullHWEntries.prototype.hw_entries = null;

            /**
             * Creates a new RIPPCFullHWEntries instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.RIPPCFullHWEntries
             * @static
             * @param {appcore.ripe.IRIPPCFullHWEntries=} [properties] Properties to set
             * @returns {appcore.ripe.RIPPCFullHWEntries} RIPPCFullHWEntries instance
             */
            RIPPCFullHWEntries.create = function create(properties) {
                return new RIPPCFullHWEntries(properties);
            };

            /**
             * Encodes the specified RIPPCFullHWEntries message. Does not implicitly {@link appcore.ripe.RIPPCFullHWEntries.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.RIPPCFullHWEntries
             * @static
             * @param {appcore.ripe.IRIPPCFullHWEntries} message RIPPCFullHWEntries message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RIPPCFullHWEntries.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.rip_pc_id != null && Object.hasOwnProperty.call(message, "rip_pc_id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.rip_pc_id);
                if (message.hw_entries != null && Object.hasOwnProperty.call(message, "hw_entries"))
                    $root.appcore.ripe.HWEntries.encode(message.hw_entries, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified RIPPCFullHWEntries message, length delimited. Does not implicitly {@link appcore.ripe.RIPPCFullHWEntries.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.RIPPCFullHWEntries
             * @static
             * @param {appcore.ripe.IRIPPCFullHWEntries} message RIPPCFullHWEntries message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RIPPCFullHWEntries.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a RIPPCFullHWEntries message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.RIPPCFullHWEntries
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.RIPPCFullHWEntries} RIPPCFullHWEntries
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RIPPCFullHWEntries.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.RIPPCFullHWEntries();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.rip_pc_id = reader.uint64();
                        break;
                    case 2:
                        message.hw_entries = $root.appcore.ripe.HWEntries.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a RIPPCFullHWEntries message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.RIPPCFullHWEntries
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.RIPPCFullHWEntries} RIPPCFullHWEntries
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RIPPCFullHWEntries.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a RIPPCFullHWEntries message.
             * @function verify
             * @memberof appcore.ripe.RIPPCFullHWEntries
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RIPPCFullHWEntries.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.rip_pc_id != null && message.hasOwnProperty("rip_pc_id"))
                    if (!$util.isInteger(message.rip_pc_id) && !(message.rip_pc_id && $util.isInteger(message.rip_pc_id.low) && $util.isInteger(message.rip_pc_id.high)))
                        return "rip_pc_id: integer|Long expected";
                if (message.hw_entries != null && message.hasOwnProperty("hw_entries")) {
                    let error = $root.appcore.ripe.HWEntries.verify(message.hw_entries);
                    if (error)
                        return "hw_entries." + error;
                }
                return null;
            };

            /**
             * Creates a RIPPCFullHWEntries message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.RIPPCFullHWEntries
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.RIPPCFullHWEntries} RIPPCFullHWEntries
             */
            RIPPCFullHWEntries.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.RIPPCFullHWEntries)
                    return object;
                let message = new $root.appcore.ripe.RIPPCFullHWEntries();
                if (object.rip_pc_id != null)
                    if ($util.Long)
                        (message.rip_pc_id = $util.Long.fromValue(object.rip_pc_id)).unsigned = true;
                    else if (typeof object.rip_pc_id === "string")
                        message.rip_pc_id = parseInt(object.rip_pc_id, 10);
                    else if (typeof object.rip_pc_id === "number")
                        message.rip_pc_id = object.rip_pc_id;
                    else if (typeof object.rip_pc_id === "object")
                        message.rip_pc_id = new $util.LongBits(object.rip_pc_id.low >>> 0, object.rip_pc_id.high >>> 0).toNumber(true);
                if (object.hw_entries != null) {
                    if (typeof object.hw_entries !== "object")
                        throw TypeError(".appcore.ripe.RIPPCFullHWEntries.hw_entries: object expected");
                    message.hw_entries = $root.appcore.ripe.HWEntries.fromObject(object.hw_entries);
                }
                return message;
            };

            /**
             * Creates a plain object from a RIPPCFullHWEntries message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.RIPPCFullHWEntries
             * @static
             * @param {appcore.ripe.RIPPCFullHWEntries} message RIPPCFullHWEntries
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RIPPCFullHWEntries.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.rip_pc_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.rip_pc_id = options.longs === String ? "0" : 0;
                    object.hw_entries = null;
                }
                if (message.rip_pc_id != null && message.hasOwnProperty("rip_pc_id"))
                    if (typeof message.rip_pc_id === "number")
                        object.rip_pc_id = options.longs === String ? String(message.rip_pc_id) : message.rip_pc_id;
                    else
                        object.rip_pc_id = options.longs === String ? $util.Long.prototype.toString.call(message.rip_pc_id) : options.longs === Number ? new $util.LongBits(message.rip_pc_id.low >>> 0, message.rip_pc_id.high >>> 0).toNumber(true) : message.rip_pc_id;
                if (message.hw_entries != null && message.hasOwnProperty("hw_entries"))
                    object.hw_entries = $root.appcore.ripe.HWEntries.toObject(message.hw_entries, options);
                return object;
            };

            /**
             * Converts this RIPPCFullHWEntries to JSON.
             * @function toJSON
             * @memberof appcore.ripe.RIPPCFullHWEntries
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RIPPCFullHWEntries.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return RIPPCFullHWEntries;
        })();

        ripe.RIPPCRegistration = (function() {

            /**
             * Properties of a RIPPCRegistration.
             * @memberof appcore.ripe
             * @interface IRIPPCRegistration
             * @property {string|null} [device_id] RIPPCRegistration device_id
             * @property {string|null} [qr_data] RIPPCRegistration qr_data
             */

            /**
             * Constructs a new RIPPCRegistration.
             * @memberof appcore.ripe
             * @classdesc Represents a RIPPCRegistration.
             * @implements IRIPPCRegistration
             * @constructor
             * @param {appcore.ripe.IRIPPCRegistration=} [properties] Properties to set
             */
            function RIPPCRegistration(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * RIPPCRegistration device_id.
             * @member {string} device_id
             * @memberof appcore.ripe.RIPPCRegistration
             * @instance
             */
            RIPPCRegistration.prototype.device_id = "";

            /**
             * RIPPCRegistration qr_data.
             * @member {string} qr_data
             * @memberof appcore.ripe.RIPPCRegistration
             * @instance
             */
            RIPPCRegistration.prototype.qr_data = "";

            /**
             * Creates a new RIPPCRegistration instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.RIPPCRegistration
             * @static
             * @param {appcore.ripe.IRIPPCRegistration=} [properties] Properties to set
             * @returns {appcore.ripe.RIPPCRegistration} RIPPCRegistration instance
             */
            RIPPCRegistration.create = function create(properties) {
                return new RIPPCRegistration(properties);
            };

            /**
             * Encodes the specified RIPPCRegistration message. Does not implicitly {@link appcore.ripe.RIPPCRegistration.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.RIPPCRegistration
             * @static
             * @param {appcore.ripe.IRIPPCRegistration} message RIPPCRegistration message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RIPPCRegistration.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.device_id != null && Object.hasOwnProperty.call(message, "device_id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.device_id);
                if (message.qr_data != null && Object.hasOwnProperty.call(message, "qr_data"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.qr_data);
                return writer;
            };

            /**
             * Encodes the specified RIPPCRegistration message, length delimited. Does not implicitly {@link appcore.ripe.RIPPCRegistration.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.RIPPCRegistration
             * @static
             * @param {appcore.ripe.IRIPPCRegistration} message RIPPCRegistration message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RIPPCRegistration.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a RIPPCRegistration message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.RIPPCRegistration
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.RIPPCRegistration} RIPPCRegistration
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RIPPCRegistration.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.RIPPCRegistration();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.device_id = reader.string();
                        break;
                    case 2:
                        message.qr_data = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a RIPPCRegistration message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.RIPPCRegistration
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.RIPPCRegistration} RIPPCRegistration
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RIPPCRegistration.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a RIPPCRegistration message.
             * @function verify
             * @memberof appcore.ripe.RIPPCRegistration
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RIPPCRegistration.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.device_id != null && message.hasOwnProperty("device_id"))
                    if (!$util.isString(message.device_id))
                        return "device_id: string expected";
                if (message.qr_data != null && message.hasOwnProperty("qr_data"))
                    if (!$util.isString(message.qr_data))
                        return "qr_data: string expected";
                return null;
            };

            /**
             * Creates a RIPPCRegistration message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.RIPPCRegistration
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.RIPPCRegistration} RIPPCRegistration
             */
            RIPPCRegistration.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.RIPPCRegistration)
                    return object;
                let message = new $root.appcore.ripe.RIPPCRegistration();
                if (object.device_id != null)
                    message.device_id = String(object.device_id);
                if (object.qr_data != null)
                    message.qr_data = String(object.qr_data);
                return message;
            };

            /**
             * Creates a plain object from a RIPPCRegistration message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.RIPPCRegistration
             * @static
             * @param {appcore.ripe.RIPPCRegistration} message RIPPCRegistration
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RIPPCRegistration.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.device_id = "";
                    object.qr_data = "";
                }
                if (message.device_id != null && message.hasOwnProperty("device_id"))
                    object.device_id = message.device_id;
                if (message.qr_data != null && message.hasOwnProperty("qr_data"))
                    object.qr_data = message.qr_data;
                return object;
            };

            /**
             * Converts this RIPPCRegistration to JSON.
             * @function toJSON
             * @memberof appcore.ripe.RIPPCRegistration
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RIPPCRegistration.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return RIPPCRegistration;
        })();

        ripe.RIPPCRegistrationCheck = (function() {

            /**
             * Properties of a RIPPCRegistrationCheck.
             * @memberof appcore.ripe
             * @interface IRIPPCRegistrationCheck
             * @property {appcore.ripe.IHWEntries|null} [hw_entries] RIPPCRegistrationCheck hw_entries
             * @property {string|null} [utc_time] RIPPCRegistrationCheck utc_time
             */

            /**
             * Constructs a new RIPPCRegistrationCheck.
             * @memberof appcore.ripe
             * @classdesc Represents a RIPPCRegistrationCheck.
             * @implements IRIPPCRegistrationCheck
             * @constructor
             * @param {appcore.ripe.IRIPPCRegistrationCheck=} [properties] Properties to set
             */
            function RIPPCRegistrationCheck(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * RIPPCRegistrationCheck hw_entries.
             * @member {appcore.ripe.IHWEntries|null|undefined} hw_entries
             * @memberof appcore.ripe.RIPPCRegistrationCheck
             * @instance
             */
            RIPPCRegistrationCheck.prototype.hw_entries = null;

            /**
             * RIPPCRegistrationCheck utc_time.
             * @member {string} utc_time
             * @memberof appcore.ripe.RIPPCRegistrationCheck
             * @instance
             */
            RIPPCRegistrationCheck.prototype.utc_time = "";

            /**
             * Creates a new RIPPCRegistrationCheck instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.RIPPCRegistrationCheck
             * @static
             * @param {appcore.ripe.IRIPPCRegistrationCheck=} [properties] Properties to set
             * @returns {appcore.ripe.RIPPCRegistrationCheck} RIPPCRegistrationCheck instance
             */
            RIPPCRegistrationCheck.create = function create(properties) {
                return new RIPPCRegistrationCheck(properties);
            };

            /**
             * Encodes the specified RIPPCRegistrationCheck message. Does not implicitly {@link appcore.ripe.RIPPCRegistrationCheck.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.RIPPCRegistrationCheck
             * @static
             * @param {appcore.ripe.IRIPPCRegistrationCheck} message RIPPCRegistrationCheck message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RIPPCRegistrationCheck.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.hw_entries != null && Object.hasOwnProperty.call(message, "hw_entries"))
                    $root.appcore.ripe.HWEntries.encode(message.hw_entries, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.utc_time != null && Object.hasOwnProperty.call(message, "utc_time"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.utc_time);
                return writer;
            };

            /**
             * Encodes the specified RIPPCRegistrationCheck message, length delimited. Does not implicitly {@link appcore.ripe.RIPPCRegistrationCheck.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.RIPPCRegistrationCheck
             * @static
             * @param {appcore.ripe.IRIPPCRegistrationCheck} message RIPPCRegistrationCheck message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RIPPCRegistrationCheck.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a RIPPCRegistrationCheck message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.RIPPCRegistrationCheck
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.RIPPCRegistrationCheck} RIPPCRegistrationCheck
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RIPPCRegistrationCheck.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.RIPPCRegistrationCheck();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.hw_entries = $root.appcore.ripe.HWEntries.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.utc_time = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a RIPPCRegistrationCheck message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.RIPPCRegistrationCheck
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.RIPPCRegistrationCheck} RIPPCRegistrationCheck
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RIPPCRegistrationCheck.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a RIPPCRegistrationCheck message.
             * @function verify
             * @memberof appcore.ripe.RIPPCRegistrationCheck
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RIPPCRegistrationCheck.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.hw_entries != null && message.hasOwnProperty("hw_entries")) {
                    let error = $root.appcore.ripe.HWEntries.verify(message.hw_entries);
                    if (error)
                        return "hw_entries." + error;
                }
                if (message.utc_time != null && message.hasOwnProperty("utc_time"))
                    if (!$util.isString(message.utc_time))
                        return "utc_time: string expected";
                return null;
            };

            /**
             * Creates a RIPPCRegistrationCheck message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.RIPPCRegistrationCheck
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.RIPPCRegistrationCheck} RIPPCRegistrationCheck
             */
            RIPPCRegistrationCheck.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.RIPPCRegistrationCheck)
                    return object;
                let message = new $root.appcore.ripe.RIPPCRegistrationCheck();
                if (object.hw_entries != null) {
                    if (typeof object.hw_entries !== "object")
                        throw TypeError(".appcore.ripe.RIPPCRegistrationCheck.hw_entries: object expected");
                    message.hw_entries = $root.appcore.ripe.HWEntries.fromObject(object.hw_entries);
                }
                if (object.utc_time != null)
                    message.utc_time = String(object.utc_time);
                return message;
            };

            /**
             * Creates a plain object from a RIPPCRegistrationCheck message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.RIPPCRegistrationCheck
             * @static
             * @param {appcore.ripe.RIPPCRegistrationCheck} message RIPPCRegistrationCheck
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RIPPCRegistrationCheck.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.hw_entries = null;
                    object.utc_time = "";
                }
                if (message.hw_entries != null && message.hasOwnProperty("hw_entries"))
                    object.hw_entries = $root.appcore.ripe.HWEntries.toObject(message.hw_entries, options);
                if (message.utc_time != null && message.hasOwnProperty("utc_time"))
                    object.utc_time = message.utc_time;
                return object;
            };

            /**
             * Converts this RIPPCRegistrationCheck to JSON.
             * @function toJSON
             * @memberof appcore.ripe.RIPPCRegistrationCheck
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RIPPCRegistrationCheck.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return RIPPCRegistrationCheck;
        })();

        ripe.RIPPCRegistrationCheckResult = (function() {

            /**
             * Properties of a RIPPCRegistrationCheckResult.
             * @memberof appcore.ripe
             * @interface IRIPPCRegistrationCheckResult
             * @property {boolean|null} [valid] RIPPCRegistrationCheckResult valid
             * @property {boolean|null} [send_full_hw_info] RIPPCRegistrationCheckResult send_full_hw_info
             * @property {number|Long|null} [rip_pc_id] RIPPCRegistrationCheckResult rip_pc_id
             * @property {appcore.ripe.IRIPPCUserData|null} [user_data] RIPPCRegistrationCheckResult user_data
             * @property {string|null} [log_upload_url] RIPPCRegistrationCheckResult log_upload_url
             * @property {boolean|null} [has_license] RIPPCRegistrationCheckResult has_license
             */

            /**
             * Constructs a new RIPPCRegistrationCheckResult.
             * @memberof appcore.ripe
             * @classdesc Represents a RIPPCRegistrationCheckResult.
             * @implements IRIPPCRegistrationCheckResult
             * @constructor
             * @param {appcore.ripe.IRIPPCRegistrationCheckResult=} [properties] Properties to set
             */
            function RIPPCRegistrationCheckResult(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * RIPPCRegistrationCheckResult valid.
             * @member {boolean} valid
             * @memberof appcore.ripe.RIPPCRegistrationCheckResult
             * @instance
             */
            RIPPCRegistrationCheckResult.prototype.valid = false;

            /**
             * RIPPCRegistrationCheckResult send_full_hw_info.
             * @member {boolean} send_full_hw_info
             * @memberof appcore.ripe.RIPPCRegistrationCheckResult
             * @instance
             */
            RIPPCRegistrationCheckResult.prototype.send_full_hw_info = false;

            /**
             * RIPPCRegistrationCheckResult rip_pc_id.
             * @member {number|Long} rip_pc_id
             * @memberof appcore.ripe.RIPPCRegistrationCheckResult
             * @instance
             */
            RIPPCRegistrationCheckResult.prototype.rip_pc_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * RIPPCRegistrationCheckResult user_data.
             * @member {appcore.ripe.IRIPPCUserData|null|undefined} user_data
             * @memberof appcore.ripe.RIPPCRegistrationCheckResult
             * @instance
             */
            RIPPCRegistrationCheckResult.prototype.user_data = null;

            /**
             * RIPPCRegistrationCheckResult log_upload_url.
             * @member {string} log_upload_url
             * @memberof appcore.ripe.RIPPCRegistrationCheckResult
             * @instance
             */
            RIPPCRegistrationCheckResult.prototype.log_upload_url = "";

            /**
             * RIPPCRegistrationCheckResult has_license.
             * @member {boolean} has_license
             * @memberof appcore.ripe.RIPPCRegistrationCheckResult
             * @instance
             */
            RIPPCRegistrationCheckResult.prototype.has_license = false;

            /**
             * Creates a new RIPPCRegistrationCheckResult instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.RIPPCRegistrationCheckResult
             * @static
             * @param {appcore.ripe.IRIPPCRegistrationCheckResult=} [properties] Properties to set
             * @returns {appcore.ripe.RIPPCRegistrationCheckResult} RIPPCRegistrationCheckResult instance
             */
            RIPPCRegistrationCheckResult.create = function create(properties) {
                return new RIPPCRegistrationCheckResult(properties);
            };

            /**
             * Encodes the specified RIPPCRegistrationCheckResult message. Does not implicitly {@link appcore.ripe.RIPPCRegistrationCheckResult.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.RIPPCRegistrationCheckResult
             * @static
             * @param {appcore.ripe.IRIPPCRegistrationCheckResult} message RIPPCRegistrationCheckResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RIPPCRegistrationCheckResult.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.valid != null && Object.hasOwnProperty.call(message, "valid"))
                    writer.uint32(/* id 1, wireType 0 =*/8).bool(message.valid);
                if (message.send_full_hw_info != null && Object.hasOwnProperty.call(message, "send_full_hw_info"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.send_full_hw_info);
                if (message.rip_pc_id != null && Object.hasOwnProperty.call(message, "rip_pc_id"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.rip_pc_id);
                if (message.user_data != null && Object.hasOwnProperty.call(message, "user_data"))
                    $root.appcore.ripe.RIPPCUserData.encode(message.user_data, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.log_upload_url != null && Object.hasOwnProperty.call(message, "log_upload_url"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.log_upload_url);
                if (message.has_license != null && Object.hasOwnProperty.call(message, "has_license"))
                    writer.uint32(/* id 6, wireType 0 =*/48).bool(message.has_license);
                return writer;
            };

            /**
             * Encodes the specified RIPPCRegistrationCheckResult message, length delimited. Does not implicitly {@link appcore.ripe.RIPPCRegistrationCheckResult.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.RIPPCRegistrationCheckResult
             * @static
             * @param {appcore.ripe.IRIPPCRegistrationCheckResult} message RIPPCRegistrationCheckResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RIPPCRegistrationCheckResult.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a RIPPCRegistrationCheckResult message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.RIPPCRegistrationCheckResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.RIPPCRegistrationCheckResult} RIPPCRegistrationCheckResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RIPPCRegistrationCheckResult.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.RIPPCRegistrationCheckResult();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.valid = reader.bool();
                        break;
                    case 2:
                        message.send_full_hw_info = reader.bool();
                        break;
                    case 3:
                        message.rip_pc_id = reader.uint64();
                        break;
                    case 4:
                        message.user_data = $root.appcore.ripe.RIPPCUserData.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.log_upload_url = reader.string();
                        break;
                    case 6:
                        message.has_license = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a RIPPCRegistrationCheckResult message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.RIPPCRegistrationCheckResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.RIPPCRegistrationCheckResult} RIPPCRegistrationCheckResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RIPPCRegistrationCheckResult.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a RIPPCRegistrationCheckResult message.
             * @function verify
             * @memberof appcore.ripe.RIPPCRegistrationCheckResult
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RIPPCRegistrationCheckResult.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.valid != null && message.hasOwnProperty("valid"))
                    if (typeof message.valid !== "boolean")
                        return "valid: boolean expected";
                if (message.send_full_hw_info != null && message.hasOwnProperty("send_full_hw_info"))
                    if (typeof message.send_full_hw_info !== "boolean")
                        return "send_full_hw_info: boolean expected";
                if (message.rip_pc_id != null && message.hasOwnProperty("rip_pc_id"))
                    if (!$util.isInteger(message.rip_pc_id) && !(message.rip_pc_id && $util.isInteger(message.rip_pc_id.low) && $util.isInteger(message.rip_pc_id.high)))
                        return "rip_pc_id: integer|Long expected";
                if (message.user_data != null && message.hasOwnProperty("user_data")) {
                    let error = $root.appcore.ripe.RIPPCUserData.verify(message.user_data);
                    if (error)
                        return "user_data." + error;
                }
                if (message.log_upload_url != null && message.hasOwnProperty("log_upload_url"))
                    if (!$util.isString(message.log_upload_url))
                        return "log_upload_url: string expected";
                if (message.has_license != null && message.hasOwnProperty("has_license"))
                    if (typeof message.has_license !== "boolean")
                        return "has_license: boolean expected";
                return null;
            };

            /**
             * Creates a RIPPCRegistrationCheckResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.RIPPCRegistrationCheckResult
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.RIPPCRegistrationCheckResult} RIPPCRegistrationCheckResult
             */
            RIPPCRegistrationCheckResult.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.RIPPCRegistrationCheckResult)
                    return object;
                let message = new $root.appcore.ripe.RIPPCRegistrationCheckResult();
                if (object.valid != null)
                    message.valid = Boolean(object.valid);
                if (object.send_full_hw_info != null)
                    message.send_full_hw_info = Boolean(object.send_full_hw_info);
                if (object.rip_pc_id != null)
                    if ($util.Long)
                        (message.rip_pc_id = $util.Long.fromValue(object.rip_pc_id)).unsigned = true;
                    else if (typeof object.rip_pc_id === "string")
                        message.rip_pc_id = parseInt(object.rip_pc_id, 10);
                    else if (typeof object.rip_pc_id === "number")
                        message.rip_pc_id = object.rip_pc_id;
                    else if (typeof object.rip_pc_id === "object")
                        message.rip_pc_id = new $util.LongBits(object.rip_pc_id.low >>> 0, object.rip_pc_id.high >>> 0).toNumber(true);
                if (object.user_data != null) {
                    if (typeof object.user_data !== "object")
                        throw TypeError(".appcore.ripe.RIPPCRegistrationCheckResult.user_data: object expected");
                    message.user_data = $root.appcore.ripe.RIPPCUserData.fromObject(object.user_data);
                }
                if (object.log_upload_url != null)
                    message.log_upload_url = String(object.log_upload_url);
                if (object.has_license != null)
                    message.has_license = Boolean(object.has_license);
                return message;
            };

            /**
             * Creates a plain object from a RIPPCRegistrationCheckResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.RIPPCRegistrationCheckResult
             * @static
             * @param {appcore.ripe.RIPPCRegistrationCheckResult} message RIPPCRegistrationCheckResult
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RIPPCRegistrationCheckResult.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.valid = false;
                    object.send_full_hw_info = false;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.rip_pc_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.rip_pc_id = options.longs === String ? "0" : 0;
                    object.user_data = null;
                    object.log_upload_url = "";
                    object.has_license = false;
                }
                if (message.valid != null && message.hasOwnProperty("valid"))
                    object.valid = message.valid;
                if (message.send_full_hw_info != null && message.hasOwnProperty("send_full_hw_info"))
                    object.send_full_hw_info = message.send_full_hw_info;
                if (message.rip_pc_id != null && message.hasOwnProperty("rip_pc_id"))
                    if (typeof message.rip_pc_id === "number")
                        object.rip_pc_id = options.longs === String ? String(message.rip_pc_id) : message.rip_pc_id;
                    else
                        object.rip_pc_id = options.longs === String ? $util.Long.prototype.toString.call(message.rip_pc_id) : options.longs === Number ? new $util.LongBits(message.rip_pc_id.low >>> 0, message.rip_pc_id.high >>> 0).toNumber(true) : message.rip_pc_id;
                if (message.user_data != null && message.hasOwnProperty("user_data"))
                    object.user_data = $root.appcore.ripe.RIPPCUserData.toObject(message.user_data, options);
                if (message.log_upload_url != null && message.hasOwnProperty("log_upload_url"))
                    object.log_upload_url = message.log_upload_url;
                if (message.has_license != null && message.hasOwnProperty("has_license"))
                    object.has_license = message.has_license;
                return object;
            };

            /**
             * Converts this RIPPCRegistrationCheckResult to JSON.
             * @function toJSON
             * @memberof appcore.ripe.RIPPCRegistrationCheckResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RIPPCRegistrationCheckResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return RIPPCRegistrationCheckResult;
        })();

        ripe.RIPPCUserDataPrinter = (function() {

            /**
             * Properties of a RIPPCUserDataPrinter.
             * @memberof appcore.ripe
             * @interface IRIPPCUserDataPrinter
             * @property {number|Long|null} [id] RIPPCUserDataPrinter id
             * @property {number|null} [output_code] RIPPCUserDataPrinter output_code
             * @property {string|null} [disk_serial_number] RIPPCUserDataPrinter disk_serial_number
             * @property {string|null} [system_serial_number] RIPPCUserDataPrinter system_serial_number
             * @property {string|null} [name] RIPPCUserDataPrinter name
             */

            /**
             * Constructs a new RIPPCUserDataPrinter.
             * @memberof appcore.ripe
             * @classdesc Represents a RIPPCUserDataPrinter.
             * @implements IRIPPCUserDataPrinter
             * @constructor
             * @param {appcore.ripe.IRIPPCUserDataPrinter=} [properties] Properties to set
             */
            function RIPPCUserDataPrinter(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * RIPPCUserDataPrinter id.
             * @member {number|Long} id
             * @memberof appcore.ripe.RIPPCUserDataPrinter
             * @instance
             */
            RIPPCUserDataPrinter.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * RIPPCUserDataPrinter output_code.
             * @member {number} output_code
             * @memberof appcore.ripe.RIPPCUserDataPrinter
             * @instance
             */
            RIPPCUserDataPrinter.prototype.output_code = 0;

            /**
             * RIPPCUserDataPrinter disk_serial_number.
             * @member {string} disk_serial_number
             * @memberof appcore.ripe.RIPPCUserDataPrinter
             * @instance
             */
            RIPPCUserDataPrinter.prototype.disk_serial_number = "";

            /**
             * RIPPCUserDataPrinter system_serial_number.
             * @member {string} system_serial_number
             * @memberof appcore.ripe.RIPPCUserDataPrinter
             * @instance
             */
            RIPPCUserDataPrinter.prototype.system_serial_number = "";

            /**
             * RIPPCUserDataPrinter name.
             * @member {string} name
             * @memberof appcore.ripe.RIPPCUserDataPrinter
             * @instance
             */
            RIPPCUserDataPrinter.prototype.name = "";

            /**
             * Creates a new RIPPCUserDataPrinter instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.RIPPCUserDataPrinter
             * @static
             * @param {appcore.ripe.IRIPPCUserDataPrinter=} [properties] Properties to set
             * @returns {appcore.ripe.RIPPCUserDataPrinter} RIPPCUserDataPrinter instance
             */
            RIPPCUserDataPrinter.create = function create(properties) {
                return new RIPPCUserDataPrinter(properties);
            };

            /**
             * Encodes the specified RIPPCUserDataPrinter message. Does not implicitly {@link appcore.ripe.RIPPCUserDataPrinter.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.RIPPCUserDataPrinter
             * @static
             * @param {appcore.ripe.IRIPPCUserDataPrinter} message RIPPCUserDataPrinter message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RIPPCUserDataPrinter.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                if (message.output_code != null && Object.hasOwnProperty.call(message, "output_code"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.output_code);
                if (message.disk_serial_number != null && Object.hasOwnProperty.call(message, "disk_serial_number"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.disk_serial_number);
                if (message.system_serial_number != null && Object.hasOwnProperty.call(message, "system_serial_number"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.system_serial_number);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.name);
                return writer;
            };

            /**
             * Encodes the specified RIPPCUserDataPrinter message, length delimited. Does not implicitly {@link appcore.ripe.RIPPCUserDataPrinter.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.RIPPCUserDataPrinter
             * @static
             * @param {appcore.ripe.IRIPPCUserDataPrinter} message RIPPCUserDataPrinter message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RIPPCUserDataPrinter.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a RIPPCUserDataPrinter message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.RIPPCUserDataPrinter
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.RIPPCUserDataPrinter} RIPPCUserDataPrinter
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RIPPCUserDataPrinter.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.RIPPCUserDataPrinter();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.int64();
                        break;
                    case 2:
                        message.output_code = reader.int32();
                        break;
                    case 3:
                        message.disk_serial_number = reader.string();
                        break;
                    case 4:
                        message.system_serial_number = reader.string();
                        break;
                    case 5:
                        message.name = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a RIPPCUserDataPrinter message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.RIPPCUserDataPrinter
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.RIPPCUserDataPrinter} RIPPCUserDataPrinter
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RIPPCUserDataPrinter.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a RIPPCUserDataPrinter message.
             * @function verify
             * @memberof appcore.ripe.RIPPCUserDataPrinter
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RIPPCUserDataPrinter.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                if (message.output_code != null && message.hasOwnProperty("output_code"))
                    if (!$util.isInteger(message.output_code))
                        return "output_code: integer expected";
                if (message.disk_serial_number != null && message.hasOwnProperty("disk_serial_number"))
                    if (!$util.isString(message.disk_serial_number))
                        return "disk_serial_number: string expected";
                if (message.system_serial_number != null && message.hasOwnProperty("system_serial_number"))
                    if (!$util.isString(message.system_serial_number))
                        return "system_serial_number: string expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                return null;
            };

            /**
             * Creates a RIPPCUserDataPrinter message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.RIPPCUserDataPrinter
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.RIPPCUserDataPrinter} RIPPCUserDataPrinter
             */
            RIPPCUserDataPrinter.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.RIPPCUserDataPrinter)
                    return object;
                let message = new $root.appcore.ripe.RIPPCUserDataPrinter();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                if (object.output_code != null)
                    message.output_code = object.output_code | 0;
                if (object.disk_serial_number != null)
                    message.disk_serial_number = String(object.disk_serial_number);
                if (object.system_serial_number != null)
                    message.system_serial_number = String(object.system_serial_number);
                if (object.name != null)
                    message.name = String(object.name);
                return message;
            };

            /**
             * Creates a plain object from a RIPPCUserDataPrinter message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.RIPPCUserDataPrinter
             * @static
             * @param {appcore.ripe.RIPPCUserDataPrinter} message RIPPCUserDataPrinter
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RIPPCUserDataPrinter.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                    object.output_code = 0;
                    object.disk_serial_number = "";
                    object.system_serial_number = "";
                    object.name = "";
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                if (message.output_code != null && message.hasOwnProperty("output_code"))
                    object.output_code = message.output_code;
                if (message.disk_serial_number != null && message.hasOwnProperty("disk_serial_number"))
                    object.disk_serial_number = message.disk_serial_number;
                if (message.system_serial_number != null && message.hasOwnProperty("system_serial_number"))
                    object.system_serial_number = message.system_serial_number;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                return object;
            };

            /**
             * Converts this RIPPCUserDataPrinter to JSON.
             * @function toJSON
             * @memberof appcore.ripe.RIPPCUserDataPrinter
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RIPPCUserDataPrinter.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return RIPPCUserDataPrinter;
        })();

        ripe.RIPPCUserDataProfile = (function() {

            /**
             * Properties of a RIPPCUserDataProfile.
             * @memberof appcore.ripe
             * @interface IRIPPCUserDataProfile
             * @property {string|null} [guid] RIPPCUserDataProfile guid
             * @property {string|null} [orig_name] RIPPCUserDataProfile orig_name
             * @property {number|null} [crc] RIPPCUserDataProfile crc
             * @property {string|null} [download_url] RIPPCUserDataProfile download_url
             * @property {boolean|null} [downloadable] RIPPCUserDataProfile downloadable
             */

            /**
             * Constructs a new RIPPCUserDataProfile.
             * @memberof appcore.ripe
             * @classdesc Represents a RIPPCUserDataProfile.
             * @implements IRIPPCUserDataProfile
             * @constructor
             * @param {appcore.ripe.IRIPPCUserDataProfile=} [properties] Properties to set
             */
            function RIPPCUserDataProfile(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * RIPPCUserDataProfile guid.
             * @member {string} guid
             * @memberof appcore.ripe.RIPPCUserDataProfile
             * @instance
             */
            RIPPCUserDataProfile.prototype.guid = "";

            /**
             * RIPPCUserDataProfile orig_name.
             * @member {string} orig_name
             * @memberof appcore.ripe.RIPPCUserDataProfile
             * @instance
             */
            RIPPCUserDataProfile.prototype.orig_name = "";

            /**
             * RIPPCUserDataProfile crc.
             * @member {number} crc
             * @memberof appcore.ripe.RIPPCUserDataProfile
             * @instance
             */
            RIPPCUserDataProfile.prototype.crc = 0;

            /**
             * RIPPCUserDataProfile download_url.
             * @member {string} download_url
             * @memberof appcore.ripe.RIPPCUserDataProfile
             * @instance
             */
            RIPPCUserDataProfile.prototype.download_url = "";

            /**
             * RIPPCUserDataProfile downloadable.
             * @member {boolean} downloadable
             * @memberof appcore.ripe.RIPPCUserDataProfile
             * @instance
             */
            RIPPCUserDataProfile.prototype.downloadable = false;

            /**
             * Creates a new RIPPCUserDataProfile instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.RIPPCUserDataProfile
             * @static
             * @param {appcore.ripe.IRIPPCUserDataProfile=} [properties] Properties to set
             * @returns {appcore.ripe.RIPPCUserDataProfile} RIPPCUserDataProfile instance
             */
            RIPPCUserDataProfile.create = function create(properties) {
                return new RIPPCUserDataProfile(properties);
            };

            /**
             * Encodes the specified RIPPCUserDataProfile message. Does not implicitly {@link appcore.ripe.RIPPCUserDataProfile.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.RIPPCUserDataProfile
             * @static
             * @param {appcore.ripe.IRIPPCUserDataProfile} message RIPPCUserDataProfile message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RIPPCUserDataProfile.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.guid != null && Object.hasOwnProperty.call(message, "guid"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.guid);
                if (message.orig_name != null && Object.hasOwnProperty.call(message, "orig_name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.orig_name);
                if (message.crc != null && Object.hasOwnProperty.call(message, "crc"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.crc);
                if (message.download_url != null && Object.hasOwnProperty.call(message, "download_url"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.download_url);
                if (message.downloadable != null && Object.hasOwnProperty.call(message, "downloadable"))
                    writer.uint32(/* id 5, wireType 0 =*/40).bool(message.downloadable);
                return writer;
            };

            /**
             * Encodes the specified RIPPCUserDataProfile message, length delimited. Does not implicitly {@link appcore.ripe.RIPPCUserDataProfile.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.RIPPCUserDataProfile
             * @static
             * @param {appcore.ripe.IRIPPCUserDataProfile} message RIPPCUserDataProfile message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RIPPCUserDataProfile.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a RIPPCUserDataProfile message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.RIPPCUserDataProfile
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.RIPPCUserDataProfile} RIPPCUserDataProfile
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RIPPCUserDataProfile.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.RIPPCUserDataProfile();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.guid = reader.string();
                        break;
                    case 2:
                        message.orig_name = reader.string();
                        break;
                    case 3:
                        message.crc = reader.uint32();
                        break;
                    case 4:
                        message.download_url = reader.string();
                        break;
                    case 5:
                        message.downloadable = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a RIPPCUserDataProfile message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.RIPPCUserDataProfile
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.RIPPCUserDataProfile} RIPPCUserDataProfile
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RIPPCUserDataProfile.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a RIPPCUserDataProfile message.
             * @function verify
             * @memberof appcore.ripe.RIPPCUserDataProfile
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RIPPCUserDataProfile.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.guid != null && message.hasOwnProperty("guid"))
                    if (!$util.isString(message.guid))
                        return "guid: string expected";
                if (message.orig_name != null && message.hasOwnProperty("orig_name"))
                    if (!$util.isString(message.orig_name))
                        return "orig_name: string expected";
                if (message.crc != null && message.hasOwnProperty("crc"))
                    if (!$util.isInteger(message.crc))
                        return "crc: integer expected";
                if (message.download_url != null && message.hasOwnProperty("download_url"))
                    if (!$util.isString(message.download_url))
                        return "download_url: string expected";
                if (message.downloadable != null && message.hasOwnProperty("downloadable"))
                    if (typeof message.downloadable !== "boolean")
                        return "downloadable: boolean expected";
                return null;
            };

            /**
             * Creates a RIPPCUserDataProfile message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.RIPPCUserDataProfile
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.RIPPCUserDataProfile} RIPPCUserDataProfile
             */
            RIPPCUserDataProfile.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.RIPPCUserDataProfile)
                    return object;
                let message = new $root.appcore.ripe.RIPPCUserDataProfile();
                if (object.guid != null)
                    message.guid = String(object.guid);
                if (object.orig_name != null)
                    message.orig_name = String(object.orig_name);
                if (object.crc != null)
                    message.crc = object.crc >>> 0;
                if (object.download_url != null)
                    message.download_url = String(object.download_url);
                if (object.downloadable != null)
                    message.downloadable = Boolean(object.downloadable);
                return message;
            };

            /**
             * Creates a plain object from a RIPPCUserDataProfile message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.RIPPCUserDataProfile
             * @static
             * @param {appcore.ripe.RIPPCUserDataProfile} message RIPPCUserDataProfile
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RIPPCUserDataProfile.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.guid = "";
                    object.orig_name = "";
                    object.crc = 0;
                    object.download_url = "";
                    object.downloadable = false;
                }
                if (message.guid != null && message.hasOwnProperty("guid"))
                    object.guid = message.guid;
                if (message.orig_name != null && message.hasOwnProperty("orig_name"))
                    object.orig_name = message.orig_name;
                if (message.crc != null && message.hasOwnProperty("crc"))
                    object.crc = message.crc;
                if (message.download_url != null && message.hasOwnProperty("download_url"))
                    object.download_url = message.download_url;
                if (message.downloadable != null && message.hasOwnProperty("downloadable"))
                    object.downloadable = message.downloadable;
                return object;
            };

            /**
             * Converts this RIPPCUserDataProfile to JSON.
             * @function toJSON
             * @memberof appcore.ripe.RIPPCUserDataProfile
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RIPPCUserDataProfile.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return RIPPCUserDataProfile;
        })();

        ripe.RIPPCUserData = (function() {

            /**
             * Properties of a RIPPCUserData.
             * @memberof appcore.ripe
             * @interface IRIPPCUserData
             * @property {Array.<number>|null} [outputs] RIPPCUserData outputs
             * @property {Array.<appcore.ripe.IPrintheadConfig>|null} [output_configs] RIPPCUserData output_configs
             * @property {Array.<appcore.ripe.IRIPPCUserDataPrinter>|null} [printers] RIPPCUserData printers
             * @property {Array.<appcore.ripe.IRIPPCUserDataProfile>|null} [profiles] RIPPCUserData profiles
             */

            /**
             * Constructs a new RIPPCUserData.
             * @memberof appcore.ripe
             * @classdesc Represents a RIPPCUserData.
             * @implements IRIPPCUserData
             * @constructor
             * @param {appcore.ripe.IRIPPCUserData=} [properties] Properties to set
             */
            function RIPPCUserData(properties) {
                this.outputs = [];
                this.output_configs = [];
                this.printers = [];
                this.profiles = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * RIPPCUserData outputs.
             * @member {Array.<number>} outputs
             * @memberof appcore.ripe.RIPPCUserData
             * @instance
             */
            RIPPCUserData.prototype.outputs = $util.emptyArray;

            /**
             * RIPPCUserData output_configs.
             * @member {Array.<appcore.ripe.IPrintheadConfig>} output_configs
             * @memberof appcore.ripe.RIPPCUserData
             * @instance
             */
            RIPPCUserData.prototype.output_configs = $util.emptyArray;

            /**
             * RIPPCUserData printers.
             * @member {Array.<appcore.ripe.IRIPPCUserDataPrinter>} printers
             * @memberof appcore.ripe.RIPPCUserData
             * @instance
             */
            RIPPCUserData.prototype.printers = $util.emptyArray;

            /**
             * RIPPCUserData profiles.
             * @member {Array.<appcore.ripe.IRIPPCUserDataProfile>} profiles
             * @memberof appcore.ripe.RIPPCUserData
             * @instance
             */
            RIPPCUserData.prototype.profiles = $util.emptyArray;

            /**
             * Creates a new RIPPCUserData instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.RIPPCUserData
             * @static
             * @param {appcore.ripe.IRIPPCUserData=} [properties] Properties to set
             * @returns {appcore.ripe.RIPPCUserData} RIPPCUserData instance
             */
            RIPPCUserData.create = function create(properties) {
                return new RIPPCUserData(properties);
            };

            /**
             * Encodes the specified RIPPCUserData message. Does not implicitly {@link appcore.ripe.RIPPCUserData.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.RIPPCUserData
             * @static
             * @param {appcore.ripe.IRIPPCUserData} message RIPPCUserData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RIPPCUserData.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.outputs != null && message.outputs.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (let i = 0; i < message.outputs.length; ++i)
                        writer.int32(message.outputs[i]);
                    writer.ldelim();
                }
                if (message.output_configs != null && message.output_configs.length)
                    for (let i = 0; i < message.output_configs.length; ++i)
                        $root.appcore.ripe.PrintheadConfig.encode(message.output_configs[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.printers != null && message.printers.length)
                    for (let i = 0; i < message.printers.length; ++i)
                        $root.appcore.ripe.RIPPCUserDataPrinter.encode(message.printers[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.profiles != null && message.profiles.length)
                    for (let i = 0; i < message.profiles.length; ++i)
                        $root.appcore.ripe.RIPPCUserDataProfile.encode(message.profiles[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified RIPPCUserData message, length delimited. Does not implicitly {@link appcore.ripe.RIPPCUserData.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.RIPPCUserData
             * @static
             * @param {appcore.ripe.IRIPPCUserData} message RIPPCUserData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RIPPCUserData.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a RIPPCUserData message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.RIPPCUserData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.RIPPCUserData} RIPPCUserData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RIPPCUserData.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.RIPPCUserData();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.outputs && message.outputs.length))
                            message.outputs = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.outputs.push(reader.int32());
                        } else
                            message.outputs.push(reader.int32());
                        break;
                    case 2:
                        if (!(message.output_configs && message.output_configs.length))
                            message.output_configs = [];
                        message.output_configs.push($root.appcore.ripe.PrintheadConfig.decode(reader, reader.uint32()));
                        break;
                    case 3:
                        if (!(message.printers && message.printers.length))
                            message.printers = [];
                        message.printers.push($root.appcore.ripe.RIPPCUserDataPrinter.decode(reader, reader.uint32()));
                        break;
                    case 4:
                        if (!(message.profiles && message.profiles.length))
                            message.profiles = [];
                        message.profiles.push($root.appcore.ripe.RIPPCUserDataProfile.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a RIPPCUserData message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.RIPPCUserData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.RIPPCUserData} RIPPCUserData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RIPPCUserData.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a RIPPCUserData message.
             * @function verify
             * @memberof appcore.ripe.RIPPCUserData
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RIPPCUserData.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.outputs != null && message.hasOwnProperty("outputs")) {
                    if (!Array.isArray(message.outputs))
                        return "outputs: array expected";
                    for (let i = 0; i < message.outputs.length; ++i)
                        if (!$util.isInteger(message.outputs[i]))
                            return "outputs: integer[] expected";
                }
                if (message.output_configs != null && message.hasOwnProperty("output_configs")) {
                    if (!Array.isArray(message.output_configs))
                        return "output_configs: array expected";
                    for (let i = 0; i < message.output_configs.length; ++i) {
                        let error = $root.appcore.ripe.PrintheadConfig.verify(message.output_configs[i]);
                        if (error)
                            return "output_configs." + error;
                    }
                }
                if (message.printers != null && message.hasOwnProperty("printers")) {
                    if (!Array.isArray(message.printers))
                        return "printers: array expected";
                    for (let i = 0; i < message.printers.length; ++i) {
                        let error = $root.appcore.ripe.RIPPCUserDataPrinter.verify(message.printers[i]);
                        if (error)
                            return "printers." + error;
                    }
                }
                if (message.profiles != null && message.hasOwnProperty("profiles")) {
                    if (!Array.isArray(message.profiles))
                        return "profiles: array expected";
                    for (let i = 0; i < message.profiles.length; ++i) {
                        let error = $root.appcore.ripe.RIPPCUserDataProfile.verify(message.profiles[i]);
                        if (error)
                            return "profiles." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a RIPPCUserData message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.RIPPCUserData
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.RIPPCUserData} RIPPCUserData
             */
            RIPPCUserData.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.RIPPCUserData)
                    return object;
                let message = new $root.appcore.ripe.RIPPCUserData();
                if (object.outputs) {
                    if (!Array.isArray(object.outputs))
                        throw TypeError(".appcore.ripe.RIPPCUserData.outputs: array expected");
                    message.outputs = [];
                    for (let i = 0; i < object.outputs.length; ++i)
                        message.outputs[i] = object.outputs[i] | 0;
                }
                if (object.output_configs) {
                    if (!Array.isArray(object.output_configs))
                        throw TypeError(".appcore.ripe.RIPPCUserData.output_configs: array expected");
                    message.output_configs = [];
                    for (let i = 0; i < object.output_configs.length; ++i) {
                        if (typeof object.output_configs[i] !== "object")
                            throw TypeError(".appcore.ripe.RIPPCUserData.output_configs: object expected");
                        message.output_configs[i] = $root.appcore.ripe.PrintheadConfig.fromObject(object.output_configs[i]);
                    }
                }
                if (object.printers) {
                    if (!Array.isArray(object.printers))
                        throw TypeError(".appcore.ripe.RIPPCUserData.printers: array expected");
                    message.printers = [];
                    for (let i = 0; i < object.printers.length; ++i) {
                        if (typeof object.printers[i] !== "object")
                            throw TypeError(".appcore.ripe.RIPPCUserData.printers: object expected");
                        message.printers[i] = $root.appcore.ripe.RIPPCUserDataPrinter.fromObject(object.printers[i]);
                    }
                }
                if (object.profiles) {
                    if (!Array.isArray(object.profiles))
                        throw TypeError(".appcore.ripe.RIPPCUserData.profiles: array expected");
                    message.profiles = [];
                    for (let i = 0; i < object.profiles.length; ++i) {
                        if (typeof object.profiles[i] !== "object")
                            throw TypeError(".appcore.ripe.RIPPCUserData.profiles: object expected");
                        message.profiles[i] = $root.appcore.ripe.RIPPCUserDataProfile.fromObject(object.profiles[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a RIPPCUserData message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.RIPPCUserData
             * @static
             * @param {appcore.ripe.RIPPCUserData} message RIPPCUserData
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RIPPCUserData.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults) {
                    object.outputs = [];
                    object.output_configs = [];
                    object.printers = [];
                    object.profiles = [];
                }
                if (message.outputs && message.outputs.length) {
                    object.outputs = [];
                    for (let j = 0; j < message.outputs.length; ++j)
                        object.outputs[j] = message.outputs[j];
                }
                if (message.output_configs && message.output_configs.length) {
                    object.output_configs = [];
                    for (let j = 0; j < message.output_configs.length; ++j)
                        object.output_configs[j] = $root.appcore.ripe.PrintheadConfig.toObject(message.output_configs[j], options);
                }
                if (message.printers && message.printers.length) {
                    object.printers = [];
                    for (let j = 0; j < message.printers.length; ++j)
                        object.printers[j] = $root.appcore.ripe.RIPPCUserDataPrinter.toObject(message.printers[j], options);
                }
                if (message.profiles && message.profiles.length) {
                    object.profiles = [];
                    for (let j = 0; j < message.profiles.length; ++j)
                        object.profiles[j] = $root.appcore.ripe.RIPPCUserDataProfile.toObject(message.profiles[j], options);
                }
                return object;
            };

            /**
             * Converts this RIPPCUserData to JSON.
             * @function toJSON
             * @memberof appcore.ripe.RIPPCUserData
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RIPPCUserData.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return RIPPCUserData;
        })();

        ripe.RIPPCRegistrationData = (function() {

            /**
             * Properties of a RIPPCRegistrationData.
             * @memberof appcore.ripe
             * @interface IRIPPCRegistrationData
             * @property {string|null} [communicator_uuid] RIPPCRegistrationData communicator_uuid
             * @property {appcore.ripe.IHWEntries|null} [hw_entries] RIPPCRegistrationData hw_entries
             */

            /**
             * Constructs a new RIPPCRegistrationData.
             * @memberof appcore.ripe
             * @classdesc Represents a RIPPCRegistrationData.
             * @implements IRIPPCRegistrationData
             * @constructor
             * @param {appcore.ripe.IRIPPCRegistrationData=} [properties] Properties to set
             */
            function RIPPCRegistrationData(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * RIPPCRegistrationData communicator_uuid.
             * @member {string} communicator_uuid
             * @memberof appcore.ripe.RIPPCRegistrationData
             * @instance
             */
            RIPPCRegistrationData.prototype.communicator_uuid = "";

            /**
             * RIPPCRegistrationData hw_entries.
             * @member {appcore.ripe.IHWEntries|null|undefined} hw_entries
             * @memberof appcore.ripe.RIPPCRegistrationData
             * @instance
             */
            RIPPCRegistrationData.prototype.hw_entries = null;

            /**
             * Creates a new RIPPCRegistrationData instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.RIPPCRegistrationData
             * @static
             * @param {appcore.ripe.IRIPPCRegistrationData=} [properties] Properties to set
             * @returns {appcore.ripe.RIPPCRegistrationData} RIPPCRegistrationData instance
             */
            RIPPCRegistrationData.create = function create(properties) {
                return new RIPPCRegistrationData(properties);
            };

            /**
             * Encodes the specified RIPPCRegistrationData message. Does not implicitly {@link appcore.ripe.RIPPCRegistrationData.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.RIPPCRegistrationData
             * @static
             * @param {appcore.ripe.IRIPPCRegistrationData} message RIPPCRegistrationData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RIPPCRegistrationData.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.communicator_uuid != null && Object.hasOwnProperty.call(message, "communicator_uuid"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.communicator_uuid);
                if (message.hw_entries != null && Object.hasOwnProperty.call(message, "hw_entries"))
                    $root.appcore.ripe.HWEntries.encode(message.hw_entries, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified RIPPCRegistrationData message, length delimited. Does not implicitly {@link appcore.ripe.RIPPCRegistrationData.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.RIPPCRegistrationData
             * @static
             * @param {appcore.ripe.IRIPPCRegistrationData} message RIPPCRegistrationData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RIPPCRegistrationData.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a RIPPCRegistrationData message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.RIPPCRegistrationData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.RIPPCRegistrationData} RIPPCRegistrationData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RIPPCRegistrationData.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.RIPPCRegistrationData();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.communicator_uuid = reader.string();
                        break;
                    case 2:
                        message.hw_entries = $root.appcore.ripe.HWEntries.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a RIPPCRegistrationData message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.RIPPCRegistrationData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.RIPPCRegistrationData} RIPPCRegistrationData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RIPPCRegistrationData.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a RIPPCRegistrationData message.
             * @function verify
             * @memberof appcore.ripe.RIPPCRegistrationData
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RIPPCRegistrationData.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.communicator_uuid != null && message.hasOwnProperty("communicator_uuid"))
                    if (!$util.isString(message.communicator_uuid))
                        return "communicator_uuid: string expected";
                if (message.hw_entries != null && message.hasOwnProperty("hw_entries")) {
                    let error = $root.appcore.ripe.HWEntries.verify(message.hw_entries);
                    if (error)
                        return "hw_entries." + error;
                }
                return null;
            };

            /**
             * Creates a RIPPCRegistrationData message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.RIPPCRegistrationData
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.RIPPCRegistrationData} RIPPCRegistrationData
             */
            RIPPCRegistrationData.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.RIPPCRegistrationData)
                    return object;
                let message = new $root.appcore.ripe.RIPPCRegistrationData();
                if (object.communicator_uuid != null)
                    message.communicator_uuid = String(object.communicator_uuid);
                if (object.hw_entries != null) {
                    if (typeof object.hw_entries !== "object")
                        throw TypeError(".appcore.ripe.RIPPCRegistrationData.hw_entries: object expected");
                    message.hw_entries = $root.appcore.ripe.HWEntries.fromObject(object.hw_entries);
                }
                return message;
            };

            /**
             * Creates a plain object from a RIPPCRegistrationData message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.RIPPCRegistrationData
             * @static
             * @param {appcore.ripe.RIPPCRegistrationData} message RIPPCRegistrationData
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RIPPCRegistrationData.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.communicator_uuid = "";
                    object.hw_entries = null;
                }
                if (message.communicator_uuid != null && message.hasOwnProperty("communicator_uuid"))
                    object.communicator_uuid = message.communicator_uuid;
                if (message.hw_entries != null && message.hasOwnProperty("hw_entries"))
                    object.hw_entries = $root.appcore.ripe.HWEntries.toObject(message.hw_entries, options);
                return object;
            };

            /**
             * Converts this RIPPCRegistrationData to JSON.
             * @function toJSON
             * @memberof appcore.ripe.RIPPCRegistrationData
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RIPPCRegistrationData.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return RIPPCRegistrationData;
        })();

        ripe.RIPPCRegistrationResult = (function() {

            /**
             * Properties of a RIPPCRegistrationResult.
             * @memberof appcore.ripe
             * @interface IRIPPCRegistrationResult
             * @property {number|null} [error_code] RIPPCRegistrationResult error_code
             */

            /**
             * Constructs a new RIPPCRegistrationResult.
             * @memberof appcore.ripe
             * @classdesc Represents a RIPPCRegistrationResult.
             * @implements IRIPPCRegistrationResult
             * @constructor
             * @param {appcore.ripe.IRIPPCRegistrationResult=} [properties] Properties to set
             */
            function RIPPCRegistrationResult(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * RIPPCRegistrationResult error_code.
             * @member {number} error_code
             * @memberof appcore.ripe.RIPPCRegistrationResult
             * @instance
             */
            RIPPCRegistrationResult.prototype.error_code = 0;

            /**
             * Creates a new RIPPCRegistrationResult instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.RIPPCRegistrationResult
             * @static
             * @param {appcore.ripe.IRIPPCRegistrationResult=} [properties] Properties to set
             * @returns {appcore.ripe.RIPPCRegistrationResult} RIPPCRegistrationResult instance
             */
            RIPPCRegistrationResult.create = function create(properties) {
                return new RIPPCRegistrationResult(properties);
            };

            /**
             * Encodes the specified RIPPCRegistrationResult message. Does not implicitly {@link appcore.ripe.RIPPCRegistrationResult.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.RIPPCRegistrationResult
             * @static
             * @param {appcore.ripe.IRIPPCRegistrationResult} message RIPPCRegistrationResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RIPPCRegistrationResult.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.error_code != null && Object.hasOwnProperty.call(message, "error_code"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.error_code);
                return writer;
            };

            /**
             * Encodes the specified RIPPCRegistrationResult message, length delimited. Does not implicitly {@link appcore.ripe.RIPPCRegistrationResult.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.RIPPCRegistrationResult
             * @static
             * @param {appcore.ripe.IRIPPCRegistrationResult} message RIPPCRegistrationResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RIPPCRegistrationResult.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a RIPPCRegistrationResult message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.RIPPCRegistrationResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.RIPPCRegistrationResult} RIPPCRegistrationResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RIPPCRegistrationResult.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.RIPPCRegistrationResult();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.error_code = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a RIPPCRegistrationResult message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.RIPPCRegistrationResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.RIPPCRegistrationResult} RIPPCRegistrationResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RIPPCRegistrationResult.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a RIPPCRegistrationResult message.
             * @function verify
             * @memberof appcore.ripe.RIPPCRegistrationResult
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RIPPCRegistrationResult.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.error_code != null && message.hasOwnProperty("error_code"))
                    if (!$util.isInteger(message.error_code))
                        return "error_code: integer expected";
                return null;
            };

            /**
             * Creates a RIPPCRegistrationResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.RIPPCRegistrationResult
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.RIPPCRegistrationResult} RIPPCRegistrationResult
             */
            RIPPCRegistrationResult.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.RIPPCRegistrationResult)
                    return object;
                let message = new $root.appcore.ripe.RIPPCRegistrationResult();
                if (object.error_code != null)
                    message.error_code = object.error_code | 0;
                return message;
            };

            /**
             * Creates a plain object from a RIPPCRegistrationResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.RIPPCRegistrationResult
             * @static
             * @param {appcore.ripe.RIPPCRegistrationResult} message RIPPCRegistrationResult
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RIPPCRegistrationResult.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.error_code = 0;
                if (message.error_code != null && message.hasOwnProperty("error_code"))
                    object.error_code = message.error_code;
                return object;
            };

            /**
             * Converts this RIPPCRegistrationResult to JSON.
             * @function toJSON
             * @memberof appcore.ripe.RIPPCRegistrationResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RIPPCRegistrationResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return RIPPCRegistrationResult;
        })();

        ripe.SaveDefaultPrinterServerIP = (function() {

            /**
             * Properties of a SaveDefaultPrinterServerIP.
             * @memberof appcore.ripe
             * @interface ISaveDefaultPrinterServerIP
             * @property {string|null} [address] SaveDefaultPrinterServerIP address
             */

            /**
             * Constructs a new SaveDefaultPrinterServerIP.
             * @memberof appcore.ripe
             * @classdesc Represents a SaveDefaultPrinterServerIP.
             * @implements ISaveDefaultPrinterServerIP
             * @constructor
             * @param {appcore.ripe.ISaveDefaultPrinterServerIP=} [properties] Properties to set
             */
            function SaveDefaultPrinterServerIP(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SaveDefaultPrinterServerIP address.
             * @member {string} address
             * @memberof appcore.ripe.SaveDefaultPrinterServerIP
             * @instance
             */
            SaveDefaultPrinterServerIP.prototype.address = "";

            /**
             * Creates a new SaveDefaultPrinterServerIP instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.SaveDefaultPrinterServerIP
             * @static
             * @param {appcore.ripe.ISaveDefaultPrinterServerIP=} [properties] Properties to set
             * @returns {appcore.ripe.SaveDefaultPrinterServerIP} SaveDefaultPrinterServerIP instance
             */
            SaveDefaultPrinterServerIP.create = function create(properties) {
                return new SaveDefaultPrinterServerIP(properties);
            };

            /**
             * Encodes the specified SaveDefaultPrinterServerIP message. Does not implicitly {@link appcore.ripe.SaveDefaultPrinterServerIP.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.SaveDefaultPrinterServerIP
             * @static
             * @param {appcore.ripe.ISaveDefaultPrinterServerIP} message SaveDefaultPrinterServerIP message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SaveDefaultPrinterServerIP.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.address);
                return writer;
            };

            /**
             * Encodes the specified SaveDefaultPrinterServerIP message, length delimited. Does not implicitly {@link appcore.ripe.SaveDefaultPrinterServerIP.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.SaveDefaultPrinterServerIP
             * @static
             * @param {appcore.ripe.ISaveDefaultPrinterServerIP} message SaveDefaultPrinterServerIP message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SaveDefaultPrinterServerIP.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SaveDefaultPrinterServerIP message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.SaveDefaultPrinterServerIP
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.SaveDefaultPrinterServerIP} SaveDefaultPrinterServerIP
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SaveDefaultPrinterServerIP.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.SaveDefaultPrinterServerIP();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.address = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SaveDefaultPrinterServerIP message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.SaveDefaultPrinterServerIP
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.SaveDefaultPrinterServerIP} SaveDefaultPrinterServerIP
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SaveDefaultPrinterServerIP.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SaveDefaultPrinterServerIP message.
             * @function verify
             * @memberof appcore.ripe.SaveDefaultPrinterServerIP
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SaveDefaultPrinterServerIP.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.address != null && message.hasOwnProperty("address"))
                    if (!$util.isString(message.address))
                        return "address: string expected";
                return null;
            };

            /**
             * Creates a SaveDefaultPrinterServerIP message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.SaveDefaultPrinterServerIP
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.SaveDefaultPrinterServerIP} SaveDefaultPrinterServerIP
             */
            SaveDefaultPrinterServerIP.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.SaveDefaultPrinterServerIP)
                    return object;
                let message = new $root.appcore.ripe.SaveDefaultPrinterServerIP();
                if (object.address != null)
                    message.address = String(object.address);
                return message;
            };

            /**
             * Creates a plain object from a SaveDefaultPrinterServerIP message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.SaveDefaultPrinterServerIP
             * @static
             * @param {appcore.ripe.SaveDefaultPrinterServerIP} message SaveDefaultPrinterServerIP
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SaveDefaultPrinterServerIP.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.address = "";
                if (message.address != null && message.hasOwnProperty("address"))
                    object.address = message.address;
                return object;
            };

            /**
             * Converts this SaveDefaultPrinterServerIP to JSON.
             * @function toJSON
             * @memberof appcore.ripe.SaveDefaultPrinterServerIP
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SaveDefaultPrinterServerIP.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return SaveDefaultPrinterServerIP;
        })();

        ripe.SavePrinter = (function() {

            /**
             * Properties of a SavePrinter.
             * @memberof appcore.ripe
             * @interface ISavePrinter
             * @property {number|Long|null} [id] SavePrinter id
             * @property {string|null} [name] SavePrinter name
             */

            /**
             * Constructs a new SavePrinter.
             * @memberof appcore.ripe
             * @classdesc Represents a SavePrinter.
             * @implements ISavePrinter
             * @constructor
             * @param {appcore.ripe.ISavePrinter=} [properties] Properties to set
             */
            function SavePrinter(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SavePrinter id.
             * @member {number|Long} id
             * @memberof appcore.ripe.SavePrinter
             * @instance
             */
            SavePrinter.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * SavePrinter name.
             * @member {string} name
             * @memberof appcore.ripe.SavePrinter
             * @instance
             */
            SavePrinter.prototype.name = "";

            /**
             * Creates a new SavePrinter instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.SavePrinter
             * @static
             * @param {appcore.ripe.ISavePrinter=} [properties] Properties to set
             * @returns {appcore.ripe.SavePrinter} SavePrinter instance
             */
            SavePrinter.create = function create(properties) {
                return new SavePrinter(properties);
            };

            /**
             * Encodes the specified SavePrinter message. Does not implicitly {@link appcore.ripe.SavePrinter.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.SavePrinter
             * @static
             * @param {appcore.ripe.ISavePrinter} message SavePrinter message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SavePrinter.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.id);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                return writer;
            };

            /**
             * Encodes the specified SavePrinter message, length delimited. Does not implicitly {@link appcore.ripe.SavePrinter.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.SavePrinter
             * @static
             * @param {appcore.ripe.ISavePrinter} message SavePrinter message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SavePrinter.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SavePrinter message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.SavePrinter
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.SavePrinter} SavePrinter
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SavePrinter.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.SavePrinter();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.uint64();
                        break;
                    case 2:
                        message.name = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SavePrinter message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.SavePrinter
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.SavePrinter} SavePrinter
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SavePrinter.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SavePrinter message.
             * @function verify
             * @memberof appcore.ripe.SavePrinter
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SavePrinter.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                return null;
            };

            /**
             * Creates a SavePrinter message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.SavePrinter
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.SavePrinter} SavePrinter
             */
            SavePrinter.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.SavePrinter)
                    return object;
                let message = new $root.appcore.ripe.SavePrinter();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
                if (object.name != null)
                    message.name = String(object.name);
                return message;
            };

            /**
             * Creates a plain object from a SavePrinter message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.SavePrinter
             * @static
             * @param {appcore.ripe.SavePrinter} message SavePrinter
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SavePrinter.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                    object.name = "";
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                return object;
            };

            /**
             * Converts this SavePrinter to JSON.
             * @function toJSON
             * @memberof appcore.ripe.SavePrinter
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SavePrinter.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return SavePrinter;
        })();

        ripe.SavePrinterResult = (function() {

            /**
             * Properties of a SavePrinterResult.
             * @memberof appcore.ripe
             * @interface ISavePrinterResult
             * @property {number|Long|null} [id] SavePrinterResult id
             * @property {number|null} [error_code] SavePrinterResult error_code
             */

            /**
             * Constructs a new SavePrinterResult.
             * @memberof appcore.ripe
             * @classdesc Represents a SavePrinterResult.
             * @implements ISavePrinterResult
             * @constructor
             * @param {appcore.ripe.ISavePrinterResult=} [properties] Properties to set
             */
            function SavePrinterResult(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SavePrinterResult id.
             * @member {number|Long} id
             * @memberof appcore.ripe.SavePrinterResult
             * @instance
             */
            SavePrinterResult.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * SavePrinterResult error_code.
             * @member {number} error_code
             * @memberof appcore.ripe.SavePrinterResult
             * @instance
             */
            SavePrinterResult.prototype.error_code = 0;

            /**
             * Creates a new SavePrinterResult instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.SavePrinterResult
             * @static
             * @param {appcore.ripe.ISavePrinterResult=} [properties] Properties to set
             * @returns {appcore.ripe.SavePrinterResult} SavePrinterResult instance
             */
            SavePrinterResult.create = function create(properties) {
                return new SavePrinterResult(properties);
            };

            /**
             * Encodes the specified SavePrinterResult message. Does not implicitly {@link appcore.ripe.SavePrinterResult.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.SavePrinterResult
             * @static
             * @param {appcore.ripe.ISavePrinterResult} message SavePrinterResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SavePrinterResult.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.id);
                if (message.error_code != null && Object.hasOwnProperty.call(message, "error_code"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.error_code);
                return writer;
            };

            /**
             * Encodes the specified SavePrinterResult message, length delimited. Does not implicitly {@link appcore.ripe.SavePrinterResult.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.SavePrinterResult
             * @static
             * @param {appcore.ripe.ISavePrinterResult} message SavePrinterResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SavePrinterResult.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SavePrinterResult message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.SavePrinterResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.SavePrinterResult} SavePrinterResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SavePrinterResult.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.SavePrinterResult();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.uint64();
                        break;
                    case 2:
                        message.error_code = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SavePrinterResult message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.SavePrinterResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.SavePrinterResult} SavePrinterResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SavePrinterResult.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SavePrinterResult message.
             * @function verify
             * @memberof appcore.ripe.SavePrinterResult
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SavePrinterResult.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                if (message.error_code != null && message.hasOwnProperty("error_code"))
                    if (!$util.isInteger(message.error_code))
                        return "error_code: integer expected";
                return null;
            };

            /**
             * Creates a SavePrinterResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.SavePrinterResult
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.SavePrinterResult} SavePrinterResult
             */
            SavePrinterResult.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.SavePrinterResult)
                    return object;
                let message = new $root.appcore.ripe.SavePrinterResult();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
                if (object.error_code != null)
                    message.error_code = object.error_code | 0;
                return message;
            };

            /**
             * Creates a plain object from a SavePrinterResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.SavePrinterResult
             * @static
             * @param {appcore.ripe.SavePrinterResult} message SavePrinterResult
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SavePrinterResult.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                    object.error_code = 0;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
                if (message.error_code != null && message.hasOwnProperty("error_code"))
                    object.error_code = message.error_code;
                return object;
            };

            /**
             * Converts this SavePrinterResult to JSON.
             * @function toJSON
             * @memberof appcore.ripe.SavePrinterResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SavePrinterResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return SavePrinterResult;
        })();

        ripe.SavePrinterServerIP = (function() {

            /**
             * Properties of a SavePrinterServerIP.
             * @memberof appcore.ripe
             * @interface ISavePrinterServerIP
             * @property {number|Long|null} [id] SavePrinterServerIP id
             * @property {string|null} [address] SavePrinterServerIP address
             */

            /**
             * Constructs a new SavePrinterServerIP.
             * @memberof appcore.ripe
             * @classdesc Represents a SavePrinterServerIP.
             * @implements ISavePrinterServerIP
             * @constructor
             * @param {appcore.ripe.ISavePrinterServerIP=} [properties] Properties to set
             */
            function SavePrinterServerIP(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SavePrinterServerIP id.
             * @member {number|Long} id
             * @memberof appcore.ripe.SavePrinterServerIP
             * @instance
             */
            SavePrinterServerIP.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * SavePrinterServerIP address.
             * @member {string} address
             * @memberof appcore.ripe.SavePrinterServerIP
             * @instance
             */
            SavePrinterServerIP.prototype.address = "";

            /**
             * Creates a new SavePrinterServerIP instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.SavePrinterServerIP
             * @static
             * @param {appcore.ripe.ISavePrinterServerIP=} [properties] Properties to set
             * @returns {appcore.ripe.SavePrinterServerIP} SavePrinterServerIP instance
             */
            SavePrinterServerIP.create = function create(properties) {
                return new SavePrinterServerIP(properties);
            };

            /**
             * Encodes the specified SavePrinterServerIP message. Does not implicitly {@link appcore.ripe.SavePrinterServerIP.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.SavePrinterServerIP
             * @static
             * @param {appcore.ripe.ISavePrinterServerIP} message SavePrinterServerIP message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SavePrinterServerIP.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.id);
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.address);
                return writer;
            };

            /**
             * Encodes the specified SavePrinterServerIP message, length delimited. Does not implicitly {@link appcore.ripe.SavePrinterServerIP.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.SavePrinterServerIP
             * @static
             * @param {appcore.ripe.ISavePrinterServerIP} message SavePrinterServerIP message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SavePrinterServerIP.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SavePrinterServerIP message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.SavePrinterServerIP
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.SavePrinterServerIP} SavePrinterServerIP
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SavePrinterServerIP.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.SavePrinterServerIP();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.uint64();
                        break;
                    case 2:
                        message.address = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SavePrinterServerIP message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.SavePrinterServerIP
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.SavePrinterServerIP} SavePrinterServerIP
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SavePrinterServerIP.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SavePrinterServerIP message.
             * @function verify
             * @memberof appcore.ripe.SavePrinterServerIP
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SavePrinterServerIP.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                if (message.address != null && message.hasOwnProperty("address"))
                    if (!$util.isString(message.address))
                        return "address: string expected";
                return null;
            };

            /**
             * Creates a SavePrinterServerIP message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.SavePrinterServerIP
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.SavePrinterServerIP} SavePrinterServerIP
             */
            SavePrinterServerIP.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.SavePrinterServerIP)
                    return object;
                let message = new $root.appcore.ripe.SavePrinterServerIP();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
                if (object.address != null)
                    message.address = String(object.address);
                return message;
            };

            /**
             * Creates a plain object from a SavePrinterServerIP message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.SavePrinterServerIP
             * @static
             * @param {appcore.ripe.SavePrinterServerIP} message SavePrinterServerIP
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SavePrinterServerIP.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                    object.address = "";
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
                if (message.address != null && message.hasOwnProperty("address"))
                    object.address = message.address;
                return object;
            };

            /**
             * Converts this SavePrinterServerIP to JSON.
             * @function toJSON
             * @memberof appcore.ripe.SavePrinterServerIP
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SavePrinterServerIP.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return SavePrinterServerIP;
        })();

        ripe.SaveRIPComputer = (function() {

            /**
             * Properties of a SaveRIPComputer.
             * @memberof appcore.ripe
             * @interface ISaveRIPComputer
             * @property {number|Long|null} [id] SaveRIPComputer id
             * @property {string|null} [name] SaveRIPComputer name
             * @property {number|Long|null} [user_id] SaveRIPComputer user_id
             * @property {boolean|null} [has_license] SaveRIPComputer has_license
             * @property {number|Long|null} [license_expiration] SaveRIPComputer license_expiration
             */

            /**
             * Constructs a new SaveRIPComputer.
             * @memberof appcore.ripe
             * @classdesc Represents a SaveRIPComputer.
             * @implements ISaveRIPComputer
             * @constructor
             * @param {appcore.ripe.ISaveRIPComputer=} [properties] Properties to set
             */
            function SaveRIPComputer(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SaveRIPComputer id.
             * @member {number|Long} id
             * @memberof appcore.ripe.SaveRIPComputer
             * @instance
             */
            SaveRIPComputer.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * SaveRIPComputer name.
             * @member {string} name
             * @memberof appcore.ripe.SaveRIPComputer
             * @instance
             */
            SaveRIPComputer.prototype.name = "";

            /**
             * SaveRIPComputer user_id.
             * @member {number|Long} user_id
             * @memberof appcore.ripe.SaveRIPComputer
             * @instance
             */
            SaveRIPComputer.prototype.user_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * SaveRIPComputer has_license.
             * @member {boolean} has_license
             * @memberof appcore.ripe.SaveRIPComputer
             * @instance
             */
            SaveRIPComputer.prototype.has_license = false;

            /**
             * SaveRIPComputer license_expiration.
             * @member {number|Long} license_expiration
             * @memberof appcore.ripe.SaveRIPComputer
             * @instance
             */
            SaveRIPComputer.prototype.license_expiration = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new SaveRIPComputer instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.SaveRIPComputer
             * @static
             * @param {appcore.ripe.ISaveRIPComputer=} [properties] Properties to set
             * @returns {appcore.ripe.SaveRIPComputer} SaveRIPComputer instance
             */
            SaveRIPComputer.create = function create(properties) {
                return new SaveRIPComputer(properties);
            };

            /**
             * Encodes the specified SaveRIPComputer message. Does not implicitly {@link appcore.ripe.SaveRIPComputer.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.SaveRIPComputer
             * @static
             * @param {appcore.ripe.ISaveRIPComputer} message SaveRIPComputer message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SaveRIPComputer.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.id);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.user_id != null && Object.hasOwnProperty.call(message, "user_id"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.user_id);
                if (message.has_license != null && Object.hasOwnProperty.call(message, "has_license"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.has_license);
                if (message.license_expiration != null && Object.hasOwnProperty.call(message, "license_expiration"))
                    writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.license_expiration);
                return writer;
            };

            /**
             * Encodes the specified SaveRIPComputer message, length delimited. Does not implicitly {@link appcore.ripe.SaveRIPComputer.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.SaveRIPComputer
             * @static
             * @param {appcore.ripe.ISaveRIPComputer} message SaveRIPComputer message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SaveRIPComputer.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SaveRIPComputer message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.SaveRIPComputer
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.SaveRIPComputer} SaveRIPComputer
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SaveRIPComputer.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.SaveRIPComputer();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.uint64();
                        break;
                    case 2:
                        message.name = reader.string();
                        break;
                    case 3:
                        message.user_id = reader.uint64();
                        break;
                    case 4:
                        message.has_license = reader.bool();
                        break;
                    case 5:
                        message.license_expiration = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SaveRIPComputer message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.SaveRIPComputer
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.SaveRIPComputer} SaveRIPComputer
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SaveRIPComputer.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SaveRIPComputer message.
             * @function verify
             * @memberof appcore.ripe.SaveRIPComputer
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SaveRIPComputer.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.user_id != null && message.hasOwnProperty("user_id"))
                    if (!$util.isInteger(message.user_id) && !(message.user_id && $util.isInteger(message.user_id.low) && $util.isInteger(message.user_id.high)))
                        return "user_id: integer|Long expected";
                if (message.has_license != null && message.hasOwnProperty("has_license"))
                    if (typeof message.has_license !== "boolean")
                        return "has_license: boolean expected";
                if (message.license_expiration != null && message.hasOwnProperty("license_expiration"))
                    if (!$util.isInteger(message.license_expiration) && !(message.license_expiration && $util.isInteger(message.license_expiration.low) && $util.isInteger(message.license_expiration.high)))
                        return "license_expiration: integer|Long expected";
                return null;
            };

            /**
             * Creates a SaveRIPComputer message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.SaveRIPComputer
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.SaveRIPComputer} SaveRIPComputer
             */
            SaveRIPComputer.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.SaveRIPComputer)
                    return object;
                let message = new $root.appcore.ripe.SaveRIPComputer();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.user_id != null)
                    if ($util.Long)
                        (message.user_id = $util.Long.fromValue(object.user_id)).unsigned = true;
                    else if (typeof object.user_id === "string")
                        message.user_id = parseInt(object.user_id, 10);
                    else if (typeof object.user_id === "number")
                        message.user_id = object.user_id;
                    else if (typeof object.user_id === "object")
                        message.user_id = new $util.LongBits(object.user_id.low >>> 0, object.user_id.high >>> 0).toNumber(true);
                if (object.has_license != null)
                    message.has_license = Boolean(object.has_license);
                if (object.license_expiration != null)
                    if ($util.Long)
                        (message.license_expiration = $util.Long.fromValue(object.license_expiration)).unsigned = true;
                    else if (typeof object.license_expiration === "string")
                        message.license_expiration = parseInt(object.license_expiration, 10);
                    else if (typeof object.license_expiration === "number")
                        message.license_expiration = object.license_expiration;
                    else if (typeof object.license_expiration === "object")
                        message.license_expiration = new $util.LongBits(object.license_expiration.low >>> 0, object.license_expiration.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a SaveRIPComputer message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.SaveRIPComputer
             * @static
             * @param {appcore.ripe.SaveRIPComputer} message SaveRIPComputer
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SaveRIPComputer.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                    object.name = "";
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.user_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.user_id = options.longs === String ? "0" : 0;
                    object.has_license = false;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.license_expiration = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.license_expiration = options.longs === String ? "0" : 0;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.user_id != null && message.hasOwnProperty("user_id"))
                    if (typeof message.user_id === "number")
                        object.user_id = options.longs === String ? String(message.user_id) : message.user_id;
                    else
                        object.user_id = options.longs === String ? $util.Long.prototype.toString.call(message.user_id) : options.longs === Number ? new $util.LongBits(message.user_id.low >>> 0, message.user_id.high >>> 0).toNumber(true) : message.user_id;
                if (message.has_license != null && message.hasOwnProperty("has_license"))
                    object.has_license = message.has_license;
                if (message.license_expiration != null && message.hasOwnProperty("license_expiration"))
                    if (typeof message.license_expiration === "number")
                        object.license_expiration = options.longs === String ? String(message.license_expiration) : message.license_expiration;
                    else
                        object.license_expiration = options.longs === String ? $util.Long.prototype.toString.call(message.license_expiration) : options.longs === Number ? new $util.LongBits(message.license_expiration.low >>> 0, message.license_expiration.high >>> 0).toNumber(true) : message.license_expiration;
                return object;
            };

            /**
             * Converts this SaveRIPComputer to JSON.
             * @function toJSON
             * @memberof appcore.ripe.SaveRIPComputer
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SaveRIPComputer.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return SaveRIPComputer;
        })();

        ripe.SaveRIPComputerResult = (function() {

            /**
             * Properties of a SaveRIPComputerResult.
             * @memberof appcore.ripe
             * @interface ISaveRIPComputerResult
             * @property {number|Long|null} [id] SaveRIPComputerResult id
             * @property {number|Long|null} [user_id] SaveRIPComputerResult user_id
             */

            /**
             * Constructs a new SaveRIPComputerResult.
             * @memberof appcore.ripe
             * @classdesc Represents a SaveRIPComputerResult.
             * @implements ISaveRIPComputerResult
             * @constructor
             * @param {appcore.ripe.ISaveRIPComputerResult=} [properties] Properties to set
             */
            function SaveRIPComputerResult(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SaveRIPComputerResult id.
             * @member {number|Long} id
             * @memberof appcore.ripe.SaveRIPComputerResult
             * @instance
             */
            SaveRIPComputerResult.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * SaveRIPComputerResult user_id.
             * @member {number|Long} user_id
             * @memberof appcore.ripe.SaveRIPComputerResult
             * @instance
             */
            SaveRIPComputerResult.prototype.user_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new SaveRIPComputerResult instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.SaveRIPComputerResult
             * @static
             * @param {appcore.ripe.ISaveRIPComputerResult=} [properties] Properties to set
             * @returns {appcore.ripe.SaveRIPComputerResult} SaveRIPComputerResult instance
             */
            SaveRIPComputerResult.create = function create(properties) {
                return new SaveRIPComputerResult(properties);
            };

            /**
             * Encodes the specified SaveRIPComputerResult message. Does not implicitly {@link appcore.ripe.SaveRIPComputerResult.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.SaveRIPComputerResult
             * @static
             * @param {appcore.ripe.ISaveRIPComputerResult} message SaveRIPComputerResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SaveRIPComputerResult.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.id);
                if (message.user_id != null && Object.hasOwnProperty.call(message, "user_id"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.user_id);
                return writer;
            };

            /**
             * Encodes the specified SaveRIPComputerResult message, length delimited. Does not implicitly {@link appcore.ripe.SaveRIPComputerResult.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.SaveRIPComputerResult
             * @static
             * @param {appcore.ripe.ISaveRIPComputerResult} message SaveRIPComputerResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SaveRIPComputerResult.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SaveRIPComputerResult message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.SaveRIPComputerResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.SaveRIPComputerResult} SaveRIPComputerResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SaveRIPComputerResult.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.SaveRIPComputerResult();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.uint64();
                        break;
                    case 2:
                        message.user_id = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SaveRIPComputerResult message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.SaveRIPComputerResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.SaveRIPComputerResult} SaveRIPComputerResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SaveRIPComputerResult.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SaveRIPComputerResult message.
             * @function verify
             * @memberof appcore.ripe.SaveRIPComputerResult
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SaveRIPComputerResult.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                if (message.user_id != null && message.hasOwnProperty("user_id"))
                    if (!$util.isInteger(message.user_id) && !(message.user_id && $util.isInteger(message.user_id.low) && $util.isInteger(message.user_id.high)))
                        return "user_id: integer|Long expected";
                return null;
            };

            /**
             * Creates a SaveRIPComputerResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.SaveRIPComputerResult
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.SaveRIPComputerResult} SaveRIPComputerResult
             */
            SaveRIPComputerResult.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.SaveRIPComputerResult)
                    return object;
                let message = new $root.appcore.ripe.SaveRIPComputerResult();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
                if (object.user_id != null)
                    if ($util.Long)
                        (message.user_id = $util.Long.fromValue(object.user_id)).unsigned = true;
                    else if (typeof object.user_id === "string")
                        message.user_id = parseInt(object.user_id, 10);
                    else if (typeof object.user_id === "number")
                        message.user_id = object.user_id;
                    else if (typeof object.user_id === "object")
                        message.user_id = new $util.LongBits(object.user_id.low >>> 0, object.user_id.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a SaveRIPComputerResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.SaveRIPComputerResult
             * @static
             * @param {appcore.ripe.SaveRIPComputerResult} message SaveRIPComputerResult
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SaveRIPComputerResult.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.user_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.user_id = options.longs === String ? "0" : 0;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
                if (message.user_id != null && message.hasOwnProperty("user_id"))
                    if (typeof message.user_id === "number")
                        object.user_id = options.longs === String ? String(message.user_id) : message.user_id;
                    else
                        object.user_id = options.longs === String ? $util.Long.prototype.toString.call(message.user_id) : options.longs === Number ? new $util.LongBits(message.user_id.low >>> 0, message.user_id.high >>> 0).toNumber(true) : message.user_id;
                return object;
            };

            /**
             * Converts this SaveRIPComputerResult to JSON.
             * @function toJSON
             * @memberof appcore.ripe.SaveRIPComputerResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SaveRIPComputerResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return SaveRIPComputerResult;
        })();

        ripe.SaveUser = (function() {

            /**
             * Properties of a SaveUser.
             * @memberof appcore.ripe
             * @interface ISaveUser
             * @property {number|Long|null} [id] SaveUser id
             * @property {string|null} [name] SaveUser name
             * @property {string|null} [email] SaveUser email
             * @property {string|null} [phone] SaveUser phone
             */

            /**
             * Constructs a new SaveUser.
             * @memberof appcore.ripe
             * @classdesc Represents a SaveUser.
             * @implements ISaveUser
             * @constructor
             * @param {appcore.ripe.ISaveUser=} [properties] Properties to set
             */
            function SaveUser(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SaveUser id.
             * @member {number|Long} id
             * @memberof appcore.ripe.SaveUser
             * @instance
             */
            SaveUser.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * SaveUser name.
             * @member {string} name
             * @memberof appcore.ripe.SaveUser
             * @instance
             */
            SaveUser.prototype.name = "";

            /**
             * SaveUser email.
             * @member {string} email
             * @memberof appcore.ripe.SaveUser
             * @instance
             */
            SaveUser.prototype.email = "";

            /**
             * SaveUser phone.
             * @member {string} phone
             * @memberof appcore.ripe.SaveUser
             * @instance
             */
            SaveUser.prototype.phone = "";

            /**
             * Creates a new SaveUser instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.SaveUser
             * @static
             * @param {appcore.ripe.ISaveUser=} [properties] Properties to set
             * @returns {appcore.ripe.SaveUser} SaveUser instance
             */
            SaveUser.create = function create(properties) {
                return new SaveUser(properties);
            };

            /**
             * Encodes the specified SaveUser message. Does not implicitly {@link appcore.ripe.SaveUser.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.SaveUser
             * @static
             * @param {appcore.ripe.ISaveUser} message SaveUser message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SaveUser.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.email);
                if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.phone);
                return writer;
            };

            /**
             * Encodes the specified SaveUser message, length delimited. Does not implicitly {@link appcore.ripe.SaveUser.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.SaveUser
             * @static
             * @param {appcore.ripe.ISaveUser} message SaveUser message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SaveUser.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SaveUser message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.SaveUser
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.SaveUser} SaveUser
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SaveUser.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.SaveUser();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.int64();
                        break;
                    case 2:
                        message.name = reader.string();
                        break;
                    case 3:
                        message.email = reader.string();
                        break;
                    case 4:
                        message.phone = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SaveUser message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.SaveUser
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.SaveUser} SaveUser
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SaveUser.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SaveUser message.
             * @function verify
             * @memberof appcore.ripe.SaveUser
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SaveUser.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.email != null && message.hasOwnProperty("email"))
                    if (!$util.isString(message.email))
                        return "email: string expected";
                if (message.phone != null && message.hasOwnProperty("phone"))
                    if (!$util.isString(message.phone))
                        return "phone: string expected";
                return null;
            };

            /**
             * Creates a SaveUser message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.SaveUser
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.SaveUser} SaveUser
             */
            SaveUser.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.SaveUser)
                    return object;
                let message = new $root.appcore.ripe.SaveUser();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.email != null)
                    message.email = String(object.email);
                if (object.phone != null)
                    message.phone = String(object.phone);
                return message;
            };

            /**
             * Creates a plain object from a SaveUser message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.SaveUser
             * @static
             * @param {appcore.ripe.SaveUser} message SaveUser
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SaveUser.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                    object.name = "";
                    object.email = "";
                    object.phone = "";
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.email != null && message.hasOwnProperty("email"))
                    object.email = message.email;
                if (message.phone != null && message.hasOwnProperty("phone"))
                    object.phone = message.phone;
                return object;
            };

            /**
             * Converts this SaveUser to JSON.
             * @function toJSON
             * @memberof appcore.ripe.SaveUser
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SaveUser.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return SaveUser;
        })();

        ripe.ScreeningAlgo = (function() {

            /**
             * Properties of a ScreeningAlgo.
             * @memberof appcore.ripe
             * @interface IScreeningAlgo
             * @property {number|null} [id] ScreeningAlgo id
             * @property {string|null} [name] ScreeningAlgo name
             */

            /**
             * Constructs a new ScreeningAlgo.
             * @memberof appcore.ripe
             * @classdesc Represents a ScreeningAlgo.
             * @implements IScreeningAlgo
             * @constructor
             * @param {appcore.ripe.IScreeningAlgo=} [properties] Properties to set
             */
            function ScreeningAlgo(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ScreeningAlgo id.
             * @member {number} id
             * @memberof appcore.ripe.ScreeningAlgo
             * @instance
             */
            ScreeningAlgo.prototype.id = 0;

            /**
             * ScreeningAlgo name.
             * @member {string} name
             * @memberof appcore.ripe.ScreeningAlgo
             * @instance
             */
            ScreeningAlgo.prototype.name = "";

            /**
             * Creates a new ScreeningAlgo instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.ScreeningAlgo
             * @static
             * @param {appcore.ripe.IScreeningAlgo=} [properties] Properties to set
             * @returns {appcore.ripe.ScreeningAlgo} ScreeningAlgo instance
             */
            ScreeningAlgo.create = function create(properties) {
                return new ScreeningAlgo(properties);
            };

            /**
             * Encodes the specified ScreeningAlgo message. Does not implicitly {@link appcore.ripe.ScreeningAlgo.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.ScreeningAlgo
             * @static
             * @param {appcore.ripe.IScreeningAlgo} message ScreeningAlgo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ScreeningAlgo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                return writer;
            };

            /**
             * Encodes the specified ScreeningAlgo message, length delimited. Does not implicitly {@link appcore.ripe.ScreeningAlgo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.ScreeningAlgo
             * @static
             * @param {appcore.ripe.IScreeningAlgo} message ScreeningAlgo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ScreeningAlgo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ScreeningAlgo message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.ScreeningAlgo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.ScreeningAlgo} ScreeningAlgo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ScreeningAlgo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.ScreeningAlgo();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.int32();
                        break;
                    case 2:
                        message.name = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ScreeningAlgo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.ScreeningAlgo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.ScreeningAlgo} ScreeningAlgo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ScreeningAlgo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ScreeningAlgo message.
             * @function verify
             * @memberof appcore.ripe.ScreeningAlgo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ScreeningAlgo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                return null;
            };

            /**
             * Creates a ScreeningAlgo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.ScreeningAlgo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.ScreeningAlgo} ScreeningAlgo
             */
            ScreeningAlgo.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.ScreeningAlgo)
                    return object;
                let message = new $root.appcore.ripe.ScreeningAlgo();
                if (object.id != null)
                    message.id = object.id | 0;
                if (object.name != null)
                    message.name = String(object.name);
                return message;
            };

            /**
             * Creates a plain object from a ScreeningAlgo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.ScreeningAlgo
             * @static
             * @param {appcore.ripe.ScreeningAlgo} message ScreeningAlgo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ScreeningAlgo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.id = 0;
                    object.name = "";
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                return object;
            };

            /**
             * Converts this ScreeningAlgo to JSON.
             * @function toJSON
             * @memberof appcore.ripe.ScreeningAlgo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ScreeningAlgo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ScreeningAlgo;
        })();

        ripe.ScreeningAlgos = (function() {

            /**
             * Properties of a ScreeningAlgos.
             * @memberof appcore.ripe
             * @interface IScreeningAlgos
             * @property {Array.<appcore.ripe.IScreeningAlgo>|null} [algo] ScreeningAlgos algo
             */

            /**
             * Constructs a new ScreeningAlgos.
             * @memberof appcore.ripe
             * @classdesc Represents a ScreeningAlgos.
             * @implements IScreeningAlgos
             * @constructor
             * @param {appcore.ripe.IScreeningAlgos=} [properties] Properties to set
             */
            function ScreeningAlgos(properties) {
                this.algo = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ScreeningAlgos algo.
             * @member {Array.<appcore.ripe.IScreeningAlgo>} algo
             * @memberof appcore.ripe.ScreeningAlgos
             * @instance
             */
            ScreeningAlgos.prototype.algo = $util.emptyArray;

            /**
             * Creates a new ScreeningAlgos instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.ScreeningAlgos
             * @static
             * @param {appcore.ripe.IScreeningAlgos=} [properties] Properties to set
             * @returns {appcore.ripe.ScreeningAlgos} ScreeningAlgos instance
             */
            ScreeningAlgos.create = function create(properties) {
                return new ScreeningAlgos(properties);
            };

            /**
             * Encodes the specified ScreeningAlgos message. Does not implicitly {@link appcore.ripe.ScreeningAlgos.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.ScreeningAlgos
             * @static
             * @param {appcore.ripe.IScreeningAlgos} message ScreeningAlgos message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ScreeningAlgos.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.algo != null && message.algo.length)
                    for (let i = 0; i < message.algo.length; ++i)
                        $root.appcore.ripe.ScreeningAlgo.encode(message.algo[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified ScreeningAlgos message, length delimited. Does not implicitly {@link appcore.ripe.ScreeningAlgos.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.ScreeningAlgos
             * @static
             * @param {appcore.ripe.IScreeningAlgos} message ScreeningAlgos message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ScreeningAlgos.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ScreeningAlgos message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.ScreeningAlgos
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.ScreeningAlgos} ScreeningAlgos
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ScreeningAlgos.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.ScreeningAlgos();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.algo && message.algo.length))
                            message.algo = [];
                        message.algo.push($root.appcore.ripe.ScreeningAlgo.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ScreeningAlgos message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.ScreeningAlgos
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.ScreeningAlgos} ScreeningAlgos
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ScreeningAlgos.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ScreeningAlgos message.
             * @function verify
             * @memberof appcore.ripe.ScreeningAlgos
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ScreeningAlgos.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.algo != null && message.hasOwnProperty("algo")) {
                    if (!Array.isArray(message.algo))
                        return "algo: array expected";
                    for (let i = 0; i < message.algo.length; ++i) {
                        let error = $root.appcore.ripe.ScreeningAlgo.verify(message.algo[i]);
                        if (error)
                            return "algo." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a ScreeningAlgos message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.ScreeningAlgos
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.ScreeningAlgos} ScreeningAlgos
             */
            ScreeningAlgos.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.ScreeningAlgos)
                    return object;
                let message = new $root.appcore.ripe.ScreeningAlgos();
                if (object.algo) {
                    if (!Array.isArray(object.algo))
                        throw TypeError(".appcore.ripe.ScreeningAlgos.algo: array expected");
                    message.algo = [];
                    for (let i = 0; i < object.algo.length; ++i) {
                        if (typeof object.algo[i] !== "object")
                            throw TypeError(".appcore.ripe.ScreeningAlgos.algo: object expected");
                        message.algo[i] = $root.appcore.ripe.ScreeningAlgo.fromObject(object.algo[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a ScreeningAlgos message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.ScreeningAlgos
             * @static
             * @param {appcore.ripe.ScreeningAlgos} message ScreeningAlgos
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ScreeningAlgos.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.algo = [];
                if (message.algo && message.algo.length) {
                    object.algo = [];
                    for (let j = 0; j < message.algo.length; ++j)
                        object.algo[j] = $root.appcore.ripe.ScreeningAlgo.toObject(message.algo[j], options);
                }
                return object;
            };

            /**
             * Converts this ScreeningAlgos to JSON.
             * @function toJSON
             * @memberof appcore.ripe.ScreeningAlgos
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ScreeningAlgos.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ScreeningAlgos;
        })();

        ripe.ServiceData = (function() {

            /**
             * Properties of a ServiceData.
             * @memberof appcore.ripe
             * @interface IServiceData
             * @property {Array.<string>|null} [urls] ServiceData urls
             * @property {number|null} [output_code] ServiceData output_code
             * @property {boolean|null} [send_to_printer_port] ServiceData send_to_printer_port
             */

            /**
             * Constructs a new ServiceData.
             * @memberof appcore.ripe
             * @classdesc Represents a ServiceData.
             * @implements IServiceData
             * @constructor
             * @param {appcore.ripe.IServiceData=} [properties] Properties to set
             */
            function ServiceData(properties) {
                this.urls = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ServiceData urls.
             * @member {Array.<string>} urls
             * @memberof appcore.ripe.ServiceData
             * @instance
             */
            ServiceData.prototype.urls = $util.emptyArray;

            /**
             * ServiceData output_code.
             * @member {number} output_code
             * @memberof appcore.ripe.ServiceData
             * @instance
             */
            ServiceData.prototype.output_code = 0;

            /**
             * ServiceData send_to_printer_port.
             * @member {boolean} send_to_printer_port
             * @memberof appcore.ripe.ServiceData
             * @instance
             */
            ServiceData.prototype.send_to_printer_port = false;

            /**
             * Creates a new ServiceData instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.ServiceData
             * @static
             * @param {appcore.ripe.IServiceData=} [properties] Properties to set
             * @returns {appcore.ripe.ServiceData} ServiceData instance
             */
            ServiceData.create = function create(properties) {
                return new ServiceData(properties);
            };

            /**
             * Encodes the specified ServiceData message. Does not implicitly {@link appcore.ripe.ServiceData.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.ServiceData
             * @static
             * @param {appcore.ripe.IServiceData} message ServiceData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServiceData.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.urls != null && message.urls.length)
                    for (let i = 0; i < message.urls.length; ++i)
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.urls[i]);
                if (message.output_code != null && Object.hasOwnProperty.call(message, "output_code"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.output_code);
                if (message.send_to_printer_port != null && Object.hasOwnProperty.call(message, "send_to_printer_port"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.send_to_printer_port);
                return writer;
            };

            /**
             * Encodes the specified ServiceData message, length delimited. Does not implicitly {@link appcore.ripe.ServiceData.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.ServiceData
             * @static
             * @param {appcore.ripe.IServiceData} message ServiceData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServiceData.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ServiceData message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.ServiceData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.ServiceData} ServiceData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServiceData.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.ServiceData();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.urls && message.urls.length))
                            message.urls = [];
                        message.urls.push(reader.string());
                        break;
                    case 2:
                        message.output_code = reader.int32();
                        break;
                    case 3:
                        message.send_to_printer_port = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ServiceData message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.ServiceData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.ServiceData} ServiceData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServiceData.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ServiceData message.
             * @function verify
             * @memberof appcore.ripe.ServiceData
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ServiceData.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.urls != null && message.hasOwnProperty("urls")) {
                    if (!Array.isArray(message.urls))
                        return "urls: array expected";
                    for (let i = 0; i < message.urls.length; ++i)
                        if (!$util.isString(message.urls[i]))
                            return "urls: string[] expected";
                }
                if (message.output_code != null && message.hasOwnProperty("output_code"))
                    if (!$util.isInteger(message.output_code))
                        return "output_code: integer expected";
                if (message.send_to_printer_port != null && message.hasOwnProperty("send_to_printer_port"))
                    if (typeof message.send_to_printer_port !== "boolean")
                        return "send_to_printer_port: boolean expected";
                return null;
            };

            /**
             * Creates a ServiceData message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.ServiceData
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.ServiceData} ServiceData
             */
            ServiceData.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.ServiceData)
                    return object;
                let message = new $root.appcore.ripe.ServiceData();
                if (object.urls) {
                    if (!Array.isArray(object.urls))
                        throw TypeError(".appcore.ripe.ServiceData.urls: array expected");
                    message.urls = [];
                    for (let i = 0; i < object.urls.length; ++i)
                        message.urls[i] = String(object.urls[i]);
                }
                if (object.output_code != null)
                    message.output_code = object.output_code | 0;
                if (object.send_to_printer_port != null)
                    message.send_to_printer_port = Boolean(object.send_to_printer_port);
                return message;
            };

            /**
             * Creates a plain object from a ServiceData message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.ServiceData
             * @static
             * @param {appcore.ripe.ServiceData} message ServiceData
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ServiceData.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.urls = [];
                if (options.defaults) {
                    object.output_code = 0;
                    object.send_to_printer_port = false;
                }
                if (message.urls && message.urls.length) {
                    object.urls = [];
                    for (let j = 0; j < message.urls.length; ++j)
                        object.urls[j] = message.urls[j];
                }
                if (message.output_code != null && message.hasOwnProperty("output_code"))
                    object.output_code = message.output_code;
                if (message.send_to_printer_port != null && message.hasOwnProperty("send_to_printer_port"))
                    object.send_to_printer_port = message.send_to_printer_port;
                return object;
            };

            /**
             * Converts this ServiceData to JSON.
             * @function toJSON
             * @memberof appcore.ripe.ServiceData
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ServiceData.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ServiceData;
        })();

        ripe.ServiceID = (function() {

            /**
             * Properties of a ServiceID.
             * @memberof appcore.ripe
             * @interface IServiceID
             * @property {number|null} [version] ServiceID version
             * @property {string|null} [disk_serial_number] ServiceID disk_serial_number
             * @property {string|null} [system_serial_number] ServiceID system_serial_number
             */

            /**
             * Constructs a new ServiceID.
             * @memberof appcore.ripe
             * @classdesc Represents a ServiceID.
             * @implements IServiceID
             * @constructor
             * @param {appcore.ripe.IServiceID=} [properties] Properties to set
             */
            function ServiceID(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ServiceID version.
             * @member {number} version
             * @memberof appcore.ripe.ServiceID
             * @instance
             */
            ServiceID.prototype.version = 0;

            /**
             * ServiceID disk_serial_number.
             * @member {string} disk_serial_number
             * @memberof appcore.ripe.ServiceID
             * @instance
             */
            ServiceID.prototype.disk_serial_number = "";

            /**
             * ServiceID system_serial_number.
             * @member {string} system_serial_number
             * @memberof appcore.ripe.ServiceID
             * @instance
             */
            ServiceID.prototype.system_serial_number = "";

            /**
             * Creates a new ServiceID instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.ServiceID
             * @static
             * @param {appcore.ripe.IServiceID=} [properties] Properties to set
             * @returns {appcore.ripe.ServiceID} ServiceID instance
             */
            ServiceID.create = function create(properties) {
                return new ServiceID(properties);
            };

            /**
             * Encodes the specified ServiceID message. Does not implicitly {@link appcore.ripe.ServiceID.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.ServiceID
             * @static
             * @param {appcore.ripe.IServiceID} message ServiceID message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServiceID.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.version != null && Object.hasOwnProperty.call(message, "version"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.version);
                if (message.disk_serial_number != null && Object.hasOwnProperty.call(message, "disk_serial_number"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.disk_serial_number);
                if (message.system_serial_number != null && Object.hasOwnProperty.call(message, "system_serial_number"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.system_serial_number);
                return writer;
            };

            /**
             * Encodes the specified ServiceID message, length delimited. Does not implicitly {@link appcore.ripe.ServiceID.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.ServiceID
             * @static
             * @param {appcore.ripe.IServiceID} message ServiceID message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServiceID.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ServiceID message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.ServiceID
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.ServiceID} ServiceID
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServiceID.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.ServiceID();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.version = reader.int32();
                        break;
                    case 2:
                        message.disk_serial_number = reader.string();
                        break;
                    case 3:
                        message.system_serial_number = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ServiceID message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.ServiceID
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.ServiceID} ServiceID
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServiceID.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ServiceID message.
             * @function verify
             * @memberof appcore.ripe.ServiceID
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ServiceID.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.version != null && message.hasOwnProperty("version"))
                    if (!$util.isInteger(message.version))
                        return "version: integer expected";
                if (message.disk_serial_number != null && message.hasOwnProperty("disk_serial_number"))
                    if (!$util.isString(message.disk_serial_number))
                        return "disk_serial_number: string expected";
                if (message.system_serial_number != null && message.hasOwnProperty("system_serial_number"))
                    if (!$util.isString(message.system_serial_number))
                        return "system_serial_number: string expected";
                return null;
            };

            /**
             * Creates a ServiceID message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.ServiceID
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.ServiceID} ServiceID
             */
            ServiceID.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.ServiceID)
                    return object;
                let message = new $root.appcore.ripe.ServiceID();
                if (object.version != null)
                    message.version = object.version | 0;
                if (object.disk_serial_number != null)
                    message.disk_serial_number = String(object.disk_serial_number);
                if (object.system_serial_number != null)
                    message.system_serial_number = String(object.system_serial_number);
                return message;
            };

            /**
             * Creates a plain object from a ServiceID message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.ServiceID
             * @static
             * @param {appcore.ripe.ServiceID} message ServiceID
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ServiceID.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.version = 0;
                    object.disk_serial_number = "";
                    object.system_serial_number = "";
                }
                if (message.version != null && message.hasOwnProperty("version"))
                    object.version = message.version;
                if (message.disk_serial_number != null && message.hasOwnProperty("disk_serial_number"))
                    object.disk_serial_number = message.disk_serial_number;
                if (message.system_serial_number != null && message.hasOwnProperty("system_serial_number"))
                    object.system_serial_number = message.system_serial_number;
                return object;
            };

            /**
             * Converts this ServiceID to JSON.
             * @function toJSON
             * @memberof appcore.ripe.ServiceID
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ServiceID.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ServiceID;
        })();

        ripe.ServiceUUID = (function() {

            /**
             * Properties of a ServiceUUID.
             * @memberof appcore.ripe
             * @interface IServiceUUID
             * @property {string|null} [uuid] ServiceUUID uuid
             */

            /**
             * Constructs a new ServiceUUID.
             * @memberof appcore.ripe
             * @classdesc Represents a ServiceUUID.
             * @implements IServiceUUID
             * @constructor
             * @param {appcore.ripe.IServiceUUID=} [properties] Properties to set
             */
            function ServiceUUID(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ServiceUUID uuid.
             * @member {string} uuid
             * @memberof appcore.ripe.ServiceUUID
             * @instance
             */
            ServiceUUID.prototype.uuid = "";

            /**
             * Creates a new ServiceUUID instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.ServiceUUID
             * @static
             * @param {appcore.ripe.IServiceUUID=} [properties] Properties to set
             * @returns {appcore.ripe.ServiceUUID} ServiceUUID instance
             */
            ServiceUUID.create = function create(properties) {
                return new ServiceUUID(properties);
            };

            /**
             * Encodes the specified ServiceUUID message. Does not implicitly {@link appcore.ripe.ServiceUUID.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.ServiceUUID
             * @static
             * @param {appcore.ripe.IServiceUUID} message ServiceUUID message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServiceUUID.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.uuid != null && Object.hasOwnProperty.call(message, "uuid"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.uuid);
                return writer;
            };

            /**
             * Encodes the specified ServiceUUID message, length delimited. Does not implicitly {@link appcore.ripe.ServiceUUID.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.ServiceUUID
             * @static
             * @param {appcore.ripe.IServiceUUID} message ServiceUUID message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServiceUUID.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ServiceUUID message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.ServiceUUID
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.ServiceUUID} ServiceUUID
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServiceUUID.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.ServiceUUID();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.uuid = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ServiceUUID message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.ServiceUUID
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.ServiceUUID} ServiceUUID
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServiceUUID.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ServiceUUID message.
             * @function verify
             * @memberof appcore.ripe.ServiceUUID
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ServiceUUID.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.uuid != null && message.hasOwnProperty("uuid"))
                    if (!$util.isString(message.uuid))
                        return "uuid: string expected";
                return null;
            };

            /**
             * Creates a ServiceUUID message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.ServiceUUID
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.ServiceUUID} ServiceUUID
             */
            ServiceUUID.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.ServiceUUID)
                    return object;
                let message = new $root.appcore.ripe.ServiceUUID();
                if (object.uuid != null)
                    message.uuid = String(object.uuid);
                return message;
            };

            /**
             * Creates a plain object from a ServiceUUID message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.ServiceUUID
             * @static
             * @param {appcore.ripe.ServiceUUID} message ServiceUUID
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ServiceUUID.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.uuid = "";
                if (message.uuid != null && message.hasOwnProperty("uuid"))
                    object.uuid = message.uuid;
                return object;
            };

            /**
             * Converts this ServiceUUID to JSON.
             * @function toJSON
             * @memberof appcore.ripe.ServiceUUID
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ServiceUUID.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ServiceUUID;
        })();

        ripe.SessionState = (function() {

            /**
             * Properties of a SessionState.
             * @memberof appcore.ripe
             * @interface ISessionState
             * @property {string|null} [session_id] SessionState session_id
             * @property {boolean|null} [session_valid] SessionState session_valid
             * @property {boolean|null} [logout] SessionState logout
             */

            /**
             * Constructs a new SessionState.
             * @memberof appcore.ripe
             * @classdesc Represents a SessionState.
             * @implements ISessionState
             * @constructor
             * @param {appcore.ripe.ISessionState=} [properties] Properties to set
             */
            function SessionState(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SessionState session_id.
             * @member {string} session_id
             * @memberof appcore.ripe.SessionState
             * @instance
             */
            SessionState.prototype.session_id = "";

            /**
             * SessionState session_valid.
             * @member {boolean} session_valid
             * @memberof appcore.ripe.SessionState
             * @instance
             */
            SessionState.prototype.session_valid = false;

            /**
             * SessionState logout.
             * @member {boolean} logout
             * @memberof appcore.ripe.SessionState
             * @instance
             */
            SessionState.prototype.logout = false;

            /**
             * Creates a new SessionState instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.SessionState
             * @static
             * @param {appcore.ripe.ISessionState=} [properties] Properties to set
             * @returns {appcore.ripe.SessionState} SessionState instance
             */
            SessionState.create = function create(properties) {
                return new SessionState(properties);
            };

            /**
             * Encodes the specified SessionState message. Does not implicitly {@link appcore.ripe.SessionState.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.SessionState
             * @static
             * @param {appcore.ripe.ISessionState} message SessionState message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SessionState.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.session_id != null && Object.hasOwnProperty.call(message, "session_id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.session_id);
                if (message.session_valid != null && Object.hasOwnProperty.call(message, "session_valid"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.session_valid);
                if (message.logout != null && Object.hasOwnProperty.call(message, "logout"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.logout);
                return writer;
            };

            /**
             * Encodes the specified SessionState message, length delimited. Does not implicitly {@link appcore.ripe.SessionState.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.SessionState
             * @static
             * @param {appcore.ripe.ISessionState} message SessionState message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SessionState.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SessionState message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.SessionState
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.SessionState} SessionState
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SessionState.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.SessionState();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.session_id = reader.string();
                        break;
                    case 2:
                        message.session_valid = reader.bool();
                        break;
                    case 3:
                        message.logout = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SessionState message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.SessionState
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.SessionState} SessionState
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SessionState.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SessionState message.
             * @function verify
             * @memberof appcore.ripe.SessionState
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SessionState.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.session_id != null && message.hasOwnProperty("session_id"))
                    if (!$util.isString(message.session_id))
                        return "session_id: string expected";
                if (message.session_valid != null && message.hasOwnProperty("session_valid"))
                    if (typeof message.session_valid !== "boolean")
                        return "session_valid: boolean expected";
                if (message.logout != null && message.hasOwnProperty("logout"))
                    if (typeof message.logout !== "boolean")
                        return "logout: boolean expected";
                return null;
            };

            /**
             * Creates a SessionState message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.SessionState
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.SessionState} SessionState
             */
            SessionState.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.SessionState)
                    return object;
                let message = new $root.appcore.ripe.SessionState();
                if (object.session_id != null)
                    message.session_id = String(object.session_id);
                if (object.session_valid != null)
                    message.session_valid = Boolean(object.session_valid);
                if (object.logout != null)
                    message.logout = Boolean(object.logout);
                return message;
            };

            /**
             * Creates a plain object from a SessionState message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.SessionState
             * @static
             * @param {appcore.ripe.SessionState} message SessionState
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SessionState.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.session_id = "";
                    object.session_valid = false;
                    object.logout = false;
                }
                if (message.session_id != null && message.hasOwnProperty("session_id"))
                    object.session_id = message.session_id;
                if (message.session_valid != null && message.hasOwnProperty("session_valid"))
                    object.session_valid = message.session_valid;
                if (message.logout != null && message.hasOwnProperty("logout"))
                    object.logout = message.logout;
                return object;
            };

            /**
             * Converts this SessionState to JSON.
             * @function toJSON
             * @memberof appcore.ripe.SessionState
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SessionState.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return SessionState;
        })();

        ripe.SetOutputForUser = (function() {

            /**
             * Properties of a SetOutputForUser.
             * @memberof appcore.ripe
             * @interface ISetOutputForUser
             * @property {string|null} [user_id] SetOutputForUser user_id
             * @property {number|null} [output_code] SetOutputForUser output_code
             */

            /**
             * Constructs a new SetOutputForUser.
             * @memberof appcore.ripe
             * @classdesc Represents a SetOutputForUser.
             * @implements ISetOutputForUser
             * @constructor
             * @param {appcore.ripe.ISetOutputForUser=} [properties] Properties to set
             */
            function SetOutputForUser(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SetOutputForUser user_id.
             * @member {string} user_id
             * @memberof appcore.ripe.SetOutputForUser
             * @instance
             */
            SetOutputForUser.prototype.user_id = "";

            /**
             * SetOutputForUser output_code.
             * @member {number} output_code
             * @memberof appcore.ripe.SetOutputForUser
             * @instance
             */
            SetOutputForUser.prototype.output_code = 0;

            /**
             * Creates a new SetOutputForUser instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.SetOutputForUser
             * @static
             * @param {appcore.ripe.ISetOutputForUser=} [properties] Properties to set
             * @returns {appcore.ripe.SetOutputForUser} SetOutputForUser instance
             */
            SetOutputForUser.create = function create(properties) {
                return new SetOutputForUser(properties);
            };

            /**
             * Encodes the specified SetOutputForUser message. Does not implicitly {@link appcore.ripe.SetOutputForUser.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.SetOutputForUser
             * @static
             * @param {appcore.ripe.ISetOutputForUser} message SetOutputForUser message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SetOutputForUser.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.user_id != null && Object.hasOwnProperty.call(message, "user_id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.user_id);
                if (message.output_code != null && Object.hasOwnProperty.call(message, "output_code"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.output_code);
                return writer;
            };

            /**
             * Encodes the specified SetOutputForUser message, length delimited. Does not implicitly {@link appcore.ripe.SetOutputForUser.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.SetOutputForUser
             * @static
             * @param {appcore.ripe.ISetOutputForUser} message SetOutputForUser message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SetOutputForUser.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SetOutputForUser message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.SetOutputForUser
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.SetOutputForUser} SetOutputForUser
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SetOutputForUser.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.SetOutputForUser();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.user_id = reader.string();
                        break;
                    case 2:
                        message.output_code = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SetOutputForUser message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.SetOutputForUser
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.SetOutputForUser} SetOutputForUser
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SetOutputForUser.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SetOutputForUser message.
             * @function verify
             * @memberof appcore.ripe.SetOutputForUser
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SetOutputForUser.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.user_id != null && message.hasOwnProperty("user_id"))
                    if (!$util.isString(message.user_id))
                        return "user_id: string expected";
                if (message.output_code != null && message.hasOwnProperty("output_code"))
                    if (!$util.isInteger(message.output_code))
                        return "output_code: integer expected";
                return null;
            };

            /**
             * Creates a SetOutputForUser message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.SetOutputForUser
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.SetOutputForUser} SetOutputForUser
             */
            SetOutputForUser.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.SetOutputForUser)
                    return object;
                let message = new $root.appcore.ripe.SetOutputForUser();
                if (object.user_id != null)
                    message.user_id = String(object.user_id);
                if (object.output_code != null)
                    message.output_code = object.output_code | 0;
                return message;
            };

            /**
             * Creates a plain object from a SetOutputForUser message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.SetOutputForUser
             * @static
             * @param {appcore.ripe.SetOutputForUser} message SetOutputForUser
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SetOutputForUser.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.user_id = "";
                    object.output_code = 0;
                }
                if (message.user_id != null && message.hasOwnProperty("user_id"))
                    object.user_id = message.user_id;
                if (message.output_code != null && message.hasOwnProperty("output_code"))
                    object.output_code = message.output_code;
                return object;
            };

            /**
             * Converts this SetOutputForUser to JSON.
             * @function toJSON
             * @memberof appcore.ripe.SetOutputForUser
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SetOutputForUser.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return SetOutputForUser;
        })();

        ripe.SourcePDFConfigsUpdatedAt = (function() {

            /**
             * Properties of a SourcePDFConfigsUpdatedAt.
             * @memberof appcore.ripe
             * @interface ISourcePDFConfigsUpdatedAt
             * @property {string|null} [guid] SourcePDFConfigsUpdatedAt guid
             * @property {string|null} [configs_updated_at] SourcePDFConfigsUpdatedAt configs_updated_at
             */

            /**
             * Constructs a new SourcePDFConfigsUpdatedAt.
             * @memberof appcore.ripe
             * @classdesc Represents a SourcePDFConfigsUpdatedAt.
             * @implements ISourcePDFConfigsUpdatedAt
             * @constructor
             * @param {appcore.ripe.ISourcePDFConfigsUpdatedAt=} [properties] Properties to set
             */
            function SourcePDFConfigsUpdatedAt(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SourcePDFConfigsUpdatedAt guid.
             * @member {string} guid
             * @memberof appcore.ripe.SourcePDFConfigsUpdatedAt
             * @instance
             */
            SourcePDFConfigsUpdatedAt.prototype.guid = "";

            /**
             * SourcePDFConfigsUpdatedAt configs_updated_at.
             * @member {string} configs_updated_at
             * @memberof appcore.ripe.SourcePDFConfigsUpdatedAt
             * @instance
             */
            SourcePDFConfigsUpdatedAt.prototype.configs_updated_at = "";

            /**
             * Creates a new SourcePDFConfigsUpdatedAt instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.SourcePDFConfigsUpdatedAt
             * @static
             * @param {appcore.ripe.ISourcePDFConfigsUpdatedAt=} [properties] Properties to set
             * @returns {appcore.ripe.SourcePDFConfigsUpdatedAt} SourcePDFConfigsUpdatedAt instance
             */
            SourcePDFConfigsUpdatedAt.create = function create(properties) {
                return new SourcePDFConfigsUpdatedAt(properties);
            };

            /**
             * Encodes the specified SourcePDFConfigsUpdatedAt message. Does not implicitly {@link appcore.ripe.SourcePDFConfigsUpdatedAt.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.SourcePDFConfigsUpdatedAt
             * @static
             * @param {appcore.ripe.ISourcePDFConfigsUpdatedAt} message SourcePDFConfigsUpdatedAt message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SourcePDFConfigsUpdatedAt.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.guid != null && Object.hasOwnProperty.call(message, "guid"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.guid);
                if (message.configs_updated_at != null && Object.hasOwnProperty.call(message, "configs_updated_at"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.configs_updated_at);
                return writer;
            };

            /**
             * Encodes the specified SourcePDFConfigsUpdatedAt message, length delimited. Does not implicitly {@link appcore.ripe.SourcePDFConfigsUpdatedAt.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.SourcePDFConfigsUpdatedAt
             * @static
             * @param {appcore.ripe.ISourcePDFConfigsUpdatedAt} message SourcePDFConfigsUpdatedAt message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SourcePDFConfigsUpdatedAt.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SourcePDFConfigsUpdatedAt message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.SourcePDFConfigsUpdatedAt
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.SourcePDFConfigsUpdatedAt} SourcePDFConfigsUpdatedAt
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SourcePDFConfigsUpdatedAt.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.SourcePDFConfigsUpdatedAt();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.guid = reader.string();
                        break;
                    case 2:
                        message.configs_updated_at = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SourcePDFConfigsUpdatedAt message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.SourcePDFConfigsUpdatedAt
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.SourcePDFConfigsUpdatedAt} SourcePDFConfigsUpdatedAt
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SourcePDFConfigsUpdatedAt.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SourcePDFConfigsUpdatedAt message.
             * @function verify
             * @memberof appcore.ripe.SourcePDFConfigsUpdatedAt
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SourcePDFConfigsUpdatedAt.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.guid != null && message.hasOwnProperty("guid"))
                    if (!$util.isString(message.guid))
                        return "guid: string expected";
                if (message.configs_updated_at != null && message.hasOwnProperty("configs_updated_at"))
                    if (!$util.isString(message.configs_updated_at))
                        return "configs_updated_at: string expected";
                return null;
            };

            /**
             * Creates a SourcePDFConfigsUpdatedAt message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.SourcePDFConfigsUpdatedAt
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.SourcePDFConfigsUpdatedAt} SourcePDFConfigsUpdatedAt
             */
            SourcePDFConfigsUpdatedAt.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.SourcePDFConfigsUpdatedAt)
                    return object;
                let message = new $root.appcore.ripe.SourcePDFConfigsUpdatedAt();
                if (object.guid != null)
                    message.guid = String(object.guid);
                if (object.configs_updated_at != null)
                    message.configs_updated_at = String(object.configs_updated_at);
                return message;
            };

            /**
             * Creates a plain object from a SourcePDFConfigsUpdatedAt message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.SourcePDFConfigsUpdatedAt
             * @static
             * @param {appcore.ripe.SourcePDFConfigsUpdatedAt} message SourcePDFConfigsUpdatedAt
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SourcePDFConfigsUpdatedAt.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.guid = "";
                    object.configs_updated_at = "";
                }
                if (message.guid != null && message.hasOwnProperty("guid"))
                    object.guid = message.guid;
                if (message.configs_updated_at != null && message.hasOwnProperty("configs_updated_at"))
                    object.configs_updated_at = message.configs_updated_at;
                return object;
            };

            /**
             * Converts this SourcePDFConfigsUpdatedAt to JSON.
             * @function toJSON
             * @memberof appcore.ripe.SourcePDFConfigsUpdatedAt
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SourcePDFConfigsUpdatedAt.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return SourcePDFConfigsUpdatedAt;
        })();

        ripe.UserInfo = (function() {

            /**
             * Properties of a UserInfo.
             * @memberof appcore.ripe
             * @interface IUserInfo
             * @property {string|null} [name] UserInfo name
             * @property {string|null} [email] UserInfo email
             * @property {string|null} [phone] UserInfo phone
             * @property {boolean|null} [admin] UserInfo admin
             * @property {boolean|null} [show_screening_tab] UserInfo show_screening_tab
             */

            /**
             * Constructs a new UserInfo.
             * @memberof appcore.ripe
             * @classdesc Represents a UserInfo.
             * @implements IUserInfo
             * @constructor
             * @param {appcore.ripe.IUserInfo=} [properties] Properties to set
             */
            function UserInfo(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UserInfo name.
             * @member {string} name
             * @memberof appcore.ripe.UserInfo
             * @instance
             */
            UserInfo.prototype.name = "";

            /**
             * UserInfo email.
             * @member {string} email
             * @memberof appcore.ripe.UserInfo
             * @instance
             */
            UserInfo.prototype.email = "";

            /**
             * UserInfo phone.
             * @member {string} phone
             * @memberof appcore.ripe.UserInfo
             * @instance
             */
            UserInfo.prototype.phone = "";

            /**
             * UserInfo admin.
             * @member {boolean} admin
             * @memberof appcore.ripe.UserInfo
             * @instance
             */
            UserInfo.prototype.admin = false;

            /**
             * UserInfo show_screening_tab.
             * @member {boolean} show_screening_tab
             * @memberof appcore.ripe.UserInfo
             * @instance
             */
            UserInfo.prototype.show_screening_tab = false;

            /**
             * Creates a new UserInfo instance using the specified properties.
             * @function create
             * @memberof appcore.ripe.UserInfo
             * @static
             * @param {appcore.ripe.IUserInfo=} [properties] Properties to set
             * @returns {appcore.ripe.UserInfo} UserInfo instance
             */
            UserInfo.create = function create(properties) {
                return new UserInfo(properties);
            };

            /**
             * Encodes the specified UserInfo message. Does not implicitly {@link appcore.ripe.UserInfo.verify|verify} messages.
             * @function encode
             * @memberof appcore.ripe.UserInfo
             * @static
             * @param {appcore.ripe.IUserInfo} message UserInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UserInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.email);
                if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.phone);
                if (message.admin != null && Object.hasOwnProperty.call(message, "admin"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.admin);
                if (message.show_screening_tab != null && Object.hasOwnProperty.call(message, "show_screening_tab"))
                    writer.uint32(/* id 5, wireType 0 =*/40).bool(message.show_screening_tab);
                return writer;
            };

            /**
             * Encodes the specified UserInfo message, length delimited. Does not implicitly {@link appcore.ripe.UserInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appcore.ripe.UserInfo
             * @static
             * @param {appcore.ripe.IUserInfo} message UserInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UserInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a UserInfo message from the specified reader or buffer.
             * @function decode
             * @memberof appcore.ripe.UserInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appcore.ripe.UserInfo} UserInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UserInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appcore.ripe.UserInfo();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.name = reader.string();
                        break;
                    case 2:
                        message.email = reader.string();
                        break;
                    case 3:
                        message.phone = reader.string();
                        break;
                    case 4:
                        message.admin = reader.bool();
                        break;
                    case 5:
                        message.show_screening_tab = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a UserInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appcore.ripe.UserInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appcore.ripe.UserInfo} UserInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UserInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a UserInfo message.
             * @function verify
             * @memberof appcore.ripe.UserInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UserInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.email != null && message.hasOwnProperty("email"))
                    if (!$util.isString(message.email))
                        return "email: string expected";
                if (message.phone != null && message.hasOwnProperty("phone"))
                    if (!$util.isString(message.phone))
                        return "phone: string expected";
                if (message.admin != null && message.hasOwnProperty("admin"))
                    if (typeof message.admin !== "boolean")
                        return "admin: boolean expected";
                if (message.show_screening_tab != null && message.hasOwnProperty("show_screening_tab"))
                    if (typeof message.show_screening_tab !== "boolean")
                        return "show_screening_tab: boolean expected";
                return null;
            };

            /**
             * Creates a UserInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appcore.ripe.UserInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appcore.ripe.UserInfo} UserInfo
             */
            UserInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.appcore.ripe.UserInfo)
                    return object;
                let message = new $root.appcore.ripe.UserInfo();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.email != null)
                    message.email = String(object.email);
                if (object.phone != null)
                    message.phone = String(object.phone);
                if (object.admin != null)
                    message.admin = Boolean(object.admin);
                if (object.show_screening_tab != null)
                    message.show_screening_tab = Boolean(object.show_screening_tab);
                return message;
            };

            /**
             * Creates a plain object from a UserInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appcore.ripe.UserInfo
             * @static
             * @param {appcore.ripe.UserInfo} message UserInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.name = "";
                    object.email = "";
                    object.phone = "";
                    object.admin = false;
                    object.show_screening_tab = false;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.email != null && message.hasOwnProperty("email"))
                    object.email = message.email;
                if (message.phone != null && message.hasOwnProperty("phone"))
                    object.phone = message.phone;
                if (message.admin != null && message.hasOwnProperty("admin"))
                    object.admin = message.admin;
                if (message.show_screening_tab != null && message.hasOwnProperty("show_screening_tab"))
                    object.show_screening_tab = message.show_screening_tab;
                return object;
            };

            /**
             * Converts this UserInfo to JSON.
             * @function toJSON
             * @memberof appcore.ripe.UserInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UserInfo;
        })();

        return ripe;
    })();

    return appcore;
})();

export const printcloud = $root.printcloud = (() => {

    /**
     * Namespace printcloud.
     * @exports printcloud
     * @namespace
     */
    const printcloud = {};

    printcloud.digital = (function() {

        /**
         * Namespace digital.
         * @memberof printcloud
         * @namespace
         */
        const digital = {};

        digital.headless = (function() {

            /**
             * Namespace headless.
             * @memberof printcloud.digital
             * @namespace
             */
            const headless = {};

            headless.ProfilingSettings = (function() {

                /**
                 * Properties of a ProfilingSettings.
                 * @memberof printcloud.digital.headless
                 * @interface IProfilingSettings
                 * @property {number|null} [id_intent] ProfilingSettings id_intent
                 * @property {string|null} [uri_icc] ProfilingSettings uri_icc
                 * @property {Array.<string>|null} [uri_device_link] ProfilingSettings uri_device_link
                 */

                /**
                 * Constructs a new ProfilingSettings.
                 * @memberof printcloud.digital.headless
                 * @classdesc Represents a ProfilingSettings.
                 * @implements IProfilingSettings
                 * @constructor
                 * @param {printcloud.digital.headless.IProfilingSettings=} [properties] Properties to set
                 */
                function ProfilingSettings(properties) {
                    this.uri_device_link = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ProfilingSettings id_intent.
                 * @member {number} id_intent
                 * @memberof printcloud.digital.headless.ProfilingSettings
                 * @instance
                 */
                ProfilingSettings.prototype.id_intent = 0;

                /**
                 * ProfilingSettings uri_icc.
                 * @member {string} uri_icc
                 * @memberof printcloud.digital.headless.ProfilingSettings
                 * @instance
                 */
                ProfilingSettings.prototype.uri_icc = "";

                /**
                 * ProfilingSettings uri_device_link.
                 * @member {Array.<string>} uri_device_link
                 * @memberof printcloud.digital.headless.ProfilingSettings
                 * @instance
                 */
                ProfilingSettings.prototype.uri_device_link = $util.emptyArray;

                /**
                 * Creates a new ProfilingSettings instance using the specified properties.
                 * @function create
                 * @memberof printcloud.digital.headless.ProfilingSettings
                 * @static
                 * @param {printcloud.digital.headless.IProfilingSettings=} [properties] Properties to set
                 * @returns {printcloud.digital.headless.ProfilingSettings} ProfilingSettings instance
                 */
                ProfilingSettings.create = function create(properties) {
                    return new ProfilingSettings(properties);
                };

                /**
                 * Encodes the specified ProfilingSettings message. Does not implicitly {@link printcloud.digital.headless.ProfilingSettings.verify|verify} messages.
                 * @function encode
                 * @memberof printcloud.digital.headless.ProfilingSettings
                 * @static
                 * @param {printcloud.digital.headless.IProfilingSettings} message ProfilingSettings message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProfilingSettings.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id_intent != null && Object.hasOwnProperty.call(message, "id_intent"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id_intent);
                    if (message.uri_icc != null && Object.hasOwnProperty.call(message, "uri_icc"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.uri_icc);
                    if (message.uri_device_link != null && message.uri_device_link.length)
                        for (let i = 0; i < message.uri_device_link.length; ++i)
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.uri_device_link[i]);
                    return writer;
                };

                /**
                 * Encodes the specified ProfilingSettings message, length delimited. Does not implicitly {@link printcloud.digital.headless.ProfilingSettings.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof printcloud.digital.headless.ProfilingSettings
                 * @static
                 * @param {printcloud.digital.headless.IProfilingSettings} message ProfilingSettings message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProfilingSettings.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ProfilingSettings message from the specified reader or buffer.
                 * @function decode
                 * @memberof printcloud.digital.headless.ProfilingSettings
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {printcloud.digital.headless.ProfilingSettings} ProfilingSettings
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProfilingSettings.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.printcloud.digital.headless.ProfilingSettings();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.id_intent = reader.int32();
                            break;
                        case 2:
                            message.uri_icc = reader.string();
                            break;
                        case 3:
                            if (!(message.uri_device_link && message.uri_device_link.length))
                                message.uri_device_link = [];
                            message.uri_device_link.push(reader.string());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ProfilingSettings message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof printcloud.digital.headless.ProfilingSettings
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {printcloud.digital.headless.ProfilingSettings} ProfilingSettings
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProfilingSettings.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ProfilingSettings message.
                 * @function verify
                 * @memberof printcloud.digital.headless.ProfilingSettings
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ProfilingSettings.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id_intent != null && message.hasOwnProperty("id_intent"))
                        if (!$util.isInteger(message.id_intent))
                            return "id_intent: integer expected";
                    if (message.uri_icc != null && message.hasOwnProperty("uri_icc"))
                        if (!$util.isString(message.uri_icc))
                            return "uri_icc: string expected";
                    if (message.uri_device_link != null && message.hasOwnProperty("uri_device_link")) {
                        if (!Array.isArray(message.uri_device_link))
                            return "uri_device_link: array expected";
                        for (let i = 0; i < message.uri_device_link.length; ++i)
                            if (!$util.isString(message.uri_device_link[i]))
                                return "uri_device_link: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ProfilingSettings message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof printcloud.digital.headless.ProfilingSettings
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {printcloud.digital.headless.ProfilingSettings} ProfilingSettings
                 */
                ProfilingSettings.fromObject = function fromObject(object) {
                    if (object instanceof $root.printcloud.digital.headless.ProfilingSettings)
                        return object;
                    let message = new $root.printcloud.digital.headless.ProfilingSettings();
                    if (object.id_intent != null)
                        message.id_intent = object.id_intent | 0;
                    if (object.uri_icc != null)
                        message.uri_icc = String(object.uri_icc);
                    if (object.uri_device_link) {
                        if (!Array.isArray(object.uri_device_link))
                            throw TypeError(".printcloud.digital.headless.ProfilingSettings.uri_device_link: array expected");
                        message.uri_device_link = [];
                        for (let i = 0; i < object.uri_device_link.length; ++i)
                            message.uri_device_link[i] = String(object.uri_device_link[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ProfilingSettings message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof printcloud.digital.headless.ProfilingSettings
                 * @static
                 * @param {printcloud.digital.headless.ProfilingSettings} message ProfilingSettings
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ProfilingSettings.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.uri_device_link = [];
                    if (options.defaults) {
                        object.id_intent = 0;
                        object.uri_icc = "";
                    }
                    if (message.id_intent != null && message.hasOwnProperty("id_intent"))
                        object.id_intent = message.id_intent;
                    if (message.uri_icc != null && message.hasOwnProperty("uri_icc"))
                        object.uri_icc = message.uri_icc;
                    if (message.uri_device_link && message.uri_device_link.length) {
                        object.uri_device_link = [];
                        for (let j = 0; j < message.uri_device_link.length; ++j)
                            object.uri_device_link[j] = message.uri_device_link[j];
                    }
                    return object;
                };

                /**
                 * Converts this ProfilingSettings to JSON.
                 * @function toJSON
                 * @memberof printcloud.digital.headless.ProfilingSettings
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ProfilingSettings.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ProfilingSettings;
            })();

            headless.SparSettings = (function() {

                /**
                 * Properties of a SparSettings.
                 * @memberof printcloud.digital.headless
                 * @interface ISparSettings
                 * @property {number|null} [x] SparSettings x
                 * @property {number|null} [y] SparSettings y
                 * @property {number|null} [width] SparSettings width
                 * @property {number|null} [height] SparSettings height
                 * @property {printcloud.digital.headless.IProfilingSettings|null} [profiling_settings] SparSettings profiling_settings
                 */

                /**
                 * Constructs a new SparSettings.
                 * @memberof printcloud.digital.headless
                 * @classdesc Represents a SparSettings.
                 * @implements ISparSettings
                 * @constructor
                 * @param {printcloud.digital.headless.ISparSettings=} [properties] Properties to set
                 */
                function SparSettings(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SparSettings x.
                 * @member {number} x
                 * @memberof printcloud.digital.headless.SparSettings
                 * @instance
                 */
                SparSettings.prototype.x = 0;

                /**
                 * SparSettings y.
                 * @member {number} y
                 * @memberof printcloud.digital.headless.SparSettings
                 * @instance
                 */
                SparSettings.prototype.y = 0;

                /**
                 * SparSettings width.
                 * @member {number} width
                 * @memberof printcloud.digital.headless.SparSettings
                 * @instance
                 */
                SparSettings.prototype.width = 0;

                /**
                 * SparSettings height.
                 * @member {number} height
                 * @memberof printcloud.digital.headless.SparSettings
                 * @instance
                 */
                SparSettings.prototype.height = 0;

                /**
                 * SparSettings profiling_settings.
                 * @member {printcloud.digital.headless.IProfilingSettings|null|undefined} profiling_settings
                 * @memberof printcloud.digital.headless.SparSettings
                 * @instance
                 */
                SparSettings.prototype.profiling_settings = null;

                /**
                 * Creates a new SparSettings instance using the specified properties.
                 * @function create
                 * @memberof printcloud.digital.headless.SparSettings
                 * @static
                 * @param {printcloud.digital.headless.ISparSettings=} [properties] Properties to set
                 * @returns {printcloud.digital.headless.SparSettings} SparSettings instance
                 */
                SparSettings.create = function create(properties) {
                    return new SparSettings(properties);
                };

                /**
                 * Encodes the specified SparSettings message. Does not implicitly {@link printcloud.digital.headless.SparSettings.verify|verify} messages.
                 * @function encode
                 * @memberof printcloud.digital.headless.SparSettings
                 * @static
                 * @param {printcloud.digital.headless.ISparSettings} message SparSettings message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SparSettings.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.x != null && Object.hasOwnProperty.call(message, "x"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.x);
                    if (message.y != null && Object.hasOwnProperty.call(message, "y"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.y);
                    if (message.width != null && Object.hasOwnProperty.call(message, "width"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.width);
                    if (message.height != null && Object.hasOwnProperty.call(message, "height"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.height);
                    if (message.profiling_settings != null && Object.hasOwnProperty.call(message, "profiling_settings"))
                        $root.printcloud.digital.headless.ProfilingSettings.encode(message.profiling_settings, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified SparSettings message, length delimited. Does not implicitly {@link printcloud.digital.headless.SparSettings.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof printcloud.digital.headless.SparSettings
                 * @static
                 * @param {printcloud.digital.headless.ISparSettings} message SparSettings message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SparSettings.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a SparSettings message from the specified reader or buffer.
                 * @function decode
                 * @memberof printcloud.digital.headless.SparSettings
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {printcloud.digital.headless.SparSettings} SparSettings
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SparSettings.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.printcloud.digital.headless.SparSettings();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.x = reader.int32();
                            break;
                        case 2:
                            message.y = reader.int32();
                            break;
                        case 3:
                            message.width = reader.int32();
                            break;
                        case 4:
                            message.height = reader.int32();
                            break;
                        case 5:
                            message.profiling_settings = $root.printcloud.digital.headless.ProfilingSettings.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a SparSettings message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof printcloud.digital.headless.SparSettings
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {printcloud.digital.headless.SparSettings} SparSettings
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SparSettings.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a SparSettings message.
                 * @function verify
                 * @memberof printcloud.digital.headless.SparSettings
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SparSettings.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.x != null && message.hasOwnProperty("x"))
                        if (!$util.isInteger(message.x))
                            return "x: integer expected";
                    if (message.y != null && message.hasOwnProperty("y"))
                        if (!$util.isInteger(message.y))
                            return "y: integer expected";
                    if (message.width != null && message.hasOwnProperty("width"))
                        if (!$util.isInteger(message.width))
                            return "width: integer expected";
                    if (message.height != null && message.hasOwnProperty("height"))
                        if (!$util.isInteger(message.height))
                            return "height: integer expected";
                    if (message.profiling_settings != null && message.hasOwnProperty("profiling_settings")) {
                        let error = $root.printcloud.digital.headless.ProfilingSettings.verify(message.profiling_settings);
                        if (error)
                            return "profiling_settings." + error;
                    }
                    return null;
                };

                /**
                 * Creates a SparSettings message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof printcloud.digital.headless.SparSettings
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {printcloud.digital.headless.SparSettings} SparSettings
                 */
                SparSettings.fromObject = function fromObject(object) {
                    if (object instanceof $root.printcloud.digital.headless.SparSettings)
                        return object;
                    let message = new $root.printcloud.digital.headless.SparSettings();
                    if (object.x != null)
                        message.x = object.x | 0;
                    if (object.y != null)
                        message.y = object.y | 0;
                    if (object.width != null)
                        message.width = object.width | 0;
                    if (object.height != null)
                        message.height = object.height | 0;
                    if (object.profiling_settings != null) {
                        if (typeof object.profiling_settings !== "object")
                            throw TypeError(".printcloud.digital.headless.SparSettings.profiling_settings: object expected");
                        message.profiling_settings = $root.printcloud.digital.headless.ProfilingSettings.fromObject(object.profiling_settings);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SparSettings message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof printcloud.digital.headless.SparSettings
                 * @static
                 * @param {printcloud.digital.headless.SparSettings} message SparSettings
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SparSettings.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.x = 0;
                        object.y = 0;
                        object.width = 0;
                        object.height = 0;
                        object.profiling_settings = null;
                    }
                    if (message.x != null && message.hasOwnProperty("x"))
                        object.x = message.x;
                    if (message.y != null && message.hasOwnProperty("y"))
                        object.y = message.y;
                    if (message.width != null && message.hasOwnProperty("width"))
                        object.width = message.width;
                    if (message.height != null && message.hasOwnProperty("height"))
                        object.height = message.height;
                    if (message.profiling_settings != null && message.hasOwnProperty("profiling_settings"))
                        object.profiling_settings = $root.printcloud.digital.headless.ProfilingSettings.toObject(message.profiling_settings, options);
                    return object;
                };

                /**
                 * Converts this SparSettings to JSON.
                 * @function toJSON
                 * @memberof printcloud.digital.headless.SparSettings
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SparSettings.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SparSettings;
            })();

            headless.RasterSettings = (function() {

                /**
                 * Properties of a RasterSettings.
                 * @memberof printcloud.digital.headless
                 * @interface IRasterSettings
                 * @property {Uint8Array|null} [uuid_output_configuration] RasterSettings uuid_output_configuration
                 * @property {number|null} [dpi_x] RasterSettings dpi_x
                 * @property {number|null} [dpi_y] RasterSettings dpi_y
                 * @property {printcloud.digital.headless.IProfilingSettings|null} [profiling_settings] RasterSettings profiling_settings
                 * @property {Array.<printcloud.digital.headless.ISparSettings>|null} [spar_settings] RasterSettings spar_settings
                 */

                /**
                 * Constructs a new RasterSettings.
                 * @memberof printcloud.digital.headless
                 * @classdesc Represents a RasterSettings.
                 * @implements IRasterSettings
                 * @constructor
                 * @param {printcloud.digital.headless.IRasterSettings=} [properties] Properties to set
                 */
                function RasterSettings(properties) {
                    this.spar_settings = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * RasterSettings uuid_output_configuration.
                 * @member {Uint8Array} uuid_output_configuration
                 * @memberof printcloud.digital.headless.RasterSettings
                 * @instance
                 */
                RasterSettings.prototype.uuid_output_configuration = $util.newBuffer([]);

                /**
                 * RasterSettings dpi_x.
                 * @member {number} dpi_x
                 * @memberof printcloud.digital.headless.RasterSettings
                 * @instance
                 */
                RasterSettings.prototype.dpi_x = 0;

                /**
                 * RasterSettings dpi_y.
                 * @member {number} dpi_y
                 * @memberof printcloud.digital.headless.RasterSettings
                 * @instance
                 */
                RasterSettings.prototype.dpi_y = 0;

                /**
                 * RasterSettings profiling_settings.
                 * @member {printcloud.digital.headless.IProfilingSettings|null|undefined} profiling_settings
                 * @memberof printcloud.digital.headless.RasterSettings
                 * @instance
                 */
                RasterSettings.prototype.profiling_settings = null;

                /**
                 * RasterSettings spar_settings.
                 * @member {Array.<printcloud.digital.headless.ISparSettings>} spar_settings
                 * @memberof printcloud.digital.headless.RasterSettings
                 * @instance
                 */
                RasterSettings.prototype.spar_settings = $util.emptyArray;

                /**
                 * Creates a new RasterSettings instance using the specified properties.
                 * @function create
                 * @memberof printcloud.digital.headless.RasterSettings
                 * @static
                 * @param {printcloud.digital.headless.IRasterSettings=} [properties] Properties to set
                 * @returns {printcloud.digital.headless.RasterSettings} RasterSettings instance
                 */
                RasterSettings.create = function create(properties) {
                    return new RasterSettings(properties);
                };

                /**
                 * Encodes the specified RasterSettings message. Does not implicitly {@link printcloud.digital.headless.RasterSettings.verify|verify} messages.
                 * @function encode
                 * @memberof printcloud.digital.headless.RasterSettings
                 * @static
                 * @param {printcloud.digital.headless.IRasterSettings} message RasterSettings message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RasterSettings.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.uuid_output_configuration != null && Object.hasOwnProperty.call(message, "uuid_output_configuration"))
                        writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.uuid_output_configuration);
                    if (message.dpi_x != null && Object.hasOwnProperty.call(message, "dpi_x"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.dpi_x);
                    if (message.dpi_y != null && Object.hasOwnProperty.call(message, "dpi_y"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.dpi_y);
                    if (message.profiling_settings != null && Object.hasOwnProperty.call(message, "profiling_settings"))
                        $root.printcloud.digital.headless.ProfilingSettings.encode(message.profiling_settings, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.spar_settings != null && message.spar_settings.length)
                        for (let i = 0; i < message.spar_settings.length; ++i)
                            $root.printcloud.digital.headless.SparSettings.encode(message.spar_settings[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified RasterSettings message, length delimited. Does not implicitly {@link printcloud.digital.headless.RasterSettings.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof printcloud.digital.headless.RasterSettings
                 * @static
                 * @param {printcloud.digital.headless.IRasterSettings} message RasterSettings message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RasterSettings.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a RasterSettings message from the specified reader or buffer.
                 * @function decode
                 * @memberof printcloud.digital.headless.RasterSettings
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {printcloud.digital.headless.RasterSettings} RasterSettings
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RasterSettings.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.printcloud.digital.headless.RasterSettings();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.uuid_output_configuration = reader.bytes();
                            break;
                        case 2:
                            message.dpi_x = reader.int32();
                            break;
                        case 3:
                            message.dpi_y = reader.int32();
                            break;
                        case 4:
                            message.profiling_settings = $root.printcloud.digital.headless.ProfilingSettings.decode(reader, reader.uint32());
                            break;
                        case 5:
                            if (!(message.spar_settings && message.spar_settings.length))
                                message.spar_settings = [];
                            message.spar_settings.push($root.printcloud.digital.headless.SparSettings.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a RasterSettings message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof printcloud.digital.headless.RasterSettings
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {printcloud.digital.headless.RasterSettings} RasterSettings
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RasterSettings.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a RasterSettings message.
                 * @function verify
                 * @memberof printcloud.digital.headless.RasterSettings
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RasterSettings.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.uuid_output_configuration != null && message.hasOwnProperty("uuid_output_configuration"))
                        if (!(message.uuid_output_configuration && typeof message.uuid_output_configuration.length === "number" || $util.isString(message.uuid_output_configuration)))
                            return "uuid_output_configuration: buffer expected";
                    if (message.dpi_x != null && message.hasOwnProperty("dpi_x"))
                        if (!$util.isInteger(message.dpi_x))
                            return "dpi_x: integer expected";
                    if (message.dpi_y != null && message.hasOwnProperty("dpi_y"))
                        if (!$util.isInteger(message.dpi_y))
                            return "dpi_y: integer expected";
                    if (message.profiling_settings != null && message.hasOwnProperty("profiling_settings")) {
                        let error = $root.printcloud.digital.headless.ProfilingSettings.verify(message.profiling_settings);
                        if (error)
                            return "profiling_settings." + error;
                    }
                    if (message.spar_settings != null && message.hasOwnProperty("spar_settings")) {
                        if (!Array.isArray(message.spar_settings))
                            return "spar_settings: array expected";
                        for (let i = 0; i < message.spar_settings.length; ++i) {
                            let error = $root.printcloud.digital.headless.SparSettings.verify(message.spar_settings[i]);
                            if (error)
                                return "spar_settings." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a RasterSettings message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof printcloud.digital.headless.RasterSettings
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {printcloud.digital.headless.RasterSettings} RasterSettings
                 */
                RasterSettings.fromObject = function fromObject(object) {
                    if (object instanceof $root.printcloud.digital.headless.RasterSettings)
                        return object;
                    let message = new $root.printcloud.digital.headless.RasterSettings();
                    if (object.uuid_output_configuration != null)
                        if (typeof object.uuid_output_configuration === "string")
                            $util.base64.decode(object.uuid_output_configuration, message.uuid_output_configuration = $util.newBuffer($util.base64.length(object.uuid_output_configuration)), 0);
                        else if (object.uuid_output_configuration.length)
                            message.uuid_output_configuration = object.uuid_output_configuration;
                    if (object.dpi_x != null)
                        message.dpi_x = object.dpi_x | 0;
                    if (object.dpi_y != null)
                        message.dpi_y = object.dpi_y | 0;
                    if (object.profiling_settings != null) {
                        if (typeof object.profiling_settings !== "object")
                            throw TypeError(".printcloud.digital.headless.RasterSettings.profiling_settings: object expected");
                        message.profiling_settings = $root.printcloud.digital.headless.ProfilingSettings.fromObject(object.profiling_settings);
                    }
                    if (object.spar_settings) {
                        if (!Array.isArray(object.spar_settings))
                            throw TypeError(".printcloud.digital.headless.RasterSettings.spar_settings: array expected");
                        message.spar_settings = [];
                        for (let i = 0; i < object.spar_settings.length; ++i) {
                            if (typeof object.spar_settings[i] !== "object")
                                throw TypeError(".printcloud.digital.headless.RasterSettings.spar_settings: object expected");
                            message.spar_settings[i] = $root.printcloud.digital.headless.SparSettings.fromObject(object.spar_settings[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a RasterSettings message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof printcloud.digital.headless.RasterSettings
                 * @static
                 * @param {printcloud.digital.headless.RasterSettings} message RasterSettings
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RasterSettings.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.spar_settings = [];
                    if (options.defaults) {
                        if (options.bytes === String)
                            object.uuid_output_configuration = "";
                        else {
                            object.uuid_output_configuration = [];
                            if (options.bytes !== Array)
                                object.uuid_output_configuration = $util.newBuffer(object.uuid_output_configuration);
                        }
                        object.dpi_x = 0;
                        object.dpi_y = 0;
                        object.profiling_settings = null;
                    }
                    if (message.uuid_output_configuration != null && message.hasOwnProperty("uuid_output_configuration"))
                        object.uuid_output_configuration = options.bytes === String ? $util.base64.encode(message.uuid_output_configuration, 0, message.uuid_output_configuration.length) : options.bytes === Array ? Array.prototype.slice.call(message.uuid_output_configuration) : message.uuid_output_configuration;
                    if (message.dpi_x != null && message.hasOwnProperty("dpi_x"))
                        object.dpi_x = message.dpi_x;
                    if (message.dpi_y != null && message.hasOwnProperty("dpi_y"))
                        object.dpi_y = message.dpi_y;
                    if (message.profiling_settings != null && message.hasOwnProperty("profiling_settings"))
                        object.profiling_settings = $root.printcloud.digital.headless.ProfilingSettings.toObject(message.profiling_settings, options);
                    if (message.spar_settings && message.spar_settings.length) {
                        object.spar_settings = [];
                        for (let j = 0; j < message.spar_settings.length; ++j)
                            object.spar_settings[j] = $root.printcloud.digital.headless.SparSettings.toObject(message.spar_settings[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this RasterSettings to JSON.
                 * @function toJSON
                 * @memberof printcloud.digital.headless.RasterSettings
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RasterSettings.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return RasterSettings;
            })();

            headless.PageDescriptor = (function() {

                /**
                 * Properties of a PageDescriptor.
                 * @memberof printcloud.digital.headless
                 * @interface IPageDescriptor
                 * @property {string|null} [uri_pdf] PageDescriptor uri_pdf
                 * @property {number|null} [page_in_pdf] PageDescriptor page_in_pdf
                 * @property {printcloud.digital.headless.IRasterSettings|null} [raster_settings_override] PageDescriptor raster_settings_override
                 */

                /**
                 * Constructs a new PageDescriptor.
                 * @memberof printcloud.digital.headless
                 * @classdesc Represents a PageDescriptor.
                 * @implements IPageDescriptor
                 * @constructor
                 * @param {printcloud.digital.headless.IPageDescriptor=} [properties] Properties to set
                 */
                function PageDescriptor(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PageDescriptor uri_pdf.
                 * @member {string} uri_pdf
                 * @memberof printcloud.digital.headless.PageDescriptor
                 * @instance
                 */
                PageDescriptor.prototype.uri_pdf = "";

                /**
                 * PageDescriptor page_in_pdf.
                 * @member {number} page_in_pdf
                 * @memberof printcloud.digital.headless.PageDescriptor
                 * @instance
                 */
                PageDescriptor.prototype.page_in_pdf = 0;

                /**
                 * PageDescriptor raster_settings_override.
                 * @member {printcloud.digital.headless.IRasterSettings|null|undefined} raster_settings_override
                 * @memberof printcloud.digital.headless.PageDescriptor
                 * @instance
                 */
                PageDescriptor.prototype.raster_settings_override = null;

                /**
                 * Creates a new PageDescriptor instance using the specified properties.
                 * @function create
                 * @memberof printcloud.digital.headless.PageDescriptor
                 * @static
                 * @param {printcloud.digital.headless.IPageDescriptor=} [properties] Properties to set
                 * @returns {printcloud.digital.headless.PageDescriptor} PageDescriptor instance
                 */
                PageDescriptor.create = function create(properties) {
                    return new PageDescriptor(properties);
                };

                /**
                 * Encodes the specified PageDescriptor message. Does not implicitly {@link printcloud.digital.headless.PageDescriptor.verify|verify} messages.
                 * @function encode
                 * @memberof printcloud.digital.headless.PageDescriptor
                 * @static
                 * @param {printcloud.digital.headless.IPageDescriptor} message PageDescriptor message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PageDescriptor.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.uri_pdf != null && Object.hasOwnProperty.call(message, "uri_pdf"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.uri_pdf);
                    if (message.page_in_pdf != null && Object.hasOwnProperty.call(message, "page_in_pdf"))
                        writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.page_in_pdf);
                    if (message.raster_settings_override != null && Object.hasOwnProperty.call(message, "raster_settings_override"))
                        $root.printcloud.digital.headless.RasterSettings.encode(message.raster_settings_override, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified PageDescriptor message, length delimited. Does not implicitly {@link printcloud.digital.headless.PageDescriptor.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof printcloud.digital.headless.PageDescriptor
                 * @static
                 * @param {printcloud.digital.headless.IPageDescriptor} message PageDescriptor message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PageDescriptor.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a PageDescriptor message from the specified reader or buffer.
                 * @function decode
                 * @memberof printcloud.digital.headless.PageDescriptor
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {printcloud.digital.headless.PageDescriptor} PageDescriptor
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PageDescriptor.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.printcloud.digital.headless.PageDescriptor();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.uri_pdf = reader.string();
                            break;
                        case 2:
                            message.page_in_pdf = reader.uint32();
                            break;
                        case 3:
                            message.raster_settings_override = $root.printcloud.digital.headless.RasterSettings.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a PageDescriptor message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof printcloud.digital.headless.PageDescriptor
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {printcloud.digital.headless.PageDescriptor} PageDescriptor
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PageDescriptor.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a PageDescriptor message.
                 * @function verify
                 * @memberof printcloud.digital.headless.PageDescriptor
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PageDescriptor.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.uri_pdf != null && message.hasOwnProperty("uri_pdf"))
                        if (!$util.isString(message.uri_pdf))
                            return "uri_pdf: string expected";
                    if (message.page_in_pdf != null && message.hasOwnProperty("page_in_pdf"))
                        if (!$util.isInteger(message.page_in_pdf))
                            return "page_in_pdf: integer expected";
                    if (message.raster_settings_override != null && message.hasOwnProperty("raster_settings_override")) {
                        let error = $root.printcloud.digital.headless.RasterSettings.verify(message.raster_settings_override);
                        if (error)
                            return "raster_settings_override." + error;
                    }
                    return null;
                };

                /**
                 * Creates a PageDescriptor message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof printcloud.digital.headless.PageDescriptor
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {printcloud.digital.headless.PageDescriptor} PageDescriptor
                 */
                PageDescriptor.fromObject = function fromObject(object) {
                    if (object instanceof $root.printcloud.digital.headless.PageDescriptor)
                        return object;
                    let message = new $root.printcloud.digital.headless.PageDescriptor();
                    if (object.uri_pdf != null)
                        message.uri_pdf = String(object.uri_pdf);
                    if (object.page_in_pdf != null)
                        message.page_in_pdf = object.page_in_pdf >>> 0;
                    if (object.raster_settings_override != null) {
                        if (typeof object.raster_settings_override !== "object")
                            throw TypeError(".printcloud.digital.headless.PageDescriptor.raster_settings_override: object expected");
                        message.raster_settings_override = $root.printcloud.digital.headless.RasterSettings.fromObject(object.raster_settings_override);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a PageDescriptor message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof printcloud.digital.headless.PageDescriptor
                 * @static
                 * @param {printcloud.digital.headless.PageDescriptor} message PageDescriptor
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PageDescriptor.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.uri_pdf = "";
                        object.page_in_pdf = 0;
                        object.raster_settings_override = null;
                    }
                    if (message.uri_pdf != null && message.hasOwnProperty("uri_pdf"))
                        object.uri_pdf = message.uri_pdf;
                    if (message.page_in_pdf != null && message.hasOwnProperty("page_in_pdf"))
                        object.page_in_pdf = message.page_in_pdf;
                    if (message.raster_settings_override != null && message.hasOwnProperty("raster_settings_override"))
                        object.raster_settings_override = $root.printcloud.digital.headless.RasterSettings.toObject(message.raster_settings_override, options);
                    return object;
                };

                /**
                 * Converts this PageDescriptor to JSON.
                 * @function toJSON
                 * @memberof printcloud.digital.headless.PageDescriptor
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PageDescriptor.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return PageDescriptor;
            })();

            headless.JobDescriptor = (function() {

                /**
                 * Properties of a JobDescriptor.
                 * @memberof printcloud.digital.headless
                 * @interface IJobDescriptor
                 * @property {string|null} [id_job] JobDescriptor id_job
                 * @property {Array.<printcloud.digital.headless.IPageDescriptor>|null} [page_descriptor] JobDescriptor page_descriptor
                 * @property {Array.<printcloud.digital.headless.IPageDescriptor>|null} [page_special_descriptor] JobDescriptor page_special_descriptor
                 * @property {printcloud.digital.headless.IRasterSettings|null} [raster_settings] JobDescriptor raster_settings
                 */

                /**
                 * Constructs a new JobDescriptor.
                 * @memberof printcloud.digital.headless
                 * @classdesc Represents a JobDescriptor.
                 * @implements IJobDescriptor
                 * @constructor
                 * @param {printcloud.digital.headless.IJobDescriptor=} [properties] Properties to set
                 */
                function JobDescriptor(properties) {
                    this.page_descriptor = [];
                    this.page_special_descriptor = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * JobDescriptor id_job.
                 * @member {string} id_job
                 * @memberof printcloud.digital.headless.JobDescriptor
                 * @instance
                 */
                JobDescriptor.prototype.id_job = "";

                /**
                 * JobDescriptor page_descriptor.
                 * @member {Array.<printcloud.digital.headless.IPageDescriptor>} page_descriptor
                 * @memberof printcloud.digital.headless.JobDescriptor
                 * @instance
                 */
                JobDescriptor.prototype.page_descriptor = $util.emptyArray;

                /**
                 * JobDescriptor page_special_descriptor.
                 * @member {Array.<printcloud.digital.headless.IPageDescriptor>} page_special_descriptor
                 * @memberof printcloud.digital.headless.JobDescriptor
                 * @instance
                 */
                JobDescriptor.prototype.page_special_descriptor = $util.emptyArray;

                /**
                 * JobDescriptor raster_settings.
                 * @member {printcloud.digital.headless.IRasterSettings|null|undefined} raster_settings
                 * @memberof printcloud.digital.headless.JobDescriptor
                 * @instance
                 */
                JobDescriptor.prototype.raster_settings = null;

                /**
                 * Creates a new JobDescriptor instance using the specified properties.
                 * @function create
                 * @memberof printcloud.digital.headless.JobDescriptor
                 * @static
                 * @param {printcloud.digital.headless.IJobDescriptor=} [properties] Properties to set
                 * @returns {printcloud.digital.headless.JobDescriptor} JobDescriptor instance
                 */
                JobDescriptor.create = function create(properties) {
                    return new JobDescriptor(properties);
                };

                /**
                 * Encodes the specified JobDescriptor message. Does not implicitly {@link printcloud.digital.headless.JobDescriptor.verify|verify} messages.
                 * @function encode
                 * @memberof printcloud.digital.headless.JobDescriptor
                 * @static
                 * @param {printcloud.digital.headless.IJobDescriptor} message JobDescriptor message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                JobDescriptor.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id_job != null && Object.hasOwnProperty.call(message, "id_job"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.id_job);
                    if (message.page_descriptor != null && message.page_descriptor.length)
                        for (let i = 0; i < message.page_descriptor.length; ++i)
                            $root.printcloud.digital.headless.PageDescriptor.encode(message.page_descriptor[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.page_special_descriptor != null && message.page_special_descriptor.length)
                        for (let i = 0; i < message.page_special_descriptor.length; ++i)
                            $root.printcloud.digital.headless.PageDescriptor.encode(message.page_special_descriptor[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.raster_settings != null && Object.hasOwnProperty.call(message, "raster_settings"))
                        $root.printcloud.digital.headless.RasterSettings.encode(message.raster_settings, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified JobDescriptor message, length delimited. Does not implicitly {@link printcloud.digital.headless.JobDescriptor.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof printcloud.digital.headless.JobDescriptor
                 * @static
                 * @param {printcloud.digital.headless.IJobDescriptor} message JobDescriptor message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                JobDescriptor.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a JobDescriptor message from the specified reader or buffer.
                 * @function decode
                 * @memberof printcloud.digital.headless.JobDescriptor
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {printcloud.digital.headless.JobDescriptor} JobDescriptor
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                JobDescriptor.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.printcloud.digital.headless.JobDescriptor();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.id_job = reader.string();
                            break;
                        case 3:
                            if (!(message.page_descriptor && message.page_descriptor.length))
                                message.page_descriptor = [];
                            message.page_descriptor.push($root.printcloud.digital.headless.PageDescriptor.decode(reader, reader.uint32()));
                            break;
                        case 4:
                            if (!(message.page_special_descriptor && message.page_special_descriptor.length))
                                message.page_special_descriptor = [];
                            message.page_special_descriptor.push($root.printcloud.digital.headless.PageDescriptor.decode(reader, reader.uint32()));
                            break;
                        case 5:
                            message.raster_settings = $root.printcloud.digital.headless.RasterSettings.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a JobDescriptor message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof printcloud.digital.headless.JobDescriptor
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {printcloud.digital.headless.JobDescriptor} JobDescriptor
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                JobDescriptor.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a JobDescriptor message.
                 * @function verify
                 * @memberof printcloud.digital.headless.JobDescriptor
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                JobDescriptor.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id_job != null && message.hasOwnProperty("id_job"))
                        if (!$util.isString(message.id_job))
                            return "id_job: string expected";
                    if (message.page_descriptor != null && message.hasOwnProperty("page_descriptor")) {
                        if (!Array.isArray(message.page_descriptor))
                            return "page_descriptor: array expected";
                        for (let i = 0; i < message.page_descriptor.length; ++i) {
                            let error = $root.printcloud.digital.headless.PageDescriptor.verify(message.page_descriptor[i]);
                            if (error)
                                return "page_descriptor." + error;
                        }
                    }
                    if (message.page_special_descriptor != null && message.hasOwnProperty("page_special_descriptor")) {
                        if (!Array.isArray(message.page_special_descriptor))
                            return "page_special_descriptor: array expected";
                        for (let i = 0; i < message.page_special_descriptor.length; ++i) {
                            let error = $root.printcloud.digital.headless.PageDescriptor.verify(message.page_special_descriptor[i]);
                            if (error)
                                return "page_special_descriptor." + error;
                        }
                    }
                    if (message.raster_settings != null && message.hasOwnProperty("raster_settings")) {
                        let error = $root.printcloud.digital.headless.RasterSettings.verify(message.raster_settings);
                        if (error)
                            return "raster_settings." + error;
                    }
                    return null;
                };

                /**
                 * Creates a JobDescriptor message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof printcloud.digital.headless.JobDescriptor
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {printcloud.digital.headless.JobDescriptor} JobDescriptor
                 */
                JobDescriptor.fromObject = function fromObject(object) {
                    if (object instanceof $root.printcloud.digital.headless.JobDescriptor)
                        return object;
                    let message = new $root.printcloud.digital.headless.JobDescriptor();
                    if (object.id_job != null)
                        message.id_job = String(object.id_job);
                    if (object.page_descriptor) {
                        if (!Array.isArray(object.page_descriptor))
                            throw TypeError(".printcloud.digital.headless.JobDescriptor.page_descriptor: array expected");
                        message.page_descriptor = [];
                        for (let i = 0; i < object.page_descriptor.length; ++i) {
                            if (typeof object.page_descriptor[i] !== "object")
                                throw TypeError(".printcloud.digital.headless.JobDescriptor.page_descriptor: object expected");
                            message.page_descriptor[i] = $root.printcloud.digital.headless.PageDescriptor.fromObject(object.page_descriptor[i]);
                        }
                    }
                    if (object.page_special_descriptor) {
                        if (!Array.isArray(object.page_special_descriptor))
                            throw TypeError(".printcloud.digital.headless.JobDescriptor.page_special_descriptor: array expected");
                        message.page_special_descriptor = [];
                        for (let i = 0; i < object.page_special_descriptor.length; ++i) {
                            if (typeof object.page_special_descriptor[i] !== "object")
                                throw TypeError(".printcloud.digital.headless.JobDescriptor.page_special_descriptor: object expected");
                            message.page_special_descriptor[i] = $root.printcloud.digital.headless.PageDescriptor.fromObject(object.page_special_descriptor[i]);
                        }
                    }
                    if (object.raster_settings != null) {
                        if (typeof object.raster_settings !== "object")
                            throw TypeError(".printcloud.digital.headless.JobDescriptor.raster_settings: object expected");
                        message.raster_settings = $root.printcloud.digital.headless.RasterSettings.fromObject(object.raster_settings);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a JobDescriptor message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof printcloud.digital.headless.JobDescriptor
                 * @static
                 * @param {printcloud.digital.headless.JobDescriptor} message JobDescriptor
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                JobDescriptor.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.page_descriptor = [];
                        object.page_special_descriptor = [];
                    }
                    if (options.defaults) {
                        object.id_job = "";
                        object.raster_settings = null;
                    }
                    if (message.id_job != null && message.hasOwnProperty("id_job"))
                        object.id_job = message.id_job;
                    if (message.page_descriptor && message.page_descriptor.length) {
                        object.page_descriptor = [];
                        for (let j = 0; j < message.page_descriptor.length; ++j)
                            object.page_descriptor[j] = $root.printcloud.digital.headless.PageDescriptor.toObject(message.page_descriptor[j], options);
                    }
                    if (message.page_special_descriptor && message.page_special_descriptor.length) {
                        object.page_special_descriptor = [];
                        for (let j = 0; j < message.page_special_descriptor.length; ++j)
                            object.page_special_descriptor[j] = $root.printcloud.digital.headless.PageDescriptor.toObject(message.page_special_descriptor[j], options);
                    }
                    if (message.raster_settings != null && message.hasOwnProperty("raster_settings"))
                        object.raster_settings = $root.printcloud.digital.headless.RasterSettings.toObject(message.raster_settings, options);
                    return object;
                };

                /**
                 * Converts this JobDescriptor to JSON.
                 * @function toJSON
                 * @memberof printcloud.digital.headless.JobDescriptor
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                JobDescriptor.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return JobDescriptor;
            })();

            headless.PageStatus = (function() {

                /**
                 * Properties of a PageStatus.
                 * @memberof printcloud.digital.headless
                 * @interface IPageStatus
                 * @property {printcloud.digital.headless.PageStatus.EnumPageStatus|null} [page_status] PageStatus page_status
                 * @property {number|null} [error_code] PageStatus error_code
                 * @property {Array.<string>|null} [page_log] PageStatus page_log
                 * @property {number|null} [width_pixel] PageStatus width_pixel
                 * @property {number|null} [height_pixel] PageStatus height_pixel
                 */

                /**
                 * Constructs a new PageStatus.
                 * @memberof printcloud.digital.headless
                 * @classdesc Represents a PageStatus.
                 * @implements IPageStatus
                 * @constructor
                 * @param {printcloud.digital.headless.IPageStatus=} [properties] Properties to set
                 */
                function PageStatus(properties) {
                    this.page_log = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PageStatus page_status.
                 * @member {printcloud.digital.headless.PageStatus.EnumPageStatus} page_status
                 * @memberof printcloud.digital.headless.PageStatus
                 * @instance
                 */
                PageStatus.prototype.page_status = 0;

                /**
                 * PageStatus error_code.
                 * @member {number} error_code
                 * @memberof printcloud.digital.headless.PageStatus
                 * @instance
                 */
                PageStatus.prototype.error_code = 0;

                /**
                 * PageStatus page_log.
                 * @member {Array.<string>} page_log
                 * @memberof printcloud.digital.headless.PageStatus
                 * @instance
                 */
                PageStatus.prototype.page_log = $util.emptyArray;

                /**
                 * PageStatus width_pixel.
                 * @member {number} width_pixel
                 * @memberof printcloud.digital.headless.PageStatus
                 * @instance
                 */
                PageStatus.prototype.width_pixel = 0;

                /**
                 * PageStatus height_pixel.
                 * @member {number} height_pixel
                 * @memberof printcloud.digital.headless.PageStatus
                 * @instance
                 */
                PageStatus.prototype.height_pixel = 0;

                /**
                 * Creates a new PageStatus instance using the specified properties.
                 * @function create
                 * @memberof printcloud.digital.headless.PageStatus
                 * @static
                 * @param {printcloud.digital.headless.IPageStatus=} [properties] Properties to set
                 * @returns {printcloud.digital.headless.PageStatus} PageStatus instance
                 */
                PageStatus.create = function create(properties) {
                    return new PageStatus(properties);
                };

                /**
                 * Encodes the specified PageStatus message. Does not implicitly {@link printcloud.digital.headless.PageStatus.verify|verify} messages.
                 * @function encode
                 * @memberof printcloud.digital.headless.PageStatus
                 * @static
                 * @param {printcloud.digital.headless.IPageStatus} message PageStatus message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PageStatus.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.page_status != null && Object.hasOwnProperty.call(message, "page_status"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page_status);
                    if (message.error_code != null && Object.hasOwnProperty.call(message, "error_code"))
                        writer.uint32(/* id 2, wireType 0 =*/16).sint32(message.error_code);
                    if (message.page_log != null && message.page_log.length)
                        for (let i = 0; i < message.page_log.length; ++i)
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.page_log[i]);
                    if (message.width_pixel != null && Object.hasOwnProperty.call(message, "width_pixel"))
                        writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.width_pixel);
                    if (message.height_pixel != null && Object.hasOwnProperty.call(message, "height_pixel"))
                        writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.height_pixel);
                    return writer;
                };

                /**
                 * Encodes the specified PageStatus message, length delimited. Does not implicitly {@link printcloud.digital.headless.PageStatus.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof printcloud.digital.headless.PageStatus
                 * @static
                 * @param {printcloud.digital.headless.IPageStatus} message PageStatus message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PageStatus.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a PageStatus message from the specified reader or buffer.
                 * @function decode
                 * @memberof printcloud.digital.headless.PageStatus
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {printcloud.digital.headless.PageStatus} PageStatus
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PageStatus.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.printcloud.digital.headless.PageStatus();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.page_status = reader.int32();
                            break;
                        case 2:
                            message.error_code = reader.sint32();
                            break;
                        case 3:
                            if (!(message.page_log && message.page_log.length))
                                message.page_log = [];
                            message.page_log.push(reader.string());
                            break;
                        case 4:
                            message.width_pixel = reader.uint32();
                            break;
                        case 5:
                            message.height_pixel = reader.uint32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a PageStatus message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof printcloud.digital.headless.PageStatus
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {printcloud.digital.headless.PageStatus} PageStatus
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PageStatus.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a PageStatus message.
                 * @function verify
                 * @memberof printcloud.digital.headless.PageStatus
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PageStatus.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.page_status != null && message.hasOwnProperty("page_status"))
                        switch (message.page_status) {
                        default:
                            return "page_status: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                            break;
                        }
                    if (message.error_code != null && message.hasOwnProperty("error_code"))
                        if (!$util.isInteger(message.error_code))
                            return "error_code: integer expected";
                    if (message.page_log != null && message.hasOwnProperty("page_log")) {
                        if (!Array.isArray(message.page_log))
                            return "page_log: array expected";
                        for (let i = 0; i < message.page_log.length; ++i)
                            if (!$util.isString(message.page_log[i]))
                                return "page_log: string[] expected";
                    }
                    if (message.width_pixel != null && message.hasOwnProperty("width_pixel"))
                        if (!$util.isInteger(message.width_pixel))
                            return "width_pixel: integer expected";
                    if (message.height_pixel != null && message.hasOwnProperty("height_pixel"))
                        if (!$util.isInteger(message.height_pixel))
                            return "height_pixel: integer expected";
                    return null;
                };

                /**
                 * Creates a PageStatus message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof printcloud.digital.headless.PageStatus
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {printcloud.digital.headless.PageStatus} PageStatus
                 */
                PageStatus.fromObject = function fromObject(object) {
                    if (object instanceof $root.printcloud.digital.headless.PageStatus)
                        return object;
                    let message = new $root.printcloud.digital.headless.PageStatus();
                    switch (object.page_status) {
                    case "UNKNOWN":
                    case 0:
                        message.page_status = 0;
                        break;
                    case "ERROR":
                    case 1:
                        message.page_status = 1;
                        break;
                    case "RASTERIZATION_STARTED":
                    case 2:
                        message.page_status = 2;
                        break;
                    case "RASTERIZATION_FINISHED":
                    case 3:
                        message.page_status = 3;
                        break;
                    case "SCREENING_STARTED":
                    case 4:
                        message.page_status = 4;
                        break;
                    case "SCREENING_FINISHED":
                    case 5:
                        message.page_status = 5;
                        break;
                    case "READY_TO_SEND":
                    case 6:
                        message.page_status = 6;
                        break;
                    case "SEND_STARTED":
                    case 7:
                        message.page_status = 7;
                        break;
                    case "SENT_TO_PRINTER":
                    case 8:
                        message.page_status = 8;
                        break;
                    }
                    if (object.error_code != null)
                        message.error_code = object.error_code | 0;
                    if (object.page_log) {
                        if (!Array.isArray(object.page_log))
                            throw TypeError(".printcloud.digital.headless.PageStatus.page_log: array expected");
                        message.page_log = [];
                        for (let i = 0; i < object.page_log.length; ++i)
                            message.page_log[i] = String(object.page_log[i]);
                    }
                    if (object.width_pixel != null)
                        message.width_pixel = object.width_pixel >>> 0;
                    if (object.height_pixel != null)
                        message.height_pixel = object.height_pixel >>> 0;
                    return message;
                };

                /**
                 * Creates a plain object from a PageStatus message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof printcloud.digital.headless.PageStatus
                 * @static
                 * @param {printcloud.digital.headless.PageStatus} message PageStatus
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PageStatus.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.page_log = [];
                    if (options.defaults) {
                        object.page_status = options.enums === String ? "UNKNOWN" : 0;
                        object.error_code = 0;
                        object.width_pixel = 0;
                        object.height_pixel = 0;
                    }
                    if (message.page_status != null && message.hasOwnProperty("page_status"))
                        object.page_status = options.enums === String ? $root.printcloud.digital.headless.PageStatus.EnumPageStatus[message.page_status] : message.page_status;
                    if (message.error_code != null && message.hasOwnProperty("error_code"))
                        object.error_code = message.error_code;
                    if (message.page_log && message.page_log.length) {
                        object.page_log = [];
                        for (let j = 0; j < message.page_log.length; ++j)
                            object.page_log[j] = message.page_log[j];
                    }
                    if (message.width_pixel != null && message.hasOwnProperty("width_pixel"))
                        object.width_pixel = message.width_pixel;
                    if (message.height_pixel != null && message.hasOwnProperty("height_pixel"))
                        object.height_pixel = message.height_pixel;
                    return object;
                };

                /**
                 * Converts this PageStatus to JSON.
                 * @function toJSON
                 * @memberof printcloud.digital.headless.PageStatus
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PageStatus.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * EnumPageStatus enum.
                 * @name printcloud.digital.headless.PageStatus.EnumPageStatus
                 * @enum {number}
                 * @property {number} UNKNOWN=0 UNKNOWN value
                 * @property {number} ERROR=1 ERROR value
                 * @property {number} RASTERIZATION_STARTED=2 RASTERIZATION_STARTED value
                 * @property {number} RASTERIZATION_FINISHED=3 RASTERIZATION_FINISHED value
                 * @property {number} SCREENING_STARTED=4 SCREENING_STARTED value
                 * @property {number} SCREENING_FINISHED=5 SCREENING_FINISHED value
                 * @property {number} READY_TO_SEND=6 READY_TO_SEND value
                 * @property {number} SEND_STARTED=7 SEND_STARTED value
                 * @property {number} SENT_TO_PRINTER=8 SENT_TO_PRINTER value
                 */
                PageStatus.EnumPageStatus = (function() {
                    const valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UNKNOWN"] = 0;
                    values[valuesById[1] = "ERROR"] = 1;
                    values[valuesById[2] = "RASTERIZATION_STARTED"] = 2;
                    values[valuesById[3] = "RASTERIZATION_FINISHED"] = 3;
                    values[valuesById[4] = "SCREENING_STARTED"] = 4;
                    values[valuesById[5] = "SCREENING_FINISHED"] = 5;
                    values[valuesById[6] = "READY_TO_SEND"] = 6;
                    values[valuesById[7] = "SEND_STARTED"] = 7;
                    values[valuesById[8] = "SENT_TO_PRINTER"] = 8;
                    return values;
                })();

                return PageStatus;
            })();

            headless.PageInfo = (function() {

                /**
                 * Properties of a PageInfo.
                 * @memberof printcloud.digital.headless
                 * @interface IPageInfo
                 * @property {number|null} [id_page] PageInfo id_page
                 * @property {printcloud.digital.headless.IPageStatus|null} [page_status] PageInfo page_status
                 */

                /**
                 * Constructs a new PageInfo.
                 * @memberof printcloud.digital.headless
                 * @classdesc Represents a PageInfo.
                 * @implements IPageInfo
                 * @constructor
                 * @param {printcloud.digital.headless.IPageInfo=} [properties] Properties to set
                 */
                function PageInfo(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PageInfo id_page.
                 * @member {number} id_page
                 * @memberof printcloud.digital.headless.PageInfo
                 * @instance
                 */
                PageInfo.prototype.id_page = 0;

                /**
                 * PageInfo page_status.
                 * @member {printcloud.digital.headless.IPageStatus|null|undefined} page_status
                 * @memberof printcloud.digital.headless.PageInfo
                 * @instance
                 */
                PageInfo.prototype.page_status = null;

                /**
                 * Creates a new PageInfo instance using the specified properties.
                 * @function create
                 * @memberof printcloud.digital.headless.PageInfo
                 * @static
                 * @param {printcloud.digital.headless.IPageInfo=} [properties] Properties to set
                 * @returns {printcloud.digital.headless.PageInfo} PageInfo instance
                 */
                PageInfo.create = function create(properties) {
                    return new PageInfo(properties);
                };

                /**
                 * Encodes the specified PageInfo message. Does not implicitly {@link printcloud.digital.headless.PageInfo.verify|verify} messages.
                 * @function encode
                 * @memberof printcloud.digital.headless.PageInfo
                 * @static
                 * @param {printcloud.digital.headless.IPageInfo} message PageInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PageInfo.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id_page != null && Object.hasOwnProperty.call(message, "id_page"))
                        writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id_page);
                    if (message.page_status != null && Object.hasOwnProperty.call(message, "page_status"))
                        $root.printcloud.digital.headless.PageStatus.encode(message.page_status, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified PageInfo message, length delimited. Does not implicitly {@link printcloud.digital.headless.PageInfo.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof printcloud.digital.headless.PageInfo
                 * @static
                 * @param {printcloud.digital.headless.IPageInfo} message PageInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PageInfo.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a PageInfo message from the specified reader or buffer.
                 * @function decode
                 * @memberof printcloud.digital.headless.PageInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {printcloud.digital.headless.PageInfo} PageInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PageInfo.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.printcloud.digital.headless.PageInfo();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.id_page = reader.uint32();
                            break;
                        case 2:
                            message.page_status = $root.printcloud.digital.headless.PageStatus.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a PageInfo message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof printcloud.digital.headless.PageInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {printcloud.digital.headless.PageInfo} PageInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PageInfo.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a PageInfo message.
                 * @function verify
                 * @memberof printcloud.digital.headless.PageInfo
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PageInfo.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id_page != null && message.hasOwnProperty("id_page"))
                        if (!$util.isInteger(message.id_page))
                            return "id_page: integer expected";
                    if (message.page_status != null && message.hasOwnProperty("page_status")) {
                        let error = $root.printcloud.digital.headless.PageStatus.verify(message.page_status);
                        if (error)
                            return "page_status." + error;
                    }
                    return null;
                };

                /**
                 * Creates a PageInfo message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof printcloud.digital.headless.PageInfo
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {printcloud.digital.headless.PageInfo} PageInfo
                 */
                PageInfo.fromObject = function fromObject(object) {
                    if (object instanceof $root.printcloud.digital.headless.PageInfo)
                        return object;
                    let message = new $root.printcloud.digital.headless.PageInfo();
                    if (object.id_page != null)
                        message.id_page = object.id_page >>> 0;
                    if (object.page_status != null) {
                        if (typeof object.page_status !== "object")
                            throw TypeError(".printcloud.digital.headless.PageInfo.page_status: object expected");
                        message.page_status = $root.printcloud.digital.headless.PageStatus.fromObject(object.page_status);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a PageInfo message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof printcloud.digital.headless.PageInfo
                 * @static
                 * @param {printcloud.digital.headless.PageInfo} message PageInfo
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PageInfo.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.id_page = 0;
                        object.page_status = null;
                    }
                    if (message.id_page != null && message.hasOwnProperty("id_page"))
                        object.id_page = message.id_page;
                    if (message.page_status != null && message.hasOwnProperty("page_status"))
                        object.page_status = $root.printcloud.digital.headless.PageStatus.toObject(message.page_status, options);
                    return object;
                };

                /**
                 * Converts this PageInfo to JSON.
                 * @function toJSON
                 * @memberof printcloud.digital.headless.PageInfo
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PageInfo.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return PageInfo;
            })();

            headless.JobStatus = (function() {

                /**
                 * Properties of a JobStatus.
                 * @memberof printcloud.digital.headless
                 * @interface IJobStatus
                 * @property {string|null} [id_job] JobStatus id_job
                 * @property {number|null} [error_code] JobStatus error_code
                 * @property {printcloud.digital.headless.JobStatus.EnumJobStatus|null} [job_status] JobStatus job_status
                 * @property {Array.<string>|null} [job_log] JobStatus job_log
                 * @property {Array.<printcloud.digital.headless.IPageStatus>|null} [page_status] JobStatus page_status
                 */

                /**
                 * Constructs a new JobStatus.
                 * @memberof printcloud.digital.headless
                 * @classdesc Represents a JobStatus.
                 * @implements IJobStatus
                 * @constructor
                 * @param {printcloud.digital.headless.IJobStatus=} [properties] Properties to set
                 */
                function JobStatus(properties) {
                    this.job_log = [];
                    this.page_status = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * JobStatus id_job.
                 * @member {string} id_job
                 * @memberof printcloud.digital.headless.JobStatus
                 * @instance
                 */
                JobStatus.prototype.id_job = "";

                /**
                 * JobStatus error_code.
                 * @member {number} error_code
                 * @memberof printcloud.digital.headless.JobStatus
                 * @instance
                 */
                JobStatus.prototype.error_code = 0;

                /**
                 * JobStatus job_status.
                 * @member {printcloud.digital.headless.JobStatus.EnumJobStatus} job_status
                 * @memberof printcloud.digital.headless.JobStatus
                 * @instance
                 */
                JobStatus.prototype.job_status = 0;

                /**
                 * JobStatus job_log.
                 * @member {Array.<string>} job_log
                 * @memberof printcloud.digital.headless.JobStatus
                 * @instance
                 */
                JobStatus.prototype.job_log = $util.emptyArray;

                /**
                 * JobStatus page_status.
                 * @member {Array.<printcloud.digital.headless.IPageStatus>} page_status
                 * @memberof printcloud.digital.headless.JobStatus
                 * @instance
                 */
                JobStatus.prototype.page_status = $util.emptyArray;

                /**
                 * Creates a new JobStatus instance using the specified properties.
                 * @function create
                 * @memberof printcloud.digital.headless.JobStatus
                 * @static
                 * @param {printcloud.digital.headless.IJobStatus=} [properties] Properties to set
                 * @returns {printcloud.digital.headless.JobStatus} JobStatus instance
                 */
                JobStatus.create = function create(properties) {
                    return new JobStatus(properties);
                };

                /**
                 * Encodes the specified JobStatus message. Does not implicitly {@link printcloud.digital.headless.JobStatus.verify|verify} messages.
                 * @function encode
                 * @memberof printcloud.digital.headless.JobStatus
                 * @static
                 * @param {printcloud.digital.headless.IJobStatus} message JobStatus message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                JobStatus.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id_job != null && Object.hasOwnProperty.call(message, "id_job"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.id_job);
                    if (message.error_code != null && Object.hasOwnProperty.call(message, "error_code"))
                        writer.uint32(/* id 2, wireType 0 =*/16).sint32(message.error_code);
                    if (message.job_status != null && Object.hasOwnProperty.call(message, "job_status"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.job_status);
                    if (message.job_log != null && message.job_log.length)
                        for (let i = 0; i < message.job_log.length; ++i)
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.job_log[i]);
                    if (message.page_status != null && message.page_status.length)
                        for (let i = 0; i < message.page_status.length; ++i)
                            $root.printcloud.digital.headless.PageStatus.encode(message.page_status[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified JobStatus message, length delimited. Does not implicitly {@link printcloud.digital.headless.JobStatus.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof printcloud.digital.headless.JobStatus
                 * @static
                 * @param {printcloud.digital.headless.IJobStatus} message JobStatus message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                JobStatus.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a JobStatus message from the specified reader or buffer.
                 * @function decode
                 * @memberof printcloud.digital.headless.JobStatus
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {printcloud.digital.headless.JobStatus} JobStatus
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                JobStatus.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.printcloud.digital.headless.JobStatus();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.id_job = reader.string();
                            break;
                        case 2:
                            message.error_code = reader.sint32();
                            break;
                        case 3:
                            message.job_status = reader.int32();
                            break;
                        case 4:
                            if (!(message.job_log && message.job_log.length))
                                message.job_log = [];
                            message.job_log.push(reader.string());
                            break;
                        case 5:
                            if (!(message.page_status && message.page_status.length))
                                message.page_status = [];
                            message.page_status.push($root.printcloud.digital.headless.PageStatus.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a JobStatus message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof printcloud.digital.headless.JobStatus
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {printcloud.digital.headless.JobStatus} JobStatus
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                JobStatus.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a JobStatus message.
                 * @function verify
                 * @memberof printcloud.digital.headless.JobStatus
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                JobStatus.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id_job != null && message.hasOwnProperty("id_job"))
                        if (!$util.isString(message.id_job))
                            return "id_job: string expected";
                    if (message.error_code != null && message.hasOwnProperty("error_code"))
                        if (!$util.isInteger(message.error_code))
                            return "error_code: integer expected";
                    if (message.job_status != null && message.hasOwnProperty("job_status"))
                        switch (message.job_status) {
                        default:
                            return "job_status: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 6:
                            break;
                        }
                    if (message.job_log != null && message.hasOwnProperty("job_log")) {
                        if (!Array.isArray(message.job_log))
                            return "job_log: array expected";
                        for (let i = 0; i < message.job_log.length; ++i)
                            if (!$util.isString(message.job_log[i]))
                                return "job_log: string[] expected";
                    }
                    if (message.page_status != null && message.hasOwnProperty("page_status")) {
                        if (!Array.isArray(message.page_status))
                            return "page_status: array expected";
                        for (let i = 0; i < message.page_status.length; ++i) {
                            let error = $root.printcloud.digital.headless.PageStatus.verify(message.page_status[i]);
                            if (error)
                                return "page_status." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a JobStatus message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof printcloud.digital.headless.JobStatus
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {printcloud.digital.headless.JobStatus} JobStatus
                 */
                JobStatus.fromObject = function fromObject(object) {
                    if (object instanceof $root.printcloud.digital.headless.JobStatus)
                        return object;
                    let message = new $root.printcloud.digital.headless.JobStatus();
                    if (object.id_job != null)
                        message.id_job = String(object.id_job);
                    if (object.error_code != null)
                        message.error_code = object.error_code | 0;
                    switch (object.job_status) {
                    case "UNKNOWN":
                    case 0:
                        message.job_status = 0;
                        break;
                    case "ERROR":
                    case 1:
                        message.job_status = 1;
                        break;
                    case "RECEIVED":
                    case 2:
                        message.job_status = 2;
                        break;
                    case "INITIALIZING":
                    case 3:
                        message.job_status = 3;
                        break;
                    case "PROCESSING":
                    case 4:
                        message.job_status = 4;
                        break;
                    case "FINISHED":
                    case 6:
                        message.job_status = 6;
                        break;
                    }
                    if (object.job_log) {
                        if (!Array.isArray(object.job_log))
                            throw TypeError(".printcloud.digital.headless.JobStatus.job_log: array expected");
                        message.job_log = [];
                        for (let i = 0; i < object.job_log.length; ++i)
                            message.job_log[i] = String(object.job_log[i]);
                    }
                    if (object.page_status) {
                        if (!Array.isArray(object.page_status))
                            throw TypeError(".printcloud.digital.headless.JobStatus.page_status: array expected");
                        message.page_status = [];
                        for (let i = 0; i < object.page_status.length; ++i) {
                            if (typeof object.page_status[i] !== "object")
                                throw TypeError(".printcloud.digital.headless.JobStatus.page_status: object expected");
                            message.page_status[i] = $root.printcloud.digital.headless.PageStatus.fromObject(object.page_status[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a JobStatus message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof printcloud.digital.headless.JobStatus
                 * @static
                 * @param {printcloud.digital.headless.JobStatus} message JobStatus
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                JobStatus.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.job_log = [];
                        object.page_status = [];
                    }
                    if (options.defaults) {
                        object.id_job = "";
                        object.error_code = 0;
                        object.job_status = options.enums === String ? "UNKNOWN" : 0;
                    }
                    if (message.id_job != null && message.hasOwnProperty("id_job"))
                        object.id_job = message.id_job;
                    if (message.error_code != null && message.hasOwnProperty("error_code"))
                        object.error_code = message.error_code;
                    if (message.job_status != null && message.hasOwnProperty("job_status"))
                        object.job_status = options.enums === String ? $root.printcloud.digital.headless.JobStatus.EnumJobStatus[message.job_status] : message.job_status;
                    if (message.job_log && message.job_log.length) {
                        object.job_log = [];
                        for (let j = 0; j < message.job_log.length; ++j)
                            object.job_log[j] = message.job_log[j];
                    }
                    if (message.page_status && message.page_status.length) {
                        object.page_status = [];
                        for (let j = 0; j < message.page_status.length; ++j)
                            object.page_status[j] = $root.printcloud.digital.headless.PageStatus.toObject(message.page_status[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this JobStatus to JSON.
                 * @function toJSON
                 * @memberof printcloud.digital.headless.JobStatus
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                JobStatus.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * EnumJobStatus enum.
                 * @name printcloud.digital.headless.JobStatus.EnumJobStatus
                 * @enum {number}
                 * @property {number} UNKNOWN=0 UNKNOWN value
                 * @property {number} ERROR=1 ERROR value
                 * @property {number} RECEIVED=2 RECEIVED value
                 * @property {number} INITIALIZING=3 INITIALIZING value
                 * @property {number} PROCESSING=4 PROCESSING value
                 * @property {number} FINISHED=6 FINISHED value
                 */
                JobStatus.EnumJobStatus = (function() {
                    const valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UNKNOWN"] = 0;
                    values[valuesById[1] = "ERROR"] = 1;
                    values[valuesById[2] = "RECEIVED"] = 2;
                    values[valuesById[3] = "INITIALIZING"] = 3;
                    values[valuesById[4] = "PROCESSING"] = 4;
                    values[valuesById[6] = "FINISHED"] = 6;
                    return values;
                })();

                return JobStatus;
            })();

            headless.Headless = (function() {

                /**
                 * Constructs a new Headless service.
                 * @memberof printcloud.digital.headless
                 * @classdesc Represents a Headless
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function Headless(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (Headless.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Headless;

                /**
                 * Creates new Headless service using the specified rpc implementation.
                 * @function create
                 * @memberof printcloud.digital.headless.Headless
                 * @static
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 * @returns {Headless} RPC service. Useful where requests and/or responses are streamed.
                 */
                Headless.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };

                /**
                 * Callback as used by {@link printcloud.digital.headless.Headless#queryPageStatus}.
                 * @memberof printcloud.digital.headless.Headless
                 * @typedef QueryPageStatusCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {printcloud.digital.headless.PageStatus} [response] PageStatus
                 */

                /**
                 * Calls QueryPageStatus.
                 * @function queryPageStatus
                 * @memberof printcloud.digital.headless.Headless
                 * @instance
                 * @param {printcloud.digital.headless.IRequestPageStatus} request RequestPageStatus message or plain object
                 * @param {printcloud.digital.headless.Headless.QueryPageStatusCallback} callback Node-style callback called with the error, if any, and PageStatus
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(Headless.prototype.queryPageStatus = function queryPageStatus(request, callback) {
                    return this.rpcCall(queryPageStatus, $root.printcloud.digital.headless.RequestPageStatus, $root.printcloud.digital.headless.PageStatus, request, callback);
                }, "name", { value: "QueryPageStatus" });

                /**
                 * Calls QueryPageStatus.
                 * @function queryPageStatus
                 * @memberof printcloud.digital.headless.Headless
                 * @instance
                 * @param {printcloud.digital.headless.IRequestPageStatus} request RequestPageStatus message or plain object
                 * @returns {Promise<printcloud.digital.headless.PageStatus>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link printcloud.digital.headless.Headless#queryJobStatus}.
                 * @memberof printcloud.digital.headless.Headless
                 * @typedef QueryJobStatusCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {printcloud.digital.headless.JobStatus} [response] JobStatus
                 */

                /**
                 * Calls QueryJobStatus.
                 * @function queryJobStatus
                 * @memberof printcloud.digital.headless.Headless
                 * @instance
                 * @param {printcloud.digital.headless.IRequestJobStatus} request RequestJobStatus message or plain object
                 * @param {printcloud.digital.headless.Headless.QueryJobStatusCallback} callback Node-style callback called with the error, if any, and JobStatus
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(Headless.prototype.queryJobStatus = function queryJobStatus(request, callback) {
                    return this.rpcCall(queryJobStatus, $root.printcloud.digital.headless.RequestJobStatus, $root.printcloud.digital.headless.JobStatus, request, callback);
                }, "name", { value: "QueryJobStatus" });

                /**
                 * Calls QueryJobStatus.
                 * @function queryJobStatus
                 * @memberof printcloud.digital.headless.Headless
                 * @instance
                 * @param {printcloud.digital.headless.IRequestJobStatus} request RequestJobStatus message or plain object
                 * @returns {Promise<printcloud.digital.headless.JobStatus>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link printcloud.digital.headless.Headless#sendPage}.
                 * @memberof printcloud.digital.headless.Headless
                 * @typedef SendPageCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {printcloud.digital.headless.ResponseSendPage} [response] ResponseSendPage
                 */

                /**
                 * Calls SendPage.
                 * @function sendPage
                 * @memberof printcloud.digital.headless.Headless
                 * @instance
                 * @param {printcloud.digital.headless.IRequestSendPage} request RequestSendPage message or plain object
                 * @param {printcloud.digital.headless.Headless.SendPageCallback} callback Node-style callback called with the error, if any, and ResponseSendPage
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(Headless.prototype.sendPage = function sendPage(request, callback) {
                    return this.rpcCall(sendPage, $root.printcloud.digital.headless.RequestSendPage, $root.printcloud.digital.headless.ResponseSendPage, request, callback);
                }, "name", { value: "SendPage" });

                /**
                 * Calls SendPage.
                 * @function sendPage
                 * @memberof printcloud.digital.headless.Headless
                 * @instance
                 * @param {printcloud.digital.headless.IRequestSendPage} request RequestSendPage message or plain object
                 * @returns {Promise<printcloud.digital.headless.ResponseSendPage>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link printcloud.digital.headless.Headless#sendJobDescription}.
                 * @memberof printcloud.digital.headless.Headless
                 * @typedef SendJobDescriptionCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {printcloud.digital.headless.ResponseSendJobDescription} [response] ResponseSendJobDescription
                 */

                /**
                 * Calls SendJobDescription.
                 * @function sendJobDescription
                 * @memberof printcloud.digital.headless.Headless
                 * @instance
                 * @param {printcloud.digital.headless.IRequestSendJobDescription} request RequestSendJobDescription message or plain object
                 * @param {printcloud.digital.headless.Headless.SendJobDescriptionCallback} callback Node-style callback called with the error, if any, and ResponseSendJobDescription
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(Headless.prototype.sendJobDescription = function sendJobDescription(request, callback) {
                    return this.rpcCall(sendJobDescription, $root.printcloud.digital.headless.RequestSendJobDescription, $root.printcloud.digital.headless.ResponseSendJobDescription, request, callback);
                }, "name", { value: "SendJobDescription" });

                /**
                 * Calls SendJobDescription.
                 * @function sendJobDescription
                 * @memberof printcloud.digital.headless.Headless
                 * @instance
                 * @param {printcloud.digital.headless.IRequestSendJobDescription} request RequestSendJobDescription message or plain object
                 * @returns {Promise<printcloud.digital.headless.ResponseSendJobDescription>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link printcloud.digital.headless.Headless#cancelJob}.
                 * @memberof printcloud.digital.headless.Headless
                 * @typedef CancelJobCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {printcloud.digital.headless.ResponseCancelJob} [response] ResponseCancelJob
                 */

                /**
                 * Calls CancelJob.
                 * @function cancelJob
                 * @memberof printcloud.digital.headless.Headless
                 * @instance
                 * @param {printcloud.digital.headless.IRequestCancelJob} request RequestCancelJob message or plain object
                 * @param {printcloud.digital.headless.Headless.CancelJobCallback} callback Node-style callback called with the error, if any, and ResponseCancelJob
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(Headless.prototype.cancelJob = function cancelJob(request, callback) {
                    return this.rpcCall(cancelJob, $root.printcloud.digital.headless.RequestCancelJob, $root.printcloud.digital.headless.ResponseCancelJob, request, callback);
                }, "name", { value: "CancelJob" });

                /**
                 * Calls CancelJob.
                 * @function cancelJob
                 * @memberof printcloud.digital.headless.Headless
                 * @instance
                 * @param {printcloud.digital.headless.IRequestCancelJob} request RequestCancelJob message or plain object
                 * @returns {Promise<printcloud.digital.headless.ResponseCancelJob>} Promise
                 * @variation 2
                 */

                return Headless;
            })();

            headless.RequestPageStatus = (function() {

                /**
                 * Properties of a RequestPageStatus.
                 * @memberof printcloud.digital.headless
                 * @interface IRequestPageStatus
                 * @property {number|null} [id_page] RequestPageStatus id_page
                 * @property {boolean|null} [include_log] RequestPageStatus include_log
                 */

                /**
                 * Constructs a new RequestPageStatus.
                 * @memberof printcloud.digital.headless
                 * @classdesc Represents a RequestPageStatus.
                 * @implements IRequestPageStatus
                 * @constructor
                 * @param {printcloud.digital.headless.IRequestPageStatus=} [properties] Properties to set
                 */
                function RequestPageStatus(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * RequestPageStatus id_page.
                 * @member {number} id_page
                 * @memberof printcloud.digital.headless.RequestPageStatus
                 * @instance
                 */
                RequestPageStatus.prototype.id_page = 0;

                /**
                 * RequestPageStatus include_log.
                 * @member {boolean} include_log
                 * @memberof printcloud.digital.headless.RequestPageStatus
                 * @instance
                 */
                RequestPageStatus.prototype.include_log = false;

                /**
                 * Creates a new RequestPageStatus instance using the specified properties.
                 * @function create
                 * @memberof printcloud.digital.headless.RequestPageStatus
                 * @static
                 * @param {printcloud.digital.headless.IRequestPageStatus=} [properties] Properties to set
                 * @returns {printcloud.digital.headless.RequestPageStatus} RequestPageStatus instance
                 */
                RequestPageStatus.create = function create(properties) {
                    return new RequestPageStatus(properties);
                };

                /**
                 * Encodes the specified RequestPageStatus message. Does not implicitly {@link printcloud.digital.headless.RequestPageStatus.verify|verify} messages.
                 * @function encode
                 * @memberof printcloud.digital.headless.RequestPageStatus
                 * @static
                 * @param {printcloud.digital.headless.IRequestPageStatus} message RequestPageStatus message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RequestPageStatus.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id_page != null && Object.hasOwnProperty.call(message, "id_page"))
                        writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id_page);
                    if (message.include_log != null && Object.hasOwnProperty.call(message, "include_log"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.include_log);
                    return writer;
                };

                /**
                 * Encodes the specified RequestPageStatus message, length delimited. Does not implicitly {@link printcloud.digital.headless.RequestPageStatus.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof printcloud.digital.headless.RequestPageStatus
                 * @static
                 * @param {printcloud.digital.headless.IRequestPageStatus} message RequestPageStatus message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RequestPageStatus.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a RequestPageStatus message from the specified reader or buffer.
                 * @function decode
                 * @memberof printcloud.digital.headless.RequestPageStatus
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {printcloud.digital.headless.RequestPageStatus} RequestPageStatus
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RequestPageStatus.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.printcloud.digital.headless.RequestPageStatus();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.id_page = reader.uint32();
                            break;
                        case 2:
                            message.include_log = reader.bool();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a RequestPageStatus message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof printcloud.digital.headless.RequestPageStatus
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {printcloud.digital.headless.RequestPageStatus} RequestPageStatus
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RequestPageStatus.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a RequestPageStatus message.
                 * @function verify
                 * @memberof printcloud.digital.headless.RequestPageStatus
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RequestPageStatus.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id_page != null && message.hasOwnProperty("id_page"))
                        if (!$util.isInteger(message.id_page))
                            return "id_page: integer expected";
                    if (message.include_log != null && message.hasOwnProperty("include_log"))
                        if (typeof message.include_log !== "boolean")
                            return "include_log: boolean expected";
                    return null;
                };

                /**
                 * Creates a RequestPageStatus message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof printcloud.digital.headless.RequestPageStatus
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {printcloud.digital.headless.RequestPageStatus} RequestPageStatus
                 */
                RequestPageStatus.fromObject = function fromObject(object) {
                    if (object instanceof $root.printcloud.digital.headless.RequestPageStatus)
                        return object;
                    let message = new $root.printcloud.digital.headless.RequestPageStatus();
                    if (object.id_page != null)
                        message.id_page = object.id_page >>> 0;
                    if (object.include_log != null)
                        message.include_log = Boolean(object.include_log);
                    return message;
                };

                /**
                 * Creates a plain object from a RequestPageStatus message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof printcloud.digital.headless.RequestPageStatus
                 * @static
                 * @param {printcloud.digital.headless.RequestPageStatus} message RequestPageStatus
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RequestPageStatus.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.id_page = 0;
                        object.include_log = false;
                    }
                    if (message.id_page != null && message.hasOwnProperty("id_page"))
                        object.id_page = message.id_page;
                    if (message.include_log != null && message.hasOwnProperty("include_log"))
                        object.include_log = message.include_log;
                    return object;
                };

                /**
                 * Converts this RequestPageStatus to JSON.
                 * @function toJSON
                 * @memberof printcloud.digital.headless.RequestPageStatus
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RequestPageStatus.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return RequestPageStatus;
            })();

            headless.RequestJobStatus = (function() {

                /**
                 * Properties of a RequestJobStatus.
                 * @memberof printcloud.digital.headless
                 * @interface IRequestJobStatus
                 * @property {number|null} [id_job] RequestJobStatus id_job
                 * @property {boolean|null} [include_log] RequestJobStatus include_log
                 * @property {boolean|null} [include_page_status] RequestJobStatus include_page_status
                 */

                /**
                 * Constructs a new RequestJobStatus.
                 * @memberof printcloud.digital.headless
                 * @classdesc Represents a RequestJobStatus.
                 * @implements IRequestJobStatus
                 * @constructor
                 * @param {printcloud.digital.headless.IRequestJobStatus=} [properties] Properties to set
                 */
                function RequestJobStatus(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * RequestJobStatus id_job.
                 * @member {number} id_job
                 * @memberof printcloud.digital.headless.RequestJobStatus
                 * @instance
                 */
                RequestJobStatus.prototype.id_job = 0;

                /**
                 * RequestJobStatus include_log.
                 * @member {boolean} include_log
                 * @memberof printcloud.digital.headless.RequestJobStatus
                 * @instance
                 */
                RequestJobStatus.prototype.include_log = false;

                /**
                 * RequestJobStatus include_page_status.
                 * @member {boolean} include_page_status
                 * @memberof printcloud.digital.headless.RequestJobStatus
                 * @instance
                 */
                RequestJobStatus.prototype.include_page_status = false;

                /**
                 * Creates a new RequestJobStatus instance using the specified properties.
                 * @function create
                 * @memberof printcloud.digital.headless.RequestJobStatus
                 * @static
                 * @param {printcloud.digital.headless.IRequestJobStatus=} [properties] Properties to set
                 * @returns {printcloud.digital.headless.RequestJobStatus} RequestJobStatus instance
                 */
                RequestJobStatus.create = function create(properties) {
                    return new RequestJobStatus(properties);
                };

                /**
                 * Encodes the specified RequestJobStatus message. Does not implicitly {@link printcloud.digital.headless.RequestJobStatus.verify|verify} messages.
                 * @function encode
                 * @memberof printcloud.digital.headless.RequestJobStatus
                 * @static
                 * @param {printcloud.digital.headless.IRequestJobStatus} message RequestJobStatus message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RequestJobStatus.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id_job != null && Object.hasOwnProperty.call(message, "id_job"))
                        writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id_job);
                    if (message.include_log != null && Object.hasOwnProperty.call(message, "include_log"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.include_log);
                    if (message.include_page_status != null && Object.hasOwnProperty.call(message, "include_page_status"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.include_page_status);
                    return writer;
                };

                /**
                 * Encodes the specified RequestJobStatus message, length delimited. Does not implicitly {@link printcloud.digital.headless.RequestJobStatus.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof printcloud.digital.headless.RequestJobStatus
                 * @static
                 * @param {printcloud.digital.headless.IRequestJobStatus} message RequestJobStatus message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RequestJobStatus.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a RequestJobStatus message from the specified reader or buffer.
                 * @function decode
                 * @memberof printcloud.digital.headless.RequestJobStatus
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {printcloud.digital.headless.RequestJobStatus} RequestJobStatus
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RequestJobStatus.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.printcloud.digital.headless.RequestJobStatus();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.id_job = reader.uint32();
                            break;
                        case 2:
                            message.include_log = reader.bool();
                            break;
                        case 3:
                            message.include_page_status = reader.bool();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a RequestJobStatus message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof printcloud.digital.headless.RequestJobStatus
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {printcloud.digital.headless.RequestJobStatus} RequestJobStatus
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RequestJobStatus.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a RequestJobStatus message.
                 * @function verify
                 * @memberof printcloud.digital.headless.RequestJobStatus
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RequestJobStatus.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id_job != null && message.hasOwnProperty("id_job"))
                        if (!$util.isInteger(message.id_job))
                            return "id_job: integer expected";
                    if (message.include_log != null && message.hasOwnProperty("include_log"))
                        if (typeof message.include_log !== "boolean")
                            return "include_log: boolean expected";
                    if (message.include_page_status != null && message.hasOwnProperty("include_page_status"))
                        if (typeof message.include_page_status !== "boolean")
                            return "include_page_status: boolean expected";
                    return null;
                };

                /**
                 * Creates a RequestJobStatus message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof printcloud.digital.headless.RequestJobStatus
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {printcloud.digital.headless.RequestJobStatus} RequestJobStatus
                 */
                RequestJobStatus.fromObject = function fromObject(object) {
                    if (object instanceof $root.printcloud.digital.headless.RequestJobStatus)
                        return object;
                    let message = new $root.printcloud.digital.headless.RequestJobStatus();
                    if (object.id_job != null)
                        message.id_job = object.id_job >>> 0;
                    if (object.include_log != null)
                        message.include_log = Boolean(object.include_log);
                    if (object.include_page_status != null)
                        message.include_page_status = Boolean(object.include_page_status);
                    return message;
                };

                /**
                 * Creates a plain object from a RequestJobStatus message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof printcloud.digital.headless.RequestJobStatus
                 * @static
                 * @param {printcloud.digital.headless.RequestJobStatus} message RequestJobStatus
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RequestJobStatus.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.id_job = 0;
                        object.include_log = false;
                        object.include_page_status = false;
                    }
                    if (message.id_job != null && message.hasOwnProperty("id_job"))
                        object.id_job = message.id_job;
                    if (message.include_log != null && message.hasOwnProperty("include_log"))
                        object.include_log = message.include_log;
                    if (message.include_page_status != null && message.hasOwnProperty("include_page_status"))
                        object.include_page_status = message.include_page_status;
                    return object;
                };

                /**
                 * Converts this RequestJobStatus to JSON.
                 * @function toJSON
                 * @memberof printcloud.digital.headless.RequestJobStatus
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RequestJobStatus.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return RequestJobStatus;
            })();

            headless.ListOutputConfiguration = (function() {

                /**
                 * Properties of a ListOutputConfiguration.
                 * @memberof printcloud.digital.headless
                 * @interface IListOutputConfiguration
                 * @property {Array.<Uint8Array>|null} [data_output_configuration] ListOutputConfiguration data_output_configuration
                 */

                /**
                 * Constructs a new ListOutputConfiguration.
                 * @memberof printcloud.digital.headless
                 * @classdesc Represents a ListOutputConfiguration.
                 * @implements IListOutputConfiguration
                 * @constructor
                 * @param {printcloud.digital.headless.IListOutputConfiguration=} [properties] Properties to set
                 */
                function ListOutputConfiguration(properties) {
                    this.data_output_configuration = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListOutputConfiguration data_output_configuration.
                 * @member {Array.<Uint8Array>} data_output_configuration
                 * @memberof printcloud.digital.headless.ListOutputConfiguration
                 * @instance
                 */
                ListOutputConfiguration.prototype.data_output_configuration = $util.emptyArray;

                /**
                 * Creates a new ListOutputConfiguration instance using the specified properties.
                 * @function create
                 * @memberof printcloud.digital.headless.ListOutputConfiguration
                 * @static
                 * @param {printcloud.digital.headless.IListOutputConfiguration=} [properties] Properties to set
                 * @returns {printcloud.digital.headless.ListOutputConfiguration} ListOutputConfiguration instance
                 */
                ListOutputConfiguration.create = function create(properties) {
                    return new ListOutputConfiguration(properties);
                };

                /**
                 * Encodes the specified ListOutputConfiguration message. Does not implicitly {@link printcloud.digital.headless.ListOutputConfiguration.verify|verify} messages.
                 * @function encode
                 * @memberof printcloud.digital.headless.ListOutputConfiguration
                 * @static
                 * @param {printcloud.digital.headless.IListOutputConfiguration} message ListOutputConfiguration message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ListOutputConfiguration.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.data_output_configuration != null && message.data_output_configuration.length)
                        for (let i = 0; i < message.data_output_configuration.length; ++i)
                            writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.data_output_configuration[i]);
                    return writer;
                };

                /**
                 * Encodes the specified ListOutputConfiguration message, length delimited. Does not implicitly {@link printcloud.digital.headless.ListOutputConfiguration.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof printcloud.digital.headless.ListOutputConfiguration
                 * @static
                 * @param {printcloud.digital.headless.IListOutputConfiguration} message ListOutputConfiguration message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ListOutputConfiguration.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ListOutputConfiguration message from the specified reader or buffer.
                 * @function decode
                 * @memberof printcloud.digital.headless.ListOutputConfiguration
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {printcloud.digital.headless.ListOutputConfiguration} ListOutputConfiguration
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ListOutputConfiguration.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.printcloud.digital.headless.ListOutputConfiguration();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 2:
                            if (!(message.data_output_configuration && message.data_output_configuration.length))
                                message.data_output_configuration = [];
                            message.data_output_configuration.push(reader.bytes());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ListOutputConfiguration message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof printcloud.digital.headless.ListOutputConfiguration
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {printcloud.digital.headless.ListOutputConfiguration} ListOutputConfiguration
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ListOutputConfiguration.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ListOutputConfiguration message.
                 * @function verify
                 * @memberof printcloud.digital.headless.ListOutputConfiguration
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListOutputConfiguration.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.data_output_configuration != null && message.hasOwnProperty("data_output_configuration")) {
                        if (!Array.isArray(message.data_output_configuration))
                            return "data_output_configuration: array expected";
                        for (let i = 0; i < message.data_output_configuration.length; ++i)
                            if (!(message.data_output_configuration[i] && typeof message.data_output_configuration[i].length === "number" || $util.isString(message.data_output_configuration[i])))
                                return "data_output_configuration: buffer[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListOutputConfiguration message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof printcloud.digital.headless.ListOutputConfiguration
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {printcloud.digital.headless.ListOutputConfiguration} ListOutputConfiguration
                 */
                ListOutputConfiguration.fromObject = function fromObject(object) {
                    if (object instanceof $root.printcloud.digital.headless.ListOutputConfiguration)
                        return object;
                    let message = new $root.printcloud.digital.headless.ListOutputConfiguration();
                    if (object.data_output_configuration) {
                        if (!Array.isArray(object.data_output_configuration))
                            throw TypeError(".printcloud.digital.headless.ListOutputConfiguration.data_output_configuration: array expected");
                        message.data_output_configuration = [];
                        for (let i = 0; i < object.data_output_configuration.length; ++i)
                            if (typeof object.data_output_configuration[i] === "string")
                                $util.base64.decode(object.data_output_configuration[i], message.data_output_configuration[i] = $util.newBuffer($util.base64.length(object.data_output_configuration[i])), 0);
                            else if (object.data_output_configuration[i].length)
                                message.data_output_configuration[i] = object.data_output_configuration[i];
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListOutputConfiguration message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof printcloud.digital.headless.ListOutputConfiguration
                 * @static
                 * @param {printcloud.digital.headless.ListOutputConfiguration} message ListOutputConfiguration
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListOutputConfiguration.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.data_output_configuration = [];
                    if (message.data_output_configuration && message.data_output_configuration.length) {
                        object.data_output_configuration = [];
                        for (let j = 0; j < message.data_output_configuration.length; ++j)
                            object.data_output_configuration[j] = options.bytes === String ? $util.base64.encode(message.data_output_configuration[j], 0, message.data_output_configuration[j].length) : options.bytes === Array ? Array.prototype.slice.call(message.data_output_configuration[j]) : message.data_output_configuration[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListOutputConfiguration to JSON.
                 * @function toJSON
                 * @memberof printcloud.digital.headless.ListOutputConfiguration
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListOutputConfiguration.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListOutputConfiguration;
            })();

            headless.RequestSendPage = (function() {

                /**
                 * Properties of a RequestSendPage.
                 * @memberof printcloud.digital.headless
                 * @interface IRequestSendPage
                 * @property {string|null} [id_job] RequestSendPage id_job
                 * @property {number|null} [id_page] RequestSendPage id_page
                 * @property {number|null} [x_offset] RequestSendPage x_offset
                 * @property {boolean|null} [is_page_special] RequestSendPage is_page_special
                 */

                /**
                 * Constructs a new RequestSendPage.
                 * @memberof printcloud.digital.headless
                 * @classdesc Represents a RequestSendPage.
                 * @implements IRequestSendPage
                 * @constructor
                 * @param {printcloud.digital.headless.IRequestSendPage=} [properties] Properties to set
                 */
                function RequestSendPage(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * RequestSendPage id_job.
                 * @member {string} id_job
                 * @memberof printcloud.digital.headless.RequestSendPage
                 * @instance
                 */
                RequestSendPage.prototype.id_job = "";

                /**
                 * RequestSendPage id_page.
                 * @member {number} id_page
                 * @memberof printcloud.digital.headless.RequestSendPage
                 * @instance
                 */
                RequestSendPage.prototype.id_page = 0;

                /**
                 * RequestSendPage x_offset.
                 * @member {number} x_offset
                 * @memberof printcloud.digital.headless.RequestSendPage
                 * @instance
                 */
                RequestSendPage.prototype.x_offset = 0;

                /**
                 * RequestSendPage is_page_special.
                 * @member {boolean} is_page_special
                 * @memberof printcloud.digital.headless.RequestSendPage
                 * @instance
                 */
                RequestSendPage.prototype.is_page_special = false;

                /**
                 * Creates a new RequestSendPage instance using the specified properties.
                 * @function create
                 * @memberof printcloud.digital.headless.RequestSendPage
                 * @static
                 * @param {printcloud.digital.headless.IRequestSendPage=} [properties] Properties to set
                 * @returns {printcloud.digital.headless.RequestSendPage} RequestSendPage instance
                 */
                RequestSendPage.create = function create(properties) {
                    return new RequestSendPage(properties);
                };

                /**
                 * Encodes the specified RequestSendPage message. Does not implicitly {@link printcloud.digital.headless.RequestSendPage.verify|verify} messages.
                 * @function encode
                 * @memberof printcloud.digital.headless.RequestSendPage
                 * @static
                 * @param {printcloud.digital.headless.IRequestSendPage} message RequestSendPage message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RequestSendPage.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id_job != null && Object.hasOwnProperty.call(message, "id_job"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.id_job);
                    if (message.id_page != null && Object.hasOwnProperty.call(message, "id_page"))
                        writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.id_page);
                    if (message.x_offset != null && Object.hasOwnProperty.call(message, "x_offset"))
                        writer.uint32(/* id 3, wireType 0 =*/24).sint32(message.x_offset);
                    if (message.is_page_special != null && Object.hasOwnProperty.call(message, "is_page_special"))
                        writer.uint32(/* id 4, wireType 0 =*/32).bool(message.is_page_special);
                    return writer;
                };

                /**
                 * Encodes the specified RequestSendPage message, length delimited. Does not implicitly {@link printcloud.digital.headless.RequestSendPage.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof printcloud.digital.headless.RequestSendPage
                 * @static
                 * @param {printcloud.digital.headless.IRequestSendPage} message RequestSendPage message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RequestSendPage.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a RequestSendPage message from the specified reader or buffer.
                 * @function decode
                 * @memberof printcloud.digital.headless.RequestSendPage
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {printcloud.digital.headless.RequestSendPage} RequestSendPage
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RequestSendPage.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.printcloud.digital.headless.RequestSendPage();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.id_job = reader.string();
                            break;
                        case 2:
                            message.id_page = reader.uint32();
                            break;
                        case 3:
                            message.x_offset = reader.sint32();
                            break;
                        case 4:
                            message.is_page_special = reader.bool();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a RequestSendPage message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof printcloud.digital.headless.RequestSendPage
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {printcloud.digital.headless.RequestSendPage} RequestSendPage
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RequestSendPage.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a RequestSendPage message.
                 * @function verify
                 * @memberof printcloud.digital.headless.RequestSendPage
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RequestSendPage.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id_job != null && message.hasOwnProperty("id_job"))
                        if (!$util.isString(message.id_job))
                            return "id_job: string expected";
                    if (message.id_page != null && message.hasOwnProperty("id_page"))
                        if (!$util.isInteger(message.id_page))
                            return "id_page: integer expected";
                    if (message.x_offset != null && message.hasOwnProperty("x_offset"))
                        if (!$util.isInteger(message.x_offset))
                            return "x_offset: integer expected";
                    if (message.is_page_special != null && message.hasOwnProperty("is_page_special"))
                        if (typeof message.is_page_special !== "boolean")
                            return "is_page_special: boolean expected";
                    return null;
                };

                /**
                 * Creates a RequestSendPage message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof printcloud.digital.headless.RequestSendPage
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {printcloud.digital.headless.RequestSendPage} RequestSendPage
                 */
                RequestSendPage.fromObject = function fromObject(object) {
                    if (object instanceof $root.printcloud.digital.headless.RequestSendPage)
                        return object;
                    let message = new $root.printcloud.digital.headless.RequestSendPage();
                    if (object.id_job != null)
                        message.id_job = String(object.id_job);
                    if (object.id_page != null)
                        message.id_page = object.id_page >>> 0;
                    if (object.x_offset != null)
                        message.x_offset = object.x_offset | 0;
                    if (object.is_page_special != null)
                        message.is_page_special = Boolean(object.is_page_special);
                    return message;
                };

                /**
                 * Creates a plain object from a RequestSendPage message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof printcloud.digital.headless.RequestSendPage
                 * @static
                 * @param {printcloud.digital.headless.RequestSendPage} message RequestSendPage
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RequestSendPage.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.id_job = "";
                        object.id_page = 0;
                        object.x_offset = 0;
                        object.is_page_special = false;
                    }
                    if (message.id_job != null && message.hasOwnProperty("id_job"))
                        object.id_job = message.id_job;
                    if (message.id_page != null && message.hasOwnProperty("id_page"))
                        object.id_page = message.id_page;
                    if (message.x_offset != null && message.hasOwnProperty("x_offset"))
                        object.x_offset = message.x_offset;
                    if (message.is_page_special != null && message.hasOwnProperty("is_page_special"))
                        object.is_page_special = message.is_page_special;
                    return object;
                };

                /**
                 * Converts this RequestSendPage to JSON.
                 * @function toJSON
                 * @memberof printcloud.digital.headless.RequestSendPage
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RequestSendPage.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return RequestSendPage;
            })();

            headless.ResponseSendPage = (function() {

                /**
                 * Properties of a ResponseSendPage.
                 * @memberof printcloud.digital.headless
                 * @interface IResponseSendPage
                 * @property {number|null} [error_code] ResponseSendPage error_code
                 * @property {Array.<string>|null} [log] ResponseSendPage log
                 */

                /**
                 * Constructs a new ResponseSendPage.
                 * @memberof printcloud.digital.headless
                 * @classdesc Represents a ResponseSendPage.
                 * @implements IResponseSendPage
                 * @constructor
                 * @param {printcloud.digital.headless.IResponseSendPage=} [properties] Properties to set
                 */
                function ResponseSendPage(properties) {
                    this.log = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ResponseSendPage error_code.
                 * @member {number} error_code
                 * @memberof printcloud.digital.headless.ResponseSendPage
                 * @instance
                 */
                ResponseSendPage.prototype.error_code = 0;

                /**
                 * ResponseSendPage log.
                 * @member {Array.<string>} log
                 * @memberof printcloud.digital.headless.ResponseSendPage
                 * @instance
                 */
                ResponseSendPage.prototype.log = $util.emptyArray;

                /**
                 * Creates a new ResponseSendPage instance using the specified properties.
                 * @function create
                 * @memberof printcloud.digital.headless.ResponseSendPage
                 * @static
                 * @param {printcloud.digital.headless.IResponseSendPage=} [properties] Properties to set
                 * @returns {printcloud.digital.headless.ResponseSendPage} ResponseSendPage instance
                 */
                ResponseSendPage.create = function create(properties) {
                    return new ResponseSendPage(properties);
                };

                /**
                 * Encodes the specified ResponseSendPage message. Does not implicitly {@link printcloud.digital.headless.ResponseSendPage.verify|verify} messages.
                 * @function encode
                 * @memberof printcloud.digital.headless.ResponseSendPage
                 * @static
                 * @param {printcloud.digital.headless.IResponseSendPage} message ResponseSendPage message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResponseSendPage.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.error_code != null && Object.hasOwnProperty.call(message, "error_code"))
                        writer.uint32(/* id 1, wireType 0 =*/8).sint32(message.error_code);
                    if (message.log != null && message.log.length)
                        for (let i = 0; i < message.log.length; ++i)
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.log[i]);
                    return writer;
                };

                /**
                 * Encodes the specified ResponseSendPage message, length delimited. Does not implicitly {@link printcloud.digital.headless.ResponseSendPage.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof printcloud.digital.headless.ResponseSendPage
                 * @static
                 * @param {printcloud.digital.headless.IResponseSendPage} message ResponseSendPage message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResponseSendPage.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ResponseSendPage message from the specified reader or buffer.
                 * @function decode
                 * @memberof printcloud.digital.headless.ResponseSendPage
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {printcloud.digital.headless.ResponseSendPage} ResponseSendPage
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResponseSendPage.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.printcloud.digital.headless.ResponseSendPage();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.error_code = reader.sint32();
                            break;
                        case 2:
                            if (!(message.log && message.log.length))
                                message.log = [];
                            message.log.push(reader.string());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ResponseSendPage message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof printcloud.digital.headless.ResponseSendPage
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {printcloud.digital.headless.ResponseSendPage} ResponseSendPage
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResponseSendPage.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ResponseSendPage message.
                 * @function verify
                 * @memberof printcloud.digital.headless.ResponseSendPage
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ResponseSendPage.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.error_code != null && message.hasOwnProperty("error_code"))
                        if (!$util.isInteger(message.error_code))
                            return "error_code: integer expected";
                    if (message.log != null && message.hasOwnProperty("log")) {
                        if (!Array.isArray(message.log))
                            return "log: array expected";
                        for (let i = 0; i < message.log.length; ++i)
                            if (!$util.isString(message.log[i]))
                                return "log: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ResponseSendPage message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof printcloud.digital.headless.ResponseSendPage
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {printcloud.digital.headless.ResponseSendPage} ResponseSendPage
                 */
                ResponseSendPage.fromObject = function fromObject(object) {
                    if (object instanceof $root.printcloud.digital.headless.ResponseSendPage)
                        return object;
                    let message = new $root.printcloud.digital.headless.ResponseSendPage();
                    if (object.error_code != null)
                        message.error_code = object.error_code | 0;
                    if (object.log) {
                        if (!Array.isArray(object.log))
                            throw TypeError(".printcloud.digital.headless.ResponseSendPage.log: array expected");
                        message.log = [];
                        for (let i = 0; i < object.log.length; ++i)
                            message.log[i] = String(object.log[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ResponseSendPage message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof printcloud.digital.headless.ResponseSendPage
                 * @static
                 * @param {printcloud.digital.headless.ResponseSendPage} message ResponseSendPage
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ResponseSendPage.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.log = [];
                    if (options.defaults)
                        object.error_code = 0;
                    if (message.error_code != null && message.hasOwnProperty("error_code"))
                        object.error_code = message.error_code;
                    if (message.log && message.log.length) {
                        object.log = [];
                        for (let j = 0; j < message.log.length; ++j)
                            object.log[j] = message.log[j];
                    }
                    return object;
                };

                /**
                 * Converts this ResponseSendPage to JSON.
                 * @function toJSON
                 * @memberof printcloud.digital.headless.ResponseSendPage
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ResponseSendPage.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ResponseSendPage;
            })();

            headless.RequestSendJobDescription = (function() {

                /**
                 * Properties of a RequestSendJobDescription.
                 * @memberof printcloud.digital.headless
                 * @interface IRequestSendJobDescription
                 * @property {printcloud.digital.headless.IJobDescriptor|null} [jobdescriptor] RequestSendJobDescription jobdescriptor
                 */

                /**
                 * Constructs a new RequestSendJobDescription.
                 * @memberof printcloud.digital.headless
                 * @classdesc Represents a RequestSendJobDescription.
                 * @implements IRequestSendJobDescription
                 * @constructor
                 * @param {printcloud.digital.headless.IRequestSendJobDescription=} [properties] Properties to set
                 */
                function RequestSendJobDescription(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * RequestSendJobDescription jobdescriptor.
                 * @member {printcloud.digital.headless.IJobDescriptor|null|undefined} jobdescriptor
                 * @memberof printcloud.digital.headless.RequestSendJobDescription
                 * @instance
                 */
                RequestSendJobDescription.prototype.jobdescriptor = null;

                /**
                 * Creates a new RequestSendJobDescription instance using the specified properties.
                 * @function create
                 * @memberof printcloud.digital.headless.RequestSendJobDescription
                 * @static
                 * @param {printcloud.digital.headless.IRequestSendJobDescription=} [properties] Properties to set
                 * @returns {printcloud.digital.headless.RequestSendJobDescription} RequestSendJobDescription instance
                 */
                RequestSendJobDescription.create = function create(properties) {
                    return new RequestSendJobDescription(properties);
                };

                /**
                 * Encodes the specified RequestSendJobDescription message. Does not implicitly {@link printcloud.digital.headless.RequestSendJobDescription.verify|verify} messages.
                 * @function encode
                 * @memberof printcloud.digital.headless.RequestSendJobDescription
                 * @static
                 * @param {printcloud.digital.headless.IRequestSendJobDescription} message RequestSendJobDescription message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RequestSendJobDescription.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.jobdescriptor != null && Object.hasOwnProperty.call(message, "jobdescriptor"))
                        $root.printcloud.digital.headless.JobDescriptor.encode(message.jobdescriptor, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified RequestSendJobDescription message, length delimited. Does not implicitly {@link printcloud.digital.headless.RequestSendJobDescription.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof printcloud.digital.headless.RequestSendJobDescription
                 * @static
                 * @param {printcloud.digital.headless.IRequestSendJobDescription} message RequestSendJobDescription message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RequestSendJobDescription.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a RequestSendJobDescription message from the specified reader or buffer.
                 * @function decode
                 * @memberof printcloud.digital.headless.RequestSendJobDescription
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {printcloud.digital.headless.RequestSendJobDescription} RequestSendJobDescription
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RequestSendJobDescription.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.printcloud.digital.headless.RequestSendJobDescription();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.jobdescriptor = $root.printcloud.digital.headless.JobDescriptor.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a RequestSendJobDescription message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof printcloud.digital.headless.RequestSendJobDescription
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {printcloud.digital.headless.RequestSendJobDescription} RequestSendJobDescription
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RequestSendJobDescription.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a RequestSendJobDescription message.
                 * @function verify
                 * @memberof printcloud.digital.headless.RequestSendJobDescription
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RequestSendJobDescription.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.jobdescriptor != null && message.hasOwnProperty("jobdescriptor")) {
                        let error = $root.printcloud.digital.headless.JobDescriptor.verify(message.jobdescriptor);
                        if (error)
                            return "jobdescriptor." + error;
                    }
                    return null;
                };

                /**
                 * Creates a RequestSendJobDescription message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof printcloud.digital.headless.RequestSendJobDescription
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {printcloud.digital.headless.RequestSendJobDescription} RequestSendJobDescription
                 */
                RequestSendJobDescription.fromObject = function fromObject(object) {
                    if (object instanceof $root.printcloud.digital.headless.RequestSendJobDescription)
                        return object;
                    let message = new $root.printcloud.digital.headless.RequestSendJobDescription();
                    if (object.jobdescriptor != null) {
                        if (typeof object.jobdescriptor !== "object")
                            throw TypeError(".printcloud.digital.headless.RequestSendJobDescription.jobdescriptor: object expected");
                        message.jobdescriptor = $root.printcloud.digital.headless.JobDescriptor.fromObject(object.jobdescriptor);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a RequestSendJobDescription message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof printcloud.digital.headless.RequestSendJobDescription
                 * @static
                 * @param {printcloud.digital.headless.RequestSendJobDescription} message RequestSendJobDescription
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RequestSendJobDescription.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.jobdescriptor = null;
                    if (message.jobdescriptor != null && message.hasOwnProperty("jobdescriptor"))
                        object.jobdescriptor = $root.printcloud.digital.headless.JobDescriptor.toObject(message.jobdescriptor, options);
                    return object;
                };

                /**
                 * Converts this RequestSendJobDescription to JSON.
                 * @function toJSON
                 * @memberof printcloud.digital.headless.RequestSendJobDescription
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RequestSendJobDescription.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return RequestSendJobDescription;
            })();

            headless.ResponseSendJobDescription = (function() {

                /**
                 * Properties of a ResponseSendJobDescription.
                 * @memberof printcloud.digital.headless
                 * @interface IResponseSendJobDescription
                 * @property {string|null} [id_job] ResponseSendJobDescription id_job
                 * @property {number|null} [error_code] ResponseSendJobDescription error_code
                 * @property {Array.<string>|null} [log] ResponseSendJobDescription log
                 */

                /**
                 * Constructs a new ResponseSendJobDescription.
                 * @memberof printcloud.digital.headless
                 * @classdesc Represents a ResponseSendJobDescription.
                 * @implements IResponseSendJobDescription
                 * @constructor
                 * @param {printcloud.digital.headless.IResponseSendJobDescription=} [properties] Properties to set
                 */
                function ResponseSendJobDescription(properties) {
                    this.log = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ResponseSendJobDescription id_job.
                 * @member {string} id_job
                 * @memberof printcloud.digital.headless.ResponseSendJobDescription
                 * @instance
                 */
                ResponseSendJobDescription.prototype.id_job = "";

                /**
                 * ResponseSendJobDescription error_code.
                 * @member {number} error_code
                 * @memberof printcloud.digital.headless.ResponseSendJobDescription
                 * @instance
                 */
                ResponseSendJobDescription.prototype.error_code = 0;

                /**
                 * ResponseSendJobDescription log.
                 * @member {Array.<string>} log
                 * @memberof printcloud.digital.headless.ResponseSendJobDescription
                 * @instance
                 */
                ResponseSendJobDescription.prototype.log = $util.emptyArray;

                /**
                 * Creates a new ResponseSendJobDescription instance using the specified properties.
                 * @function create
                 * @memberof printcloud.digital.headless.ResponseSendJobDescription
                 * @static
                 * @param {printcloud.digital.headless.IResponseSendJobDescription=} [properties] Properties to set
                 * @returns {printcloud.digital.headless.ResponseSendJobDescription} ResponseSendJobDescription instance
                 */
                ResponseSendJobDescription.create = function create(properties) {
                    return new ResponseSendJobDescription(properties);
                };

                /**
                 * Encodes the specified ResponseSendJobDescription message. Does not implicitly {@link printcloud.digital.headless.ResponseSendJobDescription.verify|verify} messages.
                 * @function encode
                 * @memberof printcloud.digital.headless.ResponseSendJobDescription
                 * @static
                 * @param {printcloud.digital.headless.IResponseSendJobDescription} message ResponseSendJobDescription message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResponseSendJobDescription.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id_job != null && Object.hasOwnProperty.call(message, "id_job"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.id_job);
                    if (message.error_code != null && Object.hasOwnProperty.call(message, "error_code"))
                        writer.uint32(/* id 2, wireType 0 =*/16).sint32(message.error_code);
                    if (message.log != null && message.log.length)
                        for (let i = 0; i < message.log.length; ++i)
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.log[i]);
                    return writer;
                };

                /**
                 * Encodes the specified ResponseSendJobDescription message, length delimited. Does not implicitly {@link printcloud.digital.headless.ResponseSendJobDescription.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof printcloud.digital.headless.ResponseSendJobDescription
                 * @static
                 * @param {printcloud.digital.headless.IResponseSendJobDescription} message ResponseSendJobDescription message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResponseSendJobDescription.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ResponseSendJobDescription message from the specified reader or buffer.
                 * @function decode
                 * @memberof printcloud.digital.headless.ResponseSendJobDescription
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {printcloud.digital.headless.ResponseSendJobDescription} ResponseSendJobDescription
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResponseSendJobDescription.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.printcloud.digital.headless.ResponseSendJobDescription();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.id_job = reader.string();
                            break;
                        case 2:
                            message.error_code = reader.sint32();
                            break;
                        case 3:
                            if (!(message.log && message.log.length))
                                message.log = [];
                            message.log.push(reader.string());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ResponseSendJobDescription message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof printcloud.digital.headless.ResponseSendJobDescription
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {printcloud.digital.headless.ResponseSendJobDescription} ResponseSendJobDescription
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResponseSendJobDescription.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ResponseSendJobDescription message.
                 * @function verify
                 * @memberof printcloud.digital.headless.ResponseSendJobDescription
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ResponseSendJobDescription.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id_job != null && message.hasOwnProperty("id_job"))
                        if (!$util.isString(message.id_job))
                            return "id_job: string expected";
                    if (message.error_code != null && message.hasOwnProperty("error_code"))
                        if (!$util.isInteger(message.error_code))
                            return "error_code: integer expected";
                    if (message.log != null && message.hasOwnProperty("log")) {
                        if (!Array.isArray(message.log))
                            return "log: array expected";
                        for (let i = 0; i < message.log.length; ++i)
                            if (!$util.isString(message.log[i]))
                                return "log: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ResponseSendJobDescription message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof printcloud.digital.headless.ResponseSendJobDescription
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {printcloud.digital.headless.ResponseSendJobDescription} ResponseSendJobDescription
                 */
                ResponseSendJobDescription.fromObject = function fromObject(object) {
                    if (object instanceof $root.printcloud.digital.headless.ResponseSendJobDescription)
                        return object;
                    let message = new $root.printcloud.digital.headless.ResponseSendJobDescription();
                    if (object.id_job != null)
                        message.id_job = String(object.id_job);
                    if (object.error_code != null)
                        message.error_code = object.error_code | 0;
                    if (object.log) {
                        if (!Array.isArray(object.log))
                            throw TypeError(".printcloud.digital.headless.ResponseSendJobDescription.log: array expected");
                        message.log = [];
                        for (let i = 0; i < object.log.length; ++i)
                            message.log[i] = String(object.log[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ResponseSendJobDescription message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof printcloud.digital.headless.ResponseSendJobDescription
                 * @static
                 * @param {printcloud.digital.headless.ResponseSendJobDescription} message ResponseSendJobDescription
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ResponseSendJobDescription.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.log = [];
                    if (options.defaults) {
                        object.id_job = "";
                        object.error_code = 0;
                    }
                    if (message.id_job != null && message.hasOwnProperty("id_job"))
                        object.id_job = message.id_job;
                    if (message.error_code != null && message.hasOwnProperty("error_code"))
                        object.error_code = message.error_code;
                    if (message.log && message.log.length) {
                        object.log = [];
                        for (let j = 0; j < message.log.length; ++j)
                            object.log[j] = message.log[j];
                    }
                    return object;
                };

                /**
                 * Converts this ResponseSendJobDescription to JSON.
                 * @function toJSON
                 * @memberof printcloud.digital.headless.ResponseSendJobDescription
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ResponseSendJobDescription.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ResponseSendJobDescription;
            })();

            headless.RequestCancelJob = (function() {

                /**
                 * Properties of a RequestCancelJob.
                 * @memberof printcloud.digital.headless
                 * @interface IRequestCancelJob
                 * @property {string|null} [id_job] RequestCancelJob id_job
                 */

                /**
                 * Constructs a new RequestCancelJob.
                 * @memberof printcloud.digital.headless
                 * @classdesc Represents a RequestCancelJob.
                 * @implements IRequestCancelJob
                 * @constructor
                 * @param {printcloud.digital.headless.IRequestCancelJob=} [properties] Properties to set
                 */
                function RequestCancelJob(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * RequestCancelJob id_job.
                 * @member {string} id_job
                 * @memberof printcloud.digital.headless.RequestCancelJob
                 * @instance
                 */
                RequestCancelJob.prototype.id_job = "";

                /**
                 * Creates a new RequestCancelJob instance using the specified properties.
                 * @function create
                 * @memberof printcloud.digital.headless.RequestCancelJob
                 * @static
                 * @param {printcloud.digital.headless.IRequestCancelJob=} [properties] Properties to set
                 * @returns {printcloud.digital.headless.RequestCancelJob} RequestCancelJob instance
                 */
                RequestCancelJob.create = function create(properties) {
                    return new RequestCancelJob(properties);
                };

                /**
                 * Encodes the specified RequestCancelJob message. Does not implicitly {@link printcloud.digital.headless.RequestCancelJob.verify|verify} messages.
                 * @function encode
                 * @memberof printcloud.digital.headless.RequestCancelJob
                 * @static
                 * @param {printcloud.digital.headless.IRequestCancelJob} message RequestCancelJob message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RequestCancelJob.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id_job != null && Object.hasOwnProperty.call(message, "id_job"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.id_job);
                    return writer;
                };

                /**
                 * Encodes the specified RequestCancelJob message, length delimited. Does not implicitly {@link printcloud.digital.headless.RequestCancelJob.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof printcloud.digital.headless.RequestCancelJob
                 * @static
                 * @param {printcloud.digital.headless.IRequestCancelJob} message RequestCancelJob message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RequestCancelJob.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a RequestCancelJob message from the specified reader or buffer.
                 * @function decode
                 * @memberof printcloud.digital.headless.RequestCancelJob
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {printcloud.digital.headless.RequestCancelJob} RequestCancelJob
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RequestCancelJob.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.printcloud.digital.headless.RequestCancelJob();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.id_job = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a RequestCancelJob message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof printcloud.digital.headless.RequestCancelJob
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {printcloud.digital.headless.RequestCancelJob} RequestCancelJob
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RequestCancelJob.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a RequestCancelJob message.
                 * @function verify
                 * @memberof printcloud.digital.headless.RequestCancelJob
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RequestCancelJob.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id_job != null && message.hasOwnProperty("id_job"))
                        if (!$util.isString(message.id_job))
                            return "id_job: string expected";
                    return null;
                };

                /**
                 * Creates a RequestCancelJob message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof printcloud.digital.headless.RequestCancelJob
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {printcloud.digital.headless.RequestCancelJob} RequestCancelJob
                 */
                RequestCancelJob.fromObject = function fromObject(object) {
                    if (object instanceof $root.printcloud.digital.headless.RequestCancelJob)
                        return object;
                    let message = new $root.printcloud.digital.headless.RequestCancelJob();
                    if (object.id_job != null)
                        message.id_job = String(object.id_job);
                    return message;
                };

                /**
                 * Creates a plain object from a RequestCancelJob message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof printcloud.digital.headless.RequestCancelJob
                 * @static
                 * @param {printcloud.digital.headless.RequestCancelJob} message RequestCancelJob
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RequestCancelJob.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.id_job = "";
                    if (message.id_job != null && message.hasOwnProperty("id_job"))
                        object.id_job = message.id_job;
                    return object;
                };

                /**
                 * Converts this RequestCancelJob to JSON.
                 * @function toJSON
                 * @memberof printcloud.digital.headless.RequestCancelJob
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RequestCancelJob.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return RequestCancelJob;
            })();

            headless.ResponseCancelJob = (function() {

                /**
                 * Properties of a ResponseCancelJob.
                 * @memberof printcloud.digital.headless
                 * @interface IResponseCancelJob
                 * @property {number|null} [error_code] ResponseCancelJob error_code
                 * @property {Array.<string>|null} [log] ResponseCancelJob log
                 */

                /**
                 * Constructs a new ResponseCancelJob.
                 * @memberof printcloud.digital.headless
                 * @classdesc Represents a ResponseCancelJob.
                 * @implements IResponseCancelJob
                 * @constructor
                 * @param {printcloud.digital.headless.IResponseCancelJob=} [properties] Properties to set
                 */
                function ResponseCancelJob(properties) {
                    this.log = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ResponseCancelJob error_code.
                 * @member {number} error_code
                 * @memberof printcloud.digital.headless.ResponseCancelJob
                 * @instance
                 */
                ResponseCancelJob.prototype.error_code = 0;

                /**
                 * ResponseCancelJob log.
                 * @member {Array.<string>} log
                 * @memberof printcloud.digital.headless.ResponseCancelJob
                 * @instance
                 */
                ResponseCancelJob.prototype.log = $util.emptyArray;

                /**
                 * Creates a new ResponseCancelJob instance using the specified properties.
                 * @function create
                 * @memberof printcloud.digital.headless.ResponseCancelJob
                 * @static
                 * @param {printcloud.digital.headless.IResponseCancelJob=} [properties] Properties to set
                 * @returns {printcloud.digital.headless.ResponseCancelJob} ResponseCancelJob instance
                 */
                ResponseCancelJob.create = function create(properties) {
                    return new ResponseCancelJob(properties);
                };

                /**
                 * Encodes the specified ResponseCancelJob message. Does not implicitly {@link printcloud.digital.headless.ResponseCancelJob.verify|verify} messages.
                 * @function encode
                 * @memberof printcloud.digital.headless.ResponseCancelJob
                 * @static
                 * @param {printcloud.digital.headless.IResponseCancelJob} message ResponseCancelJob message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResponseCancelJob.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.error_code != null && Object.hasOwnProperty.call(message, "error_code"))
                        writer.uint32(/* id 1, wireType 0 =*/8).sint32(message.error_code);
                    if (message.log != null && message.log.length)
                        for (let i = 0; i < message.log.length; ++i)
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.log[i]);
                    return writer;
                };

                /**
                 * Encodes the specified ResponseCancelJob message, length delimited. Does not implicitly {@link printcloud.digital.headless.ResponseCancelJob.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof printcloud.digital.headless.ResponseCancelJob
                 * @static
                 * @param {printcloud.digital.headless.IResponseCancelJob} message ResponseCancelJob message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResponseCancelJob.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ResponseCancelJob message from the specified reader or buffer.
                 * @function decode
                 * @memberof printcloud.digital.headless.ResponseCancelJob
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {printcloud.digital.headless.ResponseCancelJob} ResponseCancelJob
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResponseCancelJob.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.printcloud.digital.headless.ResponseCancelJob();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.error_code = reader.sint32();
                            break;
                        case 2:
                            if (!(message.log && message.log.length))
                                message.log = [];
                            message.log.push(reader.string());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ResponseCancelJob message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof printcloud.digital.headless.ResponseCancelJob
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {printcloud.digital.headless.ResponseCancelJob} ResponseCancelJob
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResponseCancelJob.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ResponseCancelJob message.
                 * @function verify
                 * @memberof printcloud.digital.headless.ResponseCancelJob
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ResponseCancelJob.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.error_code != null && message.hasOwnProperty("error_code"))
                        if (!$util.isInteger(message.error_code))
                            return "error_code: integer expected";
                    if (message.log != null && message.hasOwnProperty("log")) {
                        if (!Array.isArray(message.log))
                            return "log: array expected";
                        for (let i = 0; i < message.log.length; ++i)
                            if (!$util.isString(message.log[i]))
                                return "log: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ResponseCancelJob message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof printcloud.digital.headless.ResponseCancelJob
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {printcloud.digital.headless.ResponseCancelJob} ResponseCancelJob
                 */
                ResponseCancelJob.fromObject = function fromObject(object) {
                    if (object instanceof $root.printcloud.digital.headless.ResponseCancelJob)
                        return object;
                    let message = new $root.printcloud.digital.headless.ResponseCancelJob();
                    if (object.error_code != null)
                        message.error_code = object.error_code | 0;
                    if (object.log) {
                        if (!Array.isArray(object.log))
                            throw TypeError(".printcloud.digital.headless.ResponseCancelJob.log: array expected");
                        message.log = [];
                        for (let i = 0; i < object.log.length; ++i)
                            message.log[i] = String(object.log[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ResponseCancelJob message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof printcloud.digital.headless.ResponseCancelJob
                 * @static
                 * @param {printcloud.digital.headless.ResponseCancelJob} message ResponseCancelJob
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ResponseCancelJob.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.log = [];
                    if (options.defaults)
                        object.error_code = 0;
                    if (message.error_code != null && message.hasOwnProperty("error_code"))
                        object.error_code = message.error_code;
                    if (message.log && message.log.length) {
                        object.log = [];
                        for (let j = 0; j < message.log.length; ++j)
                            object.log[j] = message.log[j];
                    }
                    return object;
                };

                /**
                 * Converts this ResponseCancelJob to JSON.
                 * @function toJSON
                 * @memberof printcloud.digital.headless.ResponseCancelJob
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ResponseCancelJob.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ResponseCancelJob;
            })();

            return headless;
        })();

        digital.supervised = (function() {

            /**
             * Namespace supervised.
             * @memberof printcloud.digital
             * @namespace
             */
            const supervised = {};

            supervised.RequestPrint = (function() {

                /**
                 * Properties of a RequestPrint.
                 * @memberof printcloud.digital.supervised
                 * @interface IRequestPrint
                 * @property {string|null} [job_uri] RequestPrint job_uri
                 * @property {string|null} [print_id] RequestPrint print_id
                 * @property {string|null} [config_name] RequestPrint config_name
                 */

                /**
                 * Constructs a new RequestPrint.
                 * @memberof printcloud.digital.supervised
                 * @classdesc Represents a RequestPrint.
                 * @implements IRequestPrint
                 * @constructor
                 * @param {printcloud.digital.supervised.IRequestPrint=} [properties] Properties to set
                 */
                function RequestPrint(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * RequestPrint job_uri.
                 * @member {string} job_uri
                 * @memberof printcloud.digital.supervised.RequestPrint
                 * @instance
                 */
                RequestPrint.prototype.job_uri = "";

                /**
                 * RequestPrint print_id.
                 * @member {string} print_id
                 * @memberof printcloud.digital.supervised.RequestPrint
                 * @instance
                 */
                RequestPrint.prototype.print_id = "";

                /**
                 * RequestPrint config_name.
                 * @member {string} config_name
                 * @memberof printcloud.digital.supervised.RequestPrint
                 * @instance
                 */
                RequestPrint.prototype.config_name = "";

                /**
                 * Creates a new RequestPrint instance using the specified properties.
                 * @function create
                 * @memberof printcloud.digital.supervised.RequestPrint
                 * @static
                 * @param {printcloud.digital.supervised.IRequestPrint=} [properties] Properties to set
                 * @returns {printcloud.digital.supervised.RequestPrint} RequestPrint instance
                 */
                RequestPrint.create = function create(properties) {
                    return new RequestPrint(properties);
                };

                /**
                 * Encodes the specified RequestPrint message. Does not implicitly {@link printcloud.digital.supervised.RequestPrint.verify|verify} messages.
                 * @function encode
                 * @memberof printcloud.digital.supervised.RequestPrint
                 * @static
                 * @param {printcloud.digital.supervised.IRequestPrint} message RequestPrint message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RequestPrint.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.job_uri != null && Object.hasOwnProperty.call(message, "job_uri"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.job_uri);
                    if (message.print_id != null && Object.hasOwnProperty.call(message, "print_id"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.print_id);
                    if (message.config_name != null && Object.hasOwnProperty.call(message, "config_name"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.config_name);
                    return writer;
                };

                /**
                 * Encodes the specified RequestPrint message, length delimited. Does not implicitly {@link printcloud.digital.supervised.RequestPrint.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof printcloud.digital.supervised.RequestPrint
                 * @static
                 * @param {printcloud.digital.supervised.IRequestPrint} message RequestPrint message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RequestPrint.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a RequestPrint message from the specified reader or buffer.
                 * @function decode
                 * @memberof printcloud.digital.supervised.RequestPrint
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {printcloud.digital.supervised.RequestPrint} RequestPrint
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RequestPrint.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.printcloud.digital.supervised.RequestPrint();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.job_uri = reader.string();
                            break;
                        case 2:
                            message.print_id = reader.string();
                            break;
                        case 3:
                            message.config_name = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a RequestPrint message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof printcloud.digital.supervised.RequestPrint
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {printcloud.digital.supervised.RequestPrint} RequestPrint
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RequestPrint.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a RequestPrint message.
                 * @function verify
                 * @memberof printcloud.digital.supervised.RequestPrint
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RequestPrint.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.job_uri != null && message.hasOwnProperty("job_uri"))
                        if (!$util.isString(message.job_uri))
                            return "job_uri: string expected";
                    if (message.print_id != null && message.hasOwnProperty("print_id"))
                        if (!$util.isString(message.print_id))
                            return "print_id: string expected";
                    if (message.config_name != null && message.hasOwnProperty("config_name"))
                        if (!$util.isString(message.config_name))
                            return "config_name: string expected";
                    return null;
                };

                /**
                 * Creates a RequestPrint message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof printcloud.digital.supervised.RequestPrint
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {printcloud.digital.supervised.RequestPrint} RequestPrint
                 */
                RequestPrint.fromObject = function fromObject(object) {
                    if (object instanceof $root.printcloud.digital.supervised.RequestPrint)
                        return object;
                    let message = new $root.printcloud.digital.supervised.RequestPrint();
                    if (object.job_uri != null)
                        message.job_uri = String(object.job_uri);
                    if (object.print_id != null)
                        message.print_id = String(object.print_id);
                    if (object.config_name != null)
                        message.config_name = String(object.config_name);
                    return message;
                };

                /**
                 * Creates a plain object from a RequestPrint message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof printcloud.digital.supervised.RequestPrint
                 * @static
                 * @param {printcloud.digital.supervised.RequestPrint} message RequestPrint
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RequestPrint.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.job_uri = "";
                        object.print_id = "";
                        object.config_name = "";
                    }
                    if (message.job_uri != null && message.hasOwnProperty("job_uri"))
                        object.job_uri = message.job_uri;
                    if (message.print_id != null && message.hasOwnProperty("print_id"))
                        object.print_id = message.print_id;
                    if (message.config_name != null && message.hasOwnProperty("config_name"))
                        object.config_name = message.config_name;
                    return object;
                };

                /**
                 * Converts this RequestPrint to JSON.
                 * @function toJSON
                 * @memberof printcloud.digital.supervised.RequestPrint
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RequestPrint.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return RequestPrint;
            })();

            supervised.ResponsePrint = (function() {

                /**
                 * Properties of a ResponsePrint.
                 * @memberof printcloud.digital.supervised
                 * @interface IResponsePrint
                 * @property {printcloud.digital.supervised.ResponsePrint.Status|null} [status] ResponsePrint status
                 * @property {string|null} [job_uri] ResponsePrint job_uri
                 * @property {string|null} [print_id] ResponsePrint print_id
                 * @property {Array.<string>|null} [log] ResponsePrint log
                 */

                /**
                 * Constructs a new ResponsePrint.
                 * @memberof printcloud.digital.supervised
                 * @classdesc Represents a ResponsePrint.
                 * @implements IResponsePrint
                 * @constructor
                 * @param {printcloud.digital.supervised.IResponsePrint=} [properties] Properties to set
                 */
                function ResponsePrint(properties) {
                    this.log = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ResponsePrint status.
                 * @member {printcloud.digital.supervised.ResponsePrint.Status} status
                 * @memberof printcloud.digital.supervised.ResponsePrint
                 * @instance
                 */
                ResponsePrint.prototype.status = 0;

                /**
                 * ResponsePrint job_uri.
                 * @member {string} job_uri
                 * @memberof printcloud.digital.supervised.ResponsePrint
                 * @instance
                 */
                ResponsePrint.prototype.job_uri = "";

                /**
                 * ResponsePrint print_id.
                 * @member {string} print_id
                 * @memberof printcloud.digital.supervised.ResponsePrint
                 * @instance
                 */
                ResponsePrint.prototype.print_id = "";

                /**
                 * ResponsePrint log.
                 * @member {Array.<string>} log
                 * @memberof printcloud.digital.supervised.ResponsePrint
                 * @instance
                 */
                ResponsePrint.prototype.log = $util.emptyArray;

                /**
                 * Creates a new ResponsePrint instance using the specified properties.
                 * @function create
                 * @memberof printcloud.digital.supervised.ResponsePrint
                 * @static
                 * @param {printcloud.digital.supervised.IResponsePrint=} [properties] Properties to set
                 * @returns {printcloud.digital.supervised.ResponsePrint} ResponsePrint instance
                 */
                ResponsePrint.create = function create(properties) {
                    return new ResponsePrint(properties);
                };

                /**
                 * Encodes the specified ResponsePrint message. Does not implicitly {@link printcloud.digital.supervised.ResponsePrint.verify|verify} messages.
                 * @function encode
                 * @memberof printcloud.digital.supervised.ResponsePrint
                 * @static
                 * @param {printcloud.digital.supervised.IResponsePrint} message ResponsePrint message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResponsePrint.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
                    if (message.job_uri != null && Object.hasOwnProperty.call(message, "job_uri"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.job_uri);
                    if (message.print_id != null && Object.hasOwnProperty.call(message, "print_id"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.print_id);
                    if (message.log != null && message.log.length)
                        for (let i = 0; i < message.log.length; ++i)
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.log[i]);
                    return writer;
                };

                /**
                 * Encodes the specified ResponsePrint message, length delimited. Does not implicitly {@link printcloud.digital.supervised.ResponsePrint.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof printcloud.digital.supervised.ResponsePrint
                 * @static
                 * @param {printcloud.digital.supervised.IResponsePrint} message ResponsePrint message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResponsePrint.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ResponsePrint message from the specified reader or buffer.
                 * @function decode
                 * @memberof printcloud.digital.supervised.ResponsePrint
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {printcloud.digital.supervised.ResponsePrint} ResponsePrint
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResponsePrint.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.printcloud.digital.supervised.ResponsePrint();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.status = reader.int32();
                            break;
                        case 2:
                            message.job_uri = reader.string();
                            break;
                        case 3:
                            message.print_id = reader.string();
                            break;
                        case 4:
                            if (!(message.log && message.log.length))
                                message.log = [];
                            message.log.push(reader.string());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ResponsePrint message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof printcloud.digital.supervised.ResponsePrint
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {printcloud.digital.supervised.ResponsePrint} ResponsePrint
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResponsePrint.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ResponsePrint message.
                 * @function verify
                 * @memberof printcloud.digital.supervised.ResponsePrint
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ResponsePrint.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.status != null && message.hasOwnProperty("status"))
                        switch (message.status) {
                        default:
                            return "status: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.job_uri != null && message.hasOwnProperty("job_uri"))
                        if (!$util.isString(message.job_uri))
                            return "job_uri: string expected";
                    if (message.print_id != null && message.hasOwnProperty("print_id"))
                        if (!$util.isString(message.print_id))
                            return "print_id: string expected";
                    if (message.log != null && message.hasOwnProperty("log")) {
                        if (!Array.isArray(message.log))
                            return "log: array expected";
                        for (let i = 0; i < message.log.length; ++i)
                            if (!$util.isString(message.log[i]))
                                return "log: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ResponsePrint message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof printcloud.digital.supervised.ResponsePrint
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {printcloud.digital.supervised.ResponsePrint} ResponsePrint
                 */
                ResponsePrint.fromObject = function fromObject(object) {
                    if (object instanceof $root.printcloud.digital.supervised.ResponsePrint)
                        return object;
                    let message = new $root.printcloud.digital.supervised.ResponsePrint();
                    switch (object.status) {
                    case "OK":
                    case 0:
                        message.status = 0;
                        break;
                    case "ERROR":
                    case 1:
                        message.status = 1;
                        break;
                    case "PRINTED":
                    case 2:
                        message.status = 2;
                        break;
                    }
                    if (object.job_uri != null)
                        message.job_uri = String(object.job_uri);
                    if (object.print_id != null)
                        message.print_id = String(object.print_id);
                    if (object.log) {
                        if (!Array.isArray(object.log))
                            throw TypeError(".printcloud.digital.supervised.ResponsePrint.log: array expected");
                        message.log = [];
                        for (let i = 0; i < object.log.length; ++i)
                            message.log[i] = String(object.log[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ResponsePrint message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof printcloud.digital.supervised.ResponsePrint
                 * @static
                 * @param {printcloud.digital.supervised.ResponsePrint} message ResponsePrint
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ResponsePrint.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.log = [];
                    if (options.defaults) {
                        object.status = options.enums === String ? "OK" : 0;
                        object.job_uri = "";
                        object.print_id = "";
                    }
                    if (message.status != null && message.hasOwnProperty("status"))
                        object.status = options.enums === String ? $root.printcloud.digital.supervised.ResponsePrint.Status[message.status] : message.status;
                    if (message.job_uri != null && message.hasOwnProperty("job_uri"))
                        object.job_uri = message.job_uri;
                    if (message.print_id != null && message.hasOwnProperty("print_id"))
                        object.print_id = message.print_id;
                    if (message.log && message.log.length) {
                        object.log = [];
                        for (let j = 0; j < message.log.length; ++j)
                            object.log[j] = message.log[j];
                    }
                    return object;
                };

                /**
                 * Converts this ResponsePrint to JSON.
                 * @function toJSON
                 * @memberof printcloud.digital.supervised.ResponsePrint
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ResponsePrint.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Status enum.
                 * @name printcloud.digital.supervised.ResponsePrint.Status
                 * @enum {number}
                 * @property {number} OK=0 OK value
                 * @property {number} ERROR=1 ERROR value
                 * @property {number} PRINTED=2 PRINTED value
                 */
                ResponsePrint.Status = (function() {
                    const valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "OK"] = 0;
                    values[valuesById[1] = "ERROR"] = 1;
                    values[valuesById[2] = "PRINTED"] = 2;
                    return values;
                })();

                return ResponsePrint;
            })();

            supervised.RequestOwnership = (function() {

                /**
                 * Properties of a RequestOwnership.
                 * @memberof printcloud.digital.supervised
                 * @interface IRequestOwnership
                 * @property {string|null} [job_src_uri] RequestOwnership job_src_uri
                 * @property {string|null} [job_dst_uri] RequestOwnership job_dst_uri
                 */

                /**
                 * Constructs a new RequestOwnership.
                 * @memberof printcloud.digital.supervised
                 * @classdesc Represents a RequestOwnership.
                 * @implements IRequestOwnership
                 * @constructor
                 * @param {printcloud.digital.supervised.IRequestOwnership=} [properties] Properties to set
                 */
                function RequestOwnership(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * RequestOwnership job_src_uri.
                 * @member {string} job_src_uri
                 * @memberof printcloud.digital.supervised.RequestOwnership
                 * @instance
                 */
                RequestOwnership.prototype.job_src_uri = "";

                /**
                 * RequestOwnership job_dst_uri.
                 * @member {string} job_dst_uri
                 * @memberof printcloud.digital.supervised.RequestOwnership
                 * @instance
                 */
                RequestOwnership.prototype.job_dst_uri = "";

                /**
                 * Creates a new RequestOwnership instance using the specified properties.
                 * @function create
                 * @memberof printcloud.digital.supervised.RequestOwnership
                 * @static
                 * @param {printcloud.digital.supervised.IRequestOwnership=} [properties] Properties to set
                 * @returns {printcloud.digital.supervised.RequestOwnership} RequestOwnership instance
                 */
                RequestOwnership.create = function create(properties) {
                    return new RequestOwnership(properties);
                };

                /**
                 * Encodes the specified RequestOwnership message. Does not implicitly {@link printcloud.digital.supervised.RequestOwnership.verify|verify} messages.
                 * @function encode
                 * @memberof printcloud.digital.supervised.RequestOwnership
                 * @static
                 * @param {printcloud.digital.supervised.IRequestOwnership} message RequestOwnership message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RequestOwnership.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.job_src_uri != null && Object.hasOwnProperty.call(message, "job_src_uri"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.job_src_uri);
                    if (message.job_dst_uri != null && Object.hasOwnProperty.call(message, "job_dst_uri"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.job_dst_uri);
                    return writer;
                };

                /**
                 * Encodes the specified RequestOwnership message, length delimited. Does not implicitly {@link printcloud.digital.supervised.RequestOwnership.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof printcloud.digital.supervised.RequestOwnership
                 * @static
                 * @param {printcloud.digital.supervised.IRequestOwnership} message RequestOwnership message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RequestOwnership.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a RequestOwnership message from the specified reader or buffer.
                 * @function decode
                 * @memberof printcloud.digital.supervised.RequestOwnership
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {printcloud.digital.supervised.RequestOwnership} RequestOwnership
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RequestOwnership.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.printcloud.digital.supervised.RequestOwnership();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.job_src_uri = reader.string();
                            break;
                        case 2:
                            message.job_dst_uri = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a RequestOwnership message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof printcloud.digital.supervised.RequestOwnership
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {printcloud.digital.supervised.RequestOwnership} RequestOwnership
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RequestOwnership.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a RequestOwnership message.
                 * @function verify
                 * @memberof printcloud.digital.supervised.RequestOwnership
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RequestOwnership.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.job_src_uri != null && message.hasOwnProperty("job_src_uri"))
                        if (!$util.isString(message.job_src_uri))
                            return "job_src_uri: string expected";
                    if (message.job_dst_uri != null && message.hasOwnProperty("job_dst_uri"))
                        if (!$util.isString(message.job_dst_uri))
                            return "job_dst_uri: string expected";
                    return null;
                };

                /**
                 * Creates a RequestOwnership message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof printcloud.digital.supervised.RequestOwnership
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {printcloud.digital.supervised.RequestOwnership} RequestOwnership
                 */
                RequestOwnership.fromObject = function fromObject(object) {
                    if (object instanceof $root.printcloud.digital.supervised.RequestOwnership)
                        return object;
                    let message = new $root.printcloud.digital.supervised.RequestOwnership();
                    if (object.job_src_uri != null)
                        message.job_src_uri = String(object.job_src_uri);
                    if (object.job_dst_uri != null)
                        message.job_dst_uri = String(object.job_dst_uri);
                    return message;
                };

                /**
                 * Creates a plain object from a RequestOwnership message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof printcloud.digital.supervised.RequestOwnership
                 * @static
                 * @param {printcloud.digital.supervised.RequestOwnership} message RequestOwnership
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RequestOwnership.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.job_src_uri = "";
                        object.job_dst_uri = "";
                    }
                    if (message.job_src_uri != null && message.hasOwnProperty("job_src_uri"))
                        object.job_src_uri = message.job_src_uri;
                    if (message.job_dst_uri != null && message.hasOwnProperty("job_dst_uri"))
                        object.job_dst_uri = message.job_dst_uri;
                    return object;
                };

                /**
                 * Converts this RequestOwnership to JSON.
                 * @function toJSON
                 * @memberof printcloud.digital.supervised.RequestOwnership
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RequestOwnership.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return RequestOwnership;
            })();

            supervised.ResponseOwnership = (function() {

                /**
                 * Properties of a ResponseOwnership.
                 * @memberof printcloud.digital.supervised
                 * @interface IResponseOwnership
                 * @property {printcloud.digital.supervised.ResponseOwnership.Status|null} [status] ResponseOwnership status
                 */

                /**
                 * Constructs a new ResponseOwnership.
                 * @memberof printcloud.digital.supervised
                 * @classdesc Represents a ResponseOwnership.
                 * @implements IResponseOwnership
                 * @constructor
                 * @param {printcloud.digital.supervised.IResponseOwnership=} [properties] Properties to set
                 */
                function ResponseOwnership(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ResponseOwnership status.
                 * @member {printcloud.digital.supervised.ResponseOwnership.Status} status
                 * @memberof printcloud.digital.supervised.ResponseOwnership
                 * @instance
                 */
                ResponseOwnership.prototype.status = 0;

                /**
                 * Creates a new ResponseOwnership instance using the specified properties.
                 * @function create
                 * @memberof printcloud.digital.supervised.ResponseOwnership
                 * @static
                 * @param {printcloud.digital.supervised.IResponseOwnership=} [properties] Properties to set
                 * @returns {printcloud.digital.supervised.ResponseOwnership} ResponseOwnership instance
                 */
                ResponseOwnership.create = function create(properties) {
                    return new ResponseOwnership(properties);
                };

                /**
                 * Encodes the specified ResponseOwnership message. Does not implicitly {@link printcloud.digital.supervised.ResponseOwnership.verify|verify} messages.
                 * @function encode
                 * @memberof printcloud.digital.supervised.ResponseOwnership
                 * @static
                 * @param {printcloud.digital.supervised.IResponseOwnership} message ResponseOwnership message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResponseOwnership.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
                    return writer;
                };

                /**
                 * Encodes the specified ResponseOwnership message, length delimited. Does not implicitly {@link printcloud.digital.supervised.ResponseOwnership.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof printcloud.digital.supervised.ResponseOwnership
                 * @static
                 * @param {printcloud.digital.supervised.IResponseOwnership} message ResponseOwnership message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResponseOwnership.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ResponseOwnership message from the specified reader or buffer.
                 * @function decode
                 * @memberof printcloud.digital.supervised.ResponseOwnership
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {printcloud.digital.supervised.ResponseOwnership} ResponseOwnership
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResponseOwnership.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.printcloud.digital.supervised.ResponseOwnership();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.status = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ResponseOwnership message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof printcloud.digital.supervised.ResponseOwnership
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {printcloud.digital.supervised.ResponseOwnership} ResponseOwnership
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResponseOwnership.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ResponseOwnership message.
                 * @function verify
                 * @memberof printcloud.digital.supervised.ResponseOwnership
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ResponseOwnership.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.status != null && message.hasOwnProperty("status"))
                        switch (message.status) {
                        default:
                            return "status: enum value expected";
                        case 0:
                        case 1:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates a ResponseOwnership message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof printcloud.digital.supervised.ResponseOwnership
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {printcloud.digital.supervised.ResponseOwnership} ResponseOwnership
                 */
                ResponseOwnership.fromObject = function fromObject(object) {
                    if (object instanceof $root.printcloud.digital.supervised.ResponseOwnership)
                        return object;
                    let message = new $root.printcloud.digital.supervised.ResponseOwnership();
                    switch (object.status) {
                    case "GRANTED":
                    case 0:
                        message.status = 0;
                        break;
                    case "ERROR":
                    case 1:
                        message.status = 1;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ResponseOwnership message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof printcloud.digital.supervised.ResponseOwnership
                 * @static
                 * @param {printcloud.digital.supervised.ResponseOwnership} message ResponseOwnership
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ResponseOwnership.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.status = options.enums === String ? "GRANTED" : 0;
                    if (message.status != null && message.hasOwnProperty("status"))
                        object.status = options.enums === String ? $root.printcloud.digital.supervised.ResponseOwnership.Status[message.status] : message.status;
                    return object;
                };

                /**
                 * Converts this ResponseOwnership to JSON.
                 * @function toJSON
                 * @memberof printcloud.digital.supervised.ResponseOwnership
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ResponseOwnership.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Status enum.
                 * @name printcloud.digital.supervised.ResponseOwnership.Status
                 * @enum {number}
                 * @property {number} GRANTED=0 GRANTED value
                 * @property {number} ERROR=1 ERROR value
                 */
                ResponseOwnership.Status = (function() {
                    const valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "GRANTED"] = 0;
                    values[valuesById[1] = "ERROR"] = 1;
                    return values;
                })();

                return ResponseOwnership;
            })();

            supervised.RequestDelete = (function() {

                /**
                 * Properties of a RequestDelete.
                 * @memberof printcloud.digital.supervised
                 * @interface IRequestDelete
                 * @property {string|null} [job_uri] RequestDelete job_uri
                 */

                /**
                 * Constructs a new RequestDelete.
                 * @memberof printcloud.digital.supervised
                 * @classdesc Represents a RequestDelete.
                 * @implements IRequestDelete
                 * @constructor
                 * @param {printcloud.digital.supervised.IRequestDelete=} [properties] Properties to set
                 */
                function RequestDelete(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * RequestDelete job_uri.
                 * @member {string} job_uri
                 * @memberof printcloud.digital.supervised.RequestDelete
                 * @instance
                 */
                RequestDelete.prototype.job_uri = "";

                /**
                 * Creates a new RequestDelete instance using the specified properties.
                 * @function create
                 * @memberof printcloud.digital.supervised.RequestDelete
                 * @static
                 * @param {printcloud.digital.supervised.IRequestDelete=} [properties] Properties to set
                 * @returns {printcloud.digital.supervised.RequestDelete} RequestDelete instance
                 */
                RequestDelete.create = function create(properties) {
                    return new RequestDelete(properties);
                };

                /**
                 * Encodes the specified RequestDelete message. Does not implicitly {@link printcloud.digital.supervised.RequestDelete.verify|verify} messages.
                 * @function encode
                 * @memberof printcloud.digital.supervised.RequestDelete
                 * @static
                 * @param {printcloud.digital.supervised.IRequestDelete} message RequestDelete message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RequestDelete.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.job_uri != null && Object.hasOwnProperty.call(message, "job_uri"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.job_uri);
                    return writer;
                };

                /**
                 * Encodes the specified RequestDelete message, length delimited. Does not implicitly {@link printcloud.digital.supervised.RequestDelete.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof printcloud.digital.supervised.RequestDelete
                 * @static
                 * @param {printcloud.digital.supervised.IRequestDelete} message RequestDelete message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RequestDelete.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a RequestDelete message from the specified reader or buffer.
                 * @function decode
                 * @memberof printcloud.digital.supervised.RequestDelete
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {printcloud.digital.supervised.RequestDelete} RequestDelete
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RequestDelete.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.printcloud.digital.supervised.RequestDelete();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.job_uri = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a RequestDelete message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof printcloud.digital.supervised.RequestDelete
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {printcloud.digital.supervised.RequestDelete} RequestDelete
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RequestDelete.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a RequestDelete message.
                 * @function verify
                 * @memberof printcloud.digital.supervised.RequestDelete
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RequestDelete.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.job_uri != null && message.hasOwnProperty("job_uri"))
                        if (!$util.isString(message.job_uri))
                            return "job_uri: string expected";
                    return null;
                };

                /**
                 * Creates a RequestDelete message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof printcloud.digital.supervised.RequestDelete
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {printcloud.digital.supervised.RequestDelete} RequestDelete
                 */
                RequestDelete.fromObject = function fromObject(object) {
                    if (object instanceof $root.printcloud.digital.supervised.RequestDelete)
                        return object;
                    let message = new $root.printcloud.digital.supervised.RequestDelete();
                    if (object.job_uri != null)
                        message.job_uri = String(object.job_uri);
                    return message;
                };

                /**
                 * Creates a plain object from a RequestDelete message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof printcloud.digital.supervised.RequestDelete
                 * @static
                 * @param {printcloud.digital.supervised.RequestDelete} message RequestDelete
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RequestDelete.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.job_uri = "";
                    if (message.job_uri != null && message.hasOwnProperty("job_uri"))
                        object.job_uri = message.job_uri;
                    return object;
                };

                /**
                 * Converts this RequestDelete to JSON.
                 * @function toJSON
                 * @memberof printcloud.digital.supervised.RequestDelete
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RequestDelete.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return RequestDelete;
            })();

            supervised.ResponseDelete = (function() {

                /**
                 * Properties of a ResponseDelete.
                 * @memberof printcloud.digital.supervised
                 * @interface IResponseDelete
                 * @property {printcloud.digital.supervised.ResponseDelete.Status|null} [status] ResponseDelete status
                 */

                /**
                 * Constructs a new ResponseDelete.
                 * @memberof printcloud.digital.supervised
                 * @classdesc Represents a ResponseDelete.
                 * @implements IResponseDelete
                 * @constructor
                 * @param {printcloud.digital.supervised.IResponseDelete=} [properties] Properties to set
                 */
                function ResponseDelete(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ResponseDelete status.
                 * @member {printcloud.digital.supervised.ResponseDelete.Status} status
                 * @memberof printcloud.digital.supervised.ResponseDelete
                 * @instance
                 */
                ResponseDelete.prototype.status = 0;

                /**
                 * Creates a new ResponseDelete instance using the specified properties.
                 * @function create
                 * @memberof printcloud.digital.supervised.ResponseDelete
                 * @static
                 * @param {printcloud.digital.supervised.IResponseDelete=} [properties] Properties to set
                 * @returns {printcloud.digital.supervised.ResponseDelete} ResponseDelete instance
                 */
                ResponseDelete.create = function create(properties) {
                    return new ResponseDelete(properties);
                };

                /**
                 * Encodes the specified ResponseDelete message. Does not implicitly {@link printcloud.digital.supervised.ResponseDelete.verify|verify} messages.
                 * @function encode
                 * @memberof printcloud.digital.supervised.ResponseDelete
                 * @static
                 * @param {printcloud.digital.supervised.IResponseDelete} message ResponseDelete message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResponseDelete.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
                    return writer;
                };

                /**
                 * Encodes the specified ResponseDelete message, length delimited. Does not implicitly {@link printcloud.digital.supervised.ResponseDelete.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof printcloud.digital.supervised.ResponseDelete
                 * @static
                 * @param {printcloud.digital.supervised.IResponseDelete} message ResponseDelete message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResponseDelete.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ResponseDelete message from the specified reader or buffer.
                 * @function decode
                 * @memberof printcloud.digital.supervised.ResponseDelete
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {printcloud.digital.supervised.ResponseDelete} ResponseDelete
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResponseDelete.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.printcloud.digital.supervised.ResponseDelete();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.status = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ResponseDelete message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof printcloud.digital.supervised.ResponseDelete
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {printcloud.digital.supervised.ResponseDelete} ResponseDelete
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResponseDelete.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ResponseDelete message.
                 * @function verify
                 * @memberof printcloud.digital.supervised.ResponseDelete
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ResponseDelete.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.status != null && message.hasOwnProperty("status"))
                        switch (message.status) {
                        default:
                            return "status: enum value expected";
                        case 0:
                        case 1:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates a ResponseDelete message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof printcloud.digital.supervised.ResponseDelete
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {printcloud.digital.supervised.ResponseDelete} ResponseDelete
                 */
                ResponseDelete.fromObject = function fromObject(object) {
                    if (object instanceof $root.printcloud.digital.supervised.ResponseDelete)
                        return object;
                    let message = new $root.printcloud.digital.supervised.ResponseDelete();
                    switch (object.status) {
                    case "DELETED":
                    case 0:
                        message.status = 0;
                        break;
                    case "ERROR":
                    case 1:
                        message.status = 1;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ResponseDelete message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof printcloud.digital.supervised.ResponseDelete
                 * @static
                 * @param {printcloud.digital.supervised.ResponseDelete} message ResponseDelete
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ResponseDelete.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.status = options.enums === String ? "DELETED" : 0;
                    if (message.status != null && message.hasOwnProperty("status"))
                        object.status = options.enums === String ? $root.printcloud.digital.supervised.ResponseDelete.Status[message.status] : message.status;
                    return object;
                };

                /**
                 * Converts this ResponseDelete to JSON.
                 * @function toJSON
                 * @memberof printcloud.digital.supervised.ResponseDelete
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ResponseDelete.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Status enum.
                 * @name printcloud.digital.supervised.ResponseDelete.Status
                 * @enum {number}
                 * @property {number} DELETED=0 DELETED value
                 * @property {number} ERROR=1 ERROR value
                 */
                ResponseDelete.Status = (function() {
                    const valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "DELETED"] = 0;
                    values[valuesById[1] = "ERROR"] = 1;
                    return values;
                })();

                return ResponseDelete;
            })();

            supervised.QueryService = (function() {

                /**
                 * Properties of a QueryService.
                 * @memberof printcloud.digital.supervised
                 * @interface IQueryService
                 * @property {number|null} [updates_millisec] QueryService updates_millisec
                 */

                /**
                 * Constructs a new QueryService.
                 * @memberof printcloud.digital.supervised
                 * @classdesc Represents a QueryService.
                 * @implements IQueryService
                 * @constructor
                 * @param {printcloud.digital.supervised.IQueryService=} [properties] Properties to set
                 */
                function QueryService(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * QueryService updates_millisec.
                 * @member {number} updates_millisec
                 * @memberof printcloud.digital.supervised.QueryService
                 * @instance
                 */
                QueryService.prototype.updates_millisec = 0;

                /**
                 * Creates a new QueryService instance using the specified properties.
                 * @function create
                 * @memberof printcloud.digital.supervised.QueryService
                 * @static
                 * @param {printcloud.digital.supervised.IQueryService=} [properties] Properties to set
                 * @returns {printcloud.digital.supervised.QueryService} QueryService instance
                 */
                QueryService.create = function create(properties) {
                    return new QueryService(properties);
                };

                /**
                 * Encodes the specified QueryService message. Does not implicitly {@link printcloud.digital.supervised.QueryService.verify|verify} messages.
                 * @function encode
                 * @memberof printcloud.digital.supervised.QueryService
                 * @static
                 * @param {printcloud.digital.supervised.IQueryService} message QueryService message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                QueryService.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.updates_millisec != null && Object.hasOwnProperty.call(message, "updates_millisec"))
                        writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.updates_millisec);
                    return writer;
                };

                /**
                 * Encodes the specified QueryService message, length delimited. Does not implicitly {@link printcloud.digital.supervised.QueryService.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof printcloud.digital.supervised.QueryService
                 * @static
                 * @param {printcloud.digital.supervised.IQueryService} message QueryService message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                QueryService.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a QueryService message from the specified reader or buffer.
                 * @function decode
                 * @memberof printcloud.digital.supervised.QueryService
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {printcloud.digital.supervised.QueryService} QueryService
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                QueryService.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.printcloud.digital.supervised.QueryService();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.updates_millisec = reader.uint32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a QueryService message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof printcloud.digital.supervised.QueryService
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {printcloud.digital.supervised.QueryService} QueryService
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                QueryService.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a QueryService message.
                 * @function verify
                 * @memberof printcloud.digital.supervised.QueryService
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                QueryService.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.updates_millisec != null && message.hasOwnProperty("updates_millisec"))
                        if (!$util.isInteger(message.updates_millisec))
                            return "updates_millisec: integer expected";
                    return null;
                };

                /**
                 * Creates a QueryService message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof printcloud.digital.supervised.QueryService
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {printcloud.digital.supervised.QueryService} QueryService
                 */
                QueryService.fromObject = function fromObject(object) {
                    if (object instanceof $root.printcloud.digital.supervised.QueryService)
                        return object;
                    let message = new $root.printcloud.digital.supervised.QueryService();
                    if (object.updates_millisec != null)
                        message.updates_millisec = object.updates_millisec >>> 0;
                    return message;
                };

                /**
                 * Creates a plain object from a QueryService message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof printcloud.digital.supervised.QueryService
                 * @static
                 * @param {printcloud.digital.supervised.QueryService} message QueryService
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                QueryService.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.updates_millisec = 0;
                    if (message.updates_millisec != null && message.hasOwnProperty("updates_millisec"))
                        object.updates_millisec = message.updates_millisec;
                    return object;
                };

                /**
                 * Converts this QueryService to JSON.
                 * @function toJSON
                 * @memberof printcloud.digital.supervised.QueryService
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                QueryService.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return QueryService;
            })();

            supervised.InfoJobReceived = (function() {

                /**
                 * Properties of an InfoJobReceived.
                 * @memberof printcloud.digital.supervised
                 * @interface IInfoJobReceived
                 * @property {string|null} [job_uri] InfoJobReceived job_uri
                 */

                /**
                 * Constructs a new InfoJobReceived.
                 * @memberof printcloud.digital.supervised
                 * @classdesc Represents an InfoJobReceived.
                 * @implements IInfoJobReceived
                 * @constructor
                 * @param {printcloud.digital.supervised.IInfoJobReceived=} [properties] Properties to set
                 */
                function InfoJobReceived(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * InfoJobReceived job_uri.
                 * @member {string} job_uri
                 * @memberof printcloud.digital.supervised.InfoJobReceived
                 * @instance
                 */
                InfoJobReceived.prototype.job_uri = "";

                /**
                 * Creates a new InfoJobReceived instance using the specified properties.
                 * @function create
                 * @memberof printcloud.digital.supervised.InfoJobReceived
                 * @static
                 * @param {printcloud.digital.supervised.IInfoJobReceived=} [properties] Properties to set
                 * @returns {printcloud.digital.supervised.InfoJobReceived} InfoJobReceived instance
                 */
                InfoJobReceived.create = function create(properties) {
                    return new InfoJobReceived(properties);
                };

                /**
                 * Encodes the specified InfoJobReceived message. Does not implicitly {@link printcloud.digital.supervised.InfoJobReceived.verify|verify} messages.
                 * @function encode
                 * @memberof printcloud.digital.supervised.InfoJobReceived
                 * @static
                 * @param {printcloud.digital.supervised.IInfoJobReceived} message InfoJobReceived message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                InfoJobReceived.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.job_uri != null && Object.hasOwnProperty.call(message, "job_uri"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.job_uri);
                    return writer;
                };

                /**
                 * Encodes the specified InfoJobReceived message, length delimited. Does not implicitly {@link printcloud.digital.supervised.InfoJobReceived.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof printcloud.digital.supervised.InfoJobReceived
                 * @static
                 * @param {printcloud.digital.supervised.IInfoJobReceived} message InfoJobReceived message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                InfoJobReceived.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an InfoJobReceived message from the specified reader or buffer.
                 * @function decode
                 * @memberof printcloud.digital.supervised.InfoJobReceived
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {printcloud.digital.supervised.InfoJobReceived} InfoJobReceived
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                InfoJobReceived.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.printcloud.digital.supervised.InfoJobReceived();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.job_uri = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an InfoJobReceived message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof printcloud.digital.supervised.InfoJobReceived
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {printcloud.digital.supervised.InfoJobReceived} InfoJobReceived
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                InfoJobReceived.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an InfoJobReceived message.
                 * @function verify
                 * @memberof printcloud.digital.supervised.InfoJobReceived
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                InfoJobReceived.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.job_uri != null && message.hasOwnProperty("job_uri"))
                        if (!$util.isString(message.job_uri))
                            return "job_uri: string expected";
                    return null;
                };

                /**
                 * Creates an InfoJobReceived message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof printcloud.digital.supervised.InfoJobReceived
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {printcloud.digital.supervised.InfoJobReceived} InfoJobReceived
                 */
                InfoJobReceived.fromObject = function fromObject(object) {
                    if (object instanceof $root.printcloud.digital.supervised.InfoJobReceived)
                        return object;
                    let message = new $root.printcloud.digital.supervised.InfoJobReceived();
                    if (object.job_uri != null)
                        message.job_uri = String(object.job_uri);
                    return message;
                };

                /**
                 * Creates a plain object from an InfoJobReceived message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof printcloud.digital.supervised.InfoJobReceived
                 * @static
                 * @param {printcloud.digital.supervised.InfoJobReceived} message InfoJobReceived
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                InfoJobReceived.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.job_uri = "";
                    if (message.job_uri != null && message.hasOwnProperty("job_uri"))
                        object.job_uri = message.job_uri;
                    return object;
                };

                /**
                 * Converts this InfoJobReceived to JSON.
                 * @function toJSON
                 * @memberof printcloud.digital.supervised.InfoJobReceived
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                InfoJobReceived.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return InfoJobReceived;
            })();

            supervised.RequestStat = (function() {

                /**
                 * Properties of a RequestStat.
                 * @memberof printcloud.digital.supervised
                 * @interface IRequestStat
                 * @property {string|null} [uri_job] RequestStat uri_job
                 */

                /**
                 * Constructs a new RequestStat.
                 * @memberof printcloud.digital.supervised
                 * @classdesc Represents a RequestStat.
                 * @implements IRequestStat
                 * @constructor
                 * @param {printcloud.digital.supervised.IRequestStat=} [properties] Properties to set
                 */
                function RequestStat(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * RequestStat uri_job.
                 * @member {string} uri_job
                 * @memberof printcloud.digital.supervised.RequestStat
                 * @instance
                 */
                RequestStat.prototype.uri_job = "";

                /**
                 * Creates a new RequestStat instance using the specified properties.
                 * @function create
                 * @memberof printcloud.digital.supervised.RequestStat
                 * @static
                 * @param {printcloud.digital.supervised.IRequestStat=} [properties] Properties to set
                 * @returns {printcloud.digital.supervised.RequestStat} RequestStat instance
                 */
                RequestStat.create = function create(properties) {
                    return new RequestStat(properties);
                };

                /**
                 * Encodes the specified RequestStat message. Does not implicitly {@link printcloud.digital.supervised.RequestStat.verify|verify} messages.
                 * @function encode
                 * @memberof printcloud.digital.supervised.RequestStat
                 * @static
                 * @param {printcloud.digital.supervised.IRequestStat} message RequestStat message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RequestStat.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.uri_job != null && Object.hasOwnProperty.call(message, "uri_job"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.uri_job);
                    return writer;
                };

                /**
                 * Encodes the specified RequestStat message, length delimited. Does not implicitly {@link printcloud.digital.supervised.RequestStat.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof printcloud.digital.supervised.RequestStat
                 * @static
                 * @param {printcloud.digital.supervised.IRequestStat} message RequestStat message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RequestStat.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a RequestStat message from the specified reader or buffer.
                 * @function decode
                 * @memberof printcloud.digital.supervised.RequestStat
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {printcloud.digital.supervised.RequestStat} RequestStat
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RequestStat.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.printcloud.digital.supervised.RequestStat();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.uri_job = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a RequestStat message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof printcloud.digital.supervised.RequestStat
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {printcloud.digital.supervised.RequestStat} RequestStat
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RequestStat.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a RequestStat message.
                 * @function verify
                 * @memberof printcloud.digital.supervised.RequestStat
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RequestStat.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.uri_job != null && message.hasOwnProperty("uri_job"))
                        if (!$util.isString(message.uri_job))
                            return "uri_job: string expected";
                    return null;
                };

                /**
                 * Creates a RequestStat message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof printcloud.digital.supervised.RequestStat
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {printcloud.digital.supervised.RequestStat} RequestStat
                 */
                RequestStat.fromObject = function fromObject(object) {
                    if (object instanceof $root.printcloud.digital.supervised.RequestStat)
                        return object;
                    let message = new $root.printcloud.digital.supervised.RequestStat();
                    if (object.uri_job != null)
                        message.uri_job = String(object.uri_job);
                    return message;
                };

                /**
                 * Creates a plain object from a RequestStat message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof printcloud.digital.supervised.RequestStat
                 * @static
                 * @param {printcloud.digital.supervised.RequestStat} message RequestStat
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RequestStat.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.uri_job = "";
                    if (message.uri_job != null && message.hasOwnProperty("uri_job"))
                        object.uri_job = message.uri_job;
                    return object;
                };

                /**
                 * Converts this RequestStat to JSON.
                 * @function toJSON
                 * @memberof printcloud.digital.supervised.RequestStat
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RequestStat.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return RequestStat;
            })();

            supervised.StatTrf = (function() {

                /**
                 * Properties of a StatTrf.
                 * @memberof printcloud.digital.supervised
                 * @interface IStatTrf
                 * @property {number|null} [page] StatTrf page
                 * @property {number|null} [slice] StatTrf slice
                 * @property {number|null} [band] StatTrf band
                 * @property {number|null} [header_version] StatTrf header_version
                 * @property {number|null} [compression_id] StatTrf compression_id
                 * @property {number|null} [compression_variant] StatTrf compression_variant
                 * @property {number|null} [dpi_x] StatTrf dpi_x
                 * @property {number|null} [dpi_y] StatTrf dpi_y
                 * @property {number|null} [image_width] StatTrf image_width
                 * @property {number|null} [image_height] StatTrf image_height
                 * @property {number|null} [padded_width] StatTrf padded_width
                 * @property {number|null} [padded_height] StatTrf padded_height
                 * @property {number|null} [pixel_set] StatTrf pixel_set
                 * @property {number|null} [bit_padding] StatTrf bit_padding
                 * @property {number|null} [row_padding] StatTrf row_padding
                 * @property {number|Long|null} [data_size] StatTrf data_size
                 * @property {number|Long|null} [c_drops] StatTrf c_drops
                 * @property {number|Long|null} [m_drops] StatTrf m_drops
                 * @property {number|Long|null} [y_drops] StatTrf y_drops
                 * @property {number|Long|null} [k_drops] StatTrf k_drops
                 */

                /**
                 * Constructs a new StatTrf.
                 * @memberof printcloud.digital.supervised
                 * @classdesc Represents a StatTrf.
                 * @implements IStatTrf
                 * @constructor
                 * @param {printcloud.digital.supervised.IStatTrf=} [properties] Properties to set
                 */
                function StatTrf(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * StatTrf page.
                 * @member {number} page
                 * @memberof printcloud.digital.supervised.StatTrf
                 * @instance
                 */
                StatTrf.prototype.page = 0;

                /**
                 * StatTrf slice.
                 * @member {number} slice
                 * @memberof printcloud.digital.supervised.StatTrf
                 * @instance
                 */
                StatTrf.prototype.slice = 0;

                /**
                 * StatTrf band.
                 * @member {number} band
                 * @memberof printcloud.digital.supervised.StatTrf
                 * @instance
                 */
                StatTrf.prototype.band = 0;

                /**
                 * StatTrf header_version.
                 * @member {number} header_version
                 * @memberof printcloud.digital.supervised.StatTrf
                 * @instance
                 */
                StatTrf.prototype.header_version = 0;

                /**
                 * StatTrf compression_id.
                 * @member {number} compression_id
                 * @memberof printcloud.digital.supervised.StatTrf
                 * @instance
                 */
                StatTrf.prototype.compression_id = 0;

                /**
                 * StatTrf compression_variant.
                 * @member {number} compression_variant
                 * @memberof printcloud.digital.supervised.StatTrf
                 * @instance
                 */
                StatTrf.prototype.compression_variant = 0;

                /**
                 * StatTrf dpi_x.
                 * @member {number} dpi_x
                 * @memberof printcloud.digital.supervised.StatTrf
                 * @instance
                 */
                StatTrf.prototype.dpi_x = 0;

                /**
                 * StatTrf dpi_y.
                 * @member {number} dpi_y
                 * @memberof printcloud.digital.supervised.StatTrf
                 * @instance
                 */
                StatTrf.prototype.dpi_y = 0;

                /**
                 * StatTrf image_width.
                 * @member {number} image_width
                 * @memberof printcloud.digital.supervised.StatTrf
                 * @instance
                 */
                StatTrf.prototype.image_width = 0;

                /**
                 * StatTrf image_height.
                 * @member {number} image_height
                 * @memberof printcloud.digital.supervised.StatTrf
                 * @instance
                 */
                StatTrf.prototype.image_height = 0;

                /**
                 * StatTrf padded_width.
                 * @member {number} padded_width
                 * @memberof printcloud.digital.supervised.StatTrf
                 * @instance
                 */
                StatTrf.prototype.padded_width = 0;

                /**
                 * StatTrf padded_height.
                 * @member {number} padded_height
                 * @memberof printcloud.digital.supervised.StatTrf
                 * @instance
                 */
                StatTrf.prototype.padded_height = 0;

                /**
                 * StatTrf pixel_set.
                 * @member {number} pixel_set
                 * @memberof printcloud.digital.supervised.StatTrf
                 * @instance
                 */
                StatTrf.prototype.pixel_set = 0;

                /**
                 * StatTrf bit_padding.
                 * @member {number} bit_padding
                 * @memberof printcloud.digital.supervised.StatTrf
                 * @instance
                 */
                StatTrf.prototype.bit_padding = 0;

                /**
                 * StatTrf row_padding.
                 * @member {number} row_padding
                 * @memberof printcloud.digital.supervised.StatTrf
                 * @instance
                 */
                StatTrf.prototype.row_padding = 0;

                /**
                 * StatTrf data_size.
                 * @member {number|Long} data_size
                 * @memberof printcloud.digital.supervised.StatTrf
                 * @instance
                 */
                StatTrf.prototype.data_size = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                /**
                 * StatTrf c_drops.
                 * @member {number|Long} c_drops
                 * @memberof printcloud.digital.supervised.StatTrf
                 * @instance
                 */
                StatTrf.prototype.c_drops = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                /**
                 * StatTrf m_drops.
                 * @member {number|Long} m_drops
                 * @memberof printcloud.digital.supervised.StatTrf
                 * @instance
                 */
                StatTrf.prototype.m_drops = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                /**
                 * StatTrf y_drops.
                 * @member {number|Long} y_drops
                 * @memberof printcloud.digital.supervised.StatTrf
                 * @instance
                 */
                StatTrf.prototype.y_drops = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                /**
                 * StatTrf k_drops.
                 * @member {number|Long} k_drops
                 * @memberof printcloud.digital.supervised.StatTrf
                 * @instance
                 */
                StatTrf.prototype.k_drops = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                /**
                 * Creates a new StatTrf instance using the specified properties.
                 * @function create
                 * @memberof printcloud.digital.supervised.StatTrf
                 * @static
                 * @param {printcloud.digital.supervised.IStatTrf=} [properties] Properties to set
                 * @returns {printcloud.digital.supervised.StatTrf} StatTrf instance
                 */
                StatTrf.create = function create(properties) {
                    return new StatTrf(properties);
                };

                /**
                 * Encodes the specified StatTrf message. Does not implicitly {@link printcloud.digital.supervised.StatTrf.verify|verify} messages.
                 * @function encode
                 * @memberof printcloud.digital.supervised.StatTrf
                 * @static
                 * @param {printcloud.digital.supervised.IStatTrf} message StatTrf message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StatTrf.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                        writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.page);
                    if (message.slice != null && Object.hasOwnProperty.call(message, "slice"))
                        writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.slice);
                    if (message.band != null && Object.hasOwnProperty.call(message, "band"))
                        writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.band);
                    if (message.header_version != null && Object.hasOwnProperty.call(message, "header_version"))
                        writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.header_version);
                    if (message.compression_id != null && Object.hasOwnProperty.call(message, "compression_id"))
                        writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.compression_id);
                    if (message.compression_variant != null && Object.hasOwnProperty.call(message, "compression_variant"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int32(message.compression_variant);
                    if (message.dpi_x != null && Object.hasOwnProperty.call(message, "dpi_x"))
                        writer.uint32(/* id 7, wireType 0 =*/56).uint32(message.dpi_x);
                    if (message.dpi_y != null && Object.hasOwnProperty.call(message, "dpi_y"))
                        writer.uint32(/* id 8, wireType 0 =*/64).uint32(message.dpi_y);
                    if (message.image_width != null && Object.hasOwnProperty.call(message, "image_width"))
                        writer.uint32(/* id 9, wireType 0 =*/72).uint32(message.image_width);
                    if (message.image_height != null && Object.hasOwnProperty.call(message, "image_height"))
                        writer.uint32(/* id 10, wireType 0 =*/80).uint32(message.image_height);
                    if (message.padded_width != null && Object.hasOwnProperty.call(message, "padded_width"))
                        writer.uint32(/* id 11, wireType 0 =*/88).uint32(message.padded_width);
                    if (message.padded_height != null && Object.hasOwnProperty.call(message, "padded_height"))
                        writer.uint32(/* id 12, wireType 0 =*/96).uint32(message.padded_height);
                    if (message.pixel_set != null && Object.hasOwnProperty.call(message, "pixel_set"))
                        writer.uint32(/* id 13, wireType 0 =*/104).uint32(message.pixel_set);
                    if (message.bit_padding != null && Object.hasOwnProperty.call(message, "bit_padding"))
                        writer.uint32(/* id 14, wireType 0 =*/112).uint32(message.bit_padding);
                    if (message.row_padding != null && Object.hasOwnProperty.call(message, "row_padding"))
                        writer.uint32(/* id 15, wireType 0 =*/120).uint32(message.row_padding);
                    if (message.data_size != null && Object.hasOwnProperty.call(message, "data_size"))
                        writer.uint32(/* id 16, wireType 0 =*/128).uint64(message.data_size);
                    if (message.c_drops != null && Object.hasOwnProperty.call(message, "c_drops"))
                        writer.uint32(/* id 17, wireType 0 =*/136).uint64(message.c_drops);
                    if (message.m_drops != null && Object.hasOwnProperty.call(message, "m_drops"))
                        writer.uint32(/* id 18, wireType 0 =*/144).uint64(message.m_drops);
                    if (message.y_drops != null && Object.hasOwnProperty.call(message, "y_drops"))
                        writer.uint32(/* id 19, wireType 0 =*/152).uint64(message.y_drops);
                    if (message.k_drops != null && Object.hasOwnProperty.call(message, "k_drops"))
                        writer.uint32(/* id 20, wireType 0 =*/160).uint64(message.k_drops);
                    return writer;
                };

                /**
                 * Encodes the specified StatTrf message, length delimited. Does not implicitly {@link printcloud.digital.supervised.StatTrf.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof printcloud.digital.supervised.StatTrf
                 * @static
                 * @param {printcloud.digital.supervised.IStatTrf} message StatTrf message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StatTrf.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a StatTrf message from the specified reader or buffer.
                 * @function decode
                 * @memberof printcloud.digital.supervised.StatTrf
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {printcloud.digital.supervised.StatTrf} StatTrf
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StatTrf.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.printcloud.digital.supervised.StatTrf();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.page = reader.uint32();
                            break;
                        case 2:
                            message.slice = reader.uint32();
                            break;
                        case 3:
                            message.band = reader.uint32();
                            break;
                        case 4:
                            message.header_version = reader.uint32();
                            break;
                        case 5:
                            message.compression_id = reader.uint32();
                            break;
                        case 6:
                            message.compression_variant = reader.int32();
                            break;
                        case 7:
                            message.dpi_x = reader.uint32();
                            break;
                        case 8:
                            message.dpi_y = reader.uint32();
                            break;
                        case 9:
                            message.image_width = reader.uint32();
                            break;
                        case 10:
                            message.image_height = reader.uint32();
                            break;
                        case 11:
                            message.padded_width = reader.uint32();
                            break;
                        case 12:
                            message.padded_height = reader.uint32();
                            break;
                        case 13:
                            message.pixel_set = reader.uint32();
                            break;
                        case 14:
                            message.bit_padding = reader.uint32();
                            break;
                        case 15:
                            message.row_padding = reader.uint32();
                            break;
                        case 16:
                            message.data_size = reader.uint64();
                            break;
                        case 17:
                            message.c_drops = reader.uint64();
                            break;
                        case 18:
                            message.m_drops = reader.uint64();
                            break;
                        case 19:
                            message.y_drops = reader.uint64();
                            break;
                        case 20:
                            message.k_drops = reader.uint64();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a StatTrf message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof printcloud.digital.supervised.StatTrf
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {printcloud.digital.supervised.StatTrf} StatTrf
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StatTrf.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a StatTrf message.
                 * @function verify
                 * @memberof printcloud.digital.supervised.StatTrf
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                StatTrf.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.page != null && message.hasOwnProperty("page"))
                        if (!$util.isInteger(message.page))
                            return "page: integer expected";
                    if (message.slice != null && message.hasOwnProperty("slice"))
                        if (!$util.isInteger(message.slice))
                            return "slice: integer expected";
                    if (message.band != null && message.hasOwnProperty("band"))
                        if (!$util.isInteger(message.band))
                            return "band: integer expected";
                    if (message.header_version != null && message.hasOwnProperty("header_version"))
                        if (!$util.isInteger(message.header_version))
                            return "header_version: integer expected";
                    if (message.compression_id != null && message.hasOwnProperty("compression_id"))
                        if (!$util.isInteger(message.compression_id))
                            return "compression_id: integer expected";
                    if (message.compression_variant != null && message.hasOwnProperty("compression_variant"))
                        if (!$util.isInteger(message.compression_variant))
                            return "compression_variant: integer expected";
                    if (message.dpi_x != null && message.hasOwnProperty("dpi_x"))
                        if (!$util.isInteger(message.dpi_x))
                            return "dpi_x: integer expected";
                    if (message.dpi_y != null && message.hasOwnProperty("dpi_y"))
                        if (!$util.isInteger(message.dpi_y))
                            return "dpi_y: integer expected";
                    if (message.image_width != null && message.hasOwnProperty("image_width"))
                        if (!$util.isInteger(message.image_width))
                            return "image_width: integer expected";
                    if (message.image_height != null && message.hasOwnProperty("image_height"))
                        if (!$util.isInteger(message.image_height))
                            return "image_height: integer expected";
                    if (message.padded_width != null && message.hasOwnProperty("padded_width"))
                        if (!$util.isInteger(message.padded_width))
                            return "padded_width: integer expected";
                    if (message.padded_height != null && message.hasOwnProperty("padded_height"))
                        if (!$util.isInteger(message.padded_height))
                            return "padded_height: integer expected";
                    if (message.pixel_set != null && message.hasOwnProperty("pixel_set"))
                        if (!$util.isInteger(message.pixel_set))
                            return "pixel_set: integer expected";
                    if (message.bit_padding != null && message.hasOwnProperty("bit_padding"))
                        if (!$util.isInteger(message.bit_padding))
                            return "bit_padding: integer expected";
                    if (message.row_padding != null && message.hasOwnProperty("row_padding"))
                        if (!$util.isInteger(message.row_padding))
                            return "row_padding: integer expected";
                    if (message.data_size != null && message.hasOwnProperty("data_size"))
                        if (!$util.isInteger(message.data_size) && !(message.data_size && $util.isInteger(message.data_size.low) && $util.isInteger(message.data_size.high)))
                            return "data_size: integer|Long expected";
                    if (message.c_drops != null && message.hasOwnProperty("c_drops"))
                        if (!$util.isInteger(message.c_drops) && !(message.c_drops && $util.isInteger(message.c_drops.low) && $util.isInteger(message.c_drops.high)))
                            return "c_drops: integer|Long expected";
                    if (message.m_drops != null && message.hasOwnProperty("m_drops"))
                        if (!$util.isInteger(message.m_drops) && !(message.m_drops && $util.isInteger(message.m_drops.low) && $util.isInteger(message.m_drops.high)))
                            return "m_drops: integer|Long expected";
                    if (message.y_drops != null && message.hasOwnProperty("y_drops"))
                        if (!$util.isInteger(message.y_drops) && !(message.y_drops && $util.isInteger(message.y_drops.low) && $util.isInteger(message.y_drops.high)))
                            return "y_drops: integer|Long expected";
                    if (message.k_drops != null && message.hasOwnProperty("k_drops"))
                        if (!$util.isInteger(message.k_drops) && !(message.k_drops && $util.isInteger(message.k_drops.low) && $util.isInteger(message.k_drops.high)))
                            return "k_drops: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a StatTrf message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof printcloud.digital.supervised.StatTrf
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {printcloud.digital.supervised.StatTrf} StatTrf
                 */
                StatTrf.fromObject = function fromObject(object) {
                    if (object instanceof $root.printcloud.digital.supervised.StatTrf)
                        return object;
                    let message = new $root.printcloud.digital.supervised.StatTrf();
                    if (object.page != null)
                        message.page = object.page >>> 0;
                    if (object.slice != null)
                        message.slice = object.slice >>> 0;
                    if (object.band != null)
                        message.band = object.band >>> 0;
                    if (object.header_version != null)
                        message.header_version = object.header_version >>> 0;
                    if (object.compression_id != null)
                        message.compression_id = object.compression_id >>> 0;
                    if (object.compression_variant != null)
                        message.compression_variant = object.compression_variant | 0;
                    if (object.dpi_x != null)
                        message.dpi_x = object.dpi_x >>> 0;
                    if (object.dpi_y != null)
                        message.dpi_y = object.dpi_y >>> 0;
                    if (object.image_width != null)
                        message.image_width = object.image_width >>> 0;
                    if (object.image_height != null)
                        message.image_height = object.image_height >>> 0;
                    if (object.padded_width != null)
                        message.padded_width = object.padded_width >>> 0;
                    if (object.padded_height != null)
                        message.padded_height = object.padded_height >>> 0;
                    if (object.pixel_set != null)
                        message.pixel_set = object.pixel_set >>> 0;
                    if (object.bit_padding != null)
                        message.bit_padding = object.bit_padding >>> 0;
                    if (object.row_padding != null)
                        message.row_padding = object.row_padding >>> 0;
                    if (object.data_size != null)
                        if ($util.Long)
                            (message.data_size = $util.Long.fromValue(object.data_size)).unsigned = true;
                        else if (typeof object.data_size === "string")
                            message.data_size = parseInt(object.data_size, 10);
                        else if (typeof object.data_size === "number")
                            message.data_size = object.data_size;
                        else if (typeof object.data_size === "object")
                            message.data_size = new $util.LongBits(object.data_size.low >>> 0, object.data_size.high >>> 0).toNumber(true);
                    if (object.c_drops != null)
                        if ($util.Long)
                            (message.c_drops = $util.Long.fromValue(object.c_drops)).unsigned = true;
                        else if (typeof object.c_drops === "string")
                            message.c_drops = parseInt(object.c_drops, 10);
                        else if (typeof object.c_drops === "number")
                            message.c_drops = object.c_drops;
                        else if (typeof object.c_drops === "object")
                            message.c_drops = new $util.LongBits(object.c_drops.low >>> 0, object.c_drops.high >>> 0).toNumber(true);
                    if (object.m_drops != null)
                        if ($util.Long)
                            (message.m_drops = $util.Long.fromValue(object.m_drops)).unsigned = true;
                        else if (typeof object.m_drops === "string")
                            message.m_drops = parseInt(object.m_drops, 10);
                        else if (typeof object.m_drops === "number")
                            message.m_drops = object.m_drops;
                        else if (typeof object.m_drops === "object")
                            message.m_drops = new $util.LongBits(object.m_drops.low >>> 0, object.m_drops.high >>> 0).toNumber(true);
                    if (object.y_drops != null)
                        if ($util.Long)
                            (message.y_drops = $util.Long.fromValue(object.y_drops)).unsigned = true;
                        else if (typeof object.y_drops === "string")
                            message.y_drops = parseInt(object.y_drops, 10);
                        else if (typeof object.y_drops === "number")
                            message.y_drops = object.y_drops;
                        else if (typeof object.y_drops === "object")
                            message.y_drops = new $util.LongBits(object.y_drops.low >>> 0, object.y_drops.high >>> 0).toNumber(true);
                    if (object.k_drops != null)
                        if ($util.Long)
                            (message.k_drops = $util.Long.fromValue(object.k_drops)).unsigned = true;
                        else if (typeof object.k_drops === "string")
                            message.k_drops = parseInt(object.k_drops, 10);
                        else if (typeof object.k_drops === "number")
                            message.k_drops = object.k_drops;
                        else if (typeof object.k_drops === "object")
                            message.k_drops = new $util.LongBits(object.k_drops.low >>> 0, object.k_drops.high >>> 0).toNumber(true);
                    return message;
                };

                /**
                 * Creates a plain object from a StatTrf message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof printcloud.digital.supervised.StatTrf
                 * @static
                 * @param {printcloud.digital.supervised.StatTrf} message StatTrf
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                StatTrf.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.page = 0;
                        object.slice = 0;
                        object.band = 0;
                        object.header_version = 0;
                        object.compression_id = 0;
                        object.compression_variant = 0;
                        object.dpi_x = 0;
                        object.dpi_y = 0;
                        object.image_width = 0;
                        object.image_height = 0;
                        object.padded_width = 0;
                        object.padded_height = 0;
                        object.pixel_set = 0;
                        object.bit_padding = 0;
                        object.row_padding = 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, true);
                            object.data_size = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.data_size = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, true);
                            object.c_drops = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.c_drops = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, true);
                            object.m_drops = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.m_drops = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, true);
                            object.y_drops = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.y_drops = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, true);
                            object.k_drops = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.k_drops = options.longs === String ? "0" : 0;
                    }
                    if (message.page != null && message.hasOwnProperty("page"))
                        object.page = message.page;
                    if (message.slice != null && message.hasOwnProperty("slice"))
                        object.slice = message.slice;
                    if (message.band != null && message.hasOwnProperty("band"))
                        object.band = message.band;
                    if (message.header_version != null && message.hasOwnProperty("header_version"))
                        object.header_version = message.header_version;
                    if (message.compression_id != null && message.hasOwnProperty("compression_id"))
                        object.compression_id = message.compression_id;
                    if (message.compression_variant != null && message.hasOwnProperty("compression_variant"))
                        object.compression_variant = message.compression_variant;
                    if (message.dpi_x != null && message.hasOwnProperty("dpi_x"))
                        object.dpi_x = message.dpi_x;
                    if (message.dpi_y != null && message.hasOwnProperty("dpi_y"))
                        object.dpi_y = message.dpi_y;
                    if (message.image_width != null && message.hasOwnProperty("image_width"))
                        object.image_width = message.image_width;
                    if (message.image_height != null && message.hasOwnProperty("image_height"))
                        object.image_height = message.image_height;
                    if (message.padded_width != null && message.hasOwnProperty("padded_width"))
                        object.padded_width = message.padded_width;
                    if (message.padded_height != null && message.hasOwnProperty("padded_height"))
                        object.padded_height = message.padded_height;
                    if (message.pixel_set != null && message.hasOwnProperty("pixel_set"))
                        object.pixel_set = message.pixel_set;
                    if (message.bit_padding != null && message.hasOwnProperty("bit_padding"))
                        object.bit_padding = message.bit_padding;
                    if (message.row_padding != null && message.hasOwnProperty("row_padding"))
                        object.row_padding = message.row_padding;
                    if (message.data_size != null && message.hasOwnProperty("data_size"))
                        if (typeof message.data_size === "number")
                            object.data_size = options.longs === String ? String(message.data_size) : message.data_size;
                        else
                            object.data_size = options.longs === String ? $util.Long.prototype.toString.call(message.data_size) : options.longs === Number ? new $util.LongBits(message.data_size.low >>> 0, message.data_size.high >>> 0).toNumber(true) : message.data_size;
                    if (message.c_drops != null && message.hasOwnProperty("c_drops"))
                        if (typeof message.c_drops === "number")
                            object.c_drops = options.longs === String ? String(message.c_drops) : message.c_drops;
                        else
                            object.c_drops = options.longs === String ? $util.Long.prototype.toString.call(message.c_drops) : options.longs === Number ? new $util.LongBits(message.c_drops.low >>> 0, message.c_drops.high >>> 0).toNumber(true) : message.c_drops;
                    if (message.m_drops != null && message.hasOwnProperty("m_drops"))
                        if (typeof message.m_drops === "number")
                            object.m_drops = options.longs === String ? String(message.m_drops) : message.m_drops;
                        else
                            object.m_drops = options.longs === String ? $util.Long.prototype.toString.call(message.m_drops) : options.longs === Number ? new $util.LongBits(message.m_drops.low >>> 0, message.m_drops.high >>> 0).toNumber(true) : message.m_drops;
                    if (message.y_drops != null && message.hasOwnProperty("y_drops"))
                        if (typeof message.y_drops === "number")
                            object.y_drops = options.longs === String ? String(message.y_drops) : message.y_drops;
                        else
                            object.y_drops = options.longs === String ? $util.Long.prototype.toString.call(message.y_drops) : options.longs === Number ? new $util.LongBits(message.y_drops.low >>> 0, message.y_drops.high >>> 0).toNumber(true) : message.y_drops;
                    if (message.k_drops != null && message.hasOwnProperty("k_drops"))
                        if (typeof message.k_drops === "number")
                            object.k_drops = options.longs === String ? String(message.k_drops) : message.k_drops;
                        else
                            object.k_drops = options.longs === String ? $util.Long.prototype.toString.call(message.k_drops) : options.longs === Number ? new $util.LongBits(message.k_drops.low >>> 0, message.k_drops.high >>> 0).toNumber(true) : message.k_drops;
                    return object;
                };

                /**
                 * Converts this StatTrf to JSON.
                 * @function toJSON
                 * @memberof printcloud.digital.supervised.StatTrf
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                StatTrf.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return StatTrf;
            })();

            supervised.ResponseStat = (function() {

                /**
                 * Properties of a ResponseStat.
                 * @memberof printcloud.digital.supervised
                 * @interface IResponseStat
                 * @property {printcloud.digital.supervised.ResponseStat.Type|null} [type] ResponseStat type
                 * @property {number|null} [page_num] ResponseStat page_num
                 * @property {number|null} [slice_num] ResponseStat slice_num
                 * @property {number|null} [band_num] ResponseStat band_num
                 * @property {appcore.ripe.IJobDescription|null} [job_description] ResponseStat job_description
                 * @property {Array.<printcloud.digital.supervised.IStatTrf>|null} [stat_trf] ResponseStat stat_trf
                 * @property {printcloud.digital.supervised.ResponseStat.Status|null} [status] ResponseStat status
                 */

                /**
                 * Constructs a new ResponseStat.
                 * @memberof printcloud.digital.supervised
                 * @classdesc Represents a ResponseStat.
                 * @implements IResponseStat
                 * @constructor
                 * @param {printcloud.digital.supervised.IResponseStat=} [properties] Properties to set
                 */
                function ResponseStat(properties) {
                    this.stat_trf = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ResponseStat type.
                 * @member {printcloud.digital.supervised.ResponseStat.Type} type
                 * @memberof printcloud.digital.supervised.ResponseStat
                 * @instance
                 */
                ResponseStat.prototype.type = 0;

                /**
                 * ResponseStat page_num.
                 * @member {number} page_num
                 * @memberof printcloud.digital.supervised.ResponseStat
                 * @instance
                 */
                ResponseStat.prototype.page_num = 0;

                /**
                 * ResponseStat slice_num.
                 * @member {number} slice_num
                 * @memberof printcloud.digital.supervised.ResponseStat
                 * @instance
                 */
                ResponseStat.prototype.slice_num = 0;

                /**
                 * ResponseStat band_num.
                 * @member {number} band_num
                 * @memberof printcloud.digital.supervised.ResponseStat
                 * @instance
                 */
                ResponseStat.prototype.band_num = 0;

                /**
                 * ResponseStat job_description.
                 * @member {appcore.ripe.IJobDescription|null|undefined} job_description
                 * @memberof printcloud.digital.supervised.ResponseStat
                 * @instance
                 */
                ResponseStat.prototype.job_description = null;

                /**
                 * ResponseStat stat_trf.
                 * @member {Array.<printcloud.digital.supervised.IStatTrf>} stat_trf
                 * @memberof printcloud.digital.supervised.ResponseStat
                 * @instance
                 */
                ResponseStat.prototype.stat_trf = $util.emptyArray;

                /**
                 * ResponseStat status.
                 * @member {printcloud.digital.supervised.ResponseStat.Status} status
                 * @memberof printcloud.digital.supervised.ResponseStat
                 * @instance
                 */
                ResponseStat.prototype.status = 0;

                /**
                 * Creates a new ResponseStat instance using the specified properties.
                 * @function create
                 * @memberof printcloud.digital.supervised.ResponseStat
                 * @static
                 * @param {printcloud.digital.supervised.IResponseStat=} [properties] Properties to set
                 * @returns {printcloud.digital.supervised.ResponseStat} ResponseStat instance
                 */
                ResponseStat.create = function create(properties) {
                    return new ResponseStat(properties);
                };

                /**
                 * Encodes the specified ResponseStat message. Does not implicitly {@link printcloud.digital.supervised.ResponseStat.verify|verify} messages.
                 * @function encode
                 * @memberof printcloud.digital.supervised.ResponseStat
                 * @static
                 * @param {printcloud.digital.supervised.IResponseStat} message ResponseStat message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResponseStat.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                    if (message.page_num != null && Object.hasOwnProperty.call(message, "page_num"))
                        writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.page_num);
                    if (message.slice_num != null && Object.hasOwnProperty.call(message, "slice_num"))
                        writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.slice_num);
                    if (message.band_num != null && Object.hasOwnProperty.call(message, "band_num"))
                        writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.band_num);
                    if (message.job_description != null && Object.hasOwnProperty.call(message, "job_description"))
                        $root.appcore.ripe.JobDescription.encode(message.job_description, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.stat_trf != null && message.stat_trf.length)
                        for (let i = 0; i < message.stat_trf.length; ++i)
                            $root.printcloud.digital.supervised.StatTrf.encode(message.stat_trf[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                        writer.uint32(/* id 7, wireType 0 =*/56).int32(message.status);
                    return writer;
                };

                /**
                 * Encodes the specified ResponseStat message, length delimited. Does not implicitly {@link printcloud.digital.supervised.ResponseStat.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof printcloud.digital.supervised.ResponseStat
                 * @static
                 * @param {printcloud.digital.supervised.IResponseStat} message ResponseStat message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResponseStat.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ResponseStat message from the specified reader or buffer.
                 * @function decode
                 * @memberof printcloud.digital.supervised.ResponseStat
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {printcloud.digital.supervised.ResponseStat} ResponseStat
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResponseStat.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.printcloud.digital.supervised.ResponseStat();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.type = reader.int32();
                            break;
                        case 2:
                            message.page_num = reader.uint32();
                            break;
                        case 3:
                            message.slice_num = reader.uint32();
                            break;
                        case 4:
                            message.band_num = reader.uint32();
                            break;
                        case 5:
                            message.job_description = $root.appcore.ripe.JobDescription.decode(reader, reader.uint32());
                            break;
                        case 6:
                            if (!(message.stat_trf && message.stat_trf.length))
                                message.stat_trf = [];
                            message.stat_trf.push($root.printcloud.digital.supervised.StatTrf.decode(reader, reader.uint32()));
                            break;
                        case 7:
                            message.status = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ResponseStat message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof printcloud.digital.supervised.ResponseStat
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {printcloud.digital.supervised.ResponseStat} ResponseStat
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResponseStat.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ResponseStat message.
                 * @function verify
                 * @memberof printcloud.digital.supervised.ResponseStat
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ResponseStat.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.type != null && message.hasOwnProperty("type"))
                        switch (message.type) {
                        default:
                            return "type: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                            break;
                        }
                    if (message.page_num != null && message.hasOwnProperty("page_num"))
                        if (!$util.isInteger(message.page_num))
                            return "page_num: integer expected";
                    if (message.slice_num != null && message.hasOwnProperty("slice_num"))
                        if (!$util.isInteger(message.slice_num))
                            return "slice_num: integer expected";
                    if (message.band_num != null && message.hasOwnProperty("band_num"))
                        if (!$util.isInteger(message.band_num))
                            return "band_num: integer expected";
                    if (message.job_description != null && message.hasOwnProperty("job_description")) {
                        let error = $root.appcore.ripe.JobDescription.verify(message.job_description);
                        if (error)
                            return "job_description." + error;
                    }
                    if (message.stat_trf != null && message.hasOwnProperty("stat_trf")) {
                        if (!Array.isArray(message.stat_trf))
                            return "stat_trf: array expected";
                        for (let i = 0; i < message.stat_trf.length; ++i) {
                            let error = $root.printcloud.digital.supervised.StatTrf.verify(message.stat_trf[i]);
                            if (error)
                                return "stat_trf." + error;
                        }
                    }
                    if (message.status != null && message.hasOwnProperty("status"))
                        switch (message.status) {
                        default:
                            return "status: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates a ResponseStat message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof printcloud.digital.supervised.ResponseStat
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {printcloud.digital.supervised.ResponseStat} ResponseStat
                 */
                ResponseStat.fromObject = function fromObject(object) {
                    if (object instanceof $root.printcloud.digital.supervised.ResponseStat)
                        return object;
                    let message = new $root.printcloud.digital.supervised.ResponseStat();
                    switch (object.type) {
                    case "GBOR_ZS":
                    case 0:
                        message.type = 0;
                        break;
                    case "PDF":
                    case 1:
                        message.type = 1;
                        break;
                    case "PDF_ZS":
                    case 2:
                        message.type = 2;
                        break;
                    case "PCL":
                    case 3:
                        message.type = 3;
                        break;
                    case "PCL_ZS":
                    case 4:
                        message.type = 4;
                        break;
                    case "ZX":
                    case 5:
                        message.type = 5;
                        break;
                    case "ZX_ZS":
                    case 6:
                        message.type = 6;
                        break;
                    }
                    if (object.page_num != null)
                        message.page_num = object.page_num >>> 0;
                    if (object.slice_num != null)
                        message.slice_num = object.slice_num >>> 0;
                    if (object.band_num != null)
                        message.band_num = object.band_num >>> 0;
                    if (object.job_description != null) {
                        if (typeof object.job_description !== "object")
                            throw TypeError(".printcloud.digital.supervised.ResponseStat.job_description: object expected");
                        message.job_description = $root.appcore.ripe.JobDescription.fromObject(object.job_description);
                    }
                    if (object.stat_trf) {
                        if (!Array.isArray(object.stat_trf))
                            throw TypeError(".printcloud.digital.supervised.ResponseStat.stat_trf: array expected");
                        message.stat_trf = [];
                        for (let i = 0; i < object.stat_trf.length; ++i) {
                            if (typeof object.stat_trf[i] !== "object")
                                throw TypeError(".printcloud.digital.supervised.ResponseStat.stat_trf: object expected");
                            message.stat_trf[i] = $root.printcloud.digital.supervised.StatTrf.fromObject(object.stat_trf[i]);
                        }
                    }
                    switch (object.status) {
                    case "OK":
                    case 0:
                        message.status = 0;
                        break;
                    case "ERROR_INVALID_URI":
                    case 1:
                        message.status = 1;
                        break;
                    case "ERROR_LOAD_FAILED":
                    case 2:
                        message.status = 2;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ResponseStat message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof printcloud.digital.supervised.ResponseStat
                 * @static
                 * @param {printcloud.digital.supervised.ResponseStat} message ResponseStat
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ResponseStat.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.stat_trf = [];
                    if (options.defaults) {
                        object.type = options.enums === String ? "GBOR_ZS" : 0;
                        object.page_num = 0;
                        object.slice_num = 0;
                        object.band_num = 0;
                        object.job_description = null;
                        object.status = options.enums === String ? "OK" : 0;
                    }
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = options.enums === String ? $root.printcloud.digital.supervised.ResponseStat.Type[message.type] : message.type;
                    if (message.page_num != null && message.hasOwnProperty("page_num"))
                        object.page_num = message.page_num;
                    if (message.slice_num != null && message.hasOwnProperty("slice_num"))
                        object.slice_num = message.slice_num;
                    if (message.band_num != null && message.hasOwnProperty("band_num"))
                        object.band_num = message.band_num;
                    if (message.job_description != null && message.hasOwnProperty("job_description"))
                        object.job_description = $root.appcore.ripe.JobDescription.toObject(message.job_description, options);
                    if (message.stat_trf && message.stat_trf.length) {
                        object.stat_trf = [];
                        for (let j = 0; j < message.stat_trf.length; ++j)
                            object.stat_trf[j] = $root.printcloud.digital.supervised.StatTrf.toObject(message.stat_trf[j], options);
                    }
                    if (message.status != null && message.hasOwnProperty("status"))
                        object.status = options.enums === String ? $root.printcloud.digital.supervised.ResponseStat.Status[message.status] : message.status;
                    return object;
                };

                /**
                 * Converts this ResponseStat to JSON.
                 * @function toJSON
                 * @memberof printcloud.digital.supervised.ResponseStat
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ResponseStat.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Status enum.
                 * @name printcloud.digital.supervised.ResponseStat.Status
                 * @enum {number}
                 * @property {number} OK=0 OK value
                 * @property {number} ERROR_INVALID_URI=1 ERROR_INVALID_URI value
                 * @property {number} ERROR_LOAD_FAILED=2 ERROR_LOAD_FAILED value
                 */
                ResponseStat.Status = (function() {
                    const valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "OK"] = 0;
                    values[valuesById[1] = "ERROR_INVALID_URI"] = 1;
                    values[valuesById[2] = "ERROR_LOAD_FAILED"] = 2;
                    return values;
                })();

                /**
                 * Type enum.
                 * @name printcloud.digital.supervised.ResponseStat.Type
                 * @enum {number}
                 * @property {number} GBOR_ZS=0 GBOR_ZS value
                 * @property {number} PDF=1 PDF value
                 * @property {number} PDF_ZS=2 PDF_ZS value
                 * @property {number} PCL=3 PCL value
                 * @property {number} PCL_ZS=4 PCL_ZS value
                 * @property {number} ZX=5 ZX value
                 * @property {number} ZX_ZS=6 ZX_ZS value
                 */
                ResponseStat.Type = (function() {
                    const valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "GBOR_ZS"] = 0;
                    values[valuesById[1] = "PDF"] = 1;
                    values[valuesById[2] = "PDF_ZS"] = 2;
                    values[valuesById[3] = "PCL"] = 3;
                    values[valuesById[4] = "PCL_ZS"] = 4;
                    values[valuesById[5] = "ZX"] = 5;
                    values[valuesById[6] = "ZX_ZS"] = 6;
                    return values;
                })();

                return ResponseStat;
            })();

            supervised.InfoService = (function() {

                /**
                 * Properties of an InfoService.
                 * @memberof printcloud.digital.supervised
                 * @interface IInfoService
                 * @property {string|null} [config_name] InfoService config_name
                 * @property {printcloud.digital.supervised.InfoService.Status|null} [status] InfoService status
                 * @property {printcloud.digital.supervised.IInfoJobReceived|null} [info_job_received] InfoService info_job_received
                 */

                /**
                 * Constructs a new InfoService.
                 * @memberof printcloud.digital.supervised
                 * @classdesc Represents an InfoService.
                 * @implements IInfoService
                 * @constructor
                 * @param {printcloud.digital.supervised.IInfoService=} [properties] Properties to set
                 */
                function InfoService(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * InfoService config_name.
                 * @member {string} config_name
                 * @memberof printcloud.digital.supervised.InfoService
                 * @instance
                 */
                InfoService.prototype.config_name = "";

                /**
                 * InfoService status.
                 * @member {printcloud.digital.supervised.InfoService.Status} status
                 * @memberof printcloud.digital.supervised.InfoService
                 * @instance
                 */
                InfoService.prototype.status = 0;

                /**
                 * InfoService info_job_received.
                 * @member {printcloud.digital.supervised.IInfoJobReceived|null|undefined} info_job_received
                 * @memberof printcloud.digital.supervised.InfoService
                 * @instance
                 */
                InfoService.prototype.info_job_received = null;

                /**
                 * Creates a new InfoService instance using the specified properties.
                 * @function create
                 * @memberof printcloud.digital.supervised.InfoService
                 * @static
                 * @param {printcloud.digital.supervised.IInfoService=} [properties] Properties to set
                 * @returns {printcloud.digital.supervised.InfoService} InfoService instance
                 */
                InfoService.create = function create(properties) {
                    return new InfoService(properties);
                };

                /**
                 * Encodes the specified InfoService message. Does not implicitly {@link printcloud.digital.supervised.InfoService.verify|verify} messages.
                 * @function encode
                 * @memberof printcloud.digital.supervised.InfoService
                 * @static
                 * @param {printcloud.digital.supervised.IInfoService} message InfoService message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                InfoService.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.config_name != null && Object.hasOwnProperty.call(message, "config_name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.config_name);
                    if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.status);
                    if (message.info_job_received != null && Object.hasOwnProperty.call(message, "info_job_received"))
                        $root.printcloud.digital.supervised.InfoJobReceived.encode(message.info_job_received, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified InfoService message, length delimited. Does not implicitly {@link printcloud.digital.supervised.InfoService.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof printcloud.digital.supervised.InfoService
                 * @static
                 * @param {printcloud.digital.supervised.IInfoService} message InfoService message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                InfoService.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an InfoService message from the specified reader or buffer.
                 * @function decode
                 * @memberof printcloud.digital.supervised.InfoService
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {printcloud.digital.supervised.InfoService} InfoService
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                InfoService.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.printcloud.digital.supervised.InfoService();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.config_name = reader.string();
                            break;
                        case 2:
                            message.status = reader.int32();
                            break;
                        case 3:
                            message.info_job_received = $root.printcloud.digital.supervised.InfoJobReceived.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an InfoService message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof printcloud.digital.supervised.InfoService
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {printcloud.digital.supervised.InfoService} InfoService
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                InfoService.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an InfoService message.
                 * @function verify
                 * @memberof printcloud.digital.supervised.InfoService
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                InfoService.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.config_name != null && message.hasOwnProperty("config_name"))
                        if (!$util.isString(message.config_name))
                            return "config_name: string expected";
                    if (message.status != null && message.hasOwnProperty("status"))
                        switch (message.status) {
                        default:
                            return "status: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.info_job_received != null && message.hasOwnProperty("info_job_received")) {
                        let error = $root.printcloud.digital.supervised.InfoJobReceived.verify(message.info_job_received);
                        if (error)
                            return "info_job_received." + error;
                    }
                    return null;
                };

                /**
                 * Creates an InfoService message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof printcloud.digital.supervised.InfoService
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {printcloud.digital.supervised.InfoService} InfoService
                 */
                InfoService.fromObject = function fromObject(object) {
                    if (object instanceof $root.printcloud.digital.supervised.InfoService)
                        return object;
                    let message = new $root.printcloud.digital.supervised.InfoService();
                    if (object.config_name != null)
                        message.config_name = String(object.config_name);
                    switch (object.status) {
                    case "IDLE":
                    case 0:
                        message.status = 0;
                        break;
                    case "PRINTING":
                    case 1:
                        message.status = 1;
                        break;
                    case "ERROR":
                    case 2:
                        message.status = 2;
                        break;
                    }
                    if (object.info_job_received != null) {
                        if (typeof object.info_job_received !== "object")
                            throw TypeError(".printcloud.digital.supervised.InfoService.info_job_received: object expected");
                        message.info_job_received = $root.printcloud.digital.supervised.InfoJobReceived.fromObject(object.info_job_received);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an InfoService message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof printcloud.digital.supervised.InfoService
                 * @static
                 * @param {printcloud.digital.supervised.InfoService} message InfoService
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                InfoService.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.config_name = "";
                        object.status = options.enums === String ? "IDLE" : 0;
                        object.info_job_received = null;
                    }
                    if (message.config_name != null && message.hasOwnProperty("config_name"))
                        object.config_name = message.config_name;
                    if (message.status != null && message.hasOwnProperty("status"))
                        object.status = options.enums === String ? $root.printcloud.digital.supervised.InfoService.Status[message.status] : message.status;
                    if (message.info_job_received != null && message.hasOwnProperty("info_job_received"))
                        object.info_job_received = $root.printcloud.digital.supervised.InfoJobReceived.toObject(message.info_job_received, options);
                    return object;
                };

                /**
                 * Converts this InfoService to JSON.
                 * @function toJSON
                 * @memberof printcloud.digital.supervised.InfoService
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                InfoService.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Status enum.
                 * @name printcloud.digital.supervised.InfoService.Status
                 * @enum {number}
                 * @property {number} IDLE=0 IDLE value
                 * @property {number} PRINTING=1 PRINTING value
                 * @property {number} ERROR=2 ERROR value
                 */
                InfoService.Status = (function() {
                    const valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "IDLE"] = 0;
                    values[valuesById[1] = "PRINTING"] = 1;
                    values[valuesById[2] = "ERROR"] = 2;
                    return values;
                })();

                return InfoService;
            })();

            supervised.Supervised = (function() {

                /**
                 * Constructs a new Supervised service.
                 * @memberof printcloud.digital.supervised
                 * @classdesc Represents a Supervised
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function Supervised(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (Supervised.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Supervised;

                /**
                 * Creates new Supervised service using the specified rpc implementation.
                 * @function create
                 * @memberof printcloud.digital.supervised.Supervised
                 * @static
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 * @returns {Supervised} RPC service. Useful where requests and/or responses are streamed.
                 */
                Supervised.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };

                /**
                 * Callback as used by {@link printcloud.digital.supervised.Supervised#receiveServiceInfo}.
                 * @memberof printcloud.digital.supervised.Supervised
                 * @typedef ReceiveServiceInfoCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {printcloud.digital.supervised.InfoService} [response] InfoService
                 */

                /**
                 * Calls ReceiveServiceInfo.
                 * @function receiveServiceInfo
                 * @memberof printcloud.digital.supervised.Supervised
                 * @instance
                 * @param {printcloud.digital.supervised.IQueryService} request QueryService message or plain object
                 * @param {printcloud.digital.supervised.Supervised.ReceiveServiceInfoCallback} callback Node-style callback called with the error, if any, and InfoService
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(Supervised.prototype.receiveServiceInfo = function receiveServiceInfo(request, callback) {
                    return this.rpcCall(receiveServiceInfo, $root.printcloud.digital.supervised.QueryService, $root.printcloud.digital.supervised.InfoService, request, callback);
                }, "name", { value: "ReceiveServiceInfo" });

                /**
                 * Calls ReceiveServiceInfo.
                 * @function receiveServiceInfo
                 * @memberof printcloud.digital.supervised.Supervised
                 * @instance
                 * @param {printcloud.digital.supervised.IQueryService} request QueryService message or plain object
                 * @returns {Promise<printcloud.digital.supervised.InfoService>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link printcloud.digital.supervised.Supervised#print}.
                 * @memberof printcloud.digital.supervised.Supervised
                 * @typedef PrintCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {printcloud.digital.supervised.ResponsePrint} [response] ResponsePrint
                 */

                /**
                 * Calls Print.
                 * @function print
                 * @memberof printcloud.digital.supervised.Supervised
                 * @instance
                 * @param {printcloud.digital.supervised.IRequestPrint} request RequestPrint message or plain object
                 * @param {printcloud.digital.supervised.Supervised.PrintCallback} callback Node-style callback called with the error, if any, and ResponsePrint
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(Supervised.prototype.print = function print(request, callback) {
                    return this.rpcCall(print, $root.printcloud.digital.supervised.RequestPrint, $root.printcloud.digital.supervised.ResponsePrint, request, callback);
                }, "name", { value: "Print" });

                /**
                 * Calls Print.
                 * @function print
                 * @memberof printcloud.digital.supervised.Supervised
                 * @instance
                 * @param {printcloud.digital.supervised.IRequestPrint} request RequestPrint message or plain object
                 * @returns {Promise<printcloud.digital.supervised.ResponsePrint>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link printcloud.digital.supervised.Supervised#delete_}.
                 * @memberof printcloud.digital.supervised.Supervised
                 * @typedef DeleteCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {printcloud.digital.supervised.ResponseDelete} [response] ResponseDelete
                 */

                /**
                 * Calls Delete.
                 * @function delete
                 * @memberof printcloud.digital.supervised.Supervised
                 * @instance
                 * @param {printcloud.digital.supervised.IRequestDelete} request RequestDelete message or plain object
                 * @param {printcloud.digital.supervised.Supervised.DeleteCallback} callback Node-style callback called with the error, if any, and ResponseDelete
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(Supervised.prototype["delete"] = function delete_(request, callback) {
                    return this.rpcCall(delete_, $root.printcloud.digital.supervised.RequestDelete, $root.printcloud.digital.supervised.ResponseDelete, request, callback);
                }, "name", { value: "Delete" });

                /**
                 * Calls Delete.
                 * @function delete
                 * @memberof printcloud.digital.supervised.Supervised
                 * @instance
                 * @param {printcloud.digital.supervised.IRequestDelete} request RequestDelete message or plain object
                 * @returns {Promise<printcloud.digital.supervised.ResponseDelete>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link printcloud.digital.supervised.Supervised#own}.
                 * @memberof printcloud.digital.supervised.Supervised
                 * @typedef OwnCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {printcloud.digital.supervised.ResponseOwnership} [response] ResponseOwnership
                 */

                /**
                 * Calls Own.
                 * @function own
                 * @memberof printcloud.digital.supervised.Supervised
                 * @instance
                 * @param {printcloud.digital.supervised.IRequestOwnership} request RequestOwnership message or plain object
                 * @param {printcloud.digital.supervised.Supervised.OwnCallback} callback Node-style callback called with the error, if any, and ResponseOwnership
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(Supervised.prototype.own = function own(request, callback) {
                    return this.rpcCall(own, $root.printcloud.digital.supervised.RequestOwnership, $root.printcloud.digital.supervised.ResponseOwnership, request, callback);
                }, "name", { value: "Own" });

                /**
                 * Calls Own.
                 * @function own
                 * @memberof printcloud.digital.supervised.Supervised
                 * @instance
                 * @param {printcloud.digital.supervised.IRequestOwnership} request RequestOwnership message or plain object
                 * @returns {Promise<printcloud.digital.supervised.ResponseOwnership>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link printcloud.digital.supervised.Supervised#stat}.
                 * @memberof printcloud.digital.supervised.Supervised
                 * @typedef StatCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {printcloud.digital.supervised.ResponseStat} [response] ResponseStat
                 */

                /**
                 * Calls Stat.
                 * @function stat
                 * @memberof printcloud.digital.supervised.Supervised
                 * @instance
                 * @param {printcloud.digital.supervised.IRequestStat} request RequestStat message or plain object
                 * @param {printcloud.digital.supervised.Supervised.StatCallback} callback Node-style callback called with the error, if any, and ResponseStat
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(Supervised.prototype.stat = function stat(request, callback) {
                    return this.rpcCall(stat, $root.printcloud.digital.supervised.RequestStat, $root.printcloud.digital.supervised.ResponseStat, request, callback);
                }, "name", { value: "Stat" });

                /**
                 * Calls Stat.
                 * @function stat
                 * @memberof printcloud.digital.supervised.Supervised
                 * @instance
                 * @param {printcloud.digital.supervised.IRequestStat} request RequestStat message or plain object
                 * @returns {Promise<printcloud.digital.supervised.ResponseStat>} Promise
                 * @variation 2
                 */

                return Supervised;
            })();

            supervised.RequestPrintHelper = (function() {

                /**
                 * Properties of a RequestPrintHelper.
                 * @memberof printcloud.digital.supervised
                 * @interface IRequestPrintHelper
                 * @property {string|null} [guid] RequestPrintHelper guid
                 * @property {printcloud.digital.supervised.IRequestPrint|null} [request_print] RequestPrintHelper request_print
                 */

                /**
                 * Constructs a new RequestPrintHelper.
                 * @memberof printcloud.digital.supervised
                 * @classdesc Represents a RequestPrintHelper.
                 * @implements IRequestPrintHelper
                 * @constructor
                 * @param {printcloud.digital.supervised.IRequestPrintHelper=} [properties] Properties to set
                 */
                function RequestPrintHelper(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * RequestPrintHelper guid.
                 * @member {string} guid
                 * @memberof printcloud.digital.supervised.RequestPrintHelper
                 * @instance
                 */
                RequestPrintHelper.prototype.guid = "";

                /**
                 * RequestPrintHelper request_print.
                 * @member {printcloud.digital.supervised.IRequestPrint|null|undefined} request_print
                 * @memberof printcloud.digital.supervised.RequestPrintHelper
                 * @instance
                 */
                RequestPrintHelper.prototype.request_print = null;

                /**
                 * Creates a new RequestPrintHelper instance using the specified properties.
                 * @function create
                 * @memberof printcloud.digital.supervised.RequestPrintHelper
                 * @static
                 * @param {printcloud.digital.supervised.IRequestPrintHelper=} [properties] Properties to set
                 * @returns {printcloud.digital.supervised.RequestPrintHelper} RequestPrintHelper instance
                 */
                RequestPrintHelper.create = function create(properties) {
                    return new RequestPrintHelper(properties);
                };

                /**
                 * Encodes the specified RequestPrintHelper message. Does not implicitly {@link printcloud.digital.supervised.RequestPrintHelper.verify|verify} messages.
                 * @function encode
                 * @memberof printcloud.digital.supervised.RequestPrintHelper
                 * @static
                 * @param {printcloud.digital.supervised.IRequestPrintHelper} message RequestPrintHelper message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RequestPrintHelper.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.guid != null && Object.hasOwnProperty.call(message, "guid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.guid);
                    if (message.request_print != null && Object.hasOwnProperty.call(message, "request_print"))
                        $root.printcloud.digital.supervised.RequestPrint.encode(message.request_print, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified RequestPrintHelper message, length delimited. Does not implicitly {@link printcloud.digital.supervised.RequestPrintHelper.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof printcloud.digital.supervised.RequestPrintHelper
                 * @static
                 * @param {printcloud.digital.supervised.IRequestPrintHelper} message RequestPrintHelper message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RequestPrintHelper.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a RequestPrintHelper message from the specified reader or buffer.
                 * @function decode
                 * @memberof printcloud.digital.supervised.RequestPrintHelper
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {printcloud.digital.supervised.RequestPrintHelper} RequestPrintHelper
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RequestPrintHelper.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.printcloud.digital.supervised.RequestPrintHelper();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.guid = reader.string();
                            break;
                        case 2:
                            message.request_print = $root.printcloud.digital.supervised.RequestPrint.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a RequestPrintHelper message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof printcloud.digital.supervised.RequestPrintHelper
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {printcloud.digital.supervised.RequestPrintHelper} RequestPrintHelper
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RequestPrintHelper.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a RequestPrintHelper message.
                 * @function verify
                 * @memberof printcloud.digital.supervised.RequestPrintHelper
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RequestPrintHelper.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.guid != null && message.hasOwnProperty("guid"))
                        if (!$util.isString(message.guid))
                            return "guid: string expected";
                    if (message.request_print != null && message.hasOwnProperty("request_print")) {
                        let error = $root.printcloud.digital.supervised.RequestPrint.verify(message.request_print);
                        if (error)
                            return "request_print." + error;
                    }
                    return null;
                };

                /**
                 * Creates a RequestPrintHelper message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof printcloud.digital.supervised.RequestPrintHelper
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {printcloud.digital.supervised.RequestPrintHelper} RequestPrintHelper
                 */
                RequestPrintHelper.fromObject = function fromObject(object) {
                    if (object instanceof $root.printcloud.digital.supervised.RequestPrintHelper)
                        return object;
                    let message = new $root.printcloud.digital.supervised.RequestPrintHelper();
                    if (object.guid != null)
                        message.guid = String(object.guid);
                    if (object.request_print != null) {
                        if (typeof object.request_print !== "object")
                            throw TypeError(".printcloud.digital.supervised.RequestPrintHelper.request_print: object expected");
                        message.request_print = $root.printcloud.digital.supervised.RequestPrint.fromObject(object.request_print);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a RequestPrintHelper message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof printcloud.digital.supervised.RequestPrintHelper
                 * @static
                 * @param {printcloud.digital.supervised.RequestPrintHelper} message RequestPrintHelper
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RequestPrintHelper.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.guid = "";
                        object.request_print = null;
                    }
                    if (message.guid != null && message.hasOwnProperty("guid"))
                        object.guid = message.guid;
                    if (message.request_print != null && message.hasOwnProperty("request_print"))
                        object.request_print = $root.printcloud.digital.supervised.RequestPrint.toObject(message.request_print, options);
                    return object;
                };

                /**
                 * Converts this RequestPrintHelper to JSON.
                 * @function toJSON
                 * @memberof printcloud.digital.supervised.RequestPrintHelper
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RequestPrintHelper.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return RequestPrintHelper;
            })();

            supervised.ResponsePrintHelper = (function() {

                /**
                 * Properties of a ResponsePrintHelper.
                 * @memberof printcloud.digital.supervised
                 * @interface IResponsePrintHelper
                 * @property {string|null} [guid] ResponsePrintHelper guid
                 * @property {printcloud.digital.supervised.IResponsePrint|null} [response_print] ResponsePrintHelper response_print
                 */

                /**
                 * Constructs a new ResponsePrintHelper.
                 * @memberof printcloud.digital.supervised
                 * @classdesc Represents a ResponsePrintHelper.
                 * @implements IResponsePrintHelper
                 * @constructor
                 * @param {printcloud.digital.supervised.IResponsePrintHelper=} [properties] Properties to set
                 */
                function ResponsePrintHelper(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ResponsePrintHelper guid.
                 * @member {string} guid
                 * @memberof printcloud.digital.supervised.ResponsePrintHelper
                 * @instance
                 */
                ResponsePrintHelper.prototype.guid = "";

                /**
                 * ResponsePrintHelper response_print.
                 * @member {printcloud.digital.supervised.IResponsePrint|null|undefined} response_print
                 * @memberof printcloud.digital.supervised.ResponsePrintHelper
                 * @instance
                 */
                ResponsePrintHelper.prototype.response_print = null;

                /**
                 * Creates a new ResponsePrintHelper instance using the specified properties.
                 * @function create
                 * @memberof printcloud.digital.supervised.ResponsePrintHelper
                 * @static
                 * @param {printcloud.digital.supervised.IResponsePrintHelper=} [properties] Properties to set
                 * @returns {printcloud.digital.supervised.ResponsePrintHelper} ResponsePrintHelper instance
                 */
                ResponsePrintHelper.create = function create(properties) {
                    return new ResponsePrintHelper(properties);
                };

                /**
                 * Encodes the specified ResponsePrintHelper message. Does not implicitly {@link printcloud.digital.supervised.ResponsePrintHelper.verify|verify} messages.
                 * @function encode
                 * @memberof printcloud.digital.supervised.ResponsePrintHelper
                 * @static
                 * @param {printcloud.digital.supervised.IResponsePrintHelper} message ResponsePrintHelper message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResponsePrintHelper.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.guid != null && Object.hasOwnProperty.call(message, "guid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.guid);
                    if (message.response_print != null && Object.hasOwnProperty.call(message, "response_print"))
                        $root.printcloud.digital.supervised.ResponsePrint.encode(message.response_print, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified ResponsePrintHelper message, length delimited. Does not implicitly {@link printcloud.digital.supervised.ResponsePrintHelper.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof printcloud.digital.supervised.ResponsePrintHelper
                 * @static
                 * @param {printcloud.digital.supervised.IResponsePrintHelper} message ResponsePrintHelper message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResponsePrintHelper.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ResponsePrintHelper message from the specified reader or buffer.
                 * @function decode
                 * @memberof printcloud.digital.supervised.ResponsePrintHelper
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {printcloud.digital.supervised.ResponsePrintHelper} ResponsePrintHelper
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResponsePrintHelper.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.printcloud.digital.supervised.ResponsePrintHelper();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.guid = reader.string();
                            break;
                        case 2:
                            message.response_print = $root.printcloud.digital.supervised.ResponsePrint.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ResponsePrintHelper message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof printcloud.digital.supervised.ResponsePrintHelper
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {printcloud.digital.supervised.ResponsePrintHelper} ResponsePrintHelper
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResponsePrintHelper.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ResponsePrintHelper message.
                 * @function verify
                 * @memberof printcloud.digital.supervised.ResponsePrintHelper
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ResponsePrintHelper.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.guid != null && message.hasOwnProperty("guid"))
                        if (!$util.isString(message.guid))
                            return "guid: string expected";
                    if (message.response_print != null && message.hasOwnProperty("response_print")) {
                        let error = $root.printcloud.digital.supervised.ResponsePrint.verify(message.response_print);
                        if (error)
                            return "response_print." + error;
                    }
                    return null;
                };

                /**
                 * Creates a ResponsePrintHelper message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof printcloud.digital.supervised.ResponsePrintHelper
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {printcloud.digital.supervised.ResponsePrintHelper} ResponsePrintHelper
                 */
                ResponsePrintHelper.fromObject = function fromObject(object) {
                    if (object instanceof $root.printcloud.digital.supervised.ResponsePrintHelper)
                        return object;
                    let message = new $root.printcloud.digital.supervised.ResponsePrintHelper();
                    if (object.guid != null)
                        message.guid = String(object.guid);
                    if (object.response_print != null) {
                        if (typeof object.response_print !== "object")
                            throw TypeError(".printcloud.digital.supervised.ResponsePrintHelper.response_print: object expected");
                        message.response_print = $root.printcloud.digital.supervised.ResponsePrint.fromObject(object.response_print);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ResponsePrintHelper message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof printcloud.digital.supervised.ResponsePrintHelper
                 * @static
                 * @param {printcloud.digital.supervised.ResponsePrintHelper} message ResponsePrintHelper
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ResponsePrintHelper.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.guid = "";
                        object.response_print = null;
                    }
                    if (message.guid != null && message.hasOwnProperty("guid"))
                        object.guid = message.guid;
                    if (message.response_print != null && message.hasOwnProperty("response_print"))
                        object.response_print = $root.printcloud.digital.supervised.ResponsePrint.toObject(message.response_print, options);
                    return object;
                };

                /**
                 * Converts this ResponsePrintHelper to JSON.
                 * @function toJSON
                 * @memberof printcloud.digital.supervised.ResponsePrintHelper
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ResponsePrintHelper.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ResponsePrintHelper;
            })();

            supervised.RequestOwnershipHelper = (function() {

                /**
                 * Properties of a RequestOwnershipHelper.
                 * @memberof printcloud.digital.supervised
                 * @interface IRequestOwnershipHelper
                 * @property {string|null} [guid] RequestOwnershipHelper guid
                 * @property {printcloud.digital.supervised.IRequestOwnership|null} [request_ownership] RequestOwnershipHelper request_ownership
                 */

                /**
                 * Constructs a new RequestOwnershipHelper.
                 * @memberof printcloud.digital.supervised
                 * @classdesc Represents a RequestOwnershipHelper.
                 * @implements IRequestOwnershipHelper
                 * @constructor
                 * @param {printcloud.digital.supervised.IRequestOwnershipHelper=} [properties] Properties to set
                 */
                function RequestOwnershipHelper(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * RequestOwnershipHelper guid.
                 * @member {string} guid
                 * @memberof printcloud.digital.supervised.RequestOwnershipHelper
                 * @instance
                 */
                RequestOwnershipHelper.prototype.guid = "";

                /**
                 * RequestOwnershipHelper request_ownership.
                 * @member {printcloud.digital.supervised.IRequestOwnership|null|undefined} request_ownership
                 * @memberof printcloud.digital.supervised.RequestOwnershipHelper
                 * @instance
                 */
                RequestOwnershipHelper.prototype.request_ownership = null;

                /**
                 * Creates a new RequestOwnershipHelper instance using the specified properties.
                 * @function create
                 * @memberof printcloud.digital.supervised.RequestOwnershipHelper
                 * @static
                 * @param {printcloud.digital.supervised.IRequestOwnershipHelper=} [properties] Properties to set
                 * @returns {printcloud.digital.supervised.RequestOwnershipHelper} RequestOwnershipHelper instance
                 */
                RequestOwnershipHelper.create = function create(properties) {
                    return new RequestOwnershipHelper(properties);
                };

                /**
                 * Encodes the specified RequestOwnershipHelper message. Does not implicitly {@link printcloud.digital.supervised.RequestOwnershipHelper.verify|verify} messages.
                 * @function encode
                 * @memberof printcloud.digital.supervised.RequestOwnershipHelper
                 * @static
                 * @param {printcloud.digital.supervised.IRequestOwnershipHelper} message RequestOwnershipHelper message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RequestOwnershipHelper.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.guid != null && Object.hasOwnProperty.call(message, "guid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.guid);
                    if (message.request_ownership != null && Object.hasOwnProperty.call(message, "request_ownership"))
                        $root.printcloud.digital.supervised.RequestOwnership.encode(message.request_ownership, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified RequestOwnershipHelper message, length delimited. Does not implicitly {@link printcloud.digital.supervised.RequestOwnershipHelper.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof printcloud.digital.supervised.RequestOwnershipHelper
                 * @static
                 * @param {printcloud.digital.supervised.IRequestOwnershipHelper} message RequestOwnershipHelper message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RequestOwnershipHelper.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a RequestOwnershipHelper message from the specified reader or buffer.
                 * @function decode
                 * @memberof printcloud.digital.supervised.RequestOwnershipHelper
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {printcloud.digital.supervised.RequestOwnershipHelper} RequestOwnershipHelper
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RequestOwnershipHelper.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.printcloud.digital.supervised.RequestOwnershipHelper();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.guid = reader.string();
                            break;
                        case 2:
                            message.request_ownership = $root.printcloud.digital.supervised.RequestOwnership.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a RequestOwnershipHelper message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof printcloud.digital.supervised.RequestOwnershipHelper
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {printcloud.digital.supervised.RequestOwnershipHelper} RequestOwnershipHelper
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RequestOwnershipHelper.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a RequestOwnershipHelper message.
                 * @function verify
                 * @memberof printcloud.digital.supervised.RequestOwnershipHelper
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RequestOwnershipHelper.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.guid != null && message.hasOwnProperty("guid"))
                        if (!$util.isString(message.guid))
                            return "guid: string expected";
                    if (message.request_ownership != null && message.hasOwnProperty("request_ownership")) {
                        let error = $root.printcloud.digital.supervised.RequestOwnership.verify(message.request_ownership);
                        if (error)
                            return "request_ownership." + error;
                    }
                    return null;
                };

                /**
                 * Creates a RequestOwnershipHelper message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof printcloud.digital.supervised.RequestOwnershipHelper
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {printcloud.digital.supervised.RequestOwnershipHelper} RequestOwnershipHelper
                 */
                RequestOwnershipHelper.fromObject = function fromObject(object) {
                    if (object instanceof $root.printcloud.digital.supervised.RequestOwnershipHelper)
                        return object;
                    let message = new $root.printcloud.digital.supervised.RequestOwnershipHelper();
                    if (object.guid != null)
                        message.guid = String(object.guid);
                    if (object.request_ownership != null) {
                        if (typeof object.request_ownership !== "object")
                            throw TypeError(".printcloud.digital.supervised.RequestOwnershipHelper.request_ownership: object expected");
                        message.request_ownership = $root.printcloud.digital.supervised.RequestOwnership.fromObject(object.request_ownership);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a RequestOwnershipHelper message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof printcloud.digital.supervised.RequestOwnershipHelper
                 * @static
                 * @param {printcloud.digital.supervised.RequestOwnershipHelper} message RequestOwnershipHelper
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RequestOwnershipHelper.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.guid = "";
                        object.request_ownership = null;
                    }
                    if (message.guid != null && message.hasOwnProperty("guid"))
                        object.guid = message.guid;
                    if (message.request_ownership != null && message.hasOwnProperty("request_ownership"))
                        object.request_ownership = $root.printcloud.digital.supervised.RequestOwnership.toObject(message.request_ownership, options);
                    return object;
                };

                /**
                 * Converts this RequestOwnershipHelper to JSON.
                 * @function toJSON
                 * @memberof printcloud.digital.supervised.RequestOwnershipHelper
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RequestOwnershipHelper.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return RequestOwnershipHelper;
            })();

            supervised.ResponseOwnershipHelper = (function() {

                /**
                 * Properties of a ResponseOwnershipHelper.
                 * @memberof printcloud.digital.supervised
                 * @interface IResponseOwnershipHelper
                 * @property {string|null} [guid] ResponseOwnershipHelper guid
                 * @property {printcloud.digital.supervised.IResponseOwnership|null} [response_ownership] ResponseOwnershipHelper response_ownership
                 */

                /**
                 * Constructs a new ResponseOwnershipHelper.
                 * @memberof printcloud.digital.supervised
                 * @classdesc Represents a ResponseOwnershipHelper.
                 * @implements IResponseOwnershipHelper
                 * @constructor
                 * @param {printcloud.digital.supervised.IResponseOwnershipHelper=} [properties] Properties to set
                 */
                function ResponseOwnershipHelper(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ResponseOwnershipHelper guid.
                 * @member {string} guid
                 * @memberof printcloud.digital.supervised.ResponseOwnershipHelper
                 * @instance
                 */
                ResponseOwnershipHelper.prototype.guid = "";

                /**
                 * ResponseOwnershipHelper response_ownership.
                 * @member {printcloud.digital.supervised.IResponseOwnership|null|undefined} response_ownership
                 * @memberof printcloud.digital.supervised.ResponseOwnershipHelper
                 * @instance
                 */
                ResponseOwnershipHelper.prototype.response_ownership = null;

                /**
                 * Creates a new ResponseOwnershipHelper instance using the specified properties.
                 * @function create
                 * @memberof printcloud.digital.supervised.ResponseOwnershipHelper
                 * @static
                 * @param {printcloud.digital.supervised.IResponseOwnershipHelper=} [properties] Properties to set
                 * @returns {printcloud.digital.supervised.ResponseOwnershipHelper} ResponseOwnershipHelper instance
                 */
                ResponseOwnershipHelper.create = function create(properties) {
                    return new ResponseOwnershipHelper(properties);
                };

                /**
                 * Encodes the specified ResponseOwnershipHelper message. Does not implicitly {@link printcloud.digital.supervised.ResponseOwnershipHelper.verify|verify} messages.
                 * @function encode
                 * @memberof printcloud.digital.supervised.ResponseOwnershipHelper
                 * @static
                 * @param {printcloud.digital.supervised.IResponseOwnershipHelper} message ResponseOwnershipHelper message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResponseOwnershipHelper.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.guid != null && Object.hasOwnProperty.call(message, "guid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.guid);
                    if (message.response_ownership != null && Object.hasOwnProperty.call(message, "response_ownership"))
                        $root.printcloud.digital.supervised.ResponseOwnership.encode(message.response_ownership, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified ResponseOwnershipHelper message, length delimited. Does not implicitly {@link printcloud.digital.supervised.ResponseOwnershipHelper.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof printcloud.digital.supervised.ResponseOwnershipHelper
                 * @static
                 * @param {printcloud.digital.supervised.IResponseOwnershipHelper} message ResponseOwnershipHelper message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResponseOwnershipHelper.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ResponseOwnershipHelper message from the specified reader or buffer.
                 * @function decode
                 * @memberof printcloud.digital.supervised.ResponseOwnershipHelper
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {printcloud.digital.supervised.ResponseOwnershipHelper} ResponseOwnershipHelper
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResponseOwnershipHelper.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.printcloud.digital.supervised.ResponseOwnershipHelper();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.guid = reader.string();
                            break;
                        case 2:
                            message.response_ownership = $root.printcloud.digital.supervised.ResponseOwnership.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ResponseOwnershipHelper message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof printcloud.digital.supervised.ResponseOwnershipHelper
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {printcloud.digital.supervised.ResponseOwnershipHelper} ResponseOwnershipHelper
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResponseOwnershipHelper.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ResponseOwnershipHelper message.
                 * @function verify
                 * @memberof printcloud.digital.supervised.ResponseOwnershipHelper
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ResponseOwnershipHelper.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.guid != null && message.hasOwnProperty("guid"))
                        if (!$util.isString(message.guid))
                            return "guid: string expected";
                    if (message.response_ownership != null && message.hasOwnProperty("response_ownership")) {
                        let error = $root.printcloud.digital.supervised.ResponseOwnership.verify(message.response_ownership);
                        if (error)
                            return "response_ownership." + error;
                    }
                    return null;
                };

                /**
                 * Creates a ResponseOwnershipHelper message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof printcloud.digital.supervised.ResponseOwnershipHelper
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {printcloud.digital.supervised.ResponseOwnershipHelper} ResponseOwnershipHelper
                 */
                ResponseOwnershipHelper.fromObject = function fromObject(object) {
                    if (object instanceof $root.printcloud.digital.supervised.ResponseOwnershipHelper)
                        return object;
                    let message = new $root.printcloud.digital.supervised.ResponseOwnershipHelper();
                    if (object.guid != null)
                        message.guid = String(object.guid);
                    if (object.response_ownership != null) {
                        if (typeof object.response_ownership !== "object")
                            throw TypeError(".printcloud.digital.supervised.ResponseOwnershipHelper.response_ownership: object expected");
                        message.response_ownership = $root.printcloud.digital.supervised.ResponseOwnership.fromObject(object.response_ownership);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ResponseOwnershipHelper message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof printcloud.digital.supervised.ResponseOwnershipHelper
                 * @static
                 * @param {printcloud.digital.supervised.ResponseOwnershipHelper} message ResponseOwnershipHelper
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ResponseOwnershipHelper.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.guid = "";
                        object.response_ownership = null;
                    }
                    if (message.guid != null && message.hasOwnProperty("guid"))
                        object.guid = message.guid;
                    if (message.response_ownership != null && message.hasOwnProperty("response_ownership"))
                        object.response_ownership = $root.printcloud.digital.supervised.ResponseOwnership.toObject(message.response_ownership, options);
                    return object;
                };

                /**
                 * Converts this ResponseOwnershipHelper to JSON.
                 * @function toJSON
                 * @memberof printcloud.digital.supervised.ResponseOwnershipHelper
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ResponseOwnershipHelper.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ResponseOwnershipHelper;
            })();

            supervised.RequestDeleteHelper = (function() {

                /**
                 * Properties of a RequestDeleteHelper.
                 * @memberof printcloud.digital.supervised
                 * @interface IRequestDeleteHelper
                 * @property {string|null} [guid] RequestDeleteHelper guid
                 * @property {printcloud.digital.supervised.IRequestDelete|null} [request_delete] RequestDeleteHelper request_delete
                 */

                /**
                 * Constructs a new RequestDeleteHelper.
                 * @memberof printcloud.digital.supervised
                 * @classdesc Represents a RequestDeleteHelper.
                 * @implements IRequestDeleteHelper
                 * @constructor
                 * @param {printcloud.digital.supervised.IRequestDeleteHelper=} [properties] Properties to set
                 */
                function RequestDeleteHelper(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * RequestDeleteHelper guid.
                 * @member {string} guid
                 * @memberof printcloud.digital.supervised.RequestDeleteHelper
                 * @instance
                 */
                RequestDeleteHelper.prototype.guid = "";

                /**
                 * RequestDeleteHelper request_delete.
                 * @member {printcloud.digital.supervised.IRequestDelete|null|undefined} request_delete
                 * @memberof printcloud.digital.supervised.RequestDeleteHelper
                 * @instance
                 */
                RequestDeleteHelper.prototype.request_delete = null;

                /**
                 * Creates a new RequestDeleteHelper instance using the specified properties.
                 * @function create
                 * @memberof printcloud.digital.supervised.RequestDeleteHelper
                 * @static
                 * @param {printcloud.digital.supervised.IRequestDeleteHelper=} [properties] Properties to set
                 * @returns {printcloud.digital.supervised.RequestDeleteHelper} RequestDeleteHelper instance
                 */
                RequestDeleteHelper.create = function create(properties) {
                    return new RequestDeleteHelper(properties);
                };

                /**
                 * Encodes the specified RequestDeleteHelper message. Does not implicitly {@link printcloud.digital.supervised.RequestDeleteHelper.verify|verify} messages.
                 * @function encode
                 * @memberof printcloud.digital.supervised.RequestDeleteHelper
                 * @static
                 * @param {printcloud.digital.supervised.IRequestDeleteHelper} message RequestDeleteHelper message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RequestDeleteHelper.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.guid != null && Object.hasOwnProperty.call(message, "guid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.guid);
                    if (message.request_delete != null && Object.hasOwnProperty.call(message, "request_delete"))
                        $root.printcloud.digital.supervised.RequestDelete.encode(message.request_delete, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified RequestDeleteHelper message, length delimited. Does not implicitly {@link printcloud.digital.supervised.RequestDeleteHelper.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof printcloud.digital.supervised.RequestDeleteHelper
                 * @static
                 * @param {printcloud.digital.supervised.IRequestDeleteHelper} message RequestDeleteHelper message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RequestDeleteHelper.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a RequestDeleteHelper message from the specified reader or buffer.
                 * @function decode
                 * @memberof printcloud.digital.supervised.RequestDeleteHelper
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {printcloud.digital.supervised.RequestDeleteHelper} RequestDeleteHelper
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RequestDeleteHelper.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.printcloud.digital.supervised.RequestDeleteHelper();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.guid = reader.string();
                            break;
                        case 2:
                            message.request_delete = $root.printcloud.digital.supervised.RequestDelete.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a RequestDeleteHelper message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof printcloud.digital.supervised.RequestDeleteHelper
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {printcloud.digital.supervised.RequestDeleteHelper} RequestDeleteHelper
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RequestDeleteHelper.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a RequestDeleteHelper message.
                 * @function verify
                 * @memberof printcloud.digital.supervised.RequestDeleteHelper
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RequestDeleteHelper.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.guid != null && message.hasOwnProperty("guid"))
                        if (!$util.isString(message.guid))
                            return "guid: string expected";
                    if (message.request_delete != null && message.hasOwnProperty("request_delete")) {
                        let error = $root.printcloud.digital.supervised.RequestDelete.verify(message.request_delete);
                        if (error)
                            return "request_delete." + error;
                    }
                    return null;
                };

                /**
                 * Creates a RequestDeleteHelper message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof printcloud.digital.supervised.RequestDeleteHelper
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {printcloud.digital.supervised.RequestDeleteHelper} RequestDeleteHelper
                 */
                RequestDeleteHelper.fromObject = function fromObject(object) {
                    if (object instanceof $root.printcloud.digital.supervised.RequestDeleteHelper)
                        return object;
                    let message = new $root.printcloud.digital.supervised.RequestDeleteHelper();
                    if (object.guid != null)
                        message.guid = String(object.guid);
                    if (object.request_delete != null) {
                        if (typeof object.request_delete !== "object")
                            throw TypeError(".printcloud.digital.supervised.RequestDeleteHelper.request_delete: object expected");
                        message.request_delete = $root.printcloud.digital.supervised.RequestDelete.fromObject(object.request_delete);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a RequestDeleteHelper message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof printcloud.digital.supervised.RequestDeleteHelper
                 * @static
                 * @param {printcloud.digital.supervised.RequestDeleteHelper} message RequestDeleteHelper
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RequestDeleteHelper.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.guid = "";
                        object.request_delete = null;
                    }
                    if (message.guid != null && message.hasOwnProperty("guid"))
                        object.guid = message.guid;
                    if (message.request_delete != null && message.hasOwnProperty("request_delete"))
                        object.request_delete = $root.printcloud.digital.supervised.RequestDelete.toObject(message.request_delete, options);
                    return object;
                };

                /**
                 * Converts this RequestDeleteHelper to JSON.
                 * @function toJSON
                 * @memberof printcloud.digital.supervised.RequestDeleteHelper
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RequestDeleteHelper.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return RequestDeleteHelper;
            })();

            supervised.ResponseDeleteHelper = (function() {

                /**
                 * Properties of a ResponseDeleteHelper.
                 * @memberof printcloud.digital.supervised
                 * @interface IResponseDeleteHelper
                 * @property {string|null} [guid] ResponseDeleteHelper guid
                 * @property {printcloud.digital.supervised.IResponseDelete|null} [response_delete] ResponseDeleteHelper response_delete
                 */

                /**
                 * Constructs a new ResponseDeleteHelper.
                 * @memberof printcloud.digital.supervised
                 * @classdesc Represents a ResponseDeleteHelper.
                 * @implements IResponseDeleteHelper
                 * @constructor
                 * @param {printcloud.digital.supervised.IResponseDeleteHelper=} [properties] Properties to set
                 */
                function ResponseDeleteHelper(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ResponseDeleteHelper guid.
                 * @member {string} guid
                 * @memberof printcloud.digital.supervised.ResponseDeleteHelper
                 * @instance
                 */
                ResponseDeleteHelper.prototype.guid = "";

                /**
                 * ResponseDeleteHelper response_delete.
                 * @member {printcloud.digital.supervised.IResponseDelete|null|undefined} response_delete
                 * @memberof printcloud.digital.supervised.ResponseDeleteHelper
                 * @instance
                 */
                ResponseDeleteHelper.prototype.response_delete = null;

                /**
                 * Creates a new ResponseDeleteHelper instance using the specified properties.
                 * @function create
                 * @memberof printcloud.digital.supervised.ResponseDeleteHelper
                 * @static
                 * @param {printcloud.digital.supervised.IResponseDeleteHelper=} [properties] Properties to set
                 * @returns {printcloud.digital.supervised.ResponseDeleteHelper} ResponseDeleteHelper instance
                 */
                ResponseDeleteHelper.create = function create(properties) {
                    return new ResponseDeleteHelper(properties);
                };

                /**
                 * Encodes the specified ResponseDeleteHelper message. Does not implicitly {@link printcloud.digital.supervised.ResponseDeleteHelper.verify|verify} messages.
                 * @function encode
                 * @memberof printcloud.digital.supervised.ResponseDeleteHelper
                 * @static
                 * @param {printcloud.digital.supervised.IResponseDeleteHelper} message ResponseDeleteHelper message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResponseDeleteHelper.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.guid != null && Object.hasOwnProperty.call(message, "guid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.guid);
                    if (message.response_delete != null && Object.hasOwnProperty.call(message, "response_delete"))
                        $root.printcloud.digital.supervised.ResponseDelete.encode(message.response_delete, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified ResponseDeleteHelper message, length delimited. Does not implicitly {@link printcloud.digital.supervised.ResponseDeleteHelper.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof printcloud.digital.supervised.ResponseDeleteHelper
                 * @static
                 * @param {printcloud.digital.supervised.IResponseDeleteHelper} message ResponseDeleteHelper message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResponseDeleteHelper.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ResponseDeleteHelper message from the specified reader or buffer.
                 * @function decode
                 * @memberof printcloud.digital.supervised.ResponseDeleteHelper
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {printcloud.digital.supervised.ResponseDeleteHelper} ResponseDeleteHelper
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResponseDeleteHelper.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.printcloud.digital.supervised.ResponseDeleteHelper();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.guid = reader.string();
                            break;
                        case 2:
                            message.response_delete = $root.printcloud.digital.supervised.ResponseDelete.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ResponseDeleteHelper message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof printcloud.digital.supervised.ResponseDeleteHelper
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {printcloud.digital.supervised.ResponseDeleteHelper} ResponseDeleteHelper
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResponseDeleteHelper.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ResponseDeleteHelper message.
                 * @function verify
                 * @memberof printcloud.digital.supervised.ResponseDeleteHelper
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ResponseDeleteHelper.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.guid != null && message.hasOwnProperty("guid"))
                        if (!$util.isString(message.guid))
                            return "guid: string expected";
                    if (message.response_delete != null && message.hasOwnProperty("response_delete")) {
                        let error = $root.printcloud.digital.supervised.ResponseDelete.verify(message.response_delete);
                        if (error)
                            return "response_delete." + error;
                    }
                    return null;
                };

                /**
                 * Creates a ResponseDeleteHelper message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof printcloud.digital.supervised.ResponseDeleteHelper
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {printcloud.digital.supervised.ResponseDeleteHelper} ResponseDeleteHelper
                 */
                ResponseDeleteHelper.fromObject = function fromObject(object) {
                    if (object instanceof $root.printcloud.digital.supervised.ResponseDeleteHelper)
                        return object;
                    let message = new $root.printcloud.digital.supervised.ResponseDeleteHelper();
                    if (object.guid != null)
                        message.guid = String(object.guid);
                    if (object.response_delete != null) {
                        if (typeof object.response_delete !== "object")
                            throw TypeError(".printcloud.digital.supervised.ResponseDeleteHelper.response_delete: object expected");
                        message.response_delete = $root.printcloud.digital.supervised.ResponseDelete.fromObject(object.response_delete);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ResponseDeleteHelper message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof printcloud.digital.supervised.ResponseDeleteHelper
                 * @static
                 * @param {printcloud.digital.supervised.ResponseDeleteHelper} message ResponseDeleteHelper
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ResponseDeleteHelper.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.guid = "";
                        object.response_delete = null;
                    }
                    if (message.guid != null && message.hasOwnProperty("guid"))
                        object.guid = message.guid;
                    if (message.response_delete != null && message.hasOwnProperty("response_delete"))
                        object.response_delete = $root.printcloud.digital.supervised.ResponseDelete.toObject(message.response_delete, options);
                    return object;
                };

                /**
                 * Converts this ResponseDeleteHelper to JSON.
                 * @function toJSON
                 * @memberof printcloud.digital.supervised.ResponseDeleteHelper
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ResponseDeleteHelper.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ResponseDeleteHelper;
            })();

            supervised.RequestStatHelper = (function() {

                /**
                 * Properties of a RequestStatHelper.
                 * @memberof printcloud.digital.supervised
                 * @interface IRequestStatHelper
                 * @property {string|null} [guid] RequestStatHelper guid
                 * @property {printcloud.digital.supervised.IRequestStat|null} [request_stat] RequestStatHelper request_stat
                 */

                /**
                 * Constructs a new RequestStatHelper.
                 * @memberof printcloud.digital.supervised
                 * @classdesc Represents a RequestStatHelper.
                 * @implements IRequestStatHelper
                 * @constructor
                 * @param {printcloud.digital.supervised.IRequestStatHelper=} [properties] Properties to set
                 */
                function RequestStatHelper(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * RequestStatHelper guid.
                 * @member {string} guid
                 * @memberof printcloud.digital.supervised.RequestStatHelper
                 * @instance
                 */
                RequestStatHelper.prototype.guid = "";

                /**
                 * RequestStatHelper request_stat.
                 * @member {printcloud.digital.supervised.IRequestStat|null|undefined} request_stat
                 * @memberof printcloud.digital.supervised.RequestStatHelper
                 * @instance
                 */
                RequestStatHelper.prototype.request_stat = null;

                /**
                 * Creates a new RequestStatHelper instance using the specified properties.
                 * @function create
                 * @memberof printcloud.digital.supervised.RequestStatHelper
                 * @static
                 * @param {printcloud.digital.supervised.IRequestStatHelper=} [properties] Properties to set
                 * @returns {printcloud.digital.supervised.RequestStatHelper} RequestStatHelper instance
                 */
                RequestStatHelper.create = function create(properties) {
                    return new RequestStatHelper(properties);
                };

                /**
                 * Encodes the specified RequestStatHelper message. Does not implicitly {@link printcloud.digital.supervised.RequestStatHelper.verify|verify} messages.
                 * @function encode
                 * @memberof printcloud.digital.supervised.RequestStatHelper
                 * @static
                 * @param {printcloud.digital.supervised.IRequestStatHelper} message RequestStatHelper message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RequestStatHelper.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.guid != null && Object.hasOwnProperty.call(message, "guid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.guid);
                    if (message.request_stat != null && Object.hasOwnProperty.call(message, "request_stat"))
                        $root.printcloud.digital.supervised.RequestStat.encode(message.request_stat, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified RequestStatHelper message, length delimited. Does not implicitly {@link printcloud.digital.supervised.RequestStatHelper.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof printcloud.digital.supervised.RequestStatHelper
                 * @static
                 * @param {printcloud.digital.supervised.IRequestStatHelper} message RequestStatHelper message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RequestStatHelper.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a RequestStatHelper message from the specified reader or buffer.
                 * @function decode
                 * @memberof printcloud.digital.supervised.RequestStatHelper
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {printcloud.digital.supervised.RequestStatHelper} RequestStatHelper
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RequestStatHelper.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.printcloud.digital.supervised.RequestStatHelper();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.guid = reader.string();
                            break;
                        case 2:
                            message.request_stat = $root.printcloud.digital.supervised.RequestStat.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a RequestStatHelper message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof printcloud.digital.supervised.RequestStatHelper
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {printcloud.digital.supervised.RequestStatHelper} RequestStatHelper
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RequestStatHelper.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a RequestStatHelper message.
                 * @function verify
                 * @memberof printcloud.digital.supervised.RequestStatHelper
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RequestStatHelper.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.guid != null && message.hasOwnProperty("guid"))
                        if (!$util.isString(message.guid))
                            return "guid: string expected";
                    if (message.request_stat != null && message.hasOwnProperty("request_stat")) {
                        let error = $root.printcloud.digital.supervised.RequestStat.verify(message.request_stat);
                        if (error)
                            return "request_stat." + error;
                    }
                    return null;
                };

                /**
                 * Creates a RequestStatHelper message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof printcloud.digital.supervised.RequestStatHelper
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {printcloud.digital.supervised.RequestStatHelper} RequestStatHelper
                 */
                RequestStatHelper.fromObject = function fromObject(object) {
                    if (object instanceof $root.printcloud.digital.supervised.RequestStatHelper)
                        return object;
                    let message = new $root.printcloud.digital.supervised.RequestStatHelper();
                    if (object.guid != null)
                        message.guid = String(object.guid);
                    if (object.request_stat != null) {
                        if (typeof object.request_stat !== "object")
                            throw TypeError(".printcloud.digital.supervised.RequestStatHelper.request_stat: object expected");
                        message.request_stat = $root.printcloud.digital.supervised.RequestStat.fromObject(object.request_stat);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a RequestStatHelper message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof printcloud.digital.supervised.RequestStatHelper
                 * @static
                 * @param {printcloud.digital.supervised.RequestStatHelper} message RequestStatHelper
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RequestStatHelper.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.guid = "";
                        object.request_stat = null;
                    }
                    if (message.guid != null && message.hasOwnProperty("guid"))
                        object.guid = message.guid;
                    if (message.request_stat != null && message.hasOwnProperty("request_stat"))
                        object.request_stat = $root.printcloud.digital.supervised.RequestStat.toObject(message.request_stat, options);
                    return object;
                };

                /**
                 * Converts this RequestStatHelper to JSON.
                 * @function toJSON
                 * @memberof printcloud.digital.supervised.RequestStatHelper
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RequestStatHelper.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return RequestStatHelper;
            })();

            supervised.ResponseStatHelper = (function() {

                /**
                 * Properties of a ResponseStatHelper.
                 * @memberof printcloud.digital.supervised
                 * @interface IResponseStatHelper
                 * @property {string|null} [guid] ResponseStatHelper guid
                 * @property {printcloud.digital.supervised.IResponseStat|null} [response_stat] ResponseStatHelper response_stat
                 */

                /**
                 * Constructs a new ResponseStatHelper.
                 * @memberof printcloud.digital.supervised
                 * @classdesc Represents a ResponseStatHelper.
                 * @implements IResponseStatHelper
                 * @constructor
                 * @param {printcloud.digital.supervised.IResponseStatHelper=} [properties] Properties to set
                 */
                function ResponseStatHelper(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ResponseStatHelper guid.
                 * @member {string} guid
                 * @memberof printcloud.digital.supervised.ResponseStatHelper
                 * @instance
                 */
                ResponseStatHelper.prototype.guid = "";

                /**
                 * ResponseStatHelper response_stat.
                 * @member {printcloud.digital.supervised.IResponseStat|null|undefined} response_stat
                 * @memberof printcloud.digital.supervised.ResponseStatHelper
                 * @instance
                 */
                ResponseStatHelper.prototype.response_stat = null;

                /**
                 * Creates a new ResponseStatHelper instance using the specified properties.
                 * @function create
                 * @memberof printcloud.digital.supervised.ResponseStatHelper
                 * @static
                 * @param {printcloud.digital.supervised.IResponseStatHelper=} [properties] Properties to set
                 * @returns {printcloud.digital.supervised.ResponseStatHelper} ResponseStatHelper instance
                 */
                ResponseStatHelper.create = function create(properties) {
                    return new ResponseStatHelper(properties);
                };

                /**
                 * Encodes the specified ResponseStatHelper message. Does not implicitly {@link printcloud.digital.supervised.ResponseStatHelper.verify|verify} messages.
                 * @function encode
                 * @memberof printcloud.digital.supervised.ResponseStatHelper
                 * @static
                 * @param {printcloud.digital.supervised.IResponseStatHelper} message ResponseStatHelper message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResponseStatHelper.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.guid != null && Object.hasOwnProperty.call(message, "guid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.guid);
                    if (message.response_stat != null && Object.hasOwnProperty.call(message, "response_stat"))
                        $root.printcloud.digital.supervised.ResponseStat.encode(message.response_stat, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified ResponseStatHelper message, length delimited. Does not implicitly {@link printcloud.digital.supervised.ResponseStatHelper.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof printcloud.digital.supervised.ResponseStatHelper
                 * @static
                 * @param {printcloud.digital.supervised.IResponseStatHelper} message ResponseStatHelper message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResponseStatHelper.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ResponseStatHelper message from the specified reader or buffer.
                 * @function decode
                 * @memberof printcloud.digital.supervised.ResponseStatHelper
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {printcloud.digital.supervised.ResponseStatHelper} ResponseStatHelper
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResponseStatHelper.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.printcloud.digital.supervised.ResponseStatHelper();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.guid = reader.string();
                            break;
                        case 2:
                            message.response_stat = $root.printcloud.digital.supervised.ResponseStat.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ResponseStatHelper message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof printcloud.digital.supervised.ResponseStatHelper
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {printcloud.digital.supervised.ResponseStatHelper} ResponseStatHelper
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResponseStatHelper.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ResponseStatHelper message.
                 * @function verify
                 * @memberof printcloud.digital.supervised.ResponseStatHelper
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ResponseStatHelper.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.guid != null && message.hasOwnProperty("guid"))
                        if (!$util.isString(message.guid))
                            return "guid: string expected";
                    if (message.response_stat != null && message.hasOwnProperty("response_stat")) {
                        let error = $root.printcloud.digital.supervised.ResponseStat.verify(message.response_stat);
                        if (error)
                            return "response_stat." + error;
                    }
                    return null;
                };

                /**
                 * Creates a ResponseStatHelper message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof printcloud.digital.supervised.ResponseStatHelper
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {printcloud.digital.supervised.ResponseStatHelper} ResponseStatHelper
                 */
                ResponseStatHelper.fromObject = function fromObject(object) {
                    if (object instanceof $root.printcloud.digital.supervised.ResponseStatHelper)
                        return object;
                    let message = new $root.printcloud.digital.supervised.ResponseStatHelper();
                    if (object.guid != null)
                        message.guid = String(object.guid);
                    if (object.response_stat != null) {
                        if (typeof object.response_stat !== "object")
                            throw TypeError(".printcloud.digital.supervised.ResponseStatHelper.response_stat: object expected");
                        message.response_stat = $root.printcloud.digital.supervised.ResponseStat.fromObject(object.response_stat);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ResponseStatHelper message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof printcloud.digital.supervised.ResponseStatHelper
                 * @static
                 * @param {printcloud.digital.supervised.ResponseStatHelper} message ResponseStatHelper
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ResponseStatHelper.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.guid = "";
                        object.response_stat = null;
                    }
                    if (message.guid != null && message.hasOwnProperty("guid"))
                        object.guid = message.guid;
                    if (message.response_stat != null && message.hasOwnProperty("response_stat"))
                        object.response_stat = $root.printcloud.digital.supervised.ResponseStat.toObject(message.response_stat, options);
                    return object;
                };

                /**
                 * Converts this ResponseStatHelper to JSON.
                 * @function toJSON
                 * @memberof printcloud.digital.supervised.ResponseStatHelper
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ResponseStatHelper.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ResponseStatHelper;
            })();

            return supervised;
        })();

        return digital;
    })();

    return printcloud;
})();

export { $root as default };
