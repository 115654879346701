import { appReducer} from './AppReducer';
import { printheadConfigurationsReducer, StateOutputConfigurations} from './PrintheadConfigurationsReducer';
import { sharedReducer} from './SharedReducer';
import { pdfToolsReducer} from './PDFToolsReducer';
import { ripComputersReducer} from './RIPComputersReducer';
import { reducerRipComputerPrinterMapping, StateRIPComputerPrinterMapping } from '../ComponentRIPComputerPrinterMapping/ReducerRIPComputerPrinterMapping';
import Messages from '../Messages';
import { reducerSettingsLabel, StateSettingsLabel } from "../ComponentSettingsLabel/ReducerSettingsLabel";

import { appcore } from "../appcore_ripe_proto";
import { StateRoot } from '../StateRoot';
import { Action } from 'redux';

export class ActionAsyncDispatch implements Action {
    
    public type: string;
    public constructor(type: string) {
        this.type = type;
    }

    public asyncDispatch(action: ActionAsyncDispatch) {

    }
}

export default (state: StateRoot = { }, action) => {

    if(action.type == 'SESSION_STATE') {
        var sessionStateMessage = appcore.ripe.SessionState.toObject(appcore.ripe.SessionState.decode(action.data), {defaults: true});
        if(sessionStateMessage.logout) {
            // Return initial state with app state on logout
            return {
                app: appReducer(state.app, action)
            }
        }
    }

    var app = appReducer(state.app, action);
    var shared = sharedReducer(state.shared, action);

    return {
        app: app,
        shared: shared,
        printhead_configurations: printheadConfigurationsReducer(app, shared, state.printhead_configurations, action),
        pdf_tools: pdfToolsReducer(app, shared, state.pdf_tools, action),
        rip_computers: ripComputersReducer(app, shared, state.rip_computers, action),
        rip_computer_printer_mapping: reducerRipComputerPrinterMapping(app, shared, state.rip_computer_printer_mapping, action),
        settings_label: reducerSettingsLabel(app, shared, state.settings_label, action)
    }
}
