import Utils from "../Utils";

import { ActionPrintheadConfigurations } from '../actions/PrintheadConfigurationsActions';
import { pdfToolsData, deleteInputPdf, generateLiveViewResult, sourcePdf, sourcePdfConfigsUpdatedAt } from '../actions/PDFToolsActions';
import { ripComputers, saveRIPComputerResult } from '../actions/RIPComputersActions';
import { ActionsRIPComputerPrinterMapping } from "../ComponentRIPComputerPrinterMapping/ReducerRIPComputerPrinterMapping";
import { ActionsComponentSettingsLabel } from "../ComponentSettingsLabel/ReducerSettingsLabel";

var qrMessageIdActionMap = { };

qrMessageIdActionMap[Utils.QrMessageId.PRINTHEAD_CONFIGURATIONS] = (data: Uint8Array) => { return new ActionPrintheadConfigurations(data) };
qrMessageIdActionMap[Utils.QrMessageId.PDF_TOOLS_DATA] = pdfToolsData;
qrMessageIdActionMap[Utils.QrMessageId.DELETE_INPUT_PDF_RESULT] = deleteInputPdf;
qrMessageIdActionMap[Utils.QrMessageId.GENERATE_LIVE_VIEW_RESULT] = generateLiveViewResult;
qrMessageIdActionMap[Utils.QrMessageId.SOURCE_PDF] = sourcePdf;
qrMessageIdActionMap[Utils.QrMessageId.RIP_COMPUTERS] = ripComputers;
qrMessageIdActionMap[Utils.QrMessageId.SAVE_RIP_COMPUTER_RESULT] = saveRIPComputerResult;
qrMessageIdActionMap[Utils.QrMessageId.RIP_COMPUTER_PRINTER_MAPPINGS_DATA] = (data) => { return { type: ActionsRIPComputerPrinterMapping.DATA, data: data} };
qrMessageIdActionMap[Utils.QrMessageId.LABELS] = (data) => { return { type: ActionsComponentSettingsLabel.DATA, data: data} };
qrMessageIdActionMap[Utils.QrMessageId.SOURCE_PDF_CONFIGS_UPDATED_AT] = sourcePdfConfigsUpdatedAt;

export default qrMessageIdActionMap;