import { connect } from 'react-redux';
import Settings from "../Settings";
import React, { Component } from 'react';
import Messages from "../Messages";
import { ReturnHandler, ProcessorSyncEventLoop, DeclinePolicy } from "appcore-ts/taskprocessor/taskprocessor";
import { CommunicatorSocketIOClientRouted, CommunicatorSocketIOClientConfig, FactoryTaskCallback } from "appcore-ts/communicator/communicator";
import uuidv4 from "uuid/v4";
import Utils from "../Utils";

import { connectionState } from "../actions/AppActions";

import messageIdActionMap from "./MessageIdActionMap";
import qrMessageIdActionMap from "./QrMessageIdActionMap";


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      onMessage: (m) => {
        if(m.identifier == Utils.MessageId.QR_MESSAGE) {
            var qr_m = Messages.QrMessageType.decode(m.data);
            if(qrMessageIdActionMap[qr_m.identifier] !== undefined) {
                dispatch(qrMessageIdActionMap[qr_m.identifier](qr_m.data));
            }
        } else if(messageIdActionMap[m.identifier] !== undefined) {
            dispatch(messageIdActionMap[m.identifier](m.data));
        }

        // Old functionality, components that uses old method for receiving messages work with handlers invoked from here.
        var handlers = Utils.getHandlers(m.identifier);
        for(var i = 0; i < handlers.length; i++) {
            handlers[i](m.data);
        }
      },
      onConnectionStateChange: (state, qr_code_data) => {
        dispatch(connectionState(state, qr_code_data));
      }
    };
};

class MessageReceiver extends React.Component {

    _recv = (data) => {
        var message = Messages.MessageType.decode(data);
        this.props.onMessage(message);
        setTimeout(() => {
            this.communicator.recv(this._recv.bind(this));
        }, 0);
    }

    constructor() {
        super();
        this.returnHandler = new ReturnHandler();
        this.processor = new ProcessorSyncEventLoop(DeclinePolicy.Drop, this.returnHandler);
        this.returnHandler.setProcessorSync(this.processor);

        let comm_config = new CommunicatorSocketIOClientConfig();
        comm_config.url = Settings.routedSocketioUrl;
        comm_config.task_callback_factory = new FactoryTaskCallback();
        comm_config.processor = this.processor;
        comm_config.sio_event_name = "message";
        let local_address = uuidv4();
        comm_config.task_callback_factory.funcConnect = () => {
            this.props.onConnectionStateChange(true, local_address);
        };
        comm_config.task_callback_factory.funcClose = () => {
            setTimeout(() => {
                this.props.onConnectionStateChange(false, "");
                this.communicator.init();
            }, 1000);
        };
        comm_config.task_callback_factory.funcDisconnect = () => {
            setTimeout(() => {
                this.props.onConnectionStateChange(false, "");
                this.communicator.init();
            }, 1000);
        };
        comm_config.task_callback_factory.funcConnectError = () => {
            setTimeout(() => {
                this.props.onConnectionStateChange(false, "");
                this.communicator.init();
            }, 1000);
        };
        this.communicator = new CommunicatorSocketIOClientRouted(comm_config, local_address, "ddd679e6-bc7a-56cc-92a4-aaaf7c3bbb21");
        Utils.setCommunicator(this.communicator);
        this.communicator.recv(this._recv.bind(this));
        this.communicator.init();
    }

    render() {
        return null;
    }
}

export default connect(null, mapDispatchToProps)(MessageReceiver);