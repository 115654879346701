import React from 'react';
import { CollapseView } from "./CollapseView";
import { Text, View, TouchableOpacity, Image, Picker, Switch, StyleSheet, TextInput } from 'react-native';
import { colors, themes} from "./Styles";
import Messages from './Messages';
import Utils from './Utils';
import { WithTranslation, withTranslation } from "react-i18next";
import ComponentPagesToPrint from "./ComponentPagesToPrint/ComponentPagesToPrint";
import { appcore } from './appcore_ripe_proto';

interface StateOutputSettingsOverrideView {
    override: appcore.ripe.IOutputSettingsOverride;
}

interface PropsOutputSettingsOverrideView {
    override: appcore.ripe.IOutputSettingsOverride;
    profiles: Array<appcore.ripe.IICCProfile>;
    outputs: Array<appcore.ripe.IOutput>;
    onChange: (override_prm: appcore.ripe.IOutputSettingsOverride) => void;
    inch: boolean;
    map_oem_label?: appcore.ripe.IMapOEMLabel;
    screening_configs: Array<appcore.ripe.IScreeningConfig>;
    pages: number;
    pages_to_print_disabled: boolean;
}

class OutputSettingsOverrideView extends React.Component<PropsOutputSettingsOverrideView & WithTranslation, StateOutputSettingsOverrideView> {

    private collapse = true;

    constructor(props) {
        super(props);

        const output_settings_override_collapse_config = JSON.parse(localStorage.getItem('output_settings_override_collapse_config'))

        if(output_settings_override_collapse_config != null) {
            this.collapse = output_settings_override_collapse_config.collapse;
        }

        this.state = {
            override: { ... this.props.override }
        }
    }

    private saveCollapseConfig() {
        localStorage.setItem('output_settings_override_collapse_config', JSON.stringify({ collapse: this.collapse }))
    }

    private truncateProfileName(input_prm: string) {
        if (input_prm.length > 50) {
           return input_prm.substring(0, 50) + '...';
        }
        return input_prm;
    };

    renderICCProfilePicker() {

        const { t } = this.props;

        let setting_disabled = this.state.override.output_code == 0 && !this.state.override.printer_output;

        let items = [];
        
        items.push(<Picker.Item label={t("none")} value="none"></Picker.Item>);
        items.push(<Picker.Item label={t("default")} value=""></Picker.Item>);

        for(let i = 0; i < this.props.profiles.length; i++)  {
            items.push(
            <Picker.Item
                label={this.truncateProfileName(this.props.profiles[i].name)}
                value={this.props.profiles[i].guid}></Picker.Item>);
        }

        return (
            <View style={{flexDirection: "row", marginTop: 10}}>
                <Text style={{width: 95}}>{t("profile")}</Text>
                {/* @ts-ignore */}
                <Picker disabled={setting_disabled} 
                    style={{flex: 1, marginLeft: 10}} 
                    onValueChange={this.selectedProfileChange.bind(this)} 
                    selectedValue={this.state.override.icc_profile_guid}>
                    {items}
                </Picker>
            </View>
        );

    }

    
    renderOutputPicker() {

        const { t } = this.props;

        let selected_output = this.props.outputs.findIndex((value) => { 
            return value.code == this.state.override.output_code && value.printer_output == this.state.override.printer_output; 
        });

        let items = [];
        
        for(let i = 0; i < this.props.outputs.length; i++) {
            if(this.props.outputs[i].printer_output) {
                items.push(<Picker.Item label={`${t("printer")}: ${this.props.outputs[i].name}`} value={i}></Picker.Item>);
            } else {
                items.push(<Picker.Item label={ `${t("file")}: ${this.props.outputs[i].name}`} value={i}></Picker.Item>);
            }
        }

        return (
            <View style={{flexDirection: "row"}}>
                <Text style={{width: 95}}>{t("output_device")}</Text>
                <Picker style={{flex: 1, marginLeft: 10}} onValueChange={this.selectedOutputChange.bind(this)} selectedValue={selected_output}>
                    {items}
                </Picker>
            </View>
        );
    }

    render() {

        const { t } = this.props;

        return (
            <CollapseView headerText={t("output_settings_override").toUpperCase()} collapse={this.collapse} renderView={this.renderView.bind(this)} onCollapse={ (collapse) => { this.collapse = collapse; this.saveCollapseConfig(); } }>

            </CollapseView>
        );
    }

    selectedDpiConfigChange(value) {
        let res = value.split("x");
        this.state.override.dpi_x = res[0];
        this.state.override.dpi_y = res[1];
        this.props.onChange(this.state.override);
    }

    useRawThumbnailChange(value) {
        this.state.override.use_raw_thumbnail = value;
        this.props.onChange(this.state.override);
    }

    selectedScreeningConfigChange(value) {
        this.state.override.screening_config_guid = value;
        this.props.onChange(this.state.override);
    }

    selectedIntentChange(value) {
        this.state.override.intent = value;
        this.props.onChange(this.state.override);
    }

    selectedPreserveChange(value) {
        this.state.override.preserve = value;
        this.props.onChange(this.state.override);
    }
    
    selectedProfileChange(value) {
        this.state.override.icc_profile_guid = value;
        this.props.onChange(this.state.override);
    }

    toggleCollate() {
        this.state.override.collate = !this.state.override.collate;
        this.props.onChange(this.state.override);
    }

    copiesChange(value) {

        let reg = new RegExp('^[0-9]+$');
        if(reg.test(value)) {
          let new_copies = parseInt(value);
          if(new_copies > 0) {
            this.state.override.copies = new_copies;
            this.props.onChange(this.state.override);
          }
        }
    }

    bottomMarginCopyChange(value) {
        if(parseFloat(value) >= 0) {
            if(this.props.inch) {
              this.state.override.bottom_margin_copy = Utils.convertToMM(value);
            } else {
              this.state.override.bottom_margin_copy = value;
            }

            this.props.onChange(this.state.override);
        }
    }


    bottomMarginPageChange(value) {
        if(parseFloat(value) >= 0) {
            if(this.props.inch) {
              this.state.override.bottom_margin_page = Utils.convertToMM(value);
            } else {
              this.state.override.bottom_margin_page = value;
            }

            this.props.onChange(this.state.override);
        }
    }

    printModePCLChange(value) {
        this.state.override.print_mode_pcl = value;
        if(this.state.override.print_mode_pcl == appcore.ripe.PrintModePCL.single_page_static) {
            this.state.override.selected_pages_to_print = "current_page";
        }
        this.props.onChange(this.state.override);
    }

    getOEMLabel(label) {
        if(this.props.map_oem_label != undefined && this.props.map_oem_label.mapping[label] != undefined) {
            return this.props.map_oem_label.mapping[label];
        }
        return label;
    }

    renderOutputSpecificSettings() {
        
        const { t } = this.props;

        let dpi_config = null;

        let selected_output = this.props.outputs.find((value) => { return value.code == this.state.override.output_code && value.printer_output == this.state.override.printer_output; });

        if(selected_output === undefined) {
            return null;
        }

        if(selected_output.show_dpi) {

            let dpiItems = [];

            for(let i = 0; i < selected_output.dpi_options.length; i++) {
                // @ts-ignore
                dpiItems.push(<Picker.Item disabled={selected_output.show_dpi} 
                    value={selected_output.dpi_options[i]} 
                    label={this.getOEMLabel(selected_output.dpi_options[i])}>

                    </Picker.Item>);
            }

            dpi_config = 
            <Picker style={{flex: 1, marginLeft: 10}} selectedValue={this.state.override.dpi_x + "x" + this.state.override.dpi_y} onValueChange={this.selectedDpiConfigChange.bind(this)}>
                {dpiItems}
            </Picker>;
        } else {
            // @ts-ignore
            dpi_config = <Picker disabled={true} 
                style={{flex: 1, marginLeft: 10}} asd="fos">
            </Picker>;
        }

        let screening_items = [];
        screening_items.push(<Picker.Item label={t("default")} value=""></Picker.Item>);

        for(let i = 0; i < this.props.screening_configs.length; i++) {
            screening_items.push(<Picker.Item label={this.props.screening_configs[i].name} value={this.props.screening_configs[i].guid}></Picker.Item>);
        }

        let screening_config = 
        <View style={{flexDirection: "row", marginTop: 10}}>
            <Text  style={{width: 95}}>{t("screening")}</Text>
            {/* @ts-ignore */}
            <Picker disabled={!selected_output.show_screening} 
                style={{flex: 1, marginLeft: 10}} 
                onValueChange={this.selectedScreeningConfigChange.bind(this)} 
                selectedValue={this.state.override.screening_config_guid}>
                {screening_items}
            </Picker>
        </View>;

        let copies_config = 
        <View style={{flexDirection: "column"}}>
            <View style={{flexDirection: "row", marginTop: 10}}>
                <Text  style={{width: 95}}>{t("copies")}</Text>
                {/* @ts-ignore */}
                <TextInput disabled={!selected_output.show_copies_config}
                    onChangeText={(value) => {
                        this.copiesChange(value);
                    }}
                    value={this.state.override.copies.toString()} 
                    style={[styles.inputStyle, { width: 88, marginLeft: 10 }]}>
                </TextInput>
            </View>
            <View style={{flexDirection: "row", marginTop: 10}}>
                <Text  style={{width: 95}}>{t("collate")}</Text>
                {/* @ts-ignore */}
                <Switch activeThumbColor={colors.switchThumb} 
                    disabled={selected_output.only_collate_is_supported ? true : this.state.override.copies == 1 || this.props.pages == 1} 
                    onTintColor={colors.switchTint} 
                    style={{marginLeft: 10, marginBottom: 10}} 
                    value={selected_output.only_collate_is_supported ? true : this.state.override.collate } 
                    
                    onValueChange={
                        () => {
                            this.toggleCollate();
                        }
                    }>
                </Switch>
            </View>
        </View>;

        let bottom_margin_page = 
        <View style={{flexDirection: "row", marginTop: 10}}>
            <Text  style={{width: 140}}>{`${t("bottom_margin")} (${t("page")})`}</Text>
            {/* @ts-ignore */}
            <TextInput disabled={!selected_output.show_bottom_margin_page}
                keyboardType="numeric"
                onChangeText={(value) => {
                    this.bottomMarginPageChange(value);
                }}
                value={ Utils.convertIfInch(this.state.override.bottom_margin_page, this.props.inch) } 
                style={[styles.inputStyle, { width: 88, marginLeft: 10 }]}>
            </TextInput>
        </View>;

        let bottom_margin_copy = 
        <View style={{flexDirection: "row", marginTop: 10}}>
            <Text  style={{width: 140}}>{`${t("bottom_margin")} (${t("copy")})`}</Text>
            { /* @ts-ignore */}
            <TextInput disabled={!selected_output.show_bottom_margin_copy}
                keyboardType="numeric"
                onChangeText={(value) => {
                    this.bottomMarginCopyChange(value);
                }}
                value={ Utils.convertIfInch(this.state.override.bottom_margin_copy, this.props.inch) } 
                style={[styles.inputStyle, { width: 88, marginLeft: 10 }]}>
            </TextInput>
        </View>;


        let intentConfigs =
        <View style={{flexDirection: "row", marginTop: 10}}>
            <Text  style={{width: 95}}>{t("intent")}</Text>
            {/* @ts-ignore */}
            <Picker disabled={!selected_output.show_intent}
                style={{flex: 1, marginLeft: 10}} 
                onValueChange={(value) => { this.selectedIntentChange(parseInt(value))}} 
                selectedValue={this.state.override.intent}>
                <Picker.Item label={t("source")} value="0"></Picker.Item>
                <Picker.Item label={t("perceptual")} value="1"></Picker.Item>
                <Picker.Item label={t("colorimetric_relative")} value="2"></Picker.Item>
                <Picker.Item label={t("saturation")} value="3"></Picker.Item>
                <Picker.Item label={t("colorimetric_absolute")} value="4"></Picker.Item>
            </Picker>
        </View>;
        
        let preserveConfigDisabled = !selected_output.show_preserve || (selected_output.show_preserve && (this.state.override.intent == 0 ||this.state.override.intent == 4));
        let preserveConfigs =
            <View style={{flexDirection: "row", marginTop: 10}}>
                <Text  style={{width: 95}}>{t("preserve")}</Text>
                { /* @ts-ignore */}
                <Picker disabled={preserveConfigDisabled } 
                    style={{flex: 1, marginLeft: 10}} 
                    onValueChange={(value) => { this.selectedPreserveChange(parseInt(value))}} 
                    selectedValue={this.state.override.preserve}>
                    <Picker.Item label={t("none")} value="0"></Picker.Item>
                    <Picker.Item label={t("black")} value="1"></Picker.Item>
                    <Picker.Item label={t("black_plane")} value="2"></Picker.Item>
                </Picker>
            </View>;

        let print_mode_pcl =
        <View style={{flexDirection: "row", marginTop: 10}}>
            <Text  style={{width: 95}}>{t("print_mode")}</Text>
            {/* @ts-ignore*/}
            <Picker disabled={!selected_output.show_print_mode_pcl}
                style={{flex: 1, marginLeft: 10}} 
                onValueChange={(value) => { this.printModePCLChange(value)}} 
                selectedValue={this.state.override.print_mode_pcl}>
                <Picker.Item label={t("continuous")} value={appcore.ripe.PrintModePCL.continuous}></Picker.Item>
                <Picker.Item label={t("single_page")} value={appcore.ripe.PrintModePCL.single_page}></Picker.Item>
                <Picker.Item label={t("single_page_static")} value={appcore.ripe.PrintModePCL.single_page_static}></Picker.Item>
            </Picker>
        </View>;

        return (
            <View style={{ marginTop: 10}}>
                <View style={{flexDirection: "row"}}>
                    <Text  style={{width: 95}}>{t("dpi")}</Text>
                    { dpi_config }
                </View>

                { this.renderICCProfilePicker() }
                { screening_config }
                <View style={{flexDirection: "row", marginTop: 10}}>
                    <Text style={{width: 95}}>{t("use_raw_thumbnail")}</Text>
                    { /* @ts-ignore */}
                    <Switch activeThumbColor={colors.switchThumb}
                    disabled={!selected_output.show_use_raw_thumbnail} style={{marginLeft: 10, alignSelf: "center"}} value={this.state.override.use_raw_thumbnail} onValueChange={this.useRawThumbnailChange.bind(this)} onTintColor={colors.switchTint}/>
                </View>
                {bottom_margin_page}
                {bottom_margin_copy}
                {copies_config}
                <ComponentPagesToPrint style={{marginTop: 10}} 
                    disabled={this.props.pages_to_print_disabled}
                    selected_pages_to_print={this.state.override.selected_pages_to_print}
                    pages={this.state.override.pages}
                    onChange={(pages, selected_pages_to_print) => { 
                        this.state.override.pages = pages;
                        this.state.override.selected_pages_to_print = selected_pages_to_print;
                        this.props.onChange(this.state.override) 
                    }}></ComponentPagesToPrint>
                {intentConfigs}
                {preserveConfigs}
                {print_mode_pcl}
            </View>
        );
    }

    renderView() {
        return (
            <View style={{paddingTop: 10, paddingLeft: 2, paddingRight: 2}}>
                { this.renderOutputPicker() }
                { this.renderOutputSpecificSettings() }
            </View>
        )
    }

    componentWillReceiveProps(newProps) {
        Object.assign(this.state.override, newProps.override);
        this.setState(this.state);
    }


    selectedOutputChange(id) {

        this.state.override.output_code = this.props.outputs[id].code;
        this.state.override.printer_output = this.props.outputs[id].printer_output;

        let selected_output = this.props.outputs.find((value) => { return value.code == this.state.override.output_code && value.printer_output == this.state.override.printer_output; });
        if(selected_output.show_dpi) {
            this.selectedDpiConfigChange(selected_output.dpi_options[0]);
        }

        this.props.onChange(this.state.override);
    }
}
const styles = StyleSheet.create({
    inputStyle: {
        borderBottomWidth: 2,
        borderBottomColor: "#75757d",
        textAlign: "right"
    }
});

let outputSettingsOverrideView = withTranslation()(OutputSettingsOverrideView);
export { outputSettingsOverrideView as OutputSettingsOverrideView };