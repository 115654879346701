import Settings from "./Settings";

import React, { Component } from 'react';
import {
  StyleSheet,
  Text,
  FlatList,
  TouchableOpacity,
  TouchableHighlight,
  ImageBackground,
  Image,
  View,
  TextInput,
  SafeAreaView
} from 'react-native';

import Utils from "./Utils";

import Messages from "./Messages";

import is from "is_js";

import { withTranslation } from "react-i18next";

var QRCode = require('qrcode.react');

class QRAuthPage extends Component {

    renderQrContent() {

        const { t } = this.props;

        if(this.props.connectedToBackend) {

            if(this.props.qrCodeData == "") {

                let text = "";
                if(Settings.environment == "standalone") {
                    text = `${t("verifying_rip_computer_registration")}...`;
                } else {
                    text = `${t("initializing")}...`;
                }

                return (
                    <View  style={{alignItems: "center"}}>
                        <Text style={{marginBottom: 20}}>{text}</Text>
                    </View>
                );
            } else {

                return(
                    <View  style={{alignItems: "center"}}>
                        <Text style={{marginBottom: 20}}>{t("scan_qr_code_with_ripe_mobile_app")}</Text>
                        <QRCode size={Settings.environment == "standalone" ? 512 : 128} renderAs="svg" value={ this.props.qrCodeData } />
                    </View>
                );
            }
        }

        return (
            <View>
                <Text style={{marginBottom: 20}}>{`${t("connecting_to_ripe_server")}...`}</Text>
            </View>);
    }

    getLogoWithAppName() {
        if(Settings.environment == "debug") {
            return "logo_with_app_name_debug.png";
        } else if(Settings.environment == "release" || Settings.environment == "standalone") {
          return "logo_with_app_name_release.png";
        } else if(Settings.environment == "dev") {
            return "logo_with_app_name_dev.png";
        }
    }

    handlePlayIconPress() {
        if(Settings[Settings.environment].play_store_url !== "") {
            var win = window.open(Settings[Settings.environment].play_store_url);
            win.focus();
        }
    }

    handleAppStoreIconPress() {
        if(Settings[Settings.environment].apple_store_url !== "") {
            var win = window.open(Settings[Settings.environment].apple_store_url);
            win.focus();
        }
    }

    render() {

        const { t } = this.props;

        return (
            <SafeAreaView style={ styles.rootContainer }>
                <View style={{alignItems: "center", flexDirection: "row", height: "100%", justifyContent: "center"}}>
                    <View>
                        <Image source={{uri: this.getLogoWithAppName() }} style={styles.appIcon}></Image>
                    </View>

                    <View  style={{flexDirection: "row", alignItems: "center", height: 340}}>
                        <View style={{borderLeftColor: "black", borderLeftWidth: 2, width: 2, height: 340, marginLeft: 100, marginRight: 100}}></View>
                        <View style={{alignItems: "center", width: 300}}>

                            { this.renderQrContent() }

                            
                            <Text style={{marginTop: 20, textAlign: "justify"}}>{t("mobile_app_instruction_text")}</Text>
                            <View style={{ flexDirection: "row", marginTop: 20 }}>
                                <TouchableOpacity style={{marginRight: 20}} onPress={this.handlePlayIconPress.bind(this)}>
                                    <Image source={{uri: "google-play-badge.png"}} style={[styles.googlePlayIcon]}></Image>
                                </TouchableOpacity>
                                <TouchableOpacity onPress={this.handleAppStoreIconPress.bind(this)}>
                                    <Image source={{uri: "app-store-badge.png"}}  style={[styles.googlePlayIcon]}></Image>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </View>
            </SafeAreaView>
        );
    }
}

const containerBackgroundColor = "#F5F5F5";
const styles = StyleSheet.create({
    rootContainer: {
      flex: 1, 
      flexDirection: "column", 
      backgroundColor: containerBackgroundColor, 
      height: "100%"
    },
    appIcon: {
        width: 272,
        height: 300
    },
    appTitle: {
        fontSize: 40,
        fontFamily: "GOTHIC",
    },
    appSubTitle: {
        fontFamily: "GOTHIC",
        fontSize: 21
    },
    googlePlayIcon: {
        width: 120,
        height: 40
    }
  });
  

export default withTranslation()(QRAuthPage);