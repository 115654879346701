export const getPDFToolsData = () => ({
    type: 'GET_PDF_TOOLS_DATA'
});

export const pdfToolsData = (data) => ({
    type: 'PDF_TOOLS_DATA',
    data: data
});

export const filterInputChange = (value) => ({
    type: 'PDF_TOOLS_FILTER_INPUT_CHANGE',
    value: value
});

export const inputListSort = (value) => ({
    type: 'PDF_TOOLS_INPUT_LIST_SORT',
    value: value
});

export const downloadInput = (guid) => ({
    type: 'PDF_TOOLS_DOWNLOAD_INPUT',
    guid: guid
});

export const startDeleteInput = (guid) => ({
    type: 'PDF_TOOLS_START_DELETE_INPUT',
    guid: guid
});

export const deleteInput  = (guid) => ({
    type: 'PDF_TOOLS_DELETE_INPUT',
    guid: guid
});

export const undoDeleteInput  = (guid) => ({
    type: 'PDF_TOOLS_UNDO_DELETE_INPUT',
    guid: guid
});

export const uploadFileStart = (guid, filename) => ({
    type: 'PDF_TOOLS_UPLOAD_FILE_START',
    guid: guid,
    filename: filename
});

export const uploadFileProgress = (guid, progress) => ({
    type: 'PDF_TOOLS_UPLOAD_FILE_PROGRESS',
    guid: guid,
    progress: progress
});

export const uploadFileFinish = (guid, status, response, responseText) => ({
    type: 'PDF_TOOLS_UPLOAD_FILE_FINISH',
    guid: guid,
    status: status,
    response: response,
    responseText: responseText
});

export const uploadFileErrorDismiss = (guid) => ({
    type: 'PDF_TOOLS_UPLOAD_FILE_ERROR_DISMISS',
    guid: guid
});

export const deleteInputPdf = (data) => ({
    type: 'PDF_TOOLS_DELETE_INPUT_PDF_RESULT',
    data: data
});

export const outputSettingsOverrideChange = (value) => ({
    type: 'PDF_TOOLS_OUTPUT_SETTINGS_OVERRIDE_CHANGE',
    value: value
});

export const  inkManagementChange = (value) => ({
    type: 'PDF_TOOLS_INK_MANAGEMENT_CHANGE',
    value: value
});

export const  imageSizePositionChange = (value) => ({
    type: 'PDF_TOOLS_IMAGE_SIZE_POSITION_CHANGE',
    value: value
});

export const generateLiveViewResult = (data) => ({
    type: 'PDF_TOOLS_GENERATE_LIVE_VIEW_RESULT',
    data: data
});

export const generate = (gen_type, filename) => ({
    type: 'PDF_TOOLS_GENERATE',
    gen_type: gen_type,
    filename: filename
});

export const sourcePdf = (data) => ({
    type: 'PDF_TOOLS_SOURCE_PDF',
    data: data
});

export const sourcePdfConfigsUpdatedAt = (data) => ({
    type: 'PDF_TOOLS_SOURCE_PDF_CONFIGS_UPDATED_AT', 
    data: data
});

export const outputSelect = (filename) => ({
    type: 'PDF_TOOLS_OUTPUT_SELECT',
    filename: filename
});

export const outputDownload = (filename) => ({
    type: 'PDF_TOOLS_OUTPUT_DOWNLOAD',
    filename: filename
});

export const outputDownloadZip = (filename) => ({
    type: 'PDF_TOOLS_OUTPUT_DOWNLOAD_ZIP',
    filename: filename
});

export const deleteOutput = () => ({ 
    type: 'PDF_TOOLS_DELETE_OUTPUT'
});

export const thumbnailPageNumberChange = (value) => ({ 
    type: 'PDF_TOOLS_PAGE_NUMBER_CHANGE',
    value: value
});


export const unmount = () => ({ 
    type: 'PDF_TOOLS_UNMOUNT'
});

export const labelAdd = (guid_pdf, id_label) => ({
    type: 'PDF_TOOLS_LABEL_ADD',
    guid_pdf: guid_pdf,
    id_label: id_label
});

export const labelDelete = (guid_pdf, id_label) => ({
    type: 'PDF_TOOLS_LABEL_DELETE',
    guid_pdf: guid_pdf,
    id_label: id_label
});

export const filterByLabel = (values) => ({
    type: 'PDF_TOOLS_FILTER_BY_LABEL', 
    values: values
});

export const sortInputByChange = () => ({
    type: 'PDF_TOOLS_SORT_INPUT_BY_CHANGE'
})