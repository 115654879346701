import Messages from "../Messages";
import Utils from "../Utils";

var initial_state = {
  rip_computers: [],
  data_loaded: false
};

export const ripComputersReducer = (appState, sharedState, state = initial_state, action) => {
    switch (action.type) {
      case 'GET_RIP_COMPUTERS':
        var qrMessage = Messages.QrMessageType.encode({ session_id: appState.session_id, identifier: Utils.QrMessageId.GET_RIP_COMPUTERS }).finish();
        Utils.sendMessage(Utils.MessageId.QR_MESSAGE, qrMessage);
        return state;
      case 'RIP_COMPUTERS':
        var rip_computers =   Messages.RIPComputersType.toObject(Messages.RIPComputersType.decode(action.data), { defaults: true});
        
        return Object.assign({}, state, { 
          rip_computers: rip_computers.rip_computers,
          data_loaded: true
        });
        case 'RIP_COMPUTERS_UNMOUNT': 
        {
            return Object.assign({}, state, {
                data_loaded: false
            });
        }
        case 'DELETE_RIP_COMPUTER':
        {
            var qrMessage = Messages.QrMessageType.encode(
              { 
                session_id: appState.session_id, 
                identifier: Utils.QrMessageId.DELETE_RIP_COMPUTER, 
                data: Messages.DeleteRIPComputerType.encode({id: action.id}).finish()
              }).finish();
            Utils.sendMessage(Utils.MessageId.QR_MESSAGE, qrMessage);
            return state;
        }
        case 'SAVE_RIP_COMPUTER':
        {
          let rip_computer = state.rip_computers.find((item, index) => {
            return item.id == action.id
          });

          if(rip_computer != undefined) {
            var qrMessage = Messages.QrMessageType.encode(
              { 
                session_id: appState.session_id, 
                identifier: Utils.QrMessageId.SAVE_RIP_COMPUTER, 
                data: Messages.SaveRIPComputerType.encode({id: action.id, name: rip_computer.name}).finish()
              }).finish();
  
            Utils.sendMessage(Utils.MessageId.QR_MESSAGE, qrMessage);
  
            let rip_computers = state.rip_computers.map((item, index) => {
              if(item.id == action.id) {
                  return {
                      ... item,
                      saved: false
                  }
              }
              return item;
            });
            return Object.assign({}, state, { rip_computers: rip_computers});
          }

          return state;
        }
        case 'SAVE_RIP_COMPUTER_RESULT':
        {
            let result = Messages.SaveRIPComputerResultType.decode(action.data);

            let rip_computers = state.rip_computers.map((item, index) => {
              if(item.id == result.id) {
                  return {
                      ... item,
                      saved: true
                  }
              }
              return item;
            });
            return Object.assign({}, state, { rip_computers: rip_computers});
        }
        case 'RIP_COMPUTER_NAME_CHANGE':
        {
          let rip_computers = state.rip_computers.map((item, index) => {
            if(item.id == action.id) {
                return {
                    ... item,
                    name: action.value
                }
            }
            return item;
          });

          return Object.assign({}, state, { rip_computers: rip_computers});
        }
      default:
        return state;
    }
};