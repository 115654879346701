import Messages from "../Messages";

var inch_in_storage = JSON.parse(localStorage.getItem('inch_selected'));
if(inch_in_storage == null) {
    inch_in_storage = false;
}

function checkSelectedPrintheadConfigIdOnData(current_id, configs) {
    if(configs.length > 0 && current_id == -1) {
        return 0;
    }
    if(current_id >= configs.length) {
        return (configs.length > 0 ? 0 : -1);
    }
    return current_id;
}

export const sharedReducer = (state = { selected_printhead_config_id: -1, selected_pdf_guid: "", inch: inch_in_storage }, action) => {
    switch (action.type) {
      case 'PRINTHEAD_CONFIGURATIONS':
            var printheadConfigs = Messages.PrintheadConfigsType.decode(action.data);
            return Object.assign({}, state, { selected_printhead_config_id: checkSelectedPrintheadConfigIdOnData(state.selected_printhead_config_id, printheadConfigs.config) });
        case 'PRINTHEAD_CONFIGURATIONS_NEW_CONFIG': 
            return Object.assign({}, state, { selected_printhead_config_id: action.size });
        case 'SELECT_PRINTHEAD_CONFIG':
            return Object.assign({}, state, { selected_printhead_config_id: action.id });
        case 'PRINTHEAD_CONFIGURATIONS_DELETE_CONFIG':
            if(action.size > 1) { 
                return Object.assign({}, state, { selected_printhead_config_id: state.selected_printhead_config_id - 1});
            } else {
                return Object.assign({}, state, { selected_printhead_config_id: -1});
            }
        case 'PRINTHEAD_CONFIGURATIONS_COPY_CONFIG':
            return Object.assign({}, state, { selected_printhead_config_id: action.size });
        case 'TOGGLE_UNIT':
            localStorage.setItem('inch_selected', JSON.stringify(!state.inch));
            return Object.assign({}, state, { inch: !state.inch });
        case 'PDF_TOOLS_DATA':
            let pdf_tools_data = Messages.PDFToolsData.decode(action.data);
            let selected_pdf_guid = state.selected_pdf_guid;
            if(pdf_tools_data.source_pdfs.source_pdfs.find((item) => { return item.guid == state.selected_pdf_guid; }) == undefined) {
                selected_pdf_guid = "";
            }
            return Object.assign({}, state, { 
                selected_pdf_guid: selected_pdf_guid, 
                selected_printhead_config_id: checkSelectedPrintheadConfigIdOnData(state.selected_printhead_config_id, pdf_tools_data.configs.config)
            });
            return state;
        case 'PDF_TOOLS_DELETE_INPUT_PDF_RESULT':
        {
            let delete_result = Messages.DeleteInputPDFResultType.decode(action.data);
            if(delete_result.error_code == 0 && state.selected_pdf_guid == delete_result.guid) {
                return Object.assign({}, state, { selected_pdf_guid: "" });
            }
            return state;
        }
        case 'SELECT_INPUT_PDF':
        {
            return Object.assign({}, state, { selected_pdf_guid: action.guid });
        }
    default:
        return state;
    }
};