export const getRIPComputers = () => ({
    type: 'GET_RIP_COMPUTERS'
});

export const ripComputers = (data) => ({
    type: 'RIP_COMPUTERS',
    data: data
});

export const ripComputersUnmount = (data) => ({
    type: 'RIP_COMPUTERS_UNMOUNT',
    data: data
});

export const deleteRIPComputer = (id) => ({
    type: 'DELETE_RIP_COMPUTER',
    id: id
});

export const saveRIPComputer = (id) => ({
    type: 'SAVE_RIP_COMPUTER',
    id: id
});

export const saveRIPComputerResult = (data) => ({
    type: 'SAVE_RIP_COMPUTER_RESULT',
    data: data
});

export const ripComputerNameChange = (id, value) => ({
    type: 'RIP_COMPUTER_NAME_CHANGE',
    id: id,
    value: value
});