import React, { Component } from 'react';
import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  Image,
  TouchableOpacity,
  ScrollView, 
  Dimensions
} from 'react-native';

import { Menu, IconButton } from 'react-native-paper';

import { Tabs } from "./Tabs";

import { Tab } from "./Tab";

import PDFToolsPage from "./containers/PDFToolsPage";

import QRAuthPage from "./QRAuthPage";
import Utils from './Utils';
import Messages from './Messages';

import Settings from "./Settings";

import PrintheadConfigurationPage from "./PrintheadConfigurationPage";
import AdministrationPage from "./AdministrationPage";

import FeedbackComponent from "./FeedbackComponent";
import ICCProfilesConfigurationPage from "./ICCProfilesConfigurationPage";
import PrintersConfigurationPage from './PrintersConfigurationPage';
import ScreeningConfigurationPage from './ScreeningConfigurationPage';
import { mdiAccountSettingsOutline } from '@mdi/js';
import RIPComputersPage from "./containers/RIPComputersPage";
import { JobLibraryPage } from "./JobLibraryPage";
import RasterizationQueuePage from './RasterizationQueuePage';
import ComponentSettings from './ComponentSettings/ComponentSettings';
import { withTranslation } from "react-i18next";
import { i18n } from './i18n';
import { ComponentRIPComputerPrinterMapping } from "./ComponentRIPComputerPrinterMapping/ComponentRIPComputerPrinterMapping";
import ComponentTestPage from "./ComponentTestPage/ComponentTestPage";

class App extends Component {

  config_tabs = [];
  tabs = [];

  tab_output_config_key = "output_configuration";
  tab_icc_profiles_config_key = "icc_profiles_configuration";
  tab_printer_config_key = "printer_configuration";
  tab_screening_config_key = "screening_configuration";
  tab_rip_computers_key = "rip_computers";

  tab_pdf_tool_key = "pdf_tool";
  tab_image_library_key = "image_library";
  tab_rasterization_queue_key = "rasterization_queue";
  tab_administration_key = "administration";
  tab_rip_computer_printer_mapping_key = "rip_computer_printer_mapping";
  tab_settings_key = "settings";
  tab_test_page_key = "test_page";

  state = {
    selectedTabId: 0,
    selectedConfigTabId: 0,
    showConfiguration: false,
    showLicensePage: false,
    feedbackMessages: [],
    userMenuVisible: false
  };

  setTitle() {
    if(Settings.environment == "debug") {
      document.title = "debug.printcloud.digital";
    } else if(Settings.environment == "release" || Settings.environment == "standalone") {
      document.title = "printcloud.digital";
    } else if(Settings.environment == "dev") {
      document.title = "dev.printcloud.digital";
    }
  }

  setTitleIcon() {
    var link = document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    if(Settings.environment == "debug") {
      link.href = 'logo_debug.png';
    } else if(Settings.environment == "release" || Settings.environment == "standalone") {
      link.href = 'logo_release.png';
    } else if(Settings.environment == "dev") {
      link.href = 'logo_dev.png';
    }

    
    document.getElementsByTagName('head')[0].appendChild(link);
  }

  constructor(props) {
    super(props);

    this.feedbackComponent = React.createRef();

    this.setTitle();
    this.setTitleIcon();

    Utils.setHandler(Utils.MessageId.QR_MESSAGE, this.handleQrMessage.bind(this));
  }

  handleQrMessage(data) {

    const { t } = this.props;

    var qrMessage = Messages.QrMessageType.decode(data);
    if(qrMessage.session_id === this.props.session_id) {
      if(qrMessage.identifier === Utils.QrMessageId.USER_INFO) {
        var userInfo = Messages.UserInfoType.decode(qrMessage.data);
        this.tabs = [];
        this.tabs.push({ key: this.tab_pdf_tool_key});
        if(userInfo.admin) {
          this.tabs.push({ key: this.tab_administration_key});
        }

        if(Settings.environment == "dev" || Settings.environment == "debug") {
          this.tabs.push({ key: this.tab_image_library_key});
          this.tabs.push({ key: this.tab_rasterization_queue_key});
        }

        if(Settings.environment == "debug") {
          this.tabs.push({ key: this.tab_test_page_key});
        }

        this.config_tabs = [];

        this.config_tabs.push({ key: this.tab_output_config_key});
        this.config_tabs.push({ key: this.tab_icc_profiles_config_key});
        this.config_tabs.push({ key: this.tab_printer_config_key});

        if(Settings.environment != "standalone") {
          this.config_tabs.push({ key: this.tab_rip_computers_key});
          this.config_tabs.push({ key: this.tab_rip_computer_printer_mapping_key});
        }

        if(userInfo.show_screening_tab) {
          this.config_tabs.push({ key: this.tab_screening_config_key});
        }

        this.config_tabs.push({ key: this.tab_settings_key});

        this.setState( { userInfo: userInfo } );
      } else if(qrMessage.identifier === Utils.QrMessageId.LICENSE) {
        var license = Messages.LicenseType.decode(qrMessage.data);
        this.setState({showLicensePage: !license.accepted, license: license });
      }
    }
  }
    
handleSelectedTabChange(id) {
  this.setState({selectedTabId: id});
}

handleAcceptLicensePress() {
  this.setState({ licenseAccepted: true });
}

handleDeclineLicensePress() {
  this.setState({ licenseAccepted: false });
}

handleContinueLicensePress() {
  this.setState({showLicensePage: false});
  var qrMessage = Messages.QrMessageType.encode({ session_id: this.props.session_id, identifier: Utils.QrMessageId.ACCEPT_LICENSE }).finish();
  Utils.sendMessage(Utils.MessageId.QR_MESSAGE, qrMessage);
}

renderPage() {

  const { t } = this.props;

  if(this.state.showLicensePage) {

    var acceptChecked = this.state.licenseAccepted !== undefined && this.state.licenseAccepted;
    var declineChecked = this.state.licenseAccepted !== undefined && !this.state.licenseAccepted;

    return (
      <View style={{ padding: 10 }}>
        <Text style={{marginBottom: 10, fontSize: 18}}>{t("license_agreement")}</Text>
        
        <Text>{ this.state.license.text }</Text>
        <TouchableOpacity onPress={this.handleAcceptLicensePress.bind(this)} style={{marginTop: 20}}>
          <View style={{flexDirection: "row", alignItems: "center"}}>
              <input type="radio" checked={acceptChecked}  onClick={(e) => {e.preventDefault()}}></input>  
              <Text>{t("accept_license_terms")}</Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity onPress={this.handleDeclineLicensePress.bind(this)}>
          <View style={{flexDirection: "row", alignItems: "center"}}>
              <input type="radio" checked={declineChecked}  onClick={(e) => {e.preventDefault()}}></input>  
              <Text>{t("decline_license_terms")}</Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity onPress={this.handleContinueLicensePress.bind(this)} disabled={!acceptChecked}  underlayColor={itemHighlightColor} style={[styles.button, { alignSelf: "flex-start", marginTop: 20}]}>
          <Text>{t("continue")}</Text>
        </TouchableOpacity>
      </View>
    );
  } else {

    if(this.state.showConfiguration) {
      var configPage = null;

      let config_tab = this.config_tabs[this.state.selectedConfigTabId];

      if(config_tab != undefined) {
        if(config_tab.key == this.tab_output_config_key) {
          configPage = <PrintheadConfigurationPage style={{flexGrow: 1, marginLeft: -24}}></PrintheadConfigurationPage>
        } else if(config_tab.key == this.tab_icc_profiles_config_key) {
          configPage = <ICCProfilesConfigurationPage style={{flexGrow: 1, marginLeft: -24}} sessionId={this.props.session_id} onFeedback={this.handleFeedback.bind(this)}></ICCProfilesConfigurationPage>
        } else if(config_tab.key == this.tab_printer_config_key) {
          configPage = <PrintersConfigurationPage style={{flexGrow: 1, marginLeft: -24}} sessionId={this.props.session_id}></PrintersConfigurationPage>
        } else if(config_tab.key == this.tab_screening_config_key) {
          configPage = <ScreeningConfigurationPage style={{flexGrow: 1, marginLeft: -24}} sessionId={this.props.session_id}></ScreeningConfigurationPage>
        } else if(config_tab.key == this.tab_rip_computers_key) {
          configPage = <RIPComputersPage style={{flexGrow: 1, marginLeft: -24}}></RIPComputersPage>
        } else if(config_tab.key == this.tab_settings_key) {
          configPage = <ComponentSettings style={{flexGrow: 1, marginLeft: -24}}></ComponentSettings>
        } else if(config_tab.key == this.tab_rip_computer_printer_mapping_key) {
          configPage = (<ComponentRIPComputerPrinterMapping style={{marginLeft: -24}}></ComponentRIPComputerPrinterMapping>);
        }
      }

      return (
        <View style={{ flexDirection: 'row', padding: 10 }}>
          <TouchableOpacity onPress={this.handleConfigClick.bind(this) } style={{zIndex: 1}}>
            <Image source={{uri: "back-icon.png"}} style={{width: 24, height: 24}}></Image>
          </TouchableOpacity>
          { configPage }
        </View>
      );

    } else {

      let tab = this.tabs[this.state.selectedTabId];
      if(tab != undefined) {
        if(tab.key == this.tab_pdf_tool_key) {
          return (
          <View style={{flex: 1}}>
            <PDFToolsPage onFeedback={this.handleFeedback.bind(this)} style={{flex: 10}}></PDFToolsPage>
          </View>);
        } else if(tab.key == this.tab_administration_key) {
          return (<AdministrationPage sessionId={this.props.session_id}></AdministrationPage>);
        } else if(tab.key == this.tab_test_page_key) {
          return (<ComponentTestPage></ComponentTestPage>);
        }
      }
    }
  }
  return null;
}

handleFeedback(text) {
  this.state.feedbackMessages.push(this.feedbackComponent.current.createFeedbackMessage(text));
  this.setState({feedbackMessages: this.state.feedbackMessages}, () => {
    this.feedbackComponent.current.scrollToEnd();
  });
}

renderFeedbackComponent() {
  let tab = this.tabs[this.state.selectedTabId];
  if((tab != undefined && tab.key != this.tab_image_library_key && tab.key != this.tab_rasterization_queue_key) 
  
  || this.state.showConfiguration) {
    
    return (
      <FeedbackComponent ref={ this.feedbackComponent }
       feedbackMessages={this.state.feedbackMessages} style={{height: 150}} sessionId={this.props.session_id}></FeedbackComponent>
    );
  }
}

handleConfigClick() {
  this.setState({showConfiguration: !this.state.showConfiguration });
}

handleLogoutClick() {
  Utils.sendMessage(Utils.MessageId.QR_CODE_SESSION_END, Messages.QRCodeSessionEndType.encode({ session_id: this.props.session_id }).finish() );
}

handleSelectedConfigTabChange(id) {
  this.setState({selectedConfigTabId: id});
}

componentWillReceiveProps(newProps) {
  if(newProps.logout) {
    this.setState({
      selectedTabId: 0,
      selectedConfigTabId: 0,
      showConfiguration: false,
      feedbackMessages: []
    });
  }
      
}

renderTabs() {

  const { t } = this.props;

  if(this.state.showLicensePage) {
    
    return null;
  } else {

    if(this.state.showConfiguration) {

      let tabs = [];
      for(let i = 0; i < this.config_tabs.length; i++) {
          tabs.push(<Tab title={t(this.config_tabs[i].key)}></Tab>)
      }
      
      return(
        <Tabs key="configTabs" selected={this.state.selectedConfigTabId} onSelectedChange={this.handleSelectedConfigTabChange.bind(this)}>
          {tabs}
        </Tabs>
      );
    } else {


      let tabs = [];
      for(let i = 0; i < this.tabs.length; i++) {
        let title = t(this.tabs[i].key);
        if(this.tabs[i].key == "pdf_tool") {
          title = t(Settings.variant);
        }

        tabs.push(<Tab title={title}></Tab>)
      }

      return(
        <Tabs key="mainTabs" selected={this.state.selectedTabId} onSelectedChange={this.handleSelectedTabChange.bind(this)}>
          { tabs }
        </Tabs>
      );
    }
  }
}

openUserMenu() {
  this.setState({userMenuVisible: true});
}

renderHeaderIcons() {

  const { t } = this.props;

  if(this.state.showLicensePage) {
    return null;
  }

  let userMenu = null;
  let userButton = null;

  if(Settings.environment != "standalone") {
    userMenu =  
    <Menu contentStyle={{padding: 20}} visible={this.state.userMenuVisible} onDismiss={() => { this.setState({userMenuVisible: false}) }}
        anchor={{ x: Dimensions.get('window').width, y: 50 } }>
        <View>
          <Text>{ this.state.userInfo ? this.state.userInfo.name : ""}</Text>
          <Text>{ this.state.userInfo ? this.state.userInfo.email : ""}</Text>
          <Text>{ this.state.userInfo ? this.state.userInfo.phone : ""}</Text>
        
          <TouchableOpacity style={{paddingTop: 5}} onPress={this.handleLogoutClick.bind(this)}>
            <View style={{flexDirection: "row", alignItems: "center"}}>
              <Text style={{flex: 1}}>{t("logout")}</Text>
              <Image source={{uri: "logout.png"}} style={styles.icon}></Image>
            </View>
          </TouchableOpacity>
        </View>
    </Menu>;

    userButton = <IconButton icon="account-outline" style={{margin: 0}} size={28} onPress={this.openUserMenu.bind(this)}/>;
  }
  /* cog-outline settings-outline  */
  return (
    <View style={{flexDirection: "row", alignItems: "center" }}>
      { userMenu }
      <IconButton icon={"cog-outline"} style={{margin: 0}} size={28} onPress={this.handleConfigClick.bind(this)}/>
      { userButton }
    </View>
  );
}

getHeaderLogo() {
  if(Settings.environment == "debug") {
    return "logo_debug.png";
  } else if(Settings.environment == "release" || Settings.environment == "standalone") {
    return "logo_release.png";
  } else if(Settings.environment == "dev") {
    return "logo_dev.png";
  }
}

renderAppVersionText() {

  let { t } = this.props;

  if(Settings.version == "stratus") {
    return (<Text style={{fontSize: 14, fontFamily: "Comfortaa-Regular", fontWeight: "bold", letterSpacing: 3.6, marginRight: -3.6}}>{t(Settings.version).toUpperCase()}</Text>);
  } else if(Settings.version == "cirrus") {
    return (<Text style={{fontSize: 14, fontFamily: "Comfortaa-Regular", fontWeight: "bold", letterSpacing: 6.7, marginRight: -6.7}}>{t(Settings.version).toUpperCase()}</Text>);
  } else if(Settings.version == "altostratus") {
    return (<Text style={{fontSize: 11.6, fontFamily: "Comfortaa-Regular", fontWeight: "bold" }}>{t(Settings.version).toUpperCase()}</Text>);
  }
}

render() {

  let content;

  let tab = this.tabs[this.state.selectedTabId];
  if(!this.state.showConfiguration &&
     tab != undefined &&
      (tab.key == this.tab_image_library_key || tab.key == this.tab_rasterization_queue_key)) {

    let page = tab.key == this.tab_image_library_key ? 
    <JobLibraryPage>
    </JobLibraryPage>
    :
    <RasterizationQueuePage></RasterizationQueuePage>;

    content = page;
  } else {
    content = <ScrollView>
      {this.renderPage()}
    </ScrollView>
  }

  if(!this.props.session_valid) {
    return (<QRAuthPage qrCodeData={this.props.qr_code_data} connectedToBackend={this.props.connected}></QRAuthPage>);
  } else if(this.props.connected && this.props.session_valid) {
      return (
      <SafeAreaView style={ styles.rootContainer}>
        <View style={styles.header}>
          <View style={styles.headerCenter}>
            <View style={{flexDirection: "row", marginRight: 130}}>
              <Image source={{uri: this.getHeaderLogo()}} style={styles.headerIcon}></Image>
              <View style={{flexDirection: "column", alignItems: "flex-end", justifyContent: "center"}}>
                <Text style={{fontSize: 16, fontFamily: "Comfortaa-Regular"}}>printcloud</Text>
                <Text style={{fontSize: 8, fontFamily: "Comfortaa-Light", fontWeight: "bold", letterSpacing: 1.8, marginRight: -0.9, marginTop: 0, marginBottom: 3}}>DIGITAL</Text>
                {this.renderAppVersionText()}
              </View>
              
            </View>
            { this.renderTabs() }
            <View style={{flex: 1}}></View>
            { this.renderHeaderIcons() }

          </View>
        </View>
        {content}
        { this.renderFeedbackComponent() }
      </SafeAreaView>);
    }
  }
}

const containerBackgroundColor = "#F5F5F5";
const itemHighlightColor = "#cee9ff";
const headerBackgroundColor = "#FFFFFF";
const headerShadowColor = "#757575";
const itemColor = "#FFFFFF";

const buttonColor = itemColor;

const styles = StyleSheet.create({
  header: {
    padding: 10,
    paddingLeft: 5,
    paddingVertical: 5,
    alignItems: 'center',
    flexDirection: 'row',
    minHeight: 60,
    backgroundColor: headerBackgroundColor,
    shadowColor: headerShadowColor,
    shadowOpacity: 0.8,
    shadowRadius: 2,
    shadowOffset: {
      height: 1,
      width: 1
    }
  },
  icon : {
    width: 24, height: 24
  },
  headerIcon: {
    width: 50, height: 50, marginRight: 7, marginLeft: 3
  },
  rootContainer: {
    flex: 1, 
    flexDirection: "column", 
    backgroundColor: containerBackgroundColor, 
    height: "100%"
  },
  headerCenter: {
    flex: 1,
    order: 2,
    flexDirection: "row"
  },
  button: {
    backgroundColor: buttonColor, padding: 10
  },
});

export default withTranslation()(App);