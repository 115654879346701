import React from 'react';
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core/styles';
import { withTranslation, WithTranslation } from "react-i18next";
import { Typography } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { colors, themes} from "../Styles";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Input from '@material-ui/core/Input';
import { appcore } from '../appcore_ripe_proto';

interface PropsComponentPagesToPrint {
    style?: any;
    pages: string;
    selected_pages_to_print: string;
    onChange: (pages: string, selected_pages_to_print: string) => void;
    disabled?: boolean;
}

interface StateComponentPagesToPrint {

}
  
const styles = (theme: Theme) => createStyles({ 
    input: {
        textAlign: "right",
        width: 90
    },
    radio: {
        
        '&$checked': {
            color: colors.switchThumb
        },
    },
    checked: {},
    label: {
        fontSize: "0.875rem"
    }
});

type PropsComponentPagesToPrintWithStylesAndTranslation = 
PropsComponentPagesToPrint & WithStyles<typeof styles> & WithTranslation;

class ComponentPagesToPrint extends React.Component<PropsComponentPagesToPrintWithStylesAndTranslation, StateComponentPagesToPrint> {
    
    constructor(props: PropsComponentPagesToPrintWithStylesAndTranslation) {
        super(props);

    }

    handlePagesToPrintChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.props.onChange(this.props.pages, (e.currentTarget as HTMLInputElement).value);
    }

    handlePagesChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.props.onChange((e.currentTarget as HTMLInputElement).value, this.props.selected_pages_to_print);
    }

    render() {

        const { t } = this.props;

        let disabled = false;
        if(this.props.disabled != undefined) {
            disabled = this.props.disabled;
        }

        return (
            <div style={this.props.style}>
                
                <RadioGroup style={{flexDirection: "row", alignItems: "center", justifyContent: "space-between"}} value={this.props.selected_pages_to_print} onChange={this.handlePagesToPrintChange.bind(this)}>
                    <Typography variant="subtitle2">{t("pages_to_print")}</Typography>
                    <FormControlLabel disabled={disabled} classes={{label: this.props.classes.label}} value="" control={<Radio color="primary" classes={{root: this.props.classes.radio, checked: disabled? undefined : this.props.classes.checked }} 
                        className={this.props.classes.radio}/>} label={t("all")} />
                    <FormControlLabel disabled={disabled} classes={{label: this.props.classes.label}} value="current_page" control={<Radio color="primary" classes={{root: this.props.classes.radio, checked: disabled? undefined : this.props.classes.checked}} 
                        className={this.props.classes.radio}/>} label={t("current")} />
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <FormControlLabel disabled={disabled} classes={{label: this.props.classes.label}} value="pages" control={<Radio color="primary" classes={{root: this.props.classes.radio, checked: disabled? undefined : this.props.classes.checked}} 
                        className={this.props.classes.radio}/>} label={t("pages")} />
                    <Input classes={{input: this.props.classes.input}} value={this.props.pages} disabled={this.props.selected_pages_to_print != "pages"}
                        onChange={ this.handlePagesChange.bind(this) }/>
                    </div>
                </RadioGroup>
                
            </div>
        );

    }
}

export default withTranslation()(withStyles(styles, {withTheme: true})(ComponentPagesToPrint));