import React from 'react';
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Tooltip } from 'react-tippy';

interface PropsComponentLabel {
    text: string;
    color: string;
    style?: any;
}

interface StateComponentLabel {

}
  
const styles = (theme: Theme) => createStyles({ 
    container: {

    },
    label_short_text: {
        fontSize: 20
    },
    root: {
        display: "flex",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 8
    }
});

type PropsComponentLabelWithStyles = 
PropsComponentLabel & WithStyles<typeof styles>;

class ComponentLabel extends React.Component<PropsComponentLabelWithStyles, StateComponentLabel> {
    
    constructor(props: PropsComponentLabelWithStyles) {
        super(props);

    }

    renderText() {
        if(this.props.style.width > 20) {
            return (<Typography className={this.props.classes.label_short_text}>{ this.props.text.charAt(0).toUpperCase() }</Typography>);
        }
        return null;
    }

    render() {

        return (
            <Tooltip title={this.props.text} theme="dark">
                <div style={Object.assign({}, this.props.style, {backgroundColor: this.props.color})} className={this.props.classes.root}>
                    {this.renderText()}
                </div>
            </Tooltip>
        );

    }
}

export default withStyles(styles, {withTheme: true})(ComponentLabel);