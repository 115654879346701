import React, { Component } from 'react';
import { View } from 'react-native';

export class Tabs extends Component {

    state = {
        selected: -1
    };

    constructor(props) {
        super(props);
        this.state.selected = props.selected;
    }

    onTabPress(id) {
        this.setState({selected: id});
        if(this.props.onSelectedChange != undefined) { 
            this.props.onSelectedChange(id);
        }
    }

    render() {

        const children = React.Children.map(this.props.children, 
            (child, index) => { 
                if(this.state.selected == index) {
                    return React.cloneElement(child, { onPress: this.onTabPress.bind(this), index: index, selected: true });
                }
                return React.cloneElement(child, { onPress: this.onTabPress.bind(this), index: index, selected: false });
        });
        
        return (
            <View style={[{flexDirection: "row"}, this.props.style]}>
                { children }
            </View>
        );
    }
}