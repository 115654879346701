import React, { Component } from 'react';
import {
  StyleSheet,
  Text,
  FlatList,
  TouchableOpacity,
  Image,
  View,
  Picker
} from 'react-native';

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import SvgIcon from '@material-ui/core/SvgIcon';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { withTranslation } from "react-i18next";
import Settings from "./Settings";
import ThumbnailView from "./ThumbnailView";
import { IconButton } from "react-native-paper";
import { default as IconButtonMUI } from "@material-ui/core/IconButton";
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import Utils from "./Utils";
import { CollapseView } from "./CollapseView";
import SizeAndPositionControlsView from "./SizeAndPositionControlsView";
import { OutputSettingsOverrideView } from "./OutputSettingsOverrideView";
import { RipeButton } from "./RipeButton";
import InkManagementView from "./InkManagementView";
import uuidv4 from "uuid/v4";
import { Typography } from '@material-ui/core';
import ComponentPrintControl from "./ComponentPrintControl/ComponentPrintControl";
import ComponentLabel from "./ComponentLabel/ComponentLabel";
import ComponentLabelSelect from "./ComponentLabelSelect/ComponentLabelSelect";
import ComponentFilterByLabel from "./ComponentFilterByLabel/ComponentFilterByLabel";
import Input from '@material-ui/core/Input';
import { SortInputBy } from './reducers/PDFToolsReducer';

class PDFSplittingPage extends React.Component {

  image_to_pdf;
  image_to_pdf_waiting_items = [];

  constructor(props) {
    super(props);
  }

  componentWillUnmount() {
    this.props.onUnmount();
  }

  saveCollapseConfig() {
    localStorage.setItem('pdf_splitting_page_collapse_config', 
    JSON.stringify({ 
      sourceFileListCollapse: this.sourceFileListCollapse, 
      uploadCollapse: this.uploadCollapse, 
      outputFileListCollapse: this.outputFileListCollapse,
      print_control_collapse: this.print_control_collapse }))
  }

  componentWillMount() {

    const pdf_splitting_page_collapse_config = JSON.parse(localStorage.getItem('pdf_splitting_page_collapse_config'))

    if (pdf_splitting_page_collapse_config != null) {
      this.sourceFileListCollapse = pdf_splitting_page_collapse_config.sourceFileListCollapse;
      this.uploadCollapse = pdf_splitting_page_collapse_config.uploadCollapse;
      this.outputFileListCollapse = pdf_splitting_page_collapse_config.outputFileListCollapse;
      this.print_control_collapse =  pdf_splitting_page_collapse_config.print_control_collapse;
    }

    this.props.onLoadData(this.props.session_id);
  }

  selectedConfigChange(id) {
    this.props.onOutputConfigSelect(id);
  }

  renderConfigsPicker() {
    var pickerItems = [];
    for (var i = 0; i < this.props.output_configs.length; i++) {
      pickerItems.push(<Picker.Item label={this.props.output_configs[i].name} value={i}></Picker.Item>);
    }
    return (
      <Picker selectedValue={this.props.selected_printhead_config_id} onValueChange={this.selectedConfigChange.bind(this)}  >
        { pickerItems}
      </Picker>
    );
  }


  state = {
    show_image_to_pdf_dialog: false,
    image_to_pdf_options: {
      resize_filter: 1,
      dpi: ""
    }
  };

  sourceFileListCollapse = false;
  uploadCollapse = false;
  outputFileListCollapse = false;
  print_control_collapse = false;

  selectItem(item, showFeedback) {
    const { t } = this.props;

    this.props.onInputSelect(item.guid);

    if (showFeedback) {
      this.props.onFeedback(t("selected_pdf_file", { 0: item.filename }));
    }

  }

  handleUndoDeleteInputPDFPress(guid) {
    this.props.onUndoDeleteInput(guid);
  }

  handleDeleteInputPDFPress(guid) {
    const { t } = this.props;

    this.props.onStartDeleteInput(guid);

    var input = this.props.inputs.find((item) => { return item.guid == guid; });
    // TODO: should use rxjs for showing feedback in feedback component.
    this.props.onFeedback(t("deleting_pdf_file", { 0: input.filename }));

    this.setState({ sourcePdfs: this.state.sourcePdfs });
    setTimeout(() => {
      this.props.onDeleteInput(input.guid);
    }, 5000);

  }

  handlePDFA1WarningIconPress(d) {
    const { t } = this.props;
    this.props.onFeedback(t("pdf_file_is_not_pdf_a_1b_compliant", { 0: d.item.filename }));
  }

  renderInput(d) {

    const { t } = this.props;

    let input = this.props.inputs.find((item) => { return item.guid == d.item.guid; });
    if (input.uploading) {
      let text = "";
      if (input.upload_percentage != 100) {
        text = `(${t("uploading")}: ${input.upload_percentage}%)`;
      } else {
        text = `(${t("uploaded")}. ${t("generating_thumbnail_images")}...)`;
      }

      return (
        <TouchableOpacity style={styles.item} disabled={true}>
          <View style={{ flex: 1, flexDirection: "row", justifyContent: "space-between" }}>
            <Text style={[styles.sourceFilename, { alignSelf: "center" }]}>
              {input.filename}
            </Text>
            <Text style={{ alignSelf: "center", marginRight: 10 }}>
              {text}
            </Text>
          </View>
        </TouchableOpacity>
      );
    }

    var pdf_compliant_warning_icon = null;
    if (this.props.selected_printhead_config_id !== -1) {
      var config = this.props.output_configs[this.props.selected_printhead_config_id];
      if (config.output_code == 0 && !input.pdf_a1b_compliant) {

        pdf_compliant_warning_icon =
          <Tooltip
            title={t("pdf_file_is_not_pdf_a_1b_compliant", { 0: input.filename })}
            position="left"
            theme="dark"
          >
            <IconButton icon="alert-outline" style={{ margin: 0 }} size={24} />
          </Tooltip>
          ;

      }
    }

    var delete_button = null;
    var undo_deletion_button = null;
    if (input.show_undo_deletion_button) {
      undo_deletion_button = <IconButton onPress={this.handleUndoDeleteInputPDFPress.bind(this, input.guid)} style={{ margin: 0 }} size={24} icon="undo"></IconButton>;
    } else {
      delete_button = <IconButton
        icon={{ uri: "trash-can-outline.svg" }}
        style={{ margin: 0, opacity: "1" }} size={24}
        onPress={this.handleDeleteInputPDFPress.bind(this, input.guid)} />;
    }

    let max_labels_width = 90;
    let max_labels_margin_size = 15;

    let label_size = 30;
    let label_margin_size = 5;
    if(input.id_labels.length > 3) {
      label_size = 90 / input.id_labels.length;
      label_margin_size = 15 / input.id_labels.length;
    }

    let labels = input.id_labels.map((id) => {
      let label = this.props.labels.find((label) => { return label.id == id; });
      return (<ComponentLabel text={label.name} color={label.color} style={{
        width: label_size, height: label_size, marginRight: label_margin_size}}></ComponentLabel>)
    });

    return (
      <div style={{display: "flex", flexDirection: "row"}}>
        <TouchableOpacity
          style={[input.selected ? styles.selectedItem : styles.item, {flex: 1}]} onPress={this.selectItem.bind(this, input, true)} disabled={input.upload_error}>
          <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
            <Text numberOfLines={1}  style={[styles.sourceFilename, { alignSelf: "center" }]}>{input.filename}</Text>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <div style={{maxWidth: max_labels_width + max_labels_margin_size, display: "flex", flexDirection: "row", flexWrap: 1}}>
                {labels}
              </div>
              {/*pdf_compliant_warning_icon*/}
              {delete_button}
              {undo_deletion_button}
              <IconButton icon="cloud-download-outline" style={{ margin: 0, marginRight: 5 }} size={24} onPress={() => { this.props.onDownloadInput(input.guid) }} />
              <View onStartShouldSetResponder={(event) => true}>
          
                <ComponentLabelSelect style={{ marginRight: 10 }} labels={this.props.labels} selected_labels={input.id_labels} onSelectLabel={(values) => { 
                    if(values.length > input.id_labels.length) {
                      this.props.onLabelAdd(input.guid, values.filter(x => !input.id_labels.includes(x))[0]);
                    } else {
                      this.props.onLabelDelete(input.guid, input.id_labels.filter(x => !values.includes(x))[0]);
                    }
                  }}>
                </ComponentLabelSelect>
  
              </View>
            </View>
          </View>
        </TouchableOpacity>
      </div>
    );
  }

  renderItemOutput(d) {

    var printIcons = [];

    if (this.props.output_settings_override.printer_output) {
      printIcons.push(<IconButton style={{ margin: 0, marginRight: 5 }} icon="printer-wireless" size={24} onPress={this.handleGeneratePress.bind(this, Utils.GenerateTypeId.SEND_TO_PRINTER, d.item.filename)}></IconButton>);
      printIcons.push(<IconButton style={{ margin: 0, marginRight: 5 }} icon={{ uri: "bookshelf.svg" }} size={24} onPress={this.handleGeneratePress.bind(this, Utils.GenerateTypeId.SEND_TO_JOB_LIB, d.item.filename)}></IconButton>);
    }

    return (
      <TouchableOpacity style={styles.item} style={d.item.selected ? styles.selectedItem : styles.item} onPress={() => { this.props.onOutputSelect(d.item.filename) }}>
        <View style={{ flexDirection: "row" }}>
          <Text style={[styles.splitFilename, { alignSelf: "center", flex: 1 }]}>{d.item.display_filename}</Text>
          {printIcons}
          <IconButton icon="cloud-download-outline" style={{ margin: 0, marginRight: 5 }} size={24} onPress={() => {
            this.props.onOutputDownload(d.item.filename)
          }} />
        </View>
      </TouchableOpacity>
    );
  }

  handleGeneratePress(generateType, filename) {
    this.props.onGenerate(generateType, filename);
  }

  /**
   * 
   * @param {File} file 
   */
  uploadFile(file, options) {

    let filename_lower_case = file.name.toLowerCase();

    let is_image = filename_lower_case.endsWith(".png") ||
      filename_lower_case.endsWith(".jpg") ||
      filename_lower_case.endsWith(".jpeg") ||
      filename_lower_case.endsWith(".tif") ||
      filename_lower_case.endsWith(".tiff");

    let is_pdf = filename_lower_case.endsWith(".pdf");

    if (is_image && options == undefined) {

      if (this.state.show_image_to_pdf_dialog) {
        this.image_to_pdf_waiting_items.push(file);
      } else {
        this.image_to_pdf = file;
        this.state.show_image_to_pdf_dialog = true;
        this.setState(this.state);
      }
      return;
    }

    if (is_image || is_pdf) {

      let guid = uuidv4();
      let filename = file.name;
      if (!filename_lower_case.endsWith(".pdf")) {
        let last_index = filename.lastIndexOf(".");
        filename = filename.substring(0, last_index) + ".pdf";
      }

      this.props.onUploadFileStart(guid, filename);

      var xhr = new XMLHttpRequest();
      xhr.withCredentials = true;
      xhr.upload.onprogress = (e) => {
        var done = e.loaded, total = e.total;
        let progress = Math.floor((done / total) * 1000) / 10;
        this.props.onUploadFileProgress(guid, progress);
      };

      xhr.open("POST",
        Settings.backendUrl +
        "/upload-pdf?name=" +
        encodeURIComponent(filename) +
        "&session_id=" +
        encodeURIComponent(this.props.session_id) +
        (options != undefined ?
          ("&dpi=" + options.dpi +
            "&resize_filter=" + options.resize_filter)
          : ""), true);

      xhr.onload = (e) => {

        if (xhr.status == 200) {
          this.props.onUploadFileFinish(guid, xhr.status, JSON.parse(xhr.response), xhr.responseText);
        } else {
          this.props.onUploadFileFinish(guid, xhr.status, {}, xhr.responseText);
          setTimeout(() => {
            this.props.onUploadFileErrorDismiss(guid);
          }, 3000);
        }
      };

      try {
        xhr.send(file);
      }
      catch (error) {
        console.log(xhr.status);
      }
    } else if (
      filename_lower_case.endsWith(".png") ||
      filename_lower_case.endsWith(".jpg") ||
      filename_lower_case.endsWith(".jpeg") ||
      filename_lower_case.endsWith(".tif") ||
      filename_lower_case.endsWith(".tiff")
    ) {


      if (options == undefined) {

        this.setState({ show_image_to_pdf_dialog: true });

      }

    }
  }

  ondragover(e) {
    e.preventDefault();
  }

  ondrop(e) {
    e.preventDefault();

    if (e.dataTransfer.items) {
      for (var i = 0; i < e.dataTransfer.items.length; i++) {
        if (e.dataTransfer.items[i].kind === "file") {
          var file = e.dataTransfer.items[i].getAsFile();
          this.uploadFile(file);
        }
      }
    } else {
      for (var i = 0; i < e.dataTransfer.files.length; i++) {
        var file = e.dataTransfer.files[i].name;
        this.uploadFile(file);
      }
    }
  }

  renderSortButtons() {

    var buttons = [];
    if (this.props.input_list_sort == "asc" || this.props.input_list_sort == "") {
      buttons.push(
        <TouchableOpacity onPress={() => { this.props.onInputListSort("asc") }}>
          <Image source={{ uri: "up-icon.png" }} style={[styles.sortIcon]}></Image>
        </TouchableOpacity>)
    } else {
      buttons.push(<View style={[styles.sortIcon]}></View>);
    }

    let sort_by_icon_path;

    if(this.props.sort_input_by == SortInputBy.NAME) {
      sort_by_icon_path = <path fill="currentColor" d="M3 7A2 2 0 0 0 1 9V17H3V13H5V17H7V9A2 2 0 0 0 5 7H3M3 9H5V11H3M15 10.5V9A2 2 0 0 0 13 7H9V17H13A2 2 0 0 0 15 15V13.5A1.54 1.54 0 0 0 13.5 12A1.54 1.54 0 0 0 15 10.5M13 15H11V13H13V15M13 11H11V9H13M19 7A2 2 0 0 0 17 9V15A2 2 0 0 0 19 17H21A2 2 0 0 0 23 15V14H21V15H19V9H21V10H23V9A2 2 0 0 0 21 7Z" />;
    } else if(this.props.sort_input_by == SortInputBy.UPLOADED_AT) {
      sort_by_icon_path = <path fill="currentColor" d="M12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22C6.47,22 2,17.5 2,12A10,10 0 0,1 12,2M12.5,7V12.25L17,14.92L16.25,16.15L11,13V7H12.5Z" />;
    } else if(this.props.sort_input_by == SortInputBy.CONFIGS_UPDATED_AT) {
      sort_by_icon_path = <path fill="currentColor" d="M14.06,9L15,9.94L5.92,19H5V18.08L14.06,9M17.66,3C17.41,3 17.15,3.1 16.96,3.29L15.13,5.12L18.88,8.87L20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18.17,3.09 17.92,3 17.66,3M14.06,6.19L3,17.25V21H6.75L17.81,9.94L14.06,6.19Z" />;
    }

    let sort_by_button = <IconButtonMUI style={{padding: 5}} onClick={() => { this.props.onSortInputByChange() }}>
      <SvgIcon>
        {sort_by_icon_path}
      </SvgIcon>
    </IconButtonMUI>;

    if (this.props.input_list_sort == "desc" || this.props.input_list_sort == "") {
      buttons.push(
        <TouchableOpacity onPress={() => { this.props.onInputListSort("desc") }}>
          <Image source={{ uri: "down-icon.png" }} style={[styles.sortIcon]}></Image>
        </TouchableOpacity>)
    } else {
      buttons.push(<View style={[styles.sortIcon]}></View>);
    }

    return (
      <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
        <View>
          {buttons}
        </View>
        {sort_by_button}
      </div>
    );
  }

  handleImageToPDFDialogClose() {

  }

  handleImageToPDFOptionsResizeFilterChange(e) {
    this.state.image_to_pdf_options.resize_filter = e.target.value;
    this.setState(this.state);
  }

  handleImageToPDFInputChange(e) {

    try {
      let dpi = "";
      if (e.target.value !== "") {
        dpi = parseInt(e.target.value);
      }
      if ((dpi != NaN && dpi > 0) || dpi === "") {

        this.state.image_to_pdf_options.dpi = dpi;

        this.setState(this.state);
      }
    } catch (error) {

    }
  }

  renderImageToPDFDialog() {

    const { t } = this.props;

    return (
      <Dialog open={true} onClose={this.handleImageToPDFDialogClose.bind(this)}>
        <DialogTitle>
          {t("image_to_pdf_conversion")}
          <Typography>{`${t("filename")}: ${this.image_to_pdf.name}`}</Typography>
        </DialogTitle>

        <div style={{ marginLeft: 24, marginRight: 24 }}>

          <Typography>{t("specify_dpi_settings_for_resizing_the_image")}</Typography>
          <TextField value={this.state.image_to_pdf_options.dpi} onChange={this.handleImageToPDFInputChange.bind(this)} label={t("dpi")} variant="outlined" />
          <Typography style={{ marginTop: 16 }}>{`${t("choose_resize_filter")}:`}</Typography>
          <Select

            value={this.state.image_to_pdf_options.resize_filter}
            onChange={this.handleImageToPDFOptionsResizeFilterChange.bind(this)}>
            <MenuItem value={1}>{t("nearest_neighbor")}</MenuItem>
            <MenuItem value={2}>{t("gaussian")}</MenuItem>
            <MenuItem value={3}>{t("triangle")}</MenuItem>
          </Select>

          <RipeButton icon="file-upload-outline" style={{ marginTop: 16, marginBottom: 16 }} onPress={
            () => {
              this.uploadFile(this.image_to_pdf, this.state.image_to_pdf_options);
              if (this.image_to_pdf_waiting_items.length > 0) {
                this.image_to_pdf = this.image_to_pdf_waiting_items.shift();
                this.state.image_to_pdf_options.dpi = "";
                this.state.image_to_pdf_options.resize_filter = 1;
                this.setState(this.state);
              } else {
                this.setState({ show_image_to_pdf_dialog: false });
              }
            }
          }>{t("upload")}</RipeButton>
        </div>
      </Dialog>
    )
  }

  render() {

    const { t } = this.props;

    if (!this.props.data_loaded) {
      return null;
    }

    var output_settings_override_view = null;
    var size_and_position = null;
    var color_management = null;
    var ink_management = null;

    var config = this.props.output_configs[this.props.selected_printhead_config_id];
    var input = this.props.inputs.find((item) => { return item.guid == this.props.selected_pdf_guid; });

    if (this.props.output_settings_override != null) {

      output_settings_override_view = <OutputSettingsOverrideView
        onChange={(value) => { this.props.onOutputSettingsOverrideChange(value) }}
        override={this.props.output_settings_override}
        profiles={this.props.icc_profiles}
        map_oem_label={this.props.map_oem_label}
        inch={this.props.inch}
        config={config}
        outputs={this.props.selectable_outputs}
        printers={this.props.printers}
        screening_configs={this.props.screening_configs}
        pages={input.num_of_pages}
        pages_to_print_disabled={this.props.pages_to_print_disabled}>
      </OutputSettingsOverrideView>;
    }

    if (this.props.image_size_position != null) {

      size_and_position = <SizeAndPositionControlsView sessionId={this.props.session_id} inch={this.props.inch} onInchChange={() => { this.props.onToggleUnit() }} onImageSizePositionChange={this.handleImageSizePositionChange.bind(this)} imageSizePosition={this.props.image_size_position} totalPrintWidth={config.total_print_width_mm} origWidth={input.width_mm} origHeight={input.height_mm}></SizeAndPositionControlsView>;
    }

    if (this.props.ink_management_config != null) {

      var selected_output = this.props.selectable_outputs.find((value) => { return value.code == this.props.output_settings_override.output_code && value.printer_output == this.props.output_settings_override.printer_output; });

      if (selected_output.show_ink_levels) {
        ink_management = <InkManagementView onChange={(values) => { this.props.onInkManagementChange(values) }} sessionId={this.props.session_id} configGuid={config.guid} pdfGuid={input.guid} config={this.props.ink_management_config}></InkManagementView>
      }
      if (selected_output.show_color_management) {
        // colorManagement = <ColorManagementView onChange={this.handleColorManagementViewChange.bind(this)} sessionId={this.props.session_id} configGuid={config.guid} pdfGuid={input.guid} config={this.props.color_management_config}></ColorManagementView>
      }
    }
    let thumbnail_view = null;
    if (config != undefined && input != undefined && this.props.image_size_position != null) {
      thumbnail_view = <ThumbnailView onPageNumberChange={(value) => { this.props.onThumbnailPageNumberChange(value) }} page_number={this.props.page_number} config={config} input={input} thumbnail_url={this.props.thumbnail_url} live_thumbnail_url={this.props.live_thumbnail_url} image_size_position={this.props.image_size_position}></ThumbnailView>
    }

    let image_to_pdf_dialog;
    if (this.state.show_image_to_pdf_dialog) {
      image_to_pdf_dialog = this.renderImageToPDFDialog();
    }

    let source_file_list_key = this.props.inputs.map(item => item.guid).join();

    let output_file_list_key = this.props.outputs.map(item => item.filename).join();

    return (
      <View style={[styles.contentContainer, this.props.style]}>
        { image_to_pdf_dialog}
        <View style={styles.leftContainer}>
          <CollapseView key={source_file_list_key} headerAlignment="left" headerText={t("source_file_list").toUpperCase()} onCollapse={(collapse) => { this.sourceFileListCollapse = collapse; this.saveCollapseConfig(); }} collapse={this.sourceFileListCollapse} renderView={this.renderInputFileList.bind(this)}></CollapseView>
          <CollapseView headerAlignment="left" headerText={t("upload").toUpperCase()} onCollapse={(collapse) => { this.uploadCollapse = collapse; this.saveCollapseConfig(); }} collapse={this.uploadCollapse} renderView={this.renderUploadView.bind(this)}></CollapseView>

        </View>

        <View style={{ flex: 1, minWidth: 200 }}>
          {this.renderConfigsPicker()}
          {thumbnail_view}
        </View>


        <View style={styles.rightContainer} >
        <View key={(config != undefined ? config.guid : "") + (input != undefined ? input.guid: "")}>
          <CollapseView headerText={t("print_control").toUpperCase()}
            onCollapse={(collapse) => { this.print_control_collapse = collapse; this.saveCollapseConfig(); }}
            collapse={this.print_control_collapse}
            renderView={ this.renderComponentPrintControl.bind(this) }>
          </CollapseView>
          <CollapseView key={output_file_list_key} headerText={t("output_file_list").toUpperCase()} onCollapse={(collapse) => { this.outputFileListCollapse = collapse; this.saveCollapseConfig(); }} collapse={this.outputFileListCollapse} renderView={this.renderOutputFileList.bind(this)}></CollapseView>
        </View>
        <View>
          {size_and_position}
          {ink_management}
          {color_management}
          {output_settings_override_view}
        </View>
        </View>
      </View>
    );
  }

  renderComponentPrintControl() {

    let pages = 1;

    let input = this.props.inputs.find((item) => { return item.guid == this.props.selected_pdf_guid; });
    if(input != undefined) {
      pages = input.num_of_pages;
    }

    return (
      <ComponentPrintControl 
        outputs={this.props.selectable_outputs}
        pages={pages}
        output_settings_override={this.props.output_settings_override} 
        can_generate_output={this.props.can_generate_output}
        can_clear_output={this.props.can_clear_output}
        onGenerate={(generate_type_id) => { this.handleGeneratePress(generate_type_id, "") }}
        onDeleteOutput={ () => { this.props.onDeleteOutput() }}
        onOutputSettingsOverrideChange={ (value) => { this.props.onOutputSettingsOverrideChange(value) } }
        pages_to_print_disabled={this.props.pages_to_print_disabled}
        >

      </ComponentPrintControl>
    )
  }

  handleImageSizePositionChange(value) {
    this.props.onImageSizePositionChange(value);
  }

  renderUploadView() {

    const { t } = this.props;

    return (
      <View style={{ paddingTop: 10, paddingLeft: 2, paddingRight: 2 }}>
        <div onDrop={(e) => { this.ondrop(e) }} onDragOver={(e) => { this.ondragover(e) }}>
          <View style={styles.dropdown}>
            <Text>{t("drop_pdf_files_here").toUpperCase()}</Text>
            <Image source={{ uri: "upload-icon.png" }} style={styles.uploadIcon}></Image>
          </View>
        </div>
        <RipeButton icon="file-upload-outline" style={{ marginTop: 10 }} onPress={this.uploadPDFFilesViaFileBrowserPress.bind(this)}>{t("upload_pdf_files_via_file_browser").toUpperCase()}</RipeButton>
        <input ref={(fileInput) => { this.fileInput = fileInput; }} type='file' hidden onChange={this.pdfFilesSelectedForUpload.bind(this)} multiple accept=".png,.jpg,.jpeg,application/pdf,image/tiff" />
      </View>
    );
  }

  uploadPDFFilesViaFileBrowserPress() {
    this.fileInput.click();
  }

  pdfFilesSelectedForUpload(e) {

    for (var i = 0; i < this.fileInput.files.length; i++) {
      this.uploadFile(this.fileInput.files[i]);
    }

    this.fileInput.value = "";
  }

  renderInputFileList() {
    
    const { t } = this.props;

    return (
      <View style={{ paddingTop: 10, paddingLeft: 2, paddingRight: 2 }}>
        <View style={styles.sourceHeaderContainer}>
          <div style={{flexDirection: "row", display: "flex", flex: 1}}>
            <Input placeholder={t("filter_by_name")} style={{height: 34, width: "100%"}} shrink={false} value={this.props.input_list_filter} onChange={ (e) => { this.props.onFilterInputChange(e.currentTarget.value); } }/>
            {this.renderSortButtons()}
          </div>
          <ComponentFilterByLabel style={{flex: 1}} labels={this.props.labels} selected_labels={this.props.filter_by_labels}
           onSelectLabel={(values) => { this.props.onFilterByLabel(values) }}></ComponentFilterByLabel>
        </View>

        <FlatList
          style={styles.fileList}
          data={this.props.filtered_inputs}
          renderItem={this.renderInput.bind(this)}
          keyExtractor={item => item.guid}
          extraData={this.props} />
      </View>
    );
  }

  renderOutputFileList() {

    const { t } = this.props;

    var selectInputPDFHint = null;

    if (this.props.selected_pdf_guid === "") {
      selectInputPDFHint = <Text>{t("select_a_source_pdf_to_view_or_generate_output_files")}</Text>;
    }


    return (
      <View style={{ paddingTop: 10, paddingLeft: 2, paddingRight: 2 }}>
        {selectInputPDFHint}
        <FlatList
          style={styles.fileList}
          data={this.props.outputs}
          renderItem={this.renderItemOutput.bind(this)}
          keyExtractor={item => item.guid}
          extraData={this.props} />
        { this.renderDownloadZIPButton()}
      </View>
    );
  }

  renderDownloadZIPButton() {
    const { t } = this.props;

    if (this.props.can_clear_output) {
      return (
        <div style={{display: "flex", flexDirection: "row", marginBottom: 10}}>
          <RipeButton 
          style={{marginRight: 10}}
          icon={{ uri: "trash-can-outline.svg" }} 
          onPress={() => { this.props.onDeleteOutput() }}>
            {t("delete_output_files")}
          </RipeButton>
          <RipeButton style={{flex: 1}} onPress={() => { this.props.onOutputDownloadZip() }} icon="zip-box">{t("download_output_files")}</RipeButton>
        </div>
      );
    }
    return null;
  }
}



const itemHighlightColor = "#cee9ff";
const itemColor = "#FFFFFF";

const buttonColor = itemColor;

const styles = StyleSheet.create({
  contentContainer: {
    flex: 1, flexDirection: "row", padding: 15
  },
  sortIcon: {
    width: 16,
    height: 16
  },
  filterSourceFileList: {
    height: 38,
    flex: 1,
    borderBottomWidth: 2,
    borderBottomColor: "#75757d"
  },
  leftContainer: {
    marginRight: 100, minWidth: 510, maxWidth: 510
  },
  rightContainer: {
    marginLeft: 100, minWidth: 510, maxWidth: 510
  },
  fileList: {
    flexGrow: 0,
    marginBottom: 10
  },
  fileListText: {

  },
  splitButton: {
    backgroundColor: itemColor,
    padding: 10
  },
  selectedSplitButton: {
    backgroundColor: itemHighlightColor,
    padding: 10
  },
  splitButtonsContainer: {
    flexDirection: "row", justifyContent: "center"
  },
  button: {
    backgroundColor: buttonColor, padding: 10
  },
  buttonText: {
    alignSelf: "center"
  },
  dropdown: {
    justifyContent: "center", alignItems: "center", height: "100px", borderWidth: "2px", borderColor: "#75757d", borderStyle: "dashed", backgroundColor: "white"
  },
  sourceHeaderContainer: {
    flexDirection: "row",
    marginBottom: 10
  },
  splitHeaderContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    marginBottom: 10
  },
  downloadIcon: {
    width: 24, height: 24
  },
  itemIcon: {
    width: 24, height: 24
  },
  item: {
    backgroundColor: itemColor,
    height: 46,
    marginVertical: 1,
    justifyContent: "center"
  },
  selectedItem: {
    backgroundColor: itemHighlightColor,
    height: 46,
    justifyContent: "center",
    marginVertical: 1,
  },
  sourceFilename: {
    fontSize: 14,
    marginLeft: 10,
    marginRight: 10
  },
  splitFilename: {
    fontSize: 14,
    marginLeft: 10
  },
  uploadIcon: {
    width: 48,
    height: 48
  }
});

export default withTranslation()(PDFSplittingPage);