import React from 'react';
import { ComponentResourceLibraryMain, ComponentResourceLibrarySide, ComponentLog, LayoutResourceLibraryMain } from 'ripe.digital-ts-react-components';

export class JobLibraryPage extends React.Component {

  resource_info; 

  constructor(props) {
    super(props);

    let thumbnails = [];

    for(let i = 1; i <= 50; i++) {
  
       let avatar_id = (Math.floor(Math.random() * 6) + 1);

        thumbnails.push({ 
          image_url: "https://picsum.photos/300?test=" + i, 
          filename: 'img' + i + ".pdf",
          selected: false,
          owner_img_urls: 
          [
            "https://www.w3schools.com/w3images/avatar" + avatar_id + ".png"
          ],
          date: new Date(),
          cloud: i % 2 == 0
        });

        if(Math.floor(Math.random() * 2) == 0) {
          thumbnails[i - 1].owner_img_urls.push(
            "https://www.w3schools.com/w3images/avatar" + (avatar_id == 6 ? 1 : avatar_id + 1) + ".png"
          );
        }
    }
  
    let log_entries = [];
    for(let i = 1; i <= 50; i++) {
      log_entries.push({ timestamp: new Date(), message: "Uploaded PDF: img" + i + ".pdf"});
    }

    let resource_lib_info = [];
    resource_lib_info.push({name: "Files", value: "50"});
    resource_lib_info.push({name: "Size", value: "161 MB"});
    
    let resource_info = [];
    resource_info.push({name: "Width", value: "2480 px"});
    resource_info.push({name: "Height", value: "3508 px"});
    resource_info.push({name: "DPI", value: "300x300"});
    resource_info.push({name: "Mode", value: "RGB"});
    resource_info.push({name: "Size", value: "3.22 MB"});

    this.resource_info = resource_info;

    this.state = {
      show_log: false,
      sort_text: "NAME",
      selected_tab_id: 0,
      layout: LayoutResourceLibraryMain.GRID,
      layout_text: "LIST",
      log_entries: log_entries,
      thumbnails: thumbnails,
      filtered_thumbnails: thumbnails,
      multiselect: false,
      show_selected: false,
      show_clear_selected: false,
      copies: 1,
      resource_info: resource_info,
      resource_lib_info: resource_lib_info
    }
  }

  renderJobLibraryPage() {

    return (
        <div style={{display: "flex", flexDirection: "row", height: "100%"}}>
            <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
            <ComponentResourceLibraryMain 
                show_owners={false}
                onShowSelectedToggle={
                () => {
                    let filtered_thumbnails;
                    if(!this.state.show_selected) {
                    filtered_thumbnails = this.state.thumbnails.filter((item) => {
                        return item.selected;
                    });
                    } else {
                    filtered_thumbnails = this.state.thumbnails;
                    }
    
                    this.setState({show_selected: !this.state.show_selected, filtered_thumbnails: filtered_thumbnails});
                }
                }
                show_selected={this.state.show_selected}
                onMultiSelectToggle={
                () => {
                    if(this.state.multiselect) {
                    for(let i = 0; i < this.state.filtered_thumbnails.length; i++) {
                        this.state.filtered_thumbnails[i].selected = false;
                    }
                    }
                    this.setState({multiselect: !this.state.multiselect, filtered_thumbnails: this.state.filtered_thumbnails});
                }
                }
                multiselect={this.state.multiselect}
                layout={this.state.layout}
                onLayoutClick={
                () => {
                    if(this.state.layout == LayoutResourceLibraryMain.GRID) {
                      this.setState({layout: LayoutResourceLibraryMain.LIST, layout_text: "GRID"});
                    } else {
                    this.setState({layout: LayoutResourceLibraryMain.GRID, layout_text: "LIST"});
                    }
                }
    
                }
                layout_text={this.state.layout_text}
                onThumbnailClick={
                (id) => {
                    if(this.state.multiselect) {
                    this.state.filtered_thumbnails[id].selected = !this.state.filtered_thumbnails[id].selected;
                    } else {
                    let item_was_selected = this.state.filtered_thumbnails[id].selected;
                    for(let i = 0; i < this.state.filtered_thumbnails.length; i++) {
                        this.state.filtered_thumbnails[i].selected = false;
                    }
                    this.state.filtered_thumbnails[id].selected = !item_was_selected;
                    }
                    let selected_items = this.state.thumbnails.filter((item) => { return item.selected; }).length;
                    let show_clear_selected = selected_items > 0;
    
                    this.setState({ 
                    filtered_thumbnails: this.state.filtered_thumbnails, 
                    show_clear_selected: show_clear_selected,
                    resource_info: selected_items > 1 ? 
                    [{ name: "Total Size", value: (Math.round(3.22 * selected_items * 100) / 100) + " MB" }] : this.resource_info
                    });
    
                }
                }
                sort_text={this.state.sort_text}
                onSortClick={
                () => {
                    let sort_text = "";
                    if(this.state.sort_text == "NAME") {
                    sort_text = "DATE";
                    } else if(this.state.sort_text == "DATE") {
                    sort_text = "OWNER";
                    } else {
                    sort_text = "NAME";
                    }
                    this.setState({sort_text: sort_text})
                }
                }
                show_log={this.state.show_log}
                onShowLogToggle={() => { 
                this.setState({show_log: !this.state.show_log})
                }}
                thumbnail_size={{width: 258, height: 258}} 
                thumbnails={this.state.filtered_thumbnails}>
    
            </ComponentResourceLibraryMain>
            <div>
                <div style={{height: this.state.show_log ? 90: 0}}>
                  <ComponentLog log_entries={this.state.log_entries}></ComponentLog>
                </div>
            </div>
            </div>
            <div style={{ display: "flex", width: 180, minWidth: 180}}>
                <ComponentResourceLibrarySide
                header_text_control="IMAGE CONTROL"
                header_text_library_info="FOLDER INFO"
                header_text_resource_info="IMAGE INFO"
                resource_info={this.state.resource_info}
                resource_library_info={this.state.resource_lib_info}
                onClearSelected={
                    () => {
                    
                    for(let i = 0; i < this.state.filtered_thumbnails.length; i++) {
                        this.state.filtered_thumbnails[i].selected = false;
                    }
    
                    let filtered_thumbnails;
                    if(this.state.show_selected) {
                        filtered_thumbnails = this.state.thumbnails.filter((item) => {
                        return item.selected;
                        });
                    } else {
                        filtered_thumbnails = this.state.thumbnails;
                    }
                    
                    this.setState({filtered_thumbnails: filtered_thumbnails, show_clear_selected: false});
                    }
                }
                show_clear_selected={this.state.show_clear_selected}
                onCopiesChange={
                    (copies) => {
                    if(copies == 0) {
                        copies = 1;
                    }
                    this.setState({copies: copies});
                    }
                }
                copies={this.state.copies}
                ></ComponentResourceLibrarySide>
            </div>
    
        </div>
    );
  }

  render() {
    return (
      <div style={{display: "flex", flexDirection: "column", height: "100%"}}>
        {this.renderJobLibraryPage()}
      </div>
    );
  }
}
