import Settings from "./Settings";

import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { DataTable } from "react-native-paper";
import { RipeButtonSave } from "./RipeButtonSave";

import TextField from '@material-ui/core/TextField';

import {
  StyleSheet,
  Text,
  FlatList,
  TouchableOpacity,
  TouchableHighlight,
  ImageBackground,
  Image,
  View,
  TextInput,
  SafeAreaView,
  Picker,
  Switch,
} from 'react-native';

import moment from "moment";

import { colors, styles as global_styles } from "./Styles";

import { Checkbox } from 'react-native-paper';

import Utils from "./Utils";

import Messages from "./Messages";

import { RipeButton } from "./RipeButton";

import RipeButtonDelete from "./RipeButtonDelete";

import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core/styles';
import { appcore } from "./appcore_ripe_proto";

const styles = {
    accordion_content: {
        '&:hover': {
            cursor: "auto"
        }
    },
    accordion_root: {
        '&:hover': {
            cursor: "auto"
        }
    },
    license_date_container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    license_date_textfield: {
    }
};

class AdministrationPage extends Component {

    componentWillUnmount() {    
        Utils.removeHandler(Utils.MessageId.QR_MESSAGE, this.qrMessageHandler);
    }

    state = {
        users: [],
        outputs: [],
        checked: true
    }

    handleQrMessage(data)  {
        var qrMessage = Messages.QrMessageType.decode(data);
        if(qrMessage.session_id === this.props.sessionId) {
          if(qrMessage.identifier === Utils.QrMessageId.ADMINISTRATION_DATA) {
            var data = Messages.AdministrationData.decode(qrMessage.data)
            var users = data.users;
            var outputs = data.outputs;

            for(let i = 0; i < users.length; i++) {
                for(let j = 0; j < users[i].rip_computers.length; j++) {
                    users[i].rip_computers[j].saved = true;
                }
            }

            this.setState({users: users, outputs: outputs});
          }
        }
      }
        
    componentWillMount() {
        this.qrMessageHandler = this.handleQrMessage.bind(this);
        Utils.setHandler(Utils.MessageId.QR_MESSAGE, this.qrMessageHandler);
        var qrMessage = Messages.QrMessageType.encode({ session_id: this.props.sessionId, identifier: Utils.QrMessageId.GET_ADMINISTRATION_DATA }).finish();
        Utils.sendMessage(Utils.MessageId.QR_MESSAGE, qrMessage);
    }

    constructor () {
        super();
    }

    handleDeactivateClick(userId) {
        var d = Messages.QrMessageType.encode({ 
            identifier: Utils.QrMessageId.DEACTIVATE_USER, 
            session_id: this.props.sessionId, 
            data:  Messages.ActivateUserType.encode({ user_id: userId }).finish() })
        .finish();
        Utils.sendMessage(Utils.MessageId.QR_MESSAGE, d);
    }

    handleActivateClick(userId) {
        var d = Messages.QrMessageType.encode({ 
            identifier: Utils.QrMessageId.ACTIVATE_USER, 
            session_id: this.props.sessionId, 
            data:  Messages.ActivateUserType.encode({ user_id: userId }).finish() })
        .finish();
        Utils.sendMessage(Utils.MessageId.QR_MESSAGE, d);
    }

    handleLoginAsClick(userId) {
        var d = Messages.QrMessageType.encode({ 
            identifier: Utils.QrMessageId.LOGIN_AS_USER, 
            session_id: this.props.sessionId, 
            data:  Messages.LoginAsUserType.encode({ user_id: userId }).finish() })
        .finish();
        Utils.sendMessage(Utils.MessageId.QR_MESSAGE, d);
    }

    handleSaveClick(userId) {

        let user = this.state.users.find((user) => user.id == userId);

        var d = Messages.QrMessageType.encode({ 
            identifier: Utils.QrMessageId.SAVE_USER, 
            session_id: this.props.sessionId, 
            data:  Messages.SaveUserType.encode({ id: userId, name: user.name, phone: user.phone, email: user.email }).finish() })
        .finish();
        Utils.sendMessage(Utils.MessageId.QR_MESSAGE, d);
    }

    handleDeletePress(userId) {
        var d = Messages.QrMessageType.encode({ 
            identifier: Utils.QrMessageId.DELETE_USER, 
            session_id: this.props.sessionId, 
            data:  Messages.DeleteUserType.encode({ id: userId }).finish() })
        .finish();
        Utils.sendMessage(Utils.MessageId.QR_MESSAGE, d);
    }

    removeOutput(user_id, code) {
        var d = Messages.QrMessageType.encode({ 
            identifier: Utils.QrMessageId.REMOVE_OUTPUT_FROM_USER, 
            session_id: this.props.sessionId, 
            data:  Messages.SetOutputForUserType.encode({ user_id: user_id, output_code: code }).finish() })
        .finish();
        Utils.sendMessage(Utils.MessageId.QR_MESSAGE, d);
    }

    addOutput(user_id, code) {
        var d = Messages.QrMessageType.encode({ 
            identifier: Utils.QrMessageId.ADD_OUTPUT_TO_USER, 
            session_id: this.props.sessionId, 
            data:  Messages.SetOutputForUserType.encode({ user_id: user_id, output_code: code }).finish() })
        .finish();
        Utils.sendMessage(Utils.MessageId.QR_MESSAGE, d);
    }

    handleSaveRIPComputerPress(rip_computer, user) {
        var qrMessage = Messages.QrMessageType.encode(
            { 
              session_id: this.props.sessionId, 
              identifier: Utils.QrMessageId.SAVE_RIP_COMPUTER_ADMIN, 
              data: Messages.SaveRIPComputerType.encode(
                  { 
                    id: rip_computer.id,
                    has_license: rip_computer.has_license,
                    license_expiration: rip_computer.license_expiration,
                    user_id: user.id
                 }
              ).finish()
            }).finish();

        Utils.sendMessage(Utils.MessageId.QR_MESSAGE, qrMessage);
        rip_computer.saved = false;
        this.setState(this.state);
    }

    render() {

        const { t } = this.props;

        var users = [];
        for(var i = 0; i < this.state.users.length; i++) {

            var button = null;
            if(this.state.users[i].active) {
                button = 
                <RipeButton style={{width: 150}} onPress={this.handleDeactivateClick.bind(this, this.state.users[i].id)}>{t("deactivate").toUpperCase()}</RipeButton>;
            } else {
                button = <RipeButton style={{width: 150}} onPress={this.handleActivateClick.bind(this, this.state.users[i].id)}>{t("activate").toUpperCase()}</RipeButton>;
            }

            var outputs = [];
            for(var j = 0; j < this.state.outputs.length; j++) {

                var user_output_codes = this.state.users[i].output_codes;
                var code = this.state.outputs[j].code;
                var output_enabled = user_output_codes.indexOf(code) != -1;

                outputs.push(
                    <View style={{flexDirection: "row", alignItems: "center"}}>
                        <Checkbox style={{marginRight: 10}} status={output_enabled ? 'checked' : 'unchecked'} color={colors.checkbox} 
                        onPress={ output_enabled ? this.removeOutput.bind(this, this.state.users[i].email, this.state.outputs[j].code) :
                            this.addOutput.bind(this, this.state.users[i].email, this.state.outputs[j].code)}></Checkbox>
                        <Text>{this.state.outputs[j].name}</Text>
                    </View>
                );
            }

            let d_str;
            if(this.state.users[i].last_login_time != 0) {
                let d = new Date(this.state.users[i].last_login_time * 1000);
                d_str = d.toLocaleDateString() + " " + d.toLocaleTimeString();
            } else {
                d_str = "-";
            }

            let user = this.state.users[i];

            let rows = [];
            for(var j = 0; j < this.state.users[i].rip_computers.length; j++) {
                
                let rip_computer = this.state.users[i].rip_computers[j];

                let expiration_date = "";
                if(rip_computer.has_license) {
                    if(rip_computer.license_expiration != 0) {
                        expiration_date = moment(rip_computer.license_expiration * 1000).format("YYYY-MM-DD");
                    } else {
                        let date = moment().add(1, 'd');
                        expiration_date = date.format("YYYY-MM-DD");
                        rip_computer.license_expiration = date.unix();
                    }
                }

                rows.push(
                    <DataTable.Row>
                        <DataTable.Cell style={{maxWidth: 300}}>
                            <View style={{flexDirection: "row"}}>
                                <RipeButtonSave showTime={3000} style={{marginLeft: 10}} saved={rip_computer.saved} showTime="3000" onPress={this.handleSaveRIPComputerPress.bind(this, rip_computer, user)}>{t("save")}</RipeButtonSave>
                            </View>
                        </DataTable.Cell>
                        <DataTable.Cell>
                            <Text>{rip_computer.name}</Text>
                        </DataTable.Cell>
                        <DataTable.Cell>
                        <Checkbox
                            color={colors.checkbox} 
                            status={rip_computer.has_license ? 'checked' : 'unchecked'}
                            onPress={ () => {
                                rip_computer.has_license = !rip_computer.has_license;
                                this.setState(this.state);
                            }}/>
                        </DataTable.Cell>
                        <DataTable.Cell>
                            <form className={this.props.classes.license_date_container} noValidate>
                                <TextField
                                    onChange={(e) => {
                                        let date = new Date(e.currentTarget.value);
                                        rip_computer.license_expiration = date.getTime() / 1000;
                                        this.setState(this.state);
                                    }}
                                    id="date"
                                    type="date"
                                    value={expiration_date}
                                    className={this.props.classes.license_date_textfield}
                                    InputLabelProps={{
                                    shrink: true
                                    }}
                                />
                            </form>
                        </DataTable.Cell>
                    </DataTable.Row>
                );
            }
    
            let rip_computer_view = 
                <View style={[{ width: "100%" }]}>
                    <DataTable  style={{maxWidth: 920}}> 
                        <DataTable.Header>
                            <DataTable.Title><Text>{t("rip_computers")}</Text></DataTable.Title>
                            <DataTable.Title><Text>{t("name")}</Text></DataTable.Title>
                            <DataTable.Title><Text>{t("has_license")}</Text></DataTable.Title>
                            <DataTable.Title><Text>{t("license_expiration")}</Text></DataTable.Title>
                        </DataTable.Header>
                        { rows }
                    </DataTable>
                </View>;

            users.push(
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} classes={{content: this.props.classes.accordion_content, root: this.props.classes.accordion_root}}>
                    <View style={{flexDirection: "row", alignItems: "center", width: "100%"}} onClick={(e) => { e.stopPropagation(); }}>
                        { button }
                        <RipeButton style={{width: 150, marginLeft: 10}} onPress={this.handleLoginAsClick.bind(this, this.state.users[i].id)}>{t("login_as")}</RipeButton>
                        <RipeButton style={{width: 150, marginLeft: 10}} onPress={this.handleSaveClick.bind(this, this.state.users[i].id)}>{t("save")}</RipeButton>
                        <RipeButtonDelete style={{width: 150, marginLeft: 10}} timeout="10000" onPress={this.handleDeletePress.bind(this, this.state.users[i].id)}></RipeButtonDelete>
                        <TextInput value={this.state.users[i].name}  style={[global_styles.inputStyle, {flex: 1, textAlign: "center", height: 36, marginLeft: 10, marginRight: 10}]} onChangeText={this.handleNameChange.bind(this, this.state.users[i].id)}></TextInput>
                        <TextInput value={this.state.users[i].phone} style={[global_styles.inputStyle, {flex: 1, textAlign: "center", height: 36, marginRight: 10}]} onChangeText={this.handlePhoneChange.bind(this, this.state.users[i].id)}></TextInput>
                        <TextInput value={this.state.users[i].email} style={[global_styles.inputStyle, {flex: 1, textAlign: "center", height: 36, marginRight: 10}]} onChangeText={this.handleEmailChange.bind(this, this.state.users[i].id)}></TextInput>
                        <Text style={{flex: 1, textAlign:"center",  marginRight: 10, alignSelf: "center"}}>{d_str}</Text>
                        <View style={{flexWrap: "wrap", flex: 3, flexDirection: "column", height: 100, alignSelf: "center"}}>
                            {outputs}
                        </View>
                    </View>
                </AccordionSummary>
                <AccordionDetails>
                    { rip_computer_view }
                </AccordionDetails>
            </Accordion>)
        }

        return (
            <View style={{padding: 15}}>
                {users}
            </View>
        );
    }

    setUserProp(id, value, prop) {
        let user = this.state.users.find((user) => id == user.id);
        user[prop] = value;
        this.setState(this.state);
    }

    handleNameChange(id, value) {
        this.setUserProp(id, value, "name");
    }

    handlePhoneChange(id, value) {
        this.setUserProp(id, value, "phone");
    }

    handleEmailChange(id, value) {
        this.setUserProp(id, value, "email");
    }
}
const itemHighlightColor = "#cee9ff";
const localStyles = StyleSheet.create({
    inputStyle: {
        borderBottomWidth: 2,
        borderBottomColor: "#75757d",
        textAlign: "right",
        marginLeft: 10
    },
    button: {
        backgroundColor: "#FFFFFF", padding: 10
    },
    buttonText: {
        alignSelf: "center"
    }
  });
  
  export default withTranslation()(withStyles(styles)(AdministrationPage));