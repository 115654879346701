import React, { Component } from 'react';
import { CollapseView } from "./CollapseView";

import Utils from "./Utils";

import Settings from "./Settings";

import {
    Text,
    View,
    TouchableOpacity,
    Image,
    Picker,
    Switch,
    StyleSheet
  } from 'react-native';

import { TextInput, IconButton } from "react-native-paper";
import { withTranslation } from "react-i18next";
import { styles, colors, themes} from "./Styles";
import Messages from './Messages';

class PGMUploadComponent extends Component {

    state = {
        
    }

    collapse = false;

    constructor(props) {
        super(props);
        this.state.item = props.item;
    }

    render() {
        return (
            <View style={this.props.style}>
                <CollapseView renderHeader={this.renderHeader.bind(this)} collapse={this.collapse} renderView={this.renderView.bind(this)} onCollapse={ (collapse) => { this.collapse = collapse; } }>

                </CollapseView>
            </View>
        );
    }

    handleDeletePress() {
        var deleteInputData = Messages.DeletePGMFileType.encode({ guid: this.props.configGuid, color: this.props.color }).finish();
        var qrMessageData = Messages.QrMessageType.encode({ identifier: Utils.QrMessageId.DELETE_PGM_FILE, data: deleteInputData, session_id: this.props.sessionId }).finish();
        Utils.sendMessage(Utils.MessageId.QR_MESSAGE, qrMessageData);
    }

    async downloadPress() {
        var response = await fetch(Settings.backendUrl + "/pgm_files/" + this.state.item.filename + "?session_id=" + encodeURIComponent(this.props.sessionId));

        const url = window.URL.createObjectURL(await response.blob());
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;

        a.download = this.state.item.name;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    }

    renderView() {
        return (this.renderItem());
    }

    renderItem() {
        
        const { t } = this.props;

        if (!this.state.item.name && !this.state.item.errorText) {
            return null;
        }

        if (this.state.item.uploading) {

            return (
                <TouchableOpacity style={localStyles.item} disabled={true}>
                    <View style={{ flex: 1, flexDirection: "row", justifyContent: "space-between" }}>
                        <Text style={{ fontSize: 14, marginLeft: 10, alignSelf: "center" }}>
                            {this.state.item.name}
                        </Text>
                        <Text style={{ alignSelf: "center", marginRight: 10 }}>
                            {`(${t("uploading")}: ${this.state.item.uploadPercentage}%)`}
                        </Text>
                    </View>
                </TouchableOpacity>
            );
        }


        if (this.state.item.error) {
            return (
                <TouchableOpacity style={localStyles.item} disabled={true}>
                    <View style={{ flex: 1, flexDirection: "row", justifyContent: "space-between" }}>
                        <Text style={{ fontSize: 14, marginLeft: 10, alignSelf: "center", color: "#FF0000" }}>
                            {this.state.item.errorText}
                        </Text>
                    </View>
                </TouchableOpacity>
            );
        }

        return (
            <TouchableOpacity style={localStyles.item}>
                <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                    <Text style={{ fontSize: 14, alignSelf: "center", marginLeft: 10 }}>{this.state.item.name}</Text>
                    <View style={{ flexDirection: "row" }}>

                        <IconButton icon="delete" style={{ margin: 0 }} size={24} onPress={this.handleDeletePress.bind(this)} />
                        <IconButton icon="cloud-download-outline" style={{ margin: 0, marginRight: 5 }} size={24} onPress={this.downloadPress.bind(this)} />

                    </View>
                </View>
            </TouchableOpacity>
        );
    }

    
    onDrop(e) {
        this.handleDropEvent(e);
    }

    handleDropEvent(e) {
        const { t } = this.props;

        this.state.prevItem = JSON.parse(JSON.stringify(this.state.item));
        if (e.dataTransfer.items && e.dataTransfer.items.length == 1) {
            if (e.dataTransfer.items[0].kind === "file") {
                var file = e.dataTransfer.items[0].getAsFile();
                if (file.size > 1024 * 1024) {
                    this.handleErrorFile(`${t("error")}: ${t("maximum_file_size_is", { 0 : "1MB"})}`);
                }
                else {
                    this.uploadFile(file);
                }
            }
        } else if (e.dataTransfer.files.length == 1) {
            if (e.dataTransfer.files[0].kind === "file") {
                var file = e.dataTransfer.files[0].getAsFile();
                if (file.size > 1024 * 1024) {
                    this.handleErrorFile(`${t("error")}: ${t("maximum_file_size_is", { 0 : "1MB"})}`);
                }
                else {
                    this.uploadFile(file);
                }
            }
        }
    }

    handleErrorFile(error_text) {
        
        this.state.item.uploading = false;
        this.state.item.error = true;
        this.state.item.name = "";
        this.state.item.errorText = error_text;

        this.setState(this.state);

        setTimeout(() => {
            
            this.state.item = this.state.prevItem;
            this.setState(this.state);

        }, 3000);
    }


    onDragOver(e) {
        e.preventDefault();
    }

    uploadPGMPress() {
        this.fileInput.click();
    }

    filesSelectedForUpload() {

        const { t } = this.props;

        this.state.prevItem = JSON.parse(JSON.stringify(this.state.item));
        if (this.fileInput.files[0].size > 1024 * 1024) {
            this.handleErrorFile(`${t("error")}: ${t("maximum_file_size_is", { 0 : "1MB"})}`);
        }
        else {
            this.uploadFile(this.fileInput.files[0]);
        }
        this.fileInput.value = "";
    }

    uploadFile(file) {
        
        const { t } = this.props;

        /* Extension check skipped because raw file without extension shall be allowed */
        //if (file.name.toLowerCase().endsWith(".pgm") || file.name.toLowerCase().endsWith(".png") || file.name.toLowerCase().endsWith(".tif") || file.name.toLowerCase().endsWith(".tiff")  || file.name.toLowerCase().endsWith(".raw")) {

            this.state.item.guid = this.props.configGuid;
            this.state.item.name = file.name;
            this.state.item.uploadPercentage = 0;
            this.state.item.uploading = true;
            this.state.item.error = false;

            this.setState(this.state);

            var xhr = new XMLHttpRequest();
            xhr.withCredentials = true;
            xhr.upload.onprogress = (e) => {
                var done = e.loaded, total = e.total;
                let progress = Math.floor((done / total) * 1000) / 10;
                this.state.item.uploadPercentage = progress;
                this.setState(this.state);
            };

            xhr.onloadend = (e) => {
                if (xhr.status === 200) {
                    this.state.item.uploading = false;
                    this.setState(this.state);
                    /* Get configs for file URL to be able to download it */
                    var qrMessage = Messages.QrMessageType.encode({ session_id: this.props.sessionId, identifier: Utils.QrMessageId.GET_SCREENING_CONFIGURATIONS }).finish();
                    Utils.sendMessage(Utils.MessageId.QR_MESSAGE, qrMessage);
                }

                if (xhr.status === 406) {
                    this.handleErrorFile(t("file_is_not_supported"));              
                }


            };

            xhr.open("POST", Settings.backendUrl + "/upload-pgm?color=" + this.props.color + "&name=" + encodeURIComponent(file.name) + "&config_guid=" + this.props.configGuid + "&session_id=" + encodeURIComponent(this.props.sessionId), true)

            xhr.send(file);
        //}
    }

    renderHeader() {
        return (
            <View style={{flexDirection: "row", flex: 1}}>
                <div style={{flex: 1}} onDrop={(e) => { this.onDrop(e) }} onDragOver={(e) => { this.onDragOver(e) }}>
                    <View style={[styles.dropdown, { height: "24px", flexDirection: "row"}]}>
                       <Text style={{ textAlign: "left", flex: 1 }}>{this.props.title}</Text>
                       <Image source={{ uri: "upload-icon.png" }} style={{width: "24px", height: "24px"}}></Image>
                    </View>
                </div>
                <input ref={(fileInput) => { this.fileInput = fileInput; }} type='file' hidden onChange={this.filesSelectedForUpload.bind(this)} accept=".pgm,.png,.tif,.tiff,.raw" />
                <IconButton icon="file-upload-outline" style={{ width: 25, height: 25, padding: 0, margin: 0, marginLeft: 8 }} onPress={this.uploadPGMPress.bind(this)}></IconButton>     
            </View>

        );
    }

    componentWillReceiveProps(newProps) {
        this.setState({item: newProps.item});
    }
}

const itemColor = "#FFFFFF";

const localStyles = StyleSheet.create({
    item: {
        backgroundColor: itemColor,
        height: 46,
        justifyContent: "center",
        marginVertical: 1,
    }
});

export default withTranslation()(PGMUploadComponent);