import protobuf, { Field, MapField, Root } from 'protobufjs';

import { appcore } from "./appcore_ripe_proto";

// Generic
let MessageType = new protobuf.Type("Message").add(new Field("identifier", 1, "int32")).add(new Field("data", 2, "bytes"));
let QrMessageType = new protobuf.Type("QRMessage").add(new Field("session_id", 1, "string")).add(new Field("identifier", 2, "int32")).add(new Field("data", 3, "bytes"));
let QRCodeAuthReconnectType = new protobuf.Type("QRCodeAuthReconnect").add(new Field("session_id", 1, "string", "repeated"));
let QRCodeAuthNotificationType = new protobuf.Type("QRCodeAuthNotification").add(new Field("session_id", 1, "string")).add(new Field("add_session", 2, "bool"));
let QRCodeSessionEndType = new protobuf.Type("QRCodeSessionEnd").add(new Field("session_id", 1, "string"));

export default {
    MessageType: MessageType,
    GenerateType: appcore.ripe.Generate,
    GenerateStatusType: appcore.ripe.GenerateStatus,
    DeleteSplitFilesType: appcore.ripe.DeleteSplitFiles,
    QrMessageType: QrMessageType,
    SplitPDFType: appcore.ripe.SplitPDF,
    SplitPDFResultType: appcore.ripe.SplitPDFResult,
    SourcePDFType: appcore.ripe.SourcePDF,
    PDFListType: appcore.ripe.PDFList,
    PrintheadParamsType: appcore.ripe.PrintheadParams,
    PrintheadConfigType: appcore.ripe.PrintheadConfig,
    PrintheadConfigsType: appcore.ripe.PrintheadConfigs,
    UserInfoType: appcore.ripe.UserInfo,
    UserType: appcore.ripe.User,
    AdministrationData: appcore.ripe.AdministrationData,
    ActivateUserType: appcore.ripe.ActivateUser,
    LicenseType: appcore.ripe.License,
    DeleteInputPDFType: appcore.ripe.DeleteInputPDF,
    DeleteInputPDFResultType: appcore.ripe.DeleteInputPDFResult,
    OutputType: appcore.ripe.Output,
    ICCProfileType: appcore.ripe.ICCProfile,
    ICCProfilesType: appcore.ripe.ICCProfiles,
    DeleteICCProfileType: appcore.ripe.DeleteICCProfile,
    DeleteICCProfileResultType: appcore.ripe.DeleteICCProfileResult,
    ImageSizePositionType: appcore.ripe.ImageSizePosition,
    ImageSizePositionsType: appcore.ripe.ImageSizePositions,
    PDFToolsData: appcore.ripe.PDFToolsData,
    OutputSettingsOverride: appcore.ripe.OutputSettingsOverride,
    PrinterType: appcore.ripe.Printer,
    PrintersType: appcore.ripe.Printers,
    DeletePrinterType: appcore.ripe.DeletePrinter,
    DeletePrinterResultType: appcore.ripe.DeletePrinterResult,
    ColorManagementConfigsType: appcore.ripe.ColorManagementConfigs,
    ColorManagementConfigType: appcore.ripe.ColorManagementConfig,
    ColorManagementValuesType: appcore.ripe.ColorManagementValues,
    GenerateLiveViewType: appcore.ripe.GenerateLiveView,
    CancelGenerateLiveViewType: appcore.ripe.CancelGenerateLiveView,
    GenerateLiveViewResultType: appcore.ripe.GenerateLiveViewResult,
    InkManagementConfigType: appcore.ripe.InkManagementConfig,
    InkManagementConfigsType: appcore.ripe.InkManagementConfigs,
    ScreeningAlgoType: appcore.ripe.ScreeningAlgo,
    ScreeningAlgosType : appcore.ripe.ScreeningAlgos,
    ScreeningConfigType: appcore.ripe.ScreeningConfig,
    ScreeningConfigsType : appcore.ripe.ScreeningConfigs,
    GenerateScreeningPreviewType : appcore.ripe.GenerateScreeningPreview,
    GenerateScreeningPreviewResultType : appcore.ripe.GenerateScreeningPreviewResult,
    DeletePGMFileType : appcore.ripe.DeletePGMFile,
    SavePrinterType: appcore.ripe.SavePrinter,
    SavePrinterResultType: appcore.ripe.SavePrinterResult,
    LoginAsUserType: appcore.ripe.LoginAsUser,
    SetOutputForUserType: appcore.ripe.SetOutputForUser,
    QRCodeAuthReconnectType: QRCodeAuthReconnectType,
    QRCodeAuthNotificationType: QRCodeAuthNotificationType,
    QRCodeSessionEndType: QRCodeSessionEndType,
    RIPComputersType: appcore.ripe.RIPComputers,
    SaveDefaultPrinterServerIPType: appcore.ripe.SaveDefaultPrinterServerIP,
    SavePrinterServerIPType: appcore.ripe.SavePrinterServerIP,
    SaveUserType: appcore.ripe.SaveUser,
    DeleteUserType: appcore.ripe.DeleteUser,
    SaveRIPComputerType: appcore.ripe.SaveRIPComputer,
    SaveRIPComputerResultType: appcore.ripe.SaveRIPComputerResult,
    DeleteRIPComputerType: appcore.ripe.DeleteRIPComputer,
    RIPComputerPrinterMappingDataType: appcore.ripe.RIPComputerPrinterMappingData,
    AddRIPComputerPrinterMappingType: appcore.ripe.AddRIPComputerPrinterMapping,
    RemoveRIPComputerPrinterMappingType: appcore.ripe.RemoveRIPComputerPrinterMapping,
    LabelsType: appcore.ripe.Labels,
    LabelOperationType: appcore.ripe.LabelOperation,
    IntentType: appcore.ripe.Intent,
    PreserveType: appcore.ripe.Preserve,
    SourcePDFConfigsUpdatedAtType: appcore.ripe.SourcePDFConfigsUpdatedAt
};

