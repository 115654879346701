
import React, { Component } from 'react';

import {
    Text,
    TouchableOpacity
  } from 'react-native';

export class Tab extends Component {

    state = { selected: false };

    onPress = null;

    handlePress() {
        this.props.onPress(this.props.index);
    }

    render() {

        return (
            <TouchableOpacity onPress={this.handlePress.bind(this)} 
                style={[{width: 220, justifyContent: "center", alignItems: "center"}, 
                this.props.selected ? { borderBottomColor: "black", borderBottomWidth: 2} : "", this.props.style]}>
                <Text style={{fontSize: 15}}>{ this.props.title }</Text>
            </TouchableOpacity>
        );
    }
}

