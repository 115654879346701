import React, { Component } from 'react';
import { CollapseView } from "./CollapseView";

import {
    Text,
    View,
    TouchableOpacity,
    Image,
    Picker,
    Switch,
    StyleSheet
  } from 'react-native';

import { TextInput, IconButton } from "react-native-paper";

import Slider from '@material-ui/core/Slider';

import { globalStyles, colors, themes} from "./Styles";
import Messages from './Messages';
import { withStyles } from '@material-ui/core';
import { RipeButton } from './RipeButton';

import { publish, Subscriber } from "./PubSub";
import CMYKSliders from "./CMYKSliders";

import Utils from "./Utils";

import { InkSettingsChangeTopic } from "./topics";
import { withTranslation } from "react-i18next";

class InkManagementView extends Component {


    collapse = true;

    constructor(props) {
        super(props);

        const ink_management_collapse_config = JSON.parse(localStorage.getItem('ink_management_collapse_config'))

        if(ink_management_collapse_config != null) {
            this.collapse = ink_management_collapse_config.collapse;
        }

    }

    saveCollapseConfig() {
        localStorage.setItem('ink_management_collapse_config', JSON.stringify({ collapse: this.collapse }))
    }

    render() {
        const { t } = this.props;
        
        return (
            <CollapseView headerText={t("ink_levels").toUpperCase()} collapse={this.collapse} renderView={this.renderView.bind(this)} onCollapse={ (collapse) => { this.collapse = collapse; this.saveCollapseConfig(); } }>

            </CollapseView>
        );
    }

    stepMinus(value) {
        var newValue = 10 * Math.ceil(value / 10) - 10;
        if(newValue < -100) {
            newValue = -100;
        }
        return newValue;
    }

    stepPlus(value) {
        var newValue = 10 * Math.floor(value / 10) + 10;
        if(newValue > 100) {
            newValue = 100;
        }
        return newValue;
    }

    renderView() {
        
        const { t } = this.props;

        return (
        <View style={{paddingTop: 10, paddingLeft: 2, paddingRight: 2}}>
            <CMYKSliders onChange={this.inkValuesChange.bind(this)} label={t("ink").toUpperCase()} icon="water" values={this.props.config} stepMinus={this.stepMinus.bind(this)} stepPlus={this.stepPlus.bind(this)} ></CMYKSliders>
        </View>);
    }

    inkValuesChange(values) {
        this.props.onChange(values);
        this.saveInkManagementConfig(values);
    }
    
    saveInkManagementConfig(values) {

        publish(InkSettingsChangeTopic, values);
        // TODO: save in redux pdf tools reducer.
        var qrMessage = Messages.QrMessageType.encode({ session_id: this.props.sessionId, identifier: Utils.QrMessageId.SAVE_INK_MANAGEMENT_DATA, 
            data: Messages.InkManagementConfigType.encode(
                { 
                    ... values,
                    config_guid: this.props.configGuid, 
                    pdf_guid: this.props.pdfGuid }).finish() }).finish();            
            Utils.sendMessage(Utils.MessageId.QR_MESSAGE, qrMessage);

    }
}

const styles = StyleSheet.create({
    sliderLabelStyle: {
        width: 150,
        alignSelf: "center"
    }
});

export default withTranslation()(InkManagementView);