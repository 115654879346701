import React, { Component } from 'react';
import { View, Animated, TouchableOpacity, Text, Image, Easing } from "react-native";

export class CollapseView extends Component {

    state = {
        collapse: false,
        animation: new Animated.Value()
    }

    spinValue = new Animated.Value(0);

    constructor(props) {
        super(props);
        this.state.collapse = props.collapse;

        this.state.startpoint = 30;

        if(this.state.collapse) {
            this.state.animation = new Animated.Value(this.state.startpoint);
        }

        if(this.state.collapse) {
            this.spinValue = new Animated.Value(0);
        } else {
            this.spinValue = new Animated.Value(1);
        }

        if(props.headerAlignment === "left") {
            this.spin = this.spinValue.interpolate({
                inputRange: [0, 1],
                outputRange: ['0deg', '90deg']
            });
        } else {
            this.spin = this.spinValue.interpolate({
                inputRange: [0, 1],
                outputRange: ['0deg', '-90deg']
            });
        }
    }

    collapse = () => {

        if(this.state.collapse) {
            Animated.timing(
                this.spinValue,
            {
                toValue: 1,
                duration: 300,
                easing: Easing.linear
            }
            ).start();
        } else {
            Animated.timing(
                this.spinValue,
            {
                toValue: 0,
                duration: 300,
                easing: Easing.linear
            }
            ).start();
        }


        let startAnim = this.state.collapse ? this.state.startpoint : this.state.endpoint + this.state.startpoint;
        let endAnim = this.state.collapse ? this.state.startpoint + this.state.endpoint : this.state.startpoint;
        
        this.setState({ collapse: !this.state.collapse });
        
        this.state.animation.setValue(startAnim);
        Animated.spring(this.state.animation, { toValue: endAnim }).start();

        if(this.props.onCollapse !== undefined) {
            this.props.onCollapse(!this.state.collapse);
        }
    }

    endpoint = (layout) => {

        
        this.setState({ endpoint: layout.nativeEvent.layout.height });
    }

    render() {
        
        var header = <Text>{this.props.headerText}</Text>;

        if(this.props.renderHeader !== undefined) {
            header = this.props.renderHeader();
        }

        return (
            <Animated.View style={{height: this.state.animation, backgroundColor:'transparent', overflow: 'hidden', opacity: 1}}>
                <TouchableOpacity activeOpacity={1} onPress={this.collapse}>
                    <View style={{borderBottomWidth: 2,  flexDirection: (this.props.headerAlignment === "left" ? "row" : "row-reverse"), alignItems: "center"}}>
                        <Animated.Image style={{width: 24, height: 24, transform: [{rotate: this.spin}]}} source={{uri: (this.props.headerAlignment === "left" ? "right-icon.png" : "left-icon.png") }}></Animated.Image>
                        { header }
                        
                    </View>
                </TouchableOpacity>
                <View  onLayout={this.endpoint}>
                    {this.props.renderView()}
                </View>
            </Animated.View>
        );
    }
}