import React from 'react';
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core/styles';
import { withTranslation, WithTranslation } from "react-i18next";
import { Typography } from '@material-ui/core';
import ComponentLabel from "../ComponentLabel/ComponentLabel";
import ComponentFilterByLabel from "../ComponentFilterByLabel/ComponentFilterByLabel";
import ComponentLabelSelect from "../ComponentLabelSelect/ComponentLabelSelect";
import ComponentSettingsLabel from "../ComponentSettingsLabel/ComponentSettingsLabel";
import { Label } from "../ComponentSettingsLabel/ReducerSettingsLabel";

interface PropsComponentTestPage {

}

interface StateComponentTestPage {
    labels: Array<Label>,
    selected_labels: Array<number>
}

const styles = (theme: Theme) => createStyles({ 
});

type PropsComponentTestPageWithStylesAndTranslation = 
PropsComponentTestPage & WithStyles<typeof styles> & WithTranslation;

class ComponentTestPage extends React.Component<PropsComponentTestPageWithStylesAndTranslation, StateComponentTestPage> {

  constructor(props: PropsComponentTestPageWithStylesAndTranslation) {
    super(props);

    this.state = {
        labels: [{id: 1, name: "Test", color: "#FFDEDA"}, {id: 2, name: "Sample", color: "#FDB4C1"}],
        selected_labels: []
    }
  }

  handleSelectLabel(labels_prm: Array<number>){
      this.setState({selected_labels: labels_prm});
  }

  render() {

    const { t } = this.props;

    return (
      <div style={{padding: 15}}>
          <div style={{width: 70, display: "flex", justifyContent: "space-between", marginBottom: 50}}>
            <ComponentLabel text="Sample" color="#FDB4C1" style={{width: 30, height: 30}}></ComponentLabel>
            <ComponentLabel text="Test" color="#FFDEDA" style={{width: 30, height: 30}}></ComponentLabel>
          </div>

          <ComponentFilterByLabel 
            labels={this.state.labels} 
            selected_labels={this.state.selected_labels} 
            onSelectLabel={this.handleSelectLabel.bind(this)}>

          </ComponentFilterByLabel>
          <div>test</div>
          <ComponentLabelSelect labels={this.state.labels} 
            selected_labels={this.state.selected_labels} 
            onSelectLabel={this.handleSelectLabel.bind(this)}>

          </ComponentLabelSelect>
          
          
          <div style={{marginTop: 50, width: 600}}>


          <ComponentSettingsLabel></ComponentSettingsLabel>

          </div>
      </div>
    )
  }
}

export default withTranslation()(withStyles(styles, {withTheme: true})(ComponentTestPage));