import React from 'react';
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core/styles';
import { withTranslation, WithTranslation } from "react-i18next";
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import { colors } from "../Styles";
import { Label } from "./ReducerSettingsLabel";
import ComponentLabel from "../ComponentLabel/ComponentLabel";
import EditIcon from '@material-ui/icons/Edit';

const styles = (theme: Theme) => createStyles({ 
    row: {
      '&.Mui-selected, &.Mui-selected:hover': {
        backgroundColor: colors.highlight
      }
    }
  });
  
  interface PropsComponentSettingsLabelTable {
    rows: Array<Label>;
    onDelete: (id: number) => void;
    onEdit: (id: number) => void;
  }
  
  interface StateComponentSettingsLabelTable {
    filter_name: string;
    order: "desc" | "asc";
  }
  
  type PropsComponentSettingsLabelTableWithStylesAndTranslation = PropsComponentSettingsLabelTable & WithStyles<typeof styles> & WithTranslation;
  
  class ComponentSettingsLabelTable extends React.Component<PropsComponentSettingsLabelTableWithStylesAndTranslation, StateComponentSettingsLabelTable> {
  
    constructor(props: PropsComponentSettingsLabelTableWithStylesAndTranslation) {
      super(props);
      this.state = {
        filter_name: "",
        order: "desc"
      }
    }
  
      render() {
    
          const { t } = this.props;
      
          return (
            <div>
              <Typography variant="subtitle1" id="tableTitle" component="div">
              {t("labels")}
            </Typography>
            <TableContainer component={Paper} style={{width: 600,  maxHeight: 443}}>
            <Table stickyHeader> 
              <TableHead>
                <TableRow>

                  <TableCell></TableCell>
                  <TableCell>
                    <TableSortLabel
                        onClick={ () => {this.state.order == "asc" ? this.setState({order: "desc"}) : this.setState({order: "asc"})}}
                        active={true}
                        direction={this.state.order}>
                        </TableSortLabel>
                        <Input placeholder={t("name")} 
                        onChange={ (e) => { 
                            this.setState({filter_name: e.currentTarget.value}) 
                        }}/>
                    </TableCell>
                    <TableCell>{t("label")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.rows.filter(
                    (row) => {
                        return row.name.toLowerCase().includes(this.state.filter_name.toLowerCase());
                    })
                .sort((a,b) => {

                    if(a.name < b.name) {
                        if(this.state.order == "desc") {
                            return 1;
                        } else {
                            return -1;
                        }
                    }
                    if(a.name > b.name) {
                        if(this.state.order == "desc") {
                            return -1;
                        } else {
                            return 1;
                        }
                    }
                    return 0;
                })  
                .map((row) => {
      
                  return (
                    <TableRow hover>
                      
                      <TableCell component="th" scope="row">
                        <IconButton style={{padding: 0, marginRight: 20}} onClick={() => { this.props.onDelete(row.id) }}>
                            <SvgIcon>
                                <path fill="currentColor" d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z" />
                            </SvgIcon>
                        </IconButton>
                        <IconButton style={{padding: 0}} onClick={() => { this.props.onEdit(row.id) }}>
                            <EditIcon></EditIcon>
                        </IconButton>
                      </TableCell>
                      
                      <TableCell component="th" scope="row">
                        <div style={{maxWidth: 390, textOverflow: "ellipsis", overflow: "hidden"}}>{row.name}</div>
                      </TableCell>
                      <TableCell>
                          <ComponentLabel text={row.name} color={row.color} style={{width: 30, height: 30}}></ComponentLabel>
                      </TableCell>
                    </TableRow>
                  )}
                )}
              </TableBody>
            </Table>
          </TableContainer>
          </div>
          )
        }
    }
  
export default withTranslation()(withStyles(styles, {withTheme: true})(ComponentSettingsLabelTable));
  