import Messages from "./Messages";

class Utils {

    _handlers = {};

    constructor() {

    }

    convertToMM(value) {
        return  Math.round(value  * 25.4 * 100) / 100; 
    }

    convertIfInch(value, inch) {
        if(inch) {
            return  Math.round(value  / 25.4 * 100) / 100; 
        }
        return value;
    }

    sendMessage(identifier, data) {
        var data = Messages.MessageType.encode({identifier: identifier, data: data}).finish();
        this.communicator.send(data);
    }

    setCommunicator(communicator) {
        this.communicator = communicator;
    }

    setHandler(messageId, handler) {
       if(this._handlers[messageId] === undefined) {
            this._handlers[messageId] = [];
        }
        this._handlers[messageId].push(handler);
    }

    removeHandler(messageId, handler) {
        
        if(this._handlers[messageId] !== undefined) {
            var id = this._handlers[messageId].indexOf(handler);
            if(id !== -1) {
                this._handlers[messageId].splice(id, 1);
            }
        }
    }

    getHandlers(messageId) {
        var handlers = this._handlers[messageId];
        if(handlers === undefined) {
            return [];
        }
        return handlers;
    }

    MessageId = {
        QR_CODE_AUTH_NOTIFICATION: 5,
        QR_CODE_AUTH_RECONNECT:  6,
        QR_MESSAGE: 8,
        QR_CODE_SESSION_END: 23,
        RIP_PC_REGISTRATION_DATA: 31
    }

    QrMessageId = {
        GET_PDF_TOOLS_DATA: 100,
        PDF_TOOLS_DATA: 101,
        SOURCE_PDF: 102,
        GENERATE: 103,
        DELETE_SPLIT_FILES: 104,
        GET_PRINTHEAD_CONFIGURATIONS: 105,
        PRINTHEAD_CONFIGURATIONS: 106,
        SAVE_PRINTHEAD_CONFIGURATIONS: 107,
        GENERATE_STATUS: 108,
        USER_INFO: 109,
        GET_ADMINISTRATION_DATA: 110,
        ADMINISTRATION_DATA: 111,
        ACTIVATE_USER: 112,
        DEACTIVATE_USER: 113,
        LICENSE: 114,
        ACCEPT_LICENSE: 115,
        DELETE_INPUT_PDF: 117,
        DELETE_INPUT_PDF_RESULT: 118,
        GET_ICC_PROFILES: 119,
        ICC_PROFILES: 120,
        DELETE_ICC_PROFILE: 121,
        DELETE_ICC_PROFILE_RESULT: 122,
        SAVE_IMAGE_SIZE_POSITION: 123,
        GET_PRINTERS: 124,
        PRINTERS: 125,
        DELETE_PRINTER: 126,
        DELETE_PRINTER_RESULT: 127,
        SAVE_COLOR_MANAGEMENT: 128,
        GET_SCREENING_CONFIGURATIONS: 129,
        GET_SCREENING_ALGOS: 130,
        SCREENING_ALGOS: 131,
        SCREENING_CONFIGURATIONS: 132,
        SAVE_SCREENING_CONFIGURATIONS: 133,
        SAVE_INK_MANAGEMENT_DATA: 134,
        GENERATE_LIVE_VIEW_RESULT: 135,
        GENERATE_LIVE_VIEW: 136,
        CANCEL_GENERATE_LIVE_VIEW: 137,
        GENERATE_SCREENING_PREVIEW: 138,
        GENERATE_SCREENING_PREVIEW_RESULT: 139,
        DELETE_PGM_FILE: 140,
        SAVE_RIP_COMPUTER_ADMIN: 141,
        GET_RIP_COMPUTER_PRINTER_MAPPING_DATA: 142,
        RIP_COMPUTER_PRINTER_MAPPINGS_DATA: 143,
        ADD_RIP_COMPUTER_PRINTER_MAPPING: 144,
        REMOVE_RIP_COMPUTER_PRINTER_MAPPING: 145,
        GET_LABELS: 146,
        LABELS: 147,
        LABEL_OPERATION: 148,
        SAVE_OUTPUT_SETTINGS_OVERRIDE: 149,
        SOURCE_PDF_CONFIGS_UPDATED_AT: 150,
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        SAVE_PRINTER: 2000,
        SAVE_PRINTER_RESULT: 2001,
        LOGIN_AS_USER: 2002,
        ADD_OUTPUT_TO_USER: 2003,
        REMOVE_OUTPUT_FROM_USER: 2004,
        RIP_COMPUTERS: 2005,
        GET_RIP_COMPUTERS: 2006,
        SAVE_DEFAULT_PRINTER_SERVER_IP: 2007,
        SAVE_PRINTER_SERVER_IP: 2008,
        SAVE_USER: 2009,
        DELETE_USER: 2010,
        SAVE_RIP_COMPUTER_RESULT: 2011,
        DELETE_RIP_COMPUTER: 2012,
        SAVE_RIP_COMPUTER: 2013
    }

    GenerateTypeId= {
        GENERATE_FILE: 1,
        GENERATE_AND_SEND_TO_PRINTER: 2,
        GENERATE_AND_SEND_TO_JOB_LIB: 3,
        SEND_TO_PRINTER: 4,
        SEND_TO_JOB_LIB: 5
    }

    async download(url) {
        
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        document.body.appendChild(a);
        a.click();

        /*
        var response = await fetch(url);
        
        const url_obj = window.URL.createObjectURL(await response.blob());
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url_obj;
        
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url_obj);
        */
    }
    
}

var utils = new Utils();

export default utils;