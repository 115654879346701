import Messages from "../Messages";
import Utils from "../Utils";
import uuidv4 from "uuid/v4";
import Settings from "../Settings";
import { i18n} from '../i18n';
import { appcore } from "../appcore_ripe_proto";
import { Action } from "redux";
import { ActionGetPrintheadConfigurations, ActionPrintheadConfigurations, ActionPrintModePCLChange } from "../actions/PrintheadConfigurationsActions";
import { ActionAsyncDispatch } from ".";

export interface DeviceLinkUploading {
  progress: number;
  file: File;
  config_guid: string;
  error: boolean;
}

export interface StateOutputConfigurations {
  configs: Array<appcore.ripe.IPrintheadConfig>;
  printers: Array<appcore.ripe.IPrinter>;
  screening_configs: Array<appcore.ripe.IScreeningConfig>;
  selectable_outputs: Array<appcore.ripe.IOutput>;
  icc_profiles: Array<appcore.ripe.IICCProfile>;
  stitching_types: Array<{ id: number; text_key: string; icon: string }>;
  selected_stitching_id: number;
  default_head_size: number;
  data_loaded: boolean;
  device_links_uploading: Array<DeviceLinkUploading>;
  device_links: Array<appcore.ripe.IDeviceLink>;
  maps_oem_label: { [key: string]: appcore.ripe.IMapOEMLabel };
  map_oem_label?: appcore.ripe.IMapOEMLabel;
  pages_to_print_disabled: boolean;
}

function pagesToPrintDisabled(shared_state: any, configs_prm: Array<appcore.ripe.IPrintheadConfig>, outputs_prm: Array<appcore.ripe.IOutput>): boolean {
  if(shared_state.selected_printhead_config_id != -1) {

    let config = configs_prm[shared_state.selected_printhead_config_id];

    let selected_output = outputs_prm.find((value) => { return value.code == config.output_code && value.printer_output == config.printer_output; });

    if(selected_output != undefined) {
        return selected_output.show_print_mode_pcl && config.print_mode_pcl == appcore.ripe.PrintModePCL.single_page_static;
    }
  }
  return false;
}

let initial_state: StateOutputConfigurations = {
  configs: [], 
  printers: [], 
  screening_configs: [], 
  selectable_outputs: [], 
  icc_profiles: [],
  stitching_types: [
    { id: 0, text_key: "none", icon: "" },
    { id: 1, text_key: "butt_stitching", icon: "butt_b.png" },
    { id: 2, text_key: "feathering", icon: "feather2_b.png" },
    { id: 3, text_key: "sawtooth", icon: "sawtooth_b.png" }
  ],
  selected_stitching_id: 0,
  default_head_size: 324.4,
  data_loaded: false,
  device_links_uploading: new Array<DeviceLinkUploading>(),
  device_links: [],
  maps_oem_label: {},
  pages_to_print_disabled: false
};


function generateConfigGuid(configs: Array<appcore.ripe.IPrintheadConfig>) {

  let guid = uuidv4();

  let uuidUnique = false;

  do {
      uuidUnique = configs.find((item) => { return item.guid == guid; }) == null;
      if(!uuidUnique) {
          guid= uuidv4();
      }

  } while(!uuidUnique);

  return guid;
}

function calculatePrintWidth(config: appcore.ripe.IPrintheadConfig) {
  let numOfHeads = config.head_params.filter((item) => { return item.enabled; }).length;
  if(numOfHeads == 1) {
      calculate1HeadPrintWidths(config);
  } else if(numOfHeads == 2) {
      calculate2HeadPrintWidths(config);
  } else if(numOfHeads == 3) {
      calculate3HeadPrintWidths(config);
  } else if(numOfHeads == 4) {
      calculate4HeadPrintWidths(config);
  }
}

function calculate1HeadPrintWidths(config: appcore.ripe.IPrintheadConfig) {
  let headParams = config.head_params;
  headParams[0].print_width_mm = (headParams[0].right_mm * 1000 - headParams[0].left_mm * 1000) / 1000;
  headParams[1].print_width_mm = 0;
  headParams[2].print_width_mm = 0;
  headParams[3].print_width_mm = 0;
}

function calculate2HeadPrintWidths(config: appcore.ripe.IPrintheadConfig) {
  let headParams = config.head_params;
  let stitchSize = 10;
  headParams[0].print_width_mm = (headParams[0].right_mm * 1000 - headParams[0].left_mm * 1000 - (stitchSize / 2) * 1000) / 1000;
  headParams[1].print_width_mm = (headParams[1].right_mm * 1000 - headParams[1].left_mm * 1000 - (stitchSize / 2) * 1000) / 1000;
  headParams[2].print_width_mm = 0;
  headParams[3].print_width_mm = 0;
}

function calculate3HeadPrintWidths(config: appcore.ripe.IPrintheadConfig) {
  let headParams = config.head_params;
  headParams[0].print_width_mm = (headParams[0].right_mm * 1000 - headParams[0].left_mm * 1000 - 6.6 * 1000) / 1000;
  headParams[1].print_width_mm =  (headParams[1].right_mm * 1000 - headParams[1].left_mm * 1000 - 6.6 * 1000) / 1000;
  headParams[2].print_width_mm = (headParams[2].right_mm * 1000 - headParams[2].left_mm * 1000 - 6.6 * 1000) / 1000;
  headParams[3].print_width_mm = 0;
}

function calculate4HeadPrintWidths(config: appcore.ripe.IPrintheadConfig) {
  let headParams = config.head_params;
  headParams[0].print_width_mm = (headParams[0].right_mm * 1000 - headParams[0].left_mm * 1000 - 7.5 * 1000) / 1000;
  headParams[1].print_width_mm = (headParams[1].right_mm * 1000 - headParams[1].left_mm * 1000 - 5 * 1000 - 2.5 * 1000) / 1000;
  headParams[2].print_width_mm = (headParams[2].right_mm * 1000 - headParams[2].left_mm * 1000 - 5 * 1000 - 2.5 * 1000) / 1000;
  headParams[3].print_width_mm = (headParams[3].right_mm * 1000 - headParams[3].left_mm * 1000 - 7.5 * 1000) / 1000;
}

function getStitchSize(numOfHeads: number) {
  if(numOfHeads == 3) {
      return 9.9;
  }
  return 10;
}

function setDefaultHeadValues(numOfHeads: number, config: appcore.ripe.IPrintheadConfig) {
  if(numOfHeads > 0) {
      let stichSize = getStitchSize(numOfHeads);
      config.total_print_width_mm = (initial_state.default_head_size * 1000 * numOfHeads - stichSize * (numOfHeads - 1) * 1000) / 1000;
      
      let headParams = config.head_params;
      headParams[0].left_mm = 0;
      headParams[0].right_mm = initial_state.default_head_size;

      for(let i = 1; i < numOfHeads; i++) {
          headParams[i].left_mm = (headParams[i-1].right_mm * 1000 - stichSize * 1000) / 1000;
          headParams[i].right_mm = (headParams[i].left_mm * 1000 + initial_state.default_head_size * 1000) / 1000;
      }
      for(let i = numOfHeads; i < 4; i++) {
          headParams[i].left_mm = 0;
          headParams[i].right_mm = 0;
      }
  }
}

function setDefaultValues(config: appcore.ripe.IPrintheadConfig) {
  let numOfHeads = config.head_params.filter((item) => { return item.enabled; }).length;
  setDefaultHeadValues(numOfHeads, config);
  if(numOfHeads == 1) {
      calculate1HeadPrintWidths(config);
  } else if(numOfHeads == 2) {
      calculate2HeadPrintWidths(config);
  } else if(numOfHeads == 3) {
      calculate3HeadPrintWidths(config);
  } else if(numOfHeads == 4) {
      calculate4HeadPrintWidths(config);
  }
}

function copyConfigs(configs: Array<appcore.ripe.IPrintheadConfig>) {
  return configs.slice();
}

function getMapOEMLabel(shared_state, output_configs: Array<appcore.ripe.IPrintheadConfig>, maps_oem_label: { [key: string]: appcore.ripe.IMapOEMLabel }) {
  let map_oem_label = undefined;
  if(shared_state.selected_printhead_config_id != -1) {
      let config = output_configs[shared_state.selected_printhead_config_id];
      map_oem_label = maps_oem_label[config.map_oem_label_guid];
  }
  return map_oem_label;
}

export const printheadConfigurationsReducer = (appState, sharedState, state: StateOutputConfigurations = initial_state, action_prm: ActionAsyncDispatch | any) => {
    switch (action_prm.type) {
      case ActionGetPrintheadConfigurations.getType():
      {
        let qrMessage = Messages.QrMessageType.encode({ session_id: appState.session_id, identifier: Utils.QrMessageId.GET_PRINTHEAD_CONFIGURATIONS }).finish();
        Utils.sendMessage(Utils.MessageId.QR_MESSAGE, qrMessage);
        return state;
      }
      case ActionPrintheadConfigurations.getType():
      {

        let action = (action_prm as ActionPrintheadConfigurations);

        let printhead_configs: appcore.ripe.IPrintheadConfigs = appcore.ripe.PrintheadConfigs.toObject(appcore.ripe.PrintheadConfigs.decode(action.data), { defaults: true});

        let map_oem_label = getMapOEMLabel(sharedState, printhead_configs.config, printhead_configs.maps_oem_label);

        return Object.assign({}, state, { 
          configs: printhead_configs.config, 
          printers: printhead_configs.printers.printers,
          screening_configs: printhead_configs.screening_configs.config, 
          selectable_outputs: printhead_configs.selectable_outputs, 
          icc_profiles: printhead_configs.icc_profiles.icc_profiles,
          device_links: printhead_configs.device_links.device_links,
          data_loaded: true,
          maps_oem_label: printhead_configs.maps_oem_label,
          map_oem_label: map_oem_label,
          pages_to_print_disabled: pagesToPrintDisabled(sharedState, printhead_configs.config, printhead_configs.selectable_outputs)
        });
      }
      case 'SELECT_PRINTHEAD_CONFIG':
      {
        if(state.data_loaded) {
          let map_oem_label = getMapOEMLabel(sharedState, state.configs, state.maps_oem_label);

          return Object.assign({}, state, { 
            map_oem_label: map_oem_label,
            pages_to_print_disabled: pagesToPrintDisabled(sharedState, state.configs, state.selectable_outputs)
          });
        }
        return state;
      }
      case 'PRINTHEAD_CONFIGURATIONS_NEW_CONFIG':
      {
        let selectable_output =  state.selectable_outputs.length > 0 ? state.selectable_outputs[0] : null;
        let dpi_x = 0;
        let dpi_y = 0;
        if(selectable_output != null && selectable_output.dpi_options.length > 0) {
          let res = selectable_output.dpi_options[0].split("x");
          dpi_x = parseInt(res[0]);
          dpi_y = parseInt(res[1]);
        }
 
        let config = Messages.PrintheadConfigType.create({
          name: i18n.t("new_config"), 
          guid: generateConfigGuid(state.configs),
          total_print_width_mm: state.default_head_size, 
          overlap_dot_pitch: 0,
          stitching_id: 0,
          output_code: selectable_output != null ? selectable_output.code : -1,
          printer_output: selectable_output != null ? selectable_output.printer_output : false,
          dpi_x: dpi_x,
          dpi_y: dpi_y,
          icc_profile_guid: "",
          use_raw_thumbnail: true,
          screening_config_guid: "",
          collate: true,
          copies: 1,
          bottom_margin_page: 0,
          bottom_margin_copy: 0,
          device_link_ids: [],
          selected_pages_to_print: "",
          intent: 0,
          preserve: 0,
          map_oem_label_guid: ""
        });

        config.head_params.push({ enabled: true, left_mm: 0, right_mm: state.default_head_size, print_width_mm: state.default_head_size});
        config.head_params.push({ enabled: false, left_mm: 0, right_mm: 0, print_width_mm: 0});
        config.head_params.push({ enabled: false, left_mm: 0, right_mm: 0, print_width_mm: 0});
        config.head_params.push({ enabled: false, left_mm: 0, right_mm: 0, print_width_mm: 0});

        let configs_copy = copyConfigs(state.configs);
        configs_copy.push(config);

        return Object.assign({}, state, { configs: configs_copy, map_oem_label: undefined, 
          pages_to_print_disabled: pagesToPrintDisabled(sharedState, configs_copy, state.selectable_outputs) });
      }
      case 'SAVE_PRINTHEAD_CONFIGURATIONS':
      {
        let data = Messages.PrintheadConfigsType.encode({ config: state.configs }).finish();
        let qrMessage = Messages.QrMessageType.encode({ session_id: appState.session_id, identifier: Utils.QrMessageId.SAVE_PRINTHEAD_CONFIGURATIONS, data: data }).finish();
        Utils.sendMessage(Utils.MessageId.QR_MESSAGE, qrMessage);
        return state;
      }
      case 'PRINTHEAD_CONFIGURATIONS_DELETE_CONFIG':
      {
        let configs_copy = copyConfigs(state.configs);
        configs_copy.splice(action_prm.id, 1);
        let data = Messages.PrintheadConfigsType.encode({ config: configs_copy }).finish();
        let qrMessage = Messages.QrMessageType.encode({ session_id: appState.session_id, identifier: Utils.QrMessageId.SAVE_PRINTHEAD_CONFIGURATIONS, data: data }).finish();
        Utils.sendMessage(Utils.MessageId.QR_MESSAGE, qrMessage);
        return Object.assign({}, state, { configs: configs_copy });
      }
      case 'PRINTHEAD_CONFIGURATIONS_COPY_CONFIG':
      {
        let config_copy_from = state.configs[action_prm.id];
        let new_config: any = {};
        Object.assign(new_config, config_copy_from);
        new_config.name = i18n.t("copy_of", {0:new_config.name });
        new_config.guid = generateConfigGuid(state.configs); 
        let configs_copy = copyConfigs(state.configs);
        configs_copy.push(new_config);

        let map_oem_label = getMapOEMLabel(sharedState, configs_copy, state.maps_oem_label);

        return Object.assign({}, state, { configs: configs_copy, map_oem_label: map_oem_label });
      }
      case 'PRINTHEAD_CONFIGURATIONS_TOGGLE_HEAD':
      {
        let configs_copy = copyConfigs(state.configs);
        let config = configs_copy[action_prm.id];
        let head_param = config.head_params[action_prm.head_id];
        head_param.enabled = !head_param.enabled;
        setDefaultValues(config);
        return Object.assign({}, state, { configs: configs_copy });
      }
      case 'PRINTHEAD_CONFIGURATIONS_TOTAL_PRINT_WIDTH_CHANGE':
      {
        let configs_copy = copyConfigs(state.configs);
        let config = configs_copy[action_prm.id];
        if(action_prm.inch) {
            config.total_print_width_mm = Utils.convertToMM(action_prm.value);
        } else {
            config.total_print_width_mm = action_prm.value;
        }
        return Object.assign({}, state, { configs: configs_copy });
      }
      case 'PRINTHEAD_CONFIGURATIONS/PAGES_TO_PRINT':
      {
        let configs_copy = copyConfigs(state.configs);
        let config = configs_copy[action_prm.id];
        config.pages = action_prm.pages;
        config.selected_pages_to_print = action_prm.selected_pages_to_print;
        return Object.assign({}, state, { configs: configs_copy });
      }
      case 'PRINTHEAD_CONFIGURATIONS_NAME_CHANGE':
      {
        let configs_copy = copyConfigs(state.configs);
        let config = configs_copy[action_prm.id];
        config.name = action_prm.value;
        return Object.assign({}, state, { configs: configs_copy });
      }
      case 'PRINTHEAD_CONFIGURATIONS_LEFT_CHANGE':
      {
        let configs_copy = copyConfigs(state.configs);
        let config = configs_copy[action_prm.id];
        let head_param = config.head_params[action_prm.head_id];
        if(action_prm.inch) {
          head_param.left_mm = Utils.convertToMM(action_prm.value);
        } else {
          head_param.left_mm = action_prm.value;
        }
        calculatePrintWidth(config);
        return Object.assign({}, state, { configs: configs_copy });
      }
      case 'PRINTHEAD_CONFIGURATIONS_RIGHT_CHANGE':
      {
        let configs_copy = copyConfigs(state.configs);
        let config = configs_copy[action_prm.id];
        let head_param = config.head_params[action_prm.head_id];
        if(action_prm.inch) {
          head_param.right_mm = Utils.convertToMM(action_prm.value);
        } else {
          head_param.right_mm = action_prm.value;
        }
        calculatePrintWidth(config);
        return Object.assign({}, state, { configs: configs_copy });
      }
      case 'PRINTHEAD_CONFIGURATIONS_OUTPUT_CHANGE':
      {
        let configs_copy = copyConfigs(state.configs);
        let config = configs_copy[action_prm.id];
        config.output_code = state.selectable_outputs[action_prm.value].code;
        config.printer_output = state.selectable_outputs[action_prm.value].printer_output;
        let selected_output = state.selectable_outputs.find((value) => { return value.code == config.output_code && value.printer_output == config.printer_output; });
        if(selected_output.show_dpi) {
          let res = selected_output.dpi_options[0].split("x");
            config.dpi_x = parseInt(res[0]);
            config.dpi_y = parseInt(res[1]);
        }
        if(selected_output.show_print_mode_pcl && config.print_mode_pcl == appcore.ripe.PrintModePCL.single_page_static) {
          config.selected_pages_to_print = "current_page";
        }
        return Object.assign({}, state, 
          { 
            configs: configs_copy,
            pages_to_print_disabled: pagesToPrintDisabled(sharedState, configs_copy, state.selectable_outputs)
          });
      }
      case 'PRINTHEAD_CONFIGURATIONS_SCREENING_CHANGE':
      {
        let configs_copy = copyConfigs(state.configs);
        let config = configs_copy[action_prm.id];
        config.screening_config_guid = action_prm.value;
        return Object.assign({}, state, { configs: configs_copy });
      }
      case 'PRINTHEAD_CONFIGURATIONS_INTENT_CHANGE':
      {
        let configs_copy = copyConfigs(state.configs);
        let config = configs_copy[action_prm.id];
        config.intent = action_prm.value;
        return Object.assign({}, state, { configs: configs_copy });
      }
      case 'PRINTHEAD_CONFIGURATIONS_PRESERVE_CHANGE':
      {
        let configs_copy = copyConfigs(state.configs);
        let config = configs_copy[action_prm.id];
        config.preserve = action_prm.value;
        return Object.assign({}, state, { configs: configs_copy });
      }
      case 'PRINTHEAD_CONFIGURATIONS_PROFILE_CHANGE':
      {
        let configs_copy = copyConfigs(state.configs);
        let config = configs_copy[action_prm.id];
        config.icc_profile_guid = action_prm.value;
        return Object.assign({}, state, { configs: configs_copy });
      }
      case 'PRINTHEAD_CONFIGURATIONS_DPI_CHANGE':
      {
        let configs_copy = copyConfigs(state.configs);
        let config = configs_copy[action_prm.id];
        config.stitching_id = action_prm.value;
        let res = action_prm.value.split("x");
        config.dpi_x = res[0];
        config.dpi_y = res[1];
        return Object.assign({}, state, { configs: configs_copy });
      }
      case 'PRINTHEAD_CONFIGURATIONS_STITCHING_CHANGE':
      {
        let configs_copy = copyConfigs(state.configs);
        let config = configs_copy[action_prm.id];
        config.stitching_id = action_prm.value;
        return Object.assign({}, state, { configs: configs_copy });
      }
      case 'PRINTHEAD_CONFIGURATIONS_TOOGLE_RAW_THUMBNAIL':
      {
        let configs_copy = copyConfigs(state.configs);
        let config = configs_copy[action_prm.id];
        config.use_raw_thumbnail = !config.use_raw_thumbnail;
        return Object.assign({}, state, { configs: configs_copy });
      }
      case 'PRINTHEAD_CONFIGURATIONS_TOGGLE_ALWAYS_GENERATE_HEAD_WIDTH_RASTER':
      {
        let configs_copy = copyConfigs(state.configs);
        let config = configs_copy[action_prm.id];
        config.always_generate_head_width_raster = !config.always_generate_head_width_raster;
        return Object.assign({}, state, { configs: configs_copy });
      }
      case 'PRINTHEAD_CONFIGURATIONS_OVERLAP_DOT_PITCH_CHANGE':
      {
        let configs_copy = copyConfigs(state.configs);
        let config = configs_copy[action_prm.id];
        config.overlap_dot_pitch = action_prm.value;
        return Object.assign({}, state, { configs: configs_copy });
      }
      case 'PRINTHEAD_CONFIGURATIONS_UNMOUNT': 
      {
        return Object.assign({}, state, {
            data_loaded: false
        });
      }
      case 'PRINTHEAD_CONFIGURATIONS_COPIES_CHANGE': 
      {
        let configs_copy = copyConfigs(state.configs);
        let config = configs_copy[action_prm.id];
        
        let reg = new RegExp('^[0-9]+$');
        if(reg.test(action_prm.value)) {
          let new_copies = parseInt(action_prm.value);
          if(new_copies > 0) {
            config.copies = new_copies;
          }
        }

        return Object.assign({}, state, { configs: configs_copy });
      }
      case 'PRINTHEAD_CONFIGURATIONS_TOGGLE_COLLATE':
      {
        let configs_copy = copyConfigs(state.configs);
        let config = configs_copy[action_prm.id];
        config.collate = !config.collate;
        return Object.assign({}, state, { configs: configs_copy });
      }
      case 'PRINTHEAD_CONFIGURATIONS_BOTTOM_MARGIN_PAGE_CHANGE':
      {
        let configs_copy = copyConfigs(state.configs);
        let config = configs_copy[action_prm.id];

        if(parseFloat(action_prm.value) >= 0) {
          if(action_prm.inch) {
            config.bottom_margin_page = Utils.convertToMM(action_prm.value);
          } else {
            config.bottom_margin_page = action_prm.value;
          }
        }

        return Object.assign({}, state, { configs: configs_copy });
      }
      case 'PRINTHEAD_CONFIGURATIONS_BOTTOM_MARGIN_COPY_CHANGE':
      {
        let configs_copy = copyConfigs(state.configs);
        let config = configs_copy[action_prm.id];
      
        if(parseFloat(action_prm.value) >= 0) {
          if(action_prm.inch) {
            config.bottom_margin_copy = Utils.convertToMM(action_prm.value);
          } else {
            config.bottom_margin_copy = action_prm.value;
          }
        }

        return Object.assign({}, state, { configs: configs_copy });
      }
      case 'PRINTHEAD_CONFIGURATIONS/DEVICE_LINK_UPLOAD':
      {

        let config = state.configs[action_prm.id];

        let device_links_uploading = state.device_links_uploading.slice();

        for(let i = 0; i < action_prm.files.length; i++) {
          let file = action_prm.files[i];
          let filename = file.name;

          let xhr = new XMLHttpRequest();
          xhr.withCredentials = true;
          xhr.upload.onprogress = (e) => {
            let done = e.loaded, total = e.total;
              let progress = Math.floor((done / total) * 1000) / 10;
              action_prm.asyncDispatch({ type: 'PRINTHEAD_CONFIGURATIONS/DEVICE_LINK_UPLOAD_PROGRESS', progress: progress, id: action_prm.id, file: file});
          };
      
          xhr.open("POST", 
          Settings.backendUrl +  
          "/upload-device-link-profile?name=" + 
          encodeURIComponent(filename) + 
          "&session_id=" + 
          encodeURIComponent(appState.session_id));
      
          xhr.onload = (e) => {
            action_prm.asyncDispatch({ type: 'PRINTHEAD_CONFIGURATIONS/DEVICE_LINK_UPLOAD_FINISHED', id: action_prm.id, file: file, xhr});
          };

          try {
              xhr.send(action_prm.files[i]);
              let device_link_uploading: DeviceLinkUploading = { progress: 0, file: file, config_guid: config.guid, error: false };
              device_links_uploading.push(device_link_uploading);
          }
          catch(error) {
              console.log(xhr.status);
          }
        }
        return Object.assign({}, state, { device_links_uploading: device_links_uploading });
      }
      case 'PRINTHEAD_CONFIGURATIONS/DEVICE_LINK_UPLOAD_FINISHED':
      {
        let configs_copy = copyConfigs(state.configs);
        let config = configs_copy[action_prm.id];

        if(action_prm.xhr.status == 200) {
          let response = JSON.parse(action_prm.xhr.response);
          let id = state.device_links_uploading.findIndex((item) => { return action_prm.file == item.file; });
          if(id != -1) {
            state.device_links_uploading.splice(id, 1);
          }
          
          if(config.device_link_ids == undefined) {
            config.device_link_ids = [];
          }

          if(config.device_link_ids.indexOf(response.id) == -1) {
            config.device_link_ids.push(response.id);
          }

          let data = Messages.PrintheadConfigsType.encode({ config: configs_copy }).finish();
          let qrMessage = Messages.QrMessageType.encode({ session_id: appState.session_id, identifier: Utils.QrMessageId.SAVE_PRINTHEAD_CONFIGURATIONS, data: data }).finish();
          Utils.sendMessage(Utils.MessageId.QR_MESSAGE, qrMessage);
        } else {
          let device_link_uploading = state.device_links_uploading.map((item) => { 
            if(item.file == action_prm.file) {
              return {
                ... item,
                error: true
              };
            }
            return item;
          });
          
          setTimeout(() => {
            action_prm.asyncDispatch({type: 'PRINTHEAD_CONFIGURATIONS/DEVICE_LINK_UPLOAD_FINISHED_DISMISS_ERROR', file: action_prm.file })
          }, 3000);

          return Object.assign({}, state, { device_link_uploading: device_link_uploading });
        }
        return Object.assign({}, state, { configs: configs_copy });
      }
      case 'PRINTHEAD_CONFIGURATIONS/DEVICE_LINK_UPLOAD_FINISHED_DISMISS_ERROR':
      {
        let device_link_uploading = state.device_links_uploading.filter((item) => { 
          return item.file != action_prm.file;
        });
        return Object.assign({}, state, { device_link_uploading: device_link_uploading });
      }
      case 'PRINTHEAD_CONFIGURATIONS/DEVICE_LINK_UPLOAD_PROGRESS':
      {
        let device_link_uploads = state.device_links_uploading.map((item) => {
          if(item.file == action_prm.file) {
            return {
              ... item, 
              progress: action_prm.progress
            }
          }
          return item;
        });
        return Object.assign({}, state, { device_link_uploads: device_link_uploads });
      }
      case 'PRINTHEAD_CONFIGURATIONS/DEVICE_LINK_MOVE_UP':
      {
        let configs_copy = copyConfigs(state.configs);
        let config = configs_copy[action_prm.config_id];
        let device_link_id = config.device_link_ids.indexOf(action_prm.id);
        if(device_link_id != 0) {
          let tmp = config.device_link_ids[device_link_id - 1];
          config.device_link_ids[device_link_id - 1] = action_prm.id;
          config.device_link_ids[device_link_id] = tmp;
        }
        return Object.assign({}, state, { configs: configs_copy });
      }
      case 'PRINTHEAD_CONFIGURATIONS/DEVICE_LINK_MOVE_DOWN':
      {
        let configs_copy = copyConfigs(state.configs);
        let config = configs_copy[action_prm.config_id];
        let device_link_id = config.device_link_ids.indexOf(action_prm.id);
        if(device_link_id != config.device_link_ids.length - 1) {
          let tmp = config.device_link_ids[device_link_id + 1];
          config.device_link_ids[device_link_id + 1] = action_prm.id;
          config.device_link_ids[device_link_id] = tmp;
        }
        return Object.assign({}, state, { configs: configs_copy });
      }
      case 'PRINTHEAD_CONFIGURATIONS/DEVICE_LINK_DOWNLOAD':
      {
        const url = Settings.backendUrl + "/device-link-profiles/" + action_prm.id + "?session_id=" + encodeURIComponent(appState.session_id);
        Utils.download(url);
        
        return state;
      }
      case 'PRINTHEAD_CONFIGURATIONS/DEVICE_LINK_DELETE':
      {
        let configs_copy = copyConfigs(state.configs);
        let config = configs_copy[action_prm.config_id];
        let device_link_id = config.device_link_ids.indexOf(action_prm.id);
        if(device_link_id != -1) {
          config.device_link_ids.splice(device_link_id, 1);
        }
        return Object.assign({}, state, { configs: configs_copy });
      }
      case ActionPrintModePCLChange.getType():
      {
        let action = action_prm as ActionPrintModePCLChange;
        let configs_copy = copyConfigs(state.configs);
        let config = configs_copy[action.id];
        config.print_mode_pcl = action.value;

        if(config.print_mode_pcl == appcore.ripe.PrintModePCL.single_page_static) {
          config.selected_pages_to_print = "current_page";
        }

        return {
          ... state,
          configs: configs_copy,
          pages_to_print_disabled: pagesToPrintDisabled(sharedState, configs_copy, state.selectable_outputs)
        }
      }
      default:
        return state;
    }
};