import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import IconButton from '@material-ui/core/IconButton';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { ComponentToolbarButton, ComponentQueueControl } from "ripe.digital-ts-react-components";
import ShortTextIcon from '@material-ui/icons/ShortText';
import { ComponentResourceQueue, ComponentLog } from 'ripe.digital-ts-react-components';
import { QueueType } from 'ripe.digital-ts-react-components';
import { Typography, Divider } from '@material-ui/core';

const styles = (theme) => createStyles({
    root: {
      height: "100%",
      width: "100%",
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
      display: "flex", 
      flexDirection: "row"
    },
    navigation_bar: {
      minHeight: 40,
      display: "flex",
      alignItems: "center"
    },
    toolbar: {
      minWidth: 72,
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    icons_container: {
      width: 60, 
      display: "flex", 
      justifyContent: "center"
    },
    icon: {
      padding: 0
    },
    queue_root_container: {
      width: "100%", 
      overflowY: "auto",
      backgroundColor: theme.palette.background.paper
    },
    fixBottomContainer: {
      display: "flex", 
      color: theme.palette.text.primary,
      flexDirection: "column",
      height: 200
    }
});

class ComponentRasterizationQueuePage extends React.Component {

  state = {
      queue_paused: false,
      queue: [],
      log_entries: [
        { timestamp: new Date(), message: "Received Job: img1.pdf"},
        { timestamp: new Date(), message: "Received Job: img2.pdf"},
        { timestamp: new Date(), message: "Received Job: img3.pdf"}
      ]
  }

  constructor(props) {
    super(props);

    let queue = new Array();

    for(let i = 1; i <= 3; i++) {
      queue.push(
        { 
          copies: 3, 
          copies_done: i == 1 ? 1 : 0, 
          name: "Job " + i, 
          pages_done: i == 1 ? 1 : 0,
          total_pages: 10, 
          processing: i == 1, 
          resource_id: i.toString(),
          status: "STATUS " + i,
          thumbnail_uri: 
           i == 1 ? "https://picsum.photos/id/16/1000/300" :
           "https://picsum.photos/id/14/300/1000"
        }
      );
    }

    this.state.queue = queue;
  }

  render() {
    
    let classes = this.props.classes;

    return (
      <div className={classes.root}>
        <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
          
          <div className={classes.navigation_bar}>
            <div className={classes.icons_container}>
              <IconButton className={classes.icon}>
                  <KeyboardArrowLeftIcon></KeyboardArrowLeftIcon>
              </IconButton>
              <IconButton className={classes.icon}>
                  <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
              </IconButton>
            </div>
          </div>


          <div style={{display: "flex", flexDirection: "row", flex: 1}}>
            <div className={classes.toolbar}>
              <div style={{flex: 1}}></div>
              <ToggleButton value={this.state.show_log} style={{padding: 0, borderRadius: 0}} selected={this.state.show_log} onChange={() => { this.setState({show_log: !this.state.show_log}) }}>
                <ComponentToolbarButton text="LOG">
                  <ShortTextIcon></ShortTextIcon>
                </ComponentToolbarButton>
              </ToggleButton>
            </div>
            <div className={classes.queue_root_container}>
              <div style={{height: 0}}>
                <ComponentResourceQueue type={QueueType.JOB} resources={this.state.queue}></ComponentResourceQueue>
              </div>
            </div>
          </div>
          

          <div>
            <div style={{height: this.state.show_log ? 90: 0}}>
              <ComponentLog log_entries={this.state.log_entries}></ComponentLog>
            </div>
          </div>

        </div>
        <div style={{ display: "flex", flexDirection: "column", width: 180, minWidth: 180, padding: 20, paddingTop: 0, paddingBottom: 10}}>

            <div className={classes.fixBottomContainer}>
              <Typography variant="subtitle1">QUEUE CONTROL</Typography>
              <Divider style={{marginBottom: 8}}/>
              <div style={{display: "flex", flexDirection: "column", alignItems: "center", marginTop: 5}}>
                <ComponentQueueControl 
                  onClearAll={
                    () => {
                    }
                    
                  }
                  onPause={
                    () => {
                        this.setState({queue_paused: !this.state.queue_paused});
                    }
                  }
                  onStart={
                    () => {
                        this.setState({queue_paused: !this.state.queue_paused});
                    }
                  }
                  paused={this.state.queue_paused}
                ></ComponentQueueControl>
              </div>
            </div>
        </div>

      </div>
    )
  }
}

export default withStyles(styles, { withTheme: true })(ComponentRasterizationQueuePage);