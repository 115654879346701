
import React from 'react';
import { Button } from 'react-native-paper';
import { PropsRipeButton } from './RipeButton'
import { withTranslation, WithTranslation } from "react-i18next";

interface PropsRipeButtonDelete extends PropsRipeButton {
    timeout: number;
}

interface StateRipeButtonDelete {
    showIcon: { uri: string } | string;
    actualText: string;
    deletionConfirmed: boolean;
}

let trash_icon = { uri: "trash-can-outline.svg"};

class RipeButtonDelete extends React.Component<PropsRipeButtonDelete & WithTranslation, StateRipeButtonDelete> {

    public constructor(props: PropsRipeButtonDelete & WithTranslation) {
        super(props);

        const { t } = props;

        this.state = {
            showIcon: { uri: "trash-can-outline.svg"},
            actualText: t("delete"),
            deletionConfirmed: true,
        }
    }

    private timeout_id: NodeJS.Timeout = null;
    
    render() {

        const { t } = this.props;

        return (
            <Button
                style={[{ borderColor: "gray", borderWidth: 1 }, this.props.style]} labelStyle={this.props.labelStyle} color="#FFFFFF" icon={this.state.showIcon} mode="contained" onPress={() => {
                    if (this.props.onPress !== undefined) {
                        if (this.state.showIcon == "undo") {
                            this.setState({ showIcon: trash_icon, actualText: t("delete") });
                            clearTimeout(this.timeout_id);
                        }
                        else {
                            this.setState({ showIcon: "undo", actualText: t("undo")});

                            this.timeout_id = setTimeout(() => {
                                    this.props.onPress();
                                    this.setState({ showIcon: trash_icon, actualText: t("delete") });
                            }, this.props.timeout);
                        }

                    }
                }}>
                {this.state.actualText}
            </Button>
        );
    }
}

export default withTranslation()(RipeButtonDelete);