import Settings from "./Settings";

import React, { Component } from 'react';
import {
  StyleSheet,
  Text,
  FlatList,
  TouchableOpacity,
  TouchableHighlight,
  ImageBackground,
  Image,
  View,
  TextInput,
  SafeAreaView,
  Picker,
  Switch
} from 'react-native';

import { withTranslation } from "react-i18next";

import { styles, colors, themes } from "./Styles";

import { Button, DataTable, IconButton } from "react-native-paper";

import uuidv4 from "uuid/v4";

import Utils from "./Utils";

import Messages from "./Messages";
import Input from '@material-ui/core/Input';

class ICCProfilesConfigurationPage extends Component {

    state = {
        profiles: [],
        filteredProfiles: []
    }

    componentWillUnmount() {    
        Utils.removeHandler(Utils.MessageId.QR_MESSAGE, this.qrMessageHandler);
    }
    
    filter = "";

    filterAndSortProfiles(items) {
        var arr = items.filter((item) => {
            return item.name.toLowerCase().includes(this.filter.toLowerCase());
        });

        if(this.sort == "asc") {
            arr = arr.sort((a, b) => {
                if(a.name == b.name) {
                    return 0;
                }
                if(a.name > b.name) {
                    return 1;
                }
                if(a.name < b.name) {
                    return -1;
                }
            });
        } else if(this.sort == "desc") {
            arr = arr.sort((b, a) => {
                if(a.name == b.name) {
                    return 0;
                }
                if(a.name > b.name) {
                    return 1;
                }
                if(a.name < b.name) {
                    return -1;
                }
            });
        }

        return arr;
    }

    handleQrMessage(data)  {
        var qrMessage = Messages.QrMessageType.decode(data);
        if(qrMessage.session_id === this.props.sessionId) {
          if(qrMessage.identifier === Utils.QrMessageId.ICC_PROFILES) {
            var profiles = Messages.ICCProfilesType.decode(qrMessage.data);
            this.setState({profiles: profiles.icc_profiles, filteredProfiles: this.filterAndSortProfiles(profiles.icc_profiles)});
          } else if(qrMessage.identifier == Utils.QrMessageId.DELETE_ICC_PROFILE_RESULT) {
            var delete_result = Messages.DeleteICCProfileResultType.decode(qrMessage.data);
  
            var profile = this.state.profiles.find((item) =>{
              return item.guid == delete_result.guid;
            });
  
            if(profile != undefined && delete_result.error_code == 0) {
                this.state.profiles.splice(this.state.profiles.indexOf(profile), 1);
                this.setState({profiles: this.state.profiles, filteredProfiles: this.filterAndSortProfiles(this.state.profiles)});
            }
          }
        }
      }
        
    componentWillMount() {
        this.qrMessageHandler = this.handleQrMessage.bind(this);
        Utils.setHandler(Utils.MessageId.QR_MESSAGE, this.qrMessageHandler);
        var qrMessage = Messages.QrMessageType.encode({ session_id: this.props.sessionId, identifier: Utils.QrMessageId.GET_ICC_PROFILES }).finish();
        Utils.sendMessage(Utils.MessageId.QR_MESSAGE, qrMessage);
    }

    constructor () {
        super();
    }

    renderItem(d) {
        
        const { t } = this.props;

        if(d.item.uploading) {
          
            return (
              <TouchableOpacity style={localStyles.item } disabled={true}>
                <View style={{flex: 1, flexDirection: "row", justifyContent: "space-between"}}>
                  <Text style={{fontSize: 14, marginLeft: 10, alignSelf: "center"}}>
                    {d.item.name}
                  </Text>
                  <Text style={{alignSelf: "center", marginRight: 10}}>
                    { `(${t("uploading")}: ${d.item.uploadPercentage}%)` }
                  </Text>
                </View>
              </TouchableOpacity>
            );
          }

          let action_buttons;
          if(d.item.downloadable) {
            action_buttons = 
            
            <View style={{flexDirection: "row"}}>
                <IconButton icon={{ uri: "trash-can-outline.svg"}} style={{margin: 0}} size={24} onPress={this.handleDeleteProfilePress.bind(this, d.item.guid)}/>
                <IconButton icon="cloud-download-outline" style={{margin: 0, marginRight: 5}} size={24} onPress={this.downloadProfilePress.bind(this, d)}/>
            </View>
          }

          return (
            <TouchableOpacity style={localStyles.item}>
              <View style={{flexDirection: "row", justifyContent: "space-between"}}>
                  <Text style={{fontSize: 14, alignSelf: "center", marginLeft: 10}}>{d.item.name}</Text>
                    {action_buttons}
              </View>
            </TouchableOpacity>
          );
    }

    
    async downloadProfilePress(d) {
        var response = await fetch(Settings.backendUrl + "/profiles/" + d.item.guid + "?session_id=" + encodeURIComponent(this.props.sessionId));
        
        const url = window.URL.createObjectURL(await response.blob());
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        
        a.download = d.item.name;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    }

    handleDeleteProfilePress(guid) {
        var deleteInputData = Messages.DeleteICCProfileType.encode({ guid: guid }).finish();
        var qrMessageData = Messages.QrMessageType.encode({ identifier: Utils.QrMessageId.DELETE_ICC_PROFILE, data: deleteInputData, session_id: this.props.sessionId }).finish();
        Utils.sendMessage(Utils.MessageId.QR_MESSAGE, qrMessageData);
    }

    ondragover(e){
        e.preventDefault();
    }

    ondrop(e){
        e.preventDefault();

        if(e.dataTransfer.items) {
            for(var i = 0; i < e.dataTransfer.items.length; i++) {
                if(e.dataTransfer.items[i].kind === "file") {
                    var file = e.dataTransfer.items[i].getAsFile();
                    this.uploadFile(file);
                }
            }
        } else {
            for(var i = 0; i < e.dataTransfer.files.length; i++) {
                var file = e.dataTransfer.files[i].name;
                this.uploadFile(file);
            }
        }
    }

         /**
      * 
      * @param {File} file 
      */
     uploadFile(file) {
    
        const { t } = this.props;

        if(file.name.toLowerCase().endsWith(".icc") || file.name.toLowerCase().endsWith(".icm")) {
    
            var profile = {
              guid: 'uploading_' + this.state.profiles.length,
              name: file.name,
              selected: false,
              uploading: true,
              uploadPercentage: 0
            };
    
            this.state.profiles.push(profile);
            this.setState({ profiles: this.state.profiles, filteredProfiles: this.filterAndSortProfiles(this.state.profiles) });
    
            var xhr = new XMLHttpRequest();
            xhr.withCredentials = true;
            xhr.upload.onprogress = (e) => {
                var done = e.loaded, total = e.total;
                let progress = Math.floor((done / total) * 1000) / 10;
                profile.uploadPercentage = progress;
                this.setState({ profiles: this.state.profiles, filteredProfiles: this.filterAndSortProfiles(this.state.profiles) });
            };
    
            xhr.open("POST", Settings.backendUrl +  "/upload-profile?name=" + encodeURIComponent(file.name) + "&session_id=" + encodeURIComponent(this.props.sessionId), true);
    
            xhr.onload = (e) => {
    
              if (xhr.status === 200) {
                var resp = JSON.parse(xhr.responseText);
                profile.guid = resp.guid;
                profile.uploading = false;
                profile.downloadable = true;
                this.setState({ profiles: this.state.profiles, filteredProfiles: this.filterAndSortProfiles(this.state.profiles) });
              } else if(xhr.status === 409) {
                let error_text = `${t("warning")}: ${t("file_is_already_in_the_list")}`;
                this.handleUploadError(error_text, profile);
              }
            };
    
            xhr.send(file);
        }
    }

    handleUploadError(text, profile) {
        profile.name += " (" + text + ") ";
        this.setState({ profiles: this.state.profiles, filteredProfiles: this.filterAndSortProfiles(this.state.profiles) });
        setTimeout(() => {
            var id =  this.state.profiles.indexOf(profile);
            this.state.profiles.splice(id, 1);
            this.setState({ profiles: this.state.profiles, filteredProfiles: this.filterAndSortProfiles(this.state.profiles) });
        }, 3000);
      }


    handleFilterInputChange(e) {
        this.filter = e.currentTarget.value;
        this.setState({ filteredProfiles: this.filterAndSortProfiles(this.state.profiles) });
    }

    sort = "";

    handleSortAscPress() {
        this.sort = (this.sort === "asc" ? "": "asc");
        this.setState({ filteredProfiles: this.filterAndSortProfiles(this.state.profiles) });
    }

    handleSortDescPress() {
        this.sort = (this.sort === "desc" ? "": "desc");
        this.setState({ filteredProfiles: this.filterAndSortProfiles(this.state.profiles) });
    }

    renderSortButtons() {

        var buttons = [];
        if(this.sort == "asc" || this.sort == "") {
            buttons.push(
                <TouchableOpacity onPress={this.handleSortAscPress.bind(this)}>
                    <Image source={{uri: "up-icon.png"}} style={[localStyles.sortIcon]}></Image>
                </TouchableOpacity>)
        } else {
            buttons.push(<View style={[localStyles.sortIcon]}></View>);
        }

        if(this.sort == "desc" || this.sort == "") {
            buttons.push(
                <TouchableOpacity onPress={this.handleSortDescPress.bind(this)}>
                <Image source={{uri: "down-icon.png"}} style={[localStyles.sortIcon]}></Image>
            </TouchableOpacity>)
        } else {
            buttons.push(<View style={[localStyles.sortIcon]}></View>);
        }

        return (
            <View>
                {buttons}
            </View>
        );
    }

    render() {

        const { t } = this.props;

        return (
            <View style={{alignItems: "center", flex: 1, marginLeft: 100, marginRight: 100}}>
                
                <View style={{flexDirection: "row", alignSelf: "stretch", marginBottom: 10}}>
                    <Input placeholder={t("filter_by_name")} style={{height: 32, width: "100%"}} shrink={false} onChange={ (e) => { this.handleFilterInputChange(e); } }/>
                    { this.renderSortButtons() }
                </View>
                <FlatList
                    style={localStyles.profileList}
                    data={this.state.filteredProfiles}
                    renderItem={ this.renderItem.bind(this) }
                    keyExtractor={item => item.guid}
                    extraData={this.state}/>
                <div onDrop={(e) => { this.ondrop(e) }} onDragOver={(e) => { this.ondragover(e) }}>
                    <View style={styles.dropdown}>
                      <Text>{t("drop_icm_files_here")}</Text>
                      <Image source={{ uri: "upload-icon.png"}} style={styles.uploadIcon}></Image>
                    </View>
                </div>
            </View>
        );
    }
}

const itemHighlightColor = "#cee9ff";
const itemColor = "#FFFFFF";

const localStyles = StyleSheet.create({
    profileList: {
        flex: 1,
        marginBottom: 10,
        alignSelf: "stretch"
    },
    sortIcon: {
        width: 16,
        height: 16
    },
    item: {
        backgroundColor: itemColor,
        height: 46,
        justifyContent: "center",
        marginVertical: 1,
    },
    itemIcon: {
        width: 24, height: 24
    },
    filterInputStyle: {
        height: 40,
        flex: 1,
        borderBottomWidth: 2,
        borderBottomColor: "#75757d",
        alignSelf: "stretch"
      },
  });
  
  export default withTranslation()(ICCProfilesConfigurationPage);