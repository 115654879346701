import Messages from "../Messages";
import Utils from "../Utils";

export enum ActionsComponentSettingsLabel {
    LABEL_COLOR_CHANGE = "SETTINGS_LABEL/LABEL_COLOR_CHANGE",
    LABEL_NAME_CHANGE = "SETTINGS_LABEL/LABEL_NAME_CHANGE",
    ADD_OR_SAVE_LABEL = "SETTINGS_LABEL/ADD_OR_SAVE_LABEL",
    DELETE_LABEL = "SETTINGS_LABEL/DELETE_LABEL",
    EDIT_LABEL = "SETTINGS_LABEL/EDIT_LABEL",
    LOAD_DATA = "SETTINGS_LABEL/LOAD_DATA",
    UNMOUNT = "SETTINGS_LABEL/UNMOUNT",
    DATA = "SETTINGS_LABEL/DATA",
}

export interface Label {
    id: number;
    name: string;
    color: string;
}

export interface StateSettingsLabel {
    labels: Array<Label>;
    label_color: string;
    label_name: string;
    label_id: number;
    edit: boolean;
    preset_colors: Array<string>;
    data_loaded: boolean;
    color_choosed_for_add_label_by_app: boolean;
    color_choosed_for_add_label_by_user: boolean;
}

const ripe_preset_colors =
    [
        "#FDB4C1", /* https://www.schemecolor.com/was-it-true.php */
        "#FFDEDA",
        "#C5C2DF",
        "#9B94BE",
        "#F9C8A0",
        "#F28997",

        "#FCBAB1", /* https://www.schemecolor.com/subtle-care.php */
        "#F6EEDF",
        "#E7E1C9",
        "#D1CEBD",
        "#A1C1AD"
    ];

let initial_state: StateSettingsLabel = {
    labels: [],
    label_color: "#FDB4C1",
    label_name: "",
    label_id: 0,
    edit: false,
    preset_colors: ripe_preset_colors,
    data_loaded: false,
    color_choosed_for_add_label_by_app: false,
    color_choosed_for_add_label_by_user: false
};

function getRandomColor() {
    var letters = 'BCDEF'.split('');
    var color = '#';
    for (var i = 0; i < 6; i++ ) {
        color += letters[Math.floor(Math.random() * letters.length)];
    }
    return color;
}

function selectNonUsedPresetColor(preset_colors: Array<string>, labels: Array<Label>, new_label_name: string): string {

    let preset_color_non_used_at_all = preset_colors.find((preset_color) => {
        let label = labels.find((label) => { return label.color.toLowerCase() == preset_color.toLowerCase() });
        return label == undefined;
    });

    if(preset_color_non_used_at_all == undefined) { 
        // All preset colors are used, try find a colors which isn't used with the first letter of the label name
        let preset_color_non_used_with_label_name = preset_colors.find((preset_color) => {
            let label = labels.find((label) => { return label.color.toLowerCase() == preset_color.toLowerCase()
                && new_label_name.toLowerCase().charAt(0) == label.name.toLowerCase().charAt(0); });
            return label == undefined;
        });

        if(preset_color_non_used_with_label_name == undefined) {
            // All preset colors are used with the first letter of the label name
            let random_color = getRandomColor();
            while(preset_colors.find((color) => { return color.toLowerCase() == random_color.toLowerCase(); }) != undefined) {
                random_color = getRandomColor();
            }
            return random_color;
        } else {
            return preset_color_non_used_with_label_name;
        }

    } else {
        return preset_color_non_used_at_all;
    }
    
}

function getPresetColorsWithUserColors(labels: Array<Label>): Array<string> {
    let colors = ripe_preset_colors.slice();
    labels.forEach((label) => {
        if(colors.findIndex((color) => { return label.color.toLowerCase() == color.toLowerCase(); }) == -1) {
            colors.push(label.color);
        }
    })
    return colors;
}

export const reducerSettingsLabel = (app_state: any, shared_state: any, state = initial_state, action: { type: string, data: string | number | Uint8Array }) => {
    switch (action.type) {
        case ActionsComponentSettingsLabel.LOAD_DATA:
            {
                let qrMessage = Messages.QrMessageType.encode({ session_id: app_state.session_id, identifier: Utils.QrMessageId.GET_LABELS }).finish();
                Utils.sendMessage(Utils.MessageId.QR_MESSAGE, qrMessage);
                return state;
            }
        case ActionsComponentSettingsLabel.DATA:
            {
                let labels = Messages.LabelsType.toObject(Messages.LabelsType.decode(action.data as Uint8Array), { defaults: true }).labels;
                let preset_colors = getPresetColorsWithUserColors(labels);
                return Object.assign({}, state, {
                    labels: labels,
                    label_id: 0,
                    label_name: "",
                    label_color: selectNonUsedPresetColor(preset_colors, labels, ""),
                    data_loaded: true,
                    edit: false,
                    preset_colors: preset_colors,
                    color_choosed_for_add_label_by_app: false,
                    color_choosed_for_add_label_by_user: false
                });
            }
        case ActionsComponentSettingsLabel.UNMOUNT:
            {
                return Object.assign({}, state, {
                    labels: [],
                    label_color: "#FDB4C1",
                    label_name: "",
                    label_id: 0,
                    edit: false,
                    preset_colors: [],
                    data_loaded: false
                });
            }
        case ActionsComponentSettingsLabel.LABEL_COLOR_CHANGE:
            {
                return Object.assign({}, state, { label_color: action.data, color_choosed_for_add_label_by_user: true });
            }
        case ActionsComponentSettingsLabel.LABEL_NAME_CHANGE:
            {
                let choose_color_by_app =  !state.color_choosed_for_add_label_by_app;

                if(!state.color_choosed_for_add_label_by_user) {
                    // If user isn't specified a color, then check if label first character is changed, because then we need to search
                    // for a possibly new preset color
                    if((action.data as string).toLowerCase().charAt(0) != state.label_name.toLowerCase().charAt(0)) {
                        choose_color_by_app = true;
                    }
                } else {
                    choose_color_by_app = false;
                }

                if(state.edit) {
                    choose_color_by_app = false;
                }

                return Object.assign({}, state, { 
                    label_name: action.data, 
                    label_color: choose_color_by_app ?
                    selectNonUsedPresetColor(state.preset_colors, state.labels, action.data as string) 
                    : state.label_color,
                    color_choosed_for_add_label_by_app: choose_color_by_app
                });
            }
        case ActionsComponentSettingsLabel.ADD_OR_SAVE_LABEL:
            {
                let data = Messages.LabelOperationType.encode(
                    {
                        label: { color: state.label_color, id: state.label_id, name: state.label_name },
                        op: state.edit ? Messages.LabelOperationType.Operation.EDIT_LABEL : Messages.LabelOperationType.Operation.ADD_LABEL
                    }).finish();

                let qrMessage = Messages.QrMessageType.encode(
                    {
                        session_id: app_state.session_id,
                        identifier: Utils.QrMessageId.LABEL_OPERATION,
                        data: data
                    }).finish();
                Utils.sendMessage(Utils.MessageId.QR_MESSAGE, qrMessage);
                return state;
            }
        case ActionsComponentSettingsLabel.DELETE_LABEL:
            {
                let data = Messages.LabelOperationType.encode(
                    {
                        label: { id: action.data as number },
                        op: Messages.LabelOperationType.Operation.DELETE_LABEL
                    }).finish();

                let qrMessage = Messages.QrMessageType.encode(
                    {
                        session_id: app_state.session_id,
                        identifier: Utils.QrMessageId.LABEL_OPERATION,
                        data: data
                    }).finish();
                Utils.sendMessage(Utils.MessageId.QR_MESSAGE, qrMessage);
                return state;
            }
        case ActionsComponentSettingsLabel.EDIT_LABEL:
            {
                let label = state.labels.find((item) => { return item.id == action.data; });
                return Object.assign({}, state, { 
                    label_name: label.name, 
                    label_color: label.color, 
                    label_id: label.id, 
                    edit: true });
            }
        default:
            return state;
    }

}