import React from 'react';
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import { withTranslation, WithTranslation } from "react-i18next";
import { colors } from "../Styles";
import ComponentLabel from "../ComponentLabel/ComponentLabel";
import { Label } from "../ComponentSettingsLabel/ReducerSettingsLabel";
import IconButton from "@material-ui/core/IconButton";
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';

interface PropsComponentLabelSelect {
    labels: Array<Label>;
    selected_labels: Array<number>;
    onSelectLabel: (labels: Array<number>) => void;
    style?: any;
}

interface StateComponentLabelSelect {
    open: boolean;
}
  
const styles = (theme: Theme) => createStyles({ 
    root_checkbox: {
        color: colors.switchThumb,
        "&$checked": {
          color: colors.switchThumb
        }
    },
    checked_checkbox: {},
    root_select: {
        width: 120,
        position: "absolute"
    },
    icon: {
        color: "black", padding: 5
    }
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "25vw",
    },
  },
  getContentAnchorEl: null
};

type PropsComponentLabelSelectWithStylesAndTranslation = 
PropsComponentLabelSelect & WithStyles<typeof styles> & WithTranslation;

class ComponentLabelSelect extends React.Component<PropsComponentLabelSelectWithStylesAndTranslation, StateComponentLabelSelect> {

    constructor(props: PropsComponentLabelSelectWithStylesAndTranslation) {
        super(props);

        this.state = {
            open: false
        };
    }

    private handleChange(event) {
        this.props.onSelectLabel(event.target.value);
    }

    render() {
        
        const { classes, labels, selected_labels, t } = this.props;

        return (
            <div style={this.props.style}>

                <IconButton className={classes.icon} 
                    disabled={labels.length == 0} onClick={(e) => { this.setState({open: !this.state.open}); }}>
                        <LocalOfferOutlinedIcon></LocalOfferOutlinedIcon>
                </IconButton>
                <Select
                classes={{root: classes.root_select}}
                multiple
                open={this.state.open}
                onClose={(e) => { this.setState({open: !this.state.open}); }}
                value={selected_labels}
                onChange={this.handleChange.bind(this)}
                MenuProps={MenuProps}
                input={<Input style={{visibility: "hidden"}}/>}
                >
                {labels.map((label) => (
                    <MenuItem key={label.id} value={label.id}>
                        <Checkbox color="primary" checked={selected_labels.indexOf(label.id) > -1} />
                        <ListItemText primary={label.name} primaryTypographyProps={{style: {overflow: "hidden", textOverflow: "ellipsis"}}} />
                        <ComponentLabel color={label.color} text={label.name} style={{width: 30, height: 30, marginLeft: 10}}></ComponentLabel>
                    </MenuItem>
                ))}
                </Select>

            </div>
        );

    }
}

export default withTranslation()(withStyles(styles, {withTheme: true})(ComponentLabelSelect));